@import "~core/components/headerSearch";

.site-search {
    form {
        position: relative;
    }
}

// Search suggestions
.suggestions-wrapper {
    
    .suggestions {
        border-radius: $border-radius;
        padding-top: $spacer;
        padding-bottom: $spacer;
        box-shadow: 0 0 10px rgba($off-black, 0.2);
        overflow: hidden;
        ul {
            list-style: none;
            margin-bottom: 0;
        }
    }
    .suggestions-item .swatch-thumbnail {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    @include media-breakpoint-down(md) {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 110px);
    }
}

.search-icon-only {
    @include media-breakpoint-up(lg) {
        .site-search {
            .search-field {
                &:focus {
                    border-left: 2px solid $primary-black;
                    border-right: 2px solid $primary-black;
                }
            }
            &.expanded {
                button {
                    background-color: transparent;
                    &.reset-button:before {
                        color: transparent;
                    }
                }
            }
            .fa-search {
                padding-top: 0;
                padding-left: 2px;
                right: 2px;
                margin-top: -2px;
                &:focus,
                &:active {
                    outline: 2px solid $outline-color;
                }
            }
        }
    }
}

.site-search {
    .search-field-label {
        border: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .fa-search:before {
        display: none;
    }
}
.usertopnavlayout {
    .header-column-3 {
        .site-search {
            .form-control {
                @include media-breakpoint-up(lg) {
                    margin-top: 10px;
                    width: 155px;
                    border: 1px solid $medium-gray;
                    border-radius: 35px;
                    background: $concrete-grey;
                }
                @include media-breakpoint-up(sd){
                    width: 185px;
                }
                @include media-breakpoint-up(xl){
                    width: 239px;
                }
            }
        }
    }
    .form-control, select.form-control {
        padding: $spacer !important;
    }
    
    .site-search {
        .fa-search {
            @include media-breakpoint-up(lg) {
                margin-top: $spacer/4;
                right: $spacer;
            }
        }
    }
}

// changes from Page Designer EMEA
.EMEA-header-search {
    header {
        .site-search {
            .search-field {
                border: 1px solid #D4D4D4 !important;

                @include media-breakpoint-down(lg) {
                    border-radius: 20px;
                    background: #f3f3f3;
                }

                @include media-breakpoint-up(lg) {
                    border-radius: 35px;
                    background: #f3f3f3;
                    width: 100%;
                }
            }

            input {
                @include media-breakpoint-up(lg) {
                    height: 40px;
                }
            }
        }

    }

    // Styles for hidden search and back-to-top button
    .search.hidden-md-down.pull-right.search-expanded {
        margin-left: 32px;
    }

    #a2c-version-3 {
        .cart-and-ipay {
            .add-to-cart {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 24px !important;
                font-size: 14px;
                text-wrap: nowrap;
            }
        }
    }

    .back-to-top-btn {
        bottom: 120px;
    }

    .sticky-a2c-section.d-md-none {
        z-index: 99;
    }
}
