//Flyout menu
$flyout-menu__z-index: 101;
$flyout-transition__menu: right 0.5s cubic-bezier(0, 1, 0.5, 1);

.flyout-opened {
    overflow: hidden;
}

.flyout-veil-bg {
    background-color: transparent !important;
}

.flyout-wrapper {
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    bottom: 0;
    max-width: 100%;
    overflow: hidden;
    transition: $flyout-transition__menu;
    position: fixed;
    z-index: 9999;

    @include media-breakpoint-up (md) {
        width: 414px;
        right: -27rem;
    }

    &::after {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        background: $white;
        top: 0;
        bottom: 0;
        z-index: -1;
        pointer-events: none;
    }

    &::before {
        content: " ";
        position: fixed;
        left: 0;
        right: 0;
        height: 100vh;
        background: rgba($black, 0.3);
        top: 0;
        bottom: 0;
        backdrop-filter: blur(1px);
        z-index: -1;
        pointer-events: none;
        opacity: 0;
        //transition: opacity $transition;
    }

    &.flyout-show {
        &::before {
            opacity: 1;
        }
    }

    &.flyout-show {
        right: 0;
        box-shadow: 0 0 rem(9) rem(5) rgba($black, 0.1);
        transition: $flyout-transition__menu;
    }

    .flyout-frame {
        -ms-overflow-style: none; /* stylelint-disable-line */
        scrollbar-width: none; /* stylelint-disable-line */

        &::-webkit-scrollbar {
            display: none;
        }

        &.scroll {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .header-welcome-message {
        text-align: left;
    }

    .flyout-list {
        li {
            text-align: left;
            padding-bottom: 10px;
        }

        a {
            font-size: 0.875rem;
            color: $brand-primary;
            font-weight: 700;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .contact-links {
        li {
            margin-bottom: rem(16);
        }
    }
}
