.tab-section {
    padding: 0 $spacer;
    .container {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
    .tabs-holder {
        overflow: hidden;
        .tab-wrapper {
            @include media-breakpoint-down(md) {
                display: block !important;
            }
        }
        .container {
            padding: 0 $spacer;
            @include media-breakpoint-up(md) {
                padding: 0 $spacer*2;
            }
            .tab-pane {
                margin-left: -$spacer;
                margin-right: -$spacer;

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                    margin-right: 0;
                }
                .slider-container {
                    &.scroll-slider-active {
                        &.next-slide-40.slider-buttons-hidden {
                            @include media-breakpoint-down (sm) {
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                        &.next-slide-22.slider-buttons-hidden {
                            @include media-breakpoint-down (sm) {
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                    }
                    @include media-breakpoint-down(md) {
                        padding: 0;
                    }
                }

                .product-tile-image-container {
                    @include media-breakpoint-up (lg) {
                        &.show-secondary-image {
                            min-height: 370px;
                        }
                    }
                }

                ul.selected-filters-list {
                    display: none;
                }
            }
        }
    }
    .nav-wrapper {
        overflow: auto;
    }
    .nav {
        display: block;
        margin-bottom: $spacer;
        white-space: nowrap;
        background: $tab-menu-background;
        max-width: 100%;
        text-align: center;
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-scroll-behavior: smooth;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
            display: none;
            width: 0 !important;
            height: 0 !important;
            -webkit-appearance: none;
            background: transparent; // HERE IS THE TRICK
        }

        .nav-item {
            display: inline-block;
            vertical-align: middle;
        }

        .nav-link {
            font-size: $nav-link-font-size-s;
            line-height: $nav-link-line-height-s;
            display: flex;
            color: $nav-link-color;
            position: relative;
            align-items: center;
            text-decoration: none;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            transition: all 0.3s linear;
            border-bottom: 2px solid transparent;
            padding: $spacer $spacer*1.375;
            &.active {
                color: $brand-primary;
                border-bottom: 2px solid $brand-primary;
            }
            @include media-breakpoint-up(md) {
                padding: $spacer*1.5 $spacer*1.375;
            }
        }
        @include media-breakpoint-up(md) {
            margin-bottom: $spacer*2;
            padding: 0 52px;
        }
    }
    .slider-container {
        .tns-controls button {
            top: 34%;
        }
        @include media-breakpoint-down(md) {
            padding-left: $spacer/2;
            padding-right: $spacer/2;
        }
    }
    @include media-breakpoint-up(md) {
        padding: 0;
    }

    .slider-container {
        @include media-breakpoint-down(sm) {
            .tns-inner{
                padding-right: 0;
            }
        }
    }
    &.full-bleed {
        .tabs-holder {
            @include media-breakpoint-down(sm) {
                overflow: visible;
                .nav-wrapper {
                    overflow: visible;
                    margin: 0 -16px;
                }
            }
        }
    }

    &.new-category-tabs {
        margin-bottom: $spacer*3;

        .tabs-holder {
            .container {
                padding: 0 $spacer*0.5;
                @include media-breakpoint-up(md) {
                    padding: 0 $spacer;
                }
            }
        }
        .nav {
            text-align: left;
            max-width: 1440px;
            margin: 0 auto $spacer*2;
            padding: 0 $spacer;
            background: none;
            
            @include media-breakpoint-up(md) {
                padding: 0 $spacer*2;
            }

            .nav-item {
                margin-right: $spacer;
                &:last-child {
                    margin-right: 0;
                }
            }
            .nav-link {
                border: 2px solid $primary-black;
                padding: $spacer/2 $spacer;
                border-radius: $spacer*4;
                text-align: center;
                font-size: $base-font-size;
                line-height: $base-font-size*1.5;

                &:hover,
                &.active {
                    background-color: $primary-black;
                    color: $white;
                }
            }

        }
        .experience-commerce_assets-button {
            .button {
                text-align: center;
                padding: $spacer*4 0 $spacer;
            }
        }
    }

    &.no-border-links {
        .nav {
            .nav-link {
                border-bottom: none;
                padding: $spacer $spacer*1.5;
                &.active {
                    padding: $spacer $spacer*1.5;
                    font-weight: bold;
                    border-bottom: none;
                }
            }
        }
    }
}
.black-page-theme {
    background-color: $primary-black;
    color: $white;
    .tab-section {
        .tabpicker {
            background: black;
            color: white;
            .nav-link {
                color: white;
                &.active {
                    border-bottom: 2px solid white;
                }
            }
        }
    }
    .product-tile-image-container {
        background: $primary-black;
        color: $white;
    }
    .product-tile {
        a {
            color: $white;
            &.link {
                color: $white;
                &:hover {
                    color: $white;
                }
                &.charity-link {
                    color: $white;
                }
            }
        }
        .sales  {
            color: $white;
        }
        .wishlist-icon {
            &::before {
                color: $white;
            }
        }
        .price {
            del {
                color: $white;
                .strike-through {
                    color: $white;
                }
            }
        }
    }
    .slider-container {
        .tns-controls {
            button {
                background-color: $primary-black;
                &::before {
                    color: $white;
                }
                &:hover {
                    background-color: $primary-black;
                    &::before {
                    color: $white;
                    }
                }
            }
        }
        .tns-nav {
            button {
                background: $white;
                &.tns-nav {
                    background: $white;
                }
            }
        }
    }
    #module-guide-nav-container {
        a {
            color: $white;
        }
    }
    .breadcrumb-item {
        a {
            color: $white;
            &:hover {
                color: $white;
            }
        }
        &+.breadcrumb-item {
            &::before {
                color: $white;
            }
        }
    }
    .image-text-block-text {
        div {
            color: $white;
            p {
                color: $white;
            }
        }
    }
    .btn {
        color: $white;
        &:hover {
            color: $white;
        }
        &::after {
            background-color: $background-gray;
        }
    }
}
.distributors-wrapper {
    .nav-tabs {
        margin: $spacer*2.5 0;
        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: space-between;
        }
        .nav-item {
            padding: 0 $spacer*1.25;
            margin: 0 $spacer*1.25  $spacer*1.25;
            @include media-breakpoint-down(sm) {
                min-width: 50%;
                max-width: 50%;
                margin: 0 0 $spacer*1.25;
            }
            .tab-right {
                @include media-breakpoint-down(sm) {
                    text-align: right;
                }
            }
        }
    }
    #distributorsTab{
        &.nav-tabs {
            border-bottom: 1px solid $off-black;
        }
    }
    .tab-content {
        padding: $spacer*3.75 0 $spacer*1,875;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        img {
            @include media-breakpoint-down(sm) {
                margin: 0 auto;
            }

        }
    }
    .card {
        margin-bottom: $spacer*1.25;
        border: none;
        .card-body {
            padding: $spacer*2.5;
        }
    }
    .card-img-top {
        max-width: $spacer*12.5;
    }
    .image-wrapper {
        margin: 0 $spacer*2.5 $spacer*1.25;
        min-height: $spacer*5.5;
        max-height: $spacer*5.5;
        img {
            min-height: $spacer*5.5;
            max-height: $spacer*5.5;

        }
    }
}