@import "~core/product/detail";

.product-detail {
    .bundle-items-label {
        text-align: center;
        margin-top: $spacer*3;
        margin-bottom: $spacer*3;
    }

    .ootb-bundle {
        display: flex;
        .bundle-item {
            margin: 0;
            border: 0;
            width: auto;
            max-width: inherit;
            flex-basis: 33%;
        }
        .primary-images {
            .primary-images-thumbnails {
                display: none !important;
            }
            .primary-images-main {
                margin: 0 0 $spacer*1.5;
                &.product-has-thumbnails {
                    width: 100%;
                    flex-basis: 100%;
                    max-width: 100%;
                    margin: 0 0 $spacer*1.5;
                }
            }
        }
        .primary-images {
            .primary-images-main {
                &.product-has-thumbnails {
                    .slider-container {
                        .tns-controls {
                            button[data-controls='prev'],
                            button[data-controls='next'] {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .product-name {
        @include typography(5);
        @include media-breakpoint-up(md) {
            @include typography(4);
        }
    }

    .charity-message {
        margin-bottom: 10px;
    }

    .charity-note {
        font-size: 10px;
        line-height: 14px;
        font-style: italic;
    }

    .notify-bis {
        padding-top: $button-notify-padding-top;
        padding-bottom: $button-notify-padding-bottom;
    }
    
    .prices {
        margin-bottom: $spacer;

        .price {
            font-family: $primary-font;
            font-weight: bold;
        }
    }

    .d-md-none {
        .product-name {
            margin-top: 0;
            @include media-breakpoint-up(md) {
                margin-top: $spacer;
            }
        }

        .prices {
            margin-top: 0;
        }

        .product-number-rating {
            margin-bottom: $spacer*0.625;
        }
    }

    .product-number-rating {
        margin-bottom: $spacer*2;
        @include media-breakpoint-up (md) {
            margin-bottom: $spacer*1.125;
        }
    }

    .primary-images {
        margin-bottom: $spacer*1.5;


        .align-data {
            display: flex;
            justify-content: center;
            text-align: center;
            border-radius: 8px;
            position: absolute;
            transform: translate(-50%, -50%);
            @include media-breakpoint-up(lg) {
                bottom:7%;
                left: 50%;
            }
            @include media-breakpoint-down(sd) {
                bottom:9%;
                left: 45%; 
            }
            @include media-breakpoint-down(md) {
                bottom:0;
                left: 50%; 
            }

            .div-class {
                width: max-content;
                @include media-breakpoint-up(lg) {
                    height: 41px;
                }

                @include media-breakpoint-down(lg) {
                    height: 38px;
                }
                border-radius: 8px;
                align-items: center;
                display: block;

                p {
                    display: flex;
                    font-family: Inter;
                    align-items: center;
                    justify-content: center;
                    padding: 12px;

                    @include media-breakpoint-up(sd) {
                        font-size: 14px;
                        line-height: 16px;
                    }
                    @include media-breakpoint-down(sd) {
                        font-size: 12px;
                        line-height: 14px;
                    }

                    .image-styling {
                        max-width: 100%;
                        border-radius: 20px;
                        aspect-ratio: 1/1;
                        @include media-breakpoint-up(sd) {
                            margin-right: 12px;
                            width: 16px;
                            max-width: 100%;
                            height: 16px;
                        }
                        @include media-breakpoint-down(sd) {
                            margin-right: 12px;
                            width: 10px;
                            max-width: 100%;
                            height: 10px;
                        }
                    }
                }

                #closeButton {
                    img {
                        aspect-ratio: 1/1;
                        @include media-breakpoint-up(sd) {
                            margin-left: 20px;
                            width: 12px;
                            max-width: 100%;
                            height: 12px;
                        }
                        @include media-breakpoint-down(sd) {
                            margin-left: 12px;
                            width: 10px;
                            max-width: 100%;
                            height: 10px;
                        }
                    }
                }
            }
        }

        >.row {
            position: sticky;
            top: $spacer*2;
        }

        .primary-images-main {
            background-color: $pdp-image-gallery-background-color;
            @include media-breakpoint-up(md) {
                border-radius: $border-radius;
            }
            &.product-has-thumbnails {
                background-color: $pdp-image-gallery-background-color;
                margin: 0 -8px 0;
                padding: 0;
                width: calc(100% + 16px);
                
                .slider-container {
                    .tns-controls {
                        button {
                            top: 58%;
                            margin-top: $slider-button-width * -0.5;
                            background-color: $white;
                            display: none;
                            border: 1px solid transparent;
                            box-shadow: 0 0 8px rgba($black, .5);

                            @include media-breakpoint-up (md) {
                                display: block;

                                &:disabled {
                                    opacity: 1;
                                    &:before {
                                        color: $medium-gray;
                                    }
                                }
                            }

                            &:hover {
                                background-color: $white;
                                border-color: $primary-black;
                            }

                            &:before {
                                font-size: 17px;
                                color: $primary-black;
                            }

                            &[data-controls='next'] {
                                right: 0;
                            }
                            &[data-controls='prev'] {
                                left: 0;
                            }

                            @include media-breakpoint-up(md) {
                                top: auto;
                                bottom: 0;
                                left: auto;
                                right: 24px;
                                background: $white;
                                box-shadow: 0 0 8px -4px rgba($black, .5);
                    
                                &::before {
                                    line-height: 48px;
                                }

                                &[data-controls='next'] {
                                    right: 24px;
                                }
                    
                                &[data-controls='prev'] {
                                    left: auto;
                                    right: 84px;
                                }
                            }
                        }
                    }
                }
                @include media-breakpoint-up(md) {
                    padding: 0 0 $spacer*3;
                    margin: 0;
                    border-radius: $border-radius;
                    width: 100%;
                    background: none;
                }
                @include media-breakpoint-up(lg) {
                    flex-basis: calc(100% - #{$slider-thumbnail-width-lg} - #{$spacer * 2});
                    max-width: calc(100% - #{$slider-thumbnail-width-lg} - #{$spacer * 2});
                    margin-left: $spacer * 2;
                }
            }

            .slider-container {
                &.scroll-slider-active {
                    .slider {
                        @include media-breakpoint-down(sm) {
                            margin: 0;
                            padding: 0 0 $spacer*3 0;
                        }
                    }

                    .slide {
                        @include media-breakpoint-down (sm) {
                            flex: 0 0 100%;
                            position: relative;
                        }
                    }

                    .simplebar-content {
                        padding: 0 !important;
                    }
                }
                &.scroll-slider-active {
                    &.next-slide-40.slider-buttons-hidden {
                        @include media-breakpoint-down (sm) {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                    &.next-slide-22.slider-buttons-hidden {
                        @include media-breakpoint-down (sm) {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }

                .tns-controls {
                    button {
                        @include media-breakpoint-up(md) {
                            top: auto;
                            bottom: 0;
                            left: auto;
                            right: 25px;
                            background: $white;
                            box-shadow: 0 0 2px $black;

                            &:disabled {
                                opacity: 1;
                                &:before {
                                    color: $medium-gray;
                                }
                            }
                
                            &::before {
                                line-height: 50px;
                            }
                
                            &[data-controls='prev'] {
                                left: auto;
                                right: 85px;
                            }
                        }
                    }
                }
            }
        }

        .slider-container {
            padding: 0;
        }

        .primary-images-thumbnails {
            @include media-breakpoint-down(sm) {
                background-color: $white;
                position: absolute;
                left: 0;
                bottom: 0;
                margin: 0 #{-$spacer*0.5} 0;
                padding: 0;
                width: calc(100% + #{$spacer});
                max-width: inherit;

                .slider-thumbnails {
                    .slider {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-left: $spacer*0.5;
                        padding-right: $spacer*0.5;
                        background: $white;

                        .slide {
                            display: block;
                            background: transparent;
                            width: 25% !important;
                            height: $spacer*0.25;
                            border-radius: $spacer*0.5;
                            margin: 0;
                            padding: 0;
                            cursor: pointer;
                            transition: transform $transition-speed ease-out;
                            min-width: $slider-bullet-size-active;

                            img {
                                display: none !important;
                            }

                            &:after {
                                background: $white;
                                border-radius: 0;
                                width: 100%;
                                height: $spacer*0.25;
                                position: absolute;
                                left: 0;
                            }
                            &:first-child {
                                &::after {
                                    border-top-left-radius: $spacer*0.5;
                                    border-bottom-left-radius: $spacer*0.5;
                                }
                            }

                            &:last-child {
                                &::after {
                                    border-top-right-radius: $spacer*0.5;
                                    border-bottom-right-radius: $spacer*0.5;
                                }
                            }

                            &.tns-nav-active,
                            &:hover:not(.tns-nav-active) {
                                background-color: $medium-gray;
                                transition: background-color 900ms ease-in-out,
                                    height 900ms ease-in-out;
                                border: none;

                                &:after {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
            @include media-breakpoint-up(md) {
                margin-bottom: $spacer*3;
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            .slide {
                background-color: $pdp-image-gallery-background-color;
                border-radius: $border-radius;
                img {
                    border-radius: $border-radius;
                }
            } 
        }
    }

    .quantity-stepper,
    .quantity-select {
        @include media-breakpoint-up(sm) {
            border-radius: 100px;
        }
    }

    .product-features {
        .row {
            flex-wrap: nowrap;
            margin-bottom: $spacer*2.5;

            .feature-image {
                max-width: calc(60px + #{$spacer * 2});

                @include media-breakpoint-up(lg) {
                    max-width: calc(60px + #{$spacer-xl * 2});
                }
            }

            .feature-text {
                padding-left: $spacer;
                display: flex;
                flex-wrap: wrap;
                align-content: center;

                .feature-title {
                    @include typography(10);
                    width: 100%;
                }

                p:last-child {
                    margin-bottom: 0;
                }
            }
        }

        img {
            width: 100%;
            height: auto;
            max-width: 60px;
        }

        h6 {
            font-size: 16px;
        }
    }

    .attributes{
        .row {
            margin-bottom: $spacer;
            &.qty-cart-container {
                margin-bottom: 20px;
            }
            &[data-attr="iPhoneType"] {
                margin-bottom: 20px;
            }
            &.bis-container {
                padding-right: $spacer*4.5;
            }
        }

        .attribute {
            border-radius: $border-radius;
            margin-top: 0;
            margin-bottom: 0;
            border: none;
        }

        .attribute-option {
            position: relative;
            padding: $spacer*1.5 $spacer*2;
            border-style: solid;
            border-color: $medium-gray;
            border-width: 1px;
            border-radius: 0;
            border-bottom: none;
            flex-grow: 1;
            min-height: $spacer*5;

            &:first-child{
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }

            &:last-child{
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                border-bottom: 1px solid $medium-gray;
            }

            &.color-swatches {
                border-width: 0;
                padding: 0;
                margin: 0 0 $spacer/4;
                min-height: 58px;

                @include media-breakpoint-up (md) {
                    min-height: 70px;
                    margin: 0;
                }

                .color-display-value {
                    margin-top: 0;
                    @include media-breakpoint-down(md) {
                        line-height: 1;
                        margin-bottom: 0;
                        height: 15px;
                    }
                }

                .color-attribute {
                    @include media-breakpoint-down (md) {
                        margin: $spacer*0.75 $spacer*0.5 0 0;
                    }
                }
            }
        }

        .custom-attribute-radio {
            label {
                margin: 0 0 0 60px;

                .attribute-name {
                    @include typography(10);
                }

                .attribute-price {
                    margin-right: $spacer;
                    position: relative;
                    .price {
                        display: inline;
                    }
                    &:before {
                        content: '•';
                        display: none;
                        position: absolute;
                        left: ($spacer*-1)-2px;
                        top: 0px;
                        color: $dark-gray;
                        line-height: 18px;
                        font-size: 16px;
                    }
                }
            }

            &.disabled {
                label {
                    .attribute-price {
                        &:before {
                            color: $medium-gray;
                        }
                    }
                }
            }

            .attribute-description {
                margin-left: 0;
                display: block;
                @include typography(11);
            }

            input[type="radio"] {
                display: block;
                width: 100%;
                height: 100%;
                cursor: pointer;
                top: 0;
                left: 0;
                z-index: 1;
            }

            + .custom-radio {
                margin-top: 0;
            }
        }

        .product-availability {
            margin-bottom: 0;
    
            .preorder-pdp-callout {
                display: flex;
                flex-wrap: nowrap;
                width: 100%;
                padding: $spacer $spacer/1.5;
                border-radius: $spacer/2;
                background: $light-gray;
                color: $primary-black;
                position: relative;
                margin-bottom: $spacer*1.5;
    
                @include media-breakpoint-up(md) {
                    margin-bottom:  $spacer*2;
                    padding: $spacer;
                }

                .prod-stock-msg {
                    padding-right: $spacer/1.5;
                    @include typography(12);
                    border-right: 1px solid $medium-gray;
                    flex-basis: 50%;
                    text-align: center;

                    @include media-breakpoint-up(md) {
                        padding-right: $spacer;
                    }

                    .content-asset {
                        display: inline-flex;
                        align-items: center;
                        text-align: left;
                        height: 100%;
                    }
                }

                .shipping-date-info {
                    flex-basis: 50%;
                    padding-left: $spacer/1.25;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    @include media-breakpoint-up(md) {
                        padding-left: $spacer;
                    }

                    > svg {
                        padding-right: 9px;
                        width: 29px;
                    }

                    .info-title {
                        @include typography(15);
                    }

                    .shipping-date {
                        @include typography(12);
                    }
                }
            }

            .preorder-pdp-asset-tooltip {
                float: left;
                padding-right: 8px;
                position: relative;
                @include typography(15);

                .tooltip-area {
                    display: none;
                }

                strong {
                    display: block;
                    @include typography(14);
                }

                &:hover {
                    .tooltip-area {
                        padding: 12px;
                        border-radius: 8px;
                        background: $white;
                        box-shadow: 0 0 5px $gray;
                        max-width: 350px;
                        width: 345px;
                        position: absolute;
                        left: -15px;
                        bottom: 35px;
                        z-index: 99;
                        display: block;

                        @include media-breakpoint-up(md) {
                            max-width: 380px;
                            width: 375px; 
                        }

                        &::before {
                            width: 0;
                            height: 0;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-top: 7px solid white;
                            position: absolute;
                            left: 17px;
                            bottom: -6px;
                            content: "";
                            filter: drop-shadow(0px 1px 1px $gray);
                        }
                    }
                }
            }
        }
    }

    .badge-product-container {
        &.badge-image-over {
            position: absolute;
            top: $spacer*0.4375;
            left: $spacer;
        }
    }

    .attribute,
    .product-option {
        label {
            margin-bottom: $spacer / 2;
        }
    }

    label,
    .non-input-label {
        font-family: $secondary-font;
        font-size: $base-font-size;
        margin-bottom: 12px;
    }

    .non-input-label {
        @include typography(10);

        .non-color-display-value {
            font-weight: normal;
        }
        &.non-input-label {
            display: flex;
            gap: 4px;
            align-items: center;
            .color-display-value {
                margin: 0;
                height: auto;
            }
        }
    }

    .product-number-rating {
        margin-top: 0;
        height: 17px;

        .product-number {
            display: none;
        }

        .ratings {
            margin-left: 0;
            height: 17px;

            .fa:before {
                font-size: 20px;
                width: 17px;
                height: 17px;
                line-height: 17px;
            }
        }
    }

    .qty-cart-container {
        margin-top: 0;

        .cart-and-ipay {
            margin-bottom: 0;
        }

        .wishlist {
            position: relative;
            top: initial;
            right: initial;
            border: none;
            width: 56px;
            height: 56px;
            border: 2px solid $brand-primary;
            border-radius: 50%;
            margin-left: $spacer;
        }

        .mutually-exclusive-products {
            color: $danger;
            padding-top: $spacer;
        }
    }

    .quantity {
        align-self: flex-start;

        .quantity-select {
            line-height: $spacer * 1.5;
            height: 100%;
            border-radius: 100px;
            @include media-breakpoint-down(md) {
                margin-right: $spacer;
            }
            &:focus,
            &:active {
                outline: 2px solid $outline-color;
            }
        }
    }

    .color-attribute {
        display: inline-block;
        vertical-align: middle;
        float: none;
        background: none;
        margin: $spacer*0.75 $spacer*0.5 0 0;
    }

    .color-swatches {
        margin-bottom: 0;
        font-size: 0;
    
        .color-display-value,
        .pop-design-color-display-value {
            @include typography(11);
            display: block;
            margin-top: $spacer/2;
            height: 20px;
        }

        .pop-design-color-display-value {
            margin: -4px 0 0 5px;
        }

        .swatches-holder {
            margin-left: -$spacer;
        }
    
        .swatches {
            @include clearfix;
            margin: 0;
        }
    
        .swatch-circle {
            @include swatch($pdp-color-swatch-size, $white);
            margin: 0;
            border: 1px solid $medium-gray;
            border-radius: $input-border-radius;
            height: $pdp-color-swatch-height;
            width: $pdp-color-swatch-width;
            @include media-breakpoint-down (sm) {
                height: calc(18.5vw);
                width: calc(18.5vw);
            }
            &.selected {
                z-index: 2;
                border: 2px solid $primary-black;
                border-radius: $input-border-radius;
                box-shadow: 0 0 0 calc(#{$color-swatch-selected-shadow-size} - 1px) $page-background-color, 0 0 0 0 $color-swatch-selected-shadow-color;
                position: relative;
            }
        }
        .pdp-add-on-swatch-span.swatch-circle {
            height: $spacer * 2.5;
            width: $spacer * 2.5;
            @include media-breakpoint-down (sm) {
                height: $spacer * 2;
                width: $spacer * 2;
            }
        }
    
        .description {
            display: none;
        }
    
        .swatch .swatch-circle {
            margin: $spacer*0.75 0 0 $spacer*0.5;
        }
    }

    .non-color-attribute-swatches {
        >div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            >.non-input-label {
                flex-basis: 100%;
                &.iPhoneType  {
                    line-height: 1;
                }
            }
        }
        .swatch-tile-container {
            float: none;
            margin: #{$spacer/4} 0;
            flex-basis: 100%;
            max-width: 100%;

            &.iPhoneType-tile {
                @include media-breakpoint-down (sm) {
                    flex-basis: calc(50% - #{$spacer/4});
                    max-width: calc(50% - #{$spacer/4});
                }

                &.full-length {
                    @include media-breakpoint-down (sm) {
                        flex-basis: 100%;
                        max-width: 100%;
                    }
                }

                .swatch {
                    .swatch-value {
                        @include media-breakpoint-down (sm) {
                            @include typography(13);
                        }
                    }
                }
            }

            &:last-child {
                @include media-breakpoint-down (md) {
                    margin: $spacer/4 0 0 0;
                }
            }

            @include media-breakpoint-up(sm) {
                flex-basis: calc(50% - #{$spacer/4});
                max-width: 50%;
            }

            @include media-breakpoint-up(md) {
                flex-basis: 100%;
                max-width: 100%;
            }

            @include media-breakpoint-up(lg) {
                flex-basis: calc(50% - #{$spacer/4});
                max-width: 50%;
            }
            .swatch {
                width: 100%;
                .description {
                    display: none;
                }

                .swatch-value {
                    @include typography(11);
                }
            }
        }
    }
    .vat-pricing {
        font-size: $vat-price;
        line-height: $spacer*1.5;
        color: $vat-color;
        letter-spacing: 0;
        margin-bottom: $spacer*0.625;
        a {
            color: $vat-color;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: $spacer;
        }
    }

    .promotions {
        padding: $spacer $spacer $spacer/2;
        border-radius: $card-border-radius;
        background: $card-body-background;
        margin-bottom: $promotions-margin-bottom;
        margin-top: $promotions-margin-top;
        
        .promotion {
            @include typography(12);
            color: $dark-gray;
            text-align: center;
            margin-bottom: $spacer*0.5;
            strong {
                font-family: $body-copy-s-font-family;
                font-size: $body-copy-s-font-size;
                line-height: $body-copy-s-line-height;
                font-weight: $body-copy-s-font-weight;
            }
        }
    }

    .product-bundle {
        .bundle-items.ootb-bundle {
            .primary-images {
                @include media-breakpoint-up(md) {
                    min-height: 360px;
                }
                @include media-breakpoint-up(lg) {
                    min-height: 460px;
                }

                .primary-images-main {
                    &.product-has-thumbnails {
                        padding: 0;
                        margin: 0 !important;
                    }
                }

                > .row {
                    position: static;
                }
            }
        }

        > .col-md-5 {
            > .row {
                .col-4.ootb-bundle.js-bundle-images {
                    @include media-breakpoint-up(md) {
                        align-items: flex-start;
                    }
                }
            }
        }

        .prices-add-to-cart-actions {
            .primary-images {
                .primary-images-main {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    .see-all-colors {
        @include typography(13);
        text-decoration: underline;
        color: $primary-black !important;

        &.grips {
            position: absolute;
            right: 0;
            top: 50px;
        }
    }

    &.product-wrapper {
        .attributes{
            .attribute-option {
                &.color-swatches {
                    margin-bottom: $spacer;
                }
            }
        }
    }
        
    .buy-with-prime-prime-day-deal-badge-sm-container-card {
        align-items: center;
    }

    .buy-with-prime-prime-day-deal-badge-sm {
        height: 24px;
        width: auto;
        padding: 0 5px;
    }

    button.notify-me-btn {
        font-family: $button-add-to-cart-font;
        padding: $button-add-to-cart-padding-top $button-add-to-cart-padding-right $button-add-to-cart-padding-bottom $button-add-to-cart-padding-left;
        &:after {
            background-color: $primary-black;
        }

        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            text-decoration: $button-add-to-cart-text-decoration-hover;
            &:after {
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                top: -4px;
                left: -4px;
            }
        }
        .dark-theme & {
            color: $white;
            &:after {
                background-color: $primary-black;
            }
            &:hover {
                color: $primary-black;
            }
        }
        .icon-mail {
            background: url(../images/icons/email-icon.svg) no-repeat center;
            background-size: contain;
            display: inline-block;
            height: 21px;
            width: 22px;
            vertical-align: text-bottom;
            margin-right: 4px;
        }
    }
    button.notify-me-btn.shortenFontSize {
        @include media-breakpoint-down(md) {
            font-size: 12px;
            padding: $spacer 12px;
            .icon-mail {
                height: $spacer;
                width: $spacer;
                margin-right: 2px;
            }
        }
        @include media-breakpoint-down(sm) {
            font-size: $body-copy-xs-font-size;
            padding:  $button-add-to-cart-padding-top $button-add-to-cart-padding-right $button-add-to-cart-padding-bottom $button-add-to-cart-padding-left;
            .icon-mail {
                height: 18px;
                width: 18px;
                margin-right: 3px;
            }
        }
    }
    .enableStickyV1 {
        #a2c-version-2,
        #a2c-version-3 {
            .prices,
            .sticky-prod-detail {
                display: none;
            }
            .cart-and-ipay {
                width: 100%;
                float: none;
            }
        }
    }
}
.pdp-assets {
    margin: $spacer*4 0;
    overflow: hidden;
    .hero {
        &.text-over-image-or-solid-background.short.align-items-center {
            align-items: normal!important;
        }
        &.short {
            min-height: 0;
        }
        .hero-container {
            @include media-breakpoint-down(md) {
                display: block;
                padding-top: 0;
                padding-right: 0; 
                padding-bottom: 0;
                .hero-buttons {
                    padding-bottom: $spacer*4;
                }
            }
        }
    }
    .experience-main .experience-commerce_assets-assetInclude {
        margin-top: 64px;
        @include media-breakpoint-up(md) {
            margin-top: 64px;
        }
    }
    @include media-breakpoint-up(sm) {
        margin: $spacer*6 0;
    }
    .hero-media {
        &.video-container {
            @include media-breakpoint-down(md) {
                height: auto;
                min-height: 210px;
                z-index: 0;
            }
        }
    }
}

.hide-oos-variants {
    .swatch-tile-container {
        &.unselectable {
            display: none;
        }
    }

    .color-swatches {
        .unselectable-btn {
            display: none;
        }
    }
}

.main-content-group {
    .main-content-item {
        padding: 0;
        margin: 0;
        .collapsible-content-group {
            &.card {
                .card-header {
                    background-color: $tab-background;
                    .card-title {
                        margin-bottom: $spacer/8;
                    }
                }
            }
        }
    }

    &.product-has-thumbnails {
        @include media-breakpoint-up(lg) {
            padding-left: 0;
            background: none;
        }
    }

    .card {
        border-radius: 0;

        .card-header {
            .title {
                @include typography(10);
            }
        }

        .card-body {
            margin: 0;
            padding: 0;
        }

        li {
            margin-bottom: $spacer * 1.5;
        }
    }

    .product-attributes {
        .attribute-name {
            @include typography(8);
        }
    }
}

.addtocart-sticky-bar {
    box-shadow: none;
    border-bottom: $header-navbar-border-bottom-width solid $header-navbar-border-bottom-color;

    .cart-and-ipay {
        .add-to-cart.btn.btn-primary {
            font-size: $button-lg-font-size;
            line-height: $button-lg-line-height;
            padding: $button-lg-padding-top $button-lg-padding-right $button-lg-padding-bottom $button-lg-padding-left;
            width: 100%;
            letter-spacing: $button-lg-letter-spacing;
            min-height: calc(#{$button-lg-line-height} + #{$button-lg-padding-top} + #{$button-lg-padding-bottom} + #{$button-border-width * 2});
            color: $white;

            &:after {
                background-color: $primary-black;
            }
        }
    }
}

.cart-and-ipay {
    .add-to-cart,
    .btn-add-to-cart {
        color: $white;

        &:after {
            background-color: $primary-black;
        }
    }
}

.product-data {
    .btn-customize {
        padding: $spacer*0.9375 $spacer*2;
        &::after {
            position: relative;
            left: 6px;
            top: 4px;
            transform: none;
            right: auto;
        }
    }
    .product-tile {
        .btn-customize {
            padding: $spacer*0.5 $spacer*0.5;

            @include media-breakpoint-down(sm) {
                padding: $spacer*0.5 $spacer*0.35;
            }

            &::after {
                left: 1px;
                @include media-breakpoint-up (lg) {
                    left: 6px;
                }
            }
        }
    }
}

.grid-tile-add-on {
    padding: $spacer * 0.75 0;
    .custom-checkbox.pdp-add-on-checkbox {
        padding-left: 0;
        padding-right: $product-refinement-horizontal-onedropdown-btn-padding-left;
        .custom-control-input {
            z-index: 1;
            opacity: 0;
        }
        .custom-control-label,
        .custom-control-input {
            height: $spacer * 1.5;
            width: $spacer * 1.5;

            @include media-breakpoint-down(sm) {
                height: $filter-radiocheck-button-size;
                width: $filter-radiocheck-button-size;
            }
        }
        .custom-control-label:after,
        .custom-control-label:before {
            height: $spacer * 1.5;
            width: $spacer * 1.5;
            left: 0;
            @include media-breakpoint-down(sm) {
                height: $filter-radiocheck-button-size;
                width: $filter-radiocheck-button-size;
            }
        }
        .custom-control-input:checked ~ .custom-control-label:after {
            @include media-breakpoint-down(sm) {
                font-size: $body-copy-xs-font-size;
                line-height: 21px;
            }
        }
    }
    .pdp-add-on-checkbox {
        min-height: 1.5rem;
    }
    .product-tile,
    .customisable-add-on-product-pdp {
        .container-1 {
            .badge-product-container {
                display: none;
            }
        }
        .container-2 {
            flex-basis: 75% !important;
            .device-swatches {
                select.form-control {
                    font-size: $body-copy-xs-font-size;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .container-1 {
                flex-basis: 110px !important;
            }
            .container-2 {
                max-width: 65%;
                .pdp-link {
                    .product-name-wrapper {
                        font-size: $body-copy-xs-font-size;
                    }
                }
                .product-tile--buy-container {
                    padding: 0;
                    .price {
                        padding: 0;
                        justify-content: left;
                        font-size: $body-copy-xs-font-size;
                    }
                }
            }
        }
        @include media-breakpoint-down(sd) {
            .container-1 {
                flex-basis: $spacer * 5 !important;
            }
            .container-2 {
                max-width: 65%;
                .pdp-link {
                    .product-name-wrapper {
                        font-size: $body-copy-xs-font-size;
                    }
                }
                .product-tile--buy-container {
                    padding: 0;
                    .price {
                        padding: 0;
                        justify-content: left;
                        font-size: $body-copy-xs-font-size;
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            .container-1 {
                flex-basis: $spacer * 5 !important;
            }
            .container-2 {
                max-width: 65%;
                .pdp-link {
                    .product-name-wrapper {
                        font-size: $body-copy-xs-font-size;
                    }
                }
                .product-tile--buy-container {
                    padding: 0;
                    .price {
                        padding: 0;
                        justify-content: left;
                        font-size: $body-copy-xs-font-size;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm) {
            .container-1 {
                flex-basis: $spacer * 6 !important;
            }
            .container-2 {
                .pdp-link {
                    .product-name-wrapper {
                        font-size: $body-copy-xs-font-size;
                    }
                }
                .product-tile--buy-container {
                    padding: 0;
                    .price {
                        justify-content: left;
                        font-size: $body-copy-xs-font-size;
                        padding: 0;
                        .price-section {
                            display: flex;
                            gap: $spacer;
                        }
                    }
                }
            }
        }
    }
}

.sticky-a2c-section {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 99;

    .sticky-wrap {
        overflow: hidden;
        padding: $spacer;
        background-color: $white;
        box-shadow: 0 4px 40px 0 rgba($black, .20);
        display: none;
    }

    #a2c-version-2 {
        .prices {
            float: left;
            padding-right: $spacer/2;
            margin: 0;
            padding-top: $spacer;
            display: flex;
            @include typography(8);

            .price {
                @include typography(8);
                padding-left: 4px;
            }
        }

        .cart-and-ipay {
            width: 213px;
            float: right;
        }
    }

    #a2c-version-3 {
        .cart-and-ipay {
            .add-to-cart {
                max-width: 130px;
                padding: $spacer;
            }
        }
    }

    .sticky-content-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sticky-prod-detail {
        float: left;
        display: flex;
        align-items: center;
        max-width: 60%;

        .prod-image {
            float: left;
            width: 80px;

            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }

        .info-prod-area {
            overflow: hidden;

            .prod-name {
                margin: 0;
                @include typography(12);
            }

            .prices {
                padding: 0;
                margin: 0;

                .price {
                    @include typography(13);
                }
            }
        }
    }
}

.product-detail-attributes {
    .product-addons {
        .product {
            .product-tile {
                &:hover .quick-add-to-cart {
                    display: none;
                }

                .quick-add-to-cart {
                    display: none;
                    margin: 0;
                }

                .tile-body-footer {
                    .pt-2.pl-0.col-12 {
                        padding: 0;
                    }
                }

                .bwp-card-prime-day-deal-badge-container {
                    justify-content: end;
                    flex-direction: column;
                    align-items: end;
                }
            }
        }
    }
}

.chip-badges-area {
    white-space: nowrap;
    text-align: left;
    overflow-x: auto;
    display: flex;
    margin: -5px -16px 8px 0;
    padding: 0 0 8px;

    &::after {
        display: block;
        clear: both;
        content: " ";
    }

    &::-webkit-scrollbar {
        height: 1px;
    }

    &::-webkit-scrollbar-track {
        background: $white;
        border: 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $white;
        border-radius: 0;
    }

    @include media-breakpoint-up (md) {
        margin: -5px 0 8px;
    }

    .chip-badge {
        margin: 0 0 4px 0;

        &:last-child {
            margin: 0 10px 4px 0;
        }

        a {
            border-radius: 20px;
            padding: 6px 12px 5px;
            font-size: 12px;
            line-height: 16px;
            min-height: 28px;
            font-weight: 600;
            text-decoration: none;
            position: relative;
            margin: 2px;
            display: inline-block;
            vertical-align: middle;
            @include icon($icon-icon-up-right, after);
            transition: all 200ms ease-out;

            &::after {
                font-size: 8px;
                padding-left: 2px;
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                margin: 0;
                padding: 8px 14px 7px;
                transition: all 200ms ease-out;
            }
        }
    }
}

.color-swatches {
    .d-lg-block {
        @include media-breakpoint-down (md) {
            padding-bottom: $spacer;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
}