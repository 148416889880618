.category-tile {
    display: block;
    position: relative;
    text-align: center;
    text-decoration: none;
    padding: $category-tile-padding;
    border-radius: $category-tile-border-radius;
    background-color: $category-tile-background-color;
    &:hover {
        text-decoration: none;
    }
    &.zoom {
        @include zoom-image('.image-wrapper', $category-tile-hover-zoom-amount);
    }
    .image-cropper {
        border-radius: $category-tile-border-radius;
        overflow: hidden;
        margin: auto;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        position: relative;

        &.overlay {
            &::after {
                @include absolute-cover;
                background-color: rgba($color: black, $alpha: 0.1);
                content: " ";
            }
        }
    }
    .image-wrapper {
        width: 100%;
        position: relative;
        .background-image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
            @include absolute-cover;
        }
        &-custom {
            .background-image-tablet,
            .background-image-desktop {
                display: none;
            }
            @include media-breakpoint-up(md) {
                .background-image-mobile,
                .background-image-desktop {
                    display: none;
                }
                .background-image-tablet {
                    display: block;
                }
            }
            @include media-breakpoint-up(lg) {
                .background-image-mobile,
                .background-image-tablet {
                    display: none;
                }
                .background-image-desktop {
                    display: block;
                }
            }
        }
        .video-container {
            .video-aspect-ratio {
                @include absolute-center;
                padding-top: $category-tile-video-aspect-ratio;
                padding-left: $category-tile-video-horizontal-aspect-ratio;
                width: 0;
                height: 0;
                min-height: 100%;
                min-width: 100%;
                .video-player {
                    position: static;
                    pointer-events: none;
                }
                iframe {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    &.video-player {
                        position: absolute; // override for youtube, since they use .video-player for iframe
                    }
                }
            }
        }
    }
    .image-text-block-text {
        .primary-text {
            &:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
                font-size: $category-tile-primary-font-size;
            }
        }
        .secondary-text {
            &:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
                color: orange;
                font-size: $category-tile-secondary-font-size;
            }
        }
    }
    &.text-placement-below {
        .category-tile-text {
            margin-top: $spacer;
            color: $category-tile-text-below-color;
            font-family: $category-tile-text-below-font-family;
            font-size: $category-tile-text-below-font-size;
        }
    }
    &.text-placement-inside {
        &::after {
            content: '';
            @include absolute-cover;
            background-color: $category-tile-text-background;
            border-radius: $category-tile-border-radius;
        }
        .category-tile-text {
            @include absolute-center;
            color: $category-tile-text-inside-color;
            z-index: 1;
            text-shadow: $category-tile-text-shadow;
        }
    }
    &.aspect-ratio-square .image-wrapper {
        @include aspect-ratio($category-tile-aspect-ratio-square);
    }
    &.aspect-ratio-landscape .image-wrapper {
        @include aspect-ratio($category-tile-aspect-ratio-landscape);
    }
    &.aspect-ratio-portrait .image-wrapper {
        @include aspect-ratio($category-tile-aspect-ratio-portrait);
    }
    &.aspect-ratio-portrait.rounded-corners {
        .image-wrapper .video-container .video-aspect-ratio iframe {
            height: 115%;
        }
    }
    .mobile-3r-1c &.aspect-ratio-portrait.rounded-corners {
        .image-wrapper .video-container .video-aspect-ratio iframe {
            height: 135%;
        }
    }

    &.top-content-style {
        padding: $category-tile-padding*2;

        .tile-top-heading {
            margin-bottom: $spacer/2;
        }

        .tile-top-subheading {
            margin-bottom: $spacer*2;
        }

        .top-cta {
            margin-bottom: $spacer*4;
        }

        .category-tile-text {
            display: none;
        }
    }

    &.bottom-cta-layout {
        @include media-breakpoint-up (lg) {
            padding: $spacer $spacer $spacer*2;
        }

        .image-wrapper {
            max-width: 150px;
            margin: 0 auto;
            @include media-breakpoint-up (md) {
                max-width: 180px;
            }
        }

        .category-tile-text {
            margin-top: 0;
            margin-bottom: $spacer;
            color: $primary-black;
            .primary-text {
                @include media-breakpoint-down (md) {
                    font-size: 20px;
                }
            }
        }
    }
}

