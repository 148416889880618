//Extend this file from rvw_autobhan_core for PAP-1186 to change price color
@import "~core/components/pricing";

.product-detail,
.quick-view-dialog {
    .price {
        color: $pdp-price-color;
        .strike-through {
            text-decoration: line-through;
            color: $product-strike-through-price-color;
        }
        .sale-price {
            color: $product-sale-price;
        }
    }
}