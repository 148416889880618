/*
* ICON SETUP INSTRUCTIONS
* 1) To edit Icomoon fonts, import the /fonts/icomoon/selection.json file at this URL: https://icomoon.io/app/#/select
* 2) After making changes and generating the font, replace everything in /fonts/icomoon with the downloaded files and folders
* 3) Copy the font files from /fonts/icomoon/fonts to /static/fonts/
* 4) Copy over the contents of the style.scss and variables.scss files per instructions below
* 5) Update the icons listed in /templates/default/styleguide/icons/availableIcons.isml to account for any changes
*/

// Not copied from any icon set, do not overwrite
$icon-content-blank: "";
// Not copied from any icon set, do not overwrite

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?skc9fc');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?skc9fc#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?skc9fc') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?skc9fc') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?skc9fc##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"]:not(.btn),
[class*=" icon-"]:not(.btn) {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*
* Copy line 4 down from fonts/icomoon/variables.scss
*/
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-icon-up-right: "\e903";
$icon-square-x-twitter: "\e908";
$icon-star-empty: "\e937";
$icon-star-half: "\e938";
$icon-star: "\e939";
$icon-account: "\e935";
$icon-arrow-left-thin: "\e92b";
$icon-arrow-right-thin: "\e930";
$icon-badge: "\e902";
$icon-bubble-tail: "\e900";
$icon-cart: "\e91e";
$icon-checkmark: "\e93e";
$icon-close: "\e93f";
$icon-collapse: "\e940";
$icon-expand: "\e941";
$icon-facebook: "\e942";
$icon-favorite: "\e943";
$icon-favorite-selected: "\e944";
$icon-filter: "\e945";
$icon-instagram: "\e946";
$icon-logo: "\e947";
$icon-pinterest: "\e906";
$icon-play: "\e918";
$icon-search: "\e919";
$icon-select: "\e901";
$icon-tiktok: "\e920";
$icon-twitter: "\e926";
$icon-youtube: "\e92a";
$icon-menu: "\e929";
$icon-images: "\e905";
$icon-alert: "\e904";
$icon-arrow-down: "\e907";
$icon-arrow-left: "\e909";
$icon-arrow-right: "\e90b";
$icon-arrow-top: "\e90c";
$icon-arrow-up: "\e90d";
$icon-call: "\e90e";
$icon-close-small: "\e911";
$icon-edit: "\e914";
$icon-email: "\e915";
$icon-film: "\e91a";
$icon-google: "\e91b";
$icon-guarantee: "\e91c";
$icon-image: "\e91d";
$icon-info: "\e921";
$icon-link: "\e923";
$icon-linkedin: "\e924";
$icon-livechat: "\e925";
$icon-location: "\e927";
$icon-lock: "\e928";
$icon-minus: "\e92c";
$icon-offsite: "\e92d";
$icon-pause: "\e92e";
$icon-plus: "\e931";
$icon-print: "\e932";
$icon-question: "\e933";
$icon-remove: "\e934";
$icon-size: "\e936";
$icon-zoom-out: "\e93c";
$icon-zoom: "\e93d";


/*
* Copy line 30 down from from fonts/icomoon/style.scss
*/

.icon-icon-up-right {
  :before {
    content: $icon-icon-up-right;
  }
}
.icon-square-x-twitter {
  &:before {
    content: $icon-square-x-twitter;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty; 
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-arrow-left-thin {
  &:before {
    content: $icon-arrow-left-thin; 
  }
}
.icon-arrow-right-thin {
  &:before {
    content: $icon-arrow-right-thin; 
  }
}
.icon-badge {
  &:before {
    content: $icon-badge; 
  }
}
.icon-bubble-tail {
  &:before {
    content: $icon-bubble-tail; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-collapse {
  &:before {
    content: $icon-collapse; 
  }
}
.icon-expand {
  &:before {
    content: $icon-expand; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-favorite {
  &:before {
    content: $icon-favorite; 
  }
}
.icon-favorite-selected {
  &:before {
    content: $icon-favorite-selected; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-logo {
  &:before {
    content: $icon-logo; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-select {
  &:before {
    content: $icon-select; 
  }
}
.icon-tiktok {
  &:before {
    content: $icon-tiktok; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-images {
  &:before {
    content: $icon-images; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-top {
  &:before {
    content: $icon-arrow-top; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-call {
  &:before {
    content: $icon-call; 
  }
}
.icon-close-small {
  &:before {
    content: $icon-close-small; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-film {
  &:before {
    content: $icon-film; 
  }
}
.icon-google {
  &:before {
    content: $icon-google; 
  }
}
.icon-guarantee {
  &:before {
    content: $icon-guarantee; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-livechat {
  &:before {
    content: $icon-livechat; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-offsite {
  &:before {
    content: $icon-offsite; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-size {
  &:before {
    content: $icon-size; 
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; 
  }
}
.icon-zoom {
  &:before {
    content: $icon-zoom; 
  }
}


// Font Awesome helper class carryover
.fa {
    display: inline-block;
    font-weight: normal;
    font-style: normal;
    text-rendering: auto;
    font-size: inherit;
}
.fa-stack {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: inherit;
}
