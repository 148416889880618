.slider-title {
    margin: 0;
    text-align: $slider-heading-text-align;
    // allow custom page designer classes to override variable
    &:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
        @include typography($slider-heading-size);
    }
}
.slider-container {
    position: relative;
    padding-left: $slider-button-width;
    padding-right: $slider-button-width;
    letter-spacing: 0; // this prevents sizing issues caused by inherited letter-spacing
    &:not(.slider-bullets-inside):not(.slider-bullets-hidden) {
        margin-bottom: $bullets-outside-size;
    }
    &.slider-thumbnails {
        .slider {
            &.tns-slider.tns-carousel {
                .slide.tns-item {
                    padding: 0;
                    @include media-breakpoint-up(md) {
                        padding: $spacer $spacer/2;
                    }
                }
            }
        }
    }
    .slider {
        display: flex;

        .slide {
            display: none;
        }

        &.tns-slider {
            opacity: 1;
            .slide {
                display: block;
            }
        }
        &.tns-gallery .tns-slide-active {
            z-index: 1;
        }
    }
    .slide {
        transform: translateZ(0); // fix for text flicker bug in chrome
    }

    // slider nav buttons
    .tns-controls button {
        @include vertical-center;
        cursor: pointer;
        user-select: none;
        z-index: 2;
        width: $slider-button-width;
        background-color: $slider-button-background;
        border: none;
        display: flex;
        text-align: center;
        transition: background-color $transition-speed ease-out;
        font-size: 0;
        padding: 0;
        height: $slider-button-width;
        border-radius: 100%;
        border: 1px solid transparent;
        box-shadow: $slider-button-shadow;
        &[data-controls='prev'] {
            @include icon($slider-button-prev-icon, before, $slider-button-icon-size, $slider-button-color);
            left: -$spacer*1.5;
            &:before{
                margin-left: -2px;
            }
        }
        &[data-controls='next'] {
            @include icon($slider-button-next-icon, before, $slider-button-icon-size, $slider-button-color);
            right: -$spacer*1.5;
            &:before{
                margin-left: 2px;
            }
        }
        &:before {
            width: 100%;
            align-self: center;
            transition: color $transition-speed ease-out;
            color: $brand-primary;
        }
        &:hover {
            background-color: $white;
            border-color: $primary-black;
            &:before {
                color: $slider-button-color-hover;
            }
        }
        &:disabled {
            opacity: 0;
        }
    }
    
    // slider bullets
    .tns-nav {
        @include horizontal-center;
        display: flex;
        bottom: 0;
        z-index: 2;

        button {
            display: block;
            background: $slider-bullet-background;
            border: $slider-bullet-border;
            width: $slider-bullet-size;
            height: $slider-bullet-size;
            margin: $slider-bullet-margin-top $slider-bullet-margin-right $slider-bullet-margin-bottom $slider-bullet-margin-left;
            padding: 0;
            cursor: pointer;
            transition: transform $transition-speed ease-out;
            &.tns-nav-active {
                background: $slider-bullet-background-active;
                border: $slider-bullet-border-active;
                transform: scale(#{$slider-bullet-size-active / $slider-bullet-size});
            }
        }

        &.with-pagination-btn {
            button {
                order: 1;
            }

            .with-pagination {
                order: 0;
            }
        }

    }

    &.pagination-style-dots {
        .tns-nav {
            align-items: center;

            button {
                border-radius: 50%;
            }
        }
    }

    &.pagination-style-shortLines {
        .tns-nav {
            align-items: flex-end;

            button {
                background-color: $slider-short-lines-bullet-background-color;
                border: none;
                width: $slider-short-lines-bullet-size-width;
                height: $slider-short-lines-bullet-size-height;

                &.tns-nav-active,
                &:hover:not(.tns-nav-active) {
                    background-color: $slider-short-lines-bullet-active-background-color;
                    height: $slider-short-lines-bullet-active-size-height;
                    transition: background-color $transition-speed ease-in-out, height $transition-speed ease-in-out;
                }
            }
        }
    }

    &.pagination-style-newSliderDots {
        .tns-nav {
            @include horizontal-center;
            display: flex;
            bottom: -$spacer*3.5;
            z-index: 2;
            @include media-breakpoint-up (lg) {
                bottom: -$spacer*4.5;
            }
    
            button {
                position: relative;
                display: block;
                background: transparent;
                border: transparent;
                width: $spacer*1.5;
                height: $spacer*1.5;
                border-radius: 50%;
                margin: $slider-bullet-margin-top 0 $slider-bullet-margin-bottom 0;
                padding: 0;
                cursor: pointer;
                transition: transform $transition-speed ease-out;
                &::before {
                    content: '';
                    background: $primary-black;
                    height: $spacer*0.375;
                    width: $spacer*0.375;
                    border-radius: 50%;
                    @include absolute-center;
                    @include media-breakpoint-up (lg) {
                        height: $spacer*0.5;
                        width: $spacer*0.5;
                    }
                }
                &.tns-nav-active {
                    background: transparent;
                    border: 1px solid $primary-black;
                    transform: none;
                    &::before {
                        content: '';
                        background: $primary-black;
                        height: $spacer*0.625;
                        width: $spacer*0.625;
                        border-radius: 50%;
                        @include absolute-center;
                        @include media-breakpoint-up (lg) {
                            height: $spacer*0.5;
                            width: $spacer*0.5;
                        }
                    }
                }
            }
    
            &.with-pagination-btn {
                button {
                    order: 1;
                }
    
                .with-pagination {
                    order: 0;
                }
            }
    
        }
    }

    &.control-style-newSliderControl {
        padding-left: $spacer*3.25;
        padding-right: $spacer*3.25;
        @include media-breakpoint-up (lg) {
            padding-left: $spacer*8.75;
            padding-right: $spacer*8.75; 
        }
        .tns-controls button {
            @include vertical-center;
            cursor: pointer;
            user-select: none;
            z-index: 2;
            width: $spacer*3.1875;
            background-color: transparent;
            border: none;
            display: flex;
            text-align: center;
            transition: background-color $transition-speed ease-out;
            font-size: 0;
            padding: 0;
            height: $spacer*2.625;
            border-radius: 0;
            box-shadow: none;
            @include media-breakpoint-up (lg) {
                width: $spacer*8.75;
                height: $spacer*7.375;
            }
            &[data-controls='prev'] {
                left: 0;
                &:before{
                    margin-left: 0;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjMTgxODE4Ii8+Cjwvc3ZnPgo=);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                    font-size: 0;
                    height: 100%;
                    width: 100%;
                    transform: rotate(180deg);
                    
                }
            }
            &[data-controls='next'] {
                right: 0;
                &:before{
                    margin-left: 0;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjMTgxODE4Ii8+Cjwvc3ZnPgo=);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                    font-size: 0;
                    height: 100%;
                    width: 100%;
                }
            }
            &:before {
                width: 100%;
                align-self: center;
                transition: color $transition-speed ease-out;
                color: $brand-primary;
            }
            &:hover {
                background-color: transparent;
                &:before {
                    color: $slider-button-color-hover;
                }
            }
            &:disabled {
                opacity: 0;
            }
        }
    }
    // themes
    &.light {
        .tns-controls button {
            background-color: $slider-light-button-background;
            &::before {
                color: $slider-light-button-color;
            }
            &:hover {
                background-color: $slider-light-button-background-hover;
                &::before {
                    color: $slider-light-button-color-hover;
                }
            }
        }
        .tns-nav {
            button {
                background: $slider-light-bullet-background;
                border: $slider-light-bullet-border;
                &.tns-nav-active  {
                    background: $slider-light-bullet-background-active;
                    border: $slider-light-bullet-border-active;
                }
            }
        }
        .tns-nav-controller {
            &::before {
                color: $slider-light-bullet-play-icon-color;
            }
            &.pause::before {
                color: $slider-light-bullet-pause-icon-color;
            }
        }
        &.pagination-style-shortLines {
            .tns-nav {
                button {
                    background-color: $slider-light-short-lines-bullet-background-color;
                    &.tns-nav-active,
                    &:hover:not(.tns-nav-active) {
                        background-color: $slider-light-short-lines-bullet-active-background-color;
                    }
                }
            }
        }
        &.pagination-style-newSliderDots {
            .tns-nav {
                button {
                    background: transparent;
                    &::before {
                        content: '';
                        background: $slider-light-bullet-background;
                        height: $spacer*0.5;
                        width: $spacer*0.5;
                        border-radius: 50%;
                        @include absolute-center;
                    }
                    &.tns-nav-active {
                        background: transparent;
                        border: 1px solid $slider-light-bullet-background;
                        transform: none;
                    }
                }
        
            }
        }
        &.control-style-newSliderControl {
            .tns-controls button {
                background: transparent;
                &[data-controls='prev'] {
                    left: 0;
                    &:before{
                        margin-left: 0;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjZmZmZmZmYjMiLz4KPC9zdmc+Cg==);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        font-size: 0;
                        height: 100%;
                        width: 100%;
                        transform: rotate(180deg);
                        
                    }
                }
                &[data-controls='next'] {
                    right: 0;
                    &:before{
                        margin-left: 0;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjZmZmZmZmYjMiLz4KPC9zdmc+Cg==);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        font-size: 0;
                        height: 100%;
                        width: 100%;
                    }
                }
                &:before {
                    width: 100%;
                    align-self: center;
                    transition: color $transition-speed ease-out;
                    color: $brand-primary;
                }
            }
        }
    }

    [data-action='stop'],
    [data-action='start'] {
        display: none;
    }

    // style helper classes
    @include media-breakpoint-down(sm) {
        &.slider-buttons-hidden {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden {
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside {
            padding-left: 0;
            padding-right: 0;
            &.control-style-newSliderControl {
                .tns-controls button {
                    &[data-controls='prev'] {
                        left: $spacer;
                    }
                    &[data-controls='next'] {
                        right: $spacer;
                    }
                }
            }
        }
        &.slider-bullets-inside {
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
                &.slider-buttons-hidden {
                    margin-right: -$spacer;
                }
            }
            &.prev-slide-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
                &.slider-buttons-hidden {
                    margin-left: -$spacer;
                }
            }
        }
    }
    @include media-breakpoint-only(md) {
        &.slider-buttons-hidden-md {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden-md {
            margin-bottom: 0;
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside-md {
            padding-left: 0;
            padding-right: 0;
            [data-controls='prev'] {
                left: -$spacer*.8;
            }
            [data-controls='next'] {
                right: -$spacer*.8;
            }
            &.control-style-newSliderControl {
                .tns-controls button {
                    &[data-controls='prev'] {
                        left: $spacer;
                    }
                    &[data-controls='next'] {
                        right: $spacer;
                    }
                }
            }
        }
        &.slider-bullets-inside-md {
            margin-bottom: 0;
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-md-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
            }
            &.prev-slide-md-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &.slider-buttons-hidden-lg {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden-lg {
            margin-bottom: 0;
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside-lg {
            padding-left: 0;
            padding-right: 0;
            [data-controls='prev'] {
                left: -$spacer*.8;
            }
            [data-controls='next'] {
                right: -$spacer*.8;
            }
            &.control-style-newSliderControl {
                .tns-controls button {
                    &[data-controls='prev'] {
                        left: $spacer*2;
                    }
                    &[data-controls='next'] {
                        right: $spacer*2;
                    }
                }
            }
        }
        &.slider-bullets-inside-lg {
            margin-bottom: 0;
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-lg-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
            }
            &.prev-slide-lg-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
            }
        }
    }

    &.scroll-slider-active {
        @include media-breakpoint-down (sm) {
            margin-left: 0;
            margin-right: 0;
        }

        .slider {
            @include media-breakpoint-down (sm) {
                display: flex;
                flex-wrap: nowrap;
                overflow-y: auto;
                padding-left: 10px;
                padding-right: 10px;
                margin: 0 -20px 5px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .slide {
            @include media-breakpoint-down (sm) {
                transform: none;
                flex: 0 0 calc(75% - 20px);
                padding: 0 10px;
                display: block;
            }

            .image-text-block {
                &.text-placement-inside {
                    height: 100%;

                    .image-cropper {
                        height: 100%;
                        &.overlay {
                            &::after {
                                z-index: 2;
                            }
                        }
                    }

                    .image-wrapper {
                        height: 100%;
                    }
                }

                .video-container {
                    z-index: 2;
                    overflow: hidden;
                    border-radius: 20px;
                }
            }
        }

        .simplebar-content {
            display: flex;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .simplebar-track {
            &.simplebar-horizontal {
                background-color: $medium-gray;
                border-radius: 8px;
                height: 4px;
                left: 1px;
                right: 1px;

                .simplebar-scrollbar {
                    height: 4px;
                    top: 0;
                }
            }
        }

        &[data-slider-items="1"] {
            .slide {
                @include media-breakpoint-down (sm) {
                    flex: 0 0 100%;
                }
            }

            &.next-slide-13 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 90%;
                    }

                    &:last-child {
                        @include media-breakpoint-down (sm) {
                            margin-right: $spacer;
                        }
                    }
                }
            }

            &.next-slide-23 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 85%;
                    }

                    &:last-child {
                        @include media-breakpoint-down (sm) {
                            margin-right: $spacer;
                        }
                    }
                }
            }

            &.next-slide-33 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 80%;
                    }

                    &:last-child {
                        @include media-breakpoint-down (sm) {
                            margin-right: $spacer;
                        }
                    }
                }
            }

            &.next-slide-40 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 75%;
                    }
                }
            }
        }

        &[data-slider-items="2"] {
            .slide {
                @include media-breakpoint-down (sm) {
                    flex: 0 0 calc(55% - 20px);
                }
            }

            &.next-slide-8 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(52.5% - 20px);
                    }

                    &:last-child {
                        @include media-breakpoint-down (sm) {
                            margin-right: $spacer;
                        }
                    }
                }
            }

            &.next-slide-12 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(50% - 20px);
                    }

                    &:last-child {
                        @include media-breakpoint-down (sm) {
                            margin-right: $spacer;
                        }
                    }
                }
            }

            &.next-slide-17 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(47.5% - 20px);
                    }

                    &:last-child {
                        @include media-breakpoint-down (sm) {
                            margin-right: $spacer;
                        }
                    }
                }
            }

            &.next-slide-22 {
                .slide {
                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(45% - 20px);
                    }
                }
            }
        }

        &[data-slider-items="3"] {
            .slider {
                padding-right: 20px;
            }

            .slide {
                @include media-breakpoint-down (sm) {
                    flex: 0 0 calc(33.33% - 20px);
                }
            }

            &.next-slide-6 {
                .slide {
                    .category-tile {
                        @include media-breakpoint-down (sm) {
                            padding: $spacer $spacer*0.875;
                        }
                    }

                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(32.32% - 20px);
                    }
                }
            }

            &.next-slide-9 {
                .slide {
                    .category-tile {
                        @include media-breakpoint-down (sm) {
                            padding: $spacer $spacer*0.75;
                        }
                    }

                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(31.31% - 20px);
                    }
                }
            }

            &.next-slide-12 {
                .slide {
                    .category-tile {
                        @include media-breakpoint-down (sm) {
                            padding: $spacer $spacer*0.625;
                        }
                    }

                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(30.30% - 20px);
                    }
                }
            }

            &.next-slide-15 {
                .slide {
                    .category-tile {
                        @include media-breakpoint-down (sm) {
                            padding: $spacer $spacer*0.5;
                        }
                    }

                    @include media-breakpoint-down (sm) {
                        flex: 0 0 calc(29.29% - 20px);
                    }
                }
            }
        }
    }

    &.scroll-slider-active {
        &.next-slide-40.slider-buttons-hidden {
            @include media-breakpoint-down (sm) {
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.next-slide-22.slider-buttons-hidden {
            @include media-breakpoint-down (sm) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    // Dark theme
    &.dark {
        .tns-nav-controller {
            cursor: pointer;
            @include icon($video-pause-icon, before, $slider-bullet-controller-size, $slider-bullet-play-icon-color-dark);
            &.pause {
                @include icon($video-play-icon, before, $slider-bullet-controller-size, $slider-bullet-pause-icon-color-dark);
            }

            &:focus {
                outline: $outline-focus-width $outline-focus-style $white;
            }
        }
    }

    &.hide-quick-add-to-cart {
        .quick-add-to-cart {
            .js-quick-add-to-cart {
                @include media-breakpoint-down (sm) {
                    display: none;
                }
            }
        }
    }

    .product-tile {
        .product-name-wrapper {
            @include media-breakpoint-down(sm) {
                @include typography(13);
                padding: 0 $spacer*0.5;
            }
        }

        .product-tile--buy-container {
            @include media-breakpoint-down (sm) {
                padding: 0 $spacer*0.5;
            }

            .price {
                @include media-breakpoint-down (sm) {
                    @include typography(12);
                }
            }
        }
    }
}

// Slider Pause/Play Button
.tns-nav-controller {
    cursor: pointer;
    @include icon($video-pause-icon, before, $slider-bullet-controller-size, $slider-bullet-play-icon-color);
    &.pause {
        @include icon($video-play-icon, before, $slider-bullet-controller-size, $slider-bullet-pause-icon-color);
    }

    &:focus {
        outline: $outline-focus-width $outline-focus-style $white;
    }
    &:not(.with-pagination) {
        position: absolute;
        padding: $slider-bullet-controller-padding;
        z-index: 2;
    }
    &.top-right {
        top: $slider-bullet-controller-inset-amount;
        right: $slider-bullet-controller-inset-amount;
        @include media-breakpoint-down(sm) {
            top: $slider-bullet-top-controller-inset-amount;
            transform: $slider-bullet-translate-controller-inset-amount;
        }
    }
    &.top-left {
        top: $slider-bullet-controller-inset-amount;
        left: $slider-bullet-controller-inset-amount;
    }
    &.bottom-right {
        bottom: $slider-bullet-controller-inset-amount;
        right: $slider-bullet-controller-inset-amount;
    }
    &.bottom-left {
        bottom: $slider-bullet-controller-inset-amount;
        left: $slider-bullet-controller-inset-amount;
    }

    &.with-pagination {
        padding-right: $slider-bullet-with-pagination-padding-right;
    }
}

// Thumbnails
.slider-thumbnails {
    padding-left: $slider-thumbnail-button-icon-size + $spacer;
    padding-right: $slider-thumbnail-button-icon-size + $spacer;
    user-select: none;
    .slider {
        overflow: auto;
        text-align: center;
        padding-bottom: 50px;
        margin-bottom: -50px;
        display: block;
    }
    .slide {
        min-width: $slider-thumbnail-width;
        max-width: $slider-thumbnail-width;
        flex-basis: $slider-thumbnail-width;
        @include media-breakpoint-up(md) {
            max-width: $slider-thumbnail-width-md;
            flex-basis: $slider-thumbnail-width-md;
            background: $light-gray;
            padding: $spacer * 1 $spacer / 2;
            border-radius: $border-radius;
        }
        @include media-breakpoint-up(lg) {
            min-width: $slider-thumbnail-width-lg;
            max-width: $slider-thumbnail-width-lg;
            flex-basis: $slider-thumbnail-width-lg;
        }
        @include media-breakpoint-only(md) {
            padding-right: $spacer / 2 !important;
            &+.slide {
                margin-top: $slider-thumbnail-spacing;
            }
        }
        &>* {
            width: 100%;
        }
        &::after {
            content: '';
            pointer-events: none;
            @include absolute-cover;
            border-style: solid;
            border-width: $slider-thumbnail-border-width;
            border-color: $slider-thumbnail-border-color;
            transition: border $transition-speed ease-out;
        }
        &.tns-nav-active::after {
            border-width: $slider-thumbnail-border-active-width;
            border-color: $slider-thumbnail-border-active-color;
        }
    }
    
    &.slider-thumbnail-dots {
        .slide {
            @include media-breakpoint-down(sm) {
                min-width: $slider-bullet-size;
                max-width: $slider-bullet-size;
                height: $slider-bullet-size;
                margin: $slider-bullet-margin-top $slider-bullet-margin-right $slider-bullet-margin-bottom $slider-bullet-margin-left;
                &:focus {
                    outline: none;
                }
                &:after {
                    background: $slider-bullet-background;
                    border: $slider-bullet-border;
                    border-radius: $slider-thumbnail-dots-border-radius;
                }
                &.tns-nav-active::after {
                    background: $slider-bullet-background-active;
                    border: $slider-bullet-border-active;
                    transform: scale(#{$slider-bullet-size-active / $slider-bullet-size});
                }
                img {
                    display: none !important; // hides images after js reloads them from a new variant
                }
            }
            .primary-images-thumbnails-dots & {
                min-width: $slider-bullet-size;
                max-width: $slider-bullet-size;
                height: $slider-bullet-size;
                margin: $slider-bullet-margin-top $slider-bullet-margin-right $slider-bullet-margin-bottom $slider-bullet-margin-left;
                &:focus {
                    outline: none;
                }
                &:after {
                    background: $slider-bullet-background;
                    border: $slider-bullet-border;
                    border-radius: $slider-thumbnail-dots-border-radius;
                }
                &.tns-nav-active::after {
                    background: $slider-bullet-background-active;
                    border: $slider-bullet-border-active;
                    transform: scale(#{$slider-bullet-size-active / $slider-bullet-size});
                }
                img {
                    display: none !important; // hides images after js reloads them from a new variant
                }
            }
        }
        .slider-thumbnail-controls {
            display: none;
        }
    }
    .tns-horizontal {
        &>.tns-item {
            vertical-align: middle;
        }
    }
    .slider-thumbnail-controls {
        .prev,
        .next {
            position: absolute;
            top: 0;
            height: 100%;
            width: $slider-thumbnail-button-size;
            cursor: pointer;
            user-select: none;
            background-color: $slider-thumbnail-button-background-color;
            transition: opacity $transition-speed ease-out;
            &::before {
                @include vertical-center;
            }
            &:hover {
                background-color: $slider-thumbnail-button-background-color-hover;
                &::before {
                    color: $slider-thumbnail-button-color-hover;
                }
            }
            &[aria-disabled=true] {
                opacity: 0;
            }
        }
        .prev {
            @include icon($slider-thumbnail-prev-icon, before, $slider-thumbnail-button-icon-size, $slider-thumbnail-button-color);
            left: 0;
        }
        .next {
            @include icon($slider-thumbnail-next-icon, before, $slider-thumbnail-button-icon-size, $slider-thumbnail-button-color);
            right: 0;
        }
    }
    // IE11 overrides: tiny-slider serves IE-specific styles that don't work very well
    .tns-horizontal.tns-no-subpixel {
        white-space: nowrap;
        &>.tns-item {
            float: none;
            display: inline-block;
            margin: 0;
            margin-left: 0 !important;
        }
    }

    // video assets in pdp image gallery
    .video-container {
        .video-thumbnail-button::before {
            font-size: $slider-thumbnail-play-icon-size;
        }
    }

    // Vertical thumbnails: need to override Tiny Slider's injected styles, since they don't support axis changes at breakpoints

    @include media-breakpoint-down(sm) {
        &.slider-thumbnails-vertical {
            @include vertical-slider-thumbnails;
        }
    }
    @include media-breakpoint-only(md) {
        &.slider-thumbnails-vertical-md {
            @include vertical-slider-thumbnails;
        }
        &.slider-thumbnails-vertical-lg {
            @include vertical-slider-thumbnails;
        }
    }
    @include media-breakpoint-up(lg) {
        &.slider-thumbnails-vertical-lg {
            @include vertical-slider-thumbnails;
        }
    }
}

//carousel padding PAP-219      
.experience-commerce_layouts-slider {
    .slider.tns-slider.tns-carousel {
        .slide.tns-item {
            padding-right: $spacer*2!important;
        }
    }
}
//update for btn heights on einstein sliders do not use 
.experience-commerce_layouts-sliderEinstein {
    .slider-container  .tns-controls button {
        top: 33%;
        transform: translateY(-33%);
    }
}

// Product Tile Sliders, Review Sliders - see Helper Classes
//do not put .tab-section in slider, it needs to be in tabs.scss
