.image-text-block {
    display: block;
    position: relative;
    text-align: center;
    text-decoration: none;
    &.rounded-corners {
        overflow: hidden;
        .image-cropper {
            z-index: 2;
        }
    }
    &:hover {
        text-decoration: none;
    }
    &.zoom {
        @include zoom-image('.image-wrapper', $image-text-block-hover-zoom-amount);
    }
    .image-cropper {
        border-radius: $image-text-block-border-radius;
        overflow: hidden;
        margin: auto;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        position: relative;

        &.overlay {
            &::after {
                @include absolute-cover;
                background-color: rgba($color: black, $alpha: 0.1);
                content: " ";
            }
        }
    }
    .image-wrapper {
        width: 100%;
        position: relative;
        .background-image {
            @include absolute-cover;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
        }
        .background-image-tablet,
        .background-image-desktop {
            display: none !important;
        }
        @include media-breakpoint-up(md) {
            .background-image-mobile,
            .background-image-desktop {
                display: none !important;
            }
            .background-image-tablet {
                display: block !important;
            }
        }
        @include media-breakpoint-up(lg) {
            .background-image-mobile,
            .background-image-tablet {
                display: none !important;
            }
            .background-image-desktop {
                display: block !important;
            }
        }
        .video-container {
            .video-aspect-ratio {
                @include absolute-center;
                padding-top: $image-text-block-video-aspect-ratio;
                padding-left: $image-text-block-video-horizontal-aspect-ratio;
                width: 0;
                height: 0;
                min-height: 100%;
                min-width: 100%;
                .video-player {
                    position: static;
                    pointer-events: none;
                }
                iframe {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    &.video-player {
                        position: absolute; // override for youtube, since they use .video-player for iframe
                    }
                }
            }
        }
    }
    &.large,
    &.medium {
        .image-text-block-text {
            .secondary-text + .image-text-block-button {
                padding-top: $spacer;
                @include media-breakpoint-up(md) {
                    padding-top: $spacer*1.5;
                }
            }

        }
    }
    .image-text-block-text {
        .primary-text,
        .secondary-text,
        .image-text-block-subtitle {
            // override styles from page designer injected tags
            &>* {
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-transform: inherit;
                letter-spacing: inherit;
                color: inherit;
                margin-bottom: 0;
            }
            a {
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-transform: inherit;
                letter-spacing: inherit;
                color: inherit;
                margin-bottom: inherit;
                background: inherit;
            }
        }
        .image-text-block-subtitle {
            &:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
                font-size: $image-text-block-subtitle-font-size;
            }
        }
        .primary-text {
            margin: 0;
            padding-bottom: $spacer/2;
        }
        .secondary-text {
            &:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
                @include typography(9);
            }
        }
        .image-text-block-subtitle + .primary-text {
            padding-top: 0;
        }
        .secondary-text + .image-text-block-button {
            padding-top: $spacer;
        }
        .image-text-block-button {
            text-shadow: none;
        }
    }
    &.text-placement-below {
        .image-text-block-text {
            margin-top: $spacer;
        }
    }
    &.text-placement-inside {
        .image-text-block-text {
            @include absolute-center;
            color: $image-text-block-text-inside-color;
            z-index: $z-index-promo-caption;
            text-shadow: $image-text-block-text-shadow;
            top: initial;
            right: initial;
            bottom: $spacer;
            transform: none;
            padding-left: $spacer;
            padding-right: $spacer;
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            @include media-breakpoint-up(md) {
                bottom: $spacer * 1.5;
                padding-left: $spacer * 1.5;
                padding-right: $spacer * 1.5;
            }
        }
        &.large {
            .image-text-block-text {
                bottom: $spacer;
                padding-left: $spacer;
                padding-right: $spacer;
                @include media-breakpoint-up(md) {
                    bottom: $spacer * 4;
                    padding-left: $spacer * 4;
                    padding-right: $spacer * 4;
                }
            }
        }
    }
    &.aspect-ratio-square .image-wrapper {
        @include aspect-ratio($image-text-block-aspect-ratio-square);
    }
    &.aspect-ratio-landscape .image-wrapper {
        @include aspect-ratio($image-text-block-aspect-ratio-landscape);
    }
    &.aspect-ratio-portrait .image-wrapper {
        @include aspect-ratio($image-text-block-aspect-ratio-portrait);
    }
    &.aspect-ratio-portrait.rounded-corners {
        .image-wrapper .video-container .video-aspect-ratio iframe {
            height: 115%;
        }
    }
    &.aspect-ratio-portrait.rounded-corners.product-tile {
        .image-wrapper .video-container .video-aspect-ratio iframe {
            @include media-breakpoint-up(md) {
                height: 125%;
            }
        }
    }
    .mobile-4r-1c &.aspect-ratio-portrait.rounded-corners,
    .mobile-3r-1c &.aspect-ratio-portrait.rounded-corners {
        .image-wrapper .video-container .video-aspect-ratio iframe {
            height: 135%;
        }
    }
}

.photo-tile-container {
    &.plant-section-img {
        max-width:100%;
        img {
            height: auto;
        }
        @include media-breakpoint-up(md) {
            max-width: 346px;
            img {
                height: 264px;
            }
        }
    }
    &.reducing-waste-img {
        max-width:100%;
        img {
            height: auto;
        }
        @include media-breakpoint-up(md) {
            max-width: $spacer * 23.25;
            margin: 0 auto;
            img {
                height: $spacer * 21;
            }
        }
    }
    &.recycling-services {
        max-width: $spacer * 4.5;
        margin: 0 auto;
        img {
            height: $spacer * 5;
        }
        @include media-breakpoint-up(md) {
            max-width: $spacer * 8.06;
            img {
                height: $spacer * 8;
            }
        }
    }
}

.col-md-3 {
    .image-text-block {
        &.text-placement-inside {
            .image-text-block-text {
                @include media-breakpoint-up(md) {
                    bottom: $spacer * 2; // less margin from bottom on 4 column grid
                }
            }
        }
    }
}

.pdp-assets {
    .image-text-block {
        .image-text-block-text {
            .secondary-text {
                &:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
                    @include typography(11);
                }
            }
        }
    }
}

.icon-with-text-layout {
    &.text-placement-below {
        .image-text-block-text {
            margin-top: 0;
            .image-text-block-subtitle {
                margin-bottom: $spacer*0.5;
            }
            br {
                display: none;
            }
        }
    }
    .image-cropper{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacer*1.75 0;
        .image-wrapper {
            width: 178px;
            height: 140px;
            .background-image {
                background-size: contain !important;
            }
        }
    }
}

.review-tile-wrapper {
    .image-cropper {
        display: flex;
        position: relative;
        text-align: center;
        text-decoration: none;
        padding: $spacer*3.75 $spacer*0.5 $spacer*4.625;
        border-radius: $category-tile-border-radius;
        background-color: $category-tile-background-color;
        margin-bottom: $spacer*0.5;
        min-height: 368px;

        @include media-breakpoint-up (lg) {
            padding: $spacer*4.5 $spacer $spacer*3.4375;
            margin-bottom: $spacer;
            min-height: inherit;
            max-height: inherit;
            height: auto;
        }

        .image-wrapper {
            height: 234px;
            width: 230px;
            margin: 0 auto;

            @include media-breakpoint-up (lg) {
                height: 288px;
                width: 280px;
            }
        }
    }

    .image-text-block-button {
        padding-top: $spacer*0.25 !important;

        .btn-tertiary {
            text-align: left;
        }
    }
}
