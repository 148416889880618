@import "~core/components/pageHeader";

.page-header {
    .page-title {
        margin-top: $spacer * 2;
        @include typography(18);
    }
}

.page-header {
    padding-bottom: $spacer*1.5;
    .breadcrumb-wrapper {
        @include media-breakpoint-up(lg) {
            padding-bottom: $spacer/2;
            padding-top: 22px;
        }
    }
    &.search-results-header {
        .charity-amount-raised {
            color: $dark-mint;
            display: block;
        }
    }
}

#maincontent {
    .page-designer {
        .page-header.search-results-header {
            @include media-breakpoint-down (md) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

// Remove the spacing for hero banner at the top of the page
.search-results-header > .experience-region {
    margin-top: -$page-header-padding-top;
    margin-right: -$spacer;
    margin-left: -$spacer;
    @include media-breakpoint-up(md) {
        margin-top: -$page-header-padding-top-md; 
    }
}

// Remove margin for plp hero banners
.plp {
    .breadcrumb-wrapper {
        padding: $spacer 0 0;

        @include media-breakpoint-up (lg) {
            padding: $spacer*2 0;
        }
    }

    header {
        &.page-header {
            &.search-results-header {
                .breadcrumb-wrapper {
                    padding: $spacer $spacer 0;

                    @include media-breakpoint-up (lg) {
                        padding: $spacer $spacer*3 0;
                    }
                }
            }
        }
    }
}
.search-result-padding {
    padding-top: 78px;
    @include media-breakpoint-down(lg) {
        padding-top: 24px;
        display: flex;
        justify-content: center;
    }
}