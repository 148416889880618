
// Override for base search.css stylesheet
@import "variables";
@import "mixins";
@import "~core/search";
@import "bootstrap/scss/mixins/breakpoints";
.result-count {
    p {
        font-size: $spacer*2.75;
        color: $primary-black;
        font-weight: 500;
        font-family: $primary-font;
        letter-spacing: $search-letter-spacing;
        line-height:44px;
        margin-top: -54px;
        @include media-breakpoint-down(lg) {
            margin-top: -38px;
        }
        @include media-breakpoint-down(md) {
            font-size: $spacer*2;
        }
        @include media-breakpoint-down(sm) {
            font-size: $spacer;
            font-weight: bold;
        }
    }
}
.search-tips {
    font-size: $spacer*0.9225;
    text-align: left;
    margin-top: $spacer*1.375;
    color: $arsenic;
    font-weight: bolder;
    font-family: $secondary-font;
    letter-spacing: $search-letter-spacing;
    h5 {
        font-size: $spacer*0.9225;
        font-family: $primary-font;
        font-weight: normal;
        letter-spacing: $search-letter-spacing;
    }
    ul {
        padding-left: $spacer*2.5;
        li {
            padding-left: $spacer/2;
        }
        a {
            color: $cyan-blue;
            text-decoration: none;
            font-weight: bold;
            &:hover {
                color: $arsenic;
                text-decoration: underline;
            }
        }
    }
}
.product-grid {
    .product-tile {
        .product-name-wrapper {
            @include media-breakpoint-down(sm) {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                max-height: 52px;
                @include typography(13);
                padding: 0 $spacer*0.5;
            }
        }

        .product-tile--buy-container {
            @include media-breakpoint-down (sm) {
                padding: 0 $spacer*0.5;
            }

            .price {
                @include media-breakpoint-down (sm) {
                    @include typography(12);
                }
            }
        }
    }
}