@charset "UTF-8";
/*
* ICON SETUP INSTRUCTIONS
* 1) To edit Icomoon fonts, import the /fonts/icomoon/selection.json file at this URL: https://icomoon.io/app/#/select
* 2) After making changes and generating the font, replace everything in /fonts/icomoon with the downloaded files and folders
* 3) Copy the font files from /fonts/icomoon/fonts to /static/fonts/
* 4) Copy over the contents of the style.scss and variables.scss files per instructions below
* 5) Update the icons listed in /templates/default/styleguide/icons/availableIcons.isml to account for any changes
*/
@import url(~video.js/dist/video-js.min.css);
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?skc9fc");
  src: url("../fonts/icomoon.eot?skc9fc#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?skc9fc") format("truetype"), url("../fonts/icomoon.woff?skc9fc") format("woff"), url("../fonts/icomoon.svg?skc9fc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"]:not(.btn),
[class*=" icon-"]:not(.btn) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*
* Copy line 4 down from fonts/icomoon/variables.scss
*/
/*
* Copy line 30 down from from fonts/icomoon/style.scss
*/
.icon-icon-up-right :before {
  content: ""; }

.icon-square-x-twitter:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-star:before {
  content: ""; }

.icon-account:before {
  content: ""; }

.icon-arrow-left-thin:before {
  content: ""; }

.icon-arrow-right-thin:before {
  content: ""; }

.icon-badge:before {
  content: ""; }

.icon-bubble-tail:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-checkmark:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-collapse:before {
  content: ""; }

.icon-expand:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-favorite:before {
  content: ""; }

.icon-favorite-selected:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-logo:before {
  content: ""; }

.icon-pinterest:before {
  content: ""; }

.icon-play:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-select:before {
  content: ""; }

.icon-tiktok:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-menu:before {
  content: ""; }

.icon-images:before {
  content: ""; }

.icon-alert:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-top:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-call:before {
  content: ""; }

.icon-close-small:before {
  content: ""; }

.icon-edit:before {
  content: ""; }

.icon-email:before {
  content: ""; }

.icon-film:before {
  content: ""; }

.icon-google:before {
  content: ""; }

.icon-guarantee:before {
  content: ""; }

.icon-image:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-link:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-livechat:before {
  content: ""; }

.icon-location:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-offsite:before {
  content: ""; }

.icon-pause:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-remove:before {
  content: ""; }

.icon-size:before {
  content: ""; }

.icon-zoom-out:before {
  content: ""; }

.icon-zoom:before {
  content: ""; }

.fa {
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  text-rendering: auto;
  font-size: inherit; }

.fa-stack {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: inherit; }

/*
* COLORS
*/
/*
* GRID SYSTEM
*/
/*
* Z Indexes
*/
/*
* ASPECT RATIOS
*/
/*
* MISC
*/
/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
/*
* TEXT
*/
/*
* HEADERS
*/
/*
* BUTTONS
*/
/*
* Notify button
*/
/*
* FORMs
*/
/*
* PROGRESSBAR
*/
/*
* BREADCRUMBS
*/
/*
* TOOLTIPS
*/
/*
* POPOVERS
*/
/*
* MODALS
*/
/*
* ALERTS (also affects toast messages)
*/
/*
* TOAST MESSAGES
*/
/*
* CARDS
*/
/*
* COLLAPSIBLE
*/
/*
* TABS
*/
/*TAB MENU - NEW COMPONENT */
/*
* SLIDER
*/
/*
* DROPDOWNS
*/
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/*
* HEADER
*/
/*
* FOOTER
*/
/*
* PAGE-LEVEL SETTINGS
*/
/*
* PRODUCT
*/
/*
* MINICART
*/
/*
* CART AND CHECKOUT
*/
/*
* PAGE DESIGNER MODULES
*/
/*
* HERO ASSETS
*/
/*
* POPSOCKETS HERO BANNER
*/
/*
* CATEGORY TILE ASSETS
*/
/*
* IMAGE TEXT BLOCK ASSETS
*/
/*
* PHOTO HOTSPOTS ASSETS
*/
/*
* VIDEO ASSETS
*/
/*
* BLOG LANDING
*/
/*
* BLOG DETAIL
*/
/*
* CONTENT TILES
*/
/*
* STORE LOCATOR
*/
/*
* STORE DETAIL PAGE
*/
/*
* ACCOUNT
*/
/*
* GIFTCERTIFICATES
*/
/*
* PAYMENTMETHODS
*/
/*
* ACCESSIBILITY
*/
/*
* CONSENT DRAWER
*/
:root {
  --blue: #3091E7;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #ce02d6;
  --red: #a80b1d;
  --orange: #fd7e14;
  --yellow: #FFCC00;
  --green: #2eab2b;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --brand-primary: #181818;
  --light-gray: #F9F9F9;
  --medium-gray: #D4D4D4;
  --dark-gray: #595959;
  --primary-black: #181818;
  --primary-black-hover: #5d5d5d;
  --jade: #2EAB2B;
  --neon: #2EAB2B;
  --dark-mint: #76CBB4;
  --mint: #C6FDCF;
  --mint-2: #d3f0ea;
  --sunshine: #FFDF59;
  --cobalt: #2867EC;
  --seafoam: #ACE7FA;
  --violet: #634ED4;
  --periwinkle: #6C8FFF;
  --watermelon: #FF748B;
  --hot-pink: #F5A4AD;
  --tangerine: #FF9244;
  --peach: #FFAF80;
  --light-peach: #FEF0E5;
  --success: #01DF9D;
  --danger: #a80b1d;
  --warning: #FFDF59;
  --info: #ACE7FA;
  --dark-red: #8B0000;
  --spring-wood: #F6F7F4;
  --primary: #3091E7;
  --secondary: #6c757d;
  --success: #01DF9D;
  --info: #ACE7FA;
  --warning: #FFDF59;
  --danger: #a80b1d;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 544px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 1024px;
  --breakpoint-sd: 1200px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 8px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #181818;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: black;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 16px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 20px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 8px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #ce02d6;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container {
      max-width: 1420px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 1430px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1440px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-sd-1, .col-sd-2, .col-sd-3, .col-sd-4, .col-sd-5, .col-sd-6, .col-sd-7, .col-sd-8, .col-sd-9, .col-sd-10, .col-sd-11, .col-sd-12, .col-sd,
.col-sd-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 544px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 769px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-sd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sd-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sd-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sd-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sd-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sd-first {
    order: -1; }
  .order-sd-last {
    order: 13; }
  .order-sd-0 {
    order: 0; }
  .order-sd-1 {
    order: 1; }
  .order-sd-2 {
    order: 2; }
  .order-sd-3 {
    order: 3; }
  .order-sd-4 {
    order: 4; }
  .order-sd-5 {
    order: 5; }
  .order-sd-6 {
    order: 6; }
  .order-sd-7 {
    order: 7; }
  .order-sd-8 {
    order: 8; }
  .order-sd-9 {
    order: 9; }
  .order-sd-10 {
    order: 10; }
  .order-sd-11 {
    order: 11; }
  .order-sd-12 {
    order: 12; }
  .offset-sd-0 {
    margin-left: 0; }
  .offset-sd-1 {
    margin-left: 8.33333%; }
  .offset-sd-2 {
    margin-left: 16.66667%; }
  .offset-sd-3 {
    margin-left: 25%; }
  .offset-sd-4 {
    margin-left: 33.33333%; }
  .offset-sd-5 {
    margin-left: 41.66667%; }
  .offset-sd-6 {
    margin-left: 50%; }
  .offset-sd-7 {
    margin-left: 58.33333%; }
  .offset-sd-8 {
    margin-left: 66.66667%; }
  .offset-sd-9 {
    margin-left: 75%; }
  .offset-sd-10 {
    margin-left: 83.33333%; }
  .offset-sd-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 16px;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #D4D4D4; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #D4D4D4; }
  .table tbody + tbody {
    border-top: 2px solid #D4D4D4; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #D4D4D4; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #D4D4D4; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5e0f8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #93c6f3; }

.table-hover .table-primary:hover {
  background-color: #aed4f5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #aed4f5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f6e4; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7beecc; }

.table-hover .table-success:hover {
  background-color: #a1f3db; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a1f3db; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e8f8fe; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #d4f3fc; }

.table-hover .table-info:hover {
  background-color: #d0f1fd; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d0f1fd; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff6d1; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffeea9; }

.table-hover .table-warning:hover {
  background-color: #fff1b8; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff1b8; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e7bbc0; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #d28089; }

.table-hover .table-danger:hover {
  background-color: #e0a8af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e0a8af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #D4D4D4; }

.table-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 543.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-sd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sd > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(16px + 16px + 14px + (1px*2));
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #a2cef4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .form-control::placeholder {
    color: #595959;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #01DF9D; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.625rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: #01df9d;
  border-radius: 20px; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #01DF9D;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2301DF9D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #01DF9D;
    box-shadow: 0 0 0 0.2rem rgba(1, 223, 157, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .was-validated select.form-control:valid,
.was-validated select:valid, .custom-select.is-valid,
select.is-valid {
  border-color: #01DF9D;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2301DF9D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFFFFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .was-validated select.form-control:valid:focus,
  .was-validated select:valid:focus, .custom-select.is-valid:focus,
  select.is-valid:focus {
    border-color: #01DF9D;
    box-shadow: 0 0 0 0.2rem rgba(1, 223, 157, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback, .was-validated select.form-control:valid ~ .valid-feedback, .was-validated select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip,
  .was-validated select.form-control:valid ~ .valid-tooltip,
  .was-validated select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, select.is-valid.form-control ~ .valid-feedback, select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip,
  select.is-valid.form-control ~ .valid-tooltip,
  select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #01DF9D; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #01DF9D; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #01DF9D; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #15feb9;
  background-color: #15feb9; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(1, 223, 157, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #01DF9D; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #01DF9D; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #01DF9D;
  box-shadow: 0 0 0 0.2rem rgba(1, 223, 157, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #a80b1d; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.625rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: #a80b1d;
  border-radius: 20px; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #a80b1d;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23a80b1d' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23a80b1d' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #a80b1d;
    box-shadow: 0 0 0 0.2rem rgba(168, 11, 29, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .was-validated select.form-control:invalid,
.was-validated select:invalid, .custom-select.is-invalid,
select.is-invalid {
  border-color: #a80b1d;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23a80b1d' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23a80b1d' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFFFFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .was-validated select.form-control:invalid:focus,
  .was-validated select:invalid:focus, .custom-select.is-invalid:focus,
  select.is-invalid:focus {
    border-color: #a80b1d;
    box-shadow: 0 0 0 0.2rem rgba(168, 11, 29, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated select.form-control:invalid ~ .invalid-feedback, .was-validated select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .was-validated select.form-control:invalid ~ .invalid-tooltip,
  .was-validated select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, select.is-invalid.form-control ~ .invalid-feedback, select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip,
  select.is-invalid.form-control ~ .invalid-tooltip,
  select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #a80b1d; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #a80b1d; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #a80b1d; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d80e25;
  background-color: #d80e25; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(168, 11, 29, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #a80b1d; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #a80b1d; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #a80b1d;
  box-shadow: 0 0 0 0.2rem rgba(168, 11, 29, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 544px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select,
    .form-inline select.form-control,
    .form-inline select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 20px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 544px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-sd-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sd-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 8px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #3091E7; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > select.form-control,
  .input-group > select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + select.form-control,
    .input-group > .form-control + select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + select.form-control,
    .input-group > .form-control-plaintext + select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > select.form-control + .form-control,
    .input-group > select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > select.form-control + .custom-select,
    .input-group > select + .custom-select,
    .input-group > .custom-select + select.form-control,
    .input-group > select.form-control + select.form-control,
    .input-group > select + select.form-control,
    .input-group > .custom-select + select,
    .input-group > select.form-control + select,
    .input-group > select + select,
    .input-group > .custom-select + .custom-file,
    .input-group > select.form-control + .custom-file,
    .input-group > select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + select.form-control,
    .input-group > .custom-file + select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > select.form-control:focus,
  .input-group > select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child),
  .input-group > select.form-control:not(:last-child),
  .input-group > select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child),
  .input-group > select.form-control:not(:first-child),
  .input-group > select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #D4D4D4;
  border-radius: 4px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }


.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select,
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    border-color: #181818;
    background-color: #181818; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a2cef4; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #d0e6fa;
    border-color: #d0e6fa; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFFFFF;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 20px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3091E7;
  background-color: #3091E7; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 145, 231, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(48, 145, 231, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 145, 231, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFFFFF;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(48, 145, 231, 0.5); }

.custom-select, select.form-control,
select {
  display: inline-block;
  width: 100%;
  height: calc(16px + 16px + 14px + (1px*2));
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 20px;
  appearance: none; }
  .custom-select:focus,
  select:focus {
    border-color: #a2cef4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
    .custom-select:focus::-ms-value,
    select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  .custom-select[multiple],
  select[multiple], .custom-select[size]:not([size="1"]),
  select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled,
  select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand, select.form-control::-ms-expand,
  select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(16px + 16px + 14px + (1px*2));
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(16px + 16px + 14px + (1px*2));
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #a2cef4;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(16px + 16px + 14px + (1px*2));
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 4px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 4px 4px 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #3091E7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #d0e6fa; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3091E7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #d0e6fa; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #3091E7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #d0e6fa; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label, .custom-select, select.form-control,
select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label, .custom-select, select.form-control,
    select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #dee2e6 #dee2e6 #FFFFFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 20px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #3091E7; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 16px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 20px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 543.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 544px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-sd > .container,
  .navbar-expand-sd > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-sd {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sd .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sd .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sd .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sd > .container,
    .navbar-expand-sd > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sd .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sd .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid #F9F9F9;
  border-radius: 20px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #F9F9F9; }
  .card-header:first-child {
    border-radius: calc(20px - 1px) calc(20px - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #F9F9F9; }
  .card-footer:last-child {
    border-radius: 0 0 calc(20px - 1px) calc(20px - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(20px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(20px - 1px);
  border-top-right-radius: calc(20px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(20px - 1px);
  border-bottom-left-radius: calc(20px - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 544px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 544px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 544px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 20px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #181818;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: black;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; }

.page-item:last-child .page-link {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #3091E7;
  border-color: #3091E7; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #3091E7; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFFFFF;
    background-color: #1877cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.5); }

.badge-secondary {
  color: #FFFFFF;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFFFFF;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #FFFFFF;
  background-color: #01DF9D; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFFFFF;
    background-color: #01ac79; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 223, 157, 0.5); }

.badge-info {
  color: #212529;
  background-color: #ACE7FA; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #7cd9f7; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(172, 231, 250, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #FFDF59; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ffd526; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 223, 89, 0.5); }

.badge-danger {
  color: #FFFFFF;
  background-color: #a80b1d; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFFFFF;
    background-color: #780815; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(168, 11, 29, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFFFFF;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 544px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 20px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #194b78;
  background-color: #d6e9fa;
  border-color: #c5e0f8; }
  .alert-primary hr {
    border-top-color: #aed4f5; }
  .alert-primary .alert-link {
    color: #10314e; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #017452;
  background-color: #ccf9eb;
  border-color: #b8f6e4; }
  .alert-success hr {
    border-top-color: #a1f3db; }
  .alert-success .alert-link {
    color: #01412e; }

.alert-info {
  color: #597882;
  background-color: #eefafe;
  border-color: #e8f8fe; }
  .alert-info hr {
    border-top-color: #d0f1fd; }
  .alert-info .alert-link {
    color: #445c64; }

.alert-warning {
  color: #85742e;
  background-color: #fff9de;
  border-color: #fff6d1; }
  .alert-warning hr {
    border-top-color: #fff1b8; }
  .alert-warning .alert-link {
    color: #5f5321; }

.alert-danger {
  color: #57060f;
  background-color: #eeced2;
  border-color: #e7bbc0; }
  .alert-danger hr {
    border-top-color: #e0a8af; }
  .alert-danger .alert-link {
    color: #270307; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 20px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #3091E7;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #3091E7;
    border-color: #3091E7; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 0; }

@media (min-width: 544px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 0; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-sd {
    flex-direction: row; }
    .list-group-horizontal-sd .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sd .list-group-item:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0; }
      .list-group-horizontal-sd .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #194b78;
  background-color: #c5e0f8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #194b78;
    background-color: #aed4f5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #194b78;
    border-color: #194b78; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #017452;
  background-color: #b8f6e4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #017452;
    background-color: #a1f3db; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #017452;
    border-color: #017452; }

.list-group-item-info {
  color: #597882;
  background-color: #e8f8fe; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #597882;
    background-color: #d0f1fd; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #597882;
    border-color: #597882; }

.list-group-item-warning {
  color: #85742e;
  background-color: #fff6d1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #85742e;
    background-color: #fff1b8; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #85742e;
    border-color: #85742e; }

.list-group-item-danger {
  color: #57060f;
  background-color: #e7bbc0; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #57060f;
    background-color: #e0a8af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #57060f;
    border-color: #57060f; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  .close:hover {
    color: #000000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 32px 16px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 32px 32px 16px;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid transparent;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 544px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.625rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000000;
  border-radius: 20px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 300px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 20px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFFFFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #3091E7 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1877cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #01DF9D !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #01ac79 !important; }

.bg-info {
  background-color: #ACE7FA !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #7cd9f7 !important; }

.bg-warning {
  background-color: #FFDF59 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffd526 !important; }

.bg-danger {
  background-color: #a80b1d !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #780815 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #D4D4D4 !important; }

.border-top {
  border-top: 1px solid #D4D4D4 !important; }

.border-right {
  border-right: 1px solid #D4D4D4 !important; }

.border-bottom {
  border-bottom: 1px solid #D4D4D4 !important; }

.border-left {
  border-left: 1px solid #D4D4D4 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #3091E7 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #01DF9D !important; }

.border-info {
  border-color: #ACE7FA !important; }

.border-warning {
  border-color: #FFDF59 !important; }

.border-danger {
  border-color: #a80b1d !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 20px !important; }

.rounded-top {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important; }

.rounded-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important; }

.rounded-bottom {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; }

.rounded-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 544px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-sd-none {
    display: none !important; }
  .d-sd-inline {
    display: inline !important; }
  .d-sd-inline-block {
    display: inline-block !important; }
  .d-sd-block {
    display: block !important; }
  .d-sd-table {
    display: table !important; }
  .d-sd-table-row {
    display: table-row !important; }
  .d-sd-table-cell {
    display: table-cell !important; }
  .d-sd-flex {
    display: flex !important; }
  .d-sd-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 544px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-sd-row {
    flex-direction: row !important; }
  .flex-sd-column {
    flex-direction: column !important; }
  .flex-sd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sd-wrap {
    flex-wrap: wrap !important; }
  .flex-sd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sd-fill {
    flex: 1 1 auto !important; }
  .flex-sd-grow-0 {
    flex-grow: 0 !important; }
  .flex-sd-grow-1 {
    flex-grow: 1 !important; }
  .flex-sd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sd-start {
    justify-content: flex-start !important; }
  .justify-content-sd-end {
    justify-content: flex-end !important; }
  .justify-content-sd-center {
    justify-content: center !important; }
  .justify-content-sd-between {
    justify-content: space-between !important; }
  .justify-content-sd-around {
    justify-content: space-around !important; }
  .align-items-sd-start {
    align-items: flex-start !important; }
  .align-items-sd-end {
    align-items: flex-end !important; }
  .align-items-sd-center {
    align-items: center !important; }
  .align-items-sd-baseline {
    align-items: baseline !important; }
  .align-items-sd-stretch {
    align-items: stretch !important; }
  .align-content-sd-start {
    align-content: flex-start !important; }
  .align-content-sd-end {
    align-content: flex-end !important; }
  .align-content-sd-center {
    align-content: center !important; }
  .align-content-sd-between {
    align-content: space-between !important; }
  .align-content-sd-around {
    align-content: space-around !important; }
  .align-content-sd-stretch {
    align-content: stretch !important; }
  .align-self-sd-auto {
    align-self: auto !important; }
  .align-self-sd-start {
    align-self: flex-start !important; }
  .align-self-sd-end {
    align-self: flex-end !important; }
  .align-self-sd-center {
    align-self: center !important; }
  .align-self-sd-baseline {
    align-self: baseline !important; }
  .align-self-sd-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 544px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-sd-left {
    float: left !important; }
  .float-sd-right {
    float: right !important; }
  .float-sd-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 4px !important; }

.mt-1,
.my-1 {
  margin-top: 4px !important; }

.mr-1,
.mx-1 {
  margin-right: 4px !important; }

.mb-1,
.my-1 {
  margin-bottom: 4px !important; }

.ml-1,
.mx-1 {
  margin-left: 4px !important; }

.m-2 {
  margin: 8px !important; }

.mt-2,
.my-2 {
  margin-top: 8px !important; }

.mr-2,
.mx-2 {
  margin-right: 8px !important; }

.mb-2,
.my-2 {
  margin-bottom: 8px !important; }

.ml-2,
.mx-2 {
  margin-left: 8px !important; }

.m-3 {
  margin: 16px !important; }

.mt-3,
.my-3 {
  margin-top: 16px !important; }

.mr-3,
.mx-3 {
  margin-right: 16px !important; }

.mb-3,
.my-3 {
  margin-bottom: 16px !important; }

.ml-3,
.mx-3 {
  margin-left: 16px !important; }

.m-4 {
  margin: 24px !important; }

.mt-4,
.my-4 {
  margin-top: 24px !important; }

.mr-4,
.mx-4 {
  margin-right: 24px !important; }

.mb-4,
.my-4 {
  margin-bottom: 24px !important; }

.ml-4,
.mx-4 {
  margin-left: 24px !important; }

.m-5 {
  margin: 32px !important; }

.mt-5,
.my-5 {
  margin-top: 32px !important; }

.mr-5,
.mx-5 {
  margin-right: 32px !important; }

.mb-5,
.my-5 {
  margin-bottom: 32px !important; }

.ml-5,
.mx-5 {
  margin-left: 32px !important; }

.m-6 {
  margin: 48px !important; }

.mt-6,
.my-6 {
  margin-top: 48px !important; }

.mr-6,
.mx-6 {
  margin-right: 48px !important; }

.mb-6,
.my-6 {
  margin-bottom: 48px !important; }

.ml-6,
.mx-6 {
  margin-left: 48px !important; }

.m-7 {
  margin: 64px !important; }

.mt-7,
.my-7 {
  margin-top: 64px !important; }

.mr-7,
.mx-7 {
  margin-right: 64px !important; }

.mb-7,
.my-7 {
  margin-bottom: 64px !important; }

.ml-7,
.mx-7 {
  margin-left: 64px !important; }

.m-8 {
  margin: 80px !important; }

.mt-8,
.my-8 {
  margin-top: 80px !important; }

.mr-8,
.mx-8 {
  margin-right: 80px !important; }

.mb-8,
.my-8 {
  margin-bottom: 80px !important; }

.ml-8,
.mx-8 {
  margin-left: 80px !important; }

.m-9 {
  margin: 96px !important; }

.mt-9,
.my-9 {
  margin-top: 96px !important; }

.mr-9,
.mx-9 {
  margin-right: 96px !important; }

.mb-9,
.my-9 {
  margin-bottom: 96px !important; }

.ml-9,
.mx-9 {
  margin-left: 96px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 4px !important; }

.pt-1,
.py-1 {
  padding-top: 4px !important; }

.pr-1,
.px-1 {
  padding-right: 4px !important; }

.pb-1,
.py-1 {
  padding-bottom: 4px !important; }

.pl-1,
.px-1 {
  padding-left: 4px !important; }

.p-2 {
  padding: 8px !important; }

.pt-2,
.py-2 {
  padding-top: 8px !important; }

.pr-2,
.px-2 {
  padding-right: 8px !important; }

.pb-2,
.py-2 {
  padding-bottom: 8px !important; }

.pl-2,
.px-2 {
  padding-left: 8px !important; }

.p-3 {
  padding: 16px !important; }

.pt-3,
.py-3 {
  padding-top: 16px !important; }

.pr-3,
.px-3 {
  padding-right: 16px !important; }

.pb-3,
.py-3 {
  padding-bottom: 16px !important; }

.pl-3,
.px-3 {
  padding-left: 16px !important; }

.p-4 {
  padding: 24px !important; }

.pt-4,
.py-4 {
  padding-top: 24px !important; }

.pr-4,
.px-4 {
  padding-right: 24px !important; }

.pb-4,
.py-4 {
  padding-bottom: 24px !important; }

.pl-4,
.px-4 {
  padding-left: 24px !important; }

.p-5 {
  padding: 32px !important; }

.pt-5,
.py-5 {
  padding-top: 32px !important; }

.pr-5,
.px-5 {
  padding-right: 32px !important; }

.pb-5,
.py-5 {
  padding-bottom: 32px !important; }

.pl-5,
.px-5 {
  padding-left: 32px !important; }

.p-6 {
  padding: 48px !important; }

.pt-6,
.py-6 {
  padding-top: 48px !important; }

.pr-6,
.px-6 {
  padding-right: 48px !important; }

.pb-6,
.py-6 {
  padding-bottom: 48px !important; }

.pl-6,
.px-6 {
  padding-left: 48px !important; }

.p-7 {
  padding: 64px !important; }

.pt-7,
.py-7 {
  padding-top: 64px !important; }

.pr-7,
.px-7 {
  padding-right: 64px !important; }

.pb-7,
.py-7 {
  padding-bottom: 64px !important; }

.pl-7,
.px-7 {
  padding-left: 64px !important; }

.p-8 {
  padding: 80px !important; }

.pt-8,
.py-8 {
  padding-top: 80px !important; }

.pr-8,
.px-8 {
  padding-right: 80px !important; }

.pb-8,
.py-8 {
  padding-bottom: 80px !important; }

.pl-8,
.px-8 {
  padding-left: 80px !important; }

.p-9 {
  padding: 96px !important; }

.pt-9,
.py-9 {
  padding-top: 96px !important; }

.pr-9,
.px-9 {
  padding-right: 96px !important; }

.pb-9,
.py-9 {
  padding-bottom: 96px !important; }

.pl-9,
.px-9 {
  padding-left: 96px !important; }

.m-n1 {
  margin: -4px !important; }

.mt-n1,
.my-n1 {
  margin-top: -4px !important; }

.mr-n1,
.mx-n1 {
  margin-right: -4px !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -4px !important; }

.ml-n1,
.mx-n1 {
  margin-left: -4px !important; }

.m-n2 {
  margin: -8px !important; }

.mt-n2,
.my-n2 {
  margin-top: -8px !important; }

.mr-n2,
.mx-n2 {
  margin-right: -8px !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -8px !important; }

.ml-n2,
.mx-n2 {
  margin-left: -8px !important; }

.m-n3 {
  margin: -16px !important; }

.mt-n3,
.my-n3 {
  margin-top: -16px !important; }

.mr-n3,
.mx-n3 {
  margin-right: -16px !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -16px !important; }

.ml-n3,
.mx-n3 {
  margin-left: -16px !important; }

.m-n4 {
  margin: -24px !important; }

.mt-n4,
.my-n4 {
  margin-top: -24px !important; }

.mr-n4,
.mx-n4 {
  margin-right: -24px !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -24px !important; }

.ml-n4,
.mx-n4 {
  margin-left: -24px !important; }

.m-n5 {
  margin: -32px !important; }

.mt-n5,
.my-n5 {
  margin-top: -32px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -32px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -32px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -32px !important; }

.m-n6 {
  margin: -48px !important; }

.mt-n6,
.my-n6 {
  margin-top: -48px !important; }

.mr-n6,
.mx-n6 {
  margin-right: -48px !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -48px !important; }

.ml-n6,
.mx-n6 {
  margin-left: -48px !important; }

.m-n7 {
  margin: -64px !important; }

.mt-n7,
.my-n7 {
  margin-top: -64px !important; }

.mr-n7,
.mx-n7 {
  margin-right: -64px !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -64px !important; }

.ml-n7,
.mx-n7 {
  margin-left: -64px !important; }

.m-n8 {
  margin: -80px !important; }

.mt-n8,
.my-n8 {
  margin-top: -80px !important; }

.mr-n8,
.mx-n8 {
  margin-right: -80px !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -80px !important; }

.ml-n8,
.mx-n8 {
  margin-left: -80px !important; }

.m-n9 {
  margin: -96px !important; }

.mt-n9,
.my-n9 {
  margin-top: -96px !important; }

.mr-n9,
.mx-n9 {
  margin-right: -96px !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -96px !important; }

.ml-n9,
.mx-n9 {
  margin-left: -96px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 544px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 4px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 4px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 4px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 4px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 4px !important; }
  .m-sm-2 {
    margin: 8px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 8px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 8px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 8px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 8px !important; }
  .m-sm-3 {
    margin: 16px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 16px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 16px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 16px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 16px !important; }
  .m-sm-4 {
    margin: 24px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 24px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 24px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 24px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 24px !important; }
  .m-sm-5 {
    margin: 32px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 32px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 32px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 32px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 32px !important; }
  .m-sm-6 {
    margin: 48px !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 48px !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 48px !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 48px !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 48px !important; }
  .m-sm-7 {
    margin: 64px !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 64px !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 64px !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 64px !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 64px !important; }
  .m-sm-8 {
    margin: 80px !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 80px !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 80px !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 80px !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 80px !important; }
  .m-sm-9 {
    margin: 96px !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 96px !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 96px !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 96px !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 96px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 4px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 4px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 4px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 4px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 4px !important; }
  .p-sm-2 {
    padding: 8px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 8px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 8px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 8px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 8px !important; }
  .p-sm-3 {
    padding: 16px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 16px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 16px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 16px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 16px !important; }
  .p-sm-4 {
    padding: 24px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 24px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 24px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 24px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 24px !important; }
  .p-sm-5 {
    padding: 32px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 32px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 32px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 32px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 32px !important; }
  .p-sm-6 {
    padding: 48px !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 48px !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 48px !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 48px !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 48px !important; }
  .p-sm-7 {
    padding: 64px !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 64px !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 64px !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 64px !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 64px !important; }
  .p-sm-8 {
    padding: 80px !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 80px !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 80px !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 80px !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 80px !important; }
  .p-sm-9 {
    padding: 96px !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 96px !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 96px !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 96px !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 96px !important; }
  .m-sm-n1 {
    margin: -4px !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -4px !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -4px !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -4px !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -4px !important; }
  .m-sm-n2 {
    margin: -8px !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -8px !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -8px !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -8px !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -8px !important; }
  .m-sm-n3 {
    margin: -16px !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -16px !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -16px !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -16px !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -16px !important; }
  .m-sm-n4 {
    margin: -24px !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -24px !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -24px !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -24px !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -24px !important; }
  .m-sm-n5 {
    margin: -32px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -32px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -32px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -32px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -32px !important; }
  .m-sm-n6 {
    margin: -48px !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -48px !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -48px !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -48px !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -48px !important; }
  .m-sm-n7 {
    margin: -64px !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -64px !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -64px !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -64px !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -64px !important; }
  .m-sm-n8 {
    margin: -80px !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -80px !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -80px !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -80px !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -80px !important; }
  .m-sm-n9 {
    margin: -96px !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -96px !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -96px !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -96px !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -96px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 4px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 4px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 4px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 4px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 4px !important; }
  .m-md-2 {
    margin: 8px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 8px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 8px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 8px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 8px !important; }
  .m-md-3 {
    margin: 16px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 16px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 16px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 16px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 16px !important; }
  .m-md-4 {
    margin: 24px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 24px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 24px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 24px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 24px !important; }
  .m-md-5 {
    margin: 32px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 32px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 32px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 32px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 32px !important; }
  .m-md-6 {
    margin: 48px !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 48px !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 48px !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 48px !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 48px !important; }
  .m-md-7 {
    margin: 64px !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 64px !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 64px !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 64px !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 64px !important; }
  .m-md-8 {
    margin: 80px !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 80px !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 80px !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 80px !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 80px !important; }
  .m-md-9 {
    margin: 96px !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 96px !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 96px !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 96px !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 96px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 4px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 4px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 4px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 4px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 4px !important; }
  .p-md-2 {
    padding: 8px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 8px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 8px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 8px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 8px !important; }
  .p-md-3 {
    padding: 16px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 16px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 16px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 16px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 16px !important; }
  .p-md-4 {
    padding: 24px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 24px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 24px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 24px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 24px !important; }
  .p-md-5 {
    padding: 32px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 32px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 32px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 32px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 32px !important; }
  .p-md-6 {
    padding: 48px !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 48px !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 48px !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 48px !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 48px !important; }
  .p-md-7 {
    padding: 64px !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 64px !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 64px !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 64px !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 64px !important; }
  .p-md-8 {
    padding: 80px !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 80px !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 80px !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 80px !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 80px !important; }
  .p-md-9 {
    padding: 96px !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 96px !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 96px !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 96px !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 96px !important; }
  .m-md-n1 {
    margin: -4px !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -4px !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -4px !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -4px !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -4px !important; }
  .m-md-n2 {
    margin: -8px !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -8px !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -8px !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -8px !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -8px !important; }
  .m-md-n3 {
    margin: -16px !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -16px !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -16px !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -16px !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -16px !important; }
  .m-md-n4 {
    margin: -24px !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -24px !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -24px !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -24px !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -24px !important; }
  .m-md-n5 {
    margin: -32px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -32px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -32px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -32px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -32px !important; }
  .m-md-n6 {
    margin: -48px !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -48px !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -48px !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -48px !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -48px !important; }
  .m-md-n7 {
    margin: -64px !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -64px !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -64px !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -64px !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -64px !important; }
  .m-md-n8 {
    margin: -80px !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -80px !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -80px !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -80px !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -80px !important; }
  .m-md-n9 {
    margin: -96px !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -96px !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -96px !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -96px !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -96px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 4px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 4px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 4px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 4px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 4px !important; }
  .m-lg-2 {
    margin: 8px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 8px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 8px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 8px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 8px !important; }
  .m-lg-3 {
    margin: 16px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 16px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 16px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 16px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 16px !important; }
  .m-lg-4 {
    margin: 24px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 24px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 24px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 24px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 24px !important; }
  .m-lg-5 {
    margin: 32px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 32px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 32px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 32px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 32px !important; }
  .m-lg-6 {
    margin: 48px !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 48px !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 48px !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 48px !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 48px !important; }
  .m-lg-7 {
    margin: 64px !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 64px !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 64px !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 64px !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 64px !important; }
  .m-lg-8 {
    margin: 80px !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 80px !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 80px !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 80px !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 80px !important; }
  .m-lg-9 {
    margin: 96px !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 96px !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 96px !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 96px !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 96px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 4px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 4px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 4px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 4px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 4px !important; }
  .p-lg-2 {
    padding: 8px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 8px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 8px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 8px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 8px !important; }
  .p-lg-3 {
    padding: 16px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 16px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 16px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 16px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 16px !important; }
  .p-lg-4 {
    padding: 24px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 24px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 24px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 24px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 24px !important; }
  .p-lg-5 {
    padding: 32px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 32px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 32px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 32px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 32px !important; }
  .p-lg-6 {
    padding: 48px !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 48px !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 48px !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 48px !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 48px !important; }
  .p-lg-7 {
    padding: 64px !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 64px !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 64px !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 64px !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 64px !important; }
  .p-lg-8 {
    padding: 80px !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 80px !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 80px !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 80px !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 80px !important; }
  .p-lg-9 {
    padding: 96px !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 96px !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 96px !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 96px !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 96px !important; }
  .m-lg-n1 {
    margin: -4px !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -4px !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -4px !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -4px !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -4px !important; }
  .m-lg-n2 {
    margin: -8px !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -8px !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -8px !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -8px !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -8px !important; }
  .m-lg-n3 {
    margin: -16px !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -16px !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -16px !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -16px !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -16px !important; }
  .m-lg-n4 {
    margin: -24px !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -24px !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -24px !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -24px !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -24px !important; }
  .m-lg-n5 {
    margin: -32px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -32px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -32px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -32px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -32px !important; }
  .m-lg-n6 {
    margin: -48px !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -48px !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -48px !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -48px !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -48px !important; }
  .m-lg-n7 {
    margin: -64px !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -64px !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -64px !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -64px !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -64px !important; }
  .m-lg-n8 {
    margin: -80px !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -80px !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -80px !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -80px !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -80px !important; }
  .m-lg-n9 {
    margin: -96px !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -96px !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -96px !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -96px !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -96px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-sd-0 {
    margin: 0 !important; }
  .mt-sd-0,
  .my-sd-0 {
    margin-top: 0 !important; }
  .mr-sd-0,
  .mx-sd-0 {
    margin-right: 0 !important; }
  .mb-sd-0,
  .my-sd-0 {
    margin-bottom: 0 !important; }
  .ml-sd-0,
  .mx-sd-0 {
    margin-left: 0 !important; }
  .m-sd-1 {
    margin: 4px !important; }
  .mt-sd-1,
  .my-sd-1 {
    margin-top: 4px !important; }
  .mr-sd-1,
  .mx-sd-1 {
    margin-right: 4px !important; }
  .mb-sd-1,
  .my-sd-1 {
    margin-bottom: 4px !important; }
  .ml-sd-1,
  .mx-sd-1 {
    margin-left: 4px !important; }
  .m-sd-2 {
    margin: 8px !important; }
  .mt-sd-2,
  .my-sd-2 {
    margin-top: 8px !important; }
  .mr-sd-2,
  .mx-sd-2 {
    margin-right: 8px !important; }
  .mb-sd-2,
  .my-sd-2 {
    margin-bottom: 8px !important; }
  .ml-sd-2,
  .mx-sd-2 {
    margin-left: 8px !important; }
  .m-sd-3 {
    margin: 16px !important; }
  .mt-sd-3,
  .my-sd-3 {
    margin-top: 16px !important; }
  .mr-sd-3,
  .mx-sd-3 {
    margin-right: 16px !important; }
  .mb-sd-3,
  .my-sd-3 {
    margin-bottom: 16px !important; }
  .ml-sd-3,
  .mx-sd-3 {
    margin-left: 16px !important; }
  .m-sd-4 {
    margin: 24px !important; }
  .mt-sd-4,
  .my-sd-4 {
    margin-top: 24px !important; }
  .mr-sd-4,
  .mx-sd-4 {
    margin-right: 24px !important; }
  .mb-sd-4,
  .my-sd-4 {
    margin-bottom: 24px !important; }
  .ml-sd-4,
  .mx-sd-4 {
    margin-left: 24px !important; }
  .m-sd-5 {
    margin: 32px !important; }
  .mt-sd-5,
  .my-sd-5 {
    margin-top: 32px !important; }
  .mr-sd-5,
  .mx-sd-5 {
    margin-right: 32px !important; }
  .mb-sd-5,
  .my-sd-5 {
    margin-bottom: 32px !important; }
  .ml-sd-5,
  .mx-sd-5 {
    margin-left: 32px !important; }
  .m-sd-6 {
    margin: 48px !important; }
  .mt-sd-6,
  .my-sd-6 {
    margin-top: 48px !important; }
  .mr-sd-6,
  .mx-sd-6 {
    margin-right: 48px !important; }
  .mb-sd-6,
  .my-sd-6 {
    margin-bottom: 48px !important; }
  .ml-sd-6,
  .mx-sd-6 {
    margin-left: 48px !important; }
  .m-sd-7 {
    margin: 64px !important; }
  .mt-sd-7,
  .my-sd-7 {
    margin-top: 64px !important; }
  .mr-sd-7,
  .mx-sd-7 {
    margin-right: 64px !important; }
  .mb-sd-7,
  .my-sd-7 {
    margin-bottom: 64px !important; }
  .ml-sd-7,
  .mx-sd-7 {
    margin-left: 64px !important; }
  .m-sd-8 {
    margin: 80px !important; }
  .mt-sd-8,
  .my-sd-8 {
    margin-top: 80px !important; }
  .mr-sd-8,
  .mx-sd-8 {
    margin-right: 80px !important; }
  .mb-sd-8,
  .my-sd-8 {
    margin-bottom: 80px !important; }
  .ml-sd-8,
  .mx-sd-8 {
    margin-left: 80px !important; }
  .m-sd-9 {
    margin: 96px !important; }
  .mt-sd-9,
  .my-sd-9 {
    margin-top: 96px !important; }
  .mr-sd-9,
  .mx-sd-9 {
    margin-right: 96px !important; }
  .mb-sd-9,
  .my-sd-9 {
    margin-bottom: 96px !important; }
  .ml-sd-9,
  .mx-sd-9 {
    margin-left: 96px !important; }
  .p-sd-0 {
    padding: 0 !important; }
  .pt-sd-0,
  .py-sd-0 {
    padding-top: 0 !important; }
  .pr-sd-0,
  .px-sd-0 {
    padding-right: 0 !important; }
  .pb-sd-0,
  .py-sd-0 {
    padding-bottom: 0 !important; }
  .pl-sd-0,
  .px-sd-0 {
    padding-left: 0 !important; }
  .p-sd-1 {
    padding: 4px !important; }
  .pt-sd-1,
  .py-sd-1 {
    padding-top: 4px !important; }
  .pr-sd-1,
  .px-sd-1 {
    padding-right: 4px !important; }
  .pb-sd-1,
  .py-sd-1 {
    padding-bottom: 4px !important; }
  .pl-sd-1,
  .px-sd-1 {
    padding-left: 4px !important; }
  .p-sd-2 {
    padding: 8px !important; }
  .pt-sd-2,
  .py-sd-2 {
    padding-top: 8px !important; }
  .pr-sd-2,
  .px-sd-2 {
    padding-right: 8px !important; }
  .pb-sd-2,
  .py-sd-2 {
    padding-bottom: 8px !important; }
  .pl-sd-2,
  .px-sd-2 {
    padding-left: 8px !important; }
  .p-sd-3 {
    padding: 16px !important; }
  .pt-sd-3,
  .py-sd-3 {
    padding-top: 16px !important; }
  .pr-sd-3,
  .px-sd-3 {
    padding-right: 16px !important; }
  .pb-sd-3,
  .py-sd-3 {
    padding-bottom: 16px !important; }
  .pl-sd-3,
  .px-sd-3 {
    padding-left: 16px !important; }
  .p-sd-4 {
    padding: 24px !important; }
  .pt-sd-4,
  .py-sd-4 {
    padding-top: 24px !important; }
  .pr-sd-4,
  .px-sd-4 {
    padding-right: 24px !important; }
  .pb-sd-4,
  .py-sd-4 {
    padding-bottom: 24px !important; }
  .pl-sd-4,
  .px-sd-4 {
    padding-left: 24px !important; }
  .p-sd-5 {
    padding: 32px !important; }
  .pt-sd-5,
  .py-sd-5 {
    padding-top: 32px !important; }
  .pr-sd-5,
  .px-sd-5 {
    padding-right: 32px !important; }
  .pb-sd-5,
  .py-sd-5 {
    padding-bottom: 32px !important; }
  .pl-sd-5,
  .px-sd-5 {
    padding-left: 32px !important; }
  .p-sd-6 {
    padding: 48px !important; }
  .pt-sd-6,
  .py-sd-6 {
    padding-top: 48px !important; }
  .pr-sd-6,
  .px-sd-6 {
    padding-right: 48px !important; }
  .pb-sd-6,
  .py-sd-6 {
    padding-bottom: 48px !important; }
  .pl-sd-6,
  .px-sd-6 {
    padding-left: 48px !important; }
  .p-sd-7 {
    padding: 64px !important; }
  .pt-sd-7,
  .py-sd-7 {
    padding-top: 64px !important; }
  .pr-sd-7,
  .px-sd-7 {
    padding-right: 64px !important; }
  .pb-sd-7,
  .py-sd-7 {
    padding-bottom: 64px !important; }
  .pl-sd-7,
  .px-sd-7 {
    padding-left: 64px !important; }
  .p-sd-8 {
    padding: 80px !important; }
  .pt-sd-8,
  .py-sd-8 {
    padding-top: 80px !important; }
  .pr-sd-8,
  .px-sd-8 {
    padding-right: 80px !important; }
  .pb-sd-8,
  .py-sd-8 {
    padding-bottom: 80px !important; }
  .pl-sd-8,
  .px-sd-8 {
    padding-left: 80px !important; }
  .p-sd-9 {
    padding: 96px !important; }
  .pt-sd-9,
  .py-sd-9 {
    padding-top: 96px !important; }
  .pr-sd-9,
  .px-sd-9 {
    padding-right: 96px !important; }
  .pb-sd-9,
  .py-sd-9 {
    padding-bottom: 96px !important; }
  .pl-sd-9,
  .px-sd-9 {
    padding-left: 96px !important; }
  .m-sd-n1 {
    margin: -4px !important; }
  .mt-sd-n1,
  .my-sd-n1 {
    margin-top: -4px !important; }
  .mr-sd-n1,
  .mx-sd-n1 {
    margin-right: -4px !important; }
  .mb-sd-n1,
  .my-sd-n1 {
    margin-bottom: -4px !important; }
  .ml-sd-n1,
  .mx-sd-n1 {
    margin-left: -4px !important; }
  .m-sd-n2 {
    margin: -8px !important; }
  .mt-sd-n2,
  .my-sd-n2 {
    margin-top: -8px !important; }
  .mr-sd-n2,
  .mx-sd-n2 {
    margin-right: -8px !important; }
  .mb-sd-n2,
  .my-sd-n2 {
    margin-bottom: -8px !important; }
  .ml-sd-n2,
  .mx-sd-n2 {
    margin-left: -8px !important; }
  .m-sd-n3 {
    margin: -16px !important; }
  .mt-sd-n3,
  .my-sd-n3 {
    margin-top: -16px !important; }
  .mr-sd-n3,
  .mx-sd-n3 {
    margin-right: -16px !important; }
  .mb-sd-n3,
  .my-sd-n3 {
    margin-bottom: -16px !important; }
  .ml-sd-n3,
  .mx-sd-n3 {
    margin-left: -16px !important; }
  .m-sd-n4 {
    margin: -24px !important; }
  .mt-sd-n4,
  .my-sd-n4 {
    margin-top: -24px !important; }
  .mr-sd-n4,
  .mx-sd-n4 {
    margin-right: -24px !important; }
  .mb-sd-n4,
  .my-sd-n4 {
    margin-bottom: -24px !important; }
  .ml-sd-n4,
  .mx-sd-n4 {
    margin-left: -24px !important; }
  .m-sd-n5 {
    margin: -32px !important; }
  .mt-sd-n5,
  .my-sd-n5 {
    margin-top: -32px !important; }
  .mr-sd-n5,
  .mx-sd-n5 {
    margin-right: -32px !important; }
  .mb-sd-n5,
  .my-sd-n5 {
    margin-bottom: -32px !important; }
  .ml-sd-n5,
  .mx-sd-n5 {
    margin-left: -32px !important; }
  .m-sd-n6 {
    margin: -48px !important; }
  .mt-sd-n6,
  .my-sd-n6 {
    margin-top: -48px !important; }
  .mr-sd-n6,
  .mx-sd-n6 {
    margin-right: -48px !important; }
  .mb-sd-n6,
  .my-sd-n6 {
    margin-bottom: -48px !important; }
  .ml-sd-n6,
  .mx-sd-n6 {
    margin-left: -48px !important; }
  .m-sd-n7 {
    margin: -64px !important; }
  .mt-sd-n7,
  .my-sd-n7 {
    margin-top: -64px !important; }
  .mr-sd-n7,
  .mx-sd-n7 {
    margin-right: -64px !important; }
  .mb-sd-n7,
  .my-sd-n7 {
    margin-bottom: -64px !important; }
  .ml-sd-n7,
  .mx-sd-n7 {
    margin-left: -64px !important; }
  .m-sd-n8 {
    margin: -80px !important; }
  .mt-sd-n8,
  .my-sd-n8 {
    margin-top: -80px !important; }
  .mr-sd-n8,
  .mx-sd-n8 {
    margin-right: -80px !important; }
  .mb-sd-n8,
  .my-sd-n8 {
    margin-bottom: -80px !important; }
  .ml-sd-n8,
  .mx-sd-n8 {
    margin-left: -80px !important; }
  .m-sd-n9 {
    margin: -96px !important; }
  .mt-sd-n9,
  .my-sd-n9 {
    margin-top: -96px !important; }
  .mr-sd-n9,
  .mx-sd-n9 {
    margin-right: -96px !important; }
  .mb-sd-n9,
  .my-sd-n9 {
    margin-bottom: -96px !important; }
  .ml-sd-n9,
  .mx-sd-n9 {
    margin-left: -96px !important; }
  .m-sd-auto {
    margin: auto !important; }
  .mt-sd-auto,
  .my-sd-auto {
    margin-top: auto !important; }
  .mr-sd-auto,
  .mx-sd-auto {
    margin-right: auto !important; }
  .mb-sd-auto,
  .my-sd-auto {
    margin-bottom: auto !important; }
  .ml-sd-auto,
  .mx-sd-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 4px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 4px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 4px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 4px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 4px !important; }
  .m-xl-2 {
    margin: 8px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 8px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 8px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 8px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 8px !important; }
  .m-xl-3 {
    margin: 16px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 16px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 16px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 16px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 16px !important; }
  .m-xl-4 {
    margin: 24px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 24px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 24px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 24px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 24px !important; }
  .m-xl-5 {
    margin: 32px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 32px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 32px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 32px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 32px !important; }
  .m-xl-6 {
    margin: 48px !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 48px !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 48px !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 48px !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 48px !important; }
  .m-xl-7 {
    margin: 64px !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 64px !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 64px !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 64px !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 64px !important; }
  .m-xl-8 {
    margin: 80px !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 80px !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 80px !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 80px !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 80px !important; }
  .m-xl-9 {
    margin: 96px !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 96px !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 96px !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 96px !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 96px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 4px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 4px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 4px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 4px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 4px !important; }
  .p-xl-2 {
    padding: 8px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 8px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 8px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 8px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 8px !important; }
  .p-xl-3 {
    padding: 16px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 16px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 16px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 16px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 16px !important; }
  .p-xl-4 {
    padding: 24px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 24px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 24px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 24px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 24px !important; }
  .p-xl-5 {
    padding: 32px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 32px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 32px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 32px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 32px !important; }
  .p-xl-6 {
    padding: 48px !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 48px !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 48px !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 48px !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 48px !important; }
  .p-xl-7 {
    padding: 64px !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 64px !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 64px !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 64px !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 64px !important; }
  .p-xl-8 {
    padding: 80px !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 80px !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 80px !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 80px !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 80px !important; }
  .p-xl-9 {
    padding: 96px !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 96px !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 96px !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 96px !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 96px !important; }
  .m-xl-n1 {
    margin: -4px !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -4px !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -4px !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -4px !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -4px !important; }
  .m-xl-n2 {
    margin: -8px !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -8px !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -8px !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -8px !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -8px !important; }
  .m-xl-n3 {
    margin: -16px !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -16px !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -16px !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -16px !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -16px !important; }
  .m-xl-n4 {
    margin: -24px !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -24px !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -24px !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -24px !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -24px !important; }
  .m-xl-n5 {
    margin: -32px !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -32px !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -32px !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -32px !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -32px !important; }
  .m-xl-n6 {
    margin: -48px !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -48px !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -48px !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -48px !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -48px !important; }
  .m-xl-n7 {
    margin: -64px !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -64px !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -64px !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -64px !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -64px !important; }
  .m-xl-n8 {
    margin: -80px !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -80px !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -80px !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -80px !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -80px !important; }
  .m-xl-n9 {
    margin: -96px !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -96px !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -96px !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -96px !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -96px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-sd-left {
    text-align: left !important; }
  .text-sd-right {
    text-align: right !important; }
  .text-sd-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #3091E7 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #156ab5 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #01DF9D !important; }

a.text-success:hover, a.text-success:focus {
  color: #019367 !important; }

.text-info {
  color: #ACE7FA !important; }

a.text-info:hover, a.text-info:focus {
  color: #64d2f6 !important; }

.text-warning {
  color: #FFDF59 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ffd00d !important; }

.text-danger {
  color: #a80b1d !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #600611 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1024px !important; }
  .container {
    min-width: 1024px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #D4D4D4; }
  .table .thead-dark th {
    color: inherit;
    border-color: #D4D4D4; } }

dt {
  color: #495057;
  font-weight: normal; }

a {
  color: inherit; }
  a:hover {
    color: inherit; }

i {
  font-style: normal;
  display: inline-block; }

button {
  background: transparent;
  border: none;
  padding: 0;
  letter-spacing: inherit; }

ul {
  padding-left: 16px; }

figure {
  margin: 0; }

.modal-footer > :not(:first-child) {
  margin-left: 0; }

.modal-footer > :not(:last-child) {
  margin-right: 0; }

.tooltip {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit; }

.text-muted {
  color: #595959 !important; }

.row:not(.no-gutters) {
  margin-right: -8px;
  margin-left: -8px; }

.row:not(.no-gutters) > .col,
.row:not(.no-gutters) > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px; }

@media (min-width: 769px) {
  .row:not(.no-gutters) {
    margin-right: -16px;
    margin-left: -16px; }
  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) > [class*="col-"] {
    padding-right: 16px;
    padding-left: 16px; } }

@media (min-width: 1440px) {
  .row:not(.no-gutters) {
    margin-right: -16px;
    margin-left: -16px; }
  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) > [class*="col-"] {
    padding-right: 16px;
    padding-left: 16px; } }

.container {
  padding-right: 8px;
  padding-left: 8px; }
  @media (min-width: 769px) {
    .container {
      padding-right: 16px;
      padding-left: 16px; } }
  @media (min-width: 1440px) {
    .container {
      padding-right: 16px;
      padding-left: 16px; } }

.container-fluid {
  padding-right: 8px;
  padding-left: 8px; }
  @media (min-width: 769px) {
    .container-fluid {
      padding-right: 16px;
      padding-left: 16px; } }
  @media (min-width: 1440px) {
    .container-fluid {
      padding-right: 16px;
      padding-left: 16px; } }

:focus, button:focus {
  outline: 2px solid #595959; }

:focus:not(.focus-visible) {
  outline: none; }

.container {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 769px) {
    .container {
      padding-right: 32px;
      padding-left: 32px; } }
  @media (min-width: 1440px) {
    .container {
      padding-right: 32px;
      padding-left: 32px; } }

.container-fluid {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 769px) {
    .container-fluid {
      padding-right: 32px;
      padding-left: 32px; } }
  @media (min-width: 1440px) {
    .container-fluid {
      padding-right: 32px;
      padding-left: 32px; } }

.tns-outer {
  padding: 0 !important; }
  .tns-outer [hidden] {
    display: none !important; }
  .tns-outer [aria-controls], .tns-outer [data-action] {
    cursor: pointer; }

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s; }
  .tns-slider > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }
  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }
  .tns-gallery > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s; }
  .tns-gallery > .tns-slide-active {
    position: relative;
    left: auto !important; }
  .tns-gallery > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6; }
  .tns-lazy-img.tns-complete {
    opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }
  .tns-vpfix > div, .tns-vpfix > li {
    display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }
  .tns-t-ct:after {
    content: '';
    display: table;
    clear: both; }
  .tns-t-ct > div {
    width: calc(100% / 70);
    height: 10px;
    float: left; }

.svg-icon {
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block; }
  .svg-icon.customize-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M15.4462 9.19774C15.4462 12.9716 12.4191 16 8.72308 16C5.02702 16 2 12.9716 2 9.19774C2 5.4239 5.02702 2.39551 8.72308 2.39551C12.4191 2.39551 15.4462 5.4239 15.4462 9.19774Z' stroke='url(%23paint0_linear_432_5183)' stroke-width='4'/%3E%3Cpath d='M10.3859 9.99504L7.49839 10.8875C7.41781 10.9125 7.32989 10.892 7.26939 10.8342C7.20888 10.7765 7.18563 10.6908 7.20888 10.6112L8.04114 7.75703C8.05163 7.72138 8.07099 7.68866 8.09739 7.66206L10.3859 9.99504ZM10.3859 9.99504C10.4222 9.98395 10.4549 9.96418 10.4814 9.93749L10.3859 9.99504ZM15.4203 0.56685C15.4201 0.567028 15.4199 0.567206 15.4197 0.567384L8.29433 7.81833L8.54939 8.06181L10.0544 9.49845L10.3165 9.74864L17.4482 2.491L17.4489 2.49033C17.646 2.29122 17.7537 2.02469 17.7499 1.74929L15.4203 0.56685ZM15.4203 0.56685C15.6182 0.368282 15.8892 0.253852 16.1743 0.250095C16.4598 0.246337 16.7342 0.353786 16.9376 0.547424L17.4285 1.01601C17.631 1.20988 17.746 1.47375 17.7499 1.74922L15.4203 0.56685Z' fill='white' stroke='%23181818' stroke-width='0.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_432_5183' x1='0.311538' y1='34.5041' x2='18.4047' y2='34.5041' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FDD22A'/%3E%3Cstop offset='0.166667' stop-color='%23FF9244'/%3E%3Cstop offset='0.34375' stop-color='%23FF93FF'/%3E%3Cstop offset='0.536458' stop-color='%23AF0DFC'/%3E%3Cstop offset='0.697917' stop-color='%2300C4E6'/%3E%3Cstop offset='1' stop-color='%231EE1B2'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    height: 20px;
    width: 20px; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-long {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-long {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-long {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-long {
  0% {
    transform: rotateX(60deg) translateY(-120px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-long {
  0% {
    transform: rotateX(-60deg) translateY(120px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-long {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-long {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-long {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-long {
  0% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-medium {
  0% {
    opacity: 0.33; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-medium {
  0% {
    transform: scale(0.33);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-medium {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-medium {
  0% {
    transform: rotateX(60deg) translateY(-80px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-medium {
  0% {
    transform: rotateX(-60deg) translateY(80px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-medium {
  0% {
    transform: translateY(-66%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-medium {
  0% {
    transform: translateX(-66%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-medium {
  0% {
    transform: translateX(66%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-medium {
  0% {
    transform: translateY(66%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-short {
  0% {
    opacity: 0.66; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-short {
  0% {
    transform: scale(0.66);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-short {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-short {
  0% {
    transform: rotateX(60deg) translateY(-40px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-short {
  0% {
    transform: rotateX(-60deg) translateY(40px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-short {
  0% {
    transform: translateY(-33%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-short {
  0% {
    transform: translateX(-33%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-short {
  0% {
    transform: translateX(33%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-short {
  0% {
    transform: translateY(33%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-up {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-100%); } }

@keyframes slide-down {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

.swatch-circle-beige {
  width: 20px;
  height: 20px;
  background-color: #f5f5dc;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-black {
  width: 20px;
  height: 20px;
  background-color: #222222;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-blue {
  width: 20px;
  height: 20px;
  background-color: #3091E7;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-brown {
  width: 20px;
  height: 20px;
  background-color: #a52a2a;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-green {
  width: 20px;
  height: 20px;
  background-color: #2eab2b;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-grey {
  width: 20px;
  height: 20px;
  background-color: #8f979d;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-navy {
  width: 20px;
  height: 20px;
  background-color: #000080;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-orange {
  width: 20px;
  height: 20px;
  background-color: #ffa500;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-pink {
  width: 20px;
  height: 20px;
  background-color: #fe249a;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-purple {
  width: 20px;
  height: 20px;
  background-color: #800080;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-red {
  width: 20px;
  height: 20px;
  background-color: #f00;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-white {
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-yellow {
  width: 20px;
  height: 20px;
  background-color: #ff0;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 1px solid #D4D4D4;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  transform: rotate(35deg); }
  .swatch-circle-miscellaneous.swatch-mark.color-value.selected::after {
    transform: translate(50%, -50%) rotate(-35deg); }

.swatch-circle-white:not(.swatch-thumbnail),
.swatch[data-displayvalue="Sugar"]:not(.swatch-thumbnail),
.swatch[data-displayvalue="White"]:not(.swatch-thumbnail) {
  border: 1px solid #D4D4D4; }

.product-detail .color-swatches .unselectable-btn {
  position: relative;
  cursor: not-allowed;
  z-index: 1; }
  .product-detail .color-swatches .unselectable-btn + .unselectable-icon {
    pointer-events: none;
    cursor: not-allowed;
    position: absolute;
    height: 72px;
    width: 72px;
    bottom: 0;
    right: 0; }
    @media (max-width: 768.98px) {
      .product-detail .color-swatches .unselectable-btn + .unselectable-icon {
        height: calc(18.5vw);
        width: calc(18.5vw); } }
    .product-detail .color-swatches .unselectable-btn + .unselectable-icon:before, .product-detail .color-swatches .unselectable-btn + .unselectable-icon:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 18px);
      width: 1px; }
    .product-detail .color-swatches .unselectable-btn + .unselectable-icon:after {
      background-color: #FFFFFF;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
      z-index: 2;
      width: 2px; }

.product-detail .color-swatches .unselectable.Color-value.swatch-circle.selected {
  position: relative; }
  .product-detail .color-swatches .unselectable.Color-value.swatch-circle.selected:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) rotate(45deg);
    width: 1px;
    z-index: 2;
    height: calc(100% - 1px);
    background-color: transparent;
    display: block; }

.product-detail .color-swatches .customColor-value.unselectable {
  filter: blur(1px); }
  .product-detail .color-swatches .customColor-value.unselectable + .unselectable-icon {
    pointer-events: none;
    cursor: not-allowed;
    position: absolute;
    height: 72px;
    width: 72px;
    bottom: 0;
    right: 0; }
    @media (max-width: 768.98px) {
      .product-detail .color-swatches .customColor-value.unselectable + .unselectable-icon {
        height: calc(18.5vw);
        width: calc(18.5vw); } }
    .product-detail .color-swatches .customColor-value.unselectable + .unselectable-icon:before, .product-detail .color-swatches .customColor-value.unselectable + .unselectable-icon:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 18px);
      width: 1px; }
    .product-detail .color-swatches .customColor-value.unselectable + .unselectable-icon:after {
      background-color: #FFFFFF;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
      z-index: 2;
      width: 2px; }

.text--blue {
  color: #3091E7; }

.text--indigo {
  color: #6610f2; }

.text--purple {
  color: #6f42c1; }

.text--pink {
  color: #ce02d6; }

.text--red {
  color: #a80b1d; }

.text--orange {
  color: #fd7e14; }

.text--yellow {
  color: #FFCC00; }

.text--green {
  color: #2eab2b; }

.text--teal {
  color: #20c997; }

.text--cyan {
  color: #17a2b8; }

.text--white {
  color: #FFFFFF; }

.text--gray {
  color: #6c757d; }

.text--gray-dark {
  color: #343a40; }

.text--brand-primary {
  color: #181818; }

.text--light-gray {
  color: #F9F9F9; }

.text--medium-gray {
  color: #D4D4D4; }

.text--dark-gray {
  color: #595959; }

.text--primary-black {
  color: #181818; }

.text--primary-black-hover {
  color: #5d5d5d; }

.text--jade {
  color: #2EAB2B; }

.text--neon {
  color: #2EAB2B; }

.text--dark-mint {
  color: #76CBB4; }

.text--mint {
  color: #C6FDCF; }

.text--mint-2 {
  color: #d3f0ea; }

.text--sunshine {
  color: #FFDF59; }

.text--cobalt {
  color: #2867EC; }

.text--seafoam {
  color: #ACE7FA; }

.text--violet {
  color: #634ED4; }

.text--periwinkle {
  color: #6C8FFF; }

.text--watermelon {
  color: #FF748B; }

.text--hot-pink {
  color: #F5A4AD; }

.text--tangerine {
  color: #FF9244; }

.text--peach {
  color: #FFAF80; }

.text--light-peach {
  color: #FEF0E5; }

.text--success {
  color: #01DF9D; }

.text--danger {
  color: #a80b1d; }

.text--warning {
  color: #FFDF59; }

.text--info {
  color: #ACE7FA; }

.text--dark-red {
  color: #8B0000; }

.text--spring-wood {
  color: #F6F7F4; }

.background--blue {
  background-color: #3091E7; }

.background--indigo {
  background-color: #6610f2; }

.background--purple {
  background-color: #6f42c1; }

.background--pink {
  background-color: #ce02d6; }

.background--red {
  background-color: #a80b1d; }

.background--orange {
  background-color: #fd7e14; }

.background--yellow {
  background-color: #FFCC00; }

.background--green {
  background-color: #2eab2b; }

.background--teal {
  background-color: #20c997; }

.background--cyan {
  background-color: #17a2b8; }

.background--white {
  background-color: #FFFFFF; }

.background--gray {
  background-color: #6c757d; }

.background--gray-dark {
  background-color: #343a40; }

.background--brand-primary {
  background-color: #181818; }

.background--light-gray {
  background-color: #F9F9F9; }

.background--medium-gray {
  background-color: #D4D4D4; }

.background--dark-gray {
  background-color: #595959; }

.background--primary-black {
  background-color: #181818; }

.background--primary-black-hover {
  background-color: #5d5d5d; }

.background--jade {
  background-color: #2EAB2B; }

.background--neon {
  background-color: #2EAB2B; }

.background--dark-mint {
  background-color: #76CBB4; }

.background--mint {
  background-color: #C6FDCF; }

.background--mint-2 {
  background-color: #d3f0ea; }

.background--sunshine {
  background-color: #FFDF59; }

.background--cobalt {
  background-color: #2867EC; }

.background--seafoam {
  background-color: #ACE7FA; }

.background--violet {
  background-color: #634ED4; }

.background--periwinkle {
  background-color: #6C8FFF; }

.background--watermelon {
  background-color: #FF748B; }

.background--hot-pink {
  background-color: #F5A4AD; }

.background--tangerine {
  background-color: #FF9244; }

.background--peach {
  background-color: #FFAF80; }

.background--light-peach {
  background-color: #FEF0E5; }

.background--success {
  background-color: #01DF9D; }

.background--danger {
  background-color: #a80b1d; }

.background--warning {
  background-color: #FFDF59; }

.background--info {
  background-color: #ACE7FA; }

.background--dark-red {
  background-color: #8B0000; }

.background--spring-wood {
  background-color: #F6F7F4; }

.border--blue {
  border-color: #3091E7; }

.border--indigo {
  border-color: #6610f2; }

.border--purple {
  border-color: #6f42c1; }

.border--pink {
  border-color: #ce02d6; }

.border--red {
  border-color: #a80b1d; }

.border--orange {
  border-color: #fd7e14; }

.border--yellow {
  border-color: #FFCC00; }

.border--green {
  border-color: #2eab2b; }

.border--teal {
  border-color: #20c997; }

.border--cyan {
  border-color: #17a2b8; }

.border--white {
  border-color: #FFFFFF; }

.border--gray {
  border-color: #6c757d; }

.border--gray-dark {
  border-color: #343a40; }

.border--brand-primary {
  border-color: #181818; }

.border--light-gray {
  border-color: #F9F9F9; }

.border--medium-gray {
  border-color: #D4D4D4; }

.border--dark-gray {
  border-color: #595959; }

.border--primary-black {
  border-color: #181818; }

.border--primary-black-hover {
  border-color: #5d5d5d; }

.border--jade {
  border-color: #2EAB2B; }

.border--neon {
  border-color: #2EAB2B; }

.border--dark-mint {
  border-color: #76CBB4; }

.border--mint {
  border-color: #C6FDCF; }

.border--mint-2 {
  border-color: #d3f0ea; }

.border--sunshine {
  border-color: #FFDF59; }

.border--cobalt {
  border-color: #2867EC; }

.border--seafoam {
  border-color: #ACE7FA; }

.border--violet {
  border-color: #634ED4; }

.border--periwinkle {
  border-color: #6C8FFF; }

.border--watermelon {
  border-color: #FF748B; }

.border--hot-pink {
  border-color: #F5A4AD; }

.border--tangerine {
  border-color: #FF9244; }

.border--peach {
  border-color: #FFAF80; }

.border--light-peach {
  border-color: #FEF0E5; }

.border--success {
  border-color: #01DF9D; }

.border--danger {
  border-color: #a80b1d; }

.border--warning {
  border-color: #FFDF59; }

.border--info {
  border-color: #ACE7FA; }

.border--dark-red {
  border-color: #8B0000; }

.border--spring-wood {
  border-color: #F6F7F4; }

.font-size--0 {
  font-size: 0px !important; }

.font-size--1 {
  font-size: 1px !important; }

.font-size--2 {
  font-size: 2px !important; }

.font-size--3 {
  font-size: 3px !important; }

.font-size--4 {
  font-size: 4px !important; }

.font-size--5 {
  font-size: 5px !important; }

.font-size--6 {
  font-size: 6px !important; }

.font-size--7 {
  font-size: 7px !important; }

.font-size--8 {
  font-size: 8px !important; }

.font-size--9 {
  font-size: 9px !important; }

.font-size--10 {
  font-size: 10px !important; }

.font-size--11 {
  font-size: 11px !important; }

.font-size--12 {
  font-size: 12px !important; }

.font-size--13 {
  font-size: 13px !important; }

.font-size--14 {
  font-size: 14px !important; }

.font-size--15 {
  font-size: 15px !important; }

.font-size--16 {
  font-size: 16px !important; }

.font-size--17 {
  font-size: 17px !important; }

.font-size--18 {
  font-size: 18px !important; }

.font-size--19 {
  font-size: 19px !important; }

.font-size--20 {
  font-size: 20px !important; }

.font-size--21 {
  font-size: 21px !important; }

.font-size--22 {
  font-size: 22px !important; }

.font-size--23 {
  font-size: 23px !important; }

.font-size--24 {
  font-size: 24px !important; }

.font-size--25 {
  font-size: 25px !important; }

.font-size--26 {
  font-size: 26px !important; }

.font-size--27 {
  font-size: 27px !important; }

.font-size--28 {
  font-size: 28px !important; }

.font-size--29 {
  font-size: 29px !important; }

.font-size--30 {
  font-size: 30px !important; }

.font-size--31 {
  font-size: 31px !important; }

.font-size--32 {
  font-size: 32px !important; }

.font-size--33 {
  font-size: 33px !important; }

.font-size--34 {
  font-size: 34px !important; }

.font-size--35 {
  font-size: 35px !important; }

.font-size--36 {
  font-size: 36px !important; }

.font-size--37 {
  font-size: 37px !important; }

.font-size--38 {
  font-size: 38px !important; }

.font-size--39 {
  font-size: 39px !important; }

.font-size--40 {
  font-size: 40px !important; }

.font-size--41 {
  font-size: 41px !important; }

.font-size--42 {
  font-size: 42px !important; }

.font-size--43 {
  font-size: 43px !important; }

.font-size--44 {
  font-size: 44px !important; }

.font-size--45 {
  font-size: 45px !important; }

.font-size--46 {
  font-size: 46px !important; }

.font-size--47 {
  font-size: 47px !important; }

.font-size--48 {
  font-size: 48px !important; }

.font-size--49 {
  font-size: 49px !important; }

.font-size--50 {
  font-size: 50px !important; }

.font-size--51 {
  font-size: 51px !important; }

.font-size--52 {
  font-size: 52px !important; }

.font-size--53 {
  font-size: 53px !important; }

.font-size--54 {
  font-size: 54px !important; }

.font-size--55 {
  font-size: 55px !important; }

.font-size--56 {
  font-size: 56px !important; }

.font-size--57 {
  font-size: 57px !important; }

.font-size--58 {
  font-size: 58px !important; }

.font-size--59 {
  font-size: 59px !important; }

.font-size--60 {
  font-size: 60px !important; }

.line-height--0 {
  line-height: 0px !important; }

.line-height--1 {
  line-height: 1px !important; }

.line-height--2 {
  line-height: 2px !important; }

.line-height--3 {
  line-height: 3px !important; }

.line-height--4 {
  line-height: 4px !important; }

.line-height--5 {
  line-height: 5px !important; }

.line-height--6 {
  line-height: 6px !important; }

.line-height--7 {
  line-height: 7px !important; }

.line-height--8 {
  line-height: 8px !important; }

.line-height--9 {
  line-height: 9px !important; }

.line-height--10 {
  line-height: 10px !important; }

.line-height--11 {
  line-height: 11px !important; }

.line-height--12 {
  line-height: 12px !important; }

.line-height--13 {
  line-height: 13px !important; }

.line-height--14 {
  line-height: 14px !important; }

.line-height--15 {
  line-height: 15px !important; }

.line-height--16 {
  line-height: 16px !important; }

.line-height--17 {
  line-height: 17px !important; }

.line-height--18 {
  line-height: 18px !important; }

.line-height--19 {
  line-height: 19px !important; }

.line-height--20 {
  line-height: 20px !important; }

.line-height--21 {
  line-height: 21px !important; }

.line-height--22 {
  line-height: 22px !important; }

.line-height--23 {
  line-height: 23px !important; }

.line-height--24 {
  line-height: 24px !important; }

.line-height--25 {
  line-height: 25px !important; }

.line-height--26 {
  line-height: 26px !important; }

.line-height--27 {
  line-height: 27px !important; }

.line-height--28 {
  line-height: 28px !important; }

.line-height--29 {
  line-height: 29px !important; }

.line-height--30 {
  line-height: 30px !important; }

.line-height--31 {
  line-height: 31px !important; }

.line-height--32 {
  line-height: 32px !important; }

.line-height--33 {
  line-height: 33px !important; }

.line-height--34 {
  line-height: 34px !important; }

.line-height--35 {
  line-height: 35px !important; }

.line-height--36 {
  line-height: 36px !important; }

.line-height--37 {
  line-height: 37px !important; }

.line-height--38 {
  line-height: 38px !important; }

.line-height--39 {
  line-height: 39px !important; }

.line-height--40 {
  line-height: 40px !important; }

.line-height--41 {
  line-height: 41px !important; }

.line-height--42 {
  line-height: 42px !important; }

.line-height--43 {
  line-height: 43px !important; }

.line-height--44 {
  line-height: 44px !important; }

.line-height--45 {
  line-height: 45px !important; }

.line-height--46 {
  line-height: 46px !important; }

.line-height--47 {
  line-height: 47px !important; }

.line-height--48 {
  line-height: 48px !important; }

.line-height--49 {
  line-height: 49px !important; }

.line-height--50 {
  line-height: 50px !important; }

.line-height--51 {
  line-height: 51px !important; }

.line-height--52 {
  line-height: 52px !important; }

.line-height--53 {
  line-height: 53px !important; }

.line-height--54 {
  line-height: 54px !important; }

.line-height--55 {
  line-height: 55px !important; }

.line-height--56 {
  line-height: 56px !important; }

.line-height--57 {
  line-height: 57px !important; }

.line-height--58 {
  line-height: 58px !important; }

.line-height--59 {
  line-height: 59px !important; }

.line-height--60 {
  line-height: 60px !important; }

.font--primary {
  font-family: "Inter", sans-serif; }

.font--secondary {
  font-family: "Space Grotesk", sans-serif; }

.font--tertiary {
  font-family: "Space Grotesk", sans-serif; }

.font--header {
  font-family: "Space Grotesk", sans-serif; }

.font--body {
  font-family: "Inter", sans-serif; }

h1, .h1 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 32px;
  line-height: 36px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

h2, .h2 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  line-height: 36px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

h3, .h3 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 32px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

h4, .h4 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

h5, .h5 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

h6, .h6 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

p,
.p {
  letter-spacing: 0;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 24px; }
  @media (min-width: 1024px) {
    p,
    .p {
      font-size: 16px; } }
  @media (min-width: 1024px) {
    p,
    .p {
      line-height: 24px; } }
  p--small,
  .p--small {
    letter-spacing: 0;
    font-size: 16px;
    line-height: 24px; }
    @media (min-width: 1024px) {
      p--small,
      .p--small {
        font-size: 16px; } }
    @media (min-width: 1024px) {
      p--small,
      .p--small {
        line-height: 24px; } }
  p--large,
  .p--large {
    letter-spacing: 0;
    font-size: 24px; }
    @media (min-width: 1024px) {
      p--large,
      .p--large {
        font-size: 16px; } }

small {
  letter-spacing: 0;
  font-size: 16px; }
  @media (min-width: 1024px) {
    small {
      font-size: 16px; } }

.lead {
  letter-spacing: 0;
  font-size: 24px; }
  @media (min-width: 1024px) {
    .lead {
      font-size: 16px; } }

.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 32px;
  text-align: center;
  font-weight: 500;
  text-shadow: none;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 15px 8px 15px; }
  .btn:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out; }
  .btn:hover:after, .btn:focus:after, .btn[aria-describedby*="popover"]:after {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }
  .btn[class*="icon-"], .btn[class^="icon-"] {
    padding-left: 42px; }
    .btn[class*="icon-"]:before, .btn[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
  .btn-primary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 2px;
    border-style: solid;
    font-family: "Inter", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 32px;
    text-align: center;
    font-weight: 500;
    text-shadow: none;
    z-index: 1;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 8px 15px 8px 15px;
    font-family: "Inter", sans-serif;
    text-transform: none;
    color: #FFFFFF;
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    padding: 8px 15px 8px 15px; }
    .btn-primary:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 32px;
      z-index: -1;
      transition: all 200ms ease-out; }
    .btn-primary:hover:after, .btn-primary:focus:after, .btn-primary[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px; }
    .btn-primary:after {
      background-color: #181818; }
    .btn-primary:hover, .btn-primary:focus, .btn-primary[aria-describedby*="popover"] {
      color: #FFFFFF;
      text-decoration: none; }
      .btn-primary:hover:after, .btn-primary:focus:after, .btn-primary[aria-describedby*="popover"]:after {
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        top: -4px;
        left: -4px;
        background-color: #5d5d5d; }
    .dark-theme .btn-primary {
      color: #181818; }
      .dark-theme .btn-primary:after {
        background-color: #FFFFFF; }
      .dark-theme .btn-primary:hover, .dark-theme .btn-primary:focus, .dark-theme .btn-primary[aria-describedby*="popover"] {
        color: #181818; }
      .dark-theme .btn-primary.disabled, .dark-theme .btn-primary:disabled, .dark-theme .btn-primary[disabled] {
        color: #FFFFFF; }
        .dark-theme .btn-primary.disabled:after, .dark-theme .btn-primary:disabled:after, .dark-theme .btn-primary[disabled]:after {
          background-color: #D4D4D4; }
    .btn-primary.disabled, .btn-primary:disabled, .btn-primary[disabled] {
      color: #FFFFFF; }
      .btn-primary.disabled:after, .btn-primary:disabled:after, .btn-primary[disabled]:after {
        background-color: #D4D4D4; }
    .btn-primary[class*="icon-"], .btn-primary[class^="icon-"] {
      padding-left: 42px; }
      .btn-primary[class*="icon-"]:before, .btn-primary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-secondary, .btn-outline-primary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 2px;
    border-style: solid;
    font-family: "Inter", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 32px;
    text-align: center;
    font-weight: 500;
    text-shadow: none;
    z-index: 1;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 8px 15px 8px 15px;
    font-family: "Inter", sans-serif;
    text-transform: none;
    color: #181818;
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    padding: 8px 15px 8px 15px; }
    .btn-secondary:after, .btn-outline-primary:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 32px;
      z-index: -1;
      transition: all 200ms ease-out; }
    .btn-secondary:hover:after, .btn-secondary:focus:after, .btn-secondary[aria-describedby*="popover"]:after, .btn-outline-primary:hover:after, .btn-outline-primary:focus:after, .btn-outline-primary[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px; }
    .btn-secondary:after, .btn-outline-primary:after {
      background-color: rgba(255, 255, 255, 0);
      border-width: 2px;
      border-radius: 32px;
      border-color: #181818;
      border-style: solid; }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary[aria-describedby*="popover"], .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary[aria-describedby*="popover"] {
      color: #181818;
      text-decoration: none; }
      .btn-secondary:hover:after, .btn-secondary:focus:after, .btn-secondary[aria-describedby*="popover"]:after, .btn-outline-primary:hover:after, .btn-outline-primary:focus:after, .btn-outline-primary[aria-describedby*="popover"]:after {
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        top: -4px;
        left: -4px; }
    .dark-theme .btn-secondary, .dark-theme .btn-outline-primary {
      color: #FFFFFF; }
      .dark-theme .btn-secondary:after, .dark-theme .btn-outline-primary:after {
        border-color: #FFFFFF;
        background-color: rgba(24, 24, 24, 0); }
      .dark-theme .btn-secondary:hover, .dark-theme .btn-secondary:focus, .dark-theme .btn-secondary[aria-describedby*="popover"], .dark-theme .btn-outline-primary:hover, .dark-theme .btn-outline-primary:focus, .dark-theme .btn-outline-primary[aria-describedby*="popover"] {
        color: #FFFFFF; }
      .dark-theme .btn-secondary.disabled, .dark-theme .btn-secondary:disabled, .dark-theme .btn-secondary[disabled], .dark-theme .btn-outline-primary.disabled, .dark-theme .btn-outline-primary:disabled, .dark-theme .btn-outline-primary[disabled] {
        color: #D4D4D4; }
        .dark-theme .btn-secondary.disabled:after, .dark-theme .btn-secondary:disabled:after, .dark-theme .btn-secondary[disabled]:after, .dark-theme .btn-outline-primary.disabled:after, .dark-theme .btn-outline-primary:disabled:after, .dark-theme .btn-outline-primary[disabled]:after {
          border-color: #D4D4D4; }
    .btn-secondary.disabled, .btn-secondary:disabled, .btn-secondary[disabled], .btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary[disabled] {
      color: #D4D4D4; }
      .btn-secondary.disabled:after, .btn-secondary:disabled:after, .btn-secondary[disabled]:after, .btn-outline-primary.disabled:after, .btn-outline-primary:disabled:after, .btn-outline-primary[disabled]:after {
        border-color: #D4D4D4; }
    .btn-secondary[class*="icon-"], .btn-secondary[class^="icon-"], .btn-outline-primary[class*="icon-"], .btn-outline-primary[class^="icon-"] {
      padding-left: 42px; }
      .btn-secondary[class*="icon-"]:before, .btn-secondary[class^="icon-"]:before, .btn-outline-primary[class*="icon-"]:before, .btn-outline-primary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-tertiary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 2px;
    border-style: solid;
    font-family: "Inter", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 32px;
    text-align: center;
    font-weight: 500;
    text-shadow: none;
    z-index: 1;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 8px 15px 8px 15px;
    font-family: "Inter", sans-serif;
    text-transform: none;
    color: #181818;
    border-width: 0;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0);
    border-color: #181818;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
    padding: 0 0 0 0; }
    .btn-tertiary:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 32px;
      z-index: -1;
      transition: all 200ms ease-out; }
    .btn-tertiary:hover:after, .btn-tertiary:focus:after, .btn-tertiary[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px; }
    .btn-tertiary:after {
      display: none; }
    .btn-tertiary:hover, .btn-tertiary:focus, .btn-tertiary[aria-describedby*="popover"] {
      color: #181818;
      background-color: rgba(255, 255, 255, 0);
      border-color: #181818;
      text-decoration: underline; }
    .dark-theme .btn-tertiary {
      color: #FFFFFF;
      border-color: #FFFFFF; }
      .dark-theme .btn-tertiary:hover, .dark-theme .btn-tertiary:focus, .dark-theme .btn-tertiary[aria-describedby*="popover"] {
        color: #FFFFFF;
        border-color: #FFFFFF; }
      .dark-theme .btn-tertiary.disabled, .dark-theme .btn-tertiary:disabled, .dark-theme .btn-tertiary[disabled] {
        color: #FFFFFF; }
    .btn-tertiary.disabled, .btn-tertiary:disabled, .btn-tertiary[disabled] {
      color: #FFFFFF; }
    .btn-tertiary[class*="icon-"], .btn-tertiary[class^="icon-"] {
      padding-left: 42px; }
      .btn-tertiary[class*="icon-"]:before, .btn-tertiary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-sm, .btn-group-sm > .btn {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 2px;
    border-style: solid;
    font-family: "Inter", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 32px;
    text-align: center;
    font-weight: 500;
    text-shadow: none;
    z-index: 1;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 20px 5px 20px;
    letter-spacing: 0.05em; }
    .btn-sm:after, .btn-group-sm > .btn:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 32px;
      z-index: -1;
      transition: all 200ms ease-out; }
    .btn-sm:hover:after, .btn-group-sm > .btn:hover:after, .btn-sm:focus:after, .btn-group-sm > .btn:focus:after, .btn-sm[aria-describedby*="popover"]:after, .btn-group-sm > .btn[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px; }
    .btn-sm[class*="icon-"], .btn-group-sm > .btn[class*="icon-"], .btn-sm[class^="icon-"], .btn-group-sm > .btn[class^="icon-"] {
      padding-left: 42px;
      padding-left: 42px; }
      .btn-sm[class*="icon-"]:before, .btn-group-sm > .btn[class*="icon-"]:before, .btn-sm[class^="icon-"]:before, .btn-group-sm > .btn[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
  .btn-block {
    display: block;
    width: 100%; }

a,
.text-link {
  color: #181818;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer; }
  a:hover,
  .text-link:hover {
    color: rgba(24, 24, 24, 0.8);
    text-decoration: underline; }
  .dark-theme a, .dark-theme
  .text-link {
    color: #FFFFFF; }
    .dark-theme a:hover, .dark-theme
    .text-link:hover {
      color: #FFFFFF; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.header-link {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.05em; }
  .header-link:hover {
    text-decoration: none; }

.navigation-link {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0; }
  .navigation-link:hover {
    text-decoration: none; }

.visually-hidden {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  height: 1px;
  width: 1px; }

.lock-scroll {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%; }

.leading-lines {
  overflow: hidden;
  margin: 0 0 16px; }
  .leading-lines label {
    background-color: #F9F9F9; }
  .leading-lines p {
    margin-bottom: 0; }
  .leading-lines .start-lines {
    padding: 0;
    align-self: center; }
    .leading-lines .start-lines span {
      position: relative;
      background-color: #F9F9F9;
      z-index: 2;
      display: inline-block; }
      .leading-lines .start-lines span:only-child, .leading-lines .start-lines span:last-child {
        padding-right: 16px; }
    .leading-lines .start-lines::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100vw;
      white-space: nowrap;
      content: "";
      border-bottom: 0 dotted transparent;
      margin-top: 1px;
      z-index: 1; }
  .leading-lines .end-lines {
    padding: 0;
    align-self: center; }
    .leading-lines .end-lines span {
      position: relative;
      background-color: #F9F9F9;
      z-index: 2;
      display: inline-block; }
      .leading-lines .end-lines span:only-child, .leading-lines .end-lines span:last-child {
        padding-left: 16px;
        padding-right: 16px;
        margin-right: -16px; }

.email-signup-message {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.email-signup-alert {
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.4);
  text-align: center; }
  .email-signup-alert.show {
    display: block; }
  .email-signup-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .email-signup-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .email-signup-alert.alert-dismissible .close {
      padding: 16px; }

.swatch-mark.Color-value {
  transition: box-shadow 200ms ease-out; }
  .swatch-mark.Color-value:hover {
    box-shadow: none; }
  .swatch-mark.Color-value.selected {
    border: 2px solid #181818;
    border-radius: 4px;
    box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
    .swatch-mark.Color-value.selected:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 7px;
      color: #FFFFFF;
      font-weight: normal; }
    .swatch-mark.Color-value.selected[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .swatch-mark.Color-value.selected[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .swatch-mark.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.Color-value.selected.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .swatch-mark.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.Color-value.selected.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .swatch-mark.Color-value.selected.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .swatch-mark.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .btn-group-lg > .swatch-mark.Color-value.selected.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.Color-value.selected[class*="btn-"]:not(.title) .fa {
        display: none; }
    .swatch-mark.Color-value.selected::after {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      display: none;
      background: rgba(34, 34, 34, 0.7);
      width: 14px;
      height: 14px;
      line-height: 14px;
      padding-left: 1px;
      text-align: center;
      border-radius: 50%;
      z-index: 1; }
  .swatch-mark.Color-value.unselectable {
    filter: blur(0px); }
    .swatch-mark.Color-value.unselectable + .unselectable-icon {
      pointer-events: none;
      cursor: not-allowed;
      position: absolute;
      height: 72px;
      width: 72px;
      bottom: 0;
      right: 0; }
      @media (max-width: 768.98px) {
        .swatch-mark.Color-value.unselectable + .unselectable-icon {
          height: calc(18.5vw);
          width: calc(18.5vw); } }
      .swatch-mark.Color-value.unselectable + .unselectable-icon:before, .swatch-mark.Color-value.unselectable + .unselectable-icon:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(100% + 18px);
        width: 1px; }
      .swatch-mark.Color-value.unselectable + .unselectable-icon:after {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
        z-index: 2;
        width: 2px; }
  .swatch-mark.Color-value.unselectable {
    pointer-events: none;
    pointer-events: auto;
    cursor: not-allowed;
    position: relative; }

.swatch-mark.non-color-swatch-value {
  transition: border 200ms ease-out, box-shadow 200ms ease-out; }
  .swatch-mark.non-color-swatch-value:not(.unselectable):hover {
    box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
  .swatch-mark.non-color-swatch-value.selected, .swatch-mark.non-color-swatch-value.selected:hover {
    background: #F9F9F9;
    color: #222222;
    border-color: #181818;
    box-shadow: none; }
    .swatch-mark.non-color-swatch-value.selected:after, .swatch-mark.non-color-swatch-value.selected:hover:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 8px;
      color: #FFFFFF;
      font-weight: normal; }
    .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title), .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .btn-group-lg > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .btn-group-lg > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
        display: none; }
    .swatch-mark.non-color-swatch-value.selected::after, .swatch-mark.non-color-swatch-value.selected:hover::after {
      position: absolute;
      top: -5.33333px;
      right: -5.33333px;
      background: #181818;
      width: 16px;
      height: 16px;
      line-height: 16px;
      padding-left: 1px;
      text-align: center;
      border-radius: 50%;
      display: none; }
  .swatch-mark.non-color-swatch-value.unselectable {
    pointer-events: auto;
    cursor: not-allowed;
    position: relative;
    color: #D4D4D4; }

.disabled,
:disabled,
[disabled] {
  pointer-events: none; }

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.horizontal-center {
  position: absolute;
  right: 50%;
  transform: translateX(50%); }

.absolute-center {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%); }

.fade-in {
  animation-name: fadeIn;
  animation-timing-function: ease-out; }

.fade-out {
  animation-name: fadeOut;
  animation-timing-function: ease-out; }

.zoom-in {
  animation-name: scale-in-center-long;
  animation-timing-function: ease-out; }

[data-animation]:not(.disable-mobile-animation) {
  will-change: transform, opacity; }
  [data-animation]:not(.disable-mobile-animation) > [style*="animation-delay"],
  [data-animation]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    will-change: transform, opacity; }

@media (min-width: 1024px) {
  [data-animation].disable-mobile-animation {
    will-change: transform, opacity; }
    [data-animation].disable-mobile-animation > [style*="animation-delay"],
    [data-animation].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      will-change: transform, opacity; } }

.animation-initial-state:not(.disable-mobile-animation) {
  opacity: 0; }

@media (min-width: 1024px) {
  .animation-initial-state.disable-mobile-animation {
    opacity: 0; } }

.scale-in-center--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--short.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.scale-in-center--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--medium.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.scale-in-center--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--long.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hinge-drop--short:not(.disable-mobile-animation) {
  perspective: 1000px; }
  .hinge-drop--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-short 400ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--short.disable-mobile-animation {
    perspective: 1000px; }
    .hinge-drop--short.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-short 400ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.hinge-drop--medium:not(.disable-mobile-animation) {
  perspective: 750px; }
  .hinge-drop--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-medium 1200ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--medium.disable-mobile-animation {
    perspective: 750px; }
    .hinge-drop--medium.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-medium 1200ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.hinge-drop--long:not(.disable-mobile-animation) {
  perspective: 500px; }
  .hinge-drop--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-long 2000ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--long.disable-mobile-animation {
    perspective: 500px; }
    .hinge-drop--long.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-long 2000ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.flip-in-hor-top--short:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--short.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--short.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-top--medium:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--medium.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--medium.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-top--long:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--long.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--long.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--short:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--short.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--short.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--medium:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--medium.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--medium.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--long:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--long.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--long.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.slide-in-top--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-top--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-top--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.fade-in--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--short.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.fade-in--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--medium.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.fade-in--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--long.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short {
  animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium {
  animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long {
  animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short > .container > .row > [style*="animation-delay"] {
      animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium > .container > .row > [style*="animation-delay"] {
      animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long > .container > .row > [style*="animation-delay"] {
      animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short {
  animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium {
  animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long {
  animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short {
    animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium {
    animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long {
    animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; } }

.hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short {
  animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium {
  animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long {
  animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short {
  animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium {
  animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long {
  animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short {
  animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium {
  animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long {
  animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short {
  animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium {
  animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long {
  animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short {
  animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium {
  animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long {
  animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short {
  animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium {
  animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long {
  animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short {
  animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short > .container > .row > [style*="animation-delay"] {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium {
  animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium > .container > .row > [style*="animation-delay"] {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long {
  animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long > .container > .row > [style*="animation-delay"] {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short > .container > .row > [style*="animation-delay"] {
      animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium > .container > .row > [style*="animation-delay"] {
      animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long > .container > .row > [style*="animation-delay"] {
      animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.headline-xl {
  font-family: "Space Grotesk", sans-serif;
  font-size: 72px;
  line-height: 80px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -2px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .headline-xl {
      font-family: "Space Grotesk", sans-serif;
      font-size: 128px;
      line-height: 140px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -2px;
      font-style: normal;
      font-display: swap; } }

.headline-l {
  font-family: "Space Grotesk", sans-serif;
  font-size: 48px;
  line-height: 52px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -2px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .headline-l {
      font-family: "Space Grotesk", sans-serif;
      font-size: 72px;
      line-height: 80px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -2px;
      font-style: normal;
      font-display: swap; } }

.headline-m {
  font-family: "Space Grotesk", sans-serif;
  font-size: 32px;
  line-height: 36px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -2px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .headline-m {
      font-family: "Space Grotesk", sans-serif;
      font-size: 48px;
      line-height: 52px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -2px;
      font-style: normal;
      font-display: swap; } }

.headline-s {
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  line-height: 26px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .headline-s {
      font-family: "Space Grotesk", sans-serif;
      font-size: 32px;
      line-height: 36px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

.headline-xs {
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  line-height: 26px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .headline-xs {
      font-family: "Space Grotesk", sans-serif;
      font-size: 24px;
      line-height: 26px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

.body-copy-l {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 32px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-l {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      line-height: 32px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-l-book {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-l-book {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      line-height: 32px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-m {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-m {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-m-book {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-m-book {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-s {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-s {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-s-book {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-s-book {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-xs {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-xs {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-xs-book {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-xs-book {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-xxs {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-xxs {
      font-family: "Inter", sans-serif;
      font-size: 12px;
      line-height: 16px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.body-copy-xxs-book {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .body-copy-xxs-book {
      font-family: "Inter", sans-serif;
      font-size: 12px;
      line-height: 16px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.header-l {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  line-height: 56px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -2px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .header-l {
      font-family: "Inter", sans-serif;
      font-size: 72px;
      line-height: 80px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -2px;
      font-style: normal;
      font-display: swap; } }

.header-m {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 40px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .header-m {
      font-family: "Inter", sans-serif;
      font-size: 48px;
      line-height: 56px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

.header-s {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 32px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .header-s {
      font-family: "Inter", sans-serif;
      font-size: 32px;
      line-height: 40px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

.header-xs {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 32px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .header-xs {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      line-height: 32px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.headline-xxl {
  font-family: "Inter", sans-serif;
  font-size: 96px;
  line-height: 96px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -6px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .headline-xxl {
      font-family: "Inter", sans-serif;
      font-size: 144px;
      line-height: 144px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -6px;
      font-style: normal;
      font-display: swap; } }

.btn-tertiary {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 32px;
  text-align: center;
  font-weight: 500;
  text-shadow: none;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 15px 8px 15px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  color: #181818;
  border-width: 0;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0);
  border-color: #181818;
  text-decoration: underline;
  text-align: center;
  font-weight: 500;
  padding: 0 0 0 0; }
  .btn-tertiary:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out; }
  .btn-tertiary:hover:after, .btn-tertiary:focus:after, .btn-tertiary[aria-describedby*="popover"]:after {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }
  .btn-tertiary:after {
    display: none; }
  .btn-tertiary:hover, .btn-tertiary:focus, .btn-tertiary[aria-describedby*="popover"] {
    color: #181818;
    background-color: rgba(255, 255, 255, 0);
    border-color: #181818;
    text-decoration: underline; }
  .dark-theme .btn-tertiary {
    color: #FFFFFF;
    border-color: #FFFFFF; }
    .dark-theme .btn-tertiary:hover, .dark-theme .btn-tertiary:focus, .dark-theme .btn-tertiary[aria-describedby*="popover"] {
      color: #FFFFFF;
      border-color: #FFFFFF; }
    .dark-theme .btn-tertiary.disabled, .dark-theme .btn-tertiary:disabled, .dark-theme .btn-tertiary[disabled] {
      color: #FFFFFF; }
  .btn-tertiary.disabled, .btn-tertiary:disabled, .btn-tertiary[disabled] {
    color: #FFFFFF; }
  .btn-tertiary[class*="icon-"], .btn-tertiary[class^="icon-"] {
    padding-left: 42px; }
    .btn-tertiary[class*="icon-"]:before, .btn-tertiary[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }

.btn-add-to-cart, .btn.add-to-cart, .btn.add-to-cart-global {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 32px;
  text-align: center;
  font-weight: 500;
  text-shadow: none;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 15px 8px 15px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  color: #181818;
  background: transparent;
  border: none;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding: 16px 32px 16px 32px;
  line-height: 24px;
  letter-spacing: 0;
  min-height: calc(24px + 16px + 16px); }
  .btn-add-to-cart:after, .btn.add-to-cart:after, .btn.add-to-cart-global:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out; }
  .btn-add-to-cart:hover:after, .btn-add-to-cart:focus:after, .btn-add-to-cart[aria-describedby*="popover"]:after, .btn.add-to-cart:hover:after, .btn.add-to-cart:focus:after, .btn.add-to-cart[aria-describedby*="popover"]:after, .btn.add-to-cart-global:hover:after, .btn.add-to-cart-global:focus:after, .btn.add-to-cart-global[aria-describedby*="popover"]:after {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }
  .btn-add-to-cart:after, .btn.add-to-cart:after, .btn.add-to-cart-global:after {
    background-color: #FF9244; }
  .btn-add-to-cart:hover, .btn-add-to-cart:focus, .btn-add-to-cart[aria-describedby*="popover"], .btn.add-to-cart:hover, .btn.add-to-cart:focus, .btn.add-to-cart[aria-describedby*="popover"], .btn.add-to-cart-global:hover, .btn.add-to-cart-global:focus, .btn.add-to-cart-global[aria-describedby*="popover"] {
    text-decoration: none; }
    .btn-add-to-cart:hover:after, .btn-add-to-cart:focus:after, .btn-add-to-cart[aria-describedby*="popover"]:after, .btn.add-to-cart:hover:after, .btn.add-to-cart:focus:after, .btn.add-to-cart[aria-describedby*="popover"]:after, .btn.add-to-cart-global:hover:after, .btn.add-to-cart-global:focus:after, .btn.add-to-cart-global[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px; }
  .dark-theme .btn-add-to-cart, .dark-theme .btn.add-to-cart, .dark-theme .btn.add-to-cart-global {
    color: #181818; }
    .dark-theme .btn-add-to-cart:after, .dark-theme .btn.add-to-cart:after, .dark-theme .btn.add-to-cart-global:after {
      background-color: #FF9244; }
    .dark-theme .btn-add-to-cart:hover, .dark-theme .btn.add-to-cart:hover, .dark-theme .btn.add-to-cart-global:hover {
      color: #181818; }
    .dark-theme .btn-add-to-cart.disabled, .dark-theme .btn-add-to-cart:disabled, .dark-theme .btn-add-to-cart[disabled], .dark-theme .btn.add-to-cart.disabled, .dark-theme .btn.add-to-cart:disabled, .dark-theme .btn.add-to-cart[disabled], .dark-theme .btn.add-to-cart-global.disabled, .dark-theme .btn.add-to-cart-global:disabled, .dark-theme .btn.add-to-cart-global[disabled] {
      color: #FFFFFF; }
      .dark-theme .btn-add-to-cart.disabled:after, .dark-theme .btn-add-to-cart:disabled:after, .dark-theme .btn-add-to-cart[disabled]:after, .dark-theme .btn.add-to-cart.disabled:after, .dark-theme .btn.add-to-cart:disabled:after, .dark-theme .btn.add-to-cart[disabled]:after, .dark-theme .btn.add-to-cart-global.disabled:after, .dark-theme .btn.add-to-cart-global:disabled:after, .dark-theme .btn.add-to-cart-global[disabled]:after {
        background-color: #D4D4D4; }
  .btn-add-to-cart.disabled, .btn-add-to-cart:disabled, .btn-add-to-cart[disabled], .btn.add-to-cart.disabled, .btn.add-to-cart:disabled, .btn.add-to-cart[disabled], .btn.add-to-cart-global.disabled, .btn.add-to-cart-global:disabled, .btn.add-to-cart-global[disabled] {
    color: #FFFFFF; }
    .btn-add-to-cart.disabled:after, .btn-add-to-cart:disabled:after, .btn-add-to-cart[disabled]:after, .btn.add-to-cart.disabled:after, .btn.add-to-cart:disabled:after, .btn.add-to-cart[disabled]:after, .btn.add-to-cart-global.disabled:after, .btn.add-to-cart-global:disabled:after, .btn.add-to-cart-global[disabled]:after {
      background-color: #D4D4D4; }
  .btn-add-to-cart[class*="icon-"], .btn-add-to-cart[class^="icon-"], .btn.add-to-cart[class*="icon-"], .btn.add-to-cart[class^="icon-"], .btn.add-to-cart-global[class*="icon-"], .btn.add-to-cart-global[class^="icon-"] {
    padding-left: 42px; }
    .btn-add-to-cart[class*="icon-"]:before, .btn-add-to-cart[class^="icon-"]:before, .btn.add-to-cart[class*="icon-"]:before, .btn.add-to-cart[class^="icon-"]:before, .btn.add-to-cart-global[class*="icon-"]:before, .btn.add-to-cart-global[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }

.btn-lg, .btn-group-lg > .btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 32px;
  text-align: center;
  font-weight: 500;
  text-shadow: none;
  z-index: 1;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 32px 16px 32px;
  letter-spacing: 0; }
  .btn-lg:after, .btn-group-lg > .btn:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out; }
  .btn-lg:hover:after, .btn-group-lg > .btn:hover:after, .btn-lg:focus:after, .btn-group-lg > .btn:focus:after, .btn-lg[aria-describedby*="popover"]:after, .btn-group-lg > .btn[aria-describedby*="popover"]:after {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }
  .btn-lg[class*="icon-"], .btn-group-lg > .btn[class*="icon-"], .btn-lg[class^="icon-"], .btn-group-lg > .btn[class^="icon-"] {
    padding-left: 42px;
    padding-left: 63px; }
    .btn-lg[class*="icon-"]:before, .btn-group-lg > .btn[class*="icon-"]:before, .btn-lg[class^="icon-"]:before, .btn-group-lg > .btn[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }

.full-height-children > .container > .row {
  align-items: center !important; }

.full-height-children .experience-component,
.full-height-children .hero {
  height: 100%; }

.rounded-corners {
  border-radius: 20px; }

.rounded-images .photo-tile-container {
  border-radius: 20px;
  overflow: hidden; }

.rounded-images .hero-media {
  border-radius: 20px; }

.rounded-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; }
  @media (max-width: 768.98px) {
    .rounded-right-mobile {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; } }
  @media (min-width: 769px) {
    .rounded-right-desktop {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; } }

.rounded-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; }
  @media (max-width: 768.98px) {
    .rounded-left-mobile {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; } }
  @media (min-width: 769px) {
    .rounded-left-desktop {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; } }

.rounded-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px; }
  @media (max-width: 768.98px) {
    .rounded-top-mobile {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px; } }
  @media (min-width: 769px) {
    .rounded-top-desktop {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px; } }

.rounded-bottom {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; }
  @media (max-width: 768.98px) {
    .rounded-bottom-mobile {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px; } }
  @media (min-width: 769px) {
    .rounded-bottom-desktop {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px; } }

.two-column-slider .region.col-12.col-md-6:nth-of-type(2) {
  padding-right: 0;
  padding-left: 0; }
  .two-column-slider .region.col-12.col-md-6:nth-of-type(2) .mobile-1r-1c {
    border-radius: 0 0 20px 20px; }
    @media (min-width: 769px) {
      .two-column-slider .region.col-12.col-md-6:nth-of-type(2) .mobile-1r-1c {
        border-radius: 0 20px 20px 0; } }

.slider-container.slider-product-tile .tns-controls button {
  top: 33%;
  transform: translateY(-33%); }

@media (max-width: 768.98px) {
  .slider-container.slider-product-tile {
    margin-left: -16px; }
    .slider-container.slider-product-tile .slider {
      padding-left: 16px;
      padding-right: 16px; } }

@media (max-width: 768.98px) {
  .slider-container.slider-product-tile {
    margin-left: -16px; }
    .slider-container.slider-product-tile .slider {
      padding-left: 16px;
      padding-right: 16px; } }

@media (max-width: 1023.98px) {
  .slider-container.slider-reviews .hero.half-and-half .hero-container {
    padding: 16px 32px 0; } }

.slider-container.slider-reviews .hero.half-and-half .hero-media,
.slider-container.slider-reviews .hero.half-and-half .hero-media.width-1-2 {
  max-width: 320px;
  order: 1;
  background-size: contain; }
  @media (max-width: 1023.98px) {
    .slider-container.slider-reviews .hero.half-and-half .hero-media,
    .slider-container.slider-reviews .hero.half-and-half .hero-media.width-1-2 {
      height: 320px;
      margin-bottom: 16px; } }
  @media (min-width: 769px) {
    .slider-container.slider-reviews .hero.half-and-half .hero-media,
    .slider-container.slider-reviews .hero.half-and-half .hero-media.width-1-2 {
      right: 12.5%; } }

.slider-container.slider-reviews .hero.half-and-half .hero-text .hero-subtitle + .hero-title,
.slider-container.slider-reviews .hero.half-and-half .hero-text .hero-title + .hero-body,
.slider-container.slider-reviews .hero.half-and-half .hero-text .hero-embedded-component + .hero-body,
.slider-container.slider-reviews .hero.half-and-half .hero-text .hero-embedded-component .experience-region > *,
.slider-container.slider-reviews .hero.half-and-half .hero-text .hero-body + .hero-buttons .hero-button {
  margin-top: 32px; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-1"] {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-1"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-2"] {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-2"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-3"] {
  flex: 0 0 25%;
  max-width: 25%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-3"] + .region[class*="col-12"],
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-3"] + .region[class*="col-6"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-4"] {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-4"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-5"] {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-5"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-6"] {
  flex: 0 0 50%;
  max-width: 50%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-6"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-7"] {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-7"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-8"] {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-8"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-9"] {
  flex: 0 0 75%;
  max-width: 75%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-9"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-10"] {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-10"] + .region[class*="col-12"] {
    margin-top: 0; }

.no-wrap-on-mobile > .container > .row > .region[class*="col-md-11"] {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }
  .no-wrap-on-mobile > .container > .row > .region[class*="col-md-11"] + .region[class*="col-12"] {
    margin-top: 0; }

/*Gradients TODO - build mixin for this*/
.background--gradient-jade-periwinkle {
  background: linear-gradient(180deg, #2EAB2B 0%, #6C8FFF 100%); }

.background--gradient-cobalt-violet {
  background: linear-gradient(180deg, #2867EC 0%, #634ED4 100%); }

.background--gradient-peach-watermelon {
  background: linear-gradient(180deg, #FFAF80 0%, #FF748B 100%); }

.background--gradient-sunshine-tangerine {
  background: linear-gradient(180deg, #FFDF59 0%, #FF9244 100%); }

.background--gradient-sunshine-jade {
  background: linear-gradient(180deg, #FFDF59 0%, #2EAB2B 100%); }

.background--gradient-watermelon-cobalt {
  background: linear-gradient(180deg, #FF748B 0%, #2867EC 100%); }

.background--none {
  background: none !important;
  background-color: transparent !important; }

.background--contain {
  background-size: contain !important; }

.background--cover {
  background-size: cover !important; }

/* rotate classes TODO: mixin to take integer for duration */
.rotate {
  animation: rotation 25s infinite linear; }
  .rotate--fast {
    animation-duration: 15s; }
  .rotate--medium {
    animation-duration: 35s; }
  .rotate--slow {
    animation-duration: 45s; }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.headline-bg-rotate {
  z-index: 1;
  position: relative; }
  .headline-bg-rotate:after {
    content: '';
    z-index: -1;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
    animation: rotation 25s infinite linear; }
  .headline-bg-rotate.img-donate:after {
    width: 50px;
    height: 50px;
    background-image: url("../../images/charitycircle.png");
    background-position: 50%;
    top: 2px;
    margin-left: -20px; }

.btn-customize {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  border-radius: 32px;
  text-shadow: none;
  z-index: 1;
  letter-spacing: 0;
  text-transform: none;
  color: #181818;
  background-color: transparent;
  border: none;
  text-decoration: none;
  text-align: center;
  padding: 8px 32px 8px 16px; }
  @media (min-width: 769px) {
    .btn-customize {
      padding: 8px 44px 8px 16px; } }
  .btn-customize::after {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M15.4462 9.19774C15.4462 12.9716 12.4191 16 8.72308 16C5.02702 16 2 12.9716 2 9.19774C2 5.4239 5.02702 2.39551 8.72308 2.39551C12.4191 2.39551 15.4462 5.4239 15.4462 9.19774Z' stroke='url(%23paint0_linear_432_5183)' stroke-width='4'/%3E%3Cpath d='M10.3859 9.99504L7.49839 10.8875C7.41781 10.9125 7.32989 10.892 7.26939 10.8342C7.20888 10.7765 7.18563 10.6908 7.20888 10.6112L8.04114 7.75703C8.05163 7.72138 8.07099 7.68866 8.09739 7.66206L10.3859 9.99504ZM10.3859 9.99504C10.4222 9.98395 10.4549 9.96418 10.4814 9.93749L10.3859 9.99504ZM15.4203 0.56685C15.4201 0.567028 15.4199 0.567206 15.4197 0.567384L8.29433 7.81833L8.54939 8.06181L10.0544 9.49845L10.3165 9.74864L17.4482 2.491L17.4489 2.49033C17.646 2.29122 17.7537 2.02469 17.7499 1.74929L15.4203 0.56685ZM15.4203 0.56685C15.6182 0.368282 15.8892 0.253852 16.1743 0.250095C16.4598 0.246337 16.7342 0.353786 16.9376 0.547424L17.4285 1.01601C17.631 1.20988 17.746 1.47375 17.7499 1.74922L15.4203 0.56685Z' fill='white' stroke='%23181818' stroke-width='0.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_432_5183' x1='0.311538' y1='34.5041' x2='18.4047' y2='34.5041' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FDD22A'/%3E%3Cstop offset='0.166667' stop-color='%23FF9244'/%3E%3Cstop offset='0.34375' stop-color='%23FF93FF'/%3E%3Cstop offset='0.536458' stop-color='%23AF0DFC'/%3E%3Cstop offset='0.697917' stop-color='%2300C4E6'/%3E%3Cstop offset='1' stop-color='%231EE1B2'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    height: 20px;
    width: 21.92px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    left: auto; }
    @media (min-width: 769px) {
      .btn-customize::after {
        right: 16px; } }
  .btn-customize::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out;
    border: 2px solid #000000; }
  .btn-customize:hover::before, .btn-customize:focus::before, .btn-customize[aria-describedby*="popover"]::before {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }

.btn.btn-customize {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  border-radius: 32px;
  text-shadow: none;
  z-index: 1;
  letter-spacing: 0;
  text-transform: none;
  color: #181818;
  background-color: transparent;
  border: none;
  text-decoration: none;
  text-align: center;
  padding: 8px 32px 8px 16px; }
  @media (min-width: 769px) {
    .btn.btn-customize {
      padding: 8px 44px 8px 16px; } }
  .btn.btn-customize::after {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M15.4462 9.19774C15.4462 12.9716 12.4191 16 8.72308 16C5.02702 16 2 12.9716 2 9.19774C2 5.4239 5.02702 2.39551 8.72308 2.39551C12.4191 2.39551 15.4462 5.4239 15.4462 9.19774Z' stroke='url(%23paint0_linear_432_5183)' stroke-width='4'/%3E%3Cpath d='M10.3859 9.99504L7.49839 10.8875C7.41781 10.9125 7.32989 10.892 7.26939 10.8342C7.20888 10.7765 7.18563 10.6908 7.20888 10.6112L8.04114 7.75703C8.05163 7.72138 8.07099 7.68866 8.09739 7.66206L10.3859 9.99504ZM10.3859 9.99504C10.4222 9.98395 10.4549 9.96418 10.4814 9.93749L10.3859 9.99504ZM15.4203 0.56685C15.4201 0.567028 15.4199 0.567206 15.4197 0.567384L8.29433 7.81833L8.54939 8.06181L10.0544 9.49845L10.3165 9.74864L17.4482 2.491L17.4489 2.49033C17.646 2.29122 17.7537 2.02469 17.7499 1.74929L15.4203 0.56685ZM15.4203 0.56685C15.6182 0.368282 15.8892 0.253852 16.1743 0.250095C16.4598 0.246337 16.7342 0.353786 16.9376 0.547424L17.4285 1.01601C17.631 1.20988 17.746 1.47375 17.7499 1.74922L15.4203 0.56685Z' fill='white' stroke='%23181818' stroke-width='0.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_432_5183' x1='0.311538' y1='34.5041' x2='18.4047' y2='34.5041' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FDD22A'/%3E%3Cstop offset='0.166667' stop-color='%23FF9244'/%3E%3Cstop offset='0.34375' stop-color='%23FF93FF'/%3E%3Cstop offset='0.536458' stop-color='%23AF0DFC'/%3E%3Cstop offset='0.697917' stop-color='%2300C4E6'/%3E%3Cstop offset='1' stop-color='%231EE1B2'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    height: 20px;
    width: 21.92px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    left: auto; }
    @media (min-width: 769px) {
      .btn.btn-customize::after {
        right: 16px; } }
  .btn.btn-customize::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out;
    border: 2px solid #000000; }
  .btn.btn-customize:hover::before, .btn.btn-customize:focus::before, .btn.btn-customize[aria-describedby*="popover"]::before {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }

.dark-theme .btn-customize {
  color: #FFFFFF; }
  .dark-theme .btn-customize::before {
    border: 2px solid #FFFFFF; }

.dark-theme .btn.btn-customize {
  color: #FFFFFF; }
  .dark-theme .btn.btn-customize::before {
    border: 2px solid #FFFFFF; }

.dark-theme.btn-customize {
  color: #FFFFFF; }
  .dark-theme.btn-customize::before {
    border: 2px solid #FFFFFF; }

.font-family-secondary {
  font-family: "Space Grotesk", sans-serif; }

.font-weight-bold {
  font-weight: 700; }

.font-family-primary {
  font-family: "Inter", sans-serif; }

.hero-banner-background--none .hero-media {
  background: none !important;
  background-color: transparent !important; }

.hero-banner-background--contain .hero-media {
  background-size: contain !important; }

.hero-banner-background--cover .hero-media {
  background-size: cover !important; }

.hero-banner-background--auto .hero-media {
  background-size: auto !important; }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 1024px) and (max-width: 1199.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .pull-left-sd {
    float: left; }
  .pull-right-sd {
    float: right; } }

@media (min-width: 1440px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 1023.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

@media (max-width: 1439.98px) {
  .hidden-sd-down {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: flex; }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(../fonts/flags//4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(../fonts/flags//4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ae.svg); }

.flag-icon-af {
  background-image: url(../fonts/flags//4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/af.svg); }

.flag-icon-ag {
  background-image: url(../fonts/flags//4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(../fonts/flags//4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ai.svg); }

.flag-icon-al {
  background-image: url(../fonts/flags//4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/al.svg); }

.flag-icon-am {
  background-image: url(../fonts/flags//4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/am.svg); }

.flag-icon-ao {
  background-image: url(../fonts/flags//4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(../fonts/flags//4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(../fonts/flags//4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ar.svg); }

.flag-icon-as {
  background-image: url(../fonts/flags//4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/as.svg); }

.flag-icon-at {
  background-image: url(../fonts/flags//4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/at.svg); }

.flag-icon-au {
  background-image: url(../fonts/flags//4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/au.svg); }

.flag-icon-aw {
  background-image: url(../fonts/flags//4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(../fonts/flags//4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ax.svg); }

.flag-icon-az {
  background-image: url(../fonts/flags//4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/az.svg); }

.flag-icon-ba {
  background-image: url(../fonts/flags//4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(../fonts/flags//4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(../fonts/flags//4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bd.svg); }

.flag-icon-be {
  background-image: url(../fonts/flags//4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/be.svg); }

.flag-icon-bf {
  background-image: url(../fonts/flags//4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(../fonts/flags//4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(../fonts/flags//4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(../fonts/flags//4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(../fonts/flags//4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(../fonts/flags//4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(../fonts/flags//4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(../fonts/flags//4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(../fonts/flags//4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(../fonts/flags//4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bq.svg); }

.flag-icon-br {
  background-image: url(../fonts/flags//4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/br.svg); }

.flag-icon-bs {
  background-image: url(../fonts/flags//4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(../fonts/flags//4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(../fonts/flags//4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(../fonts/flags//4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bw.svg); }

.flag-icon-by {
  background-image: url(../fonts/flags//4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/by.svg); }

.flag-icon-bz {
  background-image: url(../fonts/flags//4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(../fonts/flags//4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(../fonts/flags//4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(../fonts/flags//4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(../fonts/flags//4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(../fonts/flags//4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(../fonts/flags//4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(../fonts/flags//4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(../fonts/flags//4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(../fonts/flags//4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(../fonts/flags//4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(../fonts/flags//4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cn.svg); }

.flag-icon-co {
  background-image: url(../fonts/flags//4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/co.svg); }

.flag-icon-cr {
  background-image: url(../fonts/flags//4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(../fonts/flags//4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(../fonts/flags//4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(../fonts/flags//4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(../fonts/flags//4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(../fonts/flags//4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(../fonts/flags//4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cz.svg); }

.flag-icon-de {
  background-image: url(../fonts/flags//4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/de.svg); }

.flag-icon-dj {
  background-image: url(../fonts/flags//4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(../fonts/flags//4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(../fonts/flags//4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dm.svg); }

.flag-icon-do {
  background-image: url(../fonts/flags//4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/do.svg); }

.flag-icon-dz {
  background-image: url(../fonts/flags//4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(../fonts/flags//4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(../fonts/flags//4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(../fonts/flags//4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(../fonts/flags//4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eh.svg); }

.flag-icon-er {
  background-image: url(../fonts/flags//4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/er.svg); }

.flag-icon-es {
  background-image: url(../fonts/flags//4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es.svg); }

.flag-icon-et {
  background-image: url(../fonts/flags//4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/et.svg); }

.flag-icon-fi {
  background-image: url(../fonts/flags//4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(../fonts/flags//4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(../fonts/flags//4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(../fonts/flags//4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(../fonts/flags//4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(../fonts/flags//4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(../fonts/flags//4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(../fonts/flags//4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(../fonts/flags//4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(../fonts/flags//4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(../fonts/flags//4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(../fonts/flags//4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(../fonts/flags//4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(../fonts/flags//4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(../fonts/flags//4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(../fonts/flags//4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(../fonts/flags//4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(../fonts/flags//4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(../fonts/flags//4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(../fonts/flags//4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(../fonts/flags//4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(../fonts/flags//4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(../fonts/flags//4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(../fonts/flags//4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(../fonts/flags//4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(../fonts/flags//4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(../fonts/flags//4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(../fonts/flags//4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(../fonts/flags//4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(../fonts/flags//4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(../fonts/flags//4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hu.svg); }

.flag-icon-id {
  background-image: url(../fonts/flags//4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/id.svg); }

.flag-icon-ie {
  background-image: url(../fonts/flags//4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ie.svg); }

.flag-icon-il {
  background-image: url(../fonts/flags//4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/il.svg); }

.flag-icon-im {
  background-image: url(../fonts/flags//4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/im.svg); }

.flag-icon-in {
  background-image: url(../fonts/flags//4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/in.svg); }

.flag-icon-io {
  background-image: url(../fonts/flags//4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/io.svg); }

.flag-icon-iq {
  background-image: url(../fonts/flags//4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(../fonts/flags//4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ir.svg); }

.flag-icon-is {
  background-image: url(../fonts/flags//4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/is.svg); }

.flag-icon-it {
  background-image: url(../fonts/flags//4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/it.svg); }

.flag-icon-je {
  background-image: url(../fonts/flags//4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/je.svg); }

.flag-icon-jm {
  background-image: url(../fonts/flags//4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(../fonts/flags//4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(../fonts/flags//4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(../fonts/flags//4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(../fonts/flags//4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(../fonts/flags//4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(../fonts/flags//4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ki.svg); }

.flag-icon-km {
  background-image: url(../fonts/flags//4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/km.svg); }

.flag-icon-kn {
  background-image: url(../fonts/flags//4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(../fonts/flags//4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(../fonts/flags//4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(../fonts/flags//4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(../fonts/flags//4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(../fonts/flags//4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kz.svg); }

.flag-icon-la {
  background-image: url(../fonts/flags//4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/la.svg); }

.flag-icon-lb {
  background-image: url(../fonts/flags//4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(../fonts/flags//4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lc.svg); }

.flag-icon-li {
  background-image: url(../fonts/flags//4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/li.svg); }

.flag-icon-lk {
  background-image: url(../fonts/flags//4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(../fonts/flags//4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(../fonts/flags//4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(../fonts/flags//4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(../fonts/flags//4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(../fonts/flags//4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(../fonts/flags//4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(../fonts/flags//4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(../fonts/flags//4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mc.svg); }

.flag-icon-md {
  background-image: url(../fonts/flags//4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/md.svg); }

.flag-icon-me {
  background-image: url(../fonts/flags//4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/me.svg); }

.flag-icon-mf {
  background-image: url(../fonts/flags//4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(../fonts/flags//4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(../fonts/flags//4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(../fonts/flags//4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(../fonts/flags//4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(../fonts/flags//4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(../fonts/flags//4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(../fonts/flags//4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(../fonts/flags//4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(../fonts/flags//4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(../fonts/flags//4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(../fonts/flags//4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(../fonts/flags//4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(../fonts/flags//4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(../fonts/flags//4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(../fonts/flags//4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(../fonts/flags//4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mx.svg); }

.flag-icon-my {
  background-image: url(../fonts/flags//4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/my.svg); }

.flag-icon-mz {
  background-image: url(../fonts/flags//4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mz.svg); }

.flag-icon-na {
  background-image: url(../fonts/flags//4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/na.svg); }

.flag-icon-nc {
  background-image: url(../fonts/flags//4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(../fonts/flags//4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(../fonts/flags//4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(../fonts/flags//4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(../fonts/flags//4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(../fonts/flags//4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nl.svg); }

.flag-icon-no {
  background-image: url(../fonts/flags//4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/no.svg); }

.flag-icon-np {
  background-image: url(../fonts/flags//4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/np.svg); }

.flag-icon-nr {
  background-image: url(../fonts/flags//4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(../fonts/flags//4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(../fonts/flags//4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nz.svg); }

.flag-icon-om {
  background-image: url(../fonts/flags//4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/om.svg); }

.flag-icon-pa {
  background-image: url(../fonts/flags//4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(../fonts/flags//4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(../fonts/flags//4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(../fonts/flags//4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(../fonts/flags//4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(../fonts/flags//4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(../fonts/flags//4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(../fonts/flags//4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(../fonts/flags//4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(../fonts/flags//4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(../fonts/flags//4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(../fonts/flags//4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(../fonts/flags//4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pw.svg); }

.flag-icon-py {
  background-image: url(../fonts/flags//4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/py.svg); }

.flag-icon-qa {
  background-image: url(../fonts/flags//4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/qa.svg); }

.flag-icon-re {
  background-image: url(../fonts/flags//4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/re.svg); }

.flag-icon-ro {
  background-image: url(../fonts/flags//4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(../fonts/flags//4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(../fonts/flags//4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(../fonts/flags//4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(../fonts/flags//4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(../fonts/flags//4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(../fonts/flags//4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(../fonts/flags//4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sd.svg); }

.flag-icon-se {
  background-image: url(../fonts/flags//4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/se.svg); }

.flag-icon-sg {
  background-image: url(../fonts/flags//4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(../fonts/flags//4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sh.svg); }

.flag-icon-si {
  background-image: url(../fonts/flags//4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/si.svg); }

.flag-icon-sj {
  background-image: url(../fonts/flags//4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(../fonts/flags//4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(../fonts/flags//4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(../fonts/flags//4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(../fonts/flags//4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sn.svg); }

.flag-icon-so {
  background-image: url(../fonts/flags//4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/so.svg); }

.flag-icon-sr {
  background-image: url(../fonts/flags//4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(../fonts/flags//4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ss.svg); }

.flag-icon-st {
  background-image: url(../fonts/flags//4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/st.svg); }

.flag-icon-sv {
  background-image: url(../fonts/flags//4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(../fonts/flags//4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(../fonts/flags//4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(../fonts/flags//4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(../fonts/flags//4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tc.svg); }

.flag-icon-td {
  background-image: url(../fonts/flags//4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/td.svg); }

.flag-icon-tf {
  background-image: url(../fonts/flags//4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(../fonts/flags//4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tg.svg); }

.flag-icon-th {
  background-image: url(../fonts/flags//4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/th.svg); }

.flag-icon-tj {
  background-image: url(../fonts/flags//4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(../fonts/flags//4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(../fonts/flags//4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(../fonts/flags//4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(../fonts/flags//4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tn.svg); }

.flag-icon-to {
  background-image: url(../fonts/flags//4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/to.svg); }

.flag-icon-tr {
  background-image: url(../fonts/flags//4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(../fonts/flags//4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(../fonts/flags//4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(../fonts/flags//4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(../fonts/flags//4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(../fonts/flags//4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(../fonts/flags//4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ug.svg); }

.flag-icon-um {
  background-image: url(../fonts/flags//4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/um.svg); }

.flag-icon-us {
  background-image: url(../fonts/flags//4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/us.svg); }

.flag-icon-uy {
  background-image: url(../fonts/flags//4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(../fonts/flags//4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uz.svg); }

.flag-icon-va {
  background-image: url(../fonts/flags//4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/va.svg); }

.flag-icon-vc {
  background-image: url(../fonts/flags//4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(../fonts/flags//4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(../fonts/flags//4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(../fonts/flags//4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(../fonts/flags//4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(../fonts/flags//4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(../fonts/flags//4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(../fonts/flags//4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(../fonts/flags//4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(../fonts/flags//4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/yt.svg); }

.flag-icon-za {
  background-image: url(../fonts/flags//4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/za.svg); }

.flag-icon-zm {
  background-image: url(../fonts/flags//4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(../fonts/flags//4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zw.svg); }

.flag-icon-es-ct {
  background-image: url(../fonts/flags//4x3/es-ct.svg); }
  .flag-icon-es-ct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es-ct.svg); }

.flag-icon-eu {
  background-image: url(../fonts/flags//4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(../fonts/flags//4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(../fonts/flags//4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(../fonts/flags//4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(../fonts/flags//4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(../fonts/flags//4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/un.svg); }

.toast-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }
  .toast-messages .alert {
    box-shadow: 0 2px 4px rgba(34, 34, 34, 0.4);
    text-align: center; }
    .toast-messages .alert.show {
      display: block; }
    .toast-messages .alert:not(.alert-dismissible) {
      animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
    .toast-messages .alert.alert-dismissible {
      animation: fadeIn 200ms;
      padding-right: 50px; }
      .toast-messages .alert.alert-dismissible .close {
        padding: 16px; }

.alert {
  border-radius: 20px;
  padding: 16px; }
  .alert-success {
    background: #01DF9D;
    border-color: #01DF9D;
    color: #181818; }
    .alert-success a, .alert-success a:hover {
      color: black; }
    .alert-success .close {
      color: #181818; }
  .alert-warning {
    background: #FFDF59;
    border-color: #FFDF59;
    color: #181818; }
    .alert-warning a, .alert-warning a:hover {
      color: black; }
    .alert-warning .close {
      color: #181818; }
  .alert-danger {
    background: #a80b1d;
    border-color: #a80b1d;
    color: #FFFFFF; }
    .alert-danger a, .alert-danger a:hover {
      color: #e6e6e6; }
    .alert-danger .close {
      color: #FFFFFF; }
  .alert-info {
    background: #ACE7FA;
    border-color: #ACE7FA;
    color: #181818; }
    .alert-info a, .alert-info a:hover {
      color: black; }
    .alert-info .close {
      color: #181818; }
  .alert-dismissible {
    padding-right: calc(32px + 12px); }
  .alert .close {
    padding: 16px;
    line-height: 22px; }
    .alert .close:before {
      position: static;
      transform: none;
      margin: 0;
      font-size: 12px; }

.badge-product-container {
  position: relative;
  z-index: 1;
  margin: 1px;
  display: flex;
  align-items: flex-start;
  pointer-events: none;
  top: 0; }
  @media (min-width: 769px) {
    .badge-product-container.badge-image-over {
      position: absolute;
      flex-direction: column; } }

.product-detail .badge-product-container {
  position: relative; }
  @media (min-width: 769px) {
    .product-detail .badge-product-container.badge-image-over {
      position: absolute;
      flex-direction: column; } }

.badge-product {
  text-align: left;
  padding: 2px 0px;
  margin-bottom: 1px;
  font-weight: bold;
  line-height: 16px;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid; }
  .badge-product:not([class*='text--']) {
    color: #181818; }
  .badge-product:not([class*='background--']) {
    background-color: transparent; }
  .badge-product:not([class*='border--']) {
    border-color: transparent; }
  .badge-product:not([class*='font-size--']) {
    font-size: 12px; }
  .badge-product:not([class*='font--']) {
    font-family: "Inter", sans-serif; }
  @media (min-width: 1024px) {
    .badge-product {
      padding: 12px 12px;
      font-size: 16px; } }

@media (min-width: 769px) {
  .modal .product-detail .badge-product-container.badge-image-over {
    top: 0px;
    left: 17px; }
  .modal .product-detail .badge-product-container .badge-product > p {
    line-height: 14px; } }

@media (min-width: 769px) {
  .product-detail .badge-product-container.badge-image-over {
    top: 14px;
    left: 17px; } }

.badge-product-container strong,
.badge-product-container b {
  font-weight: bold; }

.badge-product > p {
  margin-bottom: 1px;
  line-height: 16px;
  font-size: 12px; }
  .badge-product > p strong,
  .badge-product > p b {
    font-weight: bold; }

.badge-product-container.badge-image-over {
  position: absolute;
  padding: 2px 0px; }
  .badge-product-container.badge-image-over .badge-product {
    padding: 0; }

.badge--pill {
  background: #3091E7;
  color: #FFFFFF;
  padding: 0 9px; }

.chips-page .chip-badge {
  margin: 0 0 4px 0;
  display: inline-block;
  vertical-align: top; }
  .chips-page .chip-badge:last-child {
    margin: 0 10px 4px 0; }
  .chips-page .chip-badge a {
    border-radius: 20px;
    padding: 6px 12px 5px;
    font-size: 12px;
    line-height: 16px;
    min-height: 28px;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    margin: 2px;
    display: inline-block;
    vertical-align: middle;
    transition: all 200ms ease-out; }
    .chips-page .chip-badge a:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .chips-page .chip-badge a[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .chips-page .chip-badge a[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .chips-page .chip-badge a[class*="btn-"]:not(.title).btn-sm, .chips-page .chip-badge .btn-group-sm > a.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .chips-page .chip-badge a[class*="btn-"]:not(.title).btn-sm:after, .chips-page .chip-badge .btn-group-sm > a.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .chips-page .chip-badge a[class*="btn-"]:not(.title).btn-lg, .chips-page .chip-badge .btn-group-lg > a.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .chips-page .chip-badge a[class*="btn-"]:not(.title).btn-lg:after, .chips-page .chip-badge .btn-group-lg > a.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .chips-page .chip-badge a[class*="btn-"]:not(.title) [class*="icon-"],
      .chips-page .chip-badge a[class*="btn-"]:not(.title) [class^="icon-"],
      .chips-page .chip-badge a[class*="btn-"]:not(.title) .fa {
        display: none; }
    .chips-page .chip-badge a::after {
      font-size: 8px;
      padding-left: 2px;
      display: inline-block;
      vertical-align: middle; }
    .chips-page .chip-badge a:hover {
      margin: 0;
      padding: 8px 14px 7px;
      transition: all 200ms ease-out; }

.blog-nav .nav {
  background: #F2F2F2;
  border-bottom: 1px solid #D4D4D4; }

.blog-nav .nav-item .nav-link {
  text-decoration: none; }

.blog-nav .nav-item.active .nav-link {
  font-weight: bold; }

.blog-results {
  padding-top: 16px;
  padding-bottom: 16px; }
  .blog-results .result-count {
    padding: 16px 0; }

.blog-detail-header .page-header-subtitle {
  display: flex; }

.blog-detail-header.text-center .page-header-subtitle {
  justify-content: center; }

.blog-detail-header .page-header-title {
  margin-top: 10px; }

.blog-detail-header .blog-category {
  color: #595959; }
  .blog-detail-header .blog-category ~ .blog-creation-date::before {
    content: "|";
    color: #595959;
    padding-left: 10px;
    padding-right: 10px; }

.blog-detail-header .blog-creation-date {
  color: #595959; }

.blog-detail-header .page-header-author {
  margin-top: 10px;
  color: #595959; }

.blog .social-container .social-icons,
.storepage .social-container .social-icons {
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .blog .social-container .social-icons li:not(:last-child),
  .storepage .social-container .social-icons li:not(:last-child) {
    margin-right: 30px; }
  .blog .social-container .social-icons .share-icons,
  .storepage .social-container .social-icons .share-icons {
    font-size: 30px;
    color: #181818; }
    .blog .social-container .social-icons .share-icons:hover,
    .storepage .social-container .social-icons .share-icons:hover {
      color: rgba(24, 24, 24, 0.8); }

.blog .blog-footer {
  text-align: center; }

.blog .social-container {
  border-top: none;
  margin-top: 80px;
  padding-top: 0; }

.blog .search-words-container {
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #D4D4D4; }
  .blog .search-words-container label {
    margin-right: 7px;
    margin-bottom: 0; }
  .blog .search-words-container .search-words {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; }
    .blog .search-words-container .search-words li {
      padding: 0; }
    .blog .search-words-container .search-words li:not(:last-child) a::after {
      content: ",";
      margin-left: 0;
      margin-right: 7px; }

.breadcrumb {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #181818;
  line-height: 20px;
  letter-spacing: 0; }
  .breadcrumb li {
    padding: 0; }
  .breadcrumb .breadcrumb-item a {
    color: #181818;
    font-weight: 500;
    text-decoration: none; }
    .breadcrumb .breadcrumb-item a:hover {
      color: rgba(24, 24, 24, 0.8);
      text-decoration: underline; }
  .breadcrumb .breadcrumb-item span {
    color: #181818;
    font-weight: 500;
    text-decoration: none; }
  .breadcrumb .breadcrumb-item + .breadcrumb-item {
    display: flex; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
      content: "/";
      color: rgba(24, 24, 24, 0.8);
      font-weight: 500;
      padding: 0 10px; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:last-of-type a {
      color: #181818;
      font-weight: 500; }
  .breadcrumb .breadcrumb-item h1 {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    margin: 0; }

.breadcrumb .breadcrumb-item h1 {
  text-transform: capitalize; }

@media (max-width: 768.98px) {
  .breadcrumb.more li.breadcrumb-item {
    display: none; }
  .breadcrumb.more li.breadcrumb-item:first-child + .breadcrumb-item:before {
    display: none; }
  .breadcrumb.more li.breadcrumb-item:nth-last-child(-n+3) {
    display: flex; } }

.card {
  border: none;
  border-radius: 20px;
  margin-bottom: 16px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: transparent; }
  .card .card-header {
    background-color: #F9F9F9;
    color: #181818;
    border: 1px solid #F9F9F9;
    border-bottom: none;
    padding: 0;
    flex-basis: auto; }
    .card .card-header .pull-right {
      color: #181818;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      padding-top: 32px;
      padding-right: 16px; }
  .card .card-title,
  .card .card-header h2,
  .card .card-header h3,
  .card .card-header h4,
  .card .card-header .checkout-card-header,
  .card h2.card-header {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.01em;
    line-height: 24px;
    text-decoration: none;
    text-transform: none;
    position: relative;
    margin-bottom: 0;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px; }
  .card .card-body {
    background-color: #F9F9F9;
    color: #181818;
    padding: 32px;
    border: 1px solid #F9F9F9;
    border-bottom: none;
    border-top: none; }
    .card .card-body:first-child {
      border-top: 1px solid #F9F9F9; }
    .card .card-body:last-child {
      border-bottom: 1px solid #F9F9F9;
      border-radius: 0 0 calc(20px - 1px) calc(20px - 1px); }
  .card .card-header ~ .card-body {
    padding: 32px 16px 32px 16px; }
  .card .card-footer {
    background-color: #F9F9F9;
    color: #181818;
    padding: 32px 16px 32px 16px;
    border-left: 1px solid #F9F9F9;
    border-right: 1px solid #F9F9F9;
    border-bottom: 1px solid #F9F9F9;
    border-top: 0; }
    .card .card-footer a {
      color: #181818; }
  .card.ghost {
    opacity: 0.5; }
  .card.aside {
    border: 1px solid #F9F9F9;
    border-width: 1px 0; }
    .card.aside .card-header {
      border: none; }
      .card.aside .card-header .card-title {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        padding-top: 16px;
        padding-left: 0;
        padding-right: 16px;
        padding-bottom: 16px; }
    .card.aside .card-body {
      padding: 0;
      border: none;
      z-index: 1; }
    .card.aside .card-footer {
      border: none; }
  .card.no-borders .card-header,
  .card.no-borders .card-title,
  .card.no-borders .card-body,
  .card.no-borders .card-footer {
    border: none;
    padding-left: 0;
    padding-right: 0; }

.card {
  margin-bottom: 0; }
  .card .order-details-col {
    padding: 0; }
    .card .order-details-col > .col {
      padding: 0; }
  .card .order-image-col {
    float: left;
    width: 100px;
    padding: 0 10px 0 0; }
  .card .nfc-all-devices-link {
    float: right;
    font-size: 14px;
    text-decoration: underline;
    text-transform: capitalize; }
    .card .nfc-all-devices-link:hover {
      text-decoration: none; }
  .card .nfc-device-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .card .nfc-device-form select {
      margin: 0 0 10px; }
    @media (min-width: 769px) {
      .card .nfc-device-form {
        flex-wrap: nowrap; }
        .card .nfc-device-form select {
          margin: 0; } }
    .card .nfc-device-form .btn-primary {
      margin-right: 10px;
      margin-left: 0; }
      @media (min-width: 769px) {
        .card .nfc-device-form .btn-primary {
          margin-left: 10px;
          margin-right: 0; } }
    .card .nfc-device-form .nfc-reset-device {
      background: #181818; }
  .card .card-header {
    border: none;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px; }
    @media (min-width: 1024px) {
      .card .card-header {
        padding-top: 32px;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 16px; } }
    .card .card-header .pull-right {
      padding-top: 0; }
  .card .card-title,
  .card .card-header h2,
  .card .card-header h3,
  .card .card-header h4,
  .card .card-header .checkout-card-header,
  .card h2.card-header {
    padding: 0; }
    @media (min-width: 1024px) {
      .card .card-title,
      .card .card-header h2,
      .card .card-header h3,
      .card .card-header h4,
      .card .card-header .checkout-card-header,
      .card h2.card-header {
        padding: 0; } }
  .card .card-body {
    border: none;
    border-radius: 20px;
    margin-bottom: 16px;
    padding: 32px 16px 32px 16px; }
    @media (min-width: 1024px) {
      .card .card-body {
        padding: 32px; } }
    .card .card-body:first-child {
      border-top: none; }
    .card .card-body:last-child {
      border-bottom: none;
      border-radius: 20px; }
  .card .card-body:not(:last-child) {
    border-radius: 0;
    margin-bottom: 0; }
  .card .card-header ~ .card-body:last-child {
    border-radius: 0 0 calc(20px - 1px) calc(20px - 1px); }
  @media (min-width: 1024px) {
    .card .card-header ~ .card-body {
      padding: 0 32px 32px 32px; } }
  .card .card-footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; }
  .refinements .card .card-title {
    display: block; }
  .card.order-history-card {
    border: 1px solid #000000;
    margin-bottom: 32px; }
    @media (min-width: 1024px) {
      .card.order-history-card {
        max-width: 48.5%;
        min-width: 48.5%; } }
    .card.order-history-card .card-header .pull-right {
      text-decoration: none;
      font-weight: 500;
      padding: 4px 10px;
      border: 2px solid #000000;
      border-radius: 18px; }
      @media (max-width: 1199.98px) {
        .card.order-history-card .card-header .pull-right {
          float: left;
          margin-top: 16px;
          clear: both; } }
    .card.order-history-card .card-header .pull-left {
      margin-top: 6px; }
    .card.order-history-card .card-body {
      padding: 16px 32px;
      margin-bottom: 0; }
      .card.order-history-card .card-body .order-image-col {
        width: 100%; }
        @media (max-width: 1199.98px) {
          .card.order-history-card .card-body .order-image-col {
            margin-bottom: 16px;
            text-align: center; } }
    .card.order-history-card .card-footer {
      padding: 0 16px; }
      @media (max-width: 1199.98px) {
        .card.order-history-card .card-footer .card-footer-wrapper {
          display: flex;
          justify-content: space-between; } }
      @media (max-width: 1199.98px) {
        .card.order-history-card .card-footer .card-info-group {
          max-width: 49%;
          min-width: 49%; } }
      .card.order-history-card .card-footer .card-info-group .orderTotal, .card.order-history-card .card-footer .card-info-group .totalItems {
        font-weight: bold; }
  .card .coupon-code {
    display: flex;
    align-items: center; }
    .card .coupon-code .coupon-style {
      width: 20px;
      height: 20px;
      margin: 0 8px 0 0; }
    .card .coupon-code ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column; }
      .card .coupon-code ul:has(li:nth-child(2)) li:first-child {
        margin-top: 10%; }
      .card .coupon-code ul li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none; }
    .card .coupon-code button {
      cursor: pointer;
      margin-left: auto;
      padding-right: 8px; }
    .card .coupon-code .coupon-not-applied {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none; }

.page-nfc .login-oauth {
  display: none; }

.order-list-container {
  padding: 0 8px; }
  @media (min-width: 769px) {
    .order-list-container {
      padding: 0 16px; } }

.carousel .icon-prev,
.carousel .icon-next {
  background-color: #FFFFFF;
  font-size: 18px;
  padding: 16px;
  color: #000000; }

.carousel .icon-prev:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.carousel .icon-prev[class*="btn-"]:not(.title) {
  padding-left: 42px; }
  .carousel .icon-prev[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .carousel .icon-prev[class*="btn-"]:not(.title).btn-sm, .carousel .btn-group-sm > .icon-prev.btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .carousel .icon-prev[class*="btn-"]:not(.title).btn-sm:before, .carousel .btn-group-sm > .icon-prev.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-prev[class*="btn-"]:not(.title).btn-lg, .carousel .btn-group-lg > .icon-prev.btn[class*="btn-"]:not(.title) {
    padding-left: 63px; }
    .carousel .icon-prev[class*="btn-"]:not(.title).btn-lg:before, .carousel .btn-group-lg > .icon-prev.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-prev[class*="btn-"]:not(.title) [class*="icon-"],
  .carousel .icon-prev[class*="btn-"]:not(.title) [class^="icon-"],
  .carousel .icon-prev[class*="btn-"]:not(.title) .fa {
    display: none; }

.carousel .icon-next:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.carousel .icon-next[class*="btn-"]:not(.title) {
  padding-left: 42px; }
  .carousel .icon-next[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .carousel .icon-next[class*="btn-"]:not(.title).btn-sm, .carousel .btn-group-sm > .icon-next.btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .carousel .icon-next[class*="btn-"]:not(.title).btn-sm:before, .carousel .btn-group-sm > .icon-next.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-next[class*="btn-"]:not(.title).btn-lg, .carousel .btn-group-lg > .icon-next.btn[class*="btn-"]:not(.title) {
    padding-left: 63px; }
    .carousel .icon-next[class*="btn-"]:not(.title).btn-lg:before, .carousel .btn-group-lg > .icon-next.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-next[class*="btn-"]:not(.title) [class*="icon-"],
  .carousel .icon-next[class*="btn-"]:not(.title) [class^="icon-"],
  .carousel .icon-next[class*="btn-"]:not(.title) .fa {
    display: none; }

.carousel .carousel-control-prev {
  justify-content: flex-start; }

.carousel .carousel-control-next {
  justify-content: flex-end; }

.category-tile {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  padding: 16px;
  border-radius: 20px;
  background-color: #F9F9F9; }
  .category-tile:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .category-tile.zoom .image-wrapper {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .category-tile.zoom:hover .image-wrapper {
      transform: scale(1.2); } }
  .category-tile .image-cropper {
    border-radius: 20px;
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative; }
    .category-tile .image-cropper.overlay::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      content: " "; }
  .category-tile .image-wrapper {
    width: 100%;
    position: relative; }
    .category-tile .image-wrapper .background-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .category-tile .image-wrapper-custom .background-image-tablet,
    .category-tile .image-wrapper-custom .background-image-desktop {
      display: none; }
    @media (min-width: 769px) {
      .category-tile .image-wrapper-custom .background-image-mobile,
      .category-tile .image-wrapper-custom .background-image-desktop {
        display: none; }
      .category-tile .image-wrapper-custom .background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .category-tile .image-wrapper-custom .background-image-mobile,
      .category-tile .image-wrapper-custom .background-image-tablet {
        display: none; }
      .category-tile .image-wrapper-custom .background-image-desktop {
        display: block; } }
    .category-tile .image-wrapper .video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: calc(9 / 16 * 100%);
      padding-left: calc(16 / 9 * 100vh);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .category-tile .image-wrapper .video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .category-tile .image-wrapper .video-container .video-aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .category-tile .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
          position: absolute; }
  .category-tile .image-text-block-text .primary-text:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    font-size: 24px; }
  .category-tile .image-text-block-text .secondary-text:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    color: orange;
    font-size: 16px; }
  .category-tile.text-placement-below .category-tile-text {
    margin-top: 16px;
    color: #222222;
    font-family: "Space Grotesk", sans-serif;
    font-size: 14px; }
  .category-tile.text-placement-inside::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.3);
    border-radius: 20px; }
  .category-tile.text-placement-inside .category-tile-text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: #FFFFFF;
    z-index: 1;
    text-shadow: none; }
  .category-tile.aspect-ratio-square .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-square .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .category-tile.aspect-ratio-landscape .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-landscape .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .category-tile.aspect-ratio-portrait .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-portrait .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .category-tile.aspect-ratio-portrait.rounded-corners .image-wrapper .video-container .video-aspect-ratio iframe {
    height: 115%; }
  .mobile-3r-1c .category-tile.aspect-ratio-portrait.rounded-corners .image-wrapper .video-container .video-aspect-ratio iframe {
    height: 135%; }
  .category-tile.top-content-style {
    padding: 32px; }
    .category-tile.top-content-style .tile-top-heading {
      margin-bottom: 8px; }
    .category-tile.top-content-style .tile-top-subheading {
      margin-bottom: 32px; }
    .category-tile.top-content-style .top-cta {
      margin-bottom: 64px; }
    .category-tile.top-content-style .category-tile-text {
      display: none; }
  @media (min-width: 1024px) {
    .category-tile.bottom-cta-layout {
      padding: 16px 16px 32px; } }
  .category-tile.bottom-cta-layout .image-wrapper {
    max-width: 150px;
    margin: 0 auto; }
    @media (min-width: 769px) {
      .category-tile.bottom-cta-layout .image-wrapper {
        max-width: 180px; } }
  .category-tile.bottom-cta-layout .category-tile-text {
    margin-top: 0;
    margin-bottom: 16px;
    color: #181818; }
    @media (max-width: 1023.98px) {
      .category-tile.bottom-cta-layout .category-tile-text .primary-text {
        font-size: 20px; } }

body {
  font-family: "Inter", sans-serif;
  color: #181818;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  background-color: #FFFFFF; }

.page {
  background-color: #FFFFFF;
  overflow: hidden; }

.error-page {
  padding-bottom: 16px;
  padding-top: 16px; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.error-message {
  margin-top: 50px;
  margin-bottom: 50px; }

.error.continue-shopping {
  margin-bottom: 100px; }

/*
* ORIGINAL CSS
* (everything under this comment was copied from Base and eventually
* should be removed or moved above this comment if in use)
*/
.modal-background {
  background-color: #000000;
  display: none;
  height: 100vh;
  position: fixed;
  opacity: 0.5;
  width: 100vw;
  top: 0;
  left: 0;
  transform: translateZ(0);
  z-index: 201; }

input[placeholder] {
  text-overflow: ellipsis; }

.hide-order-discount {
  display: none; }

.hide-shipping-discount {
  display: none; }

.order-discount {
  color: #01DF9D; }

.giftcertificate-discount {
  color: #01DF9D; }

.shipping-discount {
  color: #01DF9D; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.error-message {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.error.continue-shopping {
  margin-bottom: 6.25em; }

.error-unassigned-category {
  color: #a80b1d; }

.skip {
  position: absolute;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #FFFFFF;
  transition: all 200ms ease-in-out; }

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: 10000000;
  background: #FFFFFF;
  transition: all 200ms ease-in-out; }

.login-oauth .oauth-facebook {
  margin-bottom: 16px; }
  .login-oauth .oauth-facebook:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .login-oauth .oauth-facebook[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-sm, .login-oauth .btn-group-sm > .oauth-facebook.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-sm:before, .login-oauth .btn-group-sm > .oauth-facebook.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-lg, .login-oauth .btn-group-lg > .oauth-facebook.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-lg:before, .login-oauth .btn-group-lg > .oauth-facebook.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title) [class*="icon-"],
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title) [class^="icon-"],
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title) .fa {
      display: none; }
  .login-oauth .oauth-facebook .fa-facebook-official {
    display: none; }

.login-oauth .oauth-google {
  margin-bottom: 16px; }
  .login-oauth .oauth-google:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .login-oauth .oauth-google[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .login-oauth .oauth-google[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .login-oauth .oauth-google[class*="btn-"]:not(.title).btn-sm, .login-oauth .btn-group-sm > .oauth-google.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .login-oauth .oauth-google[class*="btn-"]:not(.title).btn-sm:before, .login-oauth .btn-group-sm > .oauth-google.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .login-oauth .oauth-google[class*="btn-"]:not(.title).btn-lg, .login-oauth .btn-group-lg > .oauth-google.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .login-oauth .oauth-google[class*="btn-"]:not(.title).btn-lg:before, .login-oauth .btn-group-lg > .oauth-google.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .login-oauth .oauth-google[class*="btn-"]:not(.title) [class*="icon-"],
    .login-oauth .oauth-google[class*="btn-"]:not(.title) [class^="icon-"],
    .login-oauth .oauth-google[class*="btn-"]:not(.title) .fa {
      display: none; }
  .login-oauth .oauth-google .fa-google {
    display: none; }

.grecaptcha-badge {
  z-index: 100; }

.page {
  background-color: #FFFFFF;
  overflow: hidden; }
  .page[data-action="Cart-Show"] {
    padding-bottom: 100px; }
  @media (min-width: 769px) {
    .page {
      overflow: visible; }
      .page[data-action="Cart-Show"] {
        padding-bottom: 0; } }

.error-page h2 {
  font-size: 32px;
  line-height: 36px; }

.error-page .error.continue-shopping {
  display: inline-block;
  width: auto; }

.error-page .row, .error-page .col-sm-6 {
  text-align: center; }

.accountpage {
  overflow: hidden; }
  .accountpage-header {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    background-image: url("../../images/img-banner.jpg");
    background-size: cover;
    background-position: 50%;
    position: relative; }
    .accountpage-header::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      content: " "; }
    @media (max-width: 768.98px) {
      .accountpage-header {
        margin-left: 16px;
        margin-right: 16px; } }
  .accountpage-title {
    margin: 0;
    color: #FFFFFF;
    max-width: 400px;
    z-index: 1; }
  .accountpage .card-body {
    padding: 0 16px; }
  .accountpage .form-group label {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    margin-bottom: 8px;
    display: inline-block;
    position: relative;
    width: max-content; }
    @media (min-width: 1024px) {
      .accountpage .form-group label {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .accountpage .form-group label::before {
      position: absolute;
      right: -9px; }
  .accountpage .form-group.custom-checkbox label {
    width: auto; }
  .accountpage .form-group .custom-checkbox label {
    width: auto; }
  .accountpage .btn-save {
    max-width: 205px;
    margin: 0 auto; }
  .accountpage .hero .hero-container {
    padding-top: 16px;
    padding-bottom: 16px; }
  .accountpage .description {
    padding-bottom: 64px; }
    @media (min-width: 769px) {
      .accountpage .description {
        padding-bottom: 96px; } }
    .accountpage .description p {
      margin: 0; }
  .accountpage .offer-free-recycle {
    font-weight: normal; }

.order-discount .col-8 p,
.shipping-discount .col-8 p {
  color: #181818; }
  .order-discount .col-8 p span,
  .shipping-discount .col-8 p span {
    color: #181818; }

.shipping-discount-total,
.order-discount-total {
  color: #a80b1d; }

.privacy-policy {
  overflow: hidden;
  padding: 40px 80px; }
  .privacy-policy h1 {
    text-align: center;
    margin-bottom: 48px; }
  .privacy-policy h2 {
    font-size: 15px; }

/* Chrome, Safari, Edge, Opera */
/* Firefox */
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.international .btn-primary,
.international .btn-secondary {
  width: 100%;
  height: 40px;
  opacity: 1; }
  @media (min-width: 1024px) {
    .international .btn-primary,
    .international .btn-secondary {
      width: auto;
      min-width: 147px; } }

.international .btn-secondary {
  color: #181818; }

@media (min-width: 1024px) {
  .international .flag-wrapper {
    margin: 0 auto;
    max-width: 550px; } }

.smarty-form-group {
  position: relative; }
  .smarty-form-group .smarty-suggestions {
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    padding: 0;
    z-index: 4; }
    .smarty-form-group .smarty-suggestions .suggestion-item {
      cursor: pointer;
      padding: 5px 15px;
      border-bottom: 1px solid #EDEDED;
      font-size: 12px;
      line-height: 20px;
      color: #595959; }
      .smarty-form-group .smarty-suggestions .suggestion-item:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: inherit;
        color: inherit;
        font-weight: normal; }
      .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title).btn-sm, .smarty-form-group .smarty-suggestions .btn-group-sm > .suggestion-item.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title).btn-sm:before, .smarty-form-group .smarty-suggestions .btn-group-sm > .suggestion-item.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title).btn-lg, .smarty-form-group .smarty-suggestions .btn-group-lg > .suggestion-item.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title).btn-lg:before, .smarty-form-group .smarty-suggestions .btn-group-lg > .suggestion-item.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title) [class*="icon-"],
        .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title) [class^="icon-"],
        .smarty-form-group .smarty-suggestions .suggestion-item[class*="btn-"]:not(.title) .fa {
          display: none; }
      .smarty-form-group .smarty-suggestions .suggestion-item::before {
        font-size: 13px;
        padding-right: 2px;
        vertical-align: middle; }
      .smarty-form-group .smarty-suggestions .suggestion-item:hover {
        background: #F1F1F1; }

@media (max-width: 1023.98px) {
  #addressValidationModal .modal-footer {
    flex-wrap: wrap; }
    #addressValidationModal .modal-footer buton {
      margin: 0 0 10px; } }

.content-grid + .content-grid {
  margin-top: 32px; }

@media (max-width: 768.98px) {
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-12'] {
    margin-top: 32px; }
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-6']:nth-child(4n),
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-6']:nth-child(4n-1) {
    margin-top: 32px; } }

.content-tile {
  display: block;
  text-decoration: none;
  margin-bottom: 45px; }
  @media (min-width: 769px) {
    .content-tile .content-tile-image {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .content-tile:hover .content-tile-image {
      transform: scale(1.2); } }
  .content-tile .content-tile-image-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px; }
  .content-tile .content-tile-image {
    display: block; }
    .content-tile .content-tile-image::after {
      display: block;
      content: "";
      clear: both; }
    .content-tile .content-tile-image::before {
      content: "";
      display: block;
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: calc(1 / 1 * 100%); }
  .content-tile .content-tile-body {
    display: block; }
  .content-tile .content-tile-category {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    color: #595959;
    margin-bottom: 7px; }
  .content-tile .content-tile-name {
    font-family: "Space Grotesk", sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    color: #181818;
    margin-bottom: 10px; }
  .content-tile .content-tile-date {
    display: block;
    font-family: "Space Grotesk", sans-serif;
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    color: #181818;
    margin-bottom: 7px;
    text-transform: uppercase; }
  .content-tile .content-tile-description {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    color: #181818; }
  .content-tile:hover {
    text-decoration: none; }
    .content-tile:hover .content-tile-image-wrapper::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: inset 0px -0 0px #222222; }
    .content-tile:hover .content-tile-name {
      text-decoration: none;
      color: #181818; }
    .content-tile:hover .content-tile-description {
      text-decoration: none;
      color: #181818; }

.card-number-wrapper {
  position: relative; }
  .card-number-wrapper input {
    padding-right: calc(16px + 48px); }
  .card-number-wrapper::after {
    content: '';
    position: absolute;
    top: calc(calc(16px + 16px + 14px + (1px*2))/2 - 15px);
    right: 8px;
    background-repeat: no-repeat;
    background-image: url("../../images/credit.png");
    background-size: contain;
    width: 48px;
    height: 30px; }
  .card-number-wrapper[data-type="visa"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -162px -110px; }
  .card-number-wrapper[data-type="mastercard"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -295px -110px; }
  .card-number-wrapper[data-type="amex"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -230px -15px; }
  .card-number-wrapper[data-type="discover"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -95px -110px; }

.dropdown-toggle:after {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: 10px;
  color: inherit;
  font-weight: normal; }

.dropdown-toggle[class*="btn-"]:not(.title) {
  padding-right: 42px; }
  .dropdown-toggle[class*="btn-"]:not(.title):after {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(10px); }
  .dropdown-toggle[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
    padding-right: 42px; }
    .dropdown-toggle[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .dropdown-toggle[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
    padding-right: 63px; }
    .dropdown-toggle[class*="btn-"]:not(.title).btn-lg:after, .btn-group-lg > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .dropdown-toggle[class*="btn-"]:not(.title) [class*="icon-"],
  .dropdown-toggle[class*="btn-"]:not(.title) [class^="icon-"],
  .dropdown-toggle[class*="btn-"]:not(.title) .fa {
    display: none; }

.dropdown-toggle:after {
  border: none;
  vertical-align: top;
  margin-left: 5px;
  position: relative; }

.dropdown.show .dropdown-toggle:after,
.custom-dropdown.show .dropdown-toggle:after {
  transform: rotate(180deg); }

.dropdown-toggle:not(a):not(button) {
  cursor: default; }

.dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: 20px;
  border: 1px solid #F9F9F9;
  animation: fadeIn 200ms; }
  .dropdown-menu .dropdown-item {
    text-decoration: none;
    padding: 0; }
    .dropdown-menu .dropdown-item + .dropdown-item {
      border-top: 1px solid #F9F9F9; }
    .dropdown-menu .dropdown-item:hover {
      background: #F9F9F9;
      color: #181818;
      text-decoration: none; }
    .dropdown-menu .dropdown-item .dropdown-link {
      display: block;
      padding: 16px;
      color: #181818; }
      .dropdown-menu .dropdown-item .dropdown-link:hover {
        color: #181818; }
  .dropdown-menu [class*="sub-asset-"] + .dropdown-item {
    border-top: 1px solid #F9F9F9; }

.dropdown-menu {
  padding: 0 0 16px 0; }
  .dropdown-menu .dropdown-item {
    padding: 0;
    margin: 0 0 16px;
    position: relative; }
    .dropdown-menu .dropdown-item .dropdown-link {
      color: inherit; }
      .dropdown-menu .dropdown-item .dropdown-link:hover {
        text-decoration: none; }
        .dropdown-menu .dropdown-item .dropdown-link:hover .cat-text {
          text-decoration: underline; }
        .dropdown-menu .dropdown-item .dropdown-link:hover .cat-sub-nav-badge {
          text-decoration: none; }
    .dropdown-menu .dropdown-item:hover {
      text-decoration: none;
      background: #F9F9F9; }
      .dropdown-menu .dropdown-item:hover .cat-sub-nav-badge {
        text-decoration: none; }
    @media (min-width: 1024px) {
      .dropdown-menu .dropdown-item.custom-dropdown .dropdown-menu.show {
        overflow: hidden; } }
  .dropdown-menu.mega-standard .dropdown-item {
    background: #F9F9F9;
    border-radius: 20px; }
  .dropdown-menu .dropdown-link {
    display: block; }

.nav-item-cat {
  background-repeat: no-repeat;
  background-position: 90% 50%; }
  @media (max-width: 1023.98px) {
    .nav-item-cat {
      background-color: #F9F9F9;
      border-radius: 20px;
      margin: 0 16px 16px; } }
  @media (min-width: 1024px) {
    .nav-item-cat {
      background-image: none !important; } }

.navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item .dropdown-item {
  background-color: transparent !important;
  border: none;
  padding: 0; }

.navbar-nav .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item.custom-dropdown .dropdown-menu .dropdown-link {
  padding: 0 30px;
  background: transparent;
  height: auto; }
  @media (max-width: 1023.98px) {
    .navbar-nav .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item.custom-dropdown .dropdown-menu .dropdown-link.nav-link {
      padding: 16px 0; } }

.navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link {
  padding: 0;
  margin: 0; }

.navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item.custom-dropdown .dropdown-menu,
.navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
  background-repeat: no-repeat !important;
  background-position: 90% 50% !important; }
  @media (max-width: 1023.98px) {
    .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item.custom-dropdown .dropdown-menu,
    .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
      background-position: 92% 50% !important; } }
  .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item.custom-dropdown .dropdown-menu:not(.background--cover):not(.background--contain),
  .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:not(.background--cover):not(.background--contain) {
    background-size: 56px auto; }

.nav-item > .navbar .dropdown-toggle {
  border-bottom: 2px solid transparent; }

.nav-item.show > .navbar .dropdown-toggle {
  border-color: #181818; }

.navbar .dropdown-toggle:after {
  content: " "; }

.dropdown-tagline {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px; }
  .dropdown-tagline ul, .dropdown-tagline li, .dropdown-tagline button, .dropdown-tagline a {
    display: flex;
    text-decoration: none; }
    .dropdown-tagline ul span, .dropdown-tagline li span, .dropdown-tagline button span, .dropdown-tagline a span {
      margin: auto;
      line-height: 24px; }
  .dropdown-tagline .card,
  .dropdown-tagline .card-body {
    background: #FFFFFF; }

.add-giftcertificate-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.check-balance .balancemsg {
  font-weight: bold;
  font-size: 18px;
  margin-top: 16px;
  justify-content: center; }

.remove-giftcertificate-pi {
  font-family: "Inter", sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
  z-index: 1;
  background-color: #FFFFFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  padding: 0px 5px; }
  .remove-giftcertificate-pi:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .remove-giftcertificate-pi[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-lg:before, .btn-group-lg > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) [class*="icon-"],
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) [class^="icon-"],
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) .fa {
      display: none; }

.header-banner {
  background-color: #181818;
  text-align: center;
  color: #BFBFBF;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding-bottom: 8px;
  padding-top: 8px; }
  .header-banner .header-banner-column-1 .pull-left:not(:empty) {
    margin-left: 16px; }
  .header-banner .header-banner-column-2 .content {
    width: 100%; }
  .header-banner .header-banner-column-2 .close-button .close {
    opacity: 1;
    color: #FFFFFF;
    font-size: 10px;
    padding: 0 16px 0 3px; }
  .header-banner .header-banner-column-3 .pull-right {
    margin-right: 16px; }
  .header-banner .container {
    padding-left: 0;
    padding-right: 0; }
  .header-banner .dropdown-toggle:after {
    font-size: 10px; }
  .header-banner .nav-link {
    color: #BFBFBF;
    padding: 0; }

.header-nav {
  position: relative;
  z-index: 300;
  background: #FFFFFF;
  border-bottom: 0px solid transparent; }
  .header-nav.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 2px rgba(34, 34, 34, 0.2);
    transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: normal; }
    .mobile-menu-in .header-nav.fixed {
      box-shadow: none; }
    .header-nav.fixed .brand .logo-home {
      background: url(../images/logo.svg) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .header-nav.fixed .brand .logo-home {
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
      @media (min-width: 1024px) {
        .header-nav.fixed .brand .logo-home {
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
  @media (min-width: 1024px) {
    .header-nav.nav-center .navbar-nav {
      justify-content: center;
      text-align: center; } }
  @media (min-width: 1024px) {
    .logo-center .header-nav {
      border-bottom-width: 0; } }

.sticky-header .fixed-header-enhanced:not(.transparent-header) {
  padding-bottom: var(--header-height); }

.sticky-header .fixed-header-enhanced .header-nav {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 2px rgba(34, 34, 34, 0.2);
  z-index: normal;
  will-change: transform;
  transform: translateY(-100%);
  animation: slide-up 400ms ease-out forwards; }

.sticky-header.scroll-direction-up .fixed-header-enhanced .header-nav {
  animation: slide-down 400ms ease-out forwards; }

.header {
  position: relative;
  height: 60px;
  padding: 0; }
  @media (min-width: 769px) {
    .header {
      height: 60px; } }
  @media (min-width: 1024px) {
    .header {
      height: 60px; } }
  .header .row {
    height: 100%;
    align-items: center; }
    .header .row .col {
      display: flex;
      height: 100%;
      align-items: center; }
  .header > .row {
    flex-wrap: nowrap; }
  .header .header-column-1 > .pull-left:not(:empty) {
    margin-left: 16px; }
  @media (min-width: 1024px) {
    .header .header-column-1 {
      overflow-x: auto;
      overflow-y: hidden;
      mask-image: linear-gradient(90deg, black 90%, transparent);
      scrollbar-width: none; }
      .header .header-column-1::-webkit-scrollbar {
        width: 0;
        height: 0; }
      .header .header-column-1:hover {
        mask-image: unset; } }
  .header .header-column-2 {
    flex-grow: 0; }
  .header .header-column-3 {
    justify-content: flex-end; }
    .header .header-column-3 > .pull-right {
      margin-right: 16px; }
  .header .nav-link,
  .header .country-selector {
    color: #181818;
    transition: color 200ms ease-out; }
    .header .nav-link:hover,
    .header .country-selector:hover {
      color: #222222;
      transition: color 200ms ease-out; }
  .header .nav-link {
    padding: 5px; }
  .header .brand {
    height: 100%; }
    .header .brand .logo-home {
      width: 160px;
      height: 100%;
      margin: 0 auto;
      background: url(../images/logo.svg) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .header .brand .logo-home {
          width: 212px;
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
      @media (min-width: 1024px) {
        .header .brand .logo-home {
          width: 212px;
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
  .header .navbar-toggler {
    padding-left: 16px;
    padding-right: 16px; }
    .header .navbar-toggler:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 18px;
      color: #181818;
      font-weight: normal; }
    .header .navbar-toggler[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .header .navbar-toggler[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm, .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .header .navbar-toggler[class*="btn-"]:not(.title).btn-lg, .header .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .header .navbar-toggler[class*="btn-"]:not(.title).btn-lg:before, .header .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .header .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
      .header .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
      .header .navbar-toggler[class*="btn-"]:not(.title) .fa {
        display: none; }
    .header .navbar-toggler:hover {
      color: #222222; }
    .mobile-menu-in .header .navbar-toggler:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 18px;
      color: #181818;
      font-weight: normal; }
    .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm, .mobile-menu-in .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, .mobile-menu-in .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title).btn-lg, .mobile-menu-in .header .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title).btn-lg:before, .mobile-menu-in .header .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) .fa {
        display: none; }
  .header .minicart .minicart-icon:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 22px;
    color: #181818;
    font-weight: normal; }
  .header .minicart .minicart-icon[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-sm, .header .minicart .btn-group-sm > .minicart-icon.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-sm:before, .header .minicart .btn-group-sm > .minicart-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-lg, .header .minicart .btn-group-lg > .minicart-icon.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-lg:before, .header .minicart .btn-group-lg > .minicart-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .header .minicart .minicart-icon:hover:before {
    color: #222222; }
  .header .minicart .minicart-total {
    position: relative;
    margin-right: 10px; }
  .header .minicart .minicart-quantity {
    position: absolute;
    top: -2px;
    right: -9px;
    background-color: #2867EC;
    color: #FFFFFF;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: inline-block;
    text-align: center;
    font-size: 9px; }
  .header .custom-dropdown.show .nav-link {
    color: #222222; }

@media (max-width: 1023.98px) {
  .logo-left .header-column-1 {
    order: 1; }
  .logo-left .header-column-2 {
    order: 2; }
  .logo-left .header-column-3 {
    order: 2; } }

@media (min-width: 1024px) {
  .logo-left .header-banner {
    overflow: visible; }
    .logo-left .header-banner-column-3 .dropdown-menu {
      left: auto;
      right: 0; }
  .logo-left .main-menu {
    background: transparent; }
  .logo-left .header-column-1 {
    padding-left: 0;
    padding-right: 0;
    position: static; }
  .logo-left .nav-center .header-column-1 {
    text-align: center; }
    .logo-left .nav-center .header-column-1 .main-menu {
      margin: 0 auto; }
  .logo-left .header-column-2 {
    padding-left: 16px;
    padding-right: 16px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .logo-left .header-column-2 {
      min-width: calc(212px + (16px * 2)); } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .logo-left .header-column-2 {
      padding-right: 30px;
      min-width: calc(212px + 16px + 30px); } }

@media (min-width: 1024px) {
  .logo-left .header-column-3 {
    flex-grow: 0; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .logo-left .header-column-3 {
      flex: 1; } }

@media (min-width: 1024px) {
    .logo-left .header-column-3 .pull-right {
      margin-left: 16px;
      margin-right: 16px; }
      .logo-left .header-column-3 .pull-right.search {
        margin-right: 0; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link {
    padding-top: calc((60px - 24px) / 2);
    padding-bottom: calc((60px - 24px) / 2);
    position: relative;
    font-size: 14px; }
    .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link:before {
      content: '';
      display: block;
      height: 1px;
      background-color: #181818;
      position: absolute;
      top: 100%;
      transition: right 200ms ease-out;
      left: 18px;
      right: 100%;
      z-index: 1001; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link:hover:before,
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item.show .nav-link:before {
    right: 18px; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item.highlight .nav-link {
    color: #a80b1d; }
  .logo-left .navbar.bg-inverse .navbar-nav.nav-item-hover > .nav-item:not(:hover) .nav-link {
    color: #595959;
    transition: color 200ms ease-out; }
  .logo-left .navbar.bg-inverse .navbar-nav.nav-item-hover > .nav-item.highlight .nav-link:hover {
    color: #595959; } }

.main-menu {
  background-color: transparent; }
  .main-menu .navbar .close-menu button,
  .main-menu .navbar .close-button button {
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
  .main-menu .navbar .close-button button:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 12px;
    color: inherit;
    font-weight: normal; }
  .main-menu .navbar .close-button button[class*="btn-"]:not(.title) {
    padding-right: 42px; }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-sm, .main-menu .navbar .close-button .btn-group-sm > button.btn[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-sm:after, .main-menu .navbar .close-button .btn-group-sm > button.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-lg, .main-menu .navbar .close-button .btn-group-lg > button.btn[class*="btn-"]:not(.title) {
      padding-right: 63px; }
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-lg:after, .main-menu .navbar .close-button .btn-group-lg > button.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title) [class*="icon-"],
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title) [class^="icon-"],
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title) .fa {
      display: none; }
  .main-menu .navbar .close-button button span {
    display: none; }
  @media (min-width: 1024px) {
    .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle:after {
      content: none;
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: inherit;
      font-weight: normal; }
    .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-sm, .main-menu .navbar .btn-group-sm.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-sm:after, .main-menu .navbar .btn-group-sm.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-lg, .main-menu .navbar .btn-group-lg.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-lg:after, .main-menu .navbar .btn-group-lg.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) [class*="icon-"],
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) [class^="icon-"],
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) .fa {
        display: none; } }
  .main-menu .dropdown-link,
  .main-menu .nav-link {
    font-family: "Inter", sans-serif;
    text-decoration: none;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0; }
    .main-menu .dropdown-link:hover,
    .main-menu .nav-link:hover {
      text-decoration: none; }

.slide-up {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden; }
  .slide-up.hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none; }

.valid-cookie-warning {
  background-color: #3091E7;
  color: #FFFFFF;
  white-space: nowrap; }
  .valid-cookie-warning p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em; }

.checkout-header .header-locale-container .country-selector {
  display: block; }

.checkout-header .header-support-container .support {
  display: block; }

.checkout-header .header-account-container {
  display: block; }

.checkout-header .minicart {
  display: block; }

.header-account-container {
  display: none; }
  @media (min-width: 1024px) {
    .header-account-container {
      display: block; }
      .header-account-container .header-link.nav-link {
        font-size: 0;
        margin: 8px 0 0; }
        .header-account-container .header-link.nav-link:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal; }
        .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title).btn-sm, .header-account-container .btn-group-sm > .header-link.nav-link.btn[class*="btn-"]:not(.title) {
            padding-left: 42px; }
            .header-account-container .header-link.nav-link[class*="btn-"]:not(.title).btn-sm:before, .header-account-container .btn-group-sm > .header-link.nav-link.btn[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #181818;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title).btn-lg, .header-account-container .btn-group-lg > .header-link.nav-link.btn[class*="btn-"]:not(.title) {
            padding-left: 63px; }
            .header-account-container .header-link.nav-link[class*="btn-"]:not(.title).btn-lg:before, .header-account-container .btn-group-lg > .header-link.nav-link.btn[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #181818;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) [class*="icon-"],
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) [class^="icon-"],
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) .fa {
            display: none; }
        .header-account-container .header-link.nav-link > .header-link {
          display: none; } }

@media (min-width: 1024px) {
  header .user .dropdown-menu,
  header .support .dropdown-menu {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08); }
    header .user .dropdown-menu .dropdown-item + .dropdown-item,
    header .support .dropdown-menu .dropdown-item + .dropdown-item {
      border: none; }
    header .user .dropdown-menu .dropdown-item .header-link,
    header .support .dropdown-menu .dropdown-item .header-link {
      text-align: left;
      padding: 8px; }
  header .user .nav,
  header .support .nav {
    padding: 16px 0;
    display: block; }
  header.logo-center .header-link {
    font-size: 14px; }
  header.logo-center .support .dropdown-toggle {
    padding: calc((60px - 24px) / 2) 0; }
  header.logo-center .support .dropdown-menu-right {
    right: auto;
    max-width: 225px; }
  header.logo-center .header-column-2 {
    min-width: 212px; } }

html:not(.sticky-header) .transparent-header .header-nav {
  width: 100%;
  transition: background-color 200ms linear; }
  html:not(.sticky-header) .transparent-header .header-nav:not(.fixed) {
    position: absolute; }
  html:not(.sticky-header) .transparent-header .header-nav.fixed .header {
    background-color: transparent; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav {
    transition: color 200ms ease-in-out, box-shadow 200ms ease-in-out; }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark {
      color: #181818; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 18px;
        color: #181818;
        font-weight: normal; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-lg, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-lg:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) .fa {
          display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .brand a {
        filter: brightness(0); }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before {
        color: #181818; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: color;
          font-weight: normal; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: color;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: 42px; }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-lg, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-lg > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: 63px; }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-lg:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-lg > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class*="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class^="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) .fa {
            display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-quantity {
        color: #FFFFFF;
        background-color: #a80b1d; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-link:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .fa-search:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .site-search input {
          color: #181818; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .site-search .search-field::placeholder {
          color: #181818; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link {
          color: #181818; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link:before {
            background-color: #181818; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:hover .nav-link {
          color: #181818; } }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light {
      color: #FFFFFF; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 18px;
        color: #FFFFFF;
        font-weight: normal; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-lg, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-lg:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-lg > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) .fa {
          display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .brand a {
        filter: brightness(0) invert(1); }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before {
        color: #FFFFFF; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: color;
          font-weight: normal; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: color;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: 42px; }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-lg, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-lg > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: 63px; }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-lg:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-lg > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class*="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class^="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) .fa {
            display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-quantity {
        color: #FFFFFF;
        background-color: #222222; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-link:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .fa-search:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .site-search input {
          color: #FFFFFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .site-search .search-field::placeholder {
          color: #FFFFFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link {
          color: #FFFFFF; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link:before {
            background-color: #FFFFFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:hover .nav-link {
          color: #FFFFFF; } }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) {
      background: transparent;
      border-bottom: transparent;
      transition: background-color 200ms linear;
      width: 100%;
      position: absolute; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .main-menu {
          background-color: transparent;
          transition: background-color 200ms linear; } }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header {
        transition: color 200ms linear;
        background: transparent; }
        @media (min-width: 769px) {
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link {
            transition: color 200ms ease-out; } }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header .site-search {
          border: transparent; }

.header .brand .logo-home:focus {
  outline: 2px solid #181818; }

.header .wishlist-icon .icon-favorite:focus {
  outline: 2px solid #181818; }

@media (min-width: 1024px) {
  .header .user:hover .dropdown-menu {
    display: block; } }

.header .header-column-3 > .pull-right {
  margin-right: 32px; }
  @media (min-width: 1024px) {
    .header .header-column-3 > .pull-right.pr-4 {
      padding-right: 0 !important; } }

.header .brand .logo-home {
  position: relative; }
  .header .brand .logo-home:focus {
    outline: 2px solid #181818; }
  .header .brand .logo-home.selected:focus {
    outline: none; }

@media (min-width: 1024px) {
  .header .multilevel-dropdown .navbar-nav {
    justify-content: center; } }

.header #mobile-search-toggle {
  padding-left: 8px;
  font-weight: 500;
  font-size: 20px; }

body.sub-category-open .page {
  overflow: hidden; }

body.nav-overlay .modal-background {
  display: none; }
  @media (min-width: 1024px) {
    body.nav-overlay .modal-background {
      display: block; } }

.header-banner {
  z-index: 999;
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #BFBFBF;
  padding: 0; }
  @media (max-width: 1023.98px) {
    .header-banner .header-promotion {
      height: 36px;
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; } }
  @media (max-width: 768.98px) {
    .header-banner {
      font-size: 12px !important; } }
  .header-banner b {
    font-weight: 500; }
  .header-banner a {
    color: #BFBFBF;
    font-weight: 500; }
  .header-banner .header-banner-column-2 .close-button {
    z-index: 1; }
    .header-banner .header-banner-column-2 .close-button .close {
      text-shadow: none; }
  .header-banner .tns-nav-controller:not(.with-pagination) {
    padding: 0;
    height: 24px; }
  .header-banner .container {
    max-width: 100%; }
  .header-banner .mobile-1r-1c {
    padding: 8px 8px 8px 8px; }
    @media (min-width: 1024px) {
      .header-banner .mobile-1r-1c .container {
        max-width: 1440px; } }
  .header-banner .blue {
    background-color: #ACE7FA; }
  .header-banner .green {
    background-color: #2EAB2B; }
  .header-banner .yellow {
    background-color: #FFDF59; }
  .header-banner .red {
    background-color: #a80b1d; }
  .header-banner .brand-primary a {
    color: #181818; }
  .header-banner .brand-primary strong {
    color: #181818; }
  .header-banner .brand-primary .header-promotion {
    color: #181818; }
  .header-banner .white a {
    color: #FFFFFF; }
  .header-banner .white strong {
    color: #FFFFFF; }
  .header-banner .white .header-promotion {
    color: #FFFFFF; }
  .header-banner .light-gray a {
    color: #F9F9F9; }
  .header-banner .light-gray strong {
    color: #F9F9F9; }
  .header-banner .light-gray .header-promotion {
    color: #F9F9F9; }
  .header-banner .medium-gray a {
    color: #D4D4D4; }
  .header-banner .medium-gray strong {
    color: #D4D4D4; }
  .header-banner .medium-gray .header-promotion {
    color: #D4D4D4; }
  .header-banner .dark-gray a {
    color: #595959; }
  .header-banner .dark-gray strong {
    color: #595959; }
  .header-banner .dark-gray .header-promotion {
    color: #595959; }

.header {
  padding: 0;
  height: 60px; }
  @media (min-width: 769px) {
    .header {
      height: 60px;
      padding: 24px 0 8px 0; } }
  @media (min-width: 1024px) {
    .header {
      height: 60px; }
      .header .user .dropdown-menu {
        padding: 0; }
      .header .user .nav {
        padding: 16px 0 0; } }
  .header .fa-search {
    width: 22px;
    height: 22px;
    background: url(../images/icons/Search.svg) no-repeat center;
    background-size: contain; }
    @media (min-width: 769px) {
      .header .fa-search {
        width: 25px;
        height: 25px; } }
  .header .header-locale-container .country-selector {
    display: none; }
  .header .header-support-container .support {
    display: none; }
  .header .wishlist-icon .icon-favorite {
    display: block;
    text-decoration: none; }
    .header .wishlist-icon .icon-favorite:focus {
      outline: 2px solid #181818; }
    .header .wishlist-icon .icon-favorite:before {
      font-size: 20px;
      display: inline-block;
      vertical-align: middle; }
    .header .wishlist-icon .icon-favorite.selected:focus {
      outline: none; }
  .header .minicart .minicart-total {
    position: relative;
    margin-right: 16px; }
  .header .minicart .minicart-link {
    display: inline-block;
    text-decoration: none;
    vertical-align: top; }
    .header .minicart .minicart-link:focus, .header .minicart .minicart-link:active {
      outline: 2px solid #181818; }
    .header .minicart .minicart-link.selected:focus {
      outline: none; }
    .header .minicart .minicart-link .minicart-icon {
      display: block; }
      .header .minicart .minicart-link .minicart-icon:before {
        display: block; }
  .header .navbar-toggler {
    border-radius: 0; }
    @media (max-width: 1023.98px) {
      .header .navbar-toggler {
        padding-left: 0; } }

.header-nav {
  z-index: 999; }
  .logo-center .header-nav {
    border-color: #D4D4D4; }
  .header-nav .flag-icon {
    cursor: pointer; }

.main-menu .nav-link {
  font-family: "Inter", sans-serif; }

.main-menu .navbar .close-menu button,
.main-menu .navbar .close-button button {
  padding: 16px 0;
  color: #FFFFFF; }
  .main-menu .navbar .close-menu button span.icon.icon-arrow-left-thin,
  .main-menu .navbar .close-button button span.icon.icon-arrow-left-thin {
    color: #181818; }

.sticky-header .fixed-header-enhanced .header-nav {
  box-shadow: none;
  border-bottom: 0px solid transparent; }

.fixed-header-enhanced.new-minicart-border .header {
  padding: 18px 0; }
  @media (min-width: 769px) {
    .fixed-header-enhanced.new-minicart-border .header {
      padding: 18px 0; } }

.fixed-header-enhanced.new-minicart-border .header-nav {
  border-bottom: 2px solid #D4D4D4; }

.header-account-container .user.custom-dropdown .dropdown-toggle {
  padding: 0 0 0 0; }
  .header-account-container .user.custom-dropdown .dropdown-toggle:focus, .header-account-container .user.custom-dropdown .dropdown-toggle:active {
    outline: 2px solid #181818; }
  .header-account-container .user.custom-dropdown .dropdown-toggle:after {
    display: none; }
  .header-account-container .user.custom-dropdown .dropdown-toggle.selected:focus {
    outline: none; }

html:not(.sticky-header) .transparent-header .new-header {
  background-color: #FFFFFF;
  position: relative; }

html:not(.sticky-header) .transparent-header .header-nav {
  transition: background-color 200ms linear, border-color 200ms linear; }
  @media (min-width: 1024px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).new-header {
      position: relative; } }
  @media (max-width: 1023.98px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search {
      background: #FFFFFF;
      position: relative; } }
  @media (max-width: 1023.98px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .brand a:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .fa-search:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .wishlist-icon .icon-favorite:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .minicart .minicart-link:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .search-field:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .header-link.nav-link:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .nav-link:focus {
      outline: 2px solid #FFFFFF; } }
  @media (max-width: 1023.98px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .minicart .minicart-link {
      text-decoration: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .minicart .minicart-link .minicart-icon {
        display: block;
        color: #181818; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .minicart .minicart-link .minicart-icon:before {
          display: block;
          color: #181818; } }
  @media (max-width: 1023.98px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .navbar-toggler:before,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .wishlist-icon .icon-favorite:before {
      color: #181818; } }
  @media (max-width: 1023.98px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .brand a,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .fa-search {
      filter: brightness(0); } }
  @media (max-width: 1023.98px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .brand a:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .fa-search:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .wishlist-icon .icon-favorite:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .minicart .minicart-link:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .search-field:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .header-link.nav-link:focus,
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed).header-with-search .nav-link:focus {
      outline: 2px solid #FFFFFF; } }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-link {
    text-decoration: none; }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-link .minicart-icon {
      display: block; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-link .minicart-icon:before {
        display: block; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .wishlist-icon .icon-favorite:before {
    color: #FFFFFF; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .fa-search {
    filter: brightness(0) invert(1); }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .brand a:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .fa-search:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .wishlist-icon .icon-favorite:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .search-field:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .header-link.nav-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-link:focus {
    outline: 2px solid #181818; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .brand a:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .fa-search:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .wishlist-icon .icon-favorite:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .search-field:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .header-link.nav-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-link:focus {
    outline: 2px solid #181818; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover).highlight .nav-link {
    color: #a80b1d; }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover).highlight .nav-link:hover {
      color: #595959; }
  @media (max-width: 1023.98px) {
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed).header-with-search {
      background: #FFFFFF;
      position: relative; } }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .brand a:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .fa-search:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .wishlist-icon .icon-favorite:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .search-field:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .header-link.nav-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-link:focus {
    outline: 2px solid #FFFFFF; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-link {
    text-decoration: none; }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-link .minicart-icon {
      display: block; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-link .minicart-icon:before {
        display: block; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .wishlist-icon .icon-favorite:before {
    color: #181818; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .fa-search {
    filter: brightness(0); }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .brand a:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .fa-search:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .wishlist-icon .icon-favorite:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .search-field:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .header-link.nav-link:focus,
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-link:focus {
    outline: 2px solid #FFFFFF; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed).new-header {
    background-color: #FFFFFF;
    position: relative; }
  html:not(.sticky-header) .transparent-header .header-nav:not(.fixed).new-header {
    background-color: #FFFFFF;
    position: relative; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) #search-mobile:before {
    color: #FFFFFF; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-quantity {
    background: #FFFFFF;
    color: #181818; }

@media (max-width: 1023.98px) {
  .logo-center .header.container {
    padding: 0 16px; }
  .logo-center .header-with-search .header.container {
    padding: 0 16px 16px; }
  .logo-center .header-with-search .header.containers {
    padding: 0 16px 16px; }
  .logo-center .header-column-1 {
    order: 3;
    justify-content: flex-end;
    flex-grow: 0;
    margin-right: -16px;
    box-sizing: border-box; }
  .logo-center .header-column-2 {
    order: 1;
    justify-content: flex-start;
    flex-grow: 1;
    max-width: 95%; }
  .logo-center .header-column-3 {
    order: 2;
    justify-content: flex-end;
    flex-grow: 0;
    position: static; } }
  @media (max-width: 1023.98px) and (min-width: 769px) {
    .logo-center .header-column-3 {
      position: relative; } }

@media (max-width: 1023.98px) {
    .logo-center .header-column-3 > .pull-right {
      margin-right: 16px; }
    .logo-center .header-column-3 .minicart .minicart-total {
      margin-right: 0; }
    .logo-center .header-column-3 #search-mobile {
      top: -1px;
      margin: 0 16px;
      position: relative; } }

#header-secondary-nav-mobile .region {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between; }
  #header-secondary-nav-mobile .region .button > a.btn-tertiary {
    text-decoration: none; }
    #header-secondary-nav-mobile .region .button > a.btn-tertiary:hover {
      text-decoration: underline; }

#header-secondary-nav-mobile .container {
  padding: 0; }

header.logo-center .new-header .header-column-2 {
  min-width: inherit; }

header .new-header {
  padding: 0 16px; }
  @media (min-width: 1024px) {
    header .new-header {
      position: relative;
      padding: 0;
      padding-block-end: 1.5%; } }
  header .new-header .header {
    height: auto; }
    @media (min-width: 769px) {
      header .new-header .header {
        padding: 0; } }
    @media (min-width: 1024px) {
      header .new-header .header {
        padding: 16px;
        height: 82px; }
        header .new-header .header .header-column-2 {
          justify-content: center; }
          header .new-header .header .header-column-2 .brand {
            padding-left: 15%; } }
    @media (min-width: 1200px) {
      header .new-header .header {
        padding: 32px;
        height: 112px; } }
    @media (max-width: 1023.98px) {
      header .new-header .header > .row {
        height: 56px; } }
  header .new-header .header-column-2 {
    min-width: inherit; }
  @media (min-width: 1024px) {
    header .new-header .main-menu .container {
      padding: 0; } }
  header .new-header .navbar.bg-inverse .navbar-nav .nav-link {
    color: inherit; }
    @media (min-width: 1024px) {
      header .new-header .navbar.bg-inverse .navbar-nav .nav-link {
        padding: 6.4px; } }
    @media (min-width: 1200px) {
      header .new-header .navbar.bg-inverse .navbar-nav .nav-link {
        padding: 10.66667px; } }
    @media (min-width: 1440px) {
      header .new-header .navbar.bg-inverse .navbar-nav .nav-link {
        padding: 16px; } }
  header .new-header .navbar.bg-inverse .nav-item.highlight .nav-link {
    color: #a80b1d; }
    header .new-header .navbar.bg-inverse .nav-item.highlight .nav-link:hover {
      color: #595959; }
  @media (min-width: 1024px) {
    header .new-header .header-column-2 {
      position: static;
      mask-image: none;
      flex-grow: 2; } }
  @media (min-width: 1024px) {
    header .new-header .header-column-2 .multilevel-dropdown .dropdown-menu {
      top: 75%; } }
  @media (min-width: 1024px) {
    header .new-header .brand .logo-home {
      width: 152px; } }
  @media (min-width: 1200px) {
    header .new-header .brand .logo-home {
      width: 182px; } }
  @media (min-width: 1440px) {
    header .new-header .brand .logo-home {
      width: 212px; } }
  header .new-header .header-column-1 {
    mask-image: none;
    order: 1; }
    @media (min-width: 1024px) {
      header .new-header .header-column-1 {
        max-width: 20%;
        flex-grow: 0.6;
        order: 0; } }
    @media (min-width: 1440px) {
      header .new-header .header-column-1 {
        flex-grow: 1; } }
  @media (min-width: 1024px) {
    header .new-header .multilevel-dropdown .dropdown-menu {
      top: 120%; } }
  @media (min-width: 1024px) {
    header .new-header .header-column-3 {
      flex-grow: 1; } }
  @media (min-width: 1024px) {
    header .new-header .header-column-3 > .pull-right {
      margin-right: 8px; } }
  @media (min-width: 1200px) {
    header .new-header .header-column-3 > .pull-right {
      margin-right: 16px; } }
  header .new-header .header-column-3 > .pull-right:last-child {
    margin-right: 0; }
  @media (max-width: 1023.98px) {
    header .new-header .header-column-3 .navbar-toggler {
      padding-right: 0; } }
  @media (min-width: 1024px) {
    header .new-header .header-column-3 .search-icon-only .site-search {
      height: auto; } }
  @media (min-width: 1024px) {
    header .new-header .header-column-3 .search-icon-only .site-search .search-field {
      width: 155px;
      border: 1px solid #D4D4D4;
      padding: 16px; } }
  @media (min-width: 1200px) {
    header .new-header .header-column-3 .search-icon-only .site-search .search-field {
      width: 185px; } }
  @media (min-width: 1440px) {
    header .new-header .header-column-3 .search-icon-only .site-search .search-field {
      width: 239px; } }
  header .new-header .header-column-3 .search-icon-only .site-search .fa-search {
    right: 16px; }
  header .new-header .header-column-3 .search-icon-only .site-search.expend .search-field {
    border: 1px solid #D4D4D4;
    padding: 16px; }
    @media (min-width: 1024px) {
      header .new-header .header-column-3 .search-icon-only .site-search.expend .search-field {
        width: 155px; } }
    @media (min-width: 1200px) {
      header .new-header .header-column-3 .search-icon-only .site-search.expend .search-field {
        width: 185px; } }
    @media (min-width: 1440px) {
      header .new-header .header-column-3 .search-icon-only .site-search.expend .search-field {
        width: 239px; } }
  @media (max-width: 1023.98px) {
    header .new-header .site-search .fa-search {
      display: block !important;
      top: 7px;
      right: 16px; } }
  @media (max-width: 1023.98px) {
    header .new-header .site-search .fa-close,
    header .new-header .site-search .reset-button {
      display: none !important; } }
  @media (max-width: 1023.98px) {
    header .new-header .site-search .search-field {
      height: 42px;
      border: 1px solid #D4D4D4; }
      header .new-header .site-search .search-field:focus {
        border: 2px solid #181818; } }
  @media (max-width: 1023.98px) {
    header .new-header .suggestions-wrapper {
      height: auto; }
      header .new-header .suggestions-wrapper.search-active {
        height: calc(100vh - 150px);
        position: absolute !important;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 9; }
      header .new-header .suggestions-wrapper .suggestions {
        width: 100%;
        border-radius: 0; } }
  header .new-header .minicart .minicart-total {
    margin: 0; }

@media (max-width: 1023.98px) {
  header .header-with-search .header {
    height: auto; } }

@media (max-width: 1023.98px) {
  header .header-with-search .header > .row {
    height: 56px; } }

@media (max-width: 1023.98px) {
  header .header-with-search .site-search .fa-search {
    display: block !important;
    top: 7px;
    right: 16px; } }

@media (max-width: 1023.98px) {
  header .header-with-search .site-search .fa-close,
  header .header-with-search .site-search .reset-button {
    display: none !important; } }

@media (max-width: 1023.98px) {
  header .header-with-search .site-search .search-field {
    height: 40px;
    border: 1px solid #D4D4D4;
    border-radius: 20px;
    background: #f3f3f3; }
    header .header-with-search .site-search .search-field:focus {
      border: 2px solid #181818; } }

@media (max-width: 1023.98px) {
  header .header-with-search #search-mobile {
    display: none; } }

@media (max-width: 1023.98px) {
  header .header-with-search .suggestions-wrapper {
    height: auto; }
    header .header-with-search .suggestions-wrapper.search-active {
      height: calc(100vh - 150px);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      right: 0; }
    header .header-with-search .suggestions-wrapper .suggestions {
      width: 100%;
      border-radius: 0; } }

@media (max-width: 1023.98px) {
  .adjust-logo {
    padding-left: 7%; } }

@media (min-width: 1024px) {
  .checkout-header .new-header {
    padding-block-end: 0%; } }

@media (max-width: 1023.98px) {
  .logo-center .usertopnavlayout .header-column-1 {
    order: 1;
    justify-content: flex-start;
    margin-right: 0px; }
  .logo-center .usertopnavlayout .header-column-2 {
    order: 2;
    justify-content: center; }
  .logo-center .usertopnavlayout .header-column-3 .minicart {
    margin-right: 0px; }
  .logo-center .header-column-3 .minicart {
    margin-right: 16px; } }

@media (max-width: 1023.98px) {
  .usertopnavlayout .header-column-3 .icon-spacing {
    margin-right: 24px; } }

.site-search {
  position: relative;
  width: 100%; }
  .site-search .search-field {
    border-top: none; }
  .site-search .fa-close,
  .site-search .fa-search {
    position: absolute;
    top: 16px;
    border: none;
    right: 20px;
    padding: 0;
    background-color: transparent; }
  .site-search .fa-close:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: #181818;
    font-weight: normal; }
  .site-search .fa-close[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .site-search .fa-close[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .site-search .fa-close[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .fa-close.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .site-search .fa-close[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .fa-close.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-close[class*="btn-"]:not(.title).btn-lg, .site-search .btn-group-lg > .fa-close.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .site-search .fa-close[class*="btn-"]:not(.title).btn-lg:before, .site-search .btn-group-lg > .fa-close.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-close[class*="btn-"]:not(.title) [class*="icon-"],
    .site-search .fa-close[class*="btn-"]:not(.title) [class^="icon-"],
    .site-search .fa-close[class*="btn-"]:not(.title) .fa {
      display: none; }
  .site-search .fa-search:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: #181818;
    font-weight: normal; }
  .site-search .fa-search[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .site-search .fa-search[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .site-search .fa-search[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .fa-search.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .site-search .fa-search[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .fa-search.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-search[class*="btn-"]:not(.title).btn-lg, .site-search .btn-group-lg > .fa-search.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .site-search .fa-search[class*="btn-"]:not(.title).btn-lg:before, .site-search .btn-group-lg > .fa-search.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-search[class*="btn-"]:not(.title) [class*="icon-"],
    .site-search .fa-search[class*="btn-"]:not(.title) [class^="icon-"],
    .site-search .fa-search[class*="btn-"]:not(.title) .fa {
      display: none; }
  .site-search .reset-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    right: 20px;
    padding: 0 5px;
    background-color: #FFFFFF;
    z-index: 1; }
    .site-search .reset-button:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #181818;
      font-weight: normal; }
    .site-search .reset-button[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .site-search .reset-button[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .site-search .reset-button[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .reset-button.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .site-search .reset-button[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .reset-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .site-search .reset-button[class*="btn-"]:not(.title).btn-lg, .site-search .btn-group-lg > .reset-button.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .site-search .reset-button[class*="btn-"]:not(.title).btn-lg:before, .site-search .btn-group-lg > .reset-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .site-search .reset-button[class*="btn-"]:not(.title) [class*="icon-"],
      .site-search .reset-button[class*="btn-"]:not(.title) [class^="icon-"],
      .site-search .reset-button[class*="btn-"]:not(.title) .fa {
        display: none; }
  .site-search input {
    height: calc(16px + 16px + 14px + (1px*2));
    padding-right: 40px;
    appearance: none; }
    @media (min-width: 1024px) {
      .site-search input {
        height: 100%; } }
    .site-search input:hover {
      box-shadow: none; }
  @media (min-width: 1024px) {
    .site-search {
      float: right;
      display: block;
      height: 60px;
      border-width: 0px;
      border-color: transparent;
      border-style: solid; }
      .site-search form {
        float: right; }
      .site-search .search-field {
        font-family: "Inter", sans-serif;
        text-decoration: none;
        text-transform: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        border: none;
        text-align: left;
        color: #181818;
        padding-top: calc((60px - 24px) / 2);
        padding-bottom: calc((60px - 24px) / 2);
        padding-right: 40px;
        padding-left: 20px;
        transition: width 200ms ease-out;
        width: 125px;
        background: transparent;
        text-decoration: none; }
        .site-search .search-field:hover {
          text-decoration: none; }
        .site-search .search-field::placeholder {
          color: #181818; }
        .site-search .search-field:focus {
          box-shadow: none; }
        .site-search .search-field:hover {
          text-decoration: none; }
      .site-search .fa-close,
      .site-search .fa-search {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .site-search .fa-close::before,
      .site-search .fa-search::before,
      .site-search .reset-button::before {
        font-size: 22px; } }
  @media (min-width: 1440px) {
    .site-search .search-field:focus {
      width: 200px; } }

@media (min-width: 1024px) {
  .search-icon-only .site-search {
    border-color: transparent;
    border-style: solid;
    border-width: 0px;
    transition: border 200ms ease-out;
    width: auto; }
    .search-icon-only .site-search .search-field {
      border-left: none;
      border-right: none;
      width: 0; }
    .search-icon-only .site-search.expanded {
      border-color: transparent; }
      .search-icon-only .site-search.expanded .search-field {
        width: 125px; }
      .search-icon-only .site-search.expanded button {
        color: transparent; }
  .logo-center .search-icon-only .site-search {
    height: 60px;
    border-bottom: 0;
    border-top: 0; } }

@media (min-width: 1440px) {
  .search-icon-only .site-search.expanded .search-field {
    width: 200px; } }

.suggestions-wrapper {
  position: relative; }
  .suggestions-wrapper .suggestions {
    list-style-type: none;
    display: block;
    background-color: #FFFFFF;
    top: 0;
    right: 0;
    box-shadow: 0 2px 2px rgba(34, 34, 34, 0.2);
    position: absolute;
    width: 350px; }
  .suggestions-wrapper .suggestions-header {
    font-family: "Space Grotesk", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    color: #181818;
    text-transform: uppercase;
    padding-top: 16px;
    background: transparent; }
    .suggestions-wrapper .suggestions-header:not(:first-child) {
      border-top: none; }
  .suggestions-wrapper .suggestions-items {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    padding-bottom: 16px; }
  .suggestions-wrapper .suggestions-item a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-top: 3px;
    padding-bottom: 3px; }
  .suggestions-wrapper .suggestions-item .swatch-thumbnail {
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    background-size: cover;
    display: block;
    position: relative;
    text-align: center;
    border-radius: 0;
    margin-right: 16px; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value {
      transition: box-shadow 200ms ease-out; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value:hover:not(.unselectable) {
        box-shadow: inset 0px -2px 0px #595959; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected {
        box-shadow: inset 0px -2px 0px #222222; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 7px;
          color: #FFFFFF;
          font-weight: normal; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .suggestions-wrapper .suggestions-item .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .suggestions-wrapper .suggestions-item .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) .fa {
            display: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.selected::after {
          position: absolute;
          top: -4.66667px;
          right: -4.66667px;
          display: none;
          background: rgba(34, 34, 34, 0.7);
          width: 14px;
          height: 14px;
          line-height: 14px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          z-index: 1; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.unselectable:before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.unselectable:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(calc(100% + 15px) + 18px);
        width: 1px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.unselectable:after {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
        z-index: 2;
        width: 2px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.Color-value.unselectable {
        pointer-events: none;
        pointer-events: auto;
        cursor: not-allowed;
        position: relative; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value {
      transition: border 200ms ease-out, box-shadow 200ms ease-out; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value:not(.unselectable):hover {
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover {
        background: #F9F9F9;
        color: #222222;
        border-color: #181818;
        box-shadow: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 8px;
          color: #FFFFFF;
          font-weight: normal; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .suggestions-wrapper .suggestions-item .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .suggestions-wrapper .suggestions-item .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .suggestions-wrapper .suggestions-item .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .suggestions-wrapper .suggestions-item .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
            display: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected::after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
          position: absolute;
          top: -5.33333px;
          right: -5.33333px;
          background: #181818;
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          display: none; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unselectable {
        pointer-events: auto;
        cursor: not-allowed;
        position: relative;
        color: #D4D4D4; }
  .suggestions-wrapper .suggestions-item .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .suggestions-wrapper .suggestions-item .category-parent {
    color: #BEBEBE; }
  .suggestions-wrapper .suggestions-item.selected {
    background-color: #F9F9F9; }

.site-search form {
  position: relative; }

.suggestions-wrapper .suggestions {
  border-radius: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: 0 0 10px rgba(34, 34, 34, 0.2);
  overflow: hidden; }
  .suggestions-wrapper .suggestions ul {
    list-style: none;
    margin-bottom: 0; }

.suggestions-wrapper .suggestions-item .swatch-thumbnail {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

@media (max-width: 1023.98px) {
  .suggestions-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 110px); } }

@media (min-width: 1024px) {
  .search-icon-only .site-search .search-field:focus {
    border-left: 2px solid #181818;
    border-right: 2px solid #181818; }
  .search-icon-only .site-search.expanded button {
    background-color: transparent; }
    .search-icon-only .site-search.expanded button.reset-button:before {
      color: transparent; }
  .search-icon-only .site-search .fa-search {
    padding-top: 0;
    padding-left: 2px;
    right: 2px;
    margin-top: -2px; }
    .search-icon-only .site-search .fa-search:focus, .search-icon-only .site-search .fa-search:active {
      outline: 2px solid #181818; } }

.site-search .search-field-label {
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.site-search .fa-search:before {
  display: none; }

@media (min-width: 1024px) {
  .usertopnavlayout .header-column-3 .site-search .form-control {
    margin-top: 10px;
    width: 155px;
    border: 1px solid #D4D4D4;
    border-radius: 35px;
    background: #f3f3f3; } }

@media (min-width: 1200px) {
  .usertopnavlayout .header-column-3 .site-search .form-control {
    width: 185px; } }

@media (min-width: 1440px) {
  .usertopnavlayout .header-column-3 .site-search .form-control {
    width: 239px; } }

.usertopnavlayout .form-control, .usertopnavlayout select.form-control {
  padding: 16px !important; }

@media (min-width: 1024px) {
  .usertopnavlayout .site-search .fa-search {
    margin-top: 4px;
    right: 16px; } }

.EMEA-header-search header .site-search .search-field {
  border: 1px solid #D4D4D4 !important; }
  @media (max-width: 1199.98px) {
    .EMEA-header-search header .site-search .search-field {
      border-radius: 20px;
      background: #f3f3f3; } }
  @media (min-width: 1024px) {
    .EMEA-header-search header .site-search .search-field {
      border-radius: 35px;
      background: #f3f3f3;
      width: 100%; } }

@media (min-width: 1024px) {
  .EMEA-header-search header .site-search input {
    height: 40px; } }

.EMEA-header-search .search.hidden-md-down.pull-right.search-expanded {
  margin-left: 32px; }

.EMEA-header-search #a2c-version-3 .cart-and-ipay .add-to-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px !important;
  font-size: 14px;
  text-wrap: nowrap; }

.EMEA-header-search .back-to-top-btn {
  bottom: 120px; }

.EMEA-header-search .sticky-a2c-section.d-md-none {
  z-index: 99; }

.hero {
  position: relative;
  overflow: hidden;
  display: flex; }
  .hero .hero-container {
    display: flex;
    padding: 16px 16px 32px;
    height: 100%;
    width: 100%; }
    @media (min-width: 769px) {
      .hero .hero-container {
        padding: 32px; } }
    @media (min-width: 1024px) {
      .hero .hero-container {
        padding: 96px 32px; } }
    @media (min-width: 1440px) {
      .full-bleed .hero .hero-container {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto; } }
    .hero .hero-container.overlay::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      content: " "; }
    .hero .hero-container .hero-background-link {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-indent: -999px; }
      .hero .hero-container .hero-background-link .video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        .hero .hero-container .hero-background-link .video-container .video-player {
          display: none; }
  .hero .embedded-component {
    width: 100%; }
    .hero .embedded-component:empty {
      display: none; }
    .hero .embedded-component .experience-region:empty {
      display: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero {
      height: 0; }
      .hero.no-image {
        height: auto; } }
  .hero.short {
    min-height: 150px; }
    @media (min-width: 769px) {
      .hero.short {
        min-height: 80px; } }
    @media (min-width: 1024px) {
      .hero.short {
        min-height: 80px; } }
  .hero.medium {
    min-height: 250px; }
    @media (min-width: 769px) {
      .hero.medium {
        min-height: 300px; } }
    @media (min-width: 1024px) {
      .hero.medium {
        min-height: 350px; } }
  .hero.tall {
    min-height: 263px; }
    @media (min-width: 769px) {
      .hero.tall {
        min-height: 544px; } }
    @media (min-width: 1024px) {
      .hero.tall {
        min-height: 544px; } }
  .hero.screen-height {
    min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); }
    @media (min-width: 769px) {
      .hero.screen-height {
        min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); } }
    @media (min-width: 1024px) {
      .hero.screen-height {
        min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); } }
    .transparent-header ~ div .hero.screen-height {
      min-height: calc(100vh - 24px - 8px - 8px); }
      @media (min-width: 769px) {
        .transparent-header ~ div .hero.screen-height {
          min-height: calc(100vh - 24px - 8px - 8px); } }
      @media (min-width: 1024px) {
        .transparent-header ~ div .hero.screen-height {
          min-height: calc(100vh - 24px - 8px - 8px); } }
  @media (max-width: 768.98px) {
    .hero.short-mobile {
      min-height: 150px; } }
  @media (max-width: 768.98px) {
    .hero.medium-mobile {
      min-height: 250px; } }
  @media (max-width: 768.98px) {
    .hero.tall-mobile {
      min-height: 263px; } }
  @media (max-width: 768.98px) {
    .hero.screen-height-mobile {
      min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); } }
  @media (max-width: 768.98px) {
    .transparent-header ~ div .hero.screen-height-mobile {
      min-height: calc(100vh - 24px - 8px - 8px); } }
  @media (max-width: 1023.98px) {
    .hero.no-height {
      min-height: inherit; } }
  .hero.hero-banner-custom-spacing .hero-container {
    padding: 16px; }
    @media (min-width: 1024px) {
      .hero.hero-banner-custom-spacing .hero-container {
        padding: 64px 32px; } }
  .hero.original-image {
    height: auto !important;
    max-height: inherit !important;
    min-height: auto !important; }
    .hero.original-image .hero-container {
      padding: 0; }
      @media (min-width: 769px) {
        .hero.original-image .hero-container {
          padding: 0; } }
      @media (min-width: 1440px) {
        .full-bleed .hero.original-image .hero-container {
          max-width: 100%; } }
    .hero.original-image .image-wrapper {
      flex-basis: 100%; }
    .hero.original-image .hero-text {
      position: absolute;
      padding: 40px 16px; }
      @media (min-width: 769px) {
        .hero.original-image .hero-text {
          padding: 80px 32px; } }
    .hero.original-image.add-text-under .hero-text {
      position: relative;
      padding: 0; }
      @media (min-width: 769px) {
        .hero.original-image.add-text-under .hero-text {
          padding: 0; } }

.hero-media {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .full-bleed .hero-media {
    width: calc(100vw + 2px); }
  .hero-media.hero-media-tablet, .hero-media.hero-media-desktop {
    display: none !important; }
  @media (min-width: 769px) {
    .hero-media.hero-media-mobile, .hero-media.hero-media-desktop {
      display: none !important; }
    .hero-media.hero-media-tablet {
      display: block !important; } }
  @media (min-width: 1024px) {
    .hero-media.hero-media-mobile, .hero-media.hero-media-tablet {
      display: none !important; }
    .hero-media.hero-media-desktop {
      display: block !important; } }
  .hero-media.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    right: auto;
    transform: none;
    transition: opacity 200ms ease-out; }
    .hero-media.video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-16-9 {
        padding-top: calc(9 / 16 * 100%);
        padding-left: calc(16 / 9 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-9-16 {
        padding-top: calc(16 / 9 * 100%);
        padding-left: calc(9 / 16 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-4-3 {
        padding-top: calc(3 / 4 * 100%);
        padding-left: calc(4 / 3 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-3-4 {
        padding-top: calc(4 / 3 * 100%);
        padding-left: calc(3 / 4 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-1-1 {
        padding-top: calc(1 / 1 * 100%);
        padding-left: calc(1 / 1 * 100vh); }
      .hero-media.video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .hero-media.video-container .video-aspect-ratio iframe,
      .hero-media.video-container .video-aspect-ratio video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .hero-media.video-container .video-aspect-ratio iframe.video-player,
        .hero-media.video-container .video-aspect-ratio video.video-player {
          position: absolute; }
    .hero-media.video-container ~ .replay-video {
      z-index: 1;
      position: absolute;
      opacity: 0;
      transition: opacity 200ms ease-out; }
      .align-items-start .hero-media.video-container ~ .replay-video,
      .align-items-center .hero-media.video-container ~ .replay-video {
        bottom: 16px; }
      .align-items-end .hero-media.video-container ~ .replay-video {
        top: 16px; }
        .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
          top: 76px; }
          @media (min-width: 769px) {
            .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
              top: 76px; } }
          @media (min-width: 1024px) {
            .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
              top: 76px; } }
      .justify-content-md-start .hero-media.video-container ~ .replay-video {
        right: 16px; }
      .justify-content-md-end .hero-media.video-container ~ .replay-video,
      .justify-content-md-center .hero-media.video-container ~ .replay-video {
        left: 16px; }
    .hero-media.video-container.video-stopped {
      opacity: 0; }
      .hero-media.video-container.video-stopped ~ .replay-video {
        transition: opacity 200ms ease-out 600ms;
        opacity: 1; }

.hero-text {
  overflow: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 2; }
  .text-in-card .hero-text {
    padding: 16px;
    border-width: 1px;
    border-style: solid; }
    @media (min-width: 769px) {
      .text-in-card .hero-text {
        padding: 16px; } }
    @media (min-width: 1024px) {
      .text-in-card .hero-text {
        padding: 16px; } }
    .text-in-card .hero-text:not([class*='border--']) {
      border-color: transparent; }
    .text-in-card .hero-text.semitransparent:not([class*='background--']).dark-theme {
      background: rgba(34, 34, 34, 0.7); }
    .text-in-card .hero-text.semitransparent:not([class*='background--']).light-theme {
      background: rgba(255, 255, 255, 0.7); }
    .text-in-card .hero-text.opaque:not([class*='background--']).dark-theme {
      background: #222222; }
    .text-in-card .hero-text.opaque:not([class*='background--']).light-theme {
      background: #FFFFFF; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero-text.text-center {
      align-items: center; }
    .hero-text.text-right {
      align-items: flex-end; } }
  @media (min-width: 769px) {
    .hero-text.width-1-4 {
      max-width: 25%; }
    .hero-text.width-1-3 {
      max-width: 33.3333%; }
    .hero-text.width-1-2 {
      max-width: 50%; }
    .hero-text.width-2-3 {
      max-width: 66.6666%; }
    .hero-text.width-3-4 {
      max-width: 75%; }
    .hero-text.width-1 {
      max-width: 100%; } }
  .hero-text .hero-subtitle > *,
  .hero-text .hero-title > *,
  .hero-text .hero-body > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .hero-text .hero-subtitle a,
  .hero-text .hero-title a,
  .hero-text .hero-body a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    background: inherit;
    margin-bottom: inherit; }
  .hero-text .hero-subtitle:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    font-family: "Space Grotesk", sans-serif;
    font-size: 16px;
    text-transform: none; }
  .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    font-family: "Space Grotesk", sans-serif;
    font-size: 48px;
    line-height: 52px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: -2px;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
        font-family: "Space Grotesk", sans-serif;
        font-size: 72px;
        line-height: 80px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: -2px;
        font-style: normal;
        font-display: swap; } }
  .hero-text .hero-title.headline-xxl:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    font-family: "Inter", sans-serif;
    font-size: 96px;
    line-height: 96px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: -6px;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .hero-text .hero-title.headline-xxl:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
        font-family: "Inter", sans-serif;
        font-size: 144px;
        line-height: 144px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: -6px;
        font-style: normal;
        font-display: swap; } }
  .hero-text .hero-body ul {
    list-style: disc;
    list-style-position: outside;
    margin-left: 16px; }
  @media (min-width: 544px) {
    .hero-text .hero-body ul {
      columns: 2; } }
  .hero-text .hero-subtitle + .hero-title,
  .hero-text .hero-title + .hero-body,
  .hero-text .hero-embedded-component + .hero-body,
  .hero-text .hero-embedded-component .experience-region > * {
    margin-top: 16px; }
  .hero-text .hero-body + .hero-buttons .hero-button {
    margin-top: 24px; }
  .hero-text .hero-buttons {
    font-size: 0; }
  .hero-text .hero-button {
    margin-top: 16px;
    display: inline-block;
    position: relative; }
  .hero-text.text-center .hero-button:not(:only-child) {
    margin-left: 8px;
    margin-right: 8px; }
  .hero-text.text-left .hero-button:not(:only-child) {
    margin-right: 16px; }
  .hero-text.text-right .hero-button:not(:only-child) {
    margin-left: 16px; }
  .hero-text .megamenu-link-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    columns: 1; }
    .hero-text .megamenu-link-list ul li {
      padding: 0; }
    .hero-text .megamenu-link-list ul li a {
      font-family: "Inter", sans-serif;
      text-decoration: none;
      text-transform: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      display: block;
      padding: 8px 0 32px 0; }
      .hero-text .megamenu-link-list ul li a:hover {
        text-decoration: none; }

.hero-slider-container .hero-slot.container {
  padding-left: 0;
  padding-right: 0; }

.only-image-banner .hero-media {
  background-size: contain; }
  @media (min-width: 1440px) {
    .only-image-banner .hero-media {
      width: 100%; } }

.only-image-banner .hero.tall {
  min-height: 530px; }
  @media (min-width: 1024px) {
    .only-image-banner .hero.tall {
      min-height: 855px; } }

@media (max-width: 768.98px) {
  .hero.half-and-half {
    height: auto; }
    .hero.half-and-half .hero-container {
      flex-flow: column; }
    .hero.half-and-half .hero-media {
      transform: translateX(50%);
      top: 0;
      position: relative;
      width: calc(100% + 32px);
      margin: -16px 0 16px -32px; }
    .hero.half-and-half.short {
      min-height: 0; }
      .hero.half-and-half.short .hero-media {
        height: 150px; }
    .hero.half-and-half.medium {
      min-height: 0; }
      .hero.half-and-half.medium .hero-media {
        height: 250px; }
    .hero.half-and-half.tall, .hero.half-and-half.screen-height {
      min-height: 0; }
      .hero.half-and-half.tall .hero-media, .hero.half-and-half.screen-height .hero-media {
        height: 263px; } }

@media (min-width: 769px) {
  .hero.half-and-half .hero-media {
    transform: translateY(-50%); }
    .hero.half-and-half .hero-media.width-1-4 {
      max-width: 75%; }
    .hero.half-and-half .hero-media.width-1-3 {
      max-width: 66.66666%; }
    .hero.half-and-half .hero-media.width-1-2 {
      max-width: 50%; }
    .hero.half-and-half .hero-media.width-2-3 {
      max-width: 33.3333%; }
    .hero.half-and-half .hero-media.width-3-4 {
      max-width: 25%; }
    .hero.half-and-half .hero-media.width-1 {
      max-width: 50%; }
  .hero.half-and-half .hero-text.width-1 {
    max-width: 50%; }
  .hero.half-and-half .justify-content-md-start .hero-media {
    right: 0;
    left: auto; }
  .hero.half-and-half .justify-content-md-start .hero-text {
    padding-right: 16px; } }
  @media (min-width: 769px) and (min-width: 769px) {
    .hero.half-and-half .justify-content-md-start .hero-text {
      padding-right: 32px; } }
  @media (min-width: 769px) and (min-width: 1024px) {
    .hero.half-and-half .justify-content-md-start .hero-text {
      padding-right: 32px; } }

@media (min-width: 769px) {
  .hero.half-and-half .justify-content-md-end .hero-media {
    left: 0;
    right: auto; }
  .hero.half-and-half .justify-content-md-end .hero-text {
    padding-left: 16px; } }
  @media (min-width: 769px) and (min-width: 769px) {
    .hero.half-and-half .justify-content-md-end .hero-text {
      padding-left: 32px; } }
  @media (min-width: 769px) and (min-width: 1024px) {
    .hero.half-and-half .justify-content-md-end .hero-text {
      padding-left: 32px; } }

.experience-component .hero-button .video-container::before,
.experience-component .hero-button .video-container .video-player,
.experience-component .button .video-container::before,
.experience-component .button .video-container .video-player,
.experience-component .image-text-block-button .video-container::before,
.experience-component .image-text-block-button .video-container .video-player {
  display: none; }

.plp .plp-banner,
.plp .plp-banner.mobile-1r-1c.full-bleed {
  margin-top: 0 !important; }
  .plp .plp-banner .hero,
  .plp .plp-banner.mobile-1r-1c.full-bleed .hero {
    background-color: #F9F9F9; }
    @media (min-width: 1024px) {
      .plp .plp-banner .hero .hero-container,
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero .hero-container {
        padding: 16px 32px; } }
    @media screen and (min-width: 1441px) {
      .plp .plp-banner .hero.medium,
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero.medium {
        min-height: 470px; } }
  .plp .plp-banner .hero-text .hero-subtitle,
  .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle {
    font-family: "Space Grotesk", sans-serif;
    font-size: 32px;
    line-height: 36px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: -2px;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .plp .plp-banner .hero-text .hero-subtitle,
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle {
        font-family: "Space Grotesk", sans-serif;
        font-size: 48px;
        line-height: 52px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: -2px;
        font-style: normal;
        font-display: swap; } }
    .plp .plp-banner .hero-text .hero-subtitle h1, .plp .plp-banner .hero-text .hero-subtitle .h1, .plp .plp-banner .hero-text .hero-subtitle h2, .plp .plp-banner .hero-text .hero-subtitle .h2, .plp .plp-banner .hero-text .hero-subtitle h3, .plp .plp-banner .hero-text .hero-subtitle .h3, .plp .plp-banner .hero-text .hero-subtitle h4, .plp .plp-banner .hero-text .hero-subtitle .h4, .plp .plp-banner .hero-text .hero-subtitle h5, .plp .plp-banner .hero-text .hero-subtitle .h5, .plp .plp-banner .hero-text .hero-subtitle h6, .plp .plp-banner .hero-text .hero-subtitle .h6,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h1,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h1,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h2,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h2,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h3,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h3,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h4,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h4,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h5,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h5,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h6,
    .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h6 {
      font-family: "Space Grotesk", sans-serif;
      font-size: 32px;
      line-height: 36px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -2px;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .plp .plp-banner .hero-text .hero-subtitle h1, .plp .plp-banner .hero-text .hero-subtitle .h1, .plp .plp-banner .hero-text .hero-subtitle h2, .plp .plp-banner .hero-text .hero-subtitle .h2, .plp .plp-banner .hero-text .hero-subtitle h3, .plp .plp-banner .hero-text .hero-subtitle .h3, .plp .plp-banner .hero-text .hero-subtitle h4, .plp .plp-banner .hero-text .hero-subtitle .h4, .plp .plp-banner .hero-text .hero-subtitle h5, .plp .plp-banner .hero-text .hero-subtitle .h5, .plp .plp-banner .hero-text .hero-subtitle h6, .plp .plp-banner .hero-text .hero-subtitle .h6,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h1,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h1,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h2,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h2,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h3,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h3,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h4,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h4,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h5,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h5,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle h6,
        .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-subtitle .h6 {
          font-family: "Space Grotesk", sans-serif;
          font-size: 48px;
          line-height: 52px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: -2px;
          font-style: normal;
          font-display: swap; } }
  .plp .plp-banner .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book),
  .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    margin-bottom: 0;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .plp .plp-banner .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book),
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .plp .plp-banner .hero-text .hero-title,
  .plp .plp-banner .hero-text .hero-body,
  .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-title,
  .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-body {
    margin-top: 8px !important; }
    @media (max-width: 1023.98px) {
      .plp .plp-banner .hero-text .hero-title,
      .plp .plp-banner .hero-text .hero-body,
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-title,
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-body {
        max-width: 50%; } }
    @media (max-width: 543.98px) {
      .plp .plp-banner .hero-text .hero-title,
      .plp .plp-banner .hero-text .hero-body,
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-title,
      .plp .plp-banner.mobile-1r-1c.full-bleed .hero-text .hero-body {
        max-width: 65%; } }

.plp .plp-banner .hero {
  border-radius: 20px; }

.plp .plp-banner.mobile-1r-1c.full-bleed .hero {
  border-radius: 0; }

.plp .plp-banner.mobile-1r-1c.full-bleed .hero-container::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  content: " "; }

.quotes-section .hero.quotes-block .hero-text:before {
  width: 68px;
  height: 52px;
  content: '';
  display: block;
  margin-bottom: 24px;
  background-image: url("../../images/quote-img.png");
  background-position: 50%;
  background-size: 100%; }
  @media (max-width: 1023.98px) {
    .quotes-section .hero.quotes-block .hero-text:before {
      width: 40px;
      height: 30px; } }

.quotes-section .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 40px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .quotes-section .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
      font-family: "Inter", sans-serif;
      font-size: 48px;
      line-height: 56px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

@media (max-width: 1023.98px) {
  .quotes-section .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; } }
  @media (max-width: 1023.98px) and (min-width: 1024px) {
    .quotes-section .hero-text .hero-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      line-height: 32px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.quotes-section .hero-text .hero-title p {
  text-transform: uppercase; }

.quotes-section .hero-body:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 40px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .quotes-section .hero-body:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
      font-family: "Inter", sans-serif;
      font-size: 48px;
      line-height: 56px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

@media (max-width: 1023.98px) {
  .quotes-section .hero-body:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; } }
  @media (max-width: 1023.98px) and (min-width: 1024px) {
    .quotes-section .hero-body:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      line-height: 32px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.quotes-section .hero-body p {
  font-weight: normal;
  text-transform: uppercase; }

.quotes-section .hero-container {
  padding: 32px 8px 32px 48px; }
  @media (max-width: 1023.98px) {
    .quotes-section .hero-container {
      padding: 24px 8px 24px 24px; } }

.quotes-section .hero.tall {
  min-height: 344px; }
  @media (min-width: 769px) {
    .quotes-section .hero.tall {
      min-height: 600px; } }
  @media (min-width: 1024px) {
    .quotes-section .hero.tall {
      min-height: 600px; } }

@media (min-width: 1024px) {
  .image-large-text .hero .hero-container {
    padding: 32px; } }

.popsockets-hero-banner {
  position: relative;
  overflow: hidden;
  display: flex;
  max-height: calc(100vh - 104px);
  height: 135.2657vw; }
  @media (min-width: 769px) {
    .popsockets-hero-banner {
      height: 55.55556vw;
      max-height: 764.44444px; }
      .full-bleed .popsockets-hero-banner {
        max-height: 1000px; }
      .transparent-header ~ div .popsockets-hero-banner {
        padding-top: 70px; } }
  @media (min-width: 1024px) {
    .transparent-header ~ div .popsockets-hero-banner {
      padding-top: 136px; } }
  .popsockets-hero-banner .hero-container {
    display: flex;
    padding: 40px 16px;
    height: 100%;
    width: 100%; }
    @media (min-width: 769px) {
      .popsockets-hero-banner .hero-container {
        padding: 80px 32px; } }
    @media (min-width: 1440px) {
      .full-bleed .popsockets-hero-banner .hero-container {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto; } }
    .popsockets-hero-banner .hero-container.text-placement-middle-left {
      justify-content: flex-start;
      align-items: flex-end; }
      @media (min-width: 769px) {
        .popsockets-hero-banner .hero-container.text-placement-middle-left {
          justify-content: flex-start;
          align-items: center; } }
    .popsockets-hero-banner .hero-container.text-placement-bottom-left {
      justify-content: flex-start;
      align-items: flex-end; }
    .popsockets-hero-banner .hero-container.text-placement-middle-center {
      justify-content: center;
      align-items: flex-end;
      text-align: center; }
      @media (min-width: 769px) {
        .popsockets-hero-banner .hero-container.text-placement-middle-center {
          justify-content: center;
          align-items: center; } }
    .popsockets-hero-banner .hero-container.overlay::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      content: " "; }
  .popsockets-hero-banner .hero-background-link {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -999px; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .popsockets-hero-banner {
      height: 0; }
      .popsockets-hero-banner.no-image {
        height: auto; } }
  @media (min-width: 769px) {
    .popsockets-hero-banner .hero-text {
      max-width: 75%; } }
  @media (min-width: 1024px) {
    .popsockets-hero-banner .hero-text {
      max-width: 50%; } }
  .popsockets-hero-banner .hero-text .hero-subtitle {
    margin-top: 8px; }
  .popsockets-hero-banner .hero-text .hero-buttons {
    margin-top: 24px; }
  .popsockets-hero-banner .hero-text .hero-button {
    margin-top: 0;
    display: inline-block;
    position: relative; }
  .popsockets-hero-banner.short {
    min-height: 150px;
    height: auto; }
    @media (min-width: 769px) {
      .popsockets-hero-banner.short {
        min-height: 80px; } }
    @media (min-width: 1024px) {
      .popsockets-hero-banner.short {
        min-height: 80px; } }
  .popsockets-hero-banner.medium {
    min-height: 250px;
    height: auto; }
    @media (min-width: 769px) {
      .popsockets-hero-banner.medium {
        min-height: 300px; } }
    @media (min-width: 1024px) {
      .popsockets-hero-banner.medium {
        min-height: 350px; } }
  .popsockets-hero-banner.tall {
    min-height: 263px;
    height: auto; }
    @media (min-width: 769px) {
      .popsockets-hero-banner.tall {
        min-height: 544px; } }
    @media (min-width: 1024px) {
      .popsockets-hero-banner.tall {
        min-height: 544px; } }
  .popsockets-hero-banner.screen-height {
    min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px);
    height: auto; }
    @media (min-width: 769px) {
      .popsockets-hero-banner.screen-height {
        min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); } }
    @media (min-width: 1024px) {
      .popsockets-hero-banner.screen-height {
        min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); } }
    .transparent-header ~ div .popsockets-hero-banner.screen-height {
      min-height: calc(100vh - 24px - 8px - 8px); }
      @media (min-width: 769px) {
        .transparent-header ~ div .popsockets-hero-banner.screen-height {
          min-height: calc(100vh - 24px - 8px - 8px); } }
      @media (min-width: 1024px) {
        .transparent-header ~ div .popsockets-hero-banner.screen-height {
          min-height: calc(100vh - 24px - 8px - 8px); } }
  @media (max-width: 768.98px) {
    .popsockets-hero-banner.short-mobile {
      min-height: 150px;
      height: auto; } }
  @media (max-width: 768.98px) {
    .popsockets-hero-banner.medium-mobile {
      min-height: 250px;
      height: auto; } }
  @media (max-width: 768.98px) {
    .popsockets-hero-banner.tall-mobile {
      min-height: 263px;
      height: auto; } }
  @media (max-width: 768.98px) {
    .popsockets-hero-banner.screen-height-mobile {
      min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px);
      height: auto; } }
  @media (max-width: 768.98px) {
    .transparent-header ~ div .popsockets-hero-banner.screen-height-mobile {
      min-height: calc(100vh - 24px - 8px - 8px);
      height: auto; } }
  .popsockets-hero-banner.add-text-under {
    min-height: 456px !important; }
    @media (min-width: 769px) {
      .popsockets-hero-banner.add-text-under {
        min-height: 528px !important; } }
  .popsockets-hero-banner.photo-hotspots {
    border-radius: 0; }
  .popsockets-hero-banner.original-image {
    height: auto !important;
    max-height: inherit !important;
    min-height: auto !important; }
    .popsockets-hero-banner.original-image .hero-container {
      padding: 0; }
      @media (min-width: 769px) {
        .popsockets-hero-banner.original-image .hero-container {
          padding: 0; } }
      @media (min-width: 1440px) {
        .full-bleed .popsockets-hero-banner.original-image .hero-container {
          max-width: 100%; } }
    .popsockets-hero-banner.original-image .image-wrapper {
      flex-basis: 100%; }
    .popsockets-hero-banner.original-image .hero-text {
      position: absolute;
      padding: 40px 16px; }
      @media (min-width: 769px) {
        .popsockets-hero-banner.original-image .hero-text {
          padding: 80px 32px; } }
    .popsockets-hero-banner.original-image.add-text-under .hero-text {
      position: relative;
      padding: 0; }
      @media (min-width: 769px) {
        .popsockets-hero-banner.original-image.add-text-under .hero-text {
          padding: 0; } }

.hero-text.text-under {
  margin-top: 16px;
  padding: 0 16px 16px; }
  @media (min-width: 769px) {
    .hero-text.text-under {
      padding: 0; } }
  @media (min-width: 1024px) {
    .hero-text.text-under {
      margin-top: 32px; } }
  .hero-text.text-under .hero-title {
    font-family: "Inter", sans-serif !important;
    margin-bottom: 8px; }
    .hero-text.text-under .hero-title a {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      color: inherit;
      background: inherit;
      margin-bottom: inherit; }
  .hero-text.text-under .hero-subtitle {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    font-size: 20px; }
    @media (min-width: 1024px) {
      .hero-text.text-under .hero-subtitle {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        line-height: 28px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .hero-text.text-under .hero-subtitle a {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      color: inherit;
      background: inherit; }
  .hero-text.text-under .hero-buttons {
    margin-top: 24px; }
  .hero-text.text-under .hero-button {
    margin-top: 0;
    display: inline-block;
    position: relative; }

.image-text-block {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none; }
  .image-text-block.rounded-corners {
    overflow: hidden; }
    .image-text-block.rounded-corners .image-cropper {
      z-index: 2; }
  .image-text-block:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .image-text-block.zoom .image-wrapper {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .image-text-block.zoom:hover .image-wrapper {
      transform: scale(1.2); } }
  .image-text-block .image-cropper {
    border-radius: 20px;
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative; }
    .image-text-block .image-cropper.overlay::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      content: " "; }
  .image-text-block .image-wrapper {
    width: 100%;
    position: relative; }
    .image-text-block .image-wrapper .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block; }
    .image-text-block .image-wrapper .background-image-tablet,
    .image-text-block .image-wrapper .background-image-desktop {
      display: none !important; }
    @media (min-width: 769px) {
      .image-text-block .image-wrapper .background-image-mobile,
      .image-text-block .image-wrapper .background-image-desktop {
        display: none !important; }
      .image-text-block .image-wrapper .background-image-tablet {
        display: block !important; } }
    @media (min-width: 1024px) {
      .image-text-block .image-wrapper .background-image-mobile,
      .image-text-block .image-wrapper .background-image-tablet {
        display: none !important; }
      .image-text-block .image-wrapper .background-image-desktop {
        display: block !important; } }
    .image-text-block .image-wrapper .video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: calc(9 / 16 * 100%);
      padding-left: calc(16 / 9 * 100vh);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .image-text-block .image-wrapper .video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .image-text-block .image-wrapper .video-container .video-aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .image-text-block .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
          position: absolute; }
  .image-text-block.large .image-text-block-text .secondary-text + .image-text-block-button, .image-text-block.medium .image-text-block-text .secondary-text + .image-text-block-button {
    padding-top: 16px; }
    @media (min-width: 769px) {
      .image-text-block.large .image-text-block-text .secondary-text + .image-text-block-button, .image-text-block.medium .image-text-block-text .secondary-text + .image-text-block-button {
        padding-top: 24px; } }
  .image-text-block .image-text-block-text .primary-text > *,
  .image-text-block .image-text-block-text .secondary-text > *,
  .image-text-block .image-text-block-text .image-text-block-subtitle > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .image-text-block .image-text-block-text .primary-text a,
  .image-text-block .image-text-block-text .secondary-text a,
  .image-text-block .image-text-block-text .image-text-block-subtitle a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: inherit;
    background: inherit; }
  .image-text-block .image-text-block-text .image-text-block-subtitle:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
    font-size: 16px; }
  .image-text-block .image-text-block-text .primary-text {
    margin: 0;
    padding-bottom: 8px; }
  .image-text-block .image-text-block-text .secondary-text:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .image-text-block .image-text-block-text .secondary-text:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        line-height: 28px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .image-text-block .image-text-block-text .image-text-block-subtitle + .primary-text {
    padding-top: 0; }
  .image-text-block .image-text-block-text .secondary-text + .image-text-block-button {
    padding-top: 16px; }
  .image-text-block .image-text-block-text .image-text-block-button {
    text-shadow: none; }
  .image-text-block.text-placement-below .image-text-block-text {
    margin-top: 16px; }
  .image-text-block.text-placement-inside .image-text-block-text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: #FFFFFF;
    z-index: 2;
    text-shadow: none;
    top: initial;
    right: initial;
    bottom: 16px;
    transform: none;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    @media (min-width: 769px) {
      .image-text-block.text-placement-inside .image-text-block-text {
        bottom: 24px;
        padding-left: 24px;
        padding-right: 24px; } }
  .image-text-block.text-placement-inside.large .image-text-block-text {
    bottom: 16px;
    padding-left: 16px;
    padding-right: 16px; }
    @media (min-width: 769px) {
      .image-text-block.text-placement-inside.large .image-text-block-text {
        bottom: 64px;
        padding-left: 64px;
        padding-right: 64px; } }
  .image-text-block.aspect-ratio-square .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-square .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .image-text-block.aspect-ratio-landscape .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-landscape .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .image-text-block.aspect-ratio-portrait .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-portrait .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .image-text-block.aspect-ratio-portrait.rounded-corners .image-wrapper .video-container .video-aspect-ratio iframe {
    height: 115%; }
  @media (min-width: 769px) {
    .image-text-block.aspect-ratio-portrait.rounded-corners.product-tile .image-wrapper .video-container .video-aspect-ratio iframe {
      height: 125%; } }
  .mobile-4r-1c .image-text-block.aspect-ratio-portrait.rounded-corners .image-wrapper .video-container .video-aspect-ratio iframe,
  .mobile-3r-1c .image-text-block.aspect-ratio-portrait.rounded-corners .image-wrapper .video-container .video-aspect-ratio iframe {
    height: 135%; }

.photo-tile-container.plant-section-img {
  max-width: 100%; }
  .photo-tile-container.plant-section-img img {
    height: auto; }
  @media (min-width: 769px) {
    .photo-tile-container.plant-section-img {
      max-width: 346px; }
      .photo-tile-container.plant-section-img img {
        height: 264px; } }

.photo-tile-container.reducing-waste-img {
  max-width: 100%; }
  .photo-tile-container.reducing-waste-img img {
    height: auto; }
  @media (min-width: 769px) {
    .photo-tile-container.reducing-waste-img {
      max-width: 372px;
      margin: 0 auto; }
      .photo-tile-container.reducing-waste-img img {
        height: 336px; } }

.photo-tile-container.recycling-services {
  max-width: 72px;
  margin: 0 auto; }
  .photo-tile-container.recycling-services img {
    height: 80px; }
  @media (min-width: 769px) {
    .photo-tile-container.recycling-services {
      max-width: 128.96px; }
      .photo-tile-container.recycling-services img {
        height: 128px; } }

@media (min-width: 769px) {
  .col-md-3 .image-text-block.text-placement-inside .image-text-block-text {
    bottom: 32px; } }

.pdp-assets .image-text-block .image-text-block-text .secondary-text:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .pdp-assets .image-text-block .image-text-block-text .secondary-text:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book):not(.link):not(.price) {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.icon-with-text-layout.text-placement-below .image-text-block-text {
  margin-top: 0; }
  .icon-with-text-layout.text-placement-below .image-text-block-text .image-text-block-subtitle {
    margin-bottom: 8px; }
  .icon-with-text-layout.text-placement-below .image-text-block-text br {
    display: none; }

.icon-with-text-layout .image-cropper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 0; }
  .icon-with-text-layout .image-cropper .image-wrapper {
    width: 178px;
    height: 140px; }
    .icon-with-text-layout .image-cropper .image-wrapper .background-image {
      background-size: contain !important; }

.review-tile-wrapper .image-cropper {
  display: flex;
  position: relative;
  text-align: center;
  text-decoration: none;
  padding: 60px 8px 74px;
  border-radius: 20px;
  background-color: #F9F9F9;
  margin-bottom: 8px;
  min-height: 368px; }
  @media (min-width: 1024px) {
    .review-tile-wrapper .image-cropper {
      padding: 72px 16px 55px;
      margin-bottom: 16px;
      min-height: inherit;
      max-height: inherit;
      height: auto; } }
  .review-tile-wrapper .image-cropper .image-wrapper {
    height: 234px;
    width: 230px;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .review-tile-wrapper .image-cropper .image-wrapper {
        height: 288px;
        width: 280px; } }

.review-tile-wrapper .image-text-block-button {
  padding-top: 4px !important; }
  .review-tile-wrapper .image-text-block-button .btn-tertiary {
    text-align: left; }

@media (max-width: 543.98px) {
  .collapsible-xs {
    border-color: #F9F9F9; }
    .collapsible-xs .card-header {
      padding: 0;
      border-color: #F9F9F9; }
    .collapsible-xs .title {
      background-color: #FFFFFF;
      color: #181818;
      padding-top: 32px;
      padding-right: 33px;
      padding-bottom: 32px;
      padding-left: 16px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-xs .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #222222;
        font-weight: normal; }
      .collapsible-xs .title[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-xs .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-xs .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xs .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .collapsible-xs .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xs .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-xs .title[class*="btn-"]:not(.title).btn-lg, .collapsible-xs .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 63px; }
          .collapsible-xs .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-xs .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-xs .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-xs .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-xs .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-xs .title::after {
        position: absolute;
        top: 32px;
        right: 16px; }
    .collapsible-xs .content,
    .collapsible-xs .card-body {
      display: none;
      background-color: #FFFFFF;
      color: #181818; }
    .collapsible-xs.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .collapsible-xs.active .title[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .collapsible-xs.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-xs.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xs.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-xs.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xs.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xs.active .title[class*="btn-"]:not(.title).btn-lg, .collapsible-xs.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .collapsible-xs.active .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-xs.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xs.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-xs.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-xs.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-xs.active .content,
    .collapsible-xs.active .card-body {
      display: block; }
    .collapsible-xs:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-xs:not(:last-child) + .collapsible-xs {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-xs:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #F9F9F9; }
    .collapsible-xs.aside .title::after, .collapsible-xs.no-borders .title::after {
      right: 0; }
    .collapsible-xs.aside .title::after {
      top: 16px; }
    .collapsible-xs.no-borders .title::after {
      top: 32px; } }

@media (max-width: 768.98px) {
  .collapsible-sm {
    border-color: #F9F9F9; }
    .collapsible-sm .card-header {
      padding: 0;
      border-color: #F9F9F9; }
    .collapsible-sm .title {
      background-color: #FFFFFF;
      color: #181818;
      padding-top: 32px;
      padding-right: 33px;
      padding-bottom: 32px;
      padding-left: 16px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-sm .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #222222;
        font-weight: normal; }
      .collapsible-sm .title[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-sm .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-sm .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .collapsible-sm .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-sm .title[class*="btn-"]:not(.title).btn-lg, .collapsible-sm .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 63px; }
          .collapsible-sm .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-sm .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-sm .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-sm .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-sm .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-sm .title::after {
        position: absolute;
        top: 32px;
        right: 16px; }
    .collapsible-sm .content,
    .collapsible-sm .card-body {
      display: none;
      background-color: #FFFFFF;
      color: #181818; }
    .collapsible-sm.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .collapsible-sm.active .title[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .collapsible-sm.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-sm.active .title[class*="btn-"]:not(.title).btn-lg, .collapsible-sm.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .collapsible-sm.active .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-sm.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-sm.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-sm.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-sm.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-sm.active .content,
    .collapsible-sm.active .card-body {
      display: block; }
    .collapsible-sm:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-sm:not(:last-child) + .collapsible-sm {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-sm:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #F9F9F9; }
    .collapsible-sm.aside .title::after, .collapsible-sm.no-borders .title::after {
      right: 0; }
    .collapsible-sm.aside .title::after {
      top: 16px; }
    .collapsible-sm.no-borders .title::after {
      top: 32px; } }

@media (max-width: 1023.98px) {
  .collapsible-md {
    border-color: #F9F9F9; }
    .collapsible-md .card-header {
      padding: 0;
      border-color: #F9F9F9; }
    .collapsible-md .title {
      background-color: #FFFFFF;
      color: #181818;
      padding-top: 32px;
      padding-right: 33px;
      padding-bottom: 32px;
      padding-left: 16px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-md .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #222222;
        font-weight: normal; }
      .collapsible-md .title[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-md .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-md .title[class*="btn-"]:not(.title).btn-sm, .collapsible-md .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .collapsible-md .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-md .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-md .title[class*="btn-"]:not(.title).btn-lg, .collapsible-md .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 63px; }
          .collapsible-md .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-md .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-md .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-md .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-md .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-md .title::after {
        position: absolute;
        top: 32px;
        right: 16px; }
    .collapsible-md .content,
    .collapsible-md .card-body {
      display: none;
      background-color: #FFFFFF;
      color: #181818; }
    .collapsible-md.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .collapsible-md.active .title[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .collapsible-md.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-md.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-md.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-md.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-md.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-md.active .title[class*="btn-"]:not(.title).btn-lg, .collapsible-md.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .collapsible-md.active .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-md.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-md.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-md.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-md.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-md.active .content,
    .collapsible-md.active .card-body {
      display: block; }
    .collapsible-md:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-md:not(:last-child) + .collapsible-md {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-md:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #F9F9F9; }
    .collapsible-md.aside .title::after, .collapsible-md.no-borders .title::after {
      right: 0; }
    .collapsible-md.aside .title::after {
      top: 16px; }
    .collapsible-md.no-borders .title::after {
      top: 32px; } }

@media (max-width: 1199.98px) {
  .collapsible-lg {
    border-color: #F9F9F9; }
    .collapsible-lg .card-header {
      padding: 0;
      border-color: #F9F9F9; }
    .collapsible-lg .title {
      background-color: #FFFFFF;
      color: #181818;
      padding-top: 32px;
      padding-right: 33px;
      padding-bottom: 32px;
      padding-left: 16px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-lg .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #222222;
        font-weight: normal; }
      .collapsible-lg .title[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-lg .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-lg .title[class*="btn-"]:not(.title).btn-sm, .collapsible-lg .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .collapsible-lg .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-lg .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-lg .title[class*="btn-"]:not(.title).btn-lg, .collapsible-lg .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 63px; }
          .collapsible-lg .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-lg .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-lg .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-lg .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-lg .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-lg .title::after {
        position: absolute;
        top: 32px;
        right: 16px; }
    .collapsible-lg .content,
    .collapsible-lg .card-body {
      display: none;
      background-color: #FFFFFF;
      color: #181818; }
    .collapsible-lg.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .collapsible-lg.active .title[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .collapsible-lg.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-lg.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-lg.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-lg.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-lg.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-lg.active .title[class*="btn-"]:not(.title).btn-lg, .collapsible-lg.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .collapsible-lg.active .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-lg.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-lg.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-lg.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-lg.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-lg.active .content,
    .collapsible-lg.active .card-body {
      display: block; }
    .collapsible-lg:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-lg:not(:last-child) + .collapsible-lg {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-lg:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #F9F9F9; }
    .collapsible-lg.aside .title::after, .collapsible-lg.no-borders .title::after {
      right: 0; }
    .collapsible-lg.aside .title::after {
      top: 16px; }
    .collapsible-lg.no-borders .title::after {
      top: 32px; } }

@media (max-width: 1439.98px) {
  .collapsible-sd {
    border-color: #F9F9F9; }
    .collapsible-sd .card-header {
      padding: 0;
      border-color: #F9F9F9; }
    .collapsible-sd .title {
      background-color: #FFFFFF;
      color: #181818;
      padding-top: 32px;
      padding-right: 33px;
      padding-bottom: 32px;
      padding-left: 16px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-sd .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #222222;
        font-weight: normal; }
      .collapsible-sd .title[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-sd .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-sd .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sd .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .collapsible-sd .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sd .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-sd .title[class*="btn-"]:not(.title).btn-lg, .collapsible-sd .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 63px; }
          .collapsible-sd .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-sd .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #222222;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-sd .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-sd .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-sd .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-sd .title::after {
        position: absolute;
        top: 32px;
        right: 16px; }
    .collapsible-sd .content,
    .collapsible-sd .card-body {
      display: none;
      background-color: #FFFFFF;
      color: #181818; }
    .collapsible-sd.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .collapsible-sd.active .title[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .collapsible-sd.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-sd.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sd.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-sd.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sd.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-sd.active .title[class*="btn-"]:not(.title).btn-lg, .collapsible-sd.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .collapsible-sd.active .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-sd.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-sd.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-sd.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-sd.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-sd.active .content,
    .collapsible-sd.active .card-body {
      display: block; }
    .collapsible-sd:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-sd:not(:last-child) + .collapsible-sd {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-sd:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #F9F9F9; }
    .collapsible-sd.aside .title::after, .collapsible-sd.no-borders .title::after {
      right: 0; }
    .collapsible-sd.aside .title::after {
      top: 16px; }
    .collapsible-sd.no-borders .title::after {
      top: 32px; } }

.collapsible-xl {
  border-color: #F9F9F9; }
  .collapsible-xl .card-header {
    padding: 0;
    border-color: #F9F9F9; }
  .collapsible-xl .title {
    background-color: #FFFFFF;
    color: #181818;
    padding-top: 32px;
    padding-right: 33px;
    padding-bottom: 32px;
    padding-left: 16px;
    user-select: none;
    border-width: 0;
    cursor: pointer; }
    .collapsible-xl .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .collapsible-xl .title[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .collapsible-xl .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-xl .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xl .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .collapsible-xl .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xl .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xl .title[class*="btn-"]:not(.title).btn-lg, .collapsible-xl .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .collapsible-xl .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-xl .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xl .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-xl .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-xl .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-xl .title::after {
      position: absolute;
      top: 32px;
      right: 16px; }
  .collapsible-xl .content,
  .collapsible-xl .card-body {
    display: none;
    background-color: #FFFFFF;
    color: #181818; }
  .collapsible-xl.active .title:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 12px;
    color: #222222;
    font-weight: normal; }
  .collapsible-xl.active .title[class*="btn-"]:not(.title) {
    padding-right: 42px; }
    .collapsible-xl.active .title[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #222222;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .collapsible-xl.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xl.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .collapsible-xl.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xl.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .collapsible-xl.active .title[class*="btn-"]:not(.title).btn-lg, .collapsible-xl.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title) {
      padding-right: 63px; }
      .collapsible-xl.active .title[class*="btn-"]:not(.title).btn-lg:after, .collapsible-xl.active .btn-group-lg > .title.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .collapsible-xl.active .title[class*="btn-"]:not(.title) [class*="icon-"],
    .collapsible-xl.active .title[class*="btn-"]:not(.title) [class^="icon-"],
    .collapsible-xl.active .title[class*="btn-"]:not(.title) .fa {
      display: none; }
  .collapsible-xl.active .content,
  .collapsible-xl.active .card-body {
    display: block; }
  .collapsible-xl:not(:last-child) {
    margin-bottom: 0;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .collapsible-xl:not(:last-child) + .collapsible-xl {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  .collapsible-xl:not(.active):not(.aside):not(.no-borders) .card-header {
    border-bottom: 1px solid #F9F9F9; }
  .collapsible-xl.aside .title::after, .collapsible-xl.no-borders .title::after {
    right: 0; }
  .collapsible-xl.aside .title::after {
    top: 16px; }
  .collapsible-xl.no-borders .title::after {
    top: 32px; }

footer {
  background-color: #FFFFFF;
  border-top: 0px solid #FFFFFF;
  padding-bottom: 60px;
  padding-top: 72px;
  margin-top: 100px; }
  footer ul {
    list-style: none;
    padding-left: 0;
    padding-top: 12px; }
  footer .title {
    position: relative;
    color: #181818;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    font-family: "Space Grotesk", sans-serif;
    text-transform: none; }
  footer .content {
    font-size: 16px; }
    footer .content li {
      height: auto; }
      footer .content li a {
        text-decoration: none; }
  footer .store .btn {
    margin-bottom: 10px;
    margin-top: 20px; }
  footer .store .content {
    display: none; }
  @media (min-width: 769px) {
    footer .store {
      border: none; }
      footer .store .btn {
        margin-top: 5px; }
      footer .store .content {
        display: block; } }
  footer .store .social::after {
    display: block;
    content: "";
    clear: both; }
  footer .back-to-top {
    margin: 5px;
    padding: 10px 0 0 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
    footer .back-to-top .fa-stack {
      width: 25px;
      height: 25px;
      line-height: 25px; }
    footer .back-to-top .fa-arrow-up {
      width: 100%;
      height: 100%; }
      footer .back-to-top .fa-arrow-up:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal; }
      footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm, footer .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm:before, footer .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-lg, footer .back-to-top .btn-group-lg > .fa-arrow-up.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-lg:before, footer .back-to-top .btn-group-lg > .fa-arrow-up.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class*="icon-"],
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class^="icon-"],
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) .fa {
          display: none; }
    footer .back-to-top .fa-circle {
      background-color: #181818;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      border-radius: 50%; }
  footer .copyright,
  footer .postscript {
    color: #595959;
    font-size: 12px; }
    footer .copyright a,
    footer .postscript a {
      color: #595959;
      text-decoration: none; }
  footer hr {
    border-color: transparent;
    margin: 72px 0 10px 0; }
  footer a, footer .text-link {
    color: #181818; }

.footer-item.collapsible-sm {
  padding-left: 0;
  padding-right: 0; }
  .footer-item.collapsible-sm ~ .collapsible-sm {
    margin-top: 0; }
  .footer-item.collapsible-sm .content {
    margin-bottom: 0; }
  .footer-item.collapsible-sm .title {
    border-bottom: 1px solid #F9F9F9;
    padding: 20px 40px 20px 0; }
    .footer-item.collapsible-sm .title::after {
      color: #181818;
      font-size: 16px;
      right: 0; }
  @media (min-width: 769px) {
    .footer-item.collapsible-sm .title {
      border-bottom: 0;
      padding: 0; } }

@media (min-width: 769px) {
  .footer-item.collapsible-sm {
    border: none;
    padding-left: 16px;
    padding-right: 16px; }
    .footer-item.collapsible-sm .content {
      padding-left: 0;
      padding-right: 0; } }

.email-signup-form {
  margin-top: 5px; }
  .email-signup-form .email-description {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em; }
  .email-signup-form .btn:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 16px;
    color: #181818;
    font-weight: normal; }
  .email-signup-form .btn[class*="btn-"]:not(.title) {
    padding-right: 42px; }
    .email-signup-form .btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .email-signup-form .btn[class*="btn-"]:not(.title).btn-sm, .email-signup-form .btn-group-sm > .btn[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .email-signup-form .btn[class*="btn-"]:not(.title).btn-sm:after, .email-signup-form .btn-group-sm > .btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .email-signup-form .btn[class*="btn-"]:not(.title).btn-lg, .email-signup-form .btn-group-lg > .btn[class*="btn-"]:not(.title) {
      padding-right: 63px; }
      .email-signup-form .btn[class*="btn-"]:not(.title).btn-lg:after, .email-signup-form .btn-group-lg > .btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .email-signup-form .btn[class*="btn-"]:not(.title) [class*="icon-"],
    .email-signup-form .btn[class*="btn-"]:not(.title) [class^="icon-"],
    .email-signup-form .btn[class*="btn-"]:not(.title) .fa {
      display: none; }
  .email-signup-form .btn::after {
    transform: rotate(90deg); }

footer {
  padding-top: 0;
  margin-top: 64px; }
  footer .content li {
    padding-bottom: 16px; }
  footer ul {
    padding-top: 16px; }
  footer .title {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      footer .title {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    @media (min-width: 769px) {
      footer .title {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        line-height: 28px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  @media (min-width: 769px) and (min-width: 1024px) {
    footer .title {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  @media (min-width: 769px) {
    footer .footer-container .col-md-2 {
      flex: 0 0 19.4444367%;
      max-width: 19.4444367%; } }
  footer .email-signup-form {
    text-align: center; }
    footer .email-signup-form h4 {
      margin-bottom: 16px; }
    footer .email-signup-form .email-disclaimer {
      margin-top: 8px; }
      footer .email-signup-form .email-disclaimer p {
        margin-bottom: 0; }
    footer .email-signup-form .btn:after {
      width: auto;
      height: auto;
      position: relative;
      top: initial;
      left: initial;
      border-radius: 0;
      z-index: initial;
      transition: none; }
    @media (min-width: 769px) {
      footer .email-signup-form {
        text-align: left; } }
    footer .email-signup-form .nav-tabs {
      padding-bottom: 26px; }
    footer .email-signup-form .nav-tabs .nav-item:first-child .nav-link {
      border: none;
      border-radius: 4px 0 0 4px; }
    footer .email-signup-form .nav-tabs .nav-item:last-child .nav-link {
      border: none;
      border-radius: 0 4px 4px 0; }
    footer .email-signup-form .stay-in-the-know-text::after {
      content: '🔥';
      font-size: 24px;
      padding-left: 5px; }
    footer .email-signup-form .input-field-text-group button:focus-visible,
    footer .email-signup-form .input-field-email-group button:focus-visible {
      outline-width: 0; }
    footer .email-signup-form .stay-in-the-know-footer-tabs .nav-link {
      background-color: #F9F9F9; }
      footer .email-signup-form .stay-in-the-know-footer-tabs .nav-link.active {
        background-color: #2867EC;
        color: #FFFFFF; }
  footer .social {
    text-align: center; }
    footer .social .social-links {
      padding-top: 16px; }
    @media (min-width: 769px) {
      footer .social {
        text-align: left; } }
  footer .footer-apps {
    text-align: left;
    margin-top: 48px;
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    @media (min-width: 769px) {
      footer .footer-apps {
        margin-top: 64px;
        margin-bottom: 64px;
        padding: 32px;
        text-align: center;
        justify-content: center;
        background-color: #F9F9F9;
        border-radius: 6px; } }
    footer .footer-apps .we-have-an-app {
      font-family: "Inter", sans-serif;
      margin: 0 8px 0 0;
      width: 100%;
      flex-shrink: 0;
      text-transform: none;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px; }
      @media (min-width: 769px) {
        footer .footer-apps .we-have-an-app {
          margin: 0 8px;
          width: auto; } }
    footer .footer-apps .create-and-share {
      font-size: 16px;
      line-height: 24px; }
      @media (min-width: 769px) {
        footer .footer-apps .create-and-share {
          order: 1;
          flex-shrink: 0;
          width: 100%;
          margin-top: 8px; } }
    footer .footer-apps .footer-download-buttons {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      font-size: 0; }
      @media (min-width: 544px) {
        footer .footer-apps .footer-download-buttons {
          flex-wrap: nowrap; } }
      @media (min-width: 769px) {
        footer .footer-apps .footer-download-buttons {
          display: inline;
          width: auto;
          font-size: "Inter", sans-serif; } }
      footer .footer-apps .footer-download-buttons a {
        width: auto;
        margin: 16px 16px 0 0; }
        footer .footer-apps .footer-download-buttons a:nth-child(2) {
          display: none; }
        @media (min-width: 769px) {
          footer .footer-apps .footer-download-buttons a {
            margin: 0 8px;
            background: transparent;
            color: #181818;
            border-radius: 0;
            border: none;
            padding: 0;
            width: auto;
            text-decoration: underline;
            font-size: 14px; }
            footer .footer-apps .footer-download-buttons a img {
              display: none; } }
  footer .copyright,
  footer .postscript {
    font-size: 12px;
    line-height: 16px; }

.back-to-top-btn {
  position: fixed;
  right: 16px;
  bottom: 45px;
  background-color: #181818;
  border-radius: 50%;
  border-color: #181818;
  cursor: pointer;
  z-index: 100;
  height: 48px;
  width: 48px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFFFFF; }
  @media (min-width: 1024px) {
    .back-to-top-btn {
      height: 72px;
      width: 72px;
      right: 45px;
      bottom: 90px; } }
  .back-to-top-btn:focus {
    outline: 0; }
  .back-to-top-btn svg {
    width: 20px; }
    @media (min-width: 1024px) {
      .back-to-top-btn svg {
        width: 30px; } }

.loading-skeleton .loading-part {
  height: 400px;
  background-color: #D9D9D9;
  overflow: hidden;
  text-indent: -99999px;
  border-radius: 20px;
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #D9D9D9;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite; }
  @media (min-width: 769px) {
    .loading-skeleton .loading-part {
      height: 500px; } }

.footer-item.collapsible-sm .title::after {
  top: 20px; }

@keyframes loading {
  to {
    background-position-x: -20%; } }

.rally-loader .rally-spinner {
  border: 3px solid #181818;
  border-top-color: #FF9244;
  margin-top: 3px; }

.find-us-wrapper {
  background: linear-gradient(to bottom, #4586FF 0%, #4586FF 34%, #F9F9F9 34%, #F9F9F9 100%);
  padding: 32px 0 16px;
  margin-top: 64px; }
  @media (min-width: 769px) {
    .find-us-wrapper {
      background: linear-gradient(to right, #4586FF 0%, #4586FF 50%, #F9F9F9 50%, #F9F9F9 100%);
      padding: 32px 0; } }
  .find-us-wrapper .region {
    text-align: center; }
    @media (min-width: 1024px) {
      .find-us-wrapper .region {
        display: flex;
        align-items: center;
        flex-wrap: wrap; } }
    .find-us-wrapper .region:first-child {
      flex-wrap: nowrap; }
      .find-us-wrapper .region:first-child .experience-commerce_assets-html {
        flex: 0 0 auto; }
        .find-us-wrapper .region:first-child .experience-commerce_assets-html .download-text {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap;
          font-family: "Inter", sans-serif !important;
          color: #FFFFFF; }
          @media (min-width: 1024px) {
            .find-us-wrapper .region:first-child .experience-commerce_assets-html .download-text {
              font-family: "Inter", sans-serif;
              font-size: 14px;
              line-height: 20px;
              text-transform: none;
              font-weight: bold;
              letter-spacing: 0;
              font-style: normal;
              font-display: swap; } }
          .find-us-wrapper .region:first-child .experience-commerce_assets-html .download-text br {
            display: none; }
          @media (min-width: 1024px) {
            .find-us-wrapper .region:first-child .experience-commerce_assets-html .download-text {
              font-family: "Space Grotesk", sans-serif;
              font-size: 24px;
              line-height: 26px;
              text-transform: none;
              font-weight: bold;
              letter-spacing: -1px;
              font-style: normal;
              font-display: swap; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .find-us-wrapper .region:first-child .experience-commerce_assets-html .download-text {
      font-family: "Space Grotesk", sans-serif;
      font-size: 32px;
      line-height: 36px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }
          @media (min-width: 1024px) {
              .find-us-wrapper .region:first-child .experience-commerce_assets-html .download-text br {
                display: block; } }
          .find-us-wrapper .region:first-child .experience-commerce_assets-html .download-text a {
            font-family: "Inter", sans-serif !important;
            color: #FFFFFF;
            text-decoration: none;
            font-weight: inherit; }
        .find-us-wrapper .region:first-child .experience-commerce_assets-html .loyalist-text {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          line-height: 24px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap;
          color: #FFFFFF;
          margin-bottom: 0; }
          @media (min-width: 1024px) {
            .find-us-wrapper .region:first-child .experience-commerce_assets-html .loyalist-text {
              font-family: "Inter", sans-serif;
              font-size: 16px;
              line-height: 24px;
              text-transform: none;
              font-weight: normal;
              letter-spacing: 0;
              font-style: normal;
              font-display: swap; } }
      .find-us-wrapper .region:first-child .experience-commerce_assets-photoTile {
        flex: 0 0 auto;
        margin-bottom: 0; }
        .find-us-wrapper .region:first-child .experience-commerce_assets-photoTile .find-us-popsockets {
          height: 47px;
          width: 47px;
          margin-bottom: 14px; }
          @media (min-width: 1024px) {
            .find-us-wrapper .region:first-child .experience-commerce_assets-photoTile .find-us-popsockets {
              height: 77px;
              width: 77px;
              margin-right: 50px;
              margin-bottom: 0; } }
        .find-us-wrapper .region:first-child .experience-commerce_assets-photoTile .app-store {
          width: 160px;
          height: auto; }
          @media (min-width: 1024px) {
            .find-us-wrapper .region:first-child .experience-commerce_assets-photoTile .app-store {
              margin-left: 32px; } }
    @media (max-width: 1199.98px) {
      .find-us-wrapper .region:last-child {
        padding: 32px 0 2px;
        margin-top: 0 !important; } }
    @media (max-width: 1199.98px) {
      .find-us-wrapper .region:last-child .experience-commerce_assets-photoTile {
        margin-bottom: 40px; } }
    @media (max-width: 1199.98px) {
      .find-us-wrapper .region:last-child .experience-commerce_assets-photoTile:last-child {
        margin-bottom: 0; } }
    .find-us-wrapper .region .experience-commerce_assets-html {
      flex: 0 0 100%; }
      .find-us-wrapper .region .experience-commerce_assets-html .find-us-heaidng {
        font-family: "Space Grotesk", sans-serif;
        font-size: 24px;
        line-height: 26px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: -1px;
        font-style: normal;
        font-display: swap;
        font-family: "Inter", sans-serif; }
        @media (min-width: 1024px) {
          .find-us-wrapper .region .experience-commerce_assets-html .find-us-heaidng {
            font-family: "Space Grotesk", sans-serif;
            font-size: 32px;
            line-height: 36px;
            text-transform: none;
            font-weight: bold;
            letter-spacing: -1px;
            font-style: normal;
            font-display: swap; } }
        @media (min-width: 1024px) {
          .find-us-wrapper .region .experience-commerce_assets-html .find-us-heaidng {
            font-family: "Inter", sans-serif; } }
    .find-us-wrapper .region .experience-commerce_assets-photoTile {
      flex: 0 0 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto !important; }
      .find-us-wrapper .region .experience-commerce_assets-photoTile .find-us-target {
        width: 134px;
        height: 75px; }
        @media (min-width: 1024px) {
          .find-us-wrapper .region .experience-commerce_assets-photoTile .find-us-target {
            width: 104px; } }
        .find-us-wrapper .region .experience-commerce_assets-photoTile .find-us-target img {
          height: 75px !important;
          width: auto !important; }
      .find-us-wrapper .region .experience-commerce_assets-photoTile .find-us-walmart {
        width: 210px;
        height: 75px; }
        @media (min-width: 1024px) {
          .find-us-wrapper .region .experience-commerce_assets-photoTile .find-us-walmart {
            width: 155px;
            height: 45px; } }
      .find-us-wrapper .region .experience-commerce_assets-photoTile .find-best-buy {
        width: 180px;
        height: 60px; }
        @media (min-width: 1024px) {
          .find-us-wrapper .region .experience-commerce_assets-photoTile .find-best-buy {
            width: 155px;
            height: 45px; } }
      .find-us-wrapper .region .experience-commerce_assets-photoTile .find-us-amazon {
        width: 134px;
        height: 40px; }
        @media (min-width: 1024px) {
          .find-us-wrapper .region .experience-commerce_assets-photoTile .find-us-amazon {
            width: 104px;
            height: 20px; } }

.row .form-group {
  margin-top: calc(16px - 4px); }

.form-group {
  margin: 0; }
  .form-group ~ .form-group,
  .form-group ~ .btn-block {
    margin-top: 16px; }
  .form-group.required .form-control-label::before {
    content: "* ";
    color: #a80b1d; }
  .form-group label {
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
    text-transform: none;
    letter-spacing: 0;
    color: #181818; }
  .form-group .form-text,
  .form-group .invalid-feedback {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin-top: 4px;
    display: block; }
  .form-group .form-text {
    color: #595959; }
  .form-group .invalid-feedback {
    color: #a80b1d;
    display: none; }
  .form-group .was-validated .form-control:invalid {
    display: block; }

.form-control, select.form-control {
  outline: none;
  border: 1px solid #D4D4D4;
  box-shadow: none;
  color: #595959;
  background-color: #FFFFFF;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
  transition: border-color 200ms ease-out, box-shadow 200ms ease-out;
  padding: 16px 16px 14px 16px;
  height: calc(16px + 16px + 14px + (1px*2));
  background-clip: border-box; }
  @media (min-width: 769px) {
    .form-control, select.form-control {
      font-size: 16px; } }
  .form-control:hover, select.form-control:hover {
    border-color: #D4D4D4;
    box-shadow: 0 0 0 1px #D4D4D4; }
  .form-control:focus,
  .form-control.focus .form-control:focus:hover, select.form-control:focus,
  select.form-control.focus .form-control:focus:hover,
  .form-control.focus select.form-control:focus:hover,
  select.form-control.focus select.form-control:focus:hover {
    outline: none;
    border-color: #D4D4D4;
    box-shadow: none; }
  .form-control:disabled, .form-control.disabled, select.form-control:disabled, select.form-control.disabled {
    pointer-events: none;
    background-color: #FFFFFF; }
  .form-control.form-control-sm, select.form-control.form-control-sm {
    font-size: 11px;
    line-height: 14px;
    padding: 8px 10px 6px 10px;
    height: calc(14px + 8px + 6px + (1px*2)); }
  .form-control.is-invalid, select.form-control.is-invalid {
    background-image: none;
    border-color: #a80b1d;
    padding-right: 16px; }
    .form-control.is-invalid:focus, select.form-control.is-invalid:focus {
      border-color: #a80b1d;
      box-shadow: none; }
  .form-control::placeholder, select.form-control::placeholder {
    color: #595959; }

.custom-control {
  min-height: 0; }

.custom-radio,
.custom-checkbox {
  user-select: none;
  padding-left: calc(24px + 10px); }
  .custom-radio .custom-control-label,
  .custom-checkbox .custom-control-label {
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    transition: box-shadow 200ms ease-out; }
    .custom-radio .custom-control-label:hover:before,
    .custom-checkbox .custom-control-label:hover:before {
      box-shadow: 0 0 0 1px #D4D4D4; }
    .custom-radio .custom-control-label:before, .custom-radio .custom-control-label:after,
    .custom-checkbox .custom-control-label:before,
    .custom-checkbox .custom-control-label:after {
      top: 0;
      left: calc(-24px - 10px); }
  .custom-radio .custom-control-input:checked ~ .custom-control-label:before,
  .custom-radio .custom-control-input:checked:disabled ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:checked:disabled ~ .custom-control-label:before {
    background-color: #181818;
    border-color: #595959; }
  .custom-radio .custom-control-input:disabled ~ .custom-control-label,
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: inherit;
    opacity: 0.5; }
    .custom-radio .custom-control-input:disabled ~ .custom-control-label:before,
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
      box-shadow: none; }
  .custom-radio .custom-control-input.is-invalid ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label:before {
    background-color: #fcd0d5;
    border-color: #a80b1d; }
  .custom-radio .custom-control-input:focus ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none;
    border-color: #595959; }

.custom-checkbox .custom-control-label:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 1px solid #595959;
  background-color: #FFFFFF;
  border-radius: 2px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: #181818;
  border: 1px solid #595959;
  border-radius: 2px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  content: "";
  width: 24px;
  height: 24px;
  line-height: calc(24px + 2px);
  background: none;
  font-family: "icomoon";
  font-size: 16px;
  text-align: center;
  color: #FFFFFF; }

.custom-checkbox + .custom-checkbox {
  margin-top: 27px; }

.custom-radio .custom-control-label:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 1px solid #595959;
  border-radius: 50%;
  background-color: #FFFFFF; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #595959; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  content: "";
  background: none;
  width: 12px;
  height: 12px;
  top: calc(12px/ 2);
  left: calc(-34px + 12px/ 2);
  background-color: #181818;
  border-radius: 50%; }

.custom-radio + .custom-radio {
  margin-top: 27px; }

.custom-select, select.form-control,
select {
  background-image: url(../images/icons/icon-arrow-down.svg);
  background-size: 8px;
  padding-right: 29px; }
  .custom-select.form-control-sm,
  select.form-control-sm {
    background-size: 6px;
    padding-right: 21px; }
  .custom-select.is-invalid,
  select.is-invalid {
    background-image: url(../images/icons/icon-arrow-down.svg);
    background-size: 8px;
    padding-right: 29px; }
    .custom-select.is-invalid.form-control-sm,
    select.is-invalid.form-control-sm {
      background-size: 6px;
      padding-right: 21px; }

.quantity-stepper {
  display: flex;
  padding: 0; }
  .quantity-stepper input,
  .quantity-stepper button {
    border: none;
    padding: 0; }
  .quantity-stepper input {
    width: calc(100% - 20px - 16px - 16px - 6px);
    text-align: center;
    height: 100%;
    padding-bottom: 14px;
    padding-top: 16px; }
    .quantity-stepper input:focus {
      outline: none; }
  .quantity-stepper button {
    font-size: 10px;
    padding-top: 16px;
    padding-bottom: 14px; }
    .quantity-stepper button:before {
      font-weight: bold; }
    .quantity-stepper button.icon-minus {
      padding-left: 16px;
      padding-right: 3px; }
    .quantity-stepper button.icon-plus {
      padding-right: 16px;
      padding-left: 3px; }
  .quantity-stepper.form-control-sm {
    padding: 0; }
    .quantity-stepper.form-control-sm input {
      width: calc(100% - 16px - 10px - 10px - 6px);
      padding-top: 8px;
      padding-bottom: 6px; }
    .quantity-stepper.form-control-sm button {
      font-size: 8px;
      padding-top: 8px;
      padding-bottom: 6px; }
      .quantity-stepper.form-control-sm button.icon-minus {
        padding-left: 10px;
        padding-right: 2px; }
      .quantity-stepper.form-control-sm button.icon-plus {
        padding-right: 10px;
        padding-left: 2px; }

.inline-btn {
  background-color: transparent;
  border-color: #595959;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px; }
  .inline-btn:hover, .inline-btn:focus {
    border-color: #D4D4D4;
    box-shadow: 0 0 0 1px #D4D4D4; }
  .inline-btn input {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: #595959;
    padding-left: 0; }
    .inline-btn input:hover {
      border: none;
      box-shadow: none; }
    .inline-btn input:focus {
      background-color: transparent;
      outline: none; }
    .inline-btn input::placeholder {
      color: #595959; }
  .inline-btn .btn {
    border: none;
    padding: 0 5px 0 10px; }

.show-password {
  float: right; }
  .show-password input {
    margin-right: 8px; }

.validator-container {
  width: 100%;
  padding: 16px 16px 0 16px; }
  .validator-container .password-validation-helper {
    margin: 0 auto;
    padding: 0; }
    .validator-container .password-validation-helper li {
      font-family: inherit;
      list-style-type: none;
      padding: calc(16px/3) calc(16px/2);
      display: flex;
      flex-flow: wrap;
      align-items: center;
      color: #000000; }
      .validator-container .password-validation-helper li:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 13px;
        color: #BEBEBE;
        font-weight: normal; }
      .validator-container .password-validation-helper li[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #BEBEBE;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-sm, .validator-container .password-validation-helper .btn-group-sm > li.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-sm:before, .validator-container .password-validation-helper .btn-group-sm > li.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #BEBEBE;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-lg, .validator-container .password-validation-helper .btn-group-lg > li.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-lg:before, .validator-container .password-validation-helper .btn-group-lg > li.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #BEBEBE;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title) [class*="icon-"],
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title) [class^="icon-"],
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title) .fa {
          display: none; }
      .validator-container .password-validation-helper li:before {
        width: 32px; }
      .validator-container .password-validation-helper li.valid {
        color: #F9F9F9; }
        .validator-container .password-validation-helper li.valid:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 13px;
          color: #01DF9D;
          font-weight: normal; }
        .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #01DF9D;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title).btn-sm, .validator-container .password-validation-helper .btn-group-sm > li.valid.btn[class*="btn-"]:not(.title) {
            padding-left: 42px; }
            .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title).btn-sm:before, .validator-container .password-validation-helper .btn-group-sm > li.valid.btn[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #01DF9D;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title).btn-lg, .validator-container .password-validation-helper .btn-group-lg > li.valid.btn[class*="btn-"]:not(.title) {
            padding-left: 63px; }
            .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title).btn-lg:before, .validator-container .password-validation-helper .btn-group-lg > li.valid.btn[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #01DF9D;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) [class*="icon-"],
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) [class^="icon-"],
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) .fa {
            display: none; }
      .validator-container .password-validation-helper li.error:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 13px;
        color: #a80b1d;
        font-weight: normal; }
      .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #a80b1d;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title).btn-sm, .validator-container .password-validation-helper .btn-group-sm > li.error.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title).btn-sm:before, .validator-container .password-validation-helper .btn-group-sm > li.error.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #a80b1d;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title).btn-lg, .validator-container .password-validation-helper .btn-group-lg > li.error.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title).btn-lg:before, .validator-container .password-validation-helper .btn-group-lg > li.error.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #a80b1d;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) [class*="icon-"],
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) [class^="icon-"],
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) .fa {
          display: none; }
  .order-confirm-create-account .validator-container {
    padding-left: 0;
    padding-right: 0; }
    .order-confirm-create-account .validator-container .password-validation-helper li {
      padding-left: 0;
      padding-right: 0; }

.mandatory-note {
  position: relative; }
  .mandatory-note:before {
    content: "* ";
    color: #a80b1d; }

.form-control, select.form-control {
  color: #595959; }
  .form-control:focus,
  .form-control.focus .form-control:focus:hover, select.form-control:focus,
  select.form-control.focus .form-control:focus:hover,
  .form-control.focus select.form-control:focus:hover,
  select.form-control.focus select.form-control:focus:hover {
    color: #595959;
    outline: 2px solid #181818; }

.custom-select, select.form-control,
select {
  background-image: url(../images/icons/icon-select.svg);
  background-size: 8px; }
  .custom-select.focus-visible:focus,
  select.focus-visible:focus {
    outline: 2px solid; }
  .custom-select.form-control-sm,
  select.form-control-sm {
    background-size: 6px; }
  .custom-select.is-invalid,
  select.is-invalid {
    background-image: url(../images/icons/icon-select.svg);
    background-size: 8px; }
    .custom-select.is-invalid.form-control-sm,
    select.is-invalid.form-control-sm {
      background-size: 6px; }
  .custom-select.quantity,
  select.quantity, .custom-select.quantity-select,
  select.quantity-select {
    background-position: right 20px center;
    background-image: url(../images/icons/icon-quantity-select.svg);
    background-size: 10px; }

.inline-btn input {
  padding-left: 16px; }

.inline-btn .btn {
  border: none;
  padding: 0 16px 0 10px; }

.custom-attribute-radio {
  user-select: none;
  padding-left: calc(32px + 24px); }
  .custom-attribute-radio .custom-control-label {
    position: initial;
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    transition: box-shadow 200ms ease-out; }
    .custom-attribute-radio .custom-control-label:hover:before {
      box-shadow: 0 0 0 1px #D4D4D4; }
    .custom-attribute-radio .custom-control-label:before, .custom-attribute-radio .custom-control-label:after {
      top: calc(50% - 16px);
      left: 32px; }
    .custom-attribute-radio .custom-control-label:before {
      content: "";
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #FFFFFF;
      border: 1px solid #181818; }
  .custom-attribute-radio.out-of-stock .custom-control-label,
  .custom-attribute-radio.out-of-stock .attribute-description, .custom-attribute-radio.disabled .custom-control-label,
  .custom-attribute-radio.disabled .attribute-description {
    color: #D4D4D4; }
  .custom-attribute-radio.out-of-stock .custom-control-label:before, .custom-attribute-radio.disabled .custom-control-label:before {
    border-color: #D4D4D4; }

 :checked ~ .custom-control-label:before,
 :checked:disabled ~ .custom-control-label:before {
  background-color: #181818;
  border-color: #181818; }

 :checked ~ .custom-control-label:after {
  content: "";
  font-family: "icomoon";
  font-size: 16px;
  line-height: 20px;
  background: none;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(32px + 6px);
  color: #FFFFFF;
  text-align: center; }

 :disabled ~ .custom-control-label {
  color: inherit;
  opacity: 0.5; }
   :disabled ~ .custom-control-label:before {
    box-shadow: none; }

 .is-invalid ~ .custom-control-label:before {
  background-color: #fcd0d5;
  border-color: #a80b1d; }

 :focus ~ .custom-control-label:before {
  box-shadow: none;
  border-color: #181818; }

.validator-container {
  padding: 16px 0 0 0; }
  .validator-container .password-validation-helper li {
    padding: calc(16px/3) 0;
    font-size: 16px; }
    @media (min-width: 769px) {
      .validator-container .password-validation-helper li {
        padding: calc(16px/3) calc(16px/2);
        font-size: 16px; } }
    .validator-container .password-validation-helper li:before {
      width: 24px; }
      @media (min-width: 769px) {
        .validator-container .password-validation-helper li:before {
          width: 32px; } }
    .validator-container .password-validation-helper li.valid {
      color: #595959; }

.gripSetup-2 #gripSetup-2.custom-attribute-radio .custom-control-input ~ .custom-control-label:before,
.gripSetup-1 #gripSetup-1.custom-attribute-radio .custom-control-input ~ .custom-control-label:before {
  background-color: #181818;
  border-color: #181818; }

.gripSetup-2 #gripSetup-2.custom-attribute-radio .custom-control-input ~ .custom-control-label:after,
.gripSetup-1 #gripSetup-1.custom-attribute-radio .custom-control-input ~ .custom-control-label:after {
  content: "";
  font-family: "icomoon";
  font-size: 16px;
  line-height: 20px;
  background: none;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(32px + 6px);
  color: #FFFFFF;
  text-align: center; }

.custom-checkbox .custom-control-label:after {
  top: 0;
  vertical-align: middle;
  left: -34px;
  width: 24px;
  height: 24px; }

.custom-radio .custom-control-input:focus ~ .custom-control-label,
.custom-checkbox .custom-control-input:focus ~ .custom-control-label {
  outline: 2px solid #181818; }
  .custom-radio .custom-control-input:focus ~ .custom-control-label::after,
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label::after {
    outline: 2px solid #181818; }

.custom-radio .custom-control-label,
.custom-checkbox .custom-control-label {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .custom-radio .custom-control-label,
    .custom-checkbox .custom-control-label {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.form-control:focus {
  outline: 2px solid #181818; }

*:focus-visible {
  outline-color: transparent; }
  @media (min-width: 1024px) {
    *:focus-visible {
      outline-color: #181818; } }

.form-group label {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .form-group label {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.form-control:focus {
  outline: none;
  border: 2px solid #181818; }

.custom-select, select.form-control,
select, select.form-control, select {
  background-image: url(../images/icons/icon-arrow-down.svg);
  background-size: 12px;
  font-size: 14px; }

select.form-control.selectpicker {
  border: 1px solid #181818;
  border-radius: 8px;
  height: 56px;
  color: #181818; }
  select.form-control.selectpicker:hover {
    border-color: #181818; }

.custom-dropdown-div {
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 12px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  z-index: 9999;
  padding: 8px 0;
  width: 200px;
  max-height: 304px;
  overflow-y: auto;
  scrollbar-width: auto; }
  .custom-dropdown-div::-webkit-scrollbar {
    width: 4px; }
  .custom-dropdown-div::-webkit-scrollbar-thumb {
    background: #D4D4D4;
    border-radius: 12px; }
  .custom-dropdown-div::-webkit-scrollbar-track {
    background: #F9F9F9;
    margin: 8px 0;
    border-radius: 12px; }

.custom-option {
  position: relative;
  padding-bottom: calc($spacer + $line-height); }
  .custom-option::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 95%;
    height: 0.5px;
    background-color: #EDEDED;
    transform: translateX(-50%); }

.custom-dropdown-div div {
  padding: 16px;
  color: #181818;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px; }
  .custom-dropdown-div div:not(.disabled):hover {
    background-color: #F9F9F9;
    cursor: pointer; }
  .custom-dropdown-div div.selected-option {
    font-weight: 600;
    color: #181818; }
    @media (max-width: 768.98px) {
      .custom-dropdown-div div.selected-option {
        font-weight: 700; } }
  .custom-dropdown-div div.disabled {
    pointer-events: auto;
    cursor: not-allowed;
    color: #969696; }

/*
* PAGE DESIGNER LAYOUT GRID SPACING
*/
[class*='experience-commerce_layouts'] + [class*='experience-commerce_layouts'] > div {
  margin-top: 64px; }

@media (max-width: 768.98px) {
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-12']:not(.order-1), [class*='experience-commerce_layouts'] .region[class*='col-md'].order-2[class*='col-12'] {
    margin-top: 32px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-6']:not(:nth-child(1)):not(:nth-child(2)) {
    margin-top: 32px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-4']:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    margin-top: 32px; } }

[class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) {
  height: 100%; }

[class*='experience-commerce_layouts'] .no-gutters .row {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 1440px) {
    [class*='experience-commerce_layouts'] .no-gutters .row {
      padding-right: 16px;
      padding-left: 16px; } }
  [class*='experience-commerce_layouts'] .no-gutters .row > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0; }

[class*='experience-commerce_layouts'] .has-background-image {
  position: relative; }
  [class*='experience-commerce_layouts'] .has-background-image > .container {
    position: relative; }
    [class*='experience-commerce_layouts'] .has-background-image > .container .column-background {
      width: calc(100% - 32px); }
      @media (min-width: 1440px) {
        [class*='experience-commerce_layouts'] .has-background-image > .container .column-background {
          width: calc(100% - 32px); } }
  [class*='experience-commerce_layouts'] .has-background-image .column-background {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .full-bleed [class*='experience-commerce_layouts'] .has-background-image .column-background {
      width: calc(100vw + 2px); }
    [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
      display: none; }
    @media (min-width: 769px) {
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-mobile, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
        display: none; }
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-mobile, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet {
        display: none; }
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
        display: block; } }

/*
* LAYOUT GRID ASPECT RATIOS
*/
.region-square,
.region-landscape,
.region-portrait {
  position: relative; }
  .region-square .experience-component,
  .region-landscape .experience-component,
  .region-portrait .experience-component {
    position: absolute;
    top: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    max-width: calc(100% - 32px); }
    @media (min-width: 1440px) {
      .region-square .experience-component,
      .region-landscape .experience-component,
      .region-portrait .experience-component {
        max-width: calc(100% - 32px); } }

.region-square::after {
  display: block;
  content: "";
  clear: both; }

.region-square::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(1 / 1 * 100%); }

.region-landscape::after {
  display: block;
  content: "";
  clear: both; }

.region-landscape::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(3 / 4 * 100%); }

.region-portrait::after {
  display: block;
  content: "";
  clear: both; }

.region-portrait::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(4 / 3 * 100%); }

div.sf-interactions-proxyHoverContainer a {
  overflow: visible; }

.hover-switch--hover {
  display: none; }

.hover-switch:hover .hover-switch--load {
  display: none; }

.hover-switch:hover .hover-switch--hover {
  display: block; }

.product-grid .experience-region,
.product-grid .experience-component,
.product-grid .chromeless {
  height: 100%; }

.product-grid .hero {
  min-height: calc(100% - 45px);
  margin-bottom: 45px; }

[class*='experience-commerce_layouts'] + [class*='experience-commerce_layouts'] > div {
  margin-top: 64px; }
  @media (min-width: 769px) {
    [class*='experience-commerce_layouts'] + [class*='experience-commerce_layouts'] > div {
      margin-top: 96px; } }

@media (max-width: 768.98px) {
  [class*='experience-commerce_layouts'] .no-gutters .row > .region[class*=" col-"] {
    margin-top: 0; } }

@media (max-width: 768.98px) {
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-12']:not(.order-1), [class*='experience-commerce_layouts'] .region[class*='col-md'].order-2[class*='col-12'] {
    margin-top: 24px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-6']:not(:nth-child(1)):not(:nth-child(2)) {
    margin-top: 24px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-4']:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    margin-top: 24px; } }

.mobile-2r-1c.no-wrap-on-mobile .container .region:first-child {
  padding-right: 0; }
  @media (min-width: 1024px) {
    .mobile-2r-1c.no-wrap-on-mobile .container .region:first-child {
      padding-left: 0; } }

.mobile-2r-1c.no-wrap-on-mobile .container .region:last-child {
  padding-left: 0;
  padding-right: 0; }

.experience-belowFold {
  margin-top: 64px; }
  @media (min-width: 769px) {
    .experience-belowFold {
      margin-top: 96px; } }

.product-grid .promo-tile .hero.short {
  min-height: 150px; }
  @media (min-width: 769px) {
    .product-grid .promo-tile .hero.short {
      min-height: 80px; } }
  @media (min-width: 1024px) {
    .product-grid .promo-tile .hero.short {
      min-height: 80px; } }

.product-grid .promo-tile .hero.medium {
  min-height: 250px; }
  @media (min-width: 769px) {
    .product-grid .promo-tile .hero.medium {
      min-height: 300px; } }
  @media (min-width: 1024px) {
    .product-grid .promo-tile .hero.medium {
      min-height: 350px; } }

.product-grid .promo-tile .hero.tall {
  min-height: 263px; }
  @media (min-width: 769px) {
    .product-grid .promo-tile .hero.tall {
      min-height: 544px; } }
  @media (min-width: 1024px) {
    .product-grid .promo-tile .hero.tall {
      min-height: 544px; } }

.product-grid .promo-tile .hero.screen-height {
  min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); }
  @media (min-width: 769px) {
    .product-grid .promo-tile .hero.screen-height {
      min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); } }
  @media (min-width: 1024px) {
    .product-grid .promo-tile .hero.screen-height {
      min-height: calc(100vh - 60px - 0px - 24px - 8px - 8px); } }
  .transparent-header ~ div .product-grid .promo-tile .hero.screen-height {
    min-height: calc(100vh - 24px - 8px - 8px); }
    @media (min-width: 769px) {
      .transparent-header ~ div .product-grid .promo-tile .hero.screen-height {
        min-height: calc(100vh - 24px - 8px - 8px); } }
    @media (min-width: 1024px) {
      .transparent-header ~ div .product-grid .promo-tile .hero.screen-height {
        min-height: calc(100vh - 24px - 8px - 8px); } }

@media (max-width: 1023.98px) {
  .product-grid .promo-tile .hero.no-height {
    min-height: inherit; } }

.marquee-component {
  padding: 64px 0;
  overflow: hidden;
  height: auto; }
  @media (min-width: 769px) {
    .marquee-component {
      padding: 96px 0; } }
  .marquee-component .marquee-inner {
    display: block;
    position: relative;
    width: 100%;
    animation: marquee 5s linear infinite; }
    @media (min-width: 769px) {
      .marquee-component .marquee-inner {
        animation: marquee 10s linear infinite; } }
    @media (min-width: 1024px) {
      .marquee-component .marquee-inner {
        animation: marquee 15s linear infinite; } }

@keyframes marquee {
  0% {
    left: 0; }
  100% {
    left: -100%; } }
  .marquee-component .marquee-inner-row {
    float: left;
    width: 100%;
    overflow: visible;
    position: relative;
    display: flex;
    justify-content: space-around; }
    .marquee-component .marquee-inner-row span {
      font-family: "Space Grotesk", sans-serif;
      font-size: 32px;
      line-height: 36px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -2px;
      font-style: normal;
      font-display: swap;
      text-align: center;
      margin: 0 24px;
      text-overflow: ellipsis;
      white-space: nowrap; }
      @media (min-width: 1024px) {
        .marquee-component .marquee-inner-row span {
          font-family: "Space Grotesk", sans-serif;
          font-size: 48px;
          line-height: 52px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: -2px;
          font-style: normal;
          font-display: swap; } }
      .marquee-component .marquee-inner-row span:nth-child(2) {
        display: none; }
      .marquee-component .marquee-inner-row span:nth-child(3) {
        display: none; }
      @media (min-width: 769px) {
        .marquee-component .marquee-inner-row span:nth-child(2) {
          display: block; } }
      @media (min-width: 1024px) {
        .marquee-component .marquee-inner-row span:nth-child(3) {
          display: block; } }
    .marquee-component .marquee-inner-row span {
      font-size: 6vw; }
      @media (min-width: 769px) {
        .marquee-component .marquee-inner-row span {
          font-size: 3vw; } }
      @media (min-width: 1024px) {
        .marquee-component .marquee-inner-row span {
          font-size: 2vw; } }
  .marquee-component .marquee-inner-row-copy {
    position: absolute;
    left: 100%; }

.navbar {
  padding: 0; }
  @media (min-width: 1024px) {
    .navbar.bg-inverse {
      position: static; }
      .navbar.bg-inverse .navbar-nav {
        flex-wrap: nowrap; }
        .navbar.bg-inverse .navbar-nav + .nav-item {
          margin-left: 0; }
        .navbar.bg-inverse .navbar-nav .nav-link {
          padding: 16px 18px;
          white-space: nowrap; }
      .logo-center .navbar.bg-inverse .nav-item > .nav-link {
        color: #181818; }
      .logo-center .navbar.bg-inverse .nav-item .nav-link > .nav-link, .logo-center .navbar.bg-inverse .nav-item .nav-link:hover, .logo-center .navbar.bg-inverse .nav-item .nav-link:focus, .logo-center .navbar.bg-inverse .nav-item.show > .nav-link, .logo-center .navbar.bg-inverse .nav-item.show:hover, .logo-center .navbar.bg-inverse .nav-item.show:focus {
        background-color: transparent;
        color: #181818; }
      .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link {
        color: #181818; }
        .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link:hover, .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link:focus {
          background-color: #FFFFFF;
          color: #595959; } }

.multilevel-dropdown .dropdown-menu {
  top: 100%;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  @media (min-width: 1024px) {
    .multilevel-dropdown .dropdown-menu {
      box-shadow: 0 2px 2px rgba(34, 34, 34, 0.2);
      left: auto; } }

.multilevel-dropdown .dropdown-item.custom-dropdown > .dropdown-toggle::after {
  transform: rotate(-90deg); }

@media (min-width: 1024px) {
  .multilevel-dropdown .dropdown-menu > .custom-dropdown > .dropdown-menu {
    top: 0;
    right: 99%;
    border-radius: 20px; } }

.multilevel-dropdown .navbar > .close-menu {
  display: none; }

.multilevel-dropdown .close-menu .back .caret-left:after {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: 10px;
  color: inherit;
  font-weight: normal; }

.multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) {
  padding-right: 42px; }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title):after {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(10px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-sm, .multilevel-dropdown .close-menu .back .btn-group-sm > .caret-left.btn[class*="btn-"]:not(.title) {
    padding-right: 42px; }
    .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-sm:after, .multilevel-dropdown .close-menu .back .btn-group-sm > .caret-left.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-lg, .multilevel-dropdown .close-menu .back .btn-group-lg > .caret-left.btn[class*="btn-"]:not(.title) {
    padding-right: 63px; }
    .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-lg:after, .multilevel-dropdown .close-menu .back .btn-group-lg > .caret-left.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) [class*="icon-"],
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) [class^="icon-"],
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) .fa {
    display: none; }

.multilevel-dropdown .dropdown-menu.show > .dropdown-item.show > .dropdown-toggle::after {
  transform: rotate(90deg); }

.multilevel-dropdown .menu-group {
  width: 100%; }

.multilevel-dropdown .menu-subcategories {
  padding-left: 0; }

.multilevel-dropdown .last-item {
  display: none; }

@media (min-width: 1024px) {
  .navbar-nav .megamenu {
    position: static; }
    .navbar-nav .megamenu > .dropdown-menu {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      left: auto;
      top: auto;
      width: 100vw;
      max-width: 1440px;
      padding: 0;
      box-shadow: none;
      background: #FFFFFF;
      z-index: 0;
      justify-content: center; }
      .navbar-nav .megamenu > .dropdown-menu.mega-left {
        justify-content: flex-start; }
      .navbar-nav .megamenu > .dropdown-menu.show {
        display: flex; }
      .navbar-nav .megamenu > .dropdown-menu:before {
        content: '';
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        left: auto;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: -1;
        background: #FFFFFF;
        box-shadow: 0 2px 2px rgba(34, 34, 34, 0.2); }
        .logo-left .navbar-nav .megamenu > .dropdown-menu:before {
          border-top: 1px solid #F9F9F9; }
      .navbar-nav .megamenu > .dropdown-menu .menu-subcategories {
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
        padding-top: 30px;
        padding-bottom: 30px; } }
      @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories {
          flex-grow: 1; } }

@media (min-width: 1024px) {
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item {
          padding-left: 16px;
          padding-bottom: 16px; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-toggle:after {
          display: none; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
          width: auto;
          border: none;
          white-space: normal; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:hover {
            background: none; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .nav-link {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          text-transform: none;
          color: #181818; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .nav-link:hover {
            color: #181818; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-link {
          padding: 8px 0 32px 0;
          display: inline-block; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu {
          margin-left: 2px;
          position: static;
          display: flex;
          box-shadow: none;
          border-radius: 0;
          background: transparent;
          flex-flow: row wrap;
          align-content: flex-start; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item {
            border: none;
            flex-basis: 100%; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.custom-dropdown {
              flex-basis: 25%; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.last-item {
              display: block;
              order: 1000;
              padding-top: 16px; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.last-item a {
                font-weight: bold;
                text-transform: uppercase; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.last-item:before {
                content: '';
                position: absolute;
                border-top: 1px solid #F9F9F9;
                width: 48px;
                left: calc(16px + 2px + 0); }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item {
              padding-top: unset; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
                font-weight: 500;
                text-transform: none; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
                display: none; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item {
              padding-top: unset; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
                font-weight: 500;
                text-transform: none; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
                display: none; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link {
            color: #181818;
            font-size: 14px;
            font-weight: 500;
            text-transform: none; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link:hover {
              color: #595959; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link.mega-left-title {
              font-weight: bold;
              font-size: 16px;
              text-transform: none; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-menu {
            flex-flow: column; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left {
          flex: 1 1 calc((100% - 15%)/2); }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left.has-sub-asset {
            flex: 1 1 calc(100% - 15%); }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left.has-sub-asset + [class*="megamenu-asset-"] {
              display: none; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left.has-sub-asset + [class*="megamenu-asset-"] + [class*="megamenu-asset-"] {
                display: none; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left > .dropdown-item {
            display: none; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left > .dropdown-item.active-list {
              display: flex; }
      .navbar-nav .megamenu > .dropdown-menu > li > a.title {
        margin-bottom: 30px;
        padding: 0 5px;
        text-decoration: none; }
      .navbar-nav .megamenu > .dropdown-menu .megamenu-asset-only {
        width: 100%; }
      .navbar-nav .megamenu > .dropdown-menu .left-nav {
        margin-top: 30px;
        margin-bottom: 30px;
        flex: 0 0 15%;
        border-right: 2px solid #F2F2F2; }
        .navbar-nav .megamenu > .dropdown-menu .left-nav li {
          border-top: none;
          padding: 0; }
          .navbar-nav .megamenu > .dropdown-menu .left-nav li:hover {
            background: unset; }
        .navbar-nav .megamenu > .dropdown-menu .left-nav a {
          padding: 10px 15px;
          font-size: 18px;
          text-transform: uppercase;
          letter-spacing: 0;
          border-right: 2px solid #F2F2F2;
          margin-right: -2px; }
          .navbar-nav .megamenu > .dropdown-menu .left-nav a.active {
            color: #595959;
            border-right: 2px solid #595959; } }

.navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
  padding: 16px;
  border-top: 1px solid #F9F9F9; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
      padding: 30px 16px;
      margin: 0 auto;
      padding-left: 16px;
      border-top: none;
      border-right: none;
      flex-basis: 25%; } }

.navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
  padding: 16px;
  border-top: 1px solid #F9F9F9; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
      padding: 0;
      margin: 0 auto;
      padding-left: 16px;
      border-top: none;
      border-left: none;
      flex-basis: 25%; }
      .navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 img {
        margin-bottom: -30px;
        margin-top: -30px;
        margin-left: 16px;
        margin-right: -16px; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left .active-list + [class*="sub-asset-"] {
    display: flex;
    padding: 0;
    flex: 0 0 50%; } }

.navbar-nav .megamenu .mega-left .sub-asset-1,
.navbar-nav .megamenu .mega-left .sub-asset-2 {
  display: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  list-style: none; }
  .navbar-nav .megamenu .mega-left .sub-asset-1 .page-designer,
  .navbar-nav .megamenu .mega-left .sub-asset-2 .page-designer {
    flex: 0 0 100%;
    padding-right: 16px; }

.navbar-nav .megamenu .mega-left .sub-asset-1.sub-asset-2 .page-designer {
  flex: 0 0 50%; }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-2, .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-2 {
    flex: 1 1 calc((100% - 15%)/2);
    padding: 30px 16px 30px 0; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-2 {
    flex: 1 1 calc((100% - 15%)/4); } }

@media (max-width: 1023.98px) {
  .navbar-nav .megamenu.show [class*="has-asset-"] .thirdCatOpen + [class*="megamenu-asset-"] {
    display: none; }
    .navbar-nav .megamenu.show [class*="has-asset-"] .thirdCatOpen + [class*="megamenu-asset-"] + [class*="megamenu-asset-"] {
      display: none; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .menu-subcategories > .dropdown-item {
    flex-basis: 20%; }
  .navbar-nav .megamenu .has-asset-1 .menu-subcategories,
  .navbar-nav .megamenu .has-asset-2 .menu-subcategories {
    flex-grow: 1; }
    .navbar-nav .megamenu .has-asset-1 .menu-subcategories > .dropdown-item,
    .navbar-nav .megamenu .has-asset-2 .menu-subcategories > .dropdown-item {
      flex-basis: 33.33%; }
  .navbar-nav .megamenu .has-asset-1.has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 50%; } }

@media (min-width: 1440px) {
  .navbar-nav .megamenu .has-asset-1 .menu-subcategories > .dropdown-item,
  .navbar-nav .megamenu .has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 20%; }
  .navbar-nav .megamenu .has-asset-1.has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 33.33%; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left {
    position: fixed;
    transform: translateX(-100%);
    transition: transform 200ms ease-out;
    top: 0;
    left: 0;
    display: block;
    width: 350px;
    height: calc(100vh - 60px);
    background-color: #FFFFFF;
    z-index: 300;
    overflow-x: hidden;
    overflow-y: auto; }
    .main-menu.menu-toggleable-left.in {
      transform: translateX(0); }
      .main-menu.menu-toggleable-left.in .show > .dropdown-menu {
        transform: translateX(0);
        overflow: auto; }
    .main-menu.menu-toggleable-left > .container {
      height: 100%;
      padding-left: 0;
      padding-right: 0; }
    .main-menu.menu-toggleable-left .menu-group {
      padding-bottom: 16px; }
    .main-menu.menu-toggleable-left .search-mobile .search-field {
      padding: 0 16px;
      border-radius: 0;
      border-left: none;
      border-right: none;
      font-size: 16px;
      line-height: normal; }
    .main-menu.menu-toggleable-left .search-mobile .fa-close,
    .main-menu.menu-toggleable-left .search-mobile .fa-search {
      right: 16px; }
    .main-menu.menu-toggleable-left .search-mobile .reset-button {
      display: none !important; }
    .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper {
      background-color: #F9F9F9;
      overflow: hidden; }
      .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper .suggestions {
        position: static;
        width: 100%;
        margin-bottom: 16px;
        box-shadow: none; }
        .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper .suggestions > .container {
          max-width: 350px;
          margin-left: 0; }
    .main-menu.menu-toggleable-left .dropdown-menu {
      position: fixed;
      transform: translateX(-100%);
      transition: transform 200ms ease-out;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 70px;
      overflow: hidden;
      display: block; }
    .main-menu.menu-toggleable-left .dropdown-toggle:after {
      transform: rotate(-90deg); }
    .main-menu.menu-toggleable-left .dropdown-item:hover {
      background: transparent; }
    .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories {
      border-top: 1px solid #F9F9F9;
      padding-left: 16px;
      padding-right: 16px; }
      .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item > .dropdown-link {
        padding-left: 0; }
      .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item .top-category {
        margin-bottom: -1px;
        border-bottom: 1px solid #F9F9F9; }
        .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item {
          margin-left: 16px;
          margin-right: 16px;
          width: auto; }
          .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item > .dropdown-link {
            padding-left: 0; }
    .main-menu.menu-toggleable-left .top-category .user-message {
      display: none; }
    .main-menu.menu-toggleable-left .support .top-category {
      border-bottom: 1px solid #F9F9F9; }
    .main-menu.menu-toggleable-left .nav-item ~ .nav-item {
      border-top: 1px solid #F9F9F9; }
    .main-menu.menu-toggleable-left .nav-item .nav-link {
      padding: 16px;
      color: #181818; }
      .main-menu.menu-toggleable-left .nav-item .nav-link.dropdown-toggle:hover {
        color: #181818; }
    .main-menu.menu-toggleable-left .nav-item.highlight .nav-link {
      color: #a80b1d; }
    .main-menu.menu-toggleable-left .close-menu {
      background-color: #F9F9F9; }
      .main-menu.menu-toggleable-left .close-menu button {
        display: block;
        width: 100%;
        padding: 16px;
        text-align: left; }
      .main-menu.menu-toggleable-left .close-menu .icon {
        float: left;
        display: block;
        margin-top: 3px;
        margin-right: 10px; }
    .main-menu.menu-toggleable-left .flag-icon {
      margin-right: 5px; } }

@media (max-width: 768.98px) {
  .main-menu.menu-toggleable-left {
    height: calc(100vh - 60px); } }

@media (max-width: 543.98px) {
  .main-menu.menu-toggleable-left {
    width: calc(100% - 50px);
    max-width: 350px; } }

.navbar-nav {
  padding: 16px 0 0; }
  @media (min-width: 769px) {
    .navbar-nav {
      padding: 0; } }

.navbar.bg-inverse .navbar-nav .nav-link {
  padding: 16px 24px; }
  .navbar.bg-inverse .navbar-nav .nav-link .cat-nav-badge {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    padding: 2px 4px;
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    font-size: 8px;
    line-height: 10px; }
  @media (min-width: 1024px) {
    .navbar.bg-inverse .navbar-nav .nav-link:focus {
      outline: none;
      text-decoration: underline; }
      .navbar.bg-inverse .navbar-nav .nav-link:focus .cat-nav-badge {
        text-decoration: none; } }
  .navbar.bg-inverse .navbar-nav .nav-link:hover {
    text-decoration: none; }
    @media (min-width: 1024px) {
      .navbar.bg-inverse .navbar-nav .nav-link:hover {
        text-decoration: underline; } }
    .navbar.bg-inverse .navbar-nav .nav-link:hover .cat-nav-badge {
      text-decoration: none; }

.navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-link {
  color: inherit;
  display: flex; }

.navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item.text--white:hover {
  color: #FFFFFF; }

@media (min-width: 1024px) {
  .navbar .megamenu:hover > .dropdown-menu {
    display: block; }
  .navbar .megamenu .menu-subcategories > .dropdown-item.custom-dropdown {
    flex-basis: 45%; }
  .navbar .megamenu > .dropdown-menu .menu-subcategories {
    width: 100%;
    justify-content: space-around;
    padding: 24px 16px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories {
      justify-content: flex-start; } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories {
      justify-content: space-around; } }

@media (min-width: 1024px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:hover {
      background-color: #F9F9F9; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
      max-width: 28%; } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
      max-width: inherit; } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:last-child:nth-child(2), .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:last-child:nth-child(8), .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:last-child:nth-child(14) {
      margin-right: 34%; } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:last-child:nth-child(10), .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:last-child:nth-child(4) {
      margin-right: 67.25%; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item.custom-dropdown {
      max-width: inherit; } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item.custom-dropdown + .dropdown-item:nth-child(2) {
      margin-right: 0; } }

@media (min-width: 1024px) {
    .navbar .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item {
      padding: 0;
      margin: 16px 16px 0; } }

@media (max-width: 1023.98px) {
  .lower-level-open {
    height: 0; }
    .lower-level-open .nav-item {
      height: 0;
      overflow: hidden; }
      .lower-level-open .nav-item.show {
        left: 0;
        right: 0;
        height: 100vh;
        overflow: auto;
        position: fixed;
        background-color: #FFFFFF; }
    .lower-level-open .thirdCatOpen .dropdown-item {
      height: 0; }
    .lower-level-open .thirdCatOpen .show .dropdown-item {
      height: 1.5em; } }

.navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-link {
  height: 96px;
  display: flex;
  align-items: center; }

.navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu {
    position: static; }
    .navbar-nav .megamenu .menu-subcategories > .dropdown-item {
      flex-basis: 25%; }
    .navbar-nav .megamenu > .dropdown-menu {
      max-width: 100%; }
      .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-link {
        padding: 10px 30px; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-link:hover .cat-sub-nav-badge {
          text-decoration: none; }
      .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:hover {
        background-color: #F9F9F9;
        background-image: inherit; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:hover .cat-sub-nav-badge {
          text-decoration: none; }
      .navbar-nav .megamenu > .dropdown-menu:before {
        width: 100%; } }

.navbar-nav .megamenu .cat-sub-nav-badge {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  padding: 2px 4px;
  border-width: 1px;
  border-style: solid;
  border-radius: 16px;
  font-size: 8px;
  line-height: 10px; }

.main-menu.menu-toggleable-left {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden; }
  @media (max-width: 768.98px) {
    .main-menu.menu-toggleable-left {
      height: 100vh; } }
  .main-menu.menu-toggleable-left::-webkit-scrollbar {
    display: none; }
  .main-menu.menu-toggleable-left .country-selector {
    display: none; }
  .main-menu.menu-toggleable-left.navbar-toggleable-md {
    width: 100%;
    max-width: 100%; }
    .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu {
      background-color: transparent; }
      @media (max-width: 1023.98px) {
        .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu {
          position: relative;
          height: 84px; }
          .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button {
            left: 0;
            width: 15%;
            position: absolute;
            top: 32px;
            height: auto;
            padding: 0; }
            .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button > img {
              float: left;
              width: 24px; }
            .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon {
              left: auto;
              right: 0; }
              .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon:before {
                content: "";
                font-family: "icomoon";
                display: inline-block;
                font-size: 14px;
                color: #181818;
                font-weight: normal; }
              .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title) {
                padding-left: 42px; }
                .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title):before {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 20px;
                  color: #181818;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(-30px); }
                .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title).btn-sm, .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu .btn-group-sm > button.close-menu-icon.btn[class*="btn-"]:not(.title) {
                  padding-left: 42px; }
                  .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title).btn-sm:before, .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu .btn-group-sm > button.close-menu-icon.btn[class*="btn-"]:not(.title):before {
                    font-family: "icomoon";
                    display: inline-block;
                    font-size: 22px;
                    color: #181818;
                    font-weight: normal;
                    position: absolute;
                    transform: translateX(-32px); }
                .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title).btn-lg, .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu .btn-group-lg > button.close-menu-icon.btn[class*="btn-"]:not(.title) {
                  padding-left: 63px; }
                  .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title).btn-lg:before, .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu .btn-group-lg > button.close-menu-icon.btn[class*="btn-"]:not(.title):before {
                    font-family: "icomoon";
                    display: inline-block;
                    font-size: 22px;
                    color: #181818;
                    font-weight: normal;
                    position: absolute;
                    transform: translateX(-32px); }
                .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title) [class*="icon-"],
                .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title) [class^="icon-"],
                .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon[class*="btn-"]:not(.title) .fa {
                  display: none; }
              .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon.close-main-menu {
                right: 0;
                width: 50px;
                z-index: 99;
                top: 15px; }
              .main-menu.menu-toggleable-left.navbar-toggleable-md .close-menu button.close-menu-icon::before {
                font-size: 18px; } }
    .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category ~ .menu-subcategories {
      padding-left: 0;
      padding-right: 0; }
      @media (max-width: 1023.98px) {
        .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category ~ .menu-subcategories {
          padding-bottom: 100px;
          overflow: auto;
          height: 100vh; } }
      .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category ~ .menu-subcategories > .dropdown-item > .dropdown-link {
        padding: 16px 100px 16px 32px;
        white-space: normal;
        flex-wrap: wrap;
        line-height: 24px; }
        @media (min-width: 769px) {
          .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category ~ .menu-subcategories > .dropdown-item > .dropdown-link {
            padding: 16px 0 32px 0; } }
        .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category ~ .menu-subcategories > .dropdown-item > .dropdown-link:hover {
          text-decoration: none; }
          @media (min-width: 1024px) {
            .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category ~ .menu-subcategories > .dropdown-item > .dropdown-link:hover {
              text-decoration: underline; } }
        @media (min-width: 1024px) {
          .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category ~ .menu-subcategories > .dropdown-item > .dropdown-link:focus {
            outline: none;
            text-decoration: underline; } }
    @media (max-width: 1023.98px) {
      .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 12px;
        background: transparent;
        width: auto;
        left: 15%;
        right: 15%;
        z-index: 1;
        text-align: center; }
        .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category .nav-link {
          margin: 0;
          padding: 16px 0;
          font-family: "Inter", sans-serif;
          font-size: 20px;
          line-height: 28px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    @media (max-width: 1023.98px) and (min-width: 1024px) {
      .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .top-category .nav-link {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        line-height: 28px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .main-menu.menu-toggleable-left.navbar-toggleable-md > .dropdown-item:hover {
      background: #F9F9F9; }
      @media (min-width: 1024px) {
        .main-menu.menu-toggleable-left.navbar-toggleable-md > .dropdown-item:hover {
          background: #F9F9F9; } }
    .main-menu.menu-toggleable-left.navbar-toggleable-md .user-message + .dropdown-menu {
      position: static;
      transform: none; }
      .main-menu.menu-toggleable-left.navbar-toggleable-md .user-message + .dropdown-menu .dropdown-link {
        padding: 16px 32px 0; }
    .main-menu.menu-toggleable-left.navbar-toggleable-md .dropdown-item.custom-dropdown .dropdown-menu {
      margin: 0;
      padding: 0; }
    @media (max-width: 1023.98px) {
      .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .nav-link {
        padding: 32px;
        color: inherit;
        font-family: "Inter", sans-serif; }
      .main-menu.menu-toggleable-left.navbar-toggleable-md.in .show > .dropdown-menu {
        padding: 0 16px 50%;
        margin: 0; }
      .main-menu.menu-toggleable-left.navbar-toggleable-md > .nav-item {
        border-top: none;
        margin: 0 0 16px;
        border-radius: 20px;
        background: #F9F9F9; }
      .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item ~ .nav-item:not(.nav-item-cat) {
        border-top: 1px solid #F9F9F9; }
      .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group {
        padding-bottom: 200px; }
        .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .close-menu {
          display: flex; }
          .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .close-menu .back {
            padding-left: 0; }
          .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .close-menu .icon-arrow-left-thin {
            margin-top: 17px;
            font-size: 18px;
            font-weight: bold; }
        .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .brand {
          flex-grow: 1;
          max-width: 190px;
          height: 50px;
          height: 100%;
          align-items: center; }
          .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .brand .logo-home {
            width: 160px;
            height: 100%;
            margin: 0 auto;
            background: url(../images/logo.svg) no-repeat center;
            background-size: contain;
            display: block; } }
  @media (max-width: 543.98px) {
    .main-menu.menu-toggleable-left {
      width: 100%;
      max-width: 100%; } }
  @media (max-width: 1023.98px) {
    .main-menu.menu-toggleable-left {
      height: 100vh;
      top: 0 !important; }
      .main-menu.menu-toggleable-left.in {
        transform: translateX(0); }
        .main-menu.menu-toggleable-left.in .show > .dropdown-menu {
          transform: translateX(0);
          overflow: hidden;
          -ms-overflow-style: none;
          scrollbar-width: none; }
          .main-menu.menu-toggleable-left.in .show > .dropdown-menu::-webkit-scrollbar {
            display: none; }
        .main-menu.menu-toggleable-left.in .navbar-nav {
          padding: 16px 0 82px;
          display: block;
          background-color: #FFFFFF; }
        .main-menu.menu-toggleable-left.in .mobile-nav-wrap {
          overflow-x: hidden;
          overflow-y: auto;
          height: 100vh; }
          .main-menu.menu-toggleable-left.in .mobile-nav-wrap.grid-view {
            height: 100vh;
            padding: 0; }
            .main-menu.menu-toggleable-left.in .mobile-nav-wrap.grid-view .swatches {
              padding: 0 32px 104px; }
            .main-menu.menu-toggleable-left.in .mobile-nav-wrap.grid-view .first-category-name {
              margin-bottom: 32px; }
            .main-menu.menu-toggleable-left.in .mobile-nav-wrap.grid-view .subcategories-swatches {
              margin: 0;
              padding: 0; }
            .main-menu.menu-toggleable-left.in .mobile-nav-wrap.grid-view .subcategory-swatch a {
              max-width: initial; }
            .main-menu.menu-toggleable-left.in .mobile-nav-wrap.grid-view .grid-view {
              padding: 0; }
        .main-menu.menu-toggleable-left.in .content-asset .dropdown {
          z-index: 0; }
        .main-menu.menu-toggleable-left.in .search-mobile .site-search {
          padding: 0 16px; }
        .main-menu.menu-toggleable-left.in .search-mobile .search-field {
          border: 1px solid #D4D4D4;
          border-radius: 4px; }
        .main-menu.menu-toggleable-left.in .search-mobile .suggestions-wrapper {
          height: auto; }
        .main-menu.menu-toggleable-left.in.search .search-mobile {
          display: block; }
        .main-menu.menu-toggleable-left.in.search .menu-group {
          display: none; }
      .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories {
        border: 0; }
      .main-menu.menu-toggleable-left .top-category > .nav-link > .cat-nav-badge {
        display: none; }
      .main-menu.menu-toggleable-left .dropdown-menu {
        top: 0;
        z-index: 2; } }

@media (min-width: 1024px) {
  .usertopnavlayout .menu-group .navbar-nav .nav-link {
    padding: 8px; } }

@media (min-width: 1200px) {
  .usertopnavlayout .menu-group .navbar-nav .nav-link {
    padding: 16px; } }

@media (min-width: 1440px) {
  .usertopnavlayout .menu-group .navbar-nav .nav-link {
    padding: 24px; } }

.usertopnavlayout .menu-group .navbar-nav .lines {
  text-decoration: none;
  font-weight: 500; }
  @media (min-width: 1024px) {
    .usertopnavlayout .menu-group .navbar-nav .lines {
      padding: 8px; } }
  @media (min-width: 1200px) {
    .usertopnavlayout .menu-group .navbar-nav .lines {
      padding: 16px; } }
  @media (min-width: 1440px) {
    .usertopnavlayout .menu-group .navbar-nav .lines {
      padding: 24px; } }
  @media (max-width: 1199.98px) {
    .usertopnavlayout .menu-group .navbar-nav .lines:hover {
      text-decoration: none;
      font-weight: 500; } }
  @media (min-width: 1024px) {
    .usertopnavlayout .menu-group .navbar-nav .lines:hover {
      text-decoration: underline;
      text-underline-offset: 8px;
      color: #181818; } }

@media (min-width: 1024px) {
  .usertopnavlayout .menu-group .navbar-nav .nav-link:hover {
    text-underline-offset: 8px; } }

@media (min-width: 1024px) {
  .usertopnavlayout .menu-group .navbar-nav .nav-item:hover > .categories > .lines, .usertopnavlayout .menu-group .navbar-nav .nav-item:focus > .categories > .lines {
    text-decoration: underline;
    text-underline-offset: 8px;
    color: #181818; } }

@media (min-width: 1024px) {
  .usertopnavlayout .menu-group .navbar-nav .custom-dropdown:hover .first-category-name > .lines {
    text-decoration: underline;
    text-underline-offset: 8px;
    color: #181818; } }

@media (max-width: 1199.98px) {
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md {
    width: 100%;
    background: transparent; }
    .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .close-button {
      position: absolute;
      left: 83%;
      margin-top: 16px;
      padding: 0 16px 0 16px; }
      .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .close-button .new-close {
        position: absolute;
        background: #FFFFFF;
        left: 100%;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: #181818; }
    .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item.d-lg-none ~ .nav-item:not(.nav-item-cat)::before {
      background-color: transparent; }
  .usertopnavlayout .mobile-nav-wrap {
    width: 85%; }
  .usertopnavlayout .navbar-nav {
    align-items: center; }
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .close-menu {
    display: none;
    height: auto; }
    .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .close-menu button {
      top: 14px; }
  .usertopnavlayout .main-menu.menu-toggleable-left.in .search-mobile .search-field {
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 20px; }
  .usertopnavlayout .main-menu.menu-toggleable-left.in .search-mobile :focus {
    border: 2px solid #181818; }
  .usertopnavlayout .subcategories-swatches {
    scrollbar-width: none; }
  .usertopnavlayout .icon-arrow-left-thin {
    display: none; }
  .usertopnavlayout .category-image {
    width: 72px;
    height: 72px; } }

@media (min-width: 1024px) {
  .usertopnavlayout .navbar-nav {
    align-items: center !important; }
    .usertopnavlayout .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu {
      flex-flow: column wrap; }
  .usertopnavlayout .navbar .megamenu > .dropdown-menu {
    max-width: 100%;
    margin-top: 3px; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches {
      padding: 49px 20px 16px;
      max-width: 1020px;
      margin: 0 auto; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-nav {
        display: none; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-img-box {
        width: 140px !important; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-inner {
        max-width: 1020px;
        margin: 0 auto;
        overflow: hidden; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-controls button[data-controls='next'] {
        right: 6px; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-controls button[data-controls='prev'] {
        left: 6px; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-view a {
        display: flex;
        flex-direction: column;
        text-decoration: none; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-view a .subcategory-image {
          width: 120px;
          height: 120px;
          gap: 0px;
          border-radius: 20px;
          margin: 30px 10px 16px 10px; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-view a .subcategory-name {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          text-align: center;
          margin-bottom: 40px;
          text-wrap: nowrap; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      padding: 56px 0 40px;
      justify-content: center;
      background: #FFFFFF; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
        max-width: 100%; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        /* Default single column style */
        /* Two column style */ }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .shop-all {
          display: inline-flex;
          justify-content: flex-start;
          text-decoration: none;
          text-wrap: nowrap;
          width: 65px;
          height: 19px;
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 19.36px;
          text-align: left;
          color: #181818;
          margin-bottom: 12px;
          margin-right: auto;
          align-items: center; }
          .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .shop-all .icon-arrow {
            width: 14px;
            height: 13px;
            padding: 0.39px 0px 0.39px 0px;
            margin-left: 14px; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column {
          margin: 0; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column.single-column {
          display: flex;
          flex-direction: column; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column.single-column .coln {
          display: flex;
          flex-direction: column; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column.two-column {
          display: flex;
          flex-direction: column; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column.two-column .coln {
          display: flex;
          margin-top: 17px; }
          .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column.two-column .coln .dropdown-menu {
            margin-top: 6px; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .two-coln {
          column-count: 2;
          column-gap: 0;
          display: block !important;
          line-height: 0; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column .coln > span {
          display: block;
          padding-right: 16px;
          max-width: 216px;
          min-width: 216px;
          width: 100%; }
          .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column .coln > span.category-type-1 {
            max-width: initial;
            min-width: initial; }
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column .coln > span.category-type-1 > .dropdown-item > .dropdown-menu {
              min-width: 200px; }
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .desktop-column .coln > span.category-type-1 > .dropdown-item > .dropdown-menu.two-coln {
              min-width: 416px;
              width: 416px;
              column-gap: 16px; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row {
          width: 100%;
          display: flex; }
          .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-1 .dropdown-item,
          .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-2 .dropdown-item {
            background: none;
            background-size: 0 !important;
            margin: 0;
            border-radius: 0; }
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-1 .dropdown-item .subcategory-swatch-header,
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-2 .dropdown-item .subcategory-swatch-header {
              font-family: "Inter", sans-serif;
              font-size: 16px;
              font-weight: 700;
              line-height: 19.36px;
              text-align: left;
              opacity: 50%; }
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-1 .dropdown-item .dropdown-link,
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-2 .dropdown-item .dropdown-link {
              padding: 12px 0 !important;
              height: auto;
              align-items: center;
              font-family: "Inter", sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              text-align: left;
              display: inline-block; }
              .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-1 .dropdown-item .dropdown-link:hover, .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-1 .dropdown-item .dropdown-link:focus,
              .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-2 .dropdown-item .dropdown-link:hover,
              .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .category-alignment .row .category-type-2 .dropdown-item .dropdown-link:focus {
                color: #181818;
                text-decoration: underline; }
      .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset {
        position: relative;
        padding-right: 1rem; }
        .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images {
          display: flex;
          flex-direction: row;
          padding: 0; }
          .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link {
            display: flex;
            gap: 1rem; }
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type1,
            .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type2 {
              display: flex;
              flex-direction: column;
              max-width: 360px;
              width: 100%; }
              .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type1 .text-underline,
              .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type2 .text-underline {
                text-decoration: none; }
                .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type1 .text-underline .image-styling,
                .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type2 .text-underline .image-styling {
                  width: 100%;
                  max-width: 100%;
                  height: auto;
                  aspect-ratio: 1/1;
                  border-radius: 20px; }
                .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type1 .text-underline .text,
                .usertopnavlayout .navbar .megamenu > .dropdown-menu .menu-subcategories .desktop-asset .menu-images .link .type2 .text-underline .text {
                  font-family: Inter;
                  font-size: 18px;
                  font-weight: 500;
                  margin-top: 12px;
                  line-height: 21.78px;
                  text-align: left; }
  .usertopnavlayout .first-category-name {
    text-wrap: nowrap; }
  .usertopnavlayout .mobile-navigation-bar {
    display: none; }
  .usertopnavlayout .mobile-subcategories {
    display: none !important; }
  .usertopnavlayout .category-image {
    display: none; }
  .usertopnavlayout .down-arrow {
    display: none; }
  .usertopnavlayout .first-category-swatches {
    display: none; }
  .usertopnavlayout .subcategories-swatches {
    display: none; }
  .usertopnavlayout .view-all-button {
    display: none !important; }
  .usertopnavlayout .arrow-toggle {
    display: none; }
  .usertopnavlayout .subcategories-list {
    display: none; }
  .usertopnavlayout .mobile-nav-footer {
    display: none; }
  .usertopnavlayout .subcategory-swatch {
    display: none; }
  .usertopnavlayout .close-button {
    display: none; } }

@media (min-width: 1200px) {
  .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches {
    padding: 49px 20px 16px;
    max-width: 1160px;
    margin: 0 auto; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-img-box {
      width: 160px !important; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-inner {
      max-width: 1160px; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-view a .subcategory-image {
      width: 140px;
      height: 140px; } }

@media (min-width: 1440px) {
  .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches {
    padding: 49px 20px 6px;
    max-width: 1440px;
    margin: 0 auto; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-img-box {
      width: 200px !important; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-inner {
      max-width: 1440px; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-controls button[data-controls='next'] {
      right: 16px; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .tns-controls button[data-controls='prev'] {
      left: 16px; }
    .usertopnavlayout .navbar .megamenu > .dropdown-menu .swatches .swatch-view a .subcategory-image {
      width: 160px;
      height: 160px;
      margin: 30px 20px 16px 20px; } }

@media (max-width: 1023.98px) {
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item ~ .nav-item:not(.nav-item-cat) {
    border: none; }
    .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item ~ .nav-item:not(.nav-item-cat)::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 90%;
      height: 1px;
      background-color: #FFFFFF;
      transform: translateX(-50%); }
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .close-menu button {
    top: 14px; }
  .usertopnavlayout .main-menu.menu-toggleable-left.in .search-mobile .search-field {
    border: 1px solid #D4D4D4;
    border-radius: 20px; }
  .usertopnavlayout .main-menu.menu-toggleable-left.in .search-mobile :focus {
    border: 2px solid #181818; }
  .usertopnavlayout .mobile-nav-wrap .search-bar {
    padding-top: 24px;
    padding-bottom: 24px;
    background: #F9F9F9;
    font-family: "Inter", sans-serif;
    font-size: 16px; }
    .usertopnavlayout .mobile-nav-wrap .search-bar .fa-search {
      width: 22px;
      height: 22px;
      background: url(../images/icons/Search.svg) no-repeat center;
      background-size: contain; } }
    @media (max-width: 1023.98px) and (min-width: 769px) {
      .usertopnavlayout .mobile-nav-wrap .search-bar .fa-search {
        width: 25px;
        height: 25px; } }

@media (max-width: 1023.98px) {
  .usertopnavlayout .mobile-nav-wrap .view-all-button {
    text-decoration: underline;
    position: relative;
    float: right;
    font-size: 12px;
    color: #181818;
    right: 4vw; }
  .usertopnavlayout .norm-to-design {
    display: inline-flex;
    width: 100%; }
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .nav-link {
    font-weight: 500;
    padding: 0px; }
  .usertopnavlayout li.nav-item.nav-item-cat.border-nav {
    position: relative;
    border: none; }
    .usertopnavlayout li.nav-item.nav-item-cat.border-nav::before, .usertopnavlayout li.nav-item.nav-item-cat.border-nav::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 90%;
      height: 1px;
      background-color: #EDEDED;
      transform: translateX(-50%); }
  .usertopnavlayout .subcategories-list.mobile-subcategories .shop-all {
    position: relative;
    display: inline-block; }
  .usertopnavlayout .subcategories-list.mobile-subcategories::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 90%;
    height: 1px;
    background-color: #EDEDED;
    transform: translateX(-50%); }
  .mobile-nav-footer .account-border .usertopnavlayout::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 90%;
    height: 1px;
    background-color: #EDEDED;
    transform: translateX(-50%); }
  .usertopnavlayout .background-blur::before {
    background-color: #000000;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    width: 100vw;
    top: 0;
    left: 0;
    content: " ";
    z-index: 1; }
  .usertopnavlayout .view-all-image-size {
    width: 100% !important;
    height: auto !important;
    border-radius: 8px !important; }
  .usertopnavlayout .view-all-font-size {
    font-size: 14px !important;
    margin-top: 12px !important;
    margin-bottom: 12px !important; }
  .usertopnavlayout .desktop-asset {
    display: none; }
  .usertopnavlayout .nav-item-cat {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-radius: 0; }
  .usertopnavlayout .mobile-navigation-bar {
    display: flex;
    align-items: center;
    padding: 0 14px 24px 14px; }
    .usertopnavlayout .mobile-navigation-bar .social-links li {
      margin-top: 16px; }
  .usertopnavlayout .category-item {
    display: flex;
    align-items: center; }
  .usertopnavlayout .category-content a {
    display: flex;
    align-items: center; }
  .usertopnavlayout .category-image {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background: #F4F4F4;
    margin: 20px 16px 20px 16px;
    padding: 0; }
  .usertopnavlayout .mobile-nav-footer {
    background: #f3f3f3;
    padding: 16px 0 84px; }
    .usertopnavlayout .mobile-nav-footer li.nav-item {
      padding: 16px; }
  .usertopnavlayout .main-menu.menu-toggleable-left.in .navbar-nav {
    padding: 0px; }
  .usertopnavlayout .icon-arrow-left-thin {
    margin-top: 2.25vh; }
  .usertopnavlayout .top-arrow {
    rotate: 270deg !important; }
  .usertopnavlayout .custom-dropdown .categories {
    display: inline-flex;
    align-items: center;
    width: 100%; }
    .usertopnavlayout .custom-dropdown .categories .lines {
      text-decoration: none; }
  .usertopnavlayout .down-arrow {
    float: right;
    color: #5d5d5d;
    position: absolute;
    right: 16px;
    rotate: 90deg;
    z-index: 99; }
  .usertopnavlayout .new-featured {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #181818;
    text-decoration: none; }
  .usertopnavlayout .subcategories-swatches {
    flex-direction: column;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 16px;
    scrollbar-width: none; }
  .usertopnavlayout .subcategory-image {
    width: 72px;
    height: 72px;
    gap: 0px;
    border-radius: 10px; }
  .usertopnavlayout .swatches {
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 0 16px; }
  .usertopnavlayout .subcategories-list {
    display: flex;
    flex-direction: row;
    gap: 0px;
    text-wrap: nowrap; }
  .usertopnavlayout .subcategory-swatch {
    display: flex;
    align-items: flex-start;
    padding: 0px 10px 10px 10px; }
    .usertopnavlayout .subcategory-swatch:first-child {
      padding-left: 0; }
    .usertopnavlayout .subcategory-swatch .subcategory-name-list-view {
      padding-bottom: 14px; }
  .usertopnavlayout .desktop-view a {
    max-width: 72px; }
  .usertopnavlayout .subcategory-swatch-header {
    padding: 0px 0px 24px 10px; }
    .usertopnavlayout .subcategory-swatch-header .subcategory-name-list-view {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #8B8B8B;
      padding-bottom: 0px; }
  .usertopnavlayout .subcategory-swatch a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    flex-direction: column; }
  .usertopnavlayout .subcategory-name {
    font-family: "Inter", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    white-space: normal;
    margin-top: 8px;
    margin-bottom: 16px; }
  .usertopnavlayout .first-category-name {
    margin-top: 14px;
    width: 100%; }
  .usertopnavlayout .mobile-subcategories {
    display: none;
    flex-direction: column; }
  .usertopnavlayout .shop-all {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    text-decoration: none;
    padding-top: 20px;
    padding-left: 16px;
    padding-bottom: 32px !important; }
  .usertopnavlayout .icon-arrow {
    display: inline-block;
    width: auto;
    height: auto;
    margin-left: 8px;
    margin-bottom: 1px; }
  .usertopnavlayout .subcategory-name-list-view {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    padding-left: 6px;
    padding-bottom: 4px; }
  .usertopnavlayout .grid-view {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 32px;
    gap: 30px; }
    .usertopnavlayout .grid-view .subcategory-swatch {
      padding: 0px;
      justify-content: center;
      max-width: calc(33.33% - 20px); }
  .usertopnavlayout .space {
    margin-left: 32px; } }

@media (max-width: 768.98px) {
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .close-button {
    left: 80%;
    padding: 0 12px 0 16px; }
  .usertopnavlayout .category-image {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background: #F4F4F4;
    margin: 20px 16px 20px 16px;
    padding: 0; }
  .usertopnavlayout .header-nav .main-menu .row.no-gutters {
    height: 100%; }
  .usertopnavlayout .header-nav .main-menu .navbar {
    height: 100%; }
  .usertopnavlayout .header-nav .main-menu .menu-group {
    height: 100%; }
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .nav-item .nav-link {
    padding: 0px; }
  .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group {
    padding-bottom: 0px; }
    .usertopnavlayout .main-menu.menu-toggleable-left.navbar-toggleable-md .menu-group .close-menu {
      display: none; }
  .usertopnavlayout .grid-view {
    padding-left: 0;
    padding-right: 0;
    gap: 16px; }
    .usertopnavlayout .grid-view .subcategory-swatch {
      max-width: calc(50% - 8px); }
  .usertopnavlayout .mobile-nav-wrap.grid-view .subcategories-swatches {
    margin: 0 -16px 0 0; } }

.minicart {
  position: relative; }
  .minicart .popover {
    top: 100%;
    left: auto;
    right: -1px;
    min-width: 437px;
    max-width: 437px;
    min-height: 437px;
    display: none; }
    .minicart .popover.show {
      display: block; }
  .minicart .minicart-label {
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
    text-transform: none;
    letter-spacing: 0;
    display: inline; }
  .minicart .cart {
    padding-top: 8px;
    background-color: #FFFFFF; }
  .minicart .remove-btn {
    font-family: "Inter", sans-serif;
    border: none;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 32px 16px 32px 16px;
    cursor: pointer;
    z-index: 1;
    background-color: #FFFFFF;
    padding: 4px 6px;
    height: 22px;
    width: 22px;
    margin: 5px;
    left: 0;
    right: auto; }
    .minicart .remove-btn:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 11px;
      color: inherit;
      font-weight: normal; }
    .minicart .remove-btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .minicart .remove-btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .minicart .remove-btn[class*="btn-"]:not(.title).btn-sm, .minicart .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .minicart .remove-btn[class*="btn-"]:not(.title).btn-sm:before, .minicart .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .minicart .remove-btn[class*="btn-"]:not(.title).btn-lg, .minicart .btn-group-lg > .remove-btn.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .minicart .remove-btn[class*="btn-"]:not(.title).btn-lg:before, .minicart .btn-group-lg > .remove-btn.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .minicart .remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
      .minicart .remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
      .minicart .remove-btn[class*="btn-"]:not(.title) .fa {
        display: none; }
  .minicart .product-summary {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16px;
    padding-top: 16px;
    width: 100%; }
    .minicart .product-summary .line-item-name {
      width: calc(100% - 11px - 16px/2); }
  .minicart .minicart-item + .minicart-item {
    margin-top: 8px;
    padding-top: 16px;
    border-top: 1px solid #F9F9F9;
    border-radius: 20px; }
  .minicart .minicart-item:first-child .product-line-item {
    margin-top: 0; }
  .minicart .product-line-item {
    position: relative; }
  .minicart .item-price-qty .col {
    flex-direction: column;
    align-items: flex-start; }
  .minicart .quantity,
  .minicart .quantity-stepper {
    width: 100%;
    max-width: 90px; }
  .minicart .quantity {
    min-width: 50px; }
  .minicart .line-item-promo > div {
    padding: 0 0 8px; }
  .minicart .minicart-footer {
    padding-top: 16px;
    border-top: 1px solid #F9F9F9;
    padding-left: 0;
    padding-right: 0; }
  .minicart .estimated-total {
    font-family: "Inter", sans-serif;
    font-weight: bold;
    text-transform: none; }
    .minicart .estimated-total p {
      letter-spacing: 0;
      font-size: 16px; }
      @media (min-width: 1024px) {
        .minicart .estimated-total p {
          font-size: 16px; } }

.minicart {
  position: static; }
  @media (min-width: 769px) {
    .minicart {
      position: relative; } }
  .minicart .minicart-error {
    font-size: 14px; }
    .minicart .minicart-error .valid-cart-error {
      border-radius: 0; }
  .minicart .popover {
    left: 16px;
    right: 16px;
    overflow: hidden;
    min-width: 350px;
    max-width: 100%;
    min-height: inherit; }
    @media (min-width: 769px) {
      .minicart .popover {
        right: 0;
        left: auto;
        min-width: 437px;
        max-width: 437px;
        min-height: inherit; } }
    .minicart .popover.show {
      overflow: hidden; }
    .minicart .popover .btn-add-to-cart {
      padding: 16px;
      min-height: 0;
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .minicart .popover .btn-add-to-cart {
          font-family: "Inter", sans-serif;
          font-size: 20px;
          line-height: 28px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
  .minicart .cart {
    padding-top: 24px;
    padding-bottom: 8px; }
  .minicart .remove-btn {
    left: auto;
    right: 0;
    color: #181818; }
  .minicart .line-item-header,
  .minicart .line-item-availability,
  .minicart .line-item-attributes {
    margin-bottom: 8px !important; }
  .minicart .line-item-attributes {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block; }
  .minicart .minicart-footer .leading-lines .end-lines span,
  .minicart .minicart-footer .leading-lines .start-lines span {
    background: #FFFFFF; }
  .minicart .minicart-footer a,
  .minicart .minicart-footer button {
    font-size: 16px !important;
    margin-bottom: 16px !important; }
  .minicart .minicart-footer .checkout-continue div {
    margin-bottom: 0 !important; }
  .minicart .line-item-promo {
    background: #FEF0E5;
    margin-top: 12px;
    padding: 12px;
    border-radius: 8px;
    text-align: center; }
    .minicart .line-item-promo > div {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: 0;
      color: #FF9244; }
      .minicart .line-item-promo > div p {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        padding: 0;
        margin-bottom: 0;
        color: #FF9244; }
    .minicart .line-item-promo empty-state {
      background: #FFFFFF; }
  .minicart .line-item-promo.is-empty {
    background: none;
    padding: 0; }
  .minicart .product-line-item .item-price-qty {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .minicart .product-line-item .item-attributes {
    padding-bottom: 0 !important; }
  .minicart .product-line-item .buy-with-prime-prime-day-deal-badge-text-mini-cart {
    height: auto; }
  .minicart .product-line-item .buy-with-prime-prime-day-deal-badge-sm {
    height: 24px;
    width: 130px;
    padding: 0 5px; }
  .minicart .minicart-quantity-selector .form-control-sm {
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-size: 16px;
    width: 78px;
    height: 44px;
    margin: 8px 0 0 0;
    border-radius: 100px; }
  .minicart .product-summary {
    border-top: 1px solid #F9F9F9; }
    .minicart .product-summary .line-item-name {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .minicart .product-summary .line-item-name {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .minicart .product-summary .line-item-availability,
    .minicart .product-summary .line-item-attributes {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .minicart .product-summary .line-item-availability,
        .minicart .product-summary .line-item-attributes {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
  .minicart .minicart-label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .minicart .minicart-label {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .minicart .minicart-item + .minicart-item {
    border-radius: 0 !important; }
  .minicart .product-info .item-attributes,
  .minicart .product-summary .item-attributes,
  .minicart .multi-shipping .item-attributes {
    width: calc(100% - 220px); }
  .minicart .btn.remove-product {
    display: none; }
  .minicart .swipe-up-checkout-btn {
    display: none; }
  .minicart .notavailabe-cart {
    color: #a80b1d; }
  .minicart .item-image .product-image {
    border-radius: 8px; }

.line-item-total-price {
  text-align: right;
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap;
  padding-right: 0; }
  @media (min-width: 1024px) {
    .line-item-total-price {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .product-info .line-item-total-price {
    width: 82px;
    padding-right: 0; }
  .product-summary .line-item-total-price,
  .multi-shipping .line-item-total-price {
    width: 82px; }
  .line-item-total-price .line-item-total-price-amount.discount {
    color: #a80b1d; }
  .bundled-line-item .order-product-summary .line-item-total-price {
    width: 30%; }
  .line-item-total-price .position-absolute {
    bottom: 10px;
    right: 0; }
  @media (min-width: 1024px) {
    .line-item-total-price {
      padding-right: 0; } }

.cart .prime-logo {
  margin: 0; }

.fa-back {
  width: 22px;
  height: 16px;
  background: url(../images/icons/back-arrow.svg) no-repeat center;
  background-size: contain; }

.flyout-wrapper .header-welcome-message {
  padding: 28px 16px 16px; }
  @media (max-width: 768.98px) {
    .flyout-wrapper .header-welcome-message {
      font-size: 20px;
      line-height: 28px; } }

.flyout-wrapper .empty-minicart-content {
  padding: 12px 0 16px;
  margin: 4px 32px; }
  .flyout-wrapper .empty-minicart-content h2 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .flyout-wrapper .empty-minicart-content h2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .flyout-wrapper .empty-minicart-content .single-approaching-discount {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .flyout-wrapper .empty-minicart-content .single-approaching-discount {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    margin: 0 auto; }
    .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category {
      flex: 0 0 50%; }
      @media (min-width: 544px) {
        .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category {
          flex: 0 0 calc(50% - 16px); } }
      .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category:nth-child(even) {
        padding-left: 8px; }
      .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category:nth-child(odd) {
        padding-right: 8px; }
      .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category a {
        text-decoration: none;
        display: block;
        margin-bottom: 14px; }
      .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category div {
        display: block;
        margin-bottom: 8px; }
        @media (min-width: 544px) {
          .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category div {
            height: 167px;
            width: 167px; } }
        .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category div img {
          width: 100%;
          max-width: 100%;
          border-radius: 10px; }
      .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category p {
        margin: 0;
        padding: 0;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap;
        text-align: center; }
        @media (min-width: 1024px) {
          .flyout-wrapper .empty-minicart-content .empty-cart-categories-wrapper .category p {
            font-family: "Inter", sans-serif;
            font-size: 14px;
            line-height: 20px;
            text-transform: none;
            font-weight: normal;
            letter-spacing: 0;
            font-style: normal;
            font-display: swap; } }

.flyout-wrapper .cart {
  height: 100dvh; }

.flyout-wrapper .minicart-content-wrapper,
.flyout-wrapper .empty-minicart-content-wrapper {
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column; }

.flyout-wrapper .shipping-promotion-applied .progress,
.flyout-wrapper .single-approaching-discount .progress {
  margin: 12px 0;
  position: relative;
  overflow: visible;
  height: 12px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #D4D4D4; }
  .flyout-wrapper .shipping-promotion-applied .progress-bar,
  .flyout-wrapper .single-approaching-discount .progress-bar {
    top: 0;
    left: 0;
    z-index: 2;
    margin: -1px 0;
    position: absolute;
    height: 12px;
    border-radius: 8px;
    background: #2867EC; }
  .flyout-wrapper .shipping-promotion-applied .progress.shipping-completed,
  .flyout-wrapper .single-approaching-discount .progress.shipping-completed {
    border-color: #2867EC; }
    .flyout-wrapper .shipping-promotion-applied .progress.shipping-completed .progress-bar,
    .flyout-wrapper .single-approaching-discount .progress.shipping-completed .progress-bar {
      background: #2867EC; }

.flyout-wrapper .shipping-promotion-applied p,
.flyout-wrapper .single-approaching-discount p {
  margin: 0; }

.flyout-wrapper .single-approaching-discount,
.flyout-wrapper .shipping-promotion-applied {
  font-size: 14px;
  line-height: 20px; }
  .flyout-wrapper .single-approaching-discount p,
  .flyout-wrapper .shipping-promotion-applied p {
    font-size: 14px;
    line-height: 20px; }
    @media (min-width: 769px) {
      .flyout-wrapper .single-approaching-discount p,
      .flyout-wrapper .shipping-promotion-applied p {
        font-size: 14px;
        line-height: 24px; } }
  .flyout-wrapper .single-approaching-discount:empty,
  .flyout-wrapper .shipping-promotion-applied:empty {
    border: 0;
    padding: 0;
    margin: 0;
    display: none; }

.flyout-wrapper .single-approaching-discount {
  padding: 16px 30px;
  color: #181818;
  background-color: #ECF2FD; }

.flyout-wrapper .shipping-promotion-applied {
  text-align: center;
  background-color: #ECF2FD;
  border: 1px solid #ECF2FD;
  border-radius: 20px;
  margin-bottom: 0;
  padding: 32px 16px;
  color: #181818; }
  @media (min-width: 769px) {
    .flyout-wrapper .shipping-promotion-applied {
      padding: 32px 16px 32px 16px; } }
  .flyout-wrapper .shipping-promotion-applied .congrats-msg {
    position: relative; }
    .flyout-wrapper .shipping-promotion-applied .congrats-msg .content-asset:before {
      width: 24px;
      height: 24px;
      content: '';
      display: inline-block;
      vertical-align: middle;
      background-image: url("../../images/icon-congrats.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      margin-right: 4px; }

.flyout-wrapper .product-summary {
  overflow: unset;
  max-height: inherit;
  padding: 32px 0;
  border-top: 0;
  background-color: #F9F9F9; }
  .flyout-wrapper .product-summary .minicart-quantity-selector .form-control-sm {
    padding: 12px 18px !important;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .flyout-wrapper .product-summary .minicart-quantity-selector .form-control-sm {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .flyout-wrapper .product-summary .minicart-item .product-line-item {
    padding: 0 16px; }
  .flyout-wrapper .product-summary .minicart-item + .minicart-item {
    margin-top: 32px;
    padding-top: 32px; }
  .flyout-wrapper .product-summary .minicart-item .price {
    display: flex; }
    .flyout-wrapper .product-summary .minicart-item .price .strike-through {
      order: 1; }
    .flyout-wrapper .product-summary .minicart-item .price .line-item-total-price-amount.discount {
      margin-right: 6px; }
  .flyout-wrapper .product-summary .item-image,
  .flyout-wrapper .product-summary .multi-shipping .item-image {
    background-color: #F9F9F9;
    width: 118px;
    height: 118px;
    border-radius: 4px; }
  .flyout-wrapper .product-summary .line-item-name {
    width: auto;
    text-overflow: unset;
    white-space: normal; }
  .flyout-wrapper .product-summary .remove-btn {
    top: -14px;
    right: 20px;
    padding: 0;
    margin: 8px 12px;
    background-color: #F9F9F9; }
  .flyout-wrapper .product-summary .product-edit {
    position: absolute;
    top: 36px;
    right: 17px;
    z-index: 1; }
    .flyout-wrapper .product-summary .product-edit .wishlist {
      padding: 13px 16px;
      margin: 0 !important; }
      .flyout-wrapper .product-summary .product-edit .wishlist span {
        display: none; }
  .flyout-wrapper .product-summary .line-item-attributes {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    margin-bottom: 0 !important; }
    @media (min-width: 1024px) {
      .flyout-wrapper .product-summary .line-item-attributes {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        line-height: 16px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .flyout-wrapper .product-summary .gift-wrap-toggle-container {
    flex-wrap: wrap !important;
    padding-left: 28px;
    padding-bottom: 16px; }
    .flyout-wrapper .product-summary .gift-wrap-toggle-container .gift-wrap-trigger-modal,
    .flyout-wrapper .product-summary .gift-wrap-toggle-container .gift-wrap-toggle-label {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .flyout-wrapper .product-summary .gift-wrap-toggle-container .gift-wrap-trigger-modal,
        .flyout-wrapper .product-summary .gift-wrap-toggle-container .gift-wrap-toggle-label {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .flyout-wrapper .product-summary .gift-wrap-toggle-container .custom-control-label {
      line-height: 14px; }
      .flyout-wrapper .product-summary .gift-wrap-toggle-container .custom-control-label::before {
        width: 20px;
        height: 20px;
        left: -26px; }
      .flyout-wrapper .product-summary .gift-wrap-toggle-container .custom-control-label::after {
        width: 20px;
        height: 20px;
        left: -26px; }
    .flyout-wrapper .product-summary .gift-wrap-toggle-container .custom-control-input:checked ~ .custom-control-label::before {
      width: 20px;
      height: 20px;
      left: -26px; }
    .flyout-wrapper .product-summary .gift-wrap-toggle-container .custom-control-input:checked ~ .custom-control-label::after {
      width: 20px;
      height: 20px;
      left: -26px;
      font-size: 14px;
      line-height: 20px; }

.flyout-wrapper .preorder-cart-msg {
  display: flex;
  padding: 16px 16px 0 13px;
  font-size: 15px; }
  .flyout-wrapper .preorder-cart-msg .icon::before {
    font-size: 24px;
    font-weight: 500;
    padding: 2px 5px 0 0; }

.flyout-wrapper .promo-codes.card {
  padding: 26px 0;
  border-top: 1px solid #D4D4D4;
  border-bottom: 1px solid #D4D4D4;
  border-radius: 0; }
  .flyout-wrapper .promo-codes.card .card-header,
  .flyout-wrapper .promo-codes.card .card-body,
  .flyout-wrapper .promo-codes.card .card-footer {
    padding: 0 16px !important;
    background-color: transparent;
    border: 0 !important; }
    .flyout-wrapper .promo-codes.card .card-header label,
    .flyout-wrapper .promo-codes.card .card-body label,
    .flyout-wrapper .promo-codes.card .card-footer label {
      display: none; }
    .flyout-wrapper .promo-codes.card .card-header .btn,
    .flyout-wrapper .promo-codes.card .card-body .btn,
    .flyout-wrapper .promo-codes.card .card-footer .btn {
      padding: 8px 16px !important; }
  .flyout-wrapper .promo-codes.card .card-body {
    padding: 8px 16px 12px !important; }
    .flyout-wrapper .promo-codes.card .card-body .form-group .row {
      flex-wrap: nowrap;
      align-items: flex-start; }
    .flyout-wrapper .promo-codes.card .card-body .col-md-12 {
      flex: auto;
      flex-grow: 1;
      max-width: inherit;
      width: auto;
      padding-right: 0 !important; }
    .flyout-wrapper .promo-codes.card .card-body .promo-code-submit {
      flex: inherit;
      width: inherit;
      max-width: inherit;
      padding-left: 8px;
      padding-right: 16px;
      margin-top: 0 !important; }
  .flyout-wrapper .promo-codes.card .card-footer {
    border: 0; }
    .flyout-wrapper .promo-codes.card .card-footer .promotion-information {
      display: none; }
  .flyout-wrapper .promo-codes.card.collapsible-xl .title {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .flyout-wrapper .promo-codes.card.collapsible-xl .title {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .flyout-wrapper .promo-codes.card.collapsible-xl .title::after {
      top: 0;
      right: 7px; }
  .flyout-wrapper .promo-codes.card .leading-lines .start-lines span,
  .flyout-wrapper .promo-codes.card .leading-lines .end-lines span {
    background-color: transparent; }

.flyout-wrapper .card-header.cart-order-summmary {
  display: none; }

.flyout-wrapper .card.totals {
  padding: 8px 16px; }
  @media (min-width: 769px) {
    .flyout-wrapper .card.totals {
      padding: 8px 16px 0; } }
  .flyout-wrapper .card.totals.card-total-emea {
    padding-top: 16px; }
  .flyout-wrapper .card.totals .card-body {
    padding: 0; }
  .flyout-wrapper .card.totals .leading-lines .start-lines span,
  .flyout-wrapper .card.totals .leading-lines .end-lines span {
    background-color: transparent;
    color: #181818; }
  .flyout-wrapper .card.totals .shipping-method,
  .flyout-wrapper .card.totals .shipping-country-selection {
    margin-bottom: 20px; }
    .flyout-wrapper .card.totals .shipping-method .shipping-method-section-label,
    .flyout-wrapper .card.totals .shipping-country-selection .shipping-method-section-label {
      margin-bottom: 8px; }
    .flyout-wrapper .card.totals .shipping-method .custom-select, .flyout-wrapper .card.totals .shipping-method select.form-control,
    .flyout-wrapper .card.totals .shipping-method select,
    .flyout-wrapper .card.totals .shipping-method select.form-control,
    .flyout-wrapper .card.totals .shipping-method select,
    .flyout-wrapper .card.totals .shipping-country-selection .custom-select,
    .flyout-wrapper .card.totals .shipping-country-selection select.form-control,
    .flyout-wrapper .card.totals .shipping-country-selection select,
    .flyout-wrapper .card.totals .shipping-country-selection select.form-control,
    .flyout-wrapper .card.totals .shipping-country-selection select {
      border-width: 1px !important;
      line-height: 24px;
      padding: 10px 30px 10px 10px !important;
      background-image: url(../images/icons/icon-arrow-down.svg);
      background-size: 12px; }
  .flyout-wrapper .card.totals .shipping-country-selection {
    display: none; }
  .flyout-wrapper .card.totals .row {
    height: auto; }
    .flyout-wrapper .card.totals .row.estimated-total {
      margin-bottom: 20px; }
  .flyout-wrapper .card.totals p.cart-sub-total {
    margin-bottom: 8px; }
  .flyout-wrapper .card.totals .line-item-divider {
    margin-top: 2px;
    margin-bottom: 18px; }

.flyout-wrapper .shipping-promotion-applied {
  padding: 16px 30px;
  background-color: #ECF2FD;
  border-radius: 0; }
  .flyout-wrapper .shipping-promotion-applied .congrats-msg {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    text-align: center;
    margin-bottom: 12px; }
    @media (min-width: 1024px) {
      .flyout-wrapper .shipping-promotion-applied .congrats-msg {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

.flyout-wrapper .product-addons {
  padding: 0 16px;
  margin-bottom: 0;
  background-color: #F9F9F9; }
  .flyout-wrapper .product-addons .non-input-label {
    padding: 0 0 12px 0;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .flyout-wrapper .product-addons .non-input-label {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .flyout-wrapper .product-addons .product {
    margin-bottom: 12px; }
    .flyout-wrapper .product-addons .product:last-child {
      margin-bottom: 0; }
  .flyout-wrapper .product-addons .items {
    border: 0;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 32px;
    padding: 16px; }
  .flyout-wrapper .product-addons .product-tile {
    padding: 0; }
    .flyout-wrapper .product-addons .product-tile .tile-image-wrap {
      margin-bottom: 0; }
    .flyout-wrapper .product-addons .product-tile img {
      height: 96px;
      width: 96px;
      background-color: #F9F9F9;
      border-radius: 4px; }
    .flyout-wrapper .product-addons .product-tile > div {
      flex-basis: 55%; }
      @media (max-width: 768.98px) {
        .flyout-wrapper .product-addons .product-tile > div {
          flex-basis: 46%; } }
      .flyout-wrapper .product-addons .product-tile > div .pdp-link {
        padding: 0 8px; }
      .flyout-wrapper .product-addons .product-tile > div.product-tile--buy-container {
        flex-basis: 22%; }
        @media (max-width: 768.98px) {
          .flyout-wrapper .product-addons .product-tile > div.product-tile--buy-container {
            flex-basis: 26%; } }
    .flyout-wrapper .product-addons .product-tile .link {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .flyout-wrapper .product-addons .product-tile .link {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .flyout-wrapper .product-addons .product-tile .price {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      margin: 0;
      padding: 0 8px;
      justify-content: flex-start; }
      @media (min-width: 1024px) {
        .flyout-wrapper .product-addons .product-tile .price {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
      .flyout-wrapper .product-addons .product-tile .price > span {
        display: flex; }
        .flyout-wrapper .product-addons .product-tile .price > span del {
          order: 1; }
          .flyout-wrapper .product-addons .product-tile .price > span del ~ .sales {
            float: none;
            margin-right: 4px; }
    .flyout-wrapper .product-addons .product-tile::before {
      display: none; }
    .flyout-wrapper .product-addons .product-tile .btn-addon-to-cart {
      padding: 8px 16px;
      display: flex;
      justify-content: space-around;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .flyout-wrapper .product-addons .product-tile .btn-addon-to-cart {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }

.flyout-wrapper .minicart-footer {
  border-top: 0;
  box-shadow: 0px -18px 18px 0px rgba(142, 142, 142, 0.12);
  background-color: #FFFFFF;
  transition: bottom 0.3s ease-in-out; }
  @media (max-width: 768.98px) {
    .flyout-wrapper .minicart-footer {
      padding-bottom: 8px; } }
  @media (min-width: 769px) {
    .flyout-wrapper .minicart-footer {
      padding-bottom: 18px; } }
  .flyout-wrapper .minicart-footer.swipe-up {
    position: relative;
    z-index: 4; }
    .flyout-wrapper .minicart-footer.swipe-up .cartAdditionalPaymentButtons,
    .flyout-wrapper .minicart-footer.swipe-up .buy-with-prime-wrapper {
      position: static;
      top: 0; }
  .flyout-wrapper .minicart-footer .btn-add-to-cart,
  .flyout-wrapper .minicart-footer .btn.add-to-cart,
  .flyout-wrapper .minicart-footer .btn.add-to-cart-global {
    color: #FFFFFF;
    margin-bottom: 8px !important; }
    .flyout-wrapper .minicart-footer .btn-add-to-cart::after,
    .flyout-wrapper .minicart-footer .btn.add-to-cart::after,
    .flyout-wrapper .minicart-footer .btn.add-to-cart-global::after {
      background-color: #181818; }
    .flyout-wrapper .minicart-footer .btn-add-to-cart.disabled::after,
    .flyout-wrapper .minicart-footer .btn.add-to-cart.disabled::after,
    .flyout-wrapper .minicart-footer .btn.add-to-cart-global.disabled::after {
      background-color: #D4D4D4; }
  .flyout-wrapper .minicart-footer .cartAdditionalPaymentButtons {
    margin-top: 16px;
    position: absolute;
    top: 110%; }
    .flyout-wrapper .minicart-footer .cartAdditionalPaymentButtons .js-paymentmethod-mutationobserver {
      margin-top: 0 !important; }
      .flyout-wrapper .minicart-footer .cartAdditionalPaymentButtons .js-paymentmethod-mutationobserver .paypalLoader.paypal-loader-container {
        display: none !important; }
  .flyout-wrapper .minicart-footer .buy-with-prime-wrapper {
    margin-top: 8px;
    position: absolute;
    top: 110%; }
    .flyout-wrapper .minicart-footer .buy-with-prime-wrapper .btn-bwp {
      margin-bottom: 0 !important; }
  .flyout-wrapper .minicart-footer .checkout-continue .swipe-up-minicart-btn {
    position: absolute;
    background-color: transparent;
    color: transparent;
    left: 0;
    width: 100%;
    padding: 16px;
    z-index: 2;
    display: inline; }
  .flyout-wrapper .minicart-footer .checkout-continue .cross-sell-btn {
    position: absolute;
    top: 0;
    z-index: 1;
    display: block;
    width: 93%;
    height: 56px;
    text-align: center; }
    .flyout-wrapper .minicart-footer .checkout-continue .cross-sell-btn .quickview {
      display: block;
      width: 100%;
      height: 56px;
      margin: 0 !important;
      text-indent: -99999px;
      overflow: hidden; }
  .flyout-wrapper .minicart-footer .checkout-btn.position-relative {
    pointer-events: none; }
    .flyout-wrapper .minicart-footer .checkout-btn.position-relative .veil .underlay {
      border-radius: 32px;
      opacity: 1; }
    .flyout-wrapper .minicart-footer .checkout-btn.position-relative .veil .spinner {
      height: 20px;
      width: 20px;
      margin-top: -20px;
      margin-left: -20px; }

.minicart-swipe-up-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.6);
  z-index: 3;
  display: none; }

.modal {
  z-index: 1050; }
  .modal .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 20px;
    box-shadow: 0 2px 6px rgba(34, 34, 34, 0.1); }
  .modal .modal-header {
    padding: 32px 32px 16px;
    background-color: #FFFFFF;
    color: #181818;
    border-bottom-color: transparent;
    border-top-left-radius: calc(20px - 1px);
    border-top-right-radius: calc(20px - 1px); }
    .modal .modal-header .modal-title {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      letter-spacing: 0;
      font-weight: normal;
      font-style: normal; }
    .modal .modal-header .close {
      line-height: 75%;
      margin: 0;
      padding: 0;
      text-shadow: none;
      opacity: 1; }
      .modal .modal-header .close:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 14px;
        color: #181818;
        font-weight: normal; }
      .modal .modal-header .close[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .modal .modal-header .close[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .modal .modal-header .close[class*="btn-"]:not(.title).btn-sm, .modal .modal-header .btn-group-sm > .close.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .modal .modal-header .close[class*="btn-"]:not(.title).btn-sm:before, .modal .modal-header .btn-group-sm > .close.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .modal .modal-header .close[class*="btn-"]:not(.title).btn-lg, .modal .modal-header .btn-group-lg > .close.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .modal .modal-header .close[class*="btn-"]:not(.title).btn-lg:before, .modal .modal-header .btn-group-lg > .close.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .modal .modal-header .close[class*="btn-"]:not(.title) [class*="icon-"],
        .modal .modal-header .close[class*="btn-"]:not(.title) [class^="icon-"],
        .modal .modal-header .close[class*="btn-"]:not(.title) .fa {
          display: none; }
      .modal .modal-header .close [aria-hidden=true] {
        display: none; }
    .modal .modal-header a {
      color: #181818; }
  .modal .modal-body {
    flex: 0 0 auto;
    padding: 16px 32px;
    color: #181818;
    background-color: #FFFFFF; }
  .modal .modal-footer {
    padding: 16px 32px 32px;
    color: #181818;
    background-color: #FFFFFF;
    border-top-color: transparent;
    border-bottom-left-radius: calc(20px - 1px);
    border-bottom-right-radius: calc(20px - 1px); }
    .modal .modal-footer .button-wrapper .btn + .btn {
      margin-left: 16px; }
    .modal .modal-footer > .btn + .btn {
      margin-left: 16px; }
  .modal.show ~ .modal.show {
    z-index: 1052; }

.modal-backdrop {
  background-color: rgba(34, 34, 34, 0.6); }
  .modal-backdrop.show {
    opacity: 1;
    z-index: 1000; }
    .modal-backdrop.show ~ .modal-backdrop.show {
      z-index: 1051; }

.modal-dialog {
  max-width: none;
  margin: 8px;
  max-width: 500px; }
  @media (min-width: 544px) {
    .modal-dialog {
      margin: 32px auto; } }

.modal-sm {
  max-width: none;
  margin: 8px;
  max-width: 500px; }
  @media (min-width: 544px) {
    .modal-sm {
      margin: 32px auto; } }
  @media (min-width: 544px) {
    .modal-sm {
      max-width: 300px; } }

.modal-lg {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .modal-lg {
      max-width: 900px;
      margin: 32px auto; } }

.modal-xl {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .modal-xl {
      max-width: 900px;
      margin: 32px auto; } }
  @media (min-width: 1440px) {
    .modal-xl {
      max-width: 1140px; } }

.modal-scrollbody {
  overflow: hidden; }
  .modal-scrollbody .modal-body {
    overflow-x: hidden;
    overflow-y: auto; }

.modal .modal-header {
  position: relative; }
  .modal .modal-header .close {
    position: absolute;
    left: auto;
    right: 25px;
    top: 20px;
    line-height: inherit; }

.modal .notify-me-modal-dialog {
  top: 25%; }
  .modal .notify-me-modal-dialog button.notify-me-btn {
    width: 100%;
    font-family: "Inter", sans-serif;
    padding: 16px 32px 16px 32px; }
    .modal .notify-me-modal-dialog button.notify-me-btn:after {
      background-color: #181818; }
    .modal .notify-me-modal-dialog button.notify-me-btn:hover, .modal .notify-me-modal-dialog button.notify-me-btn:focus, .modal .notify-me-modal-dialog button.notify-me-btn[aria-describedby*="popover"] {
      text-decoration: none; }
      .modal .notify-me-modal-dialog button.notify-me-btn:hover:after, .modal .notify-me-modal-dialog button.notify-me-btn:focus:after, .modal .notify-me-modal-dialog button.notify-me-btn[aria-describedby*="popover"]:after {
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        top: -4px;
        left: -4px; }
    .dark-theme .modal .notify-me-modal-dialog button.notify-me-btn {
      color: #FFFFFF; }
      .dark-theme .modal .notify-me-modal-dialog button.notify-me-btn:after {
        background-color: #181818; }
      .dark-theme .modal .notify-me-modal-dialog button.notify-me-btn:hover {
        color: #181818; }
    .modal .notify-me-modal-dialog button.notify-me-btn .icon-mail {
      background: url(../images/icons/email-icon.svg) no-repeat center;
      background-size: contain;
      display: inline-block;
      width: 22px;
      height: 18px;
      margin-right: 6px;
      vertical-align: text-top; }
  .modal .notify-me-modal-dialog .notify-submit-btn {
    top: 16px;
    height: 48px; }
    .modal .notify-me-modal-dialog .notify-submit-btn::after {
      left: auto;
      right: 0; }
    .modal .notify-me-modal-dialog .notify-submit-btn:hover::after {
      right: -1px; }

.modal-content .wrapper {
  display: inline-flex;
  background: #FEF0E5;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100%; }
  @media (max-width: 1023.98px) {
    .modal-content .wrapper {
      margin-top: 24px; } }
  .modal-content .wrapper .timer-logo {
    width: 13.33px;
    height: 13.33px;
    color: #FF9244;
    margin-right: 4px; }
  .modal-content .wrapper .timer-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: #FF9244;
    margin-right: 8px; }
  .modal-content .wrapper .timer-wrapper {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center; }

.modal-content .notify-me-section {
  font-family: "Inter", sans-serif;
  text-align: center; }
  .modal-content .notify-me-section .pdp-email-product-notify input {
    border-color: #595959;
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
    .modal-content .notify-me-section .pdp-email-product-notify input:hover, .modal-content .notify-me-section .pdp-email-product-notify input:focus {
      box-shadow: none;
      border-color: #595959; }
  .modal-content .notify-me-section .pdp-email-product-notify .oosNotifyMeEmail {
    border-radius: 5px;
    border: 1px solid #181818;
    line-height: 19px; }
  .modal-content .notify-me-section .pdp-email-product-notify .notify-me-submit {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none; }
  .modal-content .notify-me-section .notify-me-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #181818; }
  .modal-content .notify-me-section .notify-modal-subheader {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #181818; }

.modal-content .notify-me-footer {
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #5d5d5d;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none; }

.modal-content .modal-footer .quantity select#quantity-1 {
  padding: 16px 16px 16px 48px; }
  @media (max-width: 768.98px) {
    .modal-content .modal-footer .quantity select#quantity-1 {
      padding: 16px 16px 16px 28px; } }

.modal-content .modal-footer .notify-me-continue-shopping {
  height: 48px;
  line-height: 19px; }

.notify {
  position: fixed;
  top: 0; }

.page-header {
  padding-top: 16px;
  padding-bottom: 16px; }
  @media (min-width: 769px) {
    .page-header {
      padding-top: 10px;
      padding-bottom: 18px; } }
  .page-header.blog-detail-header .page-header-title {
    font-family: "Space Grotesk", sans-serif;
    font-size: 24px;
    line-height: 36px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal; }
  .page-header.search-results-header .page-header-title {
    font-family: "Space Grotesk", sans-serif;
    font-size: 24px;
    line-height: 36px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    margin-top: 32px; }

.hero-slider-container + .page-header .page-header-title, .hero-slot + .page-header .page-header-title {
  display: none; }

.page-header .page-title {
  margin-top: 32px;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 32px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .page-header .page-title {
      font-family: "Inter", sans-serif;
      font-size: 32px;
      line-height: 40px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

.page-header {
  padding-bottom: 24px; }
  @media (min-width: 1024px) {
    .page-header .breadcrumb-wrapper {
      padding-bottom: 8px;
      padding-top: 22px; } }
  .page-header.search-results-header .charity-amount-raised {
    color: #76CBB4;
    display: block; }

@media (max-width: 1023.98px) {
  #maincontent .page-designer .page-header.search-results-header {
    padding-top: 0;
    padding-bottom: 0; } }

.search-results-header > .experience-region {
  margin-top: -16px;
  margin-right: -16px;
  margin-left: -16px; }
  @media (min-width: 769px) {
    .search-results-header > .experience-region {
      margin-top: -10px; } }

.plp .breadcrumb-wrapper {
  padding: 16px 0 0; }
  @media (min-width: 1024px) {
    .plp .breadcrumb-wrapper {
      padding: 32px 0; } }

.plp header.page-header.search-results-header .breadcrumb-wrapper {
  padding: 16px 16px 0; }
  @media (min-width: 1024px) {
    .plp header.page-header.search-results-header .breadcrumb-wrapper {
      padding: 16px 48px 0; } }

.search-result-padding {
  padding-top: 78px; }
  @media (max-width: 1199.98px) {
    .search-result-padding {
      padding-top: 24px;
      display: flex;
      justify-content: center; } }

.photo-hotspots {
  position: relative; }
  .photo-hotspots .hotspot {
    position: absolute;
    width: 24px;
    height: 24px;
    background: rgba(217, 217, 217, 0.7);
    border-radius: 50%;
    margin-top: -12px;
    margin-left: -12px;
    box-shadow: none;
    transition: all 200ms ease-out;
    text-decoration: none; }
    .photo-hotspots .hotspot:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: #FFFFFF;
      font-weight: normal; }
    .photo-hotspots .hotspot[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-sm, .photo-hotspots .btn-group-sm > .hotspot.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-sm:before, .photo-hotspots .btn-group-sm > .hotspot.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-lg, .photo-hotspots .btn-group-lg > .hotspot.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-lg:before, .photo-hotspots .btn-group-lg > .hotspot.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) [class*="icon-"],
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) [class^="icon-"],
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) .fa {
        display: none; }
    .photo-hotspots .hotspot::before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      width: 10px; }
    .photo-hotspots .hotspot .hotspot-border-inner,
    .photo-hotspots .hotspot .hotspot-border-outer {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      border-radius: 50%;
      transition: all 200ms ease-out; }
    .photo-hotspots .hotspot .hotspot-border-inner {
      font-size: 10px;
      line-height: 24px;
      width: 28px;
      height: 28px;
      border: 2px solid transparent;
      z-index: 1; }
    .photo-hotspots .hotspot .hotspot-border-outer {
      content: '';
      width: 32px;
      height: 32px;
      border: 2px solid #FFFFFF; }
    .photo-hotspots .hotspot:hover {
      width: 40px;
      height: 40px;
      margin-top: -20px;
      margin-left: -20px; }
      .photo-hotspots .hotspot:hover .hotspot-border-inner {
        line-height: 40px;
        width: 46px;
        height: 46px;
        border: 3px solid transparent; }
      .photo-hotspots .hotspot:hover .hotspot-border-outer {
        width: 52px;
        height: 52px;
        border: 3px solid #FFFFFF; }
    .photo-hotspots .hotspot.dark {
      background: rgba(34, 34, 34, 0.3); }
      .photo-hotspots .hotspot.dark::before {
        color: #222222; }
      .photo-hotspots .hotspot.dark .hotspot-border-inner {
        border-color: transparent; }
      .photo-hotspots .hotspot.dark .hotspot-border-outer {
        border-color: #222222; }
      .photo-hotspots .hotspot.dark:hover .hotspot-border-inner {
        border-color: transparent; }
      .photo-hotspots .hotspot.dark:hover .hotspot-border-outer {
        border-color: #222222; }

.photo-hotspots-tooltip.tooltip {
  z-index: 999; }
  .photo-hotspots-tooltip.tooltip .tooltip-inner {
    padding: 0;
    max-width: 300px; }

.photo-hotspots-tooltip-text {
  text-align: left;
  padding: 8px; }
  .photo-hotspots-tooltip-text > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .photo-hotspots-tooltip-text + .secondary-text {
    padding-top: 0; }
  .photo-hotspots-tooltip-text + .photo-hotspots-tooltip-product {
    border-top: 1px solid #D4D4D4; }

.photo-hotspots-tooltip-product {
  text-decoration: none; }
  .photo-hotspots-tooltip-product:hover {
    text-decoration: none; }
  .photo-hotspots-tooltip-product .product-image {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat; }
  .photo-hotspots-tooltip-product .product-attributes {
    max-width: calc(300px - 75px - 5px);
    padding: 16px;
    text-align: left; }
  .photo-hotspots-tooltip-product .product-name {
    font-size: 16px;
    color: #222222;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .photo-hotspots-tooltip-product .product-price {
    font-size: 16px;
    color: #595959;
    margin-top: 8px; }
    .photo-hotspots-tooltip-product .product-price .strike-through {
      margin-right: 5px; }
    .photo-hotspots-tooltip-product .product-price .sales {
      color: #181818; }
  .photo-hotspots-tooltip-product .product-message {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    color: #595959;
    margin-top: 8px; }

.photo-hotspots {
  overflow: hidden;
  border-radius: 20px; }
  .photo-hotspots .hotspot {
    background: none;
    background-image: url("../../images/icons/icon-plus.svg");
    background-repeat: no-repeat;
    background-size: 100%; }
    .photo-hotspots .hotspot:before {
      display: none; }
    .photo-hotspots .hotspot .hotspot-border-inner,
    .photo-hotspots .hotspot .hotspot-border-outer {
      transition: none; }
    .photo-hotspots .hotspot .hotspot-border-inner {
      border: 0; }
    .photo-hotspots .hotspot .hotspot-border-outer {
      content: '';
      margin-top: -16px;
      margin-right: -16px;
      animation: shopTheLookDotKeyframe 1.5s ease-in-out infinite; }
    .photo-hotspots .hotspot:hover {
      width: 24px;
      height: 24px;
      margin-top: -12px;
      margin-left: -12px; }
      .photo-hotspots .hotspot:hover .hotspot-border-inner {
        line-height: 24px;
        width: 28px;
        height: 28px;
        border: 2px solid transparent; }
      .photo-hotspots .hotspot:hover .hotspot-border-outer {
        width: 32px;
        height: 32px;
        border: 2px solid #FFFFFF; }
  .photo-hotspots.aspect-ratio-square .photo-tile-figure::after {
    display: block;
    content: "";
    clear: both; }
  .photo-hotspots.aspect-ratio-square .photo-tile-figure::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .photo-hotspots.aspect-ratio-square .photo-tile-figure img.photo-tile-image {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto; }
  .photo-hotspots.aspect-ratio-landscape .photo-tile-figure::after {
    display: block;
    content: "";
    clear: both; }
  .photo-hotspots.aspect-ratio-landscape .photo-tile-figure::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .photo-hotspots.aspect-ratio-landscape .photo-tile-figure img.photo-tile-image {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto; }
  .photo-hotspots.aspect-ratio-portrait .photo-tile-figure::after {
    display: block;
    content: "";
    clear: both; }
  .photo-hotspots.aspect-ratio-portrait .photo-tile-figure::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .photo-hotspots.aspect-ratio-portrait .photo-tile-figure::before {
    padding-top: calc(4 / 3.2 * 100%); }
  .photo-hotspots.aspect-ratio-portrait .photo-tile-figure img.photo-tile-image {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto; }

@keyframes shopTheLookDotKeyframe {
  0% {
    opacity: 0.2;
    transform: scale(0.8); }
  50% {
    opacity: 0.4; }
  75% {
    opacity: 0.6; }
  100% {
    opacity: 0;
    transform: scale(1.3); } }

.photo-hotspots-tooltip.tooltip {
  margin-bottom: 16px; }

.photo-hotspots-tooltip-text {
  text-align: left;
  padding: 8px; }

.photo-hotspots-tooltip-product {
  text-decoration: none;
  display: block !important;
  padding: 16px; }
  @media (min-width: 1024px) {
    .photo-hotspots-tooltip-product {
      padding: 32px; } }
  .photo-hotspots-tooltip-product .product-image {
    margin: 0 auto 16px; }
  .photo-hotspots-tooltip-product .product-attributes {
    padding: 0; }

.gallery-section {
  padding-top: 64px; }
  .gallery-section .head {
    padding: 0 16px; }
  .gallery-section h2 {
    margin: 0 0 24px;
    font-family: "Space Grotesk", sans-serif;
    font-size: 72px;
    line-height: 80px;
    text-transform: none; }
    @media (min-width: 1024px) {
      .gallery-section h2 {
        margin: 0 0 8px;
        font-family: "Space Grotesk", sans-serif;
        font-size: 128px;
        line-height: 140px;
        text-transform: none; } }
  .gallery-section P {
    margin: 0; }

.shop-the-look-container .photo-hotspots {
  border-radius: 8px; }

.blogs-grid .tab-section.new-category-tabs {
  padding: 0; }
  .blogs-grid .tab-section.new-category-tabs .nav {
    padding: 0; }

.blogs-grid .blog-card img {
  display: block;
  overflow: hidden;
  width: 100%;
  height: auto;
  border-radius: 24px;
  margin-bottom: 16px; }

.blogs-grid .blog-card h5 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .blogs-grid .blog-card h5 {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.blogs-grid .blog-card .card-text p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; }

.popover {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  background-color: #FFFFFF;
  border-color: transparent;
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.1);
  border-radius: 20px;
  max-width: 300px; }
  .popover .popover-header {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    background-color: #595959;
    color: #FFFFFF;
    border-top-left-radius: calc(20px - 1px);
    border-top-right-radius: calc(20px - 1px); }
  .popover .popover-body {
    padding: 16px;
    font-size: 12px; }
    .popover .popover-body p {
      font-size: 12px; }
    .popover .popover-body ul:only-child {
      margin-bottom: 0; }
  .popover.bs-popover-bottom .popover-header:before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
    display: none; }
  .popover.bs-popover-bottom .arrow:after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after {
    border-bottom-color: transparent; }

.product-detail .price,
.quick-view-dialog .price {
  color: #181818; }
  .product-detail .price .strike-through,
  .quick-view-dialog .price .strike-through {
    text-decoration: line-through;
    color: #181818; }
  .product-detail .price del ~ .sales,
  .quick-view-dialog .price del ~ .sales {
    float: left;
    margin-right: 16px; }
  .product-detail .price .tiered,
  .quick-view-dialog .price .tiered {
    color: #181818; }
    .product-detail .price .tiered table .table-header,
    .quick-view-dialog .price .tiered table .table-header {
      display: none; }
    .product-detail .price .tiered td,
    .product-detail .price .tiered span.price,
    .quick-view-dialog .price .tiered td,
    .quick-view-dialog .price .tiered span.price {
      font-size: paragraph-font-size(small);
      font-weight: bold; }
    .product-detail .price .tiered td,
    .quick-view-dialog .price .tiered td {
      padding: 8px; }
      .product-detail .price .tiered td.quantity,
      .quick-view-dialog .price .tiered td.quantity {
        font-weight: normal;
        text-align: right; }
      .product-detail .price .tiered td.value,
      .quick-view-dialog .price .tiered td.value {
        text-align: left; }
    .product-detail .price .tiered .table-header,
    .quick-view-dialog .price .tiered .table-header {
      padding: 8px; }
    .product-detail .price .tiered .column-header,
    .quick-view-dialog .price .tiered .column-header {
      padding: 8px;
      font-weight: normal; }
    .product-detail .price .tiered .column-header.quantity,
    .quick-view-dialog .price .tiered .column-header.quantity {
      text-align: right; }

.product-detail .price,
.quick-view-dialog .price {
  color: #181818; }
  .product-detail .price .strike-through,
  .quick-view-dialog .price .strike-through {
    text-decoration: line-through;
    color: #181818; }
  .product-detail .price .sale-price,
  .quick-view-dialog .price .sale-price {
    color: #a80b1d; }

.product-info .product-line-item + .product-line-item,
.product-info .product-line-item + .bonus-product-line-item,
.product-info .product-line-item + .shipment-block,
.product-info .bonus-product-line-item + .product-line-item,
.product-info .bonus-product-line-item + .bonus-product-line-item,
.product-info .bonus-product-line-item + .shipment-block,
.product-info .multi-shipping + .product-line-item,
.product-info .multi-shipping + .bonus-product-line-item,
.product-info .multi-shipping + .shipment-block,
.product-info .shipment-block + .product-line-item,
.product-info .shipment-block + .bonus-product-line-item,
.product-info .shipment-block + .shipment-block,
.product-summary .product-line-item + .product-line-item,
.product-summary .product-line-item + .bonus-product-line-item,
.product-summary .product-line-item + .shipment-block,
.product-summary .bonus-product-line-item + .product-line-item,
.product-summary .bonus-product-line-item + .bonus-product-line-item,
.product-summary .bonus-product-line-item + .shipment-block,
.product-summary .multi-shipping + .product-line-item,
.product-summary .multi-shipping + .bonus-product-line-item,
.product-summary .multi-shipping + .shipment-block,
.product-summary .shipment-block + .product-line-item,
.product-summary .shipment-block + .bonus-product-line-item,
.product-summary .shipment-block + .shipment-block {
  margin-top: 8px;
  padding-top: 16px;
  border-top: 1px solid #F9F9F9;
  border-radius: 20px; }

.product-info .bonus-product-line-item + .product-line-item,
.product-info .bonus-product-line-item + .bonus-product-line-item,
.product-summary .bonus-product-line-item + .product-line-item,
.product-summary .bonus-product-line-item + .bonus-product-line-item {
  border-top: none; }

.product-info .shipment-block + .shipment-block,
.product-summary .shipment-block + .shipment-block {
  margin-top: 16px;
  padding-top: 16px; }

.order-product-summary .product-info, .order-product-summary
.product-summary, .order-product-summary
.multi-shipping {
  padding: 0;
  margin: 0; }
  .order-product-summary .product-info h3:not(:first-child), .order-product-summary
  .product-summary h3:not(:first-child), .order-product-summary
  .multi-shipping h3:not(:first-child) {
    margin-top: 16px; }

.product-info .product-summary-block > .card,
.product-summary .product-summary-block > .card,
.multi-shipping .product-summary-block > .card {
  border: none; }
  .product-info .product-summary-block > .card .card-body,
  .product-summary .product-summary-block > .card .card-body,
  .multi-shipping .product-summary-block > .card .card-body {
    padding: 0; }

.product-info .item-image,
.product-summary .item-image,
.multi-shipping .item-image {
  width: 100px;
  margin-right: 16px;
  flex-grow: 0;
  flex-shrink: 0; }
  .product-info .item-image img.product-image,
  .product-summary .item-image img.product-image,
  .multi-shipping .item-image img.product-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto; }

.product-info .line-item-name,
.product-summary .line-item-name,
.multi-shipping .line-item-name {
  display: block;
  font-family: "Inter", sans-serif;
  letter-spacing: 0;
  font-size: 16px;
  text-transform: none;
  font-weight: normal;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 16px); }
  @media (min-width: 1024px) {
    .product-info .line-item-name,
    .product-summary .line-item-name,
    .multi-shipping .line-item-name {
      font-size: 16px; } }
  @media (min-width: 769px) {
    .product-info .line-item-name,
    .product-summary .line-item-name,
    .multi-shipping .line-item-name {
      width: calc(100% - 16px); } }

.product-info .item-attributes,
.product-summary .item-attributes,
.multi-shipping .item-attributes {
  color: #000000;
  padding: 0 0 8px;
  width: calc(100% - 100px); }

.product-info .item-attributes,
.product-info .item-attributes p,
.product-info .item-price-qty,
.product-info .line-item-availability,
.product-summary .item-attributes,
.product-summary .item-attributes p,
.product-summary .item-price-qty,
.product-summary .line-item-availability,
.multi-shipping .item-attributes,
.multi-shipping .item-attributes p,
.multi-shipping .item-price-qty,
.multi-shipping .line-item-availability {
  font-size: 16px;
  margin: 0; }

.product-info .line-item-availability .line-item-attributes,
.product-summary .line-item-availability .line-item-attributes,
.multi-shipping .line-item-availability .line-item-attributes {
  display: inline; }
  .product-info .line-item-availability .line-item-attributes:first-child:after,
  .product-summary .line-item-availability .line-item-attributes:first-child:after,
  .multi-shipping .line-item-availability .line-item-attributes:first-child:after {
    content: ': '; }

.product-info .line-item-pricing-info,
.product-summary .line-item-pricing-info,
.multi-shipping .line-item-pricing-info {
  font-size: 16px;
  margin-bottom: 0; }

.product-info .item-price-qty,
.product-info .product-card-footer,
.product-summary .item-price-qty,
.product-summary .product-card-footer,
.multi-shipping .item-price-qty,
.multi-shipping .product-card-footer {
  padding-top: 8px;
  padding-bottom: 8px; }
  .product-info .item-price-qty.row > .col,
  .product-info .product-card-footer.row > .col,
  .product-summary .item-price-qty.row > .col,
  .product-summary .product-card-footer.row > .col,
  .multi-shipping .item-price-qty.row > .col,
  .multi-shipping .product-card-footer.row > .col {
    padding: 0; }
  .product-info .item-price-qty .line-item-unit-price + .line-item-quantity,
  .product-info .item-price-qty .col-4 + .line-item-quantity,
  .product-info .product-card-footer .line-item-unit-price + .line-item-quantity,
  .product-info .product-card-footer .col-4 + .line-item-quantity,
  .product-summary .item-price-qty .line-item-unit-price + .line-item-quantity,
  .product-summary .item-price-qty .col-4 + .line-item-quantity,
  .product-summary .product-card-footer .line-item-unit-price + .line-item-quantity,
  .product-summary .product-card-footer .col-4 + .line-item-quantity,
  .multi-shipping .item-price-qty .line-item-unit-price + .line-item-quantity,
  .multi-shipping .item-price-qty .col-4 + .line-item-quantity,
  .multi-shipping .product-card-footer .line-item-unit-price + .line-item-quantity,
  .multi-shipping .product-card-footer .col-4 + .line-item-quantity {
    align-items: center;
    text-align: center; }
  .product-info .item-price-qty .line-item-total-price,
  .product-info .product-card-footer .line-item-total-price,
  .product-summary .item-price-qty .line-item-total-price,
  .product-summary .product-card-footer .line-item-total-price,
  .multi-shipping .item-price-qty .line-item-total-price,
  .multi-shipping .product-card-footer .line-item-total-price {
    align-items: flex-end;
    text-align: right; }

.product-info .quantity-label,
.product-summary .quantity-label,
.multi-shipping .quantity-label {
  display: none; }

.product-info del,
.product-info .strike-through,
.product-summary del,
.product-summary .strike-through,
.multi-shipping del,
.multi-shipping .strike-through {
  text-decoration: line-through;
  color: #181818; }
  .product-info del .value,
  .product-info .strike-through .value,
  .product-summary del .value,
  .product-summary .strike-through .value,
  .multi-shipping del .value,
  .multi-shipping .strike-through .value {
    margin-right: 4px; }

.product-info .bonus-line-item-msg,
.product-summary .bonus-line-item-msg,
.multi-shipping .bonus-line-item-msg {
  margin-top: 8px; }

.product-info .nested-line-item,
.product-summary .nested-line-item,
.multi-shipping .nested-line-item {
  margin: 0;
  border: 1px solid #F9F9F9;
  padding: 8px; }
  .product-info .nested-line-item ~ .nested-line-item,
  .product-summary .nested-line-item ~ .nested-line-item,
  .multi-shipping .nested-line-item ~ .nested-line-item {
    border-top: 0; }

.product-info .bonus-line-item-msg,
.product-info .unit-price-label,
.product-info .qty-card-quantity-label,
.product-info .quantity-label,
.product-info .line-item-total-price-label,
.product-summary .bonus-line-item-msg,
.product-summary .unit-price-label,
.product-summary .qty-card-quantity-label,
.product-summary .quantity-label,
.product-summary .line-item-total-price-label,
.multi-shipping .bonus-line-item-msg,
.multi-shipping .unit-price-label,
.multi-shipping .qty-card-quantity-label,
.multi-shipping .quantity-label,
.multi-shipping .line-item-total-price-label {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
  text-transform: none;
  letter-spacing: 0; }

.product-info .non-adjusted-price,
.product-summary .non-adjusted-price,
.multi-shipping .non-adjusted-price {
  display: none; }

.product-info .line-item-promo,
.product-summary .line-item-promo,
.multi-shipping .line-item-promo {
  color: #2eab2b;
  font-size: 16px; }

.notavailabe-cart:first-child:after {
  content: none !important; }

.product-tile {
  margin-bottom: 45px; }
  .product-tile .image-container {
    position: relative;
    margin-bottom: 10px; }
  .product-tile .tile-image {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .product-tile .product-tile-image-link {
    display: block; }
  .product-tile .quickview {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-decoration: none; }
    .product-tile .quickview .quickview-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: none;
      border-radius: 50%; }
      .product-tile .quickview .quickview-icon:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 14px;
        color: rgba(34, 34, 34, 0.7);
        font-weight: normal; }
      .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: rgba(34, 34, 34, 0.7);
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title).btn-sm, .product-tile .quickview .btn-group-sm > .quickview-icon.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title).btn-sm:before, .product-tile .quickview .btn-group-sm > .quickview-icon.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: rgba(34, 34, 34, 0.7);
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title).btn-lg, .product-tile .quickview .btn-group-lg > .quickview-icon.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title).btn-lg:before, .product-tile .quickview .btn-group-lg > .quickview-icon.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: rgba(34, 34, 34, 0.7);
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) [class*="icon-"],
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) [class^="icon-"],
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) .fa {
          display: none; }
      .product-tile .quickview .quickview-icon::before {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%); }
    .product-tile .quickview.btn {
      width: calc(100% - 20px);
      opacity: 0; }
  .product-tile .tile-body {
    padding-top: 5px;
    padding-bottom: 16px;
    position: relative; }
    .product-tile .tile-body :last-child {
      margin-bottom: 0; }
  .product-tile .color-swatches {
    margin-bottom: 10px;
    font-size: 0; }
    .product-tile .color-swatches .swatches::after {
      display: block;
      content: "";
      clear: both; }
    .product-tile .color-swatches .swatch-link {
      display: block;
      float: left;
      text-decoration: none; }
      .product-tile .color-swatches .swatch-link:first-child .swatch-circle {
        margin-left: 4px; }
    .product-tile .color-swatches .swatch-circle {
      width: 18px;
      height: 18px;
      background-color: #FFFFFF;
      background-size: cover;
      display: block;
      position: relative;
      text-align: center;
      border-radius: 50%;
      margin-right: 8px;
      margin-bottom: 8px; }
      .product-tile .color-swatches .swatch-circle.Color-value {
        transition: box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.Color-value:hover {
          box-shadow: none; }
        .product-tile .color-swatches .swatch-circle.Color-value.selected {
          border: 2px solid #181818;
          border-radius: 4px;
          box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
          .product-tile .color-swatches .swatch-circle.Color-value.selected:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 7px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.Color-value.selected::after {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            display: none;
            background: rgba(34, 34, 34, 0.7);
            width: 14px;
            height: 14px;
            line-height: 14px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            z-index: 1; }
        .product-tile .color-swatches .swatch-circle.Color-value.unselectable {
          filter: blur(0px); }
          .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon {
            pointer-events: none;
            cursor: not-allowed;
            position: absolute;
            height: 72px;
            width: 72px;
            bottom: 0;
            right: 0; }
            @media (max-width: 768.98px) {
              .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon {
                height: calc(18.5vw);
                width: calc(18.5vw); } }
            .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:before, .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 18px);
              width: 1px; }
            .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
              background-color: #FFFFFF;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
              z-index: 2;
              width: 2px; }
        .product-tile .color-swatches .swatch-circle.Color-value.unselectable {
          pointer-events: none;
          pointer-events: auto;
          cursor: not-allowed;
          position: relative; }
      .product-tile .color-swatches .swatch-circle.non-color-swatch-value {
        transition: border 200ms ease-out, box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value:not(.unselectable):hover {
          box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover {
          background: #F9F9F9;
          color: #222222;
          border-color: #181818;
          box-shadow: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 8px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected::after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover::after {
            position: absolute;
            top: -5.33333px;
            right: -5.33333px;
            background: #181818;
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            display: none; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable {
          pointer-events: auto;
          cursor: not-allowed;
          position: relative;
          color: #D4D4D4; }
      .product-tile .color-swatches .swatch-circle.selected {
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 4px #181818; }
    .product-tile .color-swatches .swatch-thumbnail {
      width: 30px;
      height: 30px;
      background-color: #FFFFFF;
      background-size: cover;
      display: block;
      position: relative;
      text-align: center;
      border-radius: 0;
      margin-right: 1px;
      margin-bottom: 1px; }
      .product-tile .color-swatches .swatch-thumbnail.Color-value {
        transition: box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value:hover:not(.unselectable) {
          box-shadow: inset 0px -2px 0px #595959; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.selected {
          box-shadow: inset 0px -2px 0px #222222; }
          .product-tile .color-swatches .swatch-thumbnail.Color-value.selected:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 7px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-thumbnail.Color-value.selected::after {
            position: absolute;
            top: -4.66667px;
            right: -4.66667px;
            display: none;
            background: rgba(34, 34, 34, 0.7);
            width: 14px;
            height: 14px;
            line-height: 14px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            z-index: 1; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable:before, .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(calc(100% + 15px) + 18px);
          width: 1px; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable:after {
          background-color: #FFFFFF;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
          z-index: 2;
          width: 2px; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable {
          pointer-events: none;
          pointer-events: auto;
          cursor: not-allowed;
          position: relative; }
      .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value {
        transition: border 200ms ease-out, box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value:not(.unselectable):hover {
          box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover {
          background: #F9F9F9;
          color: #222222;
          border-color: #181818;
          box-shadow: none; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 8px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected::after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
            position: absolute;
            top: -5.33333px;
            right: -5.33333px;
            background: #181818;
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            display: none; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable {
          pointer-events: auto;
          cursor: not-allowed;
          position: relative;
          color: #D4D4D4; }
      .product-tile .color-swatches .swatch-thumbnail.selected {
        box-shadow: inset 0px -2px 0px #222222; }
    .product-tile .color-swatches .swatch-color-count {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0;
      margin-left: 5px;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      color: #595959; }
      @media (min-width: 1024px) {
        .product-tile .color-swatches .swatch-color-count {
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(180%);
          line-height: 24px; } }
  .product-tile .pdp-link {
    margin-bottom: 4px; }
  .product-tile .link {
    display: block;
    font-family: "Space Grotesk", sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: #181818;
    text-decoration: none;
    line-height: 24px; }
    @media (min-width: 1024px) {
      .product-tile .link {
        font-size: 16px;
        line-height: 24px; } }
    .product-tile .link:hover {
      color: #181818;
      text-decoration: none; }
  .product-tile .price {
    margin-bottom: 10px;
    font-size: 13px;
    color: #181818; }
    .product-tile .price::after {
      display: block;
      content: "";
      clear: both; }
    @media (min-width: 1024px) {
      .product-tile .price {
        font-size: 16px;
        line-height: 24px; } }
    .product-tile .price del,
    .product-tile .price .strike-through {
      color: #181818; }
    .product-tile .price del ~ .sales {
      float: left;
      margin-right: 16px;
      color: #a80b1d; }
  .product-tile .ratings {
    margin-bottom: 20px;
    font-size: 11px;
    color: #181818;
    flex-basis: 100%;
    max-width: 100%; }
    .product-tile .ratings .fa-star:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-lg, .product-tile .ratings .btn-group-lg > .fa-star.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-lg:before, .product-tile .ratings .btn-group-lg > .fa-star.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-tile .ratings .fa-star-half-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-lg, .product-tile .ratings .btn-group-lg > .fa-star-half-o.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-lg:before, .product-tile .ratings .btn-group-lg > .fa-star-half-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-tile .ratings .fa-star-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-lg, .product-tile .ratings .btn-group-lg > .fa-star-o.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-lg:before, .product-tile .ratings .btn-group-lg > .fa-star-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) .fa {
        display: none; }
  @media (min-width: 1024px) {
    .product-tile.on-hover .color-swatches .swatches {
      opacity: 0; }
    .product-tile.on-hover:hover .color-swatches .swatches {
      opacity: 1; } }
  .product-tile:hover .product-tile-image-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-shadow: inset 0px -0 0px #222222; }
  .product-tile:hover .quickview.btn {
    opacity: 1; }
  @media (min-width: 769px) {
    .product-tile .show-secondary-image:hover .product-tile-image {
      display: none; }
    .product-tile .show-secondary-image:hover .product-tile-image-secondary {
      display: block; } }
  .product-tile .show-secondary-image .product-tile-image {
    display: block; }
  .product-tile .show-secondary-image .product-tile-image-secondary {
    display: none; }

.product-tile {
  position: relative; }
  .product-tile .color-swatches {
    margin-bottom: 10px;
    font-size: 0; }
    .product-tile .color-swatches .swatches::after {
      display: block;
      content: "";
      clear: both; }
    .product-tile .color-swatches .swatch-link {
      display: block;
      float: left;
      text-decoration: none; }
      .product-tile .color-swatches .swatch-link:first-child .swatch-circle {
        margin-left: 4px; }
    .product-tile .color-swatches .swatch-circle {
      width: 18px;
      height: 18px;
      background-color: #FFFFFF;
      background-size: cover;
      display: block;
      position: relative;
      text-align: center;
      border-radius: 50%;
      margin-right: 8px;
      margin-bottom: 8px; }
      .product-tile .color-swatches .swatch-circle.Color-value {
        transition: box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.Color-value:hover {
          box-shadow: none; }
        .product-tile .color-swatches .swatch-circle.Color-value.selected {
          border: 2px solid #181818;
          border-radius: 4px;
          box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
          .product-tile .color-swatches .swatch-circle.Color-value.selected:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 7px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.Color-value.selected::after {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            display: none;
            background: rgba(34, 34, 34, 0.7);
            width: 14px;
            height: 14px;
            line-height: 14px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            z-index: 1; }
        .product-tile .color-swatches .swatch-circle.Color-value.unselectable {
          filter: blur(0px); }
          .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon {
            pointer-events: none;
            cursor: not-allowed;
            position: absolute;
            height: 72px;
            width: 72px;
            bottom: 0;
            right: 0; }
            @media (max-width: 768.98px) {
              .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon {
                height: calc(18.5vw);
                width: calc(18.5vw); } }
            .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:before, .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 18px);
              width: 1px; }
            .product-tile .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
              background-color: #FFFFFF;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
              z-index: 2;
              width: 2px; }
        .product-tile .color-swatches .swatch-circle.Color-value.unselectable {
          pointer-events: none;
          pointer-events: auto;
          cursor: not-allowed;
          position: relative; }
      .product-tile .color-swatches .swatch-circle.non-color-swatch-value {
        transition: border 200ms ease-out, box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value:not(.unselectable):hover {
          box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover {
          background: #F9F9F9;
          color: #222222;
          border-color: #181818;
          box-shadow: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 8px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected::after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover::after {
            position: absolute;
            top: -5.33333px;
            right: -5.33333px;
            background: #181818;
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            display: none; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable {
          pointer-events: auto;
          cursor: not-allowed;
          position: relative;
          color: #D4D4D4; }
      .product-tile .color-swatches .swatch-circle.selected {
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 4px #181818; }
    .product-tile .color-swatches .swatch-thumbnail {
      width: 30px;
      height: 30px;
      background-color: #FFFFFF;
      background-size: cover;
      display: block;
      position: relative;
      text-align: center;
      border-radius: 0;
      margin-right: 1px;
      margin-bottom: 1px; }
      .product-tile .color-swatches .swatch-thumbnail.Color-value {
        transition: box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value:hover:not(.unselectable) {
          box-shadow: inset 0px -2px 0px #595959; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.selected {
          box-shadow: inset 0px -2px 0px #222222; }
          .product-tile .color-swatches .swatch-thumbnail.Color-value.selected:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 7px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-thumbnail.Color-value.selected::after {
            position: absolute;
            top: -4.66667px;
            right: -4.66667px;
            display: none;
            background: rgba(34, 34, 34, 0.7);
            width: 14px;
            height: 14px;
            line-height: 14px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            z-index: 1; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable:before, .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(calc(100% + 15px) + 18px);
          width: 1px; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable:after {
          background-color: #FFFFFF;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
          z-index: 2;
          width: 2px; }
        .product-tile .color-swatches .swatch-thumbnail.Color-value.unselectable {
          pointer-events: none;
          pointer-events: auto;
          cursor: not-allowed;
          position: relative; }
      .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value {
        transition: border 200ms ease-out, box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value:not(.unselectable):hover {
          box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover {
          background: #F9F9F9;
          color: #222222;
          border-color: #181818;
          box-shadow: none; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 8px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 63px; }
              .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-tile .color-swatches .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected::after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
            position: absolute;
            top: -5.33333px;
            right: -5.33333px;
            background: #181818;
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            display: none; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable {
          pointer-events: auto;
          cursor: not-allowed;
          position: relative;
          color: #D4D4D4; }
      .product-tile .color-swatches .swatch-thumbnail.selected {
        box-shadow: inset 0px -2px 0px #222222; }
    .product-tile .color-swatches .swatch-color-count {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0;
      margin-left: 5px;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      color: #595959; }
      @media (min-width: 1024px) {
        .product-tile .color-swatches .swatch-color-count {
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(180%);
          line-height: 24px; } }
  .product-tile .product-tile-image-container {
    padding: 45px 8px;
    background-color: #F9F9F9;
    border-radius: 20px; }
    .product-tile .product-tile-image-container.full-bleed-image {
      padding: calc(100% + (53px * 2) - (16px * 2)) 0 0; }
      .product-tile .product-tile-image-container.full-bleed-image img {
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
        position: absolute;
        border-radius: 20px; }
      @media (min-width: 769px) {
        .product-tile .product-tile-image-container.full-bleed-image:hover .product-tile-image {
          display: block; } }
    .product-tile .product-tile-image-container .tile-image {
      border-radius: 8px; }
  @media (min-width: 769px) {
    .product-tile .pdp-link {
      padding: 0 8px; } }
  .product-tile .pdp-link .charity-link,
  .product-tile .pdp-link .charity-link:hover,
  .product-tile .pdp-link .charity-link:visited,
  .product-tile .pdp-link .charity-link:active {
    color: #181818;
    font-size: 14px;
    text-decoration: none; }
  .product-tile .link {
    font-family: "Inter", sans-serif; }
  .product-tile--buy-container {
    padding: 0 8px; }
  .product-tile .yotpo {
    padding: 0 8px; }
  .product-tile .price {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .product-tile .price {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .product-tile .price del,
    .product-tile .price .strike-through {
      color: #181818; }
  .product-tile .badge-product-container.badge-image-over {
    top: 8px;
    left: 14px; }
    @media (max-width: 768.98px) {
      .product-tile .badge-product-container.badge-image-over {
        top: 5px; } }
  @media (min-width: 769px) {
    .product-tile .show-secondary-image:hover .product-tile-image-secondary {
      border-radius: 8px; } }
  .product-tile .quick-add-to-cart {
    position: absolute;
    left: 8px;
    bottom: 8px;
    margin: 0 !important;
    width: 100%;
    padding: 0;
    max-width: 30px;
    height: 30px; }
    .product-tile .quick-add-to-cart .row {
      position: static; }
    .product-tile .quick-add-to-cart .btn {
      max-width: 30px;
      height: 30px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      font-size: 15px;
      font-weight: bold;
      color: #F9F9F9; }
      .product-tile .quick-add-to-cart .btn:hover::after, .product-tile .quick-add-to-cart .btn:active::after, .product-tile .quick-add-to-cart .btn:focus::after {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
      .product-tile .quick-add-to-cart .btn:active::after, .product-tile .quick-add-to-cart .btn:focus::after {
        background-color: #181818; }
      @media (min-width: 1024px) {
        .product-tile .quick-add-to-cart .btn.btn-primary-outline {
          max-width: 30px;
          height: 30px; } }
      @media (min-width: 1024px) {
        .product-tile .quick-add-to-cart .btn.full-width {
          max-width: 30px;
          height: 30px; } }
      .product-tile .quick-add-to-cart .btn .diagonal-line {
        position: absolute;
        top: 15px;
        left: 2px;
        width: 24px;
        border-top: 2px solid #a80b1d;
        transform: rotate(135deg);
        transform-origin: center; }
  .product-tile .addOverlay {
    opacity: 0.5; }
  .product-tile .wishlist:not(.move) {
    top: auto;
    bottom: 8px;
    right: 8px; }
    @media (min-width: 544px) {
      .product-tile .wishlist:not(.move) {
        top: auto; } }
    .product-tile .wishlist:not(.move) .wishlist-icon {
      background-color: #181818;
      color: #F9F9F9;
      border-radius: 100%;
      font-size: 15px; }
      .product-tile .wishlist:not(.move) .wishlist-icon:hover {
        background-color: #5d5d5d; }
      .product-tile .wishlist:not(.move) .wishlist-icon::before {
        color: #F9F9F9;
        font-size: 14px; }
      .product-tile .wishlist:not(.move) .wishlist-icon.selected::before {
        color: #F9F9F9; }
  .product-tile.whiteBg-blackColor .wishlist:not(.move) .wishlist-icon {
    background-color: #FFFFFF;
    color: #181818; }
    .product-tile.whiteBg-blackColor .wishlist:not(.move) .wishlist-icon:hover {
      background-color: #f5f5f5; }
    .product-tile.whiteBg-blackColor .wishlist:not(.move) .wishlist-icon::before {
      color: #181818; }
    .product-tile.whiteBg-blackColor .wishlist:not(.move) .wishlist-icon.selected::before {
      color: #181818; }
  .product-tile.whiteBg-blackColor .btn {
    color: #181818; }
    .product-tile.whiteBg-blackColor .btn::after {
      background-color: #FFFFFF; }
    .product-tile.whiteBg-blackColor .btn:hover::after, .product-tile.whiteBg-blackColor .btn:active::after, .product-tile.whiteBg-blackColor .btn:focus::after {
      background-color: #f5f5f5; }
    .product-tile.whiteBg-blackColor .btn:active::after, .product-tile.whiteBg-blackColor .btn:focus::after {
      background-color: #FFFFFF; }
  .product-tile.noBg-blackColor .wishlist:not(.move) .wishlist-icon {
    background-color: transparent;
    color: #181818; }
    .product-tile.noBg-blackColor .wishlist:not(.move) .wishlist-icon:hover {
      background-color: transparent; }
    .product-tile.noBg-blackColor .wishlist:not(.move) .wishlist-icon::before {
      color: #181818; }
    .product-tile.noBg-blackColor .wishlist:not(.move) .wishlist-icon.selected::before {
      color: #181818; }
  .product-tile.noBg-blackColor .btn {
    color: #181818; }
    .product-tile.noBg-blackColor .btn::after {
      background-color: transparent; }
    .product-tile.noBg-blackColor .btn:hover::after, .product-tile.noBg-blackColor .btn:active::after, .product-tile.noBg-blackColor .btn:focus::after {
      background-color: transparent; }
    .product-tile.noBg-blackColor .btn:active::after, .product-tile.noBg-blackColor .btn:focus::after {
      background-color: transparent; }

@media (min-width: 1024px) {
  .slider-product-tile .product-tile .product-tile-image-container.show-secondary-image {
    min-height: 394px; } }

@media (min-width: 1024px) {
  .slider-product-tile.mb-n6 .product-tile .product-tile-image-container.show-secondary-image, .slider-product-tile[data-slider-items-lg="6"] .product-tile .product-tile-image-container.show-secondary-image {
    min-height: 277px; } }

.tile-image-wrap {
  position: relative;
  margin-bottom: 10px; }

.btn-addon-to-cart {
  display: none; }

.product-addons {
  overflow: hidden;
  width: 100%;
  margin-bottom: 16px; }
  .product-addons.collapsible-content-group {
    margin-top: -1px; }
  .product-addons .promotion, .product-addons .promotions, .product-addons .ratings, .product-addons .wishlist {
    display: none; }
  .product-addons .btn-addon-to-cart {
    display: block;
    font-family: "Inter", sans-serif;
    text-transform: none;
    color: #181818;
    background: transparent;
    border: none;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    max-width: 126px;
    margin-left: auto;
    padding: 8px 10px;
    line-height: 24px;
    letter-spacing: 0; }
    @media (min-width: 769px) {
      .product-addons .btn-addon-to-cart {
        padding: 8px 16px; } }
  .product-addons .items {
    border: 1px solid #D4D4D4;
    border-radius: 20px;
    background: #F9F9F9;
    padding: 24px; }
    @media (max-width: 768.98px) {
      .product-addons .items {
        padding: 16px; } }
    .product-addons .items .add-on-text {
      width: 253px;
      height: 24px;
      top: 24px;
      left: 24px;
      gap: 0;
      font-family: "Inter", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none; }
    .product-addons .items .add-on-save {
      width: 157px;
      height: 19px;
      top: 60px;
      left: 24px;
      gap: 0;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none; }
  .product-addons .product {
    display: flex;
    align-items: center; }
    .product-addons .product:last-child .product-tile::before {
      border-bottom: 0; }
  .product-addons .product-tile {
    margin-bottom: 0;
    padding: 24px 1px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%; }
    .product-addons .product-tile img {
      width: 100%; }
      @media (min-width: 1024px) {
        .product-addons .product-tile img {
          width: 110px; } }
    .product-addons .product-tile .price {
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .product-addons .product-tile .price {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          line-height: 24px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .product-addons .product-tile > div {
      flex-basis: 44%; }
      @media (min-width: 1024px) {
        .product-addons .product-tile > div {
          flex-basis: 40%; } }
      .product-addons .product-tile > div.tile-image-wrap {
        flex-basis: 18%; }
        @media (min-width: 1024px) {
          .product-addons .product-tile > div.tile-image-wrap {
            flex-basis: 23%; } }
      .product-addons .product-tile > div .pdp-link {
        padding: 0 3px; }
        @media (min-width: 1024px) {
          .product-addons .product-tile > div .pdp-link {
            padding: 0 4px 0 16px; } }
    .product-addons .product-tile > a.main-link {
      flex-basis: 18%; }
      @media (min-width: 1024px) {
        .product-addons .product-tile > a.main-link {
          flex-basis: 23%; } }
    .product-addons .product-tile > div.product-tile--buy-container {
      text-align: right;
      flex-basis: 38%;
      padding: 0; }
      @media (min-width: 1024px) {
        .product-addons .product-tile > div.product-tile--buy-container {
          flex-basis: 37%; } }
      .product-addons .product-tile > div.product-tile--buy-container .buy-with-prime-badge {
        padding: 0 !important; }
    .product-addons .product-tile-image-container {
      margin: 0;
      padding: 0;
      background-color: transparent; }
    .product-addons .product-tile.addon-product-tile-emea > div {
      flex-basis: 55%; }
      @media (min-width: 1024px) {
        .product-addons .product-tile.addon-product-tile-emea > div {
          flex-basis: 40%; } }
      .product-addons .product-tile.addon-product-tile-emea > div.tile-image-wrap {
        flex-basis: 18%; }
        @media (min-width: 1024px) {
          .product-addons .product-tile.addon-product-tile-emea > div.tile-image-wrap {
            flex-basis: 23%; } }
    .product-addons .product-tile.addon-product-tile-emea > div.product-tile--buy-container {
      text-align: right;
      flex-basis: 60%;
      padding: 0; }
      @media (min-width: 544px) {
        .product-addons .product-tile.addon-product-tile-emea > div.product-tile--buy-container {
          flex-basis: 30%; } }
      @media (min-width: 769px) {
        .product-addons .product-tile.addon-product-tile-emea > div.product-tile--buy-container {
          flex-basis: 32%; } }
      @media (min-width: 1024px) {
        .product-addons .product-tile.addon-product-tile-emea > div.product-tile--buy-container {
          flex-basis: 37%; } }
      .product-addons .product-tile.addon-product-tile-emea > div.product-tile--buy-container .btn-addon-to-cart {
        padding: 8px 16px;
        max-width: 145px; }
        @media (min-width: 769px) {
          .product-addons .product-tile.addon-product-tile-emea > div.product-tile--buy-container .btn-addon-to-cart {
            max-width: inherit; } }
    .product-addons .product-tile.customisable-add-on-product-pdp > div {
      flex-basis: 32%; }
      @media (min-width: 1024px) {
        .product-addons .product-tile.customisable-add-on-product-pdp > div {
          flex-basis: 40%; } }
      .product-addons .product-tile.customisable-add-on-product-pdp > div.tile-image-wrap {
        flex-basis: 18%; }
        @media (min-width: 1024px) {
          .product-addons .product-tile.customisable-add-on-product-pdp > div.tile-image-wrap {
            flex-basis: 23%; } }
    .product-addons .product-tile.customisable-add-on-product-pdp > div.product-tile--buy-container {
      text-align: right;
      flex-basis: 50%; }
      @media (min-width: 1024px) {
        .product-addons .product-tile.customisable-add-on-product-pdp > div.product-tile--buy-container {
          flex-basis: 37%; } }
      .product-addons .product-tile.customisable-add-on-product-pdp > div.product-tile--buy-container .btn-customize {
        padding: 8px 16px; }
  .product-addons .non-input-label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    padding: 16px 0 32px; }
    @media (min-width: 1024px) {
      .product-addons .non-input-label {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .product-detail .product-addons .non-input-label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    padding: 32px 0 16px; }
    @media (min-width: 1024px) {
      .product-detail .product-addons .non-input-label {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .cart-page .product-addons {
    margin-bottom: 16px; }

@media (max-width: 1199.98px) {
  .product-addons .product-tile .price del ~ .sales {
    float: none;
    margin-right: 0;
    display: block; } }

@media (max-width: 1439.98px) {
  .grid-tile-add-on .product-tile .product-tile-image-container img {
    width: 96px; } }

@media (max-width: 1199.98px) {
  .grid-tile-add-on .product-tile .product-tile-image-container img {
    width: 80px; } }

.grid-tile-add-on .product-tile .color-swatches {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: auto;
  scrollbar-width: none;
  display: block;
  max-width: 100%;
  cursor: grab; }

.grid-tile-add-on .product-tile .color-swatches::-webkit-scrollbar {
  display: none; }

.grid-tile-add-on .product-tile .swatches {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  width: max-content; }
  .grid-tile-add-on .product-tile .swatches .pdp-add-on-swatch-span:first-child {
    margin-left: 0 !important; }

.grid-tile-add-on .product-tile .pdp-add-on-swatch-span {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  flex-shrink: 0; }

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .quick-view-dialog,
    .choose-bonus-product-dialog {
      max-width: 900px;
      margin: 32px auto; } }
  .quick-view-dialog .modal-body,
  .choose-bonus-product-dialog .modal-body {
    overflow-x: hidden;
    overflow-y: auto; }
  .quick-view-dialog .product-quickview,
  .choose-bonus-product-dialog .product-quickview {
    padding: 0; }
    .quick-view-dialog .product-quickview .availability,
    .choose-bonus-product-dialog .product-quickview .availability {
      margin-left: 0;
      margin-right: 0; }
    .quick-view-dialog .product-quickview .product-availability,
    .choose-bonus-product-dialog .product-quickview .product-availability {
      display: none; }
    .quick-view-dialog .product-quickview .pdp-in-store-inventory,
    .choose-bonus-product-dialog .product-quickview .pdp-in-store-inventory {
      margin-top: 16px;
      width: 100%; }
  .quick-view-dialog .product-detail,
  .choose-bonus-product-dialog .product-detail {
    margin-top: 0;
    margin-bottom: 0; }
    .quick-view-dialog .product-detail .product-name,
    .choose-bonus-product-dialog .product-detail .product-name {
      margin-bottom: 0; }
  .quick-view-dialog .primary-images,
  .choose-bonus-product-dialog .primary-images {
    margin: 0 0 16px; }
    @media (min-width: 769px) {
      .quick-view-dialog .primary-images,
      .choose-bonus-product-dialog .primary-images {
        margin-bottom: 0; } }
  .quick-view-dialog .selectable-bonus-product-line-item,
  .choose-bonus-product-dialog .selectable-bonus-product-line-item {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 8px;
    padding-bottom: 8px; }
  .quick-view-dialog .beenSelected,
  .choose-bonus-product-dialog .beenSelected {
    background-color: #F9F9F9; }
  .quick-view-dialog .availability-container,
  .choose-bonus-product-dialog .availability-container {
    padding: 0; }
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    padding-top: 16px;
    border-top: 1px solid #D4D4D4; }
    .quick-view-dialog .modal-footer .prices,
    .choose-bonus-product-dialog .modal-footer .prices {
      text-align: center;
      margin-bottom: 8px; }
      .quick-view-dialog .modal-footer .prices .price,
      .quick-view-dialog .modal-footer .prices .sales,
      .choose-bonus-product-dialog .modal-footer .prices .price,
      .choose-bonus-product-dialog .modal-footer .prices .sales {
        font-size: 16px;
        font-family: "Space Grotesk", sans-serif; }
    .quick-view-dialog .modal-footer label,
    .quick-view-dialog .modal-footer .non-input-label,
    .choose-bonus-product-dialog .modal-footer label,
    .choose-bonus-product-dialog .modal-footer .non-input-label {
      font-family: "Inter", sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 2px;
      text-transform: none;
      letter-spacing: 0; }
  .quick-view-dialog .set-item,
  .choose-bonus-product-dialog .set-item {
    padding: 0; }
    .quick-view-dialog .set-item .product-name,
    .choose-bonus-product-dialog .set-item .product-name {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      line-height: 32px;
      text-transform: none;
      letter-spacing: 0;
      font-weight: normal;
      font-style: normal;
      margin-bottom: 8px; }
  .quick-view-dialog .bonus-product-item,
  .choose-bonus-product-dialog .bonus-product-item {
    padding: 0; }
    .quick-view-dialog .bonus-product-item ~ .bonus-product-item,
    .choose-bonus-product-dialog .bonus-product-item ~ .bonus-product-item {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid #D4D4D4; }
    .quick-view-dialog .bonus-product-item .product-quickview + hr,
    .choose-bonus-product-dialog .bonus-product-item .product-quickview + hr {
      display: none; }
    .quick-view-dialog .bonus-product-item .bonus-quantity,
    .quick-view-dialog .bonus-product-item .bonus-option,
    .quick-view-dialog .bonus-product-item .select-bonus-product,
    .quick-view-dialog .bonus-product-item .bonus-product-price:not(:empty),
    .choose-bonus-product-dialog .bonus-product-item .bonus-quantity,
    .choose-bonus-product-dialog .bonus-product-item .bonus-option,
    .choose-bonus-product-dialog .bonus-product-item .select-bonus-product,
    .choose-bonus-product-dialog .bonus-product-item .bonus-product-price:not(:empty) {
      margin-top: 16px; }
    .quick-view-dialog .bonus-product-item .attribute label,
    .choose-bonus-product-dialog .bonus-product-item .attribute label {
      font-family: "Inter", sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 2px;
      text-transform: none;
      letter-spacing: 0; }
    .quick-view-dialog .bonus-product-item .quantity,
    .choose-bonus-product-dialog .bonus-product-item .quantity {
      margin-bottom: 0; }
      .quick-view-dialog .bonus-product-item .quantity label,
      .choose-bonus-product-dialog .bonus-product-item .quantity label {
        display: block;
        margin-right: 0; }
        .quick-view-dialog .bonus-product-item .quantity label:after,
        .choose-bonus-product-dialog .bonus-product-item .quantity label:after {
          display: none; }
  .quick-view-dialog .selected-bonus-products ~ .col-sm-6,
  .choose-bonus-product-dialog .selected-bonus-products ~ .col-sm-6 {
    text-align: right; }
  .quick-view-dialog .selected-bonus-products .bonus-summary,
  .choose-bonus-product-dialog .selected-bonus-products .bonus-summary {
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
    text-transform: none;
    letter-spacing: 0; }
    .quick-view-dialog .selected-bonus-products .bonus-summary.alert-danger,
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary.alert-danger {
      background: transparent;
      border: none; }
    .quick-view-dialog .selected-bonus-products .bonus-summary .pre-cart-products,
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary .pre-cart-products {
      font-weight: bold; }
  .quick-view-dialog .selected-bonus-products .bonus-summary-products-container,
  .choose-bonus-product-dialog .selected-bonus-products .bonus-summary-products-container {
    position: relative;
    padding: 0 16px 0 16px;
    border: 1px solid #D4D4D4;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    min-height: calc(16px + 16px + 14px + (1px*2)); }
  .quick-view-dialog .selected-bonus-products .selected-pid,
  .choose-bonus-product-dialog .selected-bonus-products .selected-pid {
    position: relative;
    line-height: calc(16px + 16px + 14px + (1px*2)); }
    .quick-view-dialog .selected-bonus-products .selected-pid .bonus-product-name,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid .bonus-product-name {
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .quick-view-dialog .selected-bonus-products .selected-pid + .selected-pid::before,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid + .selected-pid::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #D4D4D4; }
    .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product {
      font-family: "Inter", sans-serif;
      border: none;
      border-radius: 50%;
      font-size: 0;
      position: absolute;
      top: 0;
      right: 0;
      margin: 32px 16px 32px 16px;
      cursor: pointer;
      z-index: 1;
      background-color: #FFFFFF;
      padding: 4px 6px;
      height: 22px;
      width: 22px;
      background-color: transparent;
      margin: 0;
      padding: 0; }
      .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product:before,
      .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 11px;
        color: inherit;
        font-weight: normal; }
      .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title),
      .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title):before,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title),
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm:before, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title):before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm:before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-lg, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-lg > .remove-bonus-product.btn[class*="btn-"]:not(.title),
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-lg,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-lg > .remove-bonus-product.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-lg:before, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-lg > .remove-bonus-product.btn[class*="btn-"]:not(.title):before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-lg:before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-lg > .remove-bonus-product.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class*="icon-"],
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class^="icon-"],
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) .fa,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class*="icon-"],
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class^="icon-"],
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) .fa {
          display: none; }

.modal-dialog {
  max-width: 800px; }

.cross-sell-copy,
.cross-sell-btn {
  display: none; }

.card .card-footer .cross-sell-btn {
  position: absolute;
  top: 0;
  z-index: 1;
  display: block;
  width: 93%; }
  .card .card-footer .cross-sell-btn .quickview {
    width: 100%;
    height: 100%;
    display: block;
    padding: 15px;
    color: transparent; }

.cross-sell.product-quickview .row .row.mt-md-3 {
  display: none; }

.cross-sell.product-quickview .row-copy {
  display: block;
  width: 100%; }

#quickViewModal {
  display: flex !important;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 0;
  overflow: visible; }
  @media (min-width: 769px) {
    #quickViewModal {
      justify-content: center; } }
  #quickViewModal.show {
    height: 100%; }
  @media (max-width: 768.98px) {
    #quickViewModal.modal.show .modal-dialog {
      transform: none; } }
  #quickViewModal .modal-dialog {
    max-width: 85%;
    width: 883px;
    height: 568px;
    max-height: 568px; }
    @media (max-width: 768.98px) {
      #quickViewModal .modal-dialog {
        margin: 0;
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 100%; } }
    #quickViewModal .modal-dialog .name-price-sm {
      margin: 0 8px 0; }
      #quickViewModal .modal-dialog .name-price-sm .product-name {
        margin-top: 0; }
  #quickViewModal .modal-header {
    position: relative;
    padding-left: 0;
    padding: 24px 32px 16px 0; }
    #quickViewModal .modal-header .quickviewHeading {
      position: absolute;
      top: -42px;
      padding-left: 0;
      font-size: 24px;
      line-height: 32px;
      font-family: "Inter", sans-serif;
      color: #FFFFFF;
      font-weight: bold; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-header .quickviewHeading {
          font-size: 20px;
          line-height: 28px;
          padding-left: 16px; } }
    @media (max-width: 768.98px) {
      #quickViewModal .modal-header {
        border-radius: 0;
        padding: 32px 0 0;
        border-radius: 20px 20px 8px 8px; } }
    #quickViewModal .modal-header .close {
      position: absolute;
      left: auto;
      right: 24px;
      top: 16px;
      z-index: 1;
      opacity: 1; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-header .close.pull-right {
          right: 16px;
          top: 32px;
          z-index: 1; } }
  #quickViewModal .modal-body {
    padding: 0 40px; }
    @media (max-width: 768.98px) {
      #quickViewModal .modal-body {
        padding-left: 16px;
        padding-right: 16px;
        height: auto;
        padding-bottom: 8px;
        min-height: calc(76vh - 140px); } }
    #quickViewModal .modal-body .primary-images-thumbnails {
      display: none; }
    #quickViewModal .modal-body .product-detail .product-name {
      margin-bottom: 16px;
      max-width: 98%; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-body .product-detail .product-name {
          margin-bottom: 0;
          margin-right: 32px; } }
    #quickViewModal .modal-body .product-detail .prices {
      margin-bottom: 16px; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-body .product-detail .prices {
          margin-top: 0;
          margin-bottom: 30px; } }
      #quickViewModal .modal-body .product-detail .prices .price {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        line-height: 28px; }
        @media (max-width: 768.98px) {
          #quickViewModal .modal-body .product-detail .prices .price {
            font-size: 16px;
            line-height: 24px; } }
    #quickViewModal .modal-body .product-detail .non-input-label.non-input-label {
      margin-bottom: 0; }
    #quickViewModal .modal-body .product-detail .color-attribute {
      margin: 2px 8px 0 0; }
    #quickViewModal .modal-body .product-detail .color-swatches {
      padding: 0;
      border: 0;
      margin-bottom: 32px; }
      #quickViewModal .modal-body .product-detail .color-swatches .color-display-value {
        margin-top: 4px; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-body .product-detail .color-swatches {
          margin-bottom: 24px; } }
    #quickViewModal .modal-body .product-detail #cross-sell-target {
      display: none; }
    #quickViewModal .modal-body .product-detail .swatch {
      margin-bottom: 0; }
      #quickViewModal .modal-body .product-detail .swatch .attributes {
        margin-bottom: 0; }
      #quickViewModal .modal-body .product-detail .swatch .iPhoneType {
        font-family: "Inter", sans-serif;
        font-weight: bold; }
      #quickViewModal .modal-body .product-detail .swatch .select-iPhoneType {
        margin-bottom: 32px; }
        @media (max-width: 768.98px) {
          #quickViewModal .modal-body .product-detail .swatch .select-iPhoneType {
            margin-bottom: 24px; } }
    #quickViewModal .modal-body .product-detail .availability {
      margin-bottom: 0; }
    @media (max-width: 768.98px) {
      #quickViewModal .modal-body .product-detail .primary-images {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1024px) {
      #quickViewModal .modal-body .product-detail .primary-images {
        flex: 0 0 calc(100% - 380px);
        max-width: calc(100% - 380px); } }
    #quickViewModal .modal-body .product-detail .primary-images > .row {
      position: static;
      height: 100%; }
    #quickViewModal .modal-body .product-detail .primary-images .primary-images-main {
      margin-left: 0;
      flex-basis: auto;
      max-width: 100%;
      width: 100%;
      padding-bottom: 0; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-body .product-detail .primary-images .primary-images-main {
          margin: 0 auto;
          padding-bottom: 0;
          flex: initial; } }
    #quickViewModal .modal-body .product-detail .primary-images .slider-container {
      height: 100%; }
      #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-outer,
      #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-ovh,
      #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-inner,
      #quickViewModal .modal-body .product-detail .primary-images .slider-container .slider {
        height: 100%; }
      #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-controls button {
        height: 32px;
        width: 32px; }
        @media (min-width: 769px) {
          #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-controls button {
            top: auto;
            bottom: 9px;
            left: auto;
            right: 16px;
            background: #FFFFFF;
            border: 1px solid transparent;
            box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.5); }
            #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-controls button:disabled {
              opacity: 1; }
              #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-controls button:disabled:before {
                color: #D4D4D4; }
            #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-controls button:hover {
              border-color: #181818; }
            #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-controls button::before {
              line-height: 32px;
              font-size: 14px;
              font-weight: bold; }
            #quickViewModal .modal-body .product-detail .primary-images .slider-container .tns-controls button[data-controls='prev'] {
              left: auto;
              right: 60px; } }
      @media (max-width: 1023.98px) {
        #quickViewModal .modal-body .product-detail .primary-images .slider-container .slide img {
          height: 300px;
          width: auto;
          margin: 0 auto; } }
    @media (max-width: 768.98px) {
      #quickViewModal .modal-body .product-detail .quantity.attribute {
        display: none; } }
    #quickViewModal .modal-body .quickview-left {
      padding-left: 8px;
      padding-right: 8px; }
      @media (min-width: 1024px) {
        #quickViewModal .modal-body .quickview-left {
          padding-left: 24px;
          padding-right: 0;
          flex: 0 0 380px;
          max-width: 380px; } }
    #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper {
          flex-direction: column-reverse;
          border-top: 1px solid #D4D4D4;
          align-items: stretch; } }
      #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .cross-sell-decline {
        max-width: 82.992px;
        border-radius: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #000000;
        padding-top: 28px;
        color: #000000; }
        @media (max-width: 768.98px) {
          #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .cross-sell-decline {
            margin: 0 auto; } }
      #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .footer-content-right {
        display: flex;
        justify-content: space-between;
        padding-top: 32px;
        width: 413px;
        max-width: 413px;
        border-top: 1px solid #D4D4D4; }
        @media (max-width: 768.98px) {
          #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .footer-content-right {
            width: 100%;
            max-width: inherit;
            border: none;
            padding-top: 24px; } }
        #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .footer-content-right .quantity {
          min-width: 79px;
          margin-right: 8px; }
          @media (max-width: 768.98px) {
            #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .footer-content-right .quantity {
              min-width: 80.96px; } }
          #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .footer-content-right .quantity .quantity-select {
            border-radius: 87px;
            height: 56px;
            width: 100%;
            padding: 16px 24px;
            font-weight: bold;
            margin-right: 0; }
        #quickViewModal .modal-body .quick-view-action-wrapper .footer-content-wrapper .footer-content-right .btn-add-to-cart .price {
          display: none; }
  #quickViewModal .modal-footer {
    display: block;
    border-top: none;
    padding: 0 16px 40px; }
    @media (max-width: 768.98px) {
      #quickViewModal .modal-footer {
        padding: 0 16px 32px;
        border-radius: 0; } }
    #quickViewModal .modal-footer .footer-content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 768.98px) {
        #quickViewModal .modal-footer .footer-content-wrapper {
          flex-direction: column-reverse;
          border-top: 1px solid #D4D4D4;
          align-items: stretch; } }
      #quickViewModal .modal-footer .footer-content-wrapper .cross-sell-decline {
        max-width: 82.992px;
        border-radius: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #000000;
        padding-top: 28px;
        color: #000000; }
        @media (max-width: 768.98px) {
          #quickViewModal .modal-footer .footer-content-wrapper .cross-sell-decline {
            margin: 0 auto; } }
      #quickViewModal .modal-footer .footer-content-wrapper .footer-content-right {
        display: flex;
        justify-content: space-between;
        padding-top: 32px;
        width: 413px;
        max-width: 413px;
        border-top: 1px solid #D4D4D4; }
        @media (max-width: 768.98px) {
          #quickViewModal .modal-footer .footer-content-wrapper .footer-content-right {
            width: auto;
            max-width: auto;
            border: none;
            padding-top: 24px; } }
        #quickViewModal .modal-footer .footer-content-wrapper .footer-content-right .quantity {
          min-width: 120.96px;
          margin-right: 8.96px; }
          @media (max-width: 768.98px) {
            #quickViewModal .modal-footer .footer-content-wrapper .footer-content-right .quantity {
              min-width: 80.96px; } }
          #quickViewModal .modal-footer .footer-content-wrapper .footer-content-right .quantity .quantity-select {
            border-radius: 100px;
            height: 56px; }
        #quickViewModal .modal-footer .footer-content-wrapper .footer-content-right .btn-add-to-cart .price {
          display: none; }
  #quickViewModal .modal-content {
    background-color: #FFFFFF; }

.modal-backdrop.quickview-backdrop {
  background-color: #000000;
  opacity: 0.7; }

.slider-title {
  margin: 0;
  text-align: center; }
  .slider-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: -1px;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .slider-title:not([class*='p--']):not(.headline-xl):not(.headline-l):not(.headline-m):not(.headline-s):not(.headline-xs):not(.body-copy-l):not(.body-copy-l-book):not(.body-copy-m):not(.body-copy-m-book):not(.body-copy-s):not(.body-copy-s-book):not(.body-copy-xs):not(.body-copy-xs-book) {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        line-height: 40px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: -1px;
        font-style: normal;
        font-display: swap; } }

.slider-container {
  position: relative;
  padding-left: 48px;
  padding-right: 48px;
  letter-spacing: 0; }
  .slider-container:not(.slider-bullets-inside):not(.slider-bullets-hidden) {
    margin-bottom: 40px; }
  .slider-container.slider-thumbnails .slider.tns-slider.tns-carousel .slide.tns-item {
    padding: 0; }
    @media (min-width: 769px) {
      .slider-container.slider-thumbnails .slider.tns-slider.tns-carousel .slide.tns-item {
        padding: 16px 8px; } }
  .slider-container .slider {
    display: flex; }
    .slider-container .slider .slide {
      display: none; }
    .slider-container .slider.tns-slider {
      opacity: 1; }
      .slider-container .slider.tns-slider .slide {
        display: block; }
    .slider-container .slider.tns-gallery .tns-slide-active {
      z-index: 1; }
  .slider-container .slide {
    transform: translateZ(0); }
  .slider-container .tns-controls button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
    z-index: 2;
    width: 48px;
    background-color: #FFFFFF;
    border: none;
    display: flex;
    text-align: center;
    transition: background-color 200ms ease-out;
    font-size: 0;
    padding: 0;
    height: 48px;
    border-radius: 100%;
    border: 1px solid transparent;
    box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.5); }
    .slider-container .tns-controls button[data-controls='prev'] {
      left: -24px; }
      .slider-container .tns-controls button[data-controls='prev']:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 17px;
        color: #181818;
        font-weight: normal; }
      .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title).btn-sm, .slider-container .tns-controls .btn-group-sm > button.btn[data-controls='prev'][class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .tns-controls .btn-group-sm > button.btn[data-controls='prev'][class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title).btn-lg, .slider-container .tns-controls .btn-group-lg > button.btn[data-controls='prev'][class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title).btn-lg:before, .slider-container .tns-controls .btn-group-lg > button.btn[data-controls='prev'][class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title) [class*="icon-"],
        .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title) [class^="icon-"],
        .slider-container .tns-controls button[data-controls='prev'][class*="btn-"]:not(.title) .fa {
          display: none; }
      .slider-container .tns-controls button[data-controls='prev']:before {
        margin-left: -2px; }
    .slider-container .tns-controls button[data-controls='next'] {
      right: -24px; }
      .slider-container .tns-controls button[data-controls='next']:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 17px;
        color: #181818;
        font-weight: normal; }
      .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title).btn-sm, .slider-container .tns-controls .btn-group-sm > button.btn[data-controls='next'][class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .tns-controls .btn-group-sm > button.btn[data-controls='next'][class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title).btn-lg, .slider-container .tns-controls .btn-group-lg > button.btn[data-controls='next'][class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title).btn-lg:before, .slider-container .tns-controls .btn-group-lg > button.btn[data-controls='next'][class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #181818;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title) [class*="icon-"],
        .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title) [class^="icon-"],
        .slider-container .tns-controls button[data-controls='next'][class*="btn-"]:not(.title) .fa {
          display: none; }
      .slider-container .tns-controls button[data-controls='next']:before {
        margin-left: 2px; }
    .slider-container .tns-controls button:before {
      width: 100%;
      align-self: center;
      transition: color 200ms ease-out;
      color: #181818; }
    .slider-container .tns-controls button:hover {
      background-color: #FFFFFF;
      border-color: #181818; }
      .slider-container .tns-controls button:hover:before {
        color: #181818; }
    .slider-container .tns-controls button:disabled {
      opacity: 0; }
  .slider-container .tns-nav {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    bottom: 0;
    z-index: 2; }
    .slider-container .tns-nav button {
      display: block;
      background: #D4D4D4;
      border: none;
      width: 8px;
      height: 8px;
      margin: 16px 8px 16px 8px;
      padding: 0;
      cursor: pointer;
      transition: transform 200ms ease-out; }
      .slider-container .tns-nav button.tns-nav-active {
        background: #181818;
        border: none;
        transform: scale(1.5); }
    .slider-container .tns-nav.with-pagination-btn button {
      order: 1; }
    .slider-container .tns-nav.with-pagination-btn .with-pagination {
      order: 0; }
  .slider-container.pagination-style-dots .tns-nav {
    align-items: center; }
    .slider-container.pagination-style-dots .tns-nav button {
      border-radius: 50%; }
  .slider-container.pagination-style-shortLines .tns-nav {
    align-items: flex-end; }
    .slider-container.pagination-style-shortLines .tns-nav button {
      background-color: #FF748B;
      border: none;
      width: 20px;
      height: 2px; }
      .slider-container.pagination-style-shortLines .tns-nav button.tns-nav-active, .slider-container.pagination-style-shortLines .tns-nav button:hover:not(.tns-nav-active) {
        background-color: #181818;
        height: 4px;
        transition: background-color 200ms ease-in-out, height 200ms ease-in-out; }
  .slider-container.pagination-style-newSliderDots .tns-nav {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    bottom: -56px;
    z-index: 2; }
    @media (min-width: 1024px) {
      .slider-container.pagination-style-newSliderDots .tns-nav {
        bottom: -72px; } }
    .slider-container.pagination-style-newSliderDots .tns-nav button {
      position: relative;
      display: block;
      background: transparent;
      border: transparent;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin: 16px 0 16px 0;
      padding: 0;
      cursor: pointer;
      transition: transform 200ms ease-out; }
      .slider-container.pagination-style-newSliderDots .tns-nav button::before {
        content: '';
        background: #181818;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%); }
        @media (min-width: 1024px) {
          .slider-container.pagination-style-newSliderDots .tns-nav button::before {
            height: 8px;
            width: 8px; } }
      .slider-container.pagination-style-newSliderDots .tns-nav button.tns-nav-active {
        background: transparent;
        border: 1px solid #181818;
        transform: none; }
        .slider-container.pagination-style-newSliderDots .tns-nav button.tns-nav-active::before {
          content: '';
          background: #181818;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%); }
          @media (min-width: 1024px) {
            .slider-container.pagination-style-newSliderDots .tns-nav button.tns-nav-active::before {
              height: 8px;
              width: 8px; } }
    .slider-container.pagination-style-newSliderDots .tns-nav.with-pagination-btn button {
      order: 1; }
    .slider-container.pagination-style-newSliderDots .tns-nav.with-pagination-btn .with-pagination {
      order: 0; }
  .slider-container.control-style-newSliderControl {
    padding-left: 52px;
    padding-right: 52px; }
    @media (min-width: 1024px) {
      .slider-container.control-style-newSliderControl {
        padding-left: 140px;
        padding-right: 140px; } }
    .slider-container.control-style-newSliderControl .tns-controls button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      user-select: none;
      z-index: 2;
      width: 51px;
      background-color: transparent;
      border: none;
      display: flex;
      text-align: center;
      transition: background-color 200ms ease-out;
      font-size: 0;
      padding: 0;
      height: 42px;
      border-radius: 0;
      box-shadow: none; }
      @media (min-width: 1024px) {
        .slider-container.control-style-newSliderControl .tns-controls button {
          width: 140px;
          height: 118px; } }
      .slider-container.control-style-newSliderControl .tns-controls button[data-controls='prev'] {
        left: 0; }
        .slider-container.control-style-newSliderControl .tns-controls button[data-controls='prev']:before {
          margin-left: 0;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjMTgxODE4Ii8+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          font-size: 0;
          height: 100%;
          width: 100%;
          transform: rotate(180deg); }
      .slider-container.control-style-newSliderControl .tns-controls button[data-controls='next'] {
        right: 0; }
        .slider-container.control-style-newSliderControl .tns-controls button[data-controls='next']:before {
          margin-left: 0;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjMTgxODE4Ii8+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          font-size: 0;
          height: 100%;
          width: 100%; }
      .slider-container.control-style-newSliderControl .tns-controls button:before {
        width: 100%;
        align-self: center;
        transition: color 200ms ease-out;
        color: #181818; }
      .slider-container.control-style-newSliderControl .tns-controls button:hover {
        background-color: transparent; }
        .slider-container.control-style-newSliderControl .tns-controls button:hover:before {
          color: #181818; }
      .slider-container.control-style-newSliderControl .tns-controls button:disabled {
        opacity: 0; }
  .slider-container.light .tns-controls button {
    background-color: #181818; }
    .slider-container.light .tns-controls button::before {
      color: rgba(255, 255, 255, 0.7); }
    .slider-container.light .tns-controls button:hover {
      background-color: #181818; }
      .slider-container.light .tns-controls button:hover::before {
        color: #FFFFFF; }
  .slider-container.light .tns-nav button {
    background: #D4D4D4;
    border: none; }
    .slider-container.light .tns-nav button.tns-nav-active {
      background: #181818;
      border: none; }
  .slider-container.light .tns-nav-controller::before {
    color: #FFFFFF; }
  .slider-container.light .tns-nav-controller.pause::before {
    color: #FFFFFF; }
  .slider-container.light.pagination-style-shortLines .tns-nav button {
    background-color: rgba(255, 255, 255, 0.5); }
    .slider-container.light.pagination-style-shortLines .tns-nav button.tns-nav-active, .slider-container.light.pagination-style-shortLines .tns-nav button:hover:not(.tns-nav-active) {
      background-color: #FFFFFF; }
  .slider-container.light.pagination-style-newSliderDots .tns-nav button {
    background: transparent; }
    .slider-container.light.pagination-style-newSliderDots .tns-nav button::before {
      content: '';
      background: #D4D4D4;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%); }
    .slider-container.light.pagination-style-newSliderDots .tns-nav button.tns-nav-active {
      background: transparent;
      border: 1px solid #D4D4D4;
      transform: none; }
  .slider-container.light.control-style-newSliderControl .tns-controls button {
    background: transparent; }
    .slider-container.light.control-style-newSliderControl .tns-controls button[data-controls='prev'] {
      left: 0; }
      .slider-container.light.control-style-newSliderControl .tns-controls button[data-controls='prev']:before {
        margin-left: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjZmZmZmZmYjMiLz4KPC9zdmc+Cg==);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        font-size: 0;
        height: 100%;
        width: 100%;
        transform: rotate(180deg); }
    .slider-container.light.control-style-newSliderControl .tns-controls button[data-controls='next'] {
      right: 0; }
      .slider-container.light.control-style-newSliderControl .tns-controls button[data-controls='next']:before {
        margin-left: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQxIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDE0MSAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Mi4yMzc4IDFMMTQwIDYwTTE0MCA2MEw4Mi4yMzc4IDExOU0xNDAgNjBIMCIgc3Ryb2tlPSIjZmZmZmZmYjMiLz4KPC9zdmc+Cg==);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        font-size: 0;
        height: 100%;
        width: 100%; }
    .slider-container.light.control-style-newSliderControl .tns-controls button:before {
      width: 100%;
      align-self: center;
      transition: color 200ms ease-out;
      color: #181818; }
  .slider-container [data-action='stop'],
  .slider-container [data-action='start'] {
    display: none; }
  @media (max-width: 768.98px) {
    .slider-container.slider-buttons-hidden {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden .tns-nav {
      display: none; }
    .slider-container.slider-buttons-inside {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-inside.control-style-newSliderControl .tns-controls button[data-controls='prev'] {
        left: 16px; }
      .slider-container.slider-buttons-inside.control-style-newSliderControl .tns-controls button[data-controls='next'] {
        right: 16px; }
    .slider-container.slider-bullets-inside .tns-nav {
      bottom: 0; }
    .slider-container.next-slide-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.next-slide-1.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.prev-slide-1.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.next-slide-2.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.prev-slide-2.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.next-slide-3.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.prev-slide-3.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.next-slide-4.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.prev-slide-4.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.next-slide-5.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.prev-slide-5.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.next-slide-6.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.prev-slide-6.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.next-slide-7.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.prev-slide-7.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.next-slide-8.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.prev-slide-8.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.next-slide-9.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.prev-slide-9.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.next-slide-10.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.prev-slide-10.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.next-slide-11.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.prev-slide-11.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.next-slide-12.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.prev-slide-12.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.next-slide-13.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.prev-slide-13.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.next-slide-14.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.prev-slide-14.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.next-slide-15.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.prev-slide-15.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.next-slide-16.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.prev-slide-16.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.next-slide-17.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.prev-slide-17.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.next-slide-18.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.prev-slide-18.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.next-slide-19.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.prev-slide-19.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.next-slide-20.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.prev-slide-20.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.next-slide-21.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.prev-slide-21.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.next-slide-22.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.prev-slide-22.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.next-slide-23.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.prev-slide-23.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.next-slide-24.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.prev-slide-24.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.next-slide-25.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.prev-slide-25.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.next-slide-26.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.prev-slide-26.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.next-slide-27.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.prev-slide-27.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.next-slide-28.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.prev-slide-28.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.next-slide-29.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.prev-slide-29.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.next-slide-30.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.prev-slide-30.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.next-slide-31.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.prev-slide-31.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.next-slide-32.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.prev-slide-32.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.next-slide-33.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.prev-slide-33.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.next-slide-34.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.prev-slide-34.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.next-slide-35.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.prev-slide-35.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.next-slide-36.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.prev-slide-36.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.next-slide-37.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.prev-slide-37.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.next-slide-38.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.prev-slide-38.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.next-slide-39.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.prev-slide-39.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.next-slide-40.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-40 .tns-inner {
      padding-left: 40%; }
    .slider-container.prev-slide-40.slider-buttons-hidden {
      margin-left: -16px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .slider-container.slider-buttons-hidden-md {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden-md .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden-md {
      margin-bottom: 0; }
      .slider-container.slider-bullets-hidden-md .tns-nav {
        display: none; }
    .slider-container.slider-buttons-inside-md {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-inside-md [data-controls='prev'] {
        left: -12.8px; }
      .slider-container.slider-buttons-inside-md [data-controls='next'] {
        right: -12.8px; }
      .slider-container.slider-buttons-inside-md.control-style-newSliderControl .tns-controls button[data-controls='prev'] {
        left: 16px; }
      .slider-container.slider-buttons-inside-md.control-style-newSliderControl .tns-controls button[data-controls='next'] {
        right: 16px; }
    .slider-container.slider-bullets-inside-md {
      margin-bottom: 0; }
      .slider-container.slider-bullets-inside-md .tns-nav {
        bottom: 0; }
    .slider-container.next-slide-md-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.prev-slide-md-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.next-slide-md-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.prev-slide-md-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.next-slide-md-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.prev-slide-md-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.next-slide-md-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.prev-slide-md-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.next-slide-md-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.prev-slide-md-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.next-slide-md-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.prev-slide-md-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.next-slide-md-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.prev-slide-md-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.next-slide-md-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.prev-slide-md-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.next-slide-md-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.prev-slide-md-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.next-slide-md-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.prev-slide-md-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.next-slide-md-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.prev-slide-md-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.next-slide-md-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.prev-slide-md-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.next-slide-md-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.prev-slide-md-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.next-slide-md-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.prev-slide-md-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.next-slide-md-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.prev-slide-md-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.next-slide-md-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.prev-slide-md-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.next-slide-md-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.prev-slide-md-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.next-slide-md-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.prev-slide-md-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.next-slide-md-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.prev-slide-md-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.next-slide-md-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.prev-slide-md-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.next-slide-md-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.prev-slide-md-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.next-slide-md-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.prev-slide-md-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.next-slide-md-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.prev-slide-md-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.next-slide-md-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.prev-slide-md-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.next-slide-md-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.prev-slide-md-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.next-slide-md-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.prev-slide-md-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.next-slide-md-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.prev-slide-md-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.next-slide-md-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.prev-slide-md-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.next-slide-md-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.prev-slide-md-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.next-slide-md-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.prev-slide-md-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.next-slide-md-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.prev-slide-md-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.next-slide-md-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.prev-slide-md-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.next-slide-md-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.prev-slide-md-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.next-slide-md-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.prev-slide-md-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.next-slide-md-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.prev-slide-md-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.next-slide-md-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.prev-slide-md-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.next-slide-md-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.prev-slide-md-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.next-slide-md-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.prev-slide-md-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.next-slide-md-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.prev-slide-md-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.next-slide-md-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.prev-slide-md-40 .tns-inner {
      padding-left: 40%; } }
  @media (min-width: 1024px) {
    .slider-container.slider-buttons-hidden-lg {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden-lg .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden-lg {
      margin-bottom: 0; }
      .slider-container.slider-bullets-hidden-lg .tns-nav {
        display: none; }
    .slider-container.slider-buttons-inside-lg {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-inside-lg [data-controls='prev'] {
        left: -12.8px; }
      .slider-container.slider-buttons-inside-lg [data-controls='next'] {
        right: -12.8px; }
      .slider-container.slider-buttons-inside-lg.control-style-newSliderControl .tns-controls button[data-controls='prev'] {
        left: 32px; }
      .slider-container.slider-buttons-inside-lg.control-style-newSliderControl .tns-controls button[data-controls='next'] {
        right: 32px; }
    .slider-container.slider-bullets-inside-lg {
      margin-bottom: 0; }
      .slider-container.slider-bullets-inside-lg .tns-nav {
        bottom: 0; }
    .slider-container.next-slide-lg-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.prev-slide-lg-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.next-slide-lg-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.prev-slide-lg-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.next-slide-lg-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.prev-slide-lg-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.next-slide-lg-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.prev-slide-lg-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.next-slide-lg-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.prev-slide-lg-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.next-slide-lg-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.prev-slide-lg-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.next-slide-lg-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.prev-slide-lg-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.next-slide-lg-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.prev-slide-lg-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.next-slide-lg-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.prev-slide-lg-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.next-slide-lg-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.prev-slide-lg-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.next-slide-lg-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.prev-slide-lg-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.next-slide-lg-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.prev-slide-lg-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.next-slide-lg-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.prev-slide-lg-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.next-slide-lg-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.prev-slide-lg-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.next-slide-lg-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.prev-slide-lg-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.next-slide-lg-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.prev-slide-lg-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.next-slide-lg-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.prev-slide-lg-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.next-slide-lg-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.prev-slide-lg-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.next-slide-lg-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.prev-slide-lg-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.next-slide-lg-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.prev-slide-lg-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.next-slide-lg-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.prev-slide-lg-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.next-slide-lg-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.prev-slide-lg-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.next-slide-lg-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.prev-slide-lg-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.next-slide-lg-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.prev-slide-lg-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.next-slide-lg-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.prev-slide-lg-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.next-slide-lg-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.prev-slide-lg-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.next-slide-lg-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.prev-slide-lg-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.next-slide-lg-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.prev-slide-lg-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.next-slide-lg-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.prev-slide-lg-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.next-slide-lg-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.prev-slide-lg-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.next-slide-lg-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.prev-slide-lg-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.next-slide-lg-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.prev-slide-lg-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.next-slide-lg-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.prev-slide-lg-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.next-slide-lg-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.prev-slide-lg-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.next-slide-lg-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.prev-slide-lg-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.next-slide-lg-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.prev-slide-lg-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.next-slide-lg-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.prev-slide-lg-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.next-slide-lg-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.prev-slide-lg-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.next-slide-lg-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.prev-slide-lg-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.next-slide-lg-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.prev-slide-lg-40 .tns-inner {
      padding-left: 40%; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active {
      margin-left: 0;
      margin-right: 0; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active .slider {
      display: flex;
      flex-wrap: nowrap;
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0 -20px 5px;
      -ms-overflow-style: none;
      scrollbar-width: none; }
      .slider-container.scroll-slider-active .slider::-webkit-scrollbar {
        display: none; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active .slide {
      transform: none;
      flex: 0 0 calc(75% - 20px);
      padding: 0 10px;
      display: block; } }
  .slider-container.scroll-slider-active .slide .image-text-block.text-placement-inside {
    height: 100%; }
    .slider-container.scroll-slider-active .slide .image-text-block.text-placement-inside .image-cropper {
      height: 100%; }
      .slider-container.scroll-slider-active .slide .image-text-block.text-placement-inside .image-cropper.overlay::after {
        z-index: 2; }
    .slider-container.scroll-slider-active .slide .image-text-block.text-placement-inside .image-wrapper {
      height: 100%; }
  .slider-container.scroll-slider-active .slide .image-text-block .video-container {
    z-index: 2;
    overflow: hidden;
    border-radius: 20px; }
  .slider-container.scroll-slider-active .simplebar-content {
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .slider-container.scroll-slider-active .simplebar-content::-webkit-scrollbar {
      display: none; }
  .slider-container.scroll-slider-active .simplebar-track.simplebar-horizontal {
    background-color: #D4D4D4;
    border-radius: 8px;
    height: 4px;
    left: 1px;
    right: 1px; }
    .slider-container.scroll-slider-active .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
      height: 4px;
      top: 0; }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"] .slide {
      flex: 0 0 100%; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"].next-slide-13 .slide {
      flex: 0 0 90%; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"].next-slide-13 .slide:last-child {
      margin-right: 16px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"].next-slide-23 .slide {
      flex: 0 0 85%; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"].next-slide-23 .slide:last-child {
      margin-right: 16px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"].next-slide-33 .slide {
      flex: 0 0 80%; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"].next-slide-33 .slide:last-child {
      margin-right: 16px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="1"].next-slide-40 .slide {
      flex: 0 0 75%; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"] .slide {
      flex: 0 0 calc(55% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"].next-slide-8 .slide {
      flex: 0 0 calc(52.5% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"].next-slide-8 .slide:last-child {
      margin-right: 16px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"].next-slide-12 .slide {
      flex: 0 0 calc(50% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"].next-slide-12 .slide:last-child {
      margin-right: 16px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"].next-slide-17 .slide {
      flex: 0 0 calc(47.5% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"].next-slide-17 .slide:last-child {
      margin-right: 16px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="2"].next-slide-22 .slide {
      flex: 0 0 calc(45% - 20px); } }
  .slider-container.scroll-slider-active[data-slider-items="3"] .slider {
    padding-right: 20px; }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"] .slide {
      flex: 0 0 calc(33.33% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-6 .slide .category-tile {
      padding: 16px 14px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-6 .slide {
      flex: 0 0 calc(32.32% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-9 .slide .category-tile {
      padding: 16px 12px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-9 .slide {
      flex: 0 0 calc(31.31% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-12 .slide .category-tile {
      padding: 16px 10px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-12 .slide {
      flex: 0 0 calc(30.30% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-15 .slide .category-tile {
      padding: 16px 8px; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active[data-slider-items="3"].next-slide-15 .slide {
      flex: 0 0 calc(29.29% - 20px); } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active.next-slide-40.slider-buttons-hidden {
      margin-left: 0;
      margin-right: 0; } }
  @media (max-width: 768.98px) {
    .slider-container.scroll-slider-active.next-slide-22.slider-buttons-hidden {
      margin-left: 0;
      margin-right: 0; } }
  .slider-container.dark .tns-nav-controller {
    cursor: pointer; }
    .slider-container.dark .tns-nav-controller:before {
      content: url("../images/icons/pause.svg");
      font-family: "icomoon";
      display: inline-block;
      font-size: 25px;
      color: #181818;
      font-weight: normal; }
    .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title).btn-sm, .slider-container.dark .btn-group-sm > .tns-nav-controller.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title).btn-sm:before, .slider-container.dark .btn-group-sm > .tns-nav-controller.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title).btn-lg, .slider-container.dark .btn-group-lg > .tns-nav-controller.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title).btn-lg:before, .slider-container.dark .btn-group-lg > .tns-nav-controller.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container.dark .tns-nav-controller[class*="btn-"]:not(.title) .fa {
        display: none; }
    .slider-container.dark .tns-nav-controller.pause:before {
      content: url("../images/icons/play.svg");
      font-family: "icomoon";
      display: inline-block;
      font-size: 25px;
      color: #181818;
      font-weight: normal; }
    .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-sm, .slider-container.dark .btn-group-sm > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-sm:before, .slider-container.dark .btn-group-sm > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-lg, .slider-container.dark .btn-group-lg > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-lg:before, .slider-container.dark .btn-group-lg > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container.dark .tns-nav-controller.pause[class*="btn-"]:not(.title) .fa {
        display: none; }
    .slider-container.dark .tns-nav-controller:focus {
      outline: 2px solid #FFFFFF; }
  @media (max-width: 768.98px) {
    .slider-container.hide-quick-add-to-cart .quick-add-to-cart .js-quick-add-to-cart {
      display: none; } }
  @media (max-width: 768.98px) {
    .slider-container .product-tile .product-name-wrapper {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      padding: 0 8px; } }
  @media (max-width: 768.98px) and (min-width: 1024px) {
    .slider-container .product-tile .product-name-wrapper {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  @media (max-width: 768.98px) {
    .slider-container .product-tile .product-tile--buy-container {
      padding: 0 8px; } }
  @media (max-width: 768.98px) {
    .slider-container .product-tile .product-tile--buy-container .price {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  @media (max-width: 768.98px) and (min-width: 1024px) {
    .slider-container .product-tile .product-tile--buy-container .price {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.tns-nav-controller {
  cursor: pointer; }
  .tns-nav-controller:before {
    content: url("../images/icons/pause.svg");
    font-family: "icomoon";
    display: inline-block;
    font-size: 25px;
    color: #F9F9F9;
    font-weight: normal; }
  .tns-nav-controller[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .tns-nav-controller[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #F9F9F9;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .tns-nav-controller[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .tns-nav-controller.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .tns-nav-controller[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .tns-nav-controller.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #F9F9F9;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .tns-nav-controller[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .tns-nav-controller.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .tns-nav-controller[class*="btn-"]:not(.title).btn-lg:before, .btn-group-lg > .tns-nav-controller.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #F9F9F9;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .tns-nav-controller[class*="btn-"]:not(.title) [class*="icon-"],
    .tns-nav-controller[class*="btn-"]:not(.title) [class^="icon-"],
    .tns-nav-controller[class*="btn-"]:not(.title) .fa {
      display: none; }
  .tns-nav-controller.pause:before {
    content: url("../images/icons/play.svg");
    font-family: "icomoon";
    display: inline-block;
    font-size: 25px;
    color: #F9F9F9;
    font-weight: normal; }
  .tns-nav-controller.pause[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .tns-nav-controller.pause[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #F9F9F9;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #F9F9F9;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-lg:before, .btn-group-lg > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #F9F9F9;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .tns-nav-controller.pause[class*="btn-"]:not(.title) [class*="icon-"],
    .tns-nav-controller.pause[class*="btn-"]:not(.title) [class^="icon-"],
    .tns-nav-controller.pause[class*="btn-"]:not(.title) .fa {
      display: none; }
  .tns-nav-controller:focus {
    outline: 2px solid #FFFFFF; }
  .tns-nav-controller:not(.with-pagination) {
    position: absolute;
    padding: 5px;
    z-index: 2; }
  .tns-nav-controller.top-right {
    top: 0;
    right: 0; }
    @media (max-width: 768.98px) {
      .tns-nav-controller.top-right {
        top: 50%;
        transform: translateY(-50%); } }
  .tns-nav-controller.top-left {
    top: 0;
    left: 0; }
  .tns-nav-controller.bottom-right {
    bottom: 0;
    right: 0; }
  .tns-nav-controller.bottom-left {
    bottom: 0;
    left: 0; }
  .tns-nav-controller.with-pagination {
    padding-right: 5px; }

.slider-thumbnails {
  padding-left: 28px;
  padding-right: 28px;
  user-select: none; }
  .slider-thumbnails .slider {
    overflow: auto;
    text-align: center;
    padding-bottom: 50px;
    margin-bottom: -50px;
    display: block; }
  .slider-thumbnails .slide {
    min-width: 85px;
    max-width: 85px;
    flex-basis: 85px; }
    @media (min-width: 769px) {
      .slider-thumbnails .slide {
        max-width: 85px;
        flex-basis: 85px;
        background: #F9F9F9;
        padding: 16px 8px;
        border-radius: 20px; } }
    @media (min-width: 1024px) {
      .slider-thumbnails .slide {
        min-width: 85px;
        max-width: 85px;
        flex-basis: 85px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) {
      .slider-thumbnails .slide {
        padding-right: 8px !important; }
        .slider-thumbnails .slide + .slide {
          margin-top: 8px; } }
    .slider-thumbnails .slide > * {
      width: 100%; }
    .slider-thumbnails .slide::after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-width: 0;
      border-color: transparent;
      transition: border 200ms ease-out; }
    .slider-thumbnails .slide.tns-nav-active::after {
      border-width: 0;
      border-color: #222222; }
  @media (max-width: 768.98px) {
    .slider-thumbnails.slider-thumbnail-dots .slide {
      min-width: 8px;
      max-width: 8px;
      height: 8px;
      margin: 16px 8px 16px 8px; }
      .slider-thumbnails.slider-thumbnail-dots .slide:focus {
        outline: none; }
      .slider-thumbnails.slider-thumbnail-dots .slide:after {
        background: #D4D4D4;
        border: none;
        border-radius: 50%; }
      .slider-thumbnails.slider-thumbnail-dots .slide.tns-nav-active::after {
        background: #181818;
        border: none;
        transform: scale(1.5); }
      .slider-thumbnails.slider-thumbnail-dots .slide img {
        display: none !important; } }
  .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide {
    min-width: 8px;
    max-width: 8px;
    height: 8px;
    margin: 16px 8px 16px 8px; }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide:focus {
      outline: none; }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide:after {
      background: #D4D4D4;
      border: none;
      border-radius: 50%; }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide.tns-nav-active::after {
      background: #181818;
      border: none;
      transform: scale(1.5); }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide img {
      display: none !important; }
  .slider-thumbnails.slider-thumbnail-dots .slider-thumbnail-controls {
    display: none; }
  .slider-thumbnails .tns-horizontal > .tns-item {
    vertical-align: middle; }
  .slider-thumbnails .slider-thumbnail-controls .prev,
  .slider-thumbnails .slider-thumbnail-controls .next {
    position: absolute;
    top: 0;
    height: 100%;
    width: 11px;
    cursor: pointer;
    user-select: none;
    background-color: #FFFFFF;
    transition: opacity 200ms ease-out; }
    .slider-thumbnails .slider-thumbnail-controls .prev::before,
    .slider-thumbnails .slider-thumbnail-controls .next::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .slider-thumbnails .slider-thumbnail-controls .prev:hover,
    .slider-thumbnails .slider-thumbnail-controls .next:hover {
      background-color: #FFFFFF; }
      .slider-thumbnails .slider-thumbnail-controls .prev:hover::before,
      .slider-thumbnails .slider-thumbnail-controls .next:hover::before {
        color: #222222; }
    .slider-thumbnails .slider-thumbnail-controls .prev[aria-disabled=true],
    .slider-thumbnails .slider-thumbnail-controls .next[aria-disabled=true] {
      opacity: 0; }
  .slider-thumbnails .slider-thumbnail-controls .prev {
    left: 0; }
    .slider-thumbnails .slider-thumbnail-controls .prev:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-sm, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .prev.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-sm:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .prev.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-lg, .slider-thumbnails .slider-thumbnail-controls .btn-group-lg > .prev.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-lg:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-lg > .prev.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-thumbnails .slider-thumbnail-controls .next {
    right: 0; }
    .slider-thumbnails .slider-thumbnail-controls .next:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #222222;
      font-weight: normal; }
    .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-sm, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .next.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-sm:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .next.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-lg, .slider-thumbnails .slider-thumbnail-controls .btn-group-lg > .next.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-lg:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-lg > .next.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #222222;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-thumbnails .tns-horizontal.tns-no-subpixel {
    white-space: nowrap; }
    .slider-thumbnails .tns-horizontal.tns-no-subpixel > .tns-item {
      float: none;
      display: inline-block;
      margin: 0;
      margin-left: 0 !important; }
  .slider-thumbnails .video-container .video-thumbnail-button::before {
    font-size: 26px; }
  @media (max-width: 768.98px) {
    .slider-thumbnails.slider-thumbnails-vertical {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical .slide {
        display: block;
        width: 100% !important;
        padding-right: 8px !important; }
        .slider-thumbnails.slider-thumbnails-vertical .slide + .slide {
          margin-top: 8px; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 85px; } }
    @media (max-width: 768.98px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        width: 85px; } }
    @media (max-width: 768.98px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        width: 85px; } }
  @media (max-width: 768.98px) {
        .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .slider-thumbnails.slider-thumbnails-vertical-md {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slide {
        display: block;
        width: 100% !important;
        padding-right: 8px !important; }
        .slider-thumbnails.slider-thumbnails-vertical-md .slide + .slide {
          margin-top: 8px; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 85px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        width: 85px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        width: 85px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
        .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; }
    .slider-thumbnails.slider-thumbnails-vertical-lg {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slide {
        display: block;
        width: 100% !important;
        padding-right: 8px !important; }
        .slider-thumbnails.slider-thumbnails-vertical-lg .slide + .slide {
          margin-top: 8px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 85px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 85px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 85px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }
  @media (min-width: 1024px) {
    .slider-thumbnails.slider-thumbnails-vertical-lg {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slide {
        display: block;
        width: 100% !important;
        padding-right: 8px !important; }
        .slider-thumbnails.slider-thumbnails-vertical-lg .slide + .slide {
          margin-top: 8px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 85px; } }
    @media (min-width: 1024px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 85px; } }
    @media (min-width: 1024px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 85px; } }
  @media (min-width: 1024px) {
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }

.experience-commerce_layouts-slider .slider.tns-slider.tns-carousel .slide.tns-item {
  padding-right: 32px !important; }

.experience-commerce_layouts-sliderEinstein .slider-container .tns-controls button {
  top: 33%;
  transform: translateY(-33%); }

.social-links {
  list-style: none;
  display: inline-flex;
  flex-flow: wrap;
  padding-left: 0px;
  margin-bottom: 0px; }
  .social-links li {
    margin: 20px 23px 20px 0px; }
  .social-links a {
    font-size: 25px;
    line-height: 25px;
    text-decoration: none; }
    .social-links a:hover {
      text-decoration: none; }
  .social-links .fa-linkedin-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-linkedin-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-linkedin-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-linkedin-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-linkedin-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-linkedin-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-linkedin-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-linkedin-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-facebook-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-facebook-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-facebook-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-facebook-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-facebook-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-facebook-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-twitter-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-twitter-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-twitter-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-twitter-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-twitter-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-twitter-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-youtube-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-youtube-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-youtube-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-youtube-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-youtube-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-youtube-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-pinterest-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-pinterest-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-pinterest-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-pinterest-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-pinterest-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-pinterest-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-pinterest-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-pinterest-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-email-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-email-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-email-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-email-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-email-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-email-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-email-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-email-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-email-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-email-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-email-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-email-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-email-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-email-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-link-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-link-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-link-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-link-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-link-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-link-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-link-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-link-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-link-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-link-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-link-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-link-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-link-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-link-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-instagram-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-instagram-square[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-instagram-square.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-instagram-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-instagram-square.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-instagram-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .share-link {
    font-size: initial; }

.social-icons {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .social-icons li {
    padding: 0; }
  .social-icons .fa-pinterest:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-pinterest[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-icons .fa-pinterest[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-pinterest[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-pinterest.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-icons .fa-pinterest[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-pinterest.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-pinterest[class*="btn-"]:not(.title).btn-lg, .social-icons .btn-group-lg > .fa-pinterest.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-icons .fa-pinterest[class*="btn-"]:not(.title).btn-lg:before, .social-icons .btn-group-lg > .fa-pinterest.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-pinterest[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-pinterest[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-pinterest[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .fa-facebook-official:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-facebook-official[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-facebook-official.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-facebook-official.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-lg, .social-icons .btn-group-lg > .fa-facebook-official.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-lg:before, .social-icons .btn-group-lg > .fa-facebook-official.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .fa-twitter:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-twitter[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-icons .fa-twitter[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-twitter.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-twitter.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-lg, .social-icons .btn-group-lg > .fa-twitter.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-lg:before, .social-icons .btn-group-lg > .fa-twitter.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-twitter[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-twitter[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .fa-link:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-link[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-icons .fa-link[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-link[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-link.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .social-icons .fa-link[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-link.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-link[class*="btn-"]:not(.title).btn-lg, .social-icons .btn-group-lg > .fa-link.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .social-icons .fa-link[class*="btn-"]:not(.title).btn-lg:before, .social-icons .btn-group-lg > .fa-link.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-link[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-link[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-link[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .share-link {
    text-decoration: none;
    display: block; }

.social-links .fa-tiktok-square:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.social-links .fa-tiktok-square[class*="btn-"]:not(.title) {
  padding-left: 42px; }
  .social-links .fa-tiktok-square[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .social-links .fa-tiktok-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-tiktok-square.btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-tiktok-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-tiktok-square.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .social-links .fa-tiktok-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-tiktok-square.btn[class*="btn-"]:not(.title) {
    padding-left: 63px; }
    .social-links .fa-tiktok-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-tiktok-square.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .social-links .fa-tiktok-square[class*="btn-"]:not(.title) [class*="icon-"],
  .social-links .fa-tiktok-square[class*="btn-"]:not(.title) [class^="icon-"],
  .social-links .fa-tiktok-square[class*="btn-"]:not(.title) .fa {
    display: none; }

.social-links .fa-x-square:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.social-links .fa-x-square[class*="btn-"]:not(.title) {
  padding-left: 42px; }
  .social-links .fa-x-square[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .social-links .fa-x-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-x-square.btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .social-links .fa-x-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-x-square.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .social-links .fa-x-square[class*="btn-"]:not(.title).btn-lg, .social-links .btn-group-lg > .fa-x-square.btn[class*="btn-"]:not(.title) {
    padding-left: 63px; }
    .social-links .fa-x-square[class*="btn-"]:not(.title).btn-lg:before, .social-links .btn-group-lg > .fa-x-square.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .social-links .fa-x-square[class*="btn-"]:not(.title) [class*="icon-"],
  .social-links .fa-x-square[class*="btn-"]:not(.title) [class^="icon-"],
  .social-links .fa-x-square[class*="btn-"]:not(.title) .fa {
    display: none; }

.refinementproducttype .slide,
.visual-category-tile__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 16px;
  height: 100%; }
  .refinementproducttype .slide .link,
  .visual-category-tile__container .link {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-weight: 500; }
    @media (min-width: 1024px) {
      .refinementproducttype .slide .link,
      .visual-category-tile__container .link {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    @media (min-width: 1024px) {
      .refinementproducttype .slide .link,
      .visual-category-tile__container .link {
        font-weight: 500; } }
  .refinementproducttype .slide img.slide,
  .visual-category-tile__container img.slide {
    margin-bottom: 16px;
    height: 75px;
    width: auto;
    display: block; }

#refinementproducttype .refinementproducttype {
  justify-content: start; }

.refinementproducttype {
  max-height: 490px;
  overflow-y: scroll; }
  .refinementproducttype .slide {
    background: #F9F9F9;
    border: 1px solid #F9F9F9;
    margin: 4px 8px;
    border: 0;
    border-radius: 20px; }
    @media (min-width: 769px) {
      .refinementproducttype .slide {
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        background: transparent; } }
    .refinementproducttype .slide .refinement-product-type-card {
      text-decoration: none;
      flex-direction: row; }
    .refinementproducttype .slide span.text-center {
      height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
  .refinementproducttype ul.values.content.row {
    display: none; }

.refinement-container .visual-category-slider {
  border-bottom: 1px solid #D4D4D4;
  padding: 16px; }
  @media (max-width: 768.98px) {
    .refinement-container .visual-category-slider {
      padding: 16px 0; }
      .refinement-container .visual-category-slider .tns-inner {
        padding-right: 20%; } }
  .refinement-container .visual-category-slider a {
    text-decoration: none; }
    .refinement-container .visual-category-slider a:hover {
      border-color: #181818; }
  .refinement-container .visual-category-slider #tns1 {
    display: flex;
    align-items: stretch; }
  .refinement-container .visual-category-slider .slider {
    min-width: 100%;
    align-items: flex-start;
    justify-content: center; }
    @media (max-width: 768.98px) {
      .refinement-container .visual-category-slider .slider {
        justify-content: flex-start; } }
  .refinement-container .visual-category-slider #tns2-iw {
    min-width: 100%; }
  @media (max-width: 768.98px) {
    .refinement-container .visual-category-slider [data-controls='prev'] {
      left: -10px;
      display: none; }
    .refinement-container .visual-category-slider [data-controls='next'] {
      right: -10px;
      display: none; } }
  .refinement-container .visual-category-slider .slider .slide {
    max-width: 224px; }
    @media (max-width: 768.98px) {
      .refinement-container .visual-category-slider .slider .slide {
        max-width: 160px; } }
  .refinement-container .visual-category-slider.less-three .slider {
    justify-content: center; }
  .refinement-container .visual-category-slider.less-three .tns-ovh .tns-inner {
    padding-right: 0 !important; }

.refinements .visual-category-slider .slider.tns-horizontal {
  display: flex; }
  .refinements .visual-category-slider .slider.tns-horizontal .slide.tns-item {
    margin-right: 16px !important; }

.card-body.content.value#refinement-group-refinementproducttype {
  display: none;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .card-body.content.value#refinement-group-refinementproducttype::-webkit-scrollbar {
    display: none; }
  .card-body.content.value#refinement-group-refinementproducttype .slide {
    min-width: 130px; }

.collapsible-xl.active .card-body.content.value#refinement-group-refinementproducttype {
  display: block; }
  @media (min-width: 1024px) {
    .collapsible-xl.active .card-body.content.value#refinement-group-refinementproducttype {
      display: flex;
      border-radius: 0; } }

.veil {
  position: absolute;
  z-index: 1000;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .veil .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000000; }

.spinner {
  width: 80px;
  height: 80px;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px; }

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #FFFFFF;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out; }

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s; }

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

.nav-tabs {
  border-bottom: none; }
  .nav-tabs .nav-link {
    font: 14px "Inter", sans-serif;
    text-transform: none;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0.05em;
    padding: 15px 16px 11px 16px;
    border-radius: 20px 20px 0 0;
    background-color: transparent;
    color: #595959;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #F9F9F9;
    border-left: none; }
    .nav-tabs .nav-link:hover {
      text-decoration: none;
      background-color: transparent;
      color: #181818;
      border-top: none;
      border-right: none;
      border-bottom: 1px solid #F9F9F9;
      border-left: none; }
    .nav-tabs .nav-link.active {
      text-decoration: none;
      background-color: transparent;
      color: #181818;
      border-top: 1px solid #F9F9F9;
      border-right: 1px solid #F9F9F9;
      border-bottom: none;
      border-left: 1px solid #F9F9F9; }
  .nav-tabs .nav-item:only-child .nav-link {
    border: none;
    background: transparent; }

.info-icon,
.tooltip-icon {
  border: none;
  padding: 0;
  position: relative;
  top: -2px;
  background: inherit;
  display: inline-block;
  cursor: pointer;
  font: inherit; }
  .info-icon:before,
  .tooltip-icon:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 120%;
    color: #181818;
    font-weight: normal; }
  .info-icon[class*="btn-"]:not(.title),
  .tooltip-icon[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .info-icon[class*="btn-"]:not(.title):before,
    .tooltip-icon[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .info-icon[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .info-icon.btn[class*="btn-"]:not(.title),
    .tooltip-icon[class*="btn-"]:not(.title).btn-sm,
    .btn-group-sm > .tooltip-icon.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .info-icon[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .info-icon.btn[class*="btn-"]:not(.title):before,
      .tooltip-icon[class*="btn-"]:not(.title).btn-sm:before,
      .btn-group-sm > .tooltip-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .info-icon[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .info-icon.btn[class*="btn-"]:not(.title),
    .tooltip-icon[class*="btn-"]:not(.title).btn-lg,
    .btn-group-lg > .tooltip-icon.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .info-icon[class*="btn-"]:not(.title).btn-lg:before, .btn-group-lg > .info-icon.btn[class*="btn-"]:not(.title):before,
      .tooltip-icon[class*="btn-"]:not(.title).btn-lg:before,
      .btn-group-lg > .tooltip-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .info-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .info-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .info-icon[class*="btn-"]:not(.title) .fa,
    .tooltip-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .tooltip-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .tooltip-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .info-icon .icon,
  .tooltip-icon .icon {
    display: none; }
  .info-icon:hover:before, .info-icon[aria-describedby*="tooltip"]:before,
  .tooltip-icon:hover:before,
  .tooltip-icon[aria-describedby*="tooltip"]:before {
    color: #595959; }

.tooltip.bs-tooltip-right .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #FFFFFF; }

.tooltip.bs-tooltip-left .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #FFFFFF; }

.tooltip.bs-tooltip-top .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #FFFFFF; }

.tooltip.bs-tooltip-bottom .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #FFFFFF; }

.tooltip .tooltip-inner {
  border-radius: 20px;
  padding: 12px 25px;
  text-align: center;
  max-width: 200px;
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.1); }
  .tooltip .tooltip-inner:not([class*='background--']) {
    background: #FFFFFF; }
  .tooltip .tooltip-inner:not([class*='text--']) {
    color: #222222; }
  .tooltip .tooltip-inner:not([class*='font-size--']) {
    font-size: 10px; }

.tooltip.show {
  opacity: 1; }

.video-container {
  position: relative;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%; }
  .video-container.aspect-ratio-16-9::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-16-9::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%); }
  .video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%); }
  .video-container.aspect-ratio-9-16::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-9-16::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(16 / 9 * 100%); }
  .video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(16 / 9 * 100%); }
  .video-container.aspect-ratio-4-3::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-4-3::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .video-container.aspect-ratio-3-4::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-3-4::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .video-container.aspect-ratio-1-1::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-1-1::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .video-container .video-player,
  .video-container .video-player iframe,
  .video-container video.vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-container .video-thumbnail:not(.btn) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
    transition: opacity 200ms;
    z-index: 1; }
    .video-container .video-thumbnail:not(.btn).faded {
      opacity: 0;
      transition: opacity 200ms; }
      .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button:before {
        content: url("../images/icons/pause.svg");
        font-family: "icomoon";
        display: inline-block;
        font-size: 50px;
        color: #FFFFFF;
        font-weight: normal; }
      .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm, .video-container .video-thumbnail:not(.btn).faded .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title) {
          padding-left: 42px; }
          .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm:before, .video-container .video-thumbnail:not(.btn).faded .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title).btn-lg, .video-container .video-thumbnail:not(.btn).faded .btn-group-lg > .video-thumbnail-button.btn[class*="btn-"]:not(.title) {
          padding-left: 63px; }
          .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title).btn-lg:before, .video-container .video-thumbnail:not(.btn).faded .btn-group-lg > .video-thumbnail-button.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) [class*="icon-"],
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) [class^="icon-"],
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) .fa {
          display: none; }
      .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-image {
        opacity: 0;
        pointer-events: none; }
      .video-container .video-thumbnail:not(.btn).faded:hover {
        opacity: 1; }
    .video-container .video-thumbnail:not(.btn):focus .video-thumbnail-button:before, .video-container .video-thumbnail:not(.btn):hover:not(.faded) .video-thumbnail-button:before {
      color: #595959;
      transform: scale(1.25); }
  .video-container.has-controls .video-thumbnail.faded {
    pointer-events: none; }
    .video-container.has-controls .video-thumbnail.faded .video-thumbnail-button {
      opacity: 0; }
  .video-container.has-controls .video-js .vjs-big-play-button,
  .video-container.has-controls .video-js .vjs-poster {
    display: none; }
  .video-container .video-thumbnail-button {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%); }
    .video-container .video-thumbnail-button:before {
      content: url("../images/icons/play.svg");
      font-family: "icomoon";
      display: inline-block;
      font-size: 50px;
      color: #FFFFFF;
      font-weight: normal; }
    .video-container .video-thumbnail-button[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm, .video-container .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm:before, .video-container .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-lg, .video-container .btn-group-lg > .video-thumbnail-button.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-lg:before, .video-container .btn-group-lg > .video-thumbnail-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) [class*="icon-"],
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) [class^="icon-"],
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) .fa {
        display: none; }
    .video-container .video-thumbnail-button:before {
      transition: all 200ms ease-in-out; }
  .video-container .video-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .video-container .video-thumbnail-image.background-image-tablet, .video-container .video-thumbnail-image.background-image-desktop {
      display: none; }
    @media (min-width: 769px) {
      .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-desktop {
        display: none; }
      .video-container .video-thumbnail-image.background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-tablet {
        display: none; }
      .video-container .video-thumbnail-image.background-image-desktop {
        display: block; } }

.video-modal .modal-dialog {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .video-modal .modal-dialog {
      max-width: 900px;
      margin: 32px auto; } }
  @media (min-width: 1440px) {
    .video-modal .modal-dialog {
      max-width: 1140px; } }

.video-modal .modal-body {
  padding: 0; }

.video-modal .video-player {
  width: 100%; }

.video-container.rounded-corners {
  overflow: hidden; }
  .video-container.rounded-corners iframe,
  .video-container.rounded-corners video {
    overflow: hidden;
    border-radius: 20px; }
  .video-container.rounded-corners .video-thumbnail,
  .video-container.rounded-corners .video-thumbnail-image {
    overflow: hidden;
    border-radius: 20px; }

.video-container.video-with-background {
  padding: 20px 16px 72px; }
  @media (min-width: 1024px) {
    .video-container.video-with-background {
      padding: 106px 32px; } }
  .video-container.video-with-background .headline-m {
    font-family: "Inter", sans-serif;
    margin-bottom: 20px; }
    @media (min-width: 1024px) {
      .video-container.video-with-background .headline-m {
        margin-bottom: 64px; } }

.video-text-block-text {
  padding-top: 24px; }

.add-to-wishlist-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.wishlist {
  white-space: nowrap;
  text-decoration: none; }
  .wishlist:hover {
    text-decoration: none; }
  .wishlist:not(.move) {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    z-index: 50; }
    @media (min-width: 544px) {
      .wishlist:not(.move) {
        top: 10px;
        right: 10px; } }
  .wishlist .wishlist-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 50%; }
    .wishlist .wishlist-icon:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: #181818;
      font-weight: normal; }
    .wishlist .wishlist-icon[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .wishlist .wishlist-icon[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .wishlist .wishlist-icon[class*="btn-"]:not(.title).btn-sm, .wishlist .btn-group-sm > .wishlist-icon.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .wishlist .wishlist-icon[class*="btn-"]:not(.title).btn-sm:before, .wishlist .btn-group-sm > .wishlist-icon.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .wishlist .wishlist-icon[class*="btn-"]:not(.title).btn-lg, .wishlist .btn-group-lg > .wishlist-icon.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .wishlist .wishlist-icon[class*="btn-"]:not(.title).btn-lg:before, .wishlist .btn-group-lg > .wishlist-icon.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .wishlist .wishlist-icon[class*="btn-"]:not(.title) [class*="icon-"],
      .wishlist .wishlist-icon[class*="btn-"]:not(.title) [class^="icon-"],
      .wishlist .wishlist-icon[class*="btn-"]:not(.title) .fa {
        display: none; }
    .wishlist .wishlist-icon.selected:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: #181818;
      font-weight: normal; }
    .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title).btn-sm, .wishlist .btn-group-sm > .wishlist-icon.selected.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title).btn-sm:before, .wishlist .btn-group-sm > .wishlist-icon.selected.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title).btn-lg, .wishlist .btn-group-lg > .wishlist-icon.selected.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title).btn-lg:before, .wishlist .btn-group-lg > .wishlist-icon.selected.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) .fa {
        display: none; }
    .wishlist .wishlist-icon.before::before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: 1px;
      padding-left: 1px; }

.wishlistItemCards .select-attributes-btn {
  white-space: nowrap; }

.wishlistItemCards .line-item-availability .line-item-attributes {
  white-space: normal; }

.btn-update-wishlist-product {
  width: 100%; }

.wishlist-remove-btn {
  font-family: "Inter", sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: 32px 16px 32px 16px;
  cursor: pointer;
  z-index: 1;
  background-color: #FFFFFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  margin: 5px 0px 0px 16px;
  left: 0; }
  .wishlist-remove-btn:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .wishlist-remove-btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .wishlist-remove-btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .wishlist-remove-btn.btn[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .wishlist-remove-btn[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .wishlist-remove-btn.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title).btn-lg, .btn-group-lg > .wishlist-remove-btn.btn[class*="btn-"]:not(.title) {
      padding-left: 63px; }
      .wishlist-remove-btn[class*="btn-"]:not(.title).btn-lg:before, .btn-group-lg > .wishlist-remove-btn.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
    .wishlist-remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
    .wishlist-remove-btn[class*="btn-"]:not(.title) .fa {
      display: none; }
  @media (min-width: 1440px) {
    .wishlist-remove-btn {
      margin-left: 28px; } }

.wishlist-not-available {
  color: #a80b1d; }

.wishlistItemCards .select-attributes-btn {
  white-space: nowrap; }

.wishlistItemCards .line-item-availability {
  margin-bottom: 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .wishlistItemCards .line-item-availability {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .wishlistItemCards .line-item-availability .line-item-attributes {
    white-space: normal; }
  .wishlistItemCards .line-item-availability .notavailabe-cart {
    color: #a80b1d; }
    .wishlistItemCards .line-item-availability .notavailabe-cart:after {
      display: none; }

.wishlistItemCards .product-info .line-item-name {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (max-width: 1023.98px) {
    .wishlistItemCards .product-info .line-item-name {
      width: calc(100% - 50px); } }
  @media (min-width: 1024px) {
    .wishlistItemCards .product-info .line-item-name {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.wishlistItemCards .product-info .item-attributes {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .wishlistItemCards .product-info .item-attributes {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  @media (min-width: 1024px) {
    .wishlistItemCards .product-info .item-attributes {
      padding: 8px 0;
      width: calc(100% - 196px); } }
  .wishlistItemCards .product-info .item-attributes .line-item-attributes {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .wishlistItemCards .product-info .item-attributes .line-item-attributes {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .wishlistItemCards .product-info .item-attributes .line-item-price {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .wishlistItemCards .product-info .item-attributes .line-item-price {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

.wishlistItemCards .btn.add-to-cart, .wishlistItemCards .btn.add-to-cart-global {
  height: 36px;
  min-height: 36px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .wishlistItemCards .btn.add-to-cart, .wishlistItemCards .btn.add-to-cart-global {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .wishlistItemCards .btn.add-to-cart:after, .wishlistItemCards .btn.add-to-cart-global:after {
    background-color: #181818; }

.wishlistItemCards .wishlist-remove-btn {
  margin: 0;
  background: transparent;
  top: -5px;
  left: auto;
  right: 4px;
  color: #000000; }
  @media (min-width: 1024px) {
    .wishlistItemCards .wishlist-remove-btn {
      right: 12px;
      top: 3px; } }
  .wishlistItemCards .wishlist-remove-btn::before {
    color: #000000; }

.wishlistItemCards .product-info .item-image {
  width: 118px; }
  @media (min-width: 1024px) {
    .wishlistItemCards .product-info .item-image {
      width: 180px; } }

.wishlistItemCards .card-body {
  border-radius: 24px !important;
  padding: 24px 16px 24px 6.5px; }
  @media (min-width: 1024px) {
    .wishlistItemCards .card-body {
      border-radius: 20px !important;
      padding: 16px; } }

.add-to-wishlist-messages {
  top: auto;
  right: 0;
  transform: none;
  bottom: 0;
  left: 0;
  padding: 32px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px -12px 44px 0px rgba(138, 138, 138, 0.12); }
  @media (min-width: 1024px) {
    .add-to-wishlist-messages {
      top: auto;
      left: auto;
      right: 40px;
      bottom: 70px;
      width: 400px; } }
  .add-to-wishlist-messages .add-to-wishlist-alert {
    background: transparent !important;
    border-color: transparent !important;
    margin-bottom: 0;
    padding: 0;
    position: static;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    font-family: "Space Grotesk", sans-serif; }
    @media (min-width: 1024px) {
      .add-to-wishlist-messages .add-to-wishlist-alert {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        line-height: 32px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    @media (min-width: 1024px) {
      .add-to-wishlist-messages .add-to-wishlist-alert {
        font-family: "Space Grotesk", sans-serif; } }
  .add-to-wishlist-messages .wishlist-product-name {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
    margin-bottom: 35px; }
  .add-to-wishlist-messages .btn.btn-primary {
    height: 56px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center; }
  .add-to-wishlist-messages .btn.close {
    position: absolute;
    top: 23px;
    right: 16px;
    padding: 0;
    opacity: 1;
    font-size: 16px; }

.dont-lose-wishlist-msg {
  border-radius: 12px;
  border: 0.75px solid #D4D4D4;
  padding: 24px 16px 20px;
  margin-bottom: 12px; }
  @media (min-width: 1024px) {
    .dont-lose-wishlist-msg {
      padding: 24px 7px 24px 16px;
      margin-bottom: 32px; } }
  .dont-lose-wishlist-msg p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .dont-lose-wishlist-msg p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .dont-lose-wishlist-msg .btn-link {
    color: #181818;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    vertical-align: baseline;
    margin-top: 10px;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    .dont-lose-wishlist-msg .btn-link:hover {
      color: rgba(24, 24, 24, 0.8);
      text-decoration: underline; }
    .dark-theme .dont-lose-wishlist-msg .btn-link {
      color: #FFFFFF; }
      .dark-theme .dont-lose-wishlist-msg .btn-link:hover {
        color: #FFFFFF; }
    @media (min-width: 1024px) {
      .dont-lose-wishlist-msg .btn-link {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .dont-lose-wishlist-msg .btn-block.btn-primary {
    margin-bottom: 12px; }

.wishlist-counter {
  padding: 20px 0 12px; }
  @media (min-width: 1024px) {
    .wishlist-counter {
      padding: 32px 0; } }

.login-page .card-body {
  border-radius: 24px !important;
  padding: 32px 16px 16px;
  margin-bottom: 12px; }
  @media (min-width: 1024px) {
    .login-page .card-body {
      padding: 28px 16px 18px; } }

.login-page .login-form-nav .nav-tabs .nav-link {
  color: #000000;
  padding-top: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .login-page .login-form-nav .nav-tabs .nav-link {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .login-page .login-form-nav .nav-tabs .nav-link.active {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .login-page .login-form-nav .nav-tabs .nav-link.active {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

.login-page .login-form-nav .nav-tabs .nav-item {
  padding: 0 16px 4px 16px; }
  .login-page .login-form-nav .nav-tabs .nav-item:first-child .nav-link {
    text-align: right; }

.login-page .login .form-group .form-control-label,
.login-page .login .form-group .custom-control-label,
.login-page .registration .form-group .form-control-label,
.login-page .registration .form-group .custom-control-label {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .login-page .login .form-group .form-control-label,
    .login-page .login .form-group .custom-control-label,
    .login-page .registration .form-group .form-control-label,
    .login-page .registration .form-group .custom-control-label {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.login-page .login .forgot-password a,
.login-page .registration .forgot-password a {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .login-page .login .forgot-password a,
    .login-page .registration .forgot-password a {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.login-page .login .privacy-policy,
.login-page .registration .privacy-policy {
  padding: 8px 80px 16px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .login-page .login .privacy-policy,
    .login-page .registration .privacy-policy {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .login-page .login .privacy-policy a,
  .login-page .registration .privacy-policy a {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .login-page .login .privacy-policy a,
      .login-page .registration .privacy-policy a {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

.login-page .login .validator-container .password-validation-helper li,
.login-page .registration .validator-container .password-validation-helper li {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .login-page .login .validator-container .password-validation-helper li,
    .login-page .registration .validator-container .password-validation-helper li {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.login-page .login .btn.btn-block.btn-primary,
.login-page .registration .btn.btn-block.btn-primary {
  margin: 48px auto 8px; }
  @media (min-width: 1024px) {
    .login-page .login .btn.btn-block.btn-primary,
    .login-page .registration .btn.btn-block.btn-primary {
      width: 100%; } }

.login-page .registration .btn.btn-block.btn-primary {
  margin: 18px auto 16px; }
  @media (min-width: 1024px) {
    .login-page .registration .btn.btn-block.btn-primary {
      margin: 48px auto 8px; } }

@media (min-width: 1024px) {
  .login-page .login-oauth {
    padding: 0 16px 0; } }

.consent-drawer {
  position: fixed;
  display: block;
  align-items: center;
  bottom: 0;
  width: 100%;
  min-height: 85px;
  z-index: 2147483601;
  background-color: #FFAF80;
  border-top: 0 solid #FF748B; }
  @media (min-width: 769px) {
    .consent-drawer {
      display: flex; } }
  .consent-drawer p {
    margin: 4px;
    font-size: 16px;
    line-height: 24px; }
  .consent-drawer .consent-text-wrapper {
    display: block;
    width: 100%;
    padding: 10px 0 10px 14px; }
    @media (min-width: 769px) {
      .consent-drawer .consent-text-wrapper {
        width: auto;
        display: inline-block;
        padding: 16px; } }
  .consent-drawer .consent-button-wrapper {
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 10px; }
    .consent-drawer .consent-button-wrapper button {
      width: 50%; }
    @media (min-width: 769px) {
      .consent-drawer .consent-button-wrapper {
        display: inline-block;
        width: auto;
        padding: 0 16px;
        margin-left: auto; }
        .consent-drawer .consent-button-wrapper button {
          width: auto; } }

.tab-section {
  padding: 0 16px; }
  @media (max-width: 768.98px) {
    .tab-section .container {
      padding: 0; } }
  .tab-section .tabs-holder {
    overflow: hidden; }
    @media (max-width: 1023.98px) {
      .tab-section .tabs-holder .tab-wrapper {
        display: block !important; } }
    .tab-section .tabs-holder .container {
      padding: 0 16px; }
      @media (min-width: 769px) {
        .tab-section .tabs-holder .container {
          padding: 0 32px; } }
      .tab-section .tabs-holder .container .tab-pane {
        margin-left: -16px;
        margin-right: -16px; }
        @media (min-width: 769px) {
          .tab-section .tabs-holder .container .tab-pane {
            margin-left: 0;
            margin-right: 0; } }
        @media (max-width: 768.98px) {
          .tab-section .tabs-holder .container .tab-pane .slider-container.scroll-slider-active.next-slide-40.slider-buttons-hidden {
            margin-left: 0;
            margin-right: 0; } }
        @media (max-width: 768.98px) {
          .tab-section .tabs-holder .container .tab-pane .slider-container.scroll-slider-active.next-slide-22.slider-buttons-hidden {
            margin-left: 0;
            margin-right: 0; } }
        @media (max-width: 1023.98px) {
          .tab-section .tabs-holder .container .tab-pane .slider-container {
            padding: 0; } }
        @media (min-width: 1024px) {
          .tab-section .tabs-holder .container .tab-pane .product-tile-image-container.show-secondary-image {
            min-height: 370px; } }
        .tab-section .tabs-holder .container .tab-pane ul.selected-filters-list {
          display: none; }
  .tab-section .nav-wrapper {
    overflow: auto; }
  .tab-section .nav {
    display: block;
    margin-bottom: 16px;
    white-space: nowrap;
    background: #F9F9F9;
    max-width: 100%;
    text-align: center;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-scroll-behavior: smooth;
    scroll-behavior: smooth; }
    .tab-section .nav::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0 !important;
      -webkit-appearance: none;
      background: transparent; }
    .tab-section .nav .nav-item {
      display: inline-block;
      vertical-align: middle; }
    .tab-section .nav .nav-link {
      font-size: 20px;
      line-height: 28px;
      display: flex;
      color: #181818;
      position: relative;
      align-items: center;
      text-decoration: none;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      transition: all 0.3s linear;
      border-bottom: 2px solid transparent;
      padding: 16px 22px; }
      .tab-section .nav .nav-link.active {
        color: #181818;
        border-bottom: 2px solid #181818; }
      @media (min-width: 769px) {
        .tab-section .nav .nav-link {
          padding: 24px 22px; } }
    @media (min-width: 769px) {
      .tab-section .nav {
        margin-bottom: 32px;
        padding: 0 52px; } }
  .tab-section .slider-container .tns-controls button {
    top: 34%; }
  @media (max-width: 1023.98px) {
    .tab-section .slider-container {
      padding-left: 8px;
      padding-right: 8px; } }
  @media (min-width: 769px) {
    .tab-section {
      padding: 0; } }
  @media (max-width: 768.98px) {
    .tab-section .slider-container .tns-inner {
      padding-right: 0; } }
  @media (max-width: 768.98px) {
    .tab-section.full-bleed .tabs-holder {
      overflow: visible; }
      .tab-section.full-bleed .tabs-holder .nav-wrapper {
        overflow: visible;
        margin: 0 -16px; } }
  .tab-section.new-category-tabs {
    margin-bottom: 48px; }
    .tab-section.new-category-tabs .tabs-holder .container {
      padding: 0 8px; }
      @media (min-width: 769px) {
        .tab-section.new-category-tabs .tabs-holder .container {
          padding: 0 16px; } }
    .tab-section.new-category-tabs .nav {
      text-align: left;
      max-width: 1440px;
      margin: 0 auto 32px;
      padding: 0 16px;
      background: none; }
      @media (min-width: 769px) {
        .tab-section.new-category-tabs .nav {
          padding: 0 32px; } }
      .tab-section.new-category-tabs .nav .nav-item {
        margin-right: 16px; }
        .tab-section.new-category-tabs .nav .nav-item:last-child {
          margin-right: 0; }
      .tab-section.new-category-tabs .nav .nav-link {
        border: 2px solid #181818;
        padding: 8px 16px;
        border-radius: 64px;
        text-align: center;
        font-size: 16px;
        line-height: 24px; }
        .tab-section.new-category-tabs .nav .nav-link:hover, .tab-section.new-category-tabs .nav .nav-link.active {
          background-color: #181818;
          color: #FFFFFF; }
    .tab-section.new-category-tabs .experience-commerce_assets-button .button {
      text-align: center;
      padding: 64px 0 16px; }
  .tab-section.no-border-links .nav .nav-link {
    border-bottom: none;
    padding: 16px 24px; }
    .tab-section.no-border-links .nav .nav-link.active {
      padding: 16px 24px;
      font-weight: bold;
      border-bottom: none; }

.black-page-theme {
  background-color: #181818;
  color: #FFFFFF; }
  .black-page-theme .tab-section .tabpicker {
    background: black;
    color: white; }
    .black-page-theme .tab-section .tabpicker .nav-link {
      color: white; }
      .black-page-theme .tab-section .tabpicker .nav-link.active {
        border-bottom: 2px solid white; }
  .black-page-theme .product-tile-image-container {
    background: #181818;
    color: #FFFFFF; }
  .black-page-theme .product-tile a {
    color: #FFFFFF; }
    .black-page-theme .product-tile a.link {
      color: #FFFFFF; }
      .black-page-theme .product-tile a.link:hover {
        color: #FFFFFF; }
      .black-page-theme .product-tile a.link.charity-link {
        color: #FFFFFF; }
  .black-page-theme .product-tile .sales {
    color: #FFFFFF; }
  .black-page-theme .product-tile .wishlist-icon::before {
    color: #FFFFFF; }
  .black-page-theme .product-tile .price del {
    color: #FFFFFF; }
    .black-page-theme .product-tile .price del .strike-through {
      color: #FFFFFF; }
  .black-page-theme .slider-container .tns-controls button {
    background-color: #181818; }
    .black-page-theme .slider-container .tns-controls button::before {
      color: #FFFFFF; }
    .black-page-theme .slider-container .tns-controls button:hover {
      background-color: #181818; }
      .black-page-theme .slider-container .tns-controls button:hover::before {
        color: #FFFFFF; }
  .black-page-theme .slider-container .tns-nav button {
    background: #FFFFFF; }
    .black-page-theme .slider-container .tns-nav button.tns-nav {
      background: #FFFFFF; }
  .black-page-theme #module-guide-nav-container a {
    color: #FFFFFF; }
  .black-page-theme .breadcrumb-item a {
    color: #FFFFFF; }
    .black-page-theme .breadcrumb-item a:hover {
      color: #FFFFFF; }
  .black-page-theme .breadcrumb-item + .breadcrumb-item::before {
    color: #FFFFFF; }
  .black-page-theme .image-text-block-text div {
    color: #FFFFFF; }
    .black-page-theme .image-text-block-text div p {
      color: #FFFFFF; }
  .black-page-theme .btn {
    color: #FFFFFF; }
    .black-page-theme .btn:hover {
      color: #FFFFFF; }
    .black-page-theme .btn::after {
      background-color: #7f7d7d; }

.distributors-wrapper .nav-tabs {
  margin: 40px 0; }
  @media (max-width: 768.98px) {
    .distributors-wrapper .nav-tabs {
      display: flex;
      justify-content: space-between; } }
  .distributors-wrapper .nav-tabs .nav-item {
    padding: 0 20px;
    margin: 0 20px 20px; }
    @media (max-width: 768.98px) {
      .distributors-wrapper .nav-tabs .nav-item {
        min-width: 50%;
        max-width: 50%;
        margin: 0 0 20px; } }
    @media (max-width: 768.98px) {
      .distributors-wrapper .nav-tabs .nav-item .tab-right {
        text-align: right; } }

.distributors-wrapper #distributorsTab.nav-tabs {
  border-bottom: 1px solid #222222; }

.distributors-wrapper .tab-content {
  padding: 60px 0 16px, 875; }
  @media (max-width: 768.98px) {
    .distributors-wrapper .tab-content {
      text-align: center; } }
  @media (max-width: 768.98px) {
    .distributors-wrapper .tab-content img {
      margin: 0 auto; } }

.distributors-wrapper .card {
  margin-bottom: 20px;
  border: none; }
  .distributors-wrapper .card .card-body {
    padding: 40px; }

.distributors-wrapper .card-img-top {
  max-width: 200px; }

.distributors-wrapper .image-wrapper {
  margin: 0 40px 20px;
  min-height: 88px;
  max-height: 88px; }
  .distributors-wrapper .image-wrapper img {
    min-height: 88px;
    max-height: 88px; }

/*
* ICON SETUP INSTRUCTIONS
* 1) To edit Icomoon fonts, import the /fonts/icomoon/selection.json file at this URL: https://icomoon.io/app/#/select
* 2) After making changes and generating the font, replace everything in /fonts/icomoon with the downloaded files and folders
* 3) Copy the font files from /fonts/icomoon/fonts to /static/fonts/
* 4) Copy over the contents of the style.scss and variables.scss files per instructions below
* 5) Update the icons listed in /templates/default/styleguide/icons/availableIcons.isml to account for any changes
*/
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?skc9fc");
  src: url("../fonts/icomoon.eot?skc9fc#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?skc9fc") format("truetype"), url("../fonts/icomoon.woff?skc9fc") format("woff"), url("../fonts/icomoon.svg?skc9fc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"]:not(.btn),
[class*=" icon-"]:not(.btn) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*
* Copy line 4 down from fonts/icomoon/variables.scss
*/
/*
* Copy line 30 down from from fonts/icomoon/style.scss
*/
.icon-icon-up-right :before {
  content: ""; }

.icon-square-x-twitter:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-star:before {
  content: ""; }

.icon-account:before {
  content: ""; }

.icon-arrow-left-thin:before {
  content: ""; }

.icon-arrow-right-thin:before {
  content: ""; }

.icon-badge:before {
  content: ""; }

.icon-bubble-tail:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-checkmark:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-collapse:before {
  content: ""; }

.icon-expand:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-favorite:before {
  content: ""; }

.icon-favorite-selected:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-logo:before {
  content: ""; }

.icon-pinterest:before {
  content: ""; }

.icon-play:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-select:before {
  content: ""; }

.icon-tiktok:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-menu:before {
  content: ""; }

.icon-images:before {
  content: ""; }

.icon-alert:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-top:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-call:before {
  content: ""; }

.icon-close-small:before {
  content: ""; }

.icon-edit:before {
  content: ""; }

.icon-email:before {
  content: ""; }

.icon-film:before {
  content: ""; }

.icon-google:before {
  content: ""; }

.icon-guarantee:before {
  content: ""; }

.icon-image:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-link:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-livechat:before {
  content: ""; }

.icon-location:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-offsite:before {
  content: ""; }

.icon-pause:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-remove:before {
  content: ""; }

.icon-size:before {
  content: ""; }

.icon-zoom-out:before {
  content: ""; }

.icon-zoom:before {
  content: ""; }

.fa {
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  text-rendering: auto;
  font-size: inherit; }

.fa-stack {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: inherit; }

/*
* COLORS
*/
/*
* GRID SYSTEM
*/
/*
* Z Indexes
*/
/*
* ASPECT RATIOS
*/
/*
* MISC
*/
/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
/*
* TEXT
*/
/*
* HEADERS
*/
/*
* BUTTONS
*/
/*
* Notify button
*/
/*
* FORMs
*/
/*
* PROGRESSBAR
*/
/*
* BREADCRUMBS
*/
/*
* TOOLTIPS
*/
/*
* POPOVERS
*/
/*
* MODALS
*/
/*
* ALERTS (also affects toast messages)
*/
/*
* TOAST MESSAGES
*/
/*
* CARDS
*/
/*
* COLLAPSIBLE
*/
/*
* TABS
*/
/*TAB MENU - NEW COMPONENT */
/*
* SLIDER
*/
/*
* DROPDOWNS
*/
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/*
* HEADER
*/
/*
* FOOTER
*/
/*
* PAGE-LEVEL SETTINGS
*/
/*
* PRODUCT
*/
/*
* MINICART
*/
/*
* CART AND CHECKOUT
*/
/*
* PAGE DESIGNER MODULES
*/
/*
* HERO ASSETS
*/
/*
* POPSOCKETS HERO BANNER
*/
/*
* CATEGORY TILE ASSETS
*/
/*
* IMAGE TEXT BLOCK ASSETS
*/
/*
* PHOTO HOTSPOTS ASSETS
*/
/*
* VIDEO ASSETS
*/
/*
* BLOG LANDING
*/
/*
* BLOG DETAIL
*/
/*
* CONTENT TILES
*/
/*
* STORE LOCATOR
*/
/*
* STORE DETAIL PAGE
*/
/*
* ACCOUNT
*/
/*
* GIFTCERTIFICATES
*/
/*
* PAYMENTMETHODS
*/
/*
* ACCESSIBILITY
*/
/*
* CONSENT DRAWER
*/
.svg-icon {
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block; }
  .svg-icon.customize-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M15.4462 9.19774C15.4462 12.9716 12.4191 16 8.72308 16C5.02702 16 2 12.9716 2 9.19774C2 5.4239 5.02702 2.39551 8.72308 2.39551C12.4191 2.39551 15.4462 5.4239 15.4462 9.19774Z' stroke='url(%23paint0_linear_432_5183)' stroke-width='4'/%3E%3Cpath d='M10.3859 9.99504L7.49839 10.8875C7.41781 10.9125 7.32989 10.892 7.26939 10.8342C7.20888 10.7765 7.18563 10.6908 7.20888 10.6112L8.04114 7.75703C8.05163 7.72138 8.07099 7.68866 8.09739 7.66206L10.3859 9.99504ZM10.3859 9.99504C10.4222 9.98395 10.4549 9.96418 10.4814 9.93749L10.3859 9.99504ZM15.4203 0.56685C15.4201 0.567028 15.4199 0.567206 15.4197 0.567384L8.29433 7.81833L8.54939 8.06181L10.0544 9.49845L10.3165 9.74864L17.4482 2.491L17.4489 2.49033C17.646 2.29122 17.7537 2.02469 17.7499 1.74929L15.4203 0.56685ZM15.4203 0.56685C15.6182 0.368282 15.8892 0.253852 16.1743 0.250095C16.4598 0.246337 16.7342 0.353786 16.9376 0.547424L17.4285 1.01601C17.631 1.20988 17.746 1.47375 17.7499 1.74922L15.4203 0.56685Z' fill='white' stroke='%23181818' stroke-width='0.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_432_5183' x1='0.311538' y1='34.5041' x2='18.4047' y2='34.5041' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FDD22A'/%3E%3Cstop offset='0.166667' stop-color='%23FF9244'/%3E%3Cstop offset='0.34375' stop-color='%23FF93FF'/%3E%3Cstop offset='0.536458' stop-color='%23AF0DFC'/%3E%3Cstop offset='0.697917' stop-color='%2300C4E6'/%3E%3Cstop offset='1' stop-color='%231EE1B2'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    height: 20px;
    width: 20px; }

.result-count p {
  font-size: 44px;
  color: #181818;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.13px;
  line-height: 44px;
  margin-top: -54px; }
  @media (max-width: 1199.98px) {
    .result-count p {
      margin-top: -38px; } }
  @media (max-width: 1023.98px) {
    .result-count p {
      font-size: 32px; } }
  @media (max-width: 768.98px) {
    .result-count p {
      font-size: 16px;
      font-weight: bold; } }

.search-tips {
  font-size: 14.76px;
  text-align: left;
  margin-top: 22px;
  color: #32414e;
  font-weight: bolder;
  font-family: "Space Grotesk", sans-serif;
  letter-spacing: 0.13px; }
  .search-tips h5 {
    font-size: 14.76px;
    font-family: "Inter", sans-serif;
    font-weight: normal;
    letter-spacing: 0.13px; }
  .search-tips ul {
    padding-left: 40px; }
    .search-tips ul li {
      padding-left: 8px; }
    .search-tips ul a {
      color: #0879c0;
      text-decoration: none;
      font-weight: bold; }
      .search-tips ul a:hover {
        color: #32414e;
        text-decoration: underline; }

@media (max-width: 768.98px) {
  .product-grid .product-tile .product-name-wrapper {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 52px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    padding: 0 8px; } }
  @media (max-width: 768.98px) and (min-width: 1024px) {
    .product-grid .product-tile .product-name-wrapper {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

@media (max-width: 768.98px) {
  .product-grid .product-tile .product-tile--buy-container {
    padding: 0 8px; } }

@media (max-width: 768.98px) {
  .product-grid .product-tile .product-tile--buy-container .price {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; } }
  @media (max-width: 768.98px) and (min-width: 1024px) {
    .product-grid .product-tile .product-tile--buy-container .price {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.flyout-opened {
  overflow: hidden; }

.flyout-veil-bg {
  background-color: transparent !important; }

.flyout-wrapper {
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
  position: fixed;
  z-index: 9999; }
  @media (min-width: 769px) {
    .flyout-wrapper {
      width: 414px;
      right: -27rem; } }
  .flyout-wrapper::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    background: #FFFFFF;
    top: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none; }
  .flyout-wrapper::before {
    content: " ";
    position: fixed;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    bottom: 0;
    backdrop-filter: blur(1px);
    z-index: -1;
    pointer-events: none;
    opacity: 0; }
  .flyout-wrapper.flyout-show::before {
    opacity: 1; }
  .flyout-wrapper.flyout-show {
    right: 0;
    box-shadow: 0 0 rem(9) rem(5) rgba(0, 0, 0, 0.1);
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1); }
  .flyout-wrapper .flyout-frame {
    -ms-overflow-style: none;
    /* stylelint-disable-line */
    scrollbar-width: none;
    /* stylelint-disable-line */ }
    .flyout-wrapper .flyout-frame::-webkit-scrollbar {
      display: none; }
    .flyout-wrapper .flyout-frame.scroll {
      overflow-y: auto;
      overflow-x: hidden; }
  .flyout-wrapper .header-welcome-message {
    text-align: left; }
  .flyout-wrapper .flyout-list li {
    text-align: left;
    padding-bottom: 10px; }
  .flyout-wrapper .flyout-list a {
    font-size: 0.875rem;
    color: #181818;
    font-weight: 700; }
    .flyout-wrapper .flyout-list a:hover {
      text-decoration: underline; }
  .flyout-wrapper .contact-links li {
    margin-bottom: rem(16); }

.product-detail {
  margin-bottom: 16px; }
  .product-detail .primary-images .slider-container {
    margin-bottom: 0; }
  .product-detail .primary-images .primary-images-main.product-has-thumbnails {
    margin-bottom: 8px; }
  @media (min-width: 1024px) {
    .product-detail .primary-images > .row {
      margin-left: 0;
      margin-right: 0; }
    .product-detail .primary-images .primary-images-thumbnails {
      flex-basis: 85px;
      max-width: 85px;
      padding-left: 0;
      padding-right: 0; }
    .product-detail .primary-images .primary-images-main {
      padding-left: 0;
      padding-right: 0; }
      .product-detail .primary-images .primary-images-main.product-has-thumbnails {
        flex-basis: calc(100% - 85px - 8px);
        max-width: calc(100% - 85px - 8px);
        margin-left: 8px;
        margin-bottom: 0; } }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .product-detail .primary-images + .col-sm-6 {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 769px) {
    .product-detail .row > .product-data {
      padding-left: 16px; } }
  @media (min-width: 1024px) {
    .product-detail .row > .product-data {
      padding-left: 16px; } }
  @media (min-width: 1440px) {
    .product-detail .row > .product-data {
      padding-left: 16px; } }
  .product-detail .product-name {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    color: #181818; }
    .product-detail .product-name a {
      text-decoration: none; }
  .product-detail .attribute,
  .product-detail .product-option {
    margin-top: 24px; }
    @media (min-width: 769px) {
      .product-detail .attribute,
      .product-detail .product-option {
        margin-top: 24px; } }
    @media (min-width: 1024px) {
      .product-detail .attribute,
      .product-detail .product-option {
        margin-top: 31px; } }
    .product-detail .attribute label,
    .product-detail .product-option label {
      display: block; }
  .product-detail .qty-cart-container {
    margin-top: 16px;
    margin-bottom: 16px; }
  .product-detail .quantity {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    .product-detail .quantity label {
      font-family: "Inter", sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 2px;
      text-transform: none;
      letter-spacing: 0;
      margin-right: 10px; }
      .product-detail .quantity label:after {
        content: ':'; }
  @media (min-width: 544px) {
    .product-detail .quantity-stepper,
    .product-detail .quantity-select {
      width: calc(100% - 10px); } }
  .product-detail .simple-quantity {
    margin-top: 16px; }
  .product-detail .availability-container {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    margin-top: 24px; }
    @media (min-width: 769px) {
      .product-detail .availability-container {
        margin-top: 24px; } }
    @media (min-width: 1024px) {
      .product-detail .availability-container {
        margin-top: 31px; } }
  .product-detail .swatch-circle {
    width: 44px;
    height: 44px;
    background-color: #FFFFFF;
    background-size: cover;
    display: block;
    position: relative;
    text-align: center;
    border-radius: 50%;
    margin: 8px 16px 8px 0; }
    .product-detail .swatch-circle.Color-value {
      transition: box-shadow 200ms ease-out; }
      .product-detail .swatch-circle.Color-value:hover {
        box-shadow: none; }
      .product-detail .swatch-circle.Color-value.selected {
        border: 2px solid #181818;
        border-radius: 4px;
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
        .product-detail .swatch-circle.Color-value.selected:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 7px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-circle.Color-value.selected::after {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          display: none;
          background: rgba(34, 34, 34, 0.7);
          width: 14px;
          height: 14px;
          line-height: 14px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          z-index: 1; }
      .product-detail .swatch-circle.Color-value.unselectable {
        filter: blur(0px); }
        .product-detail .swatch-circle.Color-value.unselectable + .unselectable-icon {
          pointer-events: none;
          cursor: not-allowed;
          position: absolute;
          height: 72px;
          width: 72px;
          bottom: 0;
          right: 0; }
          @media (max-width: 768.98px) {
            .product-detail .swatch-circle.Color-value.unselectable + .unselectable-icon {
              height: calc(18.5vw);
              width: calc(18.5vw); } }
          .product-detail .swatch-circle.Color-value.unselectable + .unselectable-icon:before, .product-detail .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 18px);
            width: 1px; }
          .product-detail .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
            background-color: #FFFFFF;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
            z-index: 2;
            width: 2px; }
      .product-detail .swatch-circle.Color-value.unselectable {
        pointer-events: none;
        pointer-events: auto;
        cursor: not-allowed;
        position: relative; }
    .product-detail .swatch-circle.non-color-swatch-value {
      transition: border 200ms ease-out, box-shadow 200ms ease-out; }
      .product-detail .swatch-circle.non-color-swatch-value:not(.unselectable):hover {
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
      .product-detail .swatch-circle.non-color-swatch-value.selected, .product-detail .swatch-circle.non-color-swatch-value.selected:hover {
        background: #F9F9F9;
        color: #222222;
        border-color: #181818;
        box-shadow: none; }
        .product-detail .swatch-circle.non-color-swatch-value.selected:after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 8px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-detail .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-detail .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-circle.non-color-swatch-value.selected::after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover::after {
          position: absolute;
          top: -5.33333px;
          right: -5.33333px;
          background: #181818;
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          display: none; }
      .product-detail .swatch-circle.non-color-swatch-value.unselectable {
        pointer-events: auto;
        cursor: not-allowed;
        position: relative;
        color: #D4D4D4; }
  .product-detail .swatch-thumbnail {
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    background-size: cover;
    display: block;
    position: relative;
    text-align: center;
    border-radius: 0;
    margin: 0.5px 1px 0.5px 0; }
    .product-detail .swatch-thumbnail.Color-value {
      transition: box-shadow 200ms ease-out; }
      .product-detail .swatch-thumbnail.Color-value:hover:not(.unselectable) {
        box-shadow: inset 0px -2px 0px #595959; }
      .product-detail .swatch-thumbnail.Color-value.selected {
        box-shadow: inset 0px -2px 0px #222222; }
        .product-detail .swatch-thumbnail.Color-value.selected:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 7px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .btn-group-lg > .swatch-thumbnail.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-thumbnail.Color-value.selected[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-thumbnail.Color-value.selected::after {
          position: absolute;
          top: -4.66667px;
          right: -4.66667px;
          display: none;
          background: rgba(34, 34, 34, 0.7);
          width: 14px;
          height: 14px;
          line-height: 14px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          z-index: 1; }
      .product-detail .swatch-thumbnail.Color-value.unselectable:before, .product-detail .swatch-thumbnail.Color-value.unselectable:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(calc(100% + 15px) + 18px);
        width: 1px; }
      .product-detail .swatch-thumbnail.Color-value.unselectable:after {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
        z-index: 2;
        width: 2px; }
      .product-detail .swatch-thumbnail.Color-value.unselectable {
        pointer-events: none;
        pointer-events: auto;
        cursor: not-allowed;
        position: relative; }
    .product-detail .swatch-thumbnail.non-color-swatch-value {
      transition: border 200ms ease-out, box-shadow 200ms ease-out; }
      .product-detail .swatch-thumbnail.non-color-swatch-value:not(.unselectable):hover {
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
      .product-detail .swatch-thumbnail.non-color-swatch-value.selected, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover {
        background: #F9F9F9;
        color: #222222;
        border-color: #181818;
        box-shadow: none; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.selected:after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 8px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-detail .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-detail .btn-group-lg > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.selected::after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
          position: absolute;
          top: -5.33333px;
          right: -5.33333px;
          background: #181818;
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          display: none; }
      .product-detail .swatch-thumbnail.non-color-swatch-value.unselectable {
        pointer-events: auto;
        cursor: not-allowed;
        position: relative;
        color: #D4D4D4; }
  .product-detail [disabled] .swatch,
  .product-detail .swatch.unselectable {
    cursor: not-allowed; }
    .product-detail [disabled] .swatch.color-value.selected::after,
    .product-detail .swatch.unselectable.color-value.selected::after {
      background-color: #BEBEBE; }
  .product-detail label.color ~ a:hover {
    text-decoration: none; }
  .product-detail .non-color-attribute-swatches::after {
    display: block;
    content: "";
    clear: both; }
  .product-detail .non-color-attribute-swatches .swatch-tile-container {
    float: left;
    display: block;
    margin: 4px 8px 4px 0; }
    .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch {
      background: none;
      display: block;
      position: relative;
      padding: 0;
      border: none; }
      .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value {
        position: relative;
        color: #222222;
        background: #F9F9F9;
        border: 2px solid #F9F9F9;
        border-radius: 8px;
        padding: 16px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; }
        @media (min-width: 1024px) {
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value {
            font-family: "Inter", sans-serif;
            font-size: 20px;
            line-height: 28px;
            text-transform: none;
            font-weight: normal;
            letter-spacing: 0;
            font-style: normal;
            font-display: swap; } }
        .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value {
          transition: box-shadow 200ms ease-out; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value:hover {
            box-shadow: none; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected {
            border: 2px solid #181818;
            border-radius: 4px;
            box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 7px;
              color: #FFFFFF;
              font-weight: normal; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title) {
                padding-right: 42px; }
                .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-lg > .swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title) {
                padding-right: 63px; }
                .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-lg > .swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected[class*="btn-"]:not(.title) .fa {
                display: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.selected::after {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              display: none;
              background: rgba(34, 34, 34, 0.7);
              width: 14px;
              height: 14px;
              line-height: 14px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              z-index: 1; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.unselectable {
            filter: blur(0px); }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.unselectable + .unselectable-icon {
              pointer-events: none;
              cursor: not-allowed;
              position: absolute;
              height: 72px;
              width: 72px;
              bottom: 0;
              right: 0; }
              @media (max-width: 768.98px) {
                .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.unselectable + .unselectable-icon {
                  height: calc(18.5vw);
                  width: calc(18.5vw); } }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.unselectable + .unselectable-icon:before, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.unselectable + .unselectable-icon:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%) rotate(45deg);
                height: calc(100% + 18px);
                width: 1px; }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.unselectable + .unselectable-icon:after {
                background-color: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
                z-index: 2;
                width: 2px; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.Color-value.unselectable {
            pointer-events: none;
            pointer-events: auto;
            cursor: not-allowed;
            position: relative; }
        .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value {
          transition: border 200ms ease-out, box-shadow 200ms ease-out; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value:not(.unselectable):hover {
            box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover {
            background: #F9F9F9;
            color: #222222;
            border-color: #181818;
            box-shadow: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 8px;
              color: #FFFFFF;
              font-weight: normal; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
                padding-right: 42px; }
                .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-lg > .swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-lg > .swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
                padding-right: 63px; }
                .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-lg > .swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-lg > .swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
                display: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected::after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover::after {
              position: absolute;
              top: -5.33333px;
              right: -5.33333px;
              background: #181818;
              width: 16px;
              height: 16px;
              line-height: 16px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              display: none; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unselectable {
            pointer-events: auto;
            cursor: not-allowed;
            position: relative;
            color: #D4D4D4; }
      .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .description {
        display: block;
        text-align: center;
        font-size: 12px;
        color: #BEBEBE;
        font-weight: normal; }
  .product-detail .social-container {
    padding-top: 24px; }
    .product-detail .social-container .social-icons {
      align-items: center; }
      .product-detail .social-container .social-icons li:not(:last-child) {
        margin-right: 30px; }
      .product-detail .social-container .social-icons .share-icons {
        font-size: 30px;
        color: #181818; }
        .product-detail .social-container .social-icons .share-icons:hover {
          color: rgba(24, 24, 24, 0.8); }
  .product-detail .product-number-rating {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 16px;
    clear: left; }
  .product-detail .product-number {
    color: #595959;
    font-size: 13px; }
  .product-detail .ratings {
    margin-left: 32px; }
    .product-detail .ratings .fa-star:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: #181818;
      font-weight: normal; }
    .product-detail .ratings .fa-star[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title).btn-sm, .product-detail .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .product-detail .ratings .fa-star[class*="btn-"]:not(.title).btn-sm:before, .product-detail .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title).btn-lg, .product-detail .ratings .btn-group-lg > .fa-star.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .product-detail .ratings .fa-star[class*="btn-"]:not(.title).btn-lg:before, .product-detail .ratings .btn-group-lg > .fa-star.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title) [class*="icon-"],
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title) [class^="icon-"],
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-detail .ratings .fa-star-half-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: #181818;
      font-weight: normal; }
    .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm, .product-detail .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm:before, .product-detail .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-lg, .product-detail .ratings .btn-group-lg > .fa-star-half-o.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-lg:before, .product-detail .ratings .btn-group-lg > .fa-star-half-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-detail .ratings .fa-star-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: #181818;
      font-weight: normal; }
    .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) {
      padding-left: 42px; }
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #181818;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm, .product-detail .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title) {
        padding-left: 42px; }
        .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm:before, .product-detail .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title).btn-lg, .product-detail .ratings .btn-group-lg > .fa-star-o.btn[class*="btn-"]:not(.title) {
        padding-left: 63px; }
        .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title).btn-lg:before, .product-detail .ratings .btn-group-lg > .fa-star-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #181818;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) .fa {
        display: none; }
  .product-detail .promotions {
    margin-top: 16px; }
    .product-detail .promotions .promotion {
      color: #181818; }
  .product-detail .prices {
    margin-top: 16px;
    margin-bottom: 16px; }
    @media (min-width: 544px) {
      .product-detail .prices {
        margin-bottom: 8px; } }
    @media (min-width: 769px) {
      .product-detail .prices {
        margin-top: 0; } }
    .product-detail .prices .price {
      font-size: 16px;
      font-family: "Space Grotesk", sans-serif; }
  .product-detail .size-chart {
    position: absolute;
    right: 16px;
    font-size: 16px; }
    .product-detail .size-chart h2 {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      letter-spacing: 0;
      font-weight: normal;
      font-style: normal; }
    .product-detail .size-chart .sizinginformation .nav-tabs .nav-link.active {
      background-color: #FFFFFF; }
    .product-detail .size-chart .sizinginformation .tab-content {
      border: 1px solid #F9F9F9;
      margin-bottom: 16px; }
      .product-detail .size-chart .sizinginformation .tab-content .sizechart {
        margin-top: -1px; }
      .product-detail .size-chart .sizinginformation .tab-content .sizecharttable {
        margin-bottom: 0; }
  .product-detail .color-attribute {
    display: block;
    float: left; }
  .product-detail label,
  .product-detail .non-input-label {
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
    text-transform: none;
    letter-spacing: 0;
    display: block; }
  .product-detail .set-item {
    padding: 0; }
  .product-detail .bundle-item {
    padding: 16px; }
    .product-detail .bundle-item:not(:last-child) {
      border-bottom: 1px solid #D4D4D4; }

.tabs {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px; }
  @media (min-width: 769px) {
    .tabs {
      padding-top: 30px; } }
  @media (min-width: 1024px) {
    .tabs {
      padding-top: 60px; } }
  .tabs .nav-link {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal; }
  @media (min-width: 1024px) {
    .tabs.product-has-thumbnails {
      padding-left: 85px; } }

.main-content-group .main-content-item {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px; }
  @media (min-width: 769px) {
    .main-content-group .main-content-item {
      padding-top: 30px; } }
  @media (min-width: 1024px) {
    .main-content-group .main-content-item {
      padding-top: 60px; } }

@media (min-width: 1024px) {
  .main-content-group.product-has-thumbnails {
    padding-left: 85px; } }

.main-content-group .card .card-header .title {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

.main-content-group .card .card-body {
  padding-bottom: 16px; }
  @media (min-width: 769px) {
    .main-content-group .card .card-body {
      padding-bottom: 30px; } }
  @media (min-width: 1024px) {
    .main-content-group .card .card-body {
      padding-bottom: 60px; } }

.main-content-group .main-attributes {
  margin-bottom: 16px; }

.main-content-group .product-attributes .attribute-name {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

@media (max-width: 768.98px) {
  .main-content-group .product-attributes + .collapsible-content.collapsible-sm.border-top,
  .main-content-group .collapsible-content.collapsible-sm.border-top {
    border-top: 1px solid #D4D4D4; } }

.main-content-group .product-attributes + .collapsible-content.collapsible-xl.border-top,
.main-content-group .collapsible-content.collapsible-xl.border-top {
  border-top: 1px solid #D4D4D4; }

.cart-and-ipay {
  text-align: center; }
  .cart-and-ipay .btn {
    width: 100%; }
  @media (min-width: 544px) {
    .cart-and-ipay {
      padding-bottom: 0; } }

.add-to-cart-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.add-to-basket-alert {
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.4);
  text-align: center; }
  .add-to-basket-alert.show {
    display: block; }
  .add-to-basket-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .add-to-basket-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .add-to-basket-alert.alert-dismissible .close {
      padding: 16px; }

.jumplink-anchor {
  position: absolute;
  top: -150px; }

.addtocart-sticky-bar {
  pointer-events: none;
  display: block;
  position: fixed;
  top: 0;
  background: #FFFFFF;
  width: 100%;
  left: 0;
  z-index: 200;
  padding: 10px 0;
  opacity: 1;
  transform: translateY(-100%);
  transition: transform 200ms linear;
  overflow: hidden;
  box-shadow: 0 2px 2px rgba(34, 34, 34, 0.2); }
  .addtocart-sticky-bar .name,
  .addtocart-sticky-bar .links {
    margin: auto; }
  .addtocart-sticky-bar h1.product-name {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    line-height: 28px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    color: #181818;
    margin: 0; }
  .addtocart-sticky-bar a.jumplink {
    margin: 0 15px;
    position: relative;
    z-index: 10; }
    .addtocart-sticky-bar a.jumplink.selected, .addtocart-sticky-bar a.jumplink:hover {
      text-decoration: none; }
  .addtocart-sticky-bar .sticky-attribute {
    float: left; }
    .addtocart-sticky-bar .sticky-attribute + .sticky-attribute {
      margin-left: 16px; }
  .addtocart-sticky-bar .attribute {
    margin-top: 0; }
    .addtocart-sticky-bar .attribute label,
    .addtocart-sticky-bar .attribute .non-input-label,
    .addtocart-sticky-bar .attribute .size-chart {
      display: none; }
    .addtocart-sticky-bar .attribute .swatch-circle {
      width: 44px;
      height: 44px;
      margin: 5px 10px 5px 0; }
    .addtocart-sticky-bar .attribute .swatch-thumbnail {
      width: calc(14px + 8px + 6px + (1px*2));
      height: calc(14px + 8px + 6px + (1px*2));
      margin: 0.5px 1px 0.5px 0; }
    .addtocart-sticky-bar .attribute .custom-select, .addtocart-sticky-bar .attribute select.form-control,
    .addtocart-sticky-bar .attribute select {
      font-size: 11px;
      line-height: 14px;
      padding: 8px 21px 6px 10px;
      height: calc(14px + 8px + 6px + (1px*2)); }
  .addtocart-sticky-bar .cart-and-ipay {
    text-align: right;
    padding-bottom: 0; }
    .addtocart-sticky-bar .cart-and-ipay .add-to-cart.btn.btn-primary {
      font-size: 14px;
      line-height: 18px;
      padding: 5px 20px 5px 20px;
      letter-spacing: 0.05em;
      width: auto; }
  .sticky-header .addtocart-sticky-bar {
    transition: transform 400ms ease-out; }
  .sticky-header.scroll-direction-up .showstickybar .addtocart-sticky-bar {
    transform: translateY(var(--header-height));
    transition: transform 400ms ease-out 50ms; }

.showstickybar .addtocart-sticky-bar {
  pointer-events: auto;
  transform: translateY(0); }

.product-detail .bundle-items-label {
  text-align: center;
  margin-top: 48px;
  margin-bottom: 48px; }

.product-detail .ootb-bundle {
  display: flex; }
  .product-detail .ootb-bundle .bundle-item {
    margin: 0;
    border: 0;
    width: auto;
    max-width: inherit;
    flex-basis: 33%; }
  .product-detail .ootb-bundle .primary-images .primary-images-thumbnails {
    display: none !important; }
  .product-detail .ootb-bundle .primary-images .primary-images-main {
    margin: 0 0 24px; }
    .product-detail .ootb-bundle .primary-images .primary-images-main.product-has-thumbnails {
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
      margin: 0 0 24px; }
  .product-detail .ootb-bundle .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button[data-controls='prev'],
  .product-detail .ootb-bundle .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button[data-controls='next'] {
    display: none; }

.product-detail .product-name {
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  line-height: 26px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: -1px;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .product-detail .product-name {
      font-family: "Space Grotesk", sans-serif;
      font-size: 24px;
      line-height: 26px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }
  @media (min-width: 769px) {
    .product-detail .product-name {
      font-family: "Space Grotesk", sans-serif;
      font-size: 24px;
      line-height: 26px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }
  @media (min-width: 769px) and (min-width: 1024px) {
    .product-detail .product-name {
      font-family: "Space Grotesk", sans-serif;
      font-size: 32px;
      line-height: 36px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: -1px;
      font-style: normal;
      font-display: swap; } }

.product-detail .charity-message {
  margin-bottom: 10px; }

.product-detail .charity-note {
  font-size: 10px;
  line-height: 14px;
  font-style: italic; }

.product-detail .notify-bis {
  padding-top: 14px;
  padding-bottom: 14px; }

.product-detail .prices {
  margin-bottom: 16px; }
  .product-detail .prices .price {
    font-family: "Inter", sans-serif;
    font-weight: bold; }

.product-detail .d-md-none .product-name {
  margin-top: 0; }
  @media (min-width: 769px) {
    .product-detail .d-md-none .product-name {
      margin-top: 16px; } }

.product-detail .d-md-none .prices {
  margin-top: 0; }

.product-detail .d-md-none .product-number-rating {
  margin-bottom: 10px; }

.product-detail .product-number-rating {
  margin-bottom: 32px; }
  @media (min-width: 769px) {
    .product-detail .product-number-rating {
      margin-bottom: 18px; } }

.product-detail .primary-images {
  margin-bottom: 24px; }
  .product-detail .primary-images .align-data {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    position: absolute;
    transform: translate(-50%, -50%); }
    @media (min-width: 1024px) {
      .product-detail .primary-images .align-data {
        bottom: 7%;
        left: 50%; } }
    @media (max-width: 1439.98px) {
      .product-detail .primary-images .align-data {
        bottom: 9%;
        left: 45%; } }
    @media (max-width: 1023.98px) {
      .product-detail .primary-images .align-data {
        bottom: 0;
        left: 50%; } }
    .product-detail .primary-images .align-data .div-class {
      width: max-content;
      border-radius: 8px;
      align-items: center;
      display: block; }
      @media (min-width: 1024px) {
        .product-detail .primary-images .align-data .div-class {
          height: 41px; } }
      @media (max-width: 1199.98px) {
        .product-detail .primary-images .align-data .div-class {
          height: 38px; } }
      .product-detail .primary-images .align-data .div-class p {
        display: flex;
        font-family: Inter;
        align-items: center;
        justify-content: center;
        padding: 12px; }
        @media (min-width: 1200px) {
          .product-detail .primary-images .align-data .div-class p {
            font-size: 14px;
            line-height: 16px; } }
        @media (max-width: 1439.98px) {
          .product-detail .primary-images .align-data .div-class p {
            font-size: 12px;
            line-height: 14px; } }
        .product-detail .primary-images .align-data .div-class p .image-styling {
          max-width: 100%;
          border-radius: 20px;
          aspect-ratio: 1/1; }
          @media (min-width: 1200px) {
            .product-detail .primary-images .align-data .div-class p .image-styling {
              margin-right: 12px;
              width: 16px;
              max-width: 100%;
              height: 16px; } }
          @media (max-width: 1439.98px) {
            .product-detail .primary-images .align-data .div-class p .image-styling {
              margin-right: 12px;
              width: 10px;
              max-width: 100%;
              height: 10px; } }
      .product-detail .primary-images .align-data .div-class #closeButton img {
        aspect-ratio: 1/1; }
        @media (min-width: 1200px) {
          .product-detail .primary-images .align-data .div-class #closeButton img {
            margin-left: 20px;
            width: 12px;
            max-width: 100%;
            height: 12px; } }
        @media (max-width: 1439.98px) {
          .product-detail .primary-images .align-data .div-class #closeButton img {
            margin-left: 12px;
            width: 10px;
            max-width: 100%;
            height: 10px; } }
  .product-detail .primary-images > .row {
    position: sticky;
    top: 32px; }
  .product-detail .primary-images .primary-images-main {
    background-color: #F9F9F9; }
    @media (min-width: 769px) {
      .product-detail .primary-images .primary-images-main {
        border-radius: 20px; } }
    .product-detail .primary-images .primary-images-main.product-has-thumbnails {
      background-color: #F9F9F9;
      margin: 0 -8px 0;
      padding: 0;
      width: calc(100% + 16px); }
      .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button {
        top: 58%;
        margin-top: -24px;
        background-color: #FFFFFF;
        display: none;
        border: 1px solid transparent;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); }
        @media (min-width: 769px) {
          .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button {
            display: block; }
            .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button:disabled {
              opacity: 1; }
              .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button:disabled:before {
                color: #D4D4D4; } }
        .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button:hover {
          background-color: #FFFFFF;
          border-color: #181818; }
        .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button:before {
          font-size: 17px;
          color: #181818; }
        .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button[data-controls='next'] {
          right: 0; }
        .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button[data-controls='prev'] {
          left: 0; }
        @media (min-width: 769px) {
          .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button {
            top: auto;
            bottom: 0;
            left: auto;
            right: 24px;
            background: #FFFFFF;
            box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.5); }
            .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button::before {
              line-height: 48px; }
            .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button[data-controls='next'] {
              right: 24px; }
            .product-detail .primary-images .primary-images-main.product-has-thumbnails .slider-container .tns-controls button[data-controls='prev'] {
              left: auto;
              right: 84px; } }
      @media (min-width: 769px) {
        .product-detail .primary-images .primary-images-main.product-has-thumbnails {
          padding: 0 0 48px;
          margin: 0;
          border-radius: 20px;
          width: 100%;
          background: none; } }
      @media (min-width: 1024px) {
        .product-detail .primary-images .primary-images-main.product-has-thumbnails {
          flex-basis: calc(100% - 85px - 32px);
          max-width: calc(100% - 85px - 32px);
          margin-left: 32px; } }
    @media (max-width: 768.98px) {
      .product-detail .primary-images .primary-images-main .slider-container.scroll-slider-active .slider {
        margin: 0;
        padding: 0 0 48px 0; } }
    @media (max-width: 768.98px) {
      .product-detail .primary-images .primary-images-main .slider-container.scroll-slider-active .slide {
        flex: 0 0 100%;
        position: relative; } }
    .product-detail .primary-images .primary-images-main .slider-container.scroll-slider-active .simplebar-content {
      padding: 0 !important; }
    @media (max-width: 768.98px) {
      .product-detail .primary-images .primary-images-main .slider-container.scroll-slider-active.next-slide-40.slider-buttons-hidden {
        margin-left: 0;
        margin-right: 0; } }
    @media (max-width: 768.98px) {
      .product-detail .primary-images .primary-images-main .slider-container.scroll-slider-active.next-slide-22.slider-buttons-hidden {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 769px) {
      .product-detail .primary-images .primary-images-main .slider-container .tns-controls button {
        top: auto;
        bottom: 0;
        left: auto;
        right: 25px;
        background: #FFFFFF;
        box-shadow: 0 0 2px #000000; }
        .product-detail .primary-images .primary-images-main .slider-container .tns-controls button:disabled {
          opacity: 1; }
          .product-detail .primary-images .primary-images-main .slider-container .tns-controls button:disabled:before {
            color: #D4D4D4; }
        .product-detail .primary-images .primary-images-main .slider-container .tns-controls button::before {
          line-height: 50px; }
        .product-detail .primary-images .primary-images-main .slider-container .tns-controls button[data-controls='prev'] {
          left: auto;
          right: 85px; } }
  .product-detail .primary-images .slider-container {
    padding: 0; }
  @media (max-width: 768.98px) {
    .product-detail .primary-images .primary-images-thumbnails {
      background-color: #FFFFFF;
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0 -8px 0;
      padding: 0;
      width: calc(100% + 16px);
      max-width: inherit; }
      .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        background: #FFFFFF; }
        .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide {
          display: block;
          background: transparent;
          width: 25% !important;
          height: 4px;
          border-radius: 8px;
          margin: 0;
          padding: 0;
          cursor: pointer;
          transition: transform 200ms ease-out;
          min-width: 12px; }
          .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide img {
            display: none !important; }
          .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide:after {
            background: #FFFFFF;
            border-radius: 0;
            width: 100%;
            height: 4px;
            position: absolute;
            left: 0; }
          .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide:first-child::after {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px; }
          .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide:last-child::after {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px; }
          .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide.tns-nav-active, .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide:hover:not(.tns-nav-active) {
            background-color: #D4D4D4;
            transition: background-color 900ms ease-in-out, height 900ms ease-in-out;
            border: none; }
            .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide.tns-nav-active:after, .product-detail .primary-images .primary-images-thumbnails .slider-thumbnails .slider .slide:hover:not(.tns-nav-active):after {
              background-color: transparent; } }
  @media (min-width: 769px) {
    .product-detail .primary-images .primary-images-thumbnails {
      margin-bottom: 48px; } }
  @media (min-width: 769px) {
    .product-detail .primary-images {
      margin-bottom: 0; }
      .product-detail .primary-images .slide {
        background-color: #F9F9F9;
        border-radius: 20px; }
        .product-detail .primary-images .slide img {
          border-radius: 20px; } }

@media (min-width: 544px) {
  .product-detail .quantity-stepper,
  .product-detail .quantity-select {
    border-radius: 100px; } }

.product-detail .product-features .row {
  flex-wrap: nowrap;
  margin-bottom: 40px; }
  .product-detail .product-features .row .feature-image {
    max-width: calc(60px + 32px); }
    @media (min-width: 1024px) {
      .product-detail .product-features .row .feature-image {
        max-width: calc(60px + 32px); } }
  .product-detail .product-features .row .feature-text {
    padding-left: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: center; }
    .product-detail .product-features .row .feature-text .feature-title {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      width: 100%; }
      @media (min-width: 1024px) {
        .product-detail .product-features .row .feature-text .feature-title {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          line-height: 24px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .product-detail .product-features .row .feature-text p:last-child {
      margin-bottom: 0; }

.product-detail .product-features img {
  width: 100%;
  height: auto;
  max-width: 60px; }

.product-detail .product-features h6 {
  font-size: 16px; }

.product-detail .attributes .row {
  margin-bottom: 16px; }
  .product-detail .attributes .row.qty-cart-container {
    margin-bottom: 20px; }
  .product-detail .attributes .row[data-attr="iPhoneType"] {
    margin-bottom: 20px; }
  .product-detail .attributes .row.bis-container {
    padding-right: 72px; }

.product-detail .attributes .attribute {
  border-radius: 20px;
  margin-top: 0;
  margin-bottom: 0;
  border: none; }

.product-detail .attributes .attribute-option {
  position: relative;
  padding: 24px 32px;
  border-style: solid;
  border-color: #D4D4D4;
  border-width: 1px;
  border-radius: 0;
  border-bottom: none;
  flex-grow: 1;
  min-height: 80px; }
  .product-detail .attributes .attribute-option:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
  .product-detail .attributes .attribute-option:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom: 1px solid #D4D4D4; }
  .product-detail .attributes .attribute-option.color-swatches {
    border-width: 0;
    padding: 0;
    margin: 0 0 4px;
    min-height: 58px; }
    @media (min-width: 769px) {
      .product-detail .attributes .attribute-option.color-swatches {
        min-height: 70px;
        margin: 0; } }
    .product-detail .attributes .attribute-option.color-swatches .color-display-value {
      margin-top: 0; }
      @media (max-width: 1023.98px) {
        .product-detail .attributes .attribute-option.color-swatches .color-display-value {
          line-height: 1;
          margin-bottom: 0;
          height: 15px; } }
    @media (max-width: 1023.98px) {
      .product-detail .attributes .attribute-option.color-swatches .color-attribute {
        margin: 12px 8px 0 0; } }

.product-detail .attributes .custom-attribute-radio label {
  margin: 0 0 0 60px; }
  .product-detail .attributes .custom-attribute-radio label .attribute-name {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .product-detail .attributes .custom-attribute-radio label .attribute-name {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .product-detail .attributes .custom-attribute-radio label .attribute-price {
    margin-right: 16px;
    position: relative; }
    .product-detail .attributes .custom-attribute-radio label .attribute-price .price {
      display: inline; }
    .product-detail .attributes .custom-attribute-radio label .attribute-price:before {
      content: '•';
      display: none;
      position: absolute;
      left: -18px;
      top: 0px;
      color: #595959;
      line-height: 18px;
      font-size: 16px; }

.product-detail .attributes .custom-attribute-radio.disabled label .attribute-price:before {
  color: #D4D4D4; }

.product-detail .attributes .custom-attribute-radio .attribute-description {
  margin-left: 0;
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .product-detail .attributes .custom-attribute-radio .attribute-description {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.product-detail .attributes .custom-attribute-radio input[type="radio"] {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 1; }

.product-detail .attributes .custom-attribute-radio + .custom-radio {
  margin-top: 0; }

.product-detail .attributes .product-availability {
  margin-bottom: 0; }
  .product-detail .attributes .product-availability .preorder-pdp-callout {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 16px 10.66667px;
    border-radius: 8px;
    background: #F9F9F9;
    color: #181818;
    position: relative;
    margin-bottom: 24px; }
    @media (min-width: 769px) {
      .product-detail .attributes .product-availability .preorder-pdp-callout {
        margin-bottom: 32px;
        padding: 16px; } }
    .product-detail .attributes .product-availability .preorder-pdp-callout .prod-stock-msg {
      padding-right: 10.66667px;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      border-right: 1px solid #D4D4D4;
      flex-basis: 50%;
      text-align: center; }
      @media (min-width: 1024px) {
        .product-detail .attributes .product-availability .preorder-pdp-callout .prod-stock-msg {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
      @media (min-width: 769px) {
        .product-detail .attributes .product-availability .preorder-pdp-callout .prod-stock-msg {
          padding-right: 16px; } }
      .product-detail .attributes .product-availability .preorder-pdp-callout .prod-stock-msg .content-asset {
        display: inline-flex;
        align-items: center;
        text-align: left;
        height: 100%; }
    .product-detail .attributes .product-availability .preorder-pdp-callout .shipping-date-info {
      flex-basis: 50%;
      padding-left: 12.8px;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 769px) {
        .product-detail .attributes .product-availability .preorder-pdp-callout .shipping-date-info {
          padding-left: 16px; } }
      .product-detail .attributes .product-availability .preorder-pdp-callout .shipping-date-info > svg {
        padding-right: 9px;
        width: 29px; }
      .product-detail .attributes .product-availability .preorder-pdp-callout .shipping-date-info .info-title {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        line-height: 16px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; }
        @media (min-width: 1024px) {
          .product-detail .attributes .product-availability .preorder-pdp-callout .shipping-date-info .info-title {
            font-family: "Inter", sans-serif;
            font-size: 12px;
            line-height: 16px;
            text-transform: none;
            font-weight: normal;
            letter-spacing: 0;
            font-style: normal;
            font-display: swap; } }
      .product-detail .attributes .product-availability .preorder-pdp-callout .shipping-date-info .shipping-date {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; }
        @media (min-width: 1024px) {
          .product-detail .attributes .product-availability .preorder-pdp-callout .shipping-date-info .shipping-date {
            font-family: "Inter", sans-serif;
            font-size: 14px;
            line-height: 20px;
            text-transform: none;
            font-weight: bold;
            letter-spacing: 0;
            font-style: normal;
            font-display: swap; } }
  .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip {
    float: left;
    padding-right: 8px;
    position: relative;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        line-height: 16px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip .tooltip-area {
      display: none; }
    .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip strong {
      display: block;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      line-height: 16px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip strong {
          font-family: "Inter", sans-serif;
          font-size: 12px;
          line-height: 16px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip:hover .tooltip-area {
      padding: 12px;
      border-radius: 8px;
      background: #FFFFFF;
      box-shadow: 0 0 5px #BEBEBE;
      max-width: 350px;
      width: 345px;
      position: absolute;
      left: -15px;
      bottom: 35px;
      z-index: 99;
      display: block; }
      @media (min-width: 769px) {
        .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip:hover .tooltip-area {
          max-width: 380px;
          width: 375px; } }
      .product-detail .attributes .product-availability .preorder-pdp-asset-tooltip:hover .tooltip-area::before {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid white;
        position: absolute;
        left: 17px;
        bottom: -6px;
        content: "";
        filter: drop-shadow(0px 1px 1px #BEBEBE); }

.product-detail .badge-product-container.badge-image-over {
  position: absolute;
  top: 7px;
  left: 16px; }

.product-detail .attribute label,
.product-detail .product-option label {
  margin-bottom: 8px; }

.product-detail label,
.product-detail .non-input-label {
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  margin-bottom: 12px; }

.product-detail .non-input-label {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .product-detail .non-input-label {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .product-detail .non-input-label .non-color-display-value {
    font-weight: normal; }
  .product-detail .non-input-label.non-input-label {
    display: flex;
    gap: 4px;
    align-items: center; }
    .product-detail .non-input-label.non-input-label .color-display-value {
      margin: 0;
      height: auto; }

.product-detail .product-number-rating {
  margin-top: 0;
  height: 17px; }
  .product-detail .product-number-rating .product-number {
    display: none; }
  .product-detail .product-number-rating .ratings {
    margin-left: 0;
    height: 17px; }
    .product-detail .product-number-rating .ratings .fa:before {
      font-size: 20px;
      width: 17px;
      height: 17px;
      line-height: 17px; }

.product-detail .qty-cart-container {
  margin-top: 0; }
  .product-detail .qty-cart-container .cart-and-ipay {
    margin-bottom: 0; }
  .product-detail .qty-cart-container .wishlist {
    position: relative;
    top: initial;
    right: initial;
    border: none;
    width: 56px;
    height: 56px;
    border: 2px solid #181818;
    border-radius: 50%;
    margin-left: 16px; }
  .product-detail .qty-cart-container .mutually-exclusive-products {
    color: #a80b1d;
    padding-top: 16px; }

.product-detail .quantity {
  align-self: flex-start; }
  .product-detail .quantity .quantity-select {
    line-height: 24px;
    height: 100%;
    border-radius: 100px; }
    @media (max-width: 1023.98px) {
      .product-detail .quantity .quantity-select {
        margin-right: 16px; } }
    .product-detail .quantity .quantity-select:focus, .product-detail .quantity .quantity-select:active {
      outline: 2px solid #181818; }

.product-detail .color-attribute {
  display: inline-block;
  vertical-align: middle;
  float: none;
  background: none;
  margin: 12px 8px 0 0; }

.product-detail .color-swatches {
  margin-bottom: 0;
  font-size: 0; }
  .product-detail .color-swatches .color-display-value,
  .product-detail .color-swatches .pop-design-color-display-value {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    display: block;
    margin-top: 8px;
    height: 20px; }
    @media (min-width: 1024px) {
      .product-detail .color-swatches .color-display-value,
      .product-detail .color-swatches .pop-design-color-display-value {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .product-detail .color-swatches .pop-design-color-display-value {
    margin: -4px 0 0 5px; }
  .product-detail .color-swatches .swatches-holder {
    margin-left: -16px; }
  .product-detail .color-swatches .swatches {
    margin: 0; }
    .product-detail .color-swatches .swatches::after {
      display: block;
      content: "";
      clear: both; }
  .product-detail .color-swatches .swatch-circle {
    width: 44px;
    height: 44px;
    background-color: #FFFFFF;
    background-size: cover;
    display: block;
    position: relative;
    text-align: center;
    border-radius: 50%;
    margin: 0;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    height: 72px;
    width: 72px; }
    .product-detail .color-swatches .swatch-circle.Color-value {
      transition: box-shadow 200ms ease-out; }
      .product-detail .color-swatches .swatch-circle.Color-value:hover {
        box-shadow: none; }
      .product-detail .color-swatches .swatch-circle.Color-value.selected {
        border: 2px solid #181818;
        border-radius: 4px;
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
        .product-detail .color-swatches .swatch-circle.Color-value.selected:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 7px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .color-swatches .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .color-swatches .btn-group-sm > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .color-swatches .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .color-swatches .btn-group-lg > .swatch-circle.Color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .color-swatches .swatch-circle.Color-value.selected[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .color-swatches .swatch-circle.Color-value.selected::after {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          display: none;
          background: rgba(34, 34, 34, 0.7);
          width: 14px;
          height: 14px;
          line-height: 14px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          z-index: 1; }
      .product-detail .color-swatches .swatch-circle.Color-value.unselectable {
        filter: blur(0px); }
        .product-detail .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon {
          pointer-events: none;
          cursor: not-allowed;
          position: absolute;
          height: 72px;
          width: 72px;
          bottom: 0;
          right: 0; }
          @media (max-width: 768.98px) {
            .product-detail .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon {
              height: calc(18.5vw);
              width: calc(18.5vw); } }
          .product-detail .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:before, .product-detail .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 18px);
            width: 1px; }
          .product-detail .color-swatches .swatch-circle.Color-value.unselectable + .unselectable-icon:after {
            background-color: #FFFFFF;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
            z-index: 2;
            width: 2px; }
      .product-detail .color-swatches .swatch-circle.Color-value.unselectable {
        pointer-events: none;
        pointer-events: auto;
        cursor: not-allowed;
        position: relative; }
    .product-detail .color-swatches .swatch-circle.non-color-swatch-value {
      transition: border 200ms ease-out, box-shadow 200ms ease-out; }
      .product-detail .color-swatches .swatch-circle.non-color-swatch-value:not(.unselectable):hover {
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
      .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected, .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover {
        background: #F9F9F9;
        color: #222222;
        border-color: #181818;
        box-shadow: none; }
        .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:after, .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 8px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-detail .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 42px; }
            .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-detail .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .product-detail .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .product-detail .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: 63px; }
            .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .product-detail .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .product-detail .color-swatches .btn-group-lg > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected::after, .product-detail .color-swatches .swatch-circle.non-color-swatch-value.selected:hover::after {
          position: absolute;
          top: -5.33333px;
          right: -5.33333px;
          background: #181818;
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          display: none; }
      .product-detail .color-swatches .swatch-circle.non-color-swatch-value.unselectable {
        pointer-events: auto;
        cursor: not-allowed;
        position: relative;
        color: #D4D4D4; }
    @media (max-width: 768.98px) {
      .product-detail .color-swatches .swatch-circle {
        height: calc(18.5vw);
        width: calc(18.5vw); } }
    .product-detail .color-swatches .swatch-circle.selected {
      z-index: 2;
      border: 2px solid #181818;
      border-radius: 4px;
      box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818;
      position: relative; }
  .product-detail .color-swatches .pdp-add-on-swatch-span.swatch-circle {
    height: 40px;
    width: 40px; }
    @media (max-width: 768.98px) {
      .product-detail .color-swatches .pdp-add-on-swatch-span.swatch-circle {
        height: 32px;
        width: 32px; } }
  .product-detail .color-swatches .description {
    display: none; }
  .product-detail .color-swatches .swatch .swatch-circle {
    margin: 12px 0 0 8px; }

.product-detail .non-color-attribute-swatches > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .product-detail .non-color-attribute-swatches > div > .non-input-label {
    flex-basis: 100%; }
    .product-detail .non-color-attribute-swatches > div > .non-input-label.iPhoneType {
      line-height: 1; }

.product-detail .non-color-attribute-swatches .swatch-tile-container {
  float: none;
  margin: 4px 0;
  flex-basis: 100%;
  max-width: 100%; }
  @media (max-width: 768.98px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container.iPhoneType-tile {
      flex-basis: calc(50% - 4px);
      max-width: calc(50% - 4px); } }
  @media (max-width: 768.98px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container.iPhoneType-tile.full-length {
      flex-basis: 100%;
      max-width: 100%; } }
  @media (max-width: 768.98px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container.iPhoneType-tile .swatch .swatch-value {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  @media (max-width: 768.98px) and (min-width: 1024px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container.iPhoneType-tile .swatch .swatch-value {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  @media (max-width: 1023.98px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container:last-child {
      margin: 4px 0 0 0; } }
  @media (min-width: 544px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container {
      flex-basis: calc(50% - 4px);
      max-width: 50%; } }
  @media (min-width: 769px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container {
      flex-basis: 100%;
      max-width: 100%; } }
  @media (min-width: 1024px) {
    .product-detail .non-color-attribute-swatches .swatch-tile-container {
      flex-basis: calc(50% - 4px);
      max-width: 50%; } }
  .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch {
    width: 100%; }
    .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .description {
      display: none; }
    .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          line-height: 24px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }

.product-detail .vat-pricing {
  font-size: 14px;
  line-height: 24px;
  color: #66676e;
  letter-spacing: 0;
  margin-bottom: 10px; }
  .product-detail .vat-pricing a {
    color: #66676e; }
  @media (min-width: 769px) {
    .product-detail .vat-pricing {
      margin-bottom: 16px; } }

.product-detail .promotions {
  padding: 16px 16px 8px;
  border-radius: 20px;
  background: #F9F9F9;
  margin-bottom: 40px;
  margin-top: 20px; }
  .product-detail .promotions .promotion {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    color: #595959;
    text-align: center;
    margin-bottom: 8px; }
    @media (min-width: 1024px) {
      .product-detail .promotions .promotion {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .product-detail .promotions .promotion strong {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold; }

@media (min-width: 769px) {
  .product-detail .product-bundle .bundle-items.ootb-bundle .primary-images {
    min-height: 360px; } }

@media (min-width: 1024px) {
  .product-detail .product-bundle .bundle-items.ootb-bundle .primary-images {
    min-height: 460px; } }

.product-detail .product-bundle .bundle-items.ootb-bundle .primary-images .primary-images-main.product-has-thumbnails {
  padding: 0;
  margin: 0 !important; }

.product-detail .product-bundle .bundle-items.ootb-bundle .primary-images > .row {
  position: static; }

@media (min-width: 769px) {
  .product-detail .product-bundle > .col-md-5 > .row .col-4.ootb-bundle.js-bundle-images {
    align-items: flex-start; } }

.product-detail .product-bundle .prices-add-to-cart-actions .primary-images .primary-images-main {
  margin: 0;
  padding: 0; }

.product-detail .see-all-colors {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap;
  text-decoration: underline;
  color: #181818 !important; }
  @media (min-width: 1024px) {
    .product-detail .see-all-colors {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .product-detail .see-all-colors.grips {
    position: absolute;
    right: 0;
    top: 50px; }

.product-detail.product-wrapper .attributes .attribute-option.color-swatches {
  margin-bottom: 16px; }

.product-detail .buy-with-prime-prime-day-deal-badge-sm-container-card {
  align-items: center; }

.product-detail .buy-with-prime-prime-day-deal-badge-sm {
  height: 24px;
  width: auto;
  padding: 0 5px; }

.product-detail button.notify-me-btn {
  font-family: "Inter", sans-serif;
  padding: 16px 32px 16px 32px; }
  .product-detail button.notify-me-btn:after {
    background-color: #181818; }
  .product-detail button.notify-me-btn:hover, .product-detail button.notify-me-btn:focus, .product-detail button.notify-me-btn[aria-describedby*="popover"] {
    text-decoration: none; }
    .product-detail button.notify-me-btn:hover:after, .product-detail button.notify-me-btn:focus:after, .product-detail button.notify-me-btn[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px; }
  .dark-theme .product-detail button.notify-me-btn {
    color: #FFFFFF; }
    .dark-theme .product-detail button.notify-me-btn:after {
      background-color: #181818; }
    .dark-theme .product-detail button.notify-me-btn:hover {
      color: #181818; }
  .product-detail button.notify-me-btn .icon-mail {
    background: url(../images/icons/email-icon.svg) no-repeat center;
    background-size: contain;
    display: inline-block;
    height: 21px;
    width: 22px;
    vertical-align: text-bottom;
    margin-right: 4px; }

@media (max-width: 1023.98px) {
  .product-detail button.notify-me-btn.shortenFontSize {
    font-size: 12px;
    padding: 16px 12px; }
    .product-detail button.notify-me-btn.shortenFontSize .icon-mail {
      height: 16px;
      width: 16px;
      margin-right: 2px; } }

@media (max-width: 768.98px) {
  .product-detail button.notify-me-btn.shortenFontSize {
    font-size: 14px;
    padding: 16px 32px 16px 32px; }
    .product-detail button.notify-me-btn.shortenFontSize .icon-mail {
      height: 18px;
      width: 18px;
      margin-right: 3px; } }

.product-detail .enableStickyV1 #a2c-version-2 .prices,
.product-detail .enableStickyV1 #a2c-version-2 .sticky-prod-detail,
.product-detail .enableStickyV1 #a2c-version-3 .prices,
.product-detail .enableStickyV1 #a2c-version-3 .sticky-prod-detail {
  display: none; }

.product-detail .enableStickyV1 #a2c-version-2 .cart-and-ipay,
.product-detail .enableStickyV1 #a2c-version-3 .cart-and-ipay {
  width: 100%;
  float: none; }

.pdp-assets {
  margin: 64px 0;
  overflow: hidden; }
  .pdp-assets .hero.text-over-image-or-solid-background.short.align-items-center {
    align-items: normal !important; }
  .pdp-assets .hero.short {
    min-height: 0; }
  @media (max-width: 1023.98px) {
    .pdp-assets .hero .hero-container {
      display: block;
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0; }
      .pdp-assets .hero .hero-container .hero-buttons {
        padding-bottom: 64px; } }
  .pdp-assets .experience-main .experience-commerce_assets-assetInclude {
    margin-top: 64px; }
    @media (min-width: 769px) {
      .pdp-assets .experience-main .experience-commerce_assets-assetInclude {
        margin-top: 64px; } }
  @media (min-width: 544px) {
    .pdp-assets {
      margin: 96px 0; } }
  @media (max-width: 1023.98px) {
    .pdp-assets .hero-media.video-container {
      height: auto;
      min-height: 210px;
      z-index: 0; } }

.hide-oos-variants .swatch-tile-container.unselectable {
  display: none; }

.hide-oos-variants .color-swatches .unselectable-btn {
  display: none; }

.main-content-group .main-content-item {
  padding: 0;
  margin: 0; }
  .main-content-group .main-content-item .collapsible-content-group.card .card-header {
    background-color: transparent; }
    .main-content-group .main-content-item .collapsible-content-group.card .card-header .card-title {
      margin-bottom: 2px; }

@media (min-width: 1024px) {
  .main-content-group.product-has-thumbnails {
    padding-left: 0;
    background: none; } }

.main-content-group .card {
  border-radius: 0; }
  .main-content-group .card .card-header .title {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .main-content-group .card .card-header .title {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .main-content-group .card .card-body {
    margin: 0;
    padding: 0; }
  .main-content-group .card li {
    margin-bottom: 24px; }

.main-content-group .product-attributes .attribute-name {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap; }
  @media (min-width: 1024px) {
    .main-content-group .product-attributes .attribute-name {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.addtocart-sticky-bar {
  box-shadow: none;
  border-bottom: 0px solid transparent; }
  .addtocart-sticky-bar .cart-and-ipay .add-to-cart.btn.btn-primary {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 32px 16px 32px;
    width: 100%;
    letter-spacing: 0;
    min-height: calc(24px + 16px + 16px + 4px);
    color: #FFFFFF; }
    .addtocart-sticky-bar .cart-and-ipay .add-to-cart.btn.btn-primary:after {
      background-color: #181818; }

.cart-and-ipay .add-to-cart,
.cart-and-ipay .btn-add-to-cart {
  color: #FFFFFF; }
  .cart-and-ipay .add-to-cart:after,
  .cart-and-ipay .btn-add-to-cart:after {
    background-color: #181818; }

.product-data .btn-customize {
  padding: 15px 32px; }
  .product-data .btn-customize::after {
    position: relative;
    left: 6px;
    top: 4px;
    transform: none;
    right: auto; }

.product-data .product-tile .btn-customize {
  padding: 8px 8px; }
  @media (max-width: 768.98px) {
    .product-data .product-tile .btn-customize {
      padding: 8px 5.6px; } }
  .product-data .product-tile .btn-customize::after {
    left: 1px; }
    @media (min-width: 1024px) {
      .product-data .product-tile .btn-customize::after {
        left: 6px; } }

.grid-tile-add-on {
  padding: 12px 0; }
  .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox {
    padding-left: 0;
    padding-right: 12px; }
    .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-input {
      z-index: 1;
      opacity: 0; }
    .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-label,
    .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-input {
      height: 24px;
      width: 24px; }
      @media (max-width: 768.98px) {
        .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-label,
        .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-input {
          height: 20px;
          width: 20px; } }
    .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-label:after,
    .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-label:before {
      height: 24px;
      width: 24px;
      left: 0; }
      @media (max-width: 768.98px) {
        .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-label:after,
        .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-label:before {
          height: 20px;
          width: 20px; } }
    @media (max-width: 768.98px) {
      .grid-tile-add-on .custom-checkbox.pdp-add-on-checkbox .custom-control-input:checked ~ .custom-control-label:after {
        font-size: 14px;
        line-height: 21px; } }
  .grid-tile-add-on .pdp-add-on-checkbox {
    min-height: 1.5rem; }
  .grid-tile-add-on .product-tile .container-1 .badge-product-container,
  .grid-tile-add-on .customisable-add-on-product-pdp .container-1 .badge-product-container {
    display: none; }
  .grid-tile-add-on .product-tile .container-2,
  .grid-tile-add-on .customisable-add-on-product-pdp .container-2 {
    flex-basis: 75% !important; }
    .grid-tile-add-on .product-tile .container-2 .device-swatches select.form-control,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .device-swatches select.form-control {
      font-size: 14px; }
  @media (min-width: 1024px) {
    .grid-tile-add-on .product-tile .container-1,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-1 {
      flex-basis: 110px !important; }
    .grid-tile-add-on .product-tile .container-2,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-2 {
      max-width: 65%; }
      .grid-tile-add-on .product-tile .container-2 .pdp-link .product-name-wrapper,
      .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .pdp-link .product-name-wrapper {
        font-size: 14px; }
      .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container,
      .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container {
        padding: 0; }
        .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container .price,
        .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container .price {
          padding: 0;
          justify-content: left;
          font-size: 14px; } }
  @media (max-width: 1439.98px) {
    .grid-tile-add-on .product-tile .container-1,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-1 {
      flex-basis: 80px !important; }
    .grid-tile-add-on .product-tile .container-2,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-2 {
      max-width: 65%; }
      .grid-tile-add-on .product-tile .container-2 .pdp-link .product-name-wrapper,
      .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .pdp-link .product-name-wrapper {
        font-size: 14px; }
      .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container,
      .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container {
        padding: 0; }
        .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container .price,
        .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container .price {
          padding: 0;
          justify-content: left;
          font-size: 14px; } }
  @media (max-width: 1199.98px) {
    .grid-tile-add-on .product-tile .container-1,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-1 {
      flex-basis: 80px !important; }
    .grid-tile-add-on .product-tile .container-2,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-2 {
      max-width: 65%; }
      .grid-tile-add-on .product-tile .container-2 .pdp-link .product-name-wrapper,
      .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .pdp-link .product-name-wrapper {
        font-size: 14px; }
      .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container,
      .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container {
        padding: 0; }
        .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container .price,
        .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container .price {
          padding: 0;
          justify-content: left;
          font-size: 14px; } }
  @media (max-width: 768.98px) {
    .grid-tile-add-on .product-tile .container-1,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-1 {
      flex-basis: 96px !important; }
    .grid-tile-add-on .product-tile .container-2 .pdp-link .product-name-wrapper,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .pdp-link .product-name-wrapper {
      font-size: 14px; }
    .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container,
    .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container {
      padding: 0; }
      .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container .price,
      .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container .price {
        justify-content: left;
        font-size: 14px;
        padding: 0; }
        .grid-tile-add-on .product-tile .container-2 .product-tile--buy-container .price .price-section,
        .grid-tile-add-on .customisable-add-on-product-pdp .container-2 .product-tile--buy-container .price .price-section {
          display: flex;
          gap: 16px; } }

.sticky-a2c-section {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 99; }
  .sticky-a2c-section .sticky-wrap {
    overflow: hidden;
    padding: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.2);
    display: none; }
  .sticky-a2c-section #a2c-version-2 .prices {
    float: left;
    padding-right: 8px;
    margin: 0;
    padding-top: 16px;
    display: flex;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    line-height: 28px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .sticky-a2c-section #a2c-version-2 .prices {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        line-height: 28px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    .sticky-a2c-section #a2c-version-2 .prices .price {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 28px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      padding-left: 4px; }
      @media (min-width: 1024px) {
        .sticky-a2c-section #a2c-version-2 .prices .price {
          font-family: "Inter", sans-serif;
          font-size: 20px;
          line-height: 28px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
  .sticky-a2c-section #a2c-version-2 .cart-and-ipay {
    width: 213px;
    float: right; }
  .sticky-a2c-section #a2c-version-3 .cart-and-ipay .add-to-cart {
    max-width: 130px;
    padding: 16px; }
  .sticky-a2c-section .sticky-content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .sticky-a2c-section .sticky-prod-detail {
    float: left;
    display: flex;
    align-items: center;
    max-width: 60%; }
    .sticky-a2c-section .sticky-prod-detail .prod-image {
      float: left;
      width: 80px; }
      .sticky-a2c-section .sticky-prod-detail .prod-image img {
        width: 100%;
        height: auto;
        display: block; }
    .sticky-a2c-section .sticky-prod-detail .info-prod-area {
      overflow: hidden; }
      .sticky-a2c-section .sticky-prod-detail .info-prod-area .prod-name {
        margin: 0;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; }
        @media (min-width: 1024px) {
          .sticky-a2c-section .sticky-prod-detail .info-prod-area .prod-name {
            font-family: "Inter", sans-serif;
            font-size: 14px;
            line-height: 20px;
            text-transform: none;
            font-weight: bold;
            letter-spacing: 0;
            font-style: normal;
            font-display: swap; } }
      .sticky-a2c-section .sticky-prod-detail .info-prod-area .prices {
        padding: 0;
        margin: 0; }
        .sticky-a2c-section .sticky-prod-detail .info-prod-area .prices .price {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; }
          @media (min-width: 1024px) {
            .sticky-a2c-section .sticky-prod-detail .info-prod-area .prices .price {
              font-family: "Inter", sans-serif;
              font-size: 14px;
              line-height: 20px;
              text-transform: none;
              font-weight: normal;
              letter-spacing: 0;
              font-style: normal;
              font-display: swap; } }

.product-detail-attributes .product-addons .product .product-tile:hover .quick-add-to-cart {
  display: none; }

.product-detail-attributes .product-addons .product .product-tile .quick-add-to-cart {
  display: none;
  margin: 0; }

.product-detail-attributes .product-addons .product .product-tile .tile-body-footer .pt-2.pl-0.col-12 {
  padding: 0; }

.product-detail-attributes .product-addons .product .product-tile .bwp-card-prime-day-deal-badge-container {
  justify-content: end;
  flex-direction: column;
  align-items: end; }

.chip-badges-area {
  white-space: nowrap;
  text-align: left;
  overflow-x: auto;
  display: flex;
  margin: -5px -16px 8px 0;
  padding: 0 0 8px; }
  .chip-badges-area::after {
    display: block;
    clear: both;
    content: " "; }
  .chip-badges-area::-webkit-scrollbar {
    height: 1px; }
  .chip-badges-area::-webkit-scrollbar-track {
    background: #FFFFFF;
    border: 0; }
  .chip-badges-area::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 0; }
  @media (min-width: 769px) {
    .chip-badges-area {
      margin: -5px 0 8px; } }
  .chip-badges-area .chip-badge {
    margin: 0 0 4px 0; }
    .chip-badges-area .chip-badge:last-child {
      margin: 0 10px 4px 0; }
    .chip-badges-area .chip-badge a {
      border-radius: 20px;
      padding: 6px 12px 5px;
      font-size: 12px;
      line-height: 16px;
      min-height: 28px;
      font-weight: 600;
      text-decoration: none;
      position: relative;
      margin: 2px;
      display: inline-block;
      vertical-align: middle;
      transition: all 200ms ease-out; }
      .chip-badges-area .chip-badge a:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: inherit;
        color: inherit;
        font-weight: normal; }
      .chip-badges-area .chip-badge a[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .chip-badges-area .chip-badge a[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .chip-badges-area .chip-badge a[class*="btn-"]:not(.title).btn-sm, .chip-badges-area .chip-badge .btn-group-sm > a.btn[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .chip-badges-area .chip-badge a[class*="btn-"]:not(.title).btn-sm:after, .chip-badges-area .chip-badge .btn-group-sm > a.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .chip-badges-area .chip-badge a[class*="btn-"]:not(.title).btn-lg, .chip-badges-area .chip-badge .btn-group-lg > a.btn[class*="btn-"]:not(.title) {
          padding-right: 63px; }
          .chip-badges-area .chip-badge a[class*="btn-"]:not(.title).btn-lg:after, .chip-badges-area .chip-badge .btn-group-lg > a.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .chip-badges-area .chip-badge a[class*="btn-"]:not(.title) [class*="icon-"],
        .chip-badges-area .chip-badge a[class*="btn-"]:not(.title) [class^="icon-"],
        .chip-badges-area .chip-badge a[class*="btn-"]:not(.title) .fa {
          display: none; }
      .chip-badges-area .chip-badge a::after {
        font-size: 8px;
        padding-left: 2px;
        display: inline-block;
        vertical-align: middle; }
      .chip-badges-area .chip-badge a:hover {
        margin: 0;
        padding: 8px 14px 7px;
        transition: all 200ms ease-out; }

@media (max-width: 1023.98px) {
  .color-swatches .d-lg-block {
    padding-bottom: 16px;
    overflow-x: auto;
    overflow-y: hidden; } }

.bundle-items .primary-images .overlaid .overlay-image {
  display: none; }

.bundle-items .primary-images .overlaid .base-image + .overlay-image {
  display: block;
  position: absolute;
  left: 0;
  top: 0; }

.bundle-items .primary-images .overlaid:nth-child(1) .base-image + .overlay-image {
  transform: translateY(-14%) rotateX(48deg); }

.bundle-items .primary-images .overlaid:nth-child(2) .base-image + .overlay-image {
  transform: none; }

.bundle-items .primary-images .overlaid:nth-child(4) .base-image + .overlay-image,
.bundle-items .primary-images .overlaid:nth-child(6) .base-image + .overlay-image {
  transform: translateX(4.1%) rotateY(46deg) scale(0.3); }

.bundle-items .primary-images .overlaid:nth-child(5) .base-image + .overlay-image,
.bundle-items .primary-images .overlaid:nth-child(7) .base-image + .overlay-image {
  transform: translateX(9%) rotateY(46deg) scale(0.3); }

.bundle-items .primary-images .overlaid.poptivism:nth-child(1) .base-image + .overlay-image {
  transform: translateY(-14%) rotateX(48deg) scale(1.05); }

.bundle-items .primary-images .overlaid.poptivism:nth-child(4) .base-image + .overlay-image,
.bundle-items .primary-images .overlaid.poptivism:nth-child(6) .base-image + .overlay-image {
  transform: translateX(4.1%) rotateY(46deg) scale(0.305); }

.bundle-items .primary-images .overlaid.poptivism:nth-child(5) .base-image + .overlay-image,
.bundle-items .primary-images .overlaid.poptivism:nth-child(7) .base-image + .overlay-image {
  transform: translateX(9%) rotateY(46deg) scale(0.305); }

.bundle-items .primary-images .primary-images-main .overlaid:nth-child(3) .base-image + .overlay-image {
  transform: translateY(-1%) rotateX(43deg) scale(1.02); }

.bundle-items .primary-images .primary-images-main .overlaid.poptivism:nth-child(3) .base-image + .overlay-image {
  transform: translateY(-1%) rotateX(43deg) scale(1.07); }

.bundle-items .primary-images .primary-images-thumbnails .overlaid .base-image + .overlay-image {
  top: 8px; }

.bundle-items .primary-images .primary-images-thumbnails .overlaid:nth-child(1) .base-image + .overlay-image {
  transform: translateY(-11%) rotateX(48deg) scale(0.85); }

.bundle-items .primary-images .primary-images-thumbnails .overlaid:nth-child(3) .base-image + .overlay-image {
  transform: rotateX(45deg) scale(0.82); }

@media (max-width: 1023.98px) {
  .bundle-items .primary-images {
    margin-bottom: 24px; } }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid .overlay-image {
  display: none; }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid .base-image + .overlay-image {
  display: block;
  position: absolute;
  left: 0;
  top: 0; }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid:nth-child(1) .base-image + .overlay-image {
  transform: translateY(0%) rotateX(0deg) scale(0.71); }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid:nth-child(2) .base-image + .overlay-image {
  transform: translateX(17.1%) rotateY(48deg) scale(0.8); }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid:nth-child(3) .base-image + .overlay-image {
  transform: translateY(1%) translateX(-0.1%) rotate(0deg) scale(0.275); }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid:nth-child(4) .base-image + .overlay-image {
  transform: translateX(5.1%) translateY(1%) rotateY(40deg) scale(0.257); }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid:nth-child(6) .base-image + .overlay-image {
  transform: translateX(33.1%) translateY(3%) rotateY(50deg) scale(0.3); }

.bundle-items .primary-images.magsafe-grips-slider-images .overlaid:nth-child(5) .base-image + .overlay-image,
.bundle-items .primary-images.magsafe-grips-slider-images .overlaid:nth-child(7) .base-image + .overlay-image {
  transform: translateX(9%) rotateY(46deg) scale(0.3); }

.bundle-items .primary-images.magsafe-grips-slider-images .primary-images-thumbnails .overlaid .base-image + .overlay-image {
  top: 8px; }

.bundle-items .primary-images.magsafe-grips-slider-images .primary-images-thumbnails .overlaid:nth-child(1) .base-image + .overlay-image {
  transform: translateY(0%) rotateX(0deg) scale(0.56); }

.bundle-items .primary-images.magsafe-grips-slider-images .primary-images-thumbnails .overlaid:nth-child(3) .base-image + .overlay-image {
  transform: translateY(1%) translateX(-0.1%) rotate(0deg) scale(0.21); }

.bundle-items .attributes .bundle-swatches {
  margin-bottom: 32px; }

.bundle-items .attributes .bundle-swatch-accordioncolor.row,
.bundle-items .attributes .bundle-swatch-platformcolor.row {
  margin-bottom: 0; }
  .bundle-items .attributes .bundle-swatch-accordioncolor.row .attribute-option,
  .bundle-items .attributes .bundle-swatch-platformcolor.row .attribute-option {
    display: flex;
    align-items: flex-start; }
  .bundle-items .attributes .bundle-swatch-accordioncolor.row .grip-part-svg,
  .bundle-items .attributes .bundle-swatch-platformcolor.row .grip-part-svg {
    padding-right: 24px; }

.bundle-items .attributes .bundle-swatch-gripsetup {
  margin-bottom: 32px; }

.bundle-items .attributes .bundle-swatch-accordioncolor.row .attribute-option {
  border: none;
  margin-bottom: 0;
  padding-right: 0;
  position: relative; }
  @media (min-width: 1024px) {
    .bundle-items .attributes .bundle-swatch-accordioncolor.row .attribute-option {
      padding-right: 32px;
      position: static; } }
  .bundle-items .attributes .bundle-swatch-accordioncolor.row .attribute-option:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.bundle-items .attributes .bundle-swatch-platformcolor.row .attribute-option {
  border: none;
  border-top: 1px solid #D4D4D4;
  padding-right: 0;
  position: relative; }
  @media (min-width: 1024px) {
    .bundle-items .attributes .bundle-swatch-platformcolor.row .attribute-option {
      border-top: none;
      padding-top: 0;
      padding-right: 32px;
      position: static; } }
  .bundle-items .attributes .bundle-swatch-platformcolor.row .attribute-option:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.bundle-items .attributes .bundle-swatch-magsafecolor.row .attribute-option {
  border: none;
  position: relative;
  padding-left: 0; }
  @media (min-width: 1024px) {
    .bundle-items .attributes .bundle-swatch-magsafecolor.row .attribute-option {
      position: static; } }
  .bundle-items .attributes .bundle-swatch-magsafecolor.row .attribute-option .swatches-holder {
    margin-left: 0; }
    .bundle-items .attributes .bundle-swatch-magsafecolor.row .attribute-option .swatches-holder .swatch:first-child .swatch-circle {
      margin-left: 0; }

.bundle-items .attributes .color-swatches .swatches {
  position: relative; }
  .bundle-items .attributes .color-swatches .swatches::after {
    display: block;
    content: "";
    clear: both; }
  .bundle-items .attributes .color-swatches .swatches .name {
    display: none;
    position: absolute;
    top: -12px;
    left: 0;
    text-align: left;
    width: calc(100% - 16px); }
  .bundle-items .attributes .color-swatches .swatches .selected .name {
    color: #181818;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .bundle-items .attributes .color-swatches .swatches .selected .name {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

.bundle-items .attributes .swatch + .bundle-swatch-gripsetup {
  margin-top: 32px; }

.bundle-items .quantity-select {
  display: none; }

.product-detail .product-bundle.byo {
  padding-top: 32px; }
  @media (min-width: 769px) {
    .product-detail .product-bundle.byo {
      padding-top: 64px; } }
  .product-detail .product-bundle.byo .details-popover {
    text-align: right;
    margin-top: -16px;
    padding-bottom: 8px; }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .details-popover {
        margin-top: -24px; } }
    .product-detail .product-bundle.byo .details-popover .popover {
      top: auto;
      left: auto;
      right: 16px;
      margin: 0;
      z-index: 20;
      width: auto;
      bottom: 50px;
      padding: 20px;
      display: none;
      max-width: 350px; }
      @media (min-width: 769px) {
        .product-detail .product-bundle.byo .details-popover .popover {
          left: 50%;
          right: auto;
          bottom: 50px;
          width: 450px;
          padding: 30px;
          max-width: 450px;
          margin-left: -70px; } }
      .product-detail .product-bundle.byo .details-popover .popover:after, .product-detail .product-bundle.byo .details-popover .popover:before {
        left: auto;
        right: 30px;
        content: '';
        bottom: -10px;
        position: absolute;
        border-width: 10px 10px 0;
        border-style: solid; }
        @media (min-width: 769px) {
          .product-detail .product-bundle.byo .details-popover .popover:after, .product-detail .product-bundle.byo .details-popover .popover:before {
            left: 50%;
            right: auto;
            margin-left: -3px; } }
      .product-detail .product-bundle.byo .details-popover .popover:after {
        border-color: #FFFFFF transparent; }
      .product-detail .product-bundle.byo .details-popover .popover:before {
        bottom: -11px;
        border-color: rgba(0, 0, 0, 0.1) transparent; }
    .product-detail .product-bundle.byo .details-popover .btn-detail {
      margin: 0 16px;
      position: relative;
      padding-left: 20px; }
      .product-detail .product-bundle.byo .details-popover .btn-detail i {
        top: 5px;
        left: 0;
        font-size: 12px;
        font-weight: bold;
        position: absolute; }
    .product-detail .product-bundle.byo .details-popover .popover-close {
      top: 15px;
      right: 15px;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      text-decoration: none; }
      @media (min-width: 1024px) {
        .product-detail .product-bundle.byo .details-popover .popover-close {
          top: 30px;
          right: 15px; } }
  .product-detail .product-bundle.byo .bundle-footer {
    padding: 16px 16px 32px; }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .bundle-footer {
        padding: 32px 0; } }
    .product-detail .product-bundle.byo .bundle-footer .notify-submit-btn {
      top: 16px;
      height: 48px; }
  .product-detail .product-bundle.byo .heading-container.no-subtitles {
    margin-bottom: 32px; }
    @media (min-width: 1024px) {
      .product-detail .product-bundle.byo .heading-container.no-subtitles {
        margin-bottom: 48px; } }
    .product-detail .product-bundle.byo .heading-container.no-subtitles .product-name {
      margin-bottom: 8px; }
  .product-detail .product-bundle.byo .heading-container p {
    margin: 0; }
  .product-detail .product-bundle.byo .product-name {
    font-family: "Space Grotesk", sans-serif;
    font-size: 32px;
    line-height: 36px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: -2px;
    font-style: normal;
    font-display: swap;
    margin-bottom: 24px;
    text-align: center; }
    @media (min-width: 1024px) {
      .product-detail .product-bundle.byo .product-name {
        font-family: "Space Grotesk", sans-serif;
        font-size: 48px;
        line-height: 52px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: -2px;
        font-style: normal;
        font-display: swap; } }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .product-name {
        margin-bottom: 32px; } }
  .product-detail .product-bundle.byo .attribute label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .product-detail .product-bundle.byo .attribute label {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .product-detail .product-bundle.byo .bundle-items-label {
    margin: 64px 0 48px; }
    @media (max-width: 1023.98px) {
      .product-detail .product-bundle.byo .bundle-items-label {
        margin: 32px 0; } }
  .product-detail .product-bundle.byo .bundle-items.ootb-bundle .primary-images > .row {
    margin: 0; }
  .product-detail .product-bundle.byo .bundle-items.ootb-bundle .primary-images .primary-images-main {
    display: flex;
    min-height: 370px;
    align-items: center;
    position: relative;
    margin-bottom: 24px; }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .bundle-items.ootb-bundle .primary-images .primary-images-main {
        min-height: 432px; } }
    .product-detail .product-bundle.byo .bundle-items.ootb-bundle .primary-images .primary-images-main .overlay-image {
      z-index: 1;
      top: 0;
      left: 0;
      position: absolute;
      border-radius: 50%;
      transform: translateY(-15%) scale(0.45); }
    .product-detail .product-bundle.byo .bundle-items.ootb-bundle .primary-images .primary-images-main.product-has-thumbnails {
      padding-bottom: 0;
      margin-bottom: 24px; }
      @media (min-width: 769px) {
        .product-detail .product-bundle.byo .bundle-items.ootb-bundle .primary-images .primary-images-main.product-has-thumbnails {
          margin-bottom: 32px; } }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .bundle-items.ootb-bundle .primary-images .primary-images-main {
        margin-bottom: 32px; } }
  @media (min-width: 1024px) {
    .product-detail .product-bundle.byo .bundle-items.ootb-bundle {
      gap: 56px; } }
  .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item {
    position: relative; }
    .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:first-child {
      padding-bottom: 0; }
      @media (min-width: 769px) {
        .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:first-child .primary-images::after {
          display: none; } }
    @media (max-width: 768.98px) {
      .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:last-child::after {
        display: none; } }
    @media (min-width: 1024px) {
      .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:last-child .details-popover .popover {
        left: auto;
        right: 0; }
        .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:last-child .details-popover .popover::after, .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:last-child .details-popover .popover::before {
          left: auto;
          right: 50px; } }
    .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item .primary-images {
      position: relative;
      overflow: visible; }
      @media (max-width: 1023.98px) {
        .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item .primary-images {
          padding: 0; } }
      .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item .primary-images:after {
        top: 50%;
        left: -15px;
        width: 26px;
        height: 26px;
        content: '';
        bottom: auto;
        display: none;
        margin-top: -30px;
        position: absolute;
        background-image: url("../../images/icon-plus.svg");
        background-size: 100%; }
        @media (min-width: 769px) {
          .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item .primary-images:after {
            display: block; } }
        @media (min-width: 1024px) {
          .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item .primary-images:after {
            left: -41px; } }
    .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:after {
      width: 26px;
      height: 26px;
      content: '';
      bottom: 40px;
      display: block;
      margin: 8px auto 32px;
      background-image: url("../../images/icon-plus.svg");
      background-size: 100%; }
      @media (min-width: 769px) {
        .product-detail .product-bundle.byo .bundle-items.ootb-bundle .bundle-item:after {
          top: 320px;
          bottom: auto;
          left: -41px;
          display: none;
          margin-right: 0;
          margin-top: -13px;
          position: absolute; } }
  .product-detail .product-bundle.byo .bundle-items.ootb-bundle .product-name {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    line-height: 28px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    margin-bottom: 16px; }
    @media (min-width: 1024px) {
      .product-detail .product-bundle.byo .bundle-items.ootb-bundle .product-name {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        line-height: 28px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .bundle-items.ootb-bundle .product-name {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        line-height: 32px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap;
        margin-bottom: 32px; } }
  @media (min-width: 769px) and (min-width: 1024px) {
    .product-detail .product-bundle.byo .bundle-items.ootb-bundle .product-name {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      line-height: 32px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .product-detail .product-bundle.byo .attributes {
    display: flex;
    flex-direction: column; }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .attributes {
        display: block; } }
    .product-detail .product-bundle.byo .attributes .pop-top-design .attribute-option {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px; }
    .product-detail .product-bundle.byo .attributes .attribute-option.add {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px; }
    .product-detail .product-bundle.byo .attributes .attribute-option.byo-swatches {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .product-detail .product-bundle.byo .attributes .row.swatch {
      margin-bottom: 24px; }
      @media (min-width: 769px) {
        .product-detail .product-bundle.byo .attributes .row.swatch {
          margin-bottom: 32px; } }
      @media (max-width: 1023.98px) {
        .product-detail .product-bundle.byo .attributes .row.swatch .color-swatches .color-label {
          margin-top: 12px; } }
    .product-detail .product-bundle.byo .attributes .row.static-quantity {
      margin: 0; }
      @media (min-width: 769px) {
        .product-detail .product-bundle.byo .attributes .row.static-quantity {
          margin-bottom: 24px; } }
  .product-detail .product-bundle.byo .color-swatches {
    margin: 0; }
  .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions {
    padding: 0;
    position: relative;
    transition: all 0.1s linear; }
    @media (min-width: 769px) {
      .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions {
        padding: 0;
        position: static; } }
    .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .price {
      display: flex;
      gap: 10px;
      align-items: center; }
      .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .price > span {
        display: flex;
        gap: 10px;
        align-items: center; }
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .price > span del span {
          display: inline; }
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .price > span del .sales {
          margin: 0; }
    .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions h6 {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      margin-bottom: 16px; }
      @media (min-width: 1024px) {
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions h6 {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle {
      width: 84px;
      padding: 0;
      background: none;
      margin: 0 3px 16px; }
      .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle.selected {
        position: relative; }
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle.selected .primary-images .primary-images-main:after {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          content: '';
          position: absolute;
          border-radius: 8px;
          pointer-events: none;
          border: 2px solid #181818; }
      .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .overlay-image {
        z-index: 1;
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 50%;
        transform: translateY(-15%) scale(0.45); }
      .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .primary-images {
        padding: 0;
        overflow: hidden; }
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .primary-images > .row {
          margin: 0; }
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .primary-images:after {
          display: none; }
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .primary-images .primary-images-main {
          margin: 0;
          display: flex;
          height: 84px;
          width: 84px;
          border-radius: 8px;
          background: #F9F9F9;
          align-items: center; }
          .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .primary-images .primary-images-main .slider-container.scroll-slider-active .slide {
            flex: 0 0 100%;
            padding: 0; }
            .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .primary-images .primary-images-main .slider-container.scroll-slider-active .slide img {
              height: auto;
              width: 100%; }
      .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .small-tiles {
        text-align: left;
        padding: 8px 0 0; }
        .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .small-tiles p {
          margin: 0;
          font-family: "Inter", sans-serif;
          font-size: 12px;
          line-height: 16px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; }
          @media (min-width: 1024px) {
            .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .ootb-bundle .small-tiles p {
              font-family: "Inter", sans-serif;
              font-size: 12px;
              line-height: 16px;
              text-transform: none;
              font-weight: bold;
              letter-spacing: 0;
              font-style: normal;
              font-display: swap; } }
    .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .thumbnails-container {
      position: relative;
      transition: all 0.1s linear; }
    .product-detail .product-bundle.byo .bundle-footer .prices-add-to-cart-actions .thumbnails {
      overflow: hidden; }

.product-detail .product-bundle .thumbnail-img {
  border-radius: 15px;
  background-color: #F9F9F9; }

.product-detail .product-bundle .quantity {
  display: none; }

.product-detail .product-bundle .badge-product-container.badge-image-over {
  display: none; }

.product-detail .product-bundle .bundle-footer {
  background: #FFFFFF;
  bottom: 20%;
  width: 100%;
  padding: 32px 0;
  text-align: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); }
  @media (min-width: 769px) {
    .product-detail .product-bundle .bundle-footer {
      padding: 28px 0; } }
  .product-detail .product-bundle .bundle-footer .btn-add-to-cart {
    display: flex;
    margin: 0 auto;
    min-width: inherit;
    width: auto;
    padding: 16px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: 50px;
    color: #FFFFFF; }
    @media (min-width: 1024px) {
      .product-detail .product-bundle .bundle-footer .btn-add-to-cart {
        min-width: 364px;
        padding: 16px 32px; } }
    .product-detail .product-bundle .bundle-footer .btn-add-to-cart:after {
      background-color: #181818; }
    .product-detail .product-bundle .bundle-footer .btn-add-to-cart.disabled, .product-detail .product-bundle .bundle-footer .btn-add-to-cart:disabled, .product-detail .product-bundle .bundle-footer .btn-add-to-cart[disabled] {
      color: #FFFFFF; }
      .product-detail .product-bundle .bundle-footer .btn-add-to-cart.disabled:after, .product-detail .product-bundle .bundle-footer .btn-add-to-cart:disabled:after, .product-detail .product-bundle .bundle-footer .btn-add-to-cart[disabled]:after {
        background-color: #D4D4D4; }
    .product-detail .product-bundle .bundle-footer .btn-add-to-cart .price {
      display: block;
      margin-left: 6.4px; }
      .product-detail .product-bundle .bundle-footer .btn-add-to-cart .price:before {
        content: '-';
        font-weight: bold;
        display: inline-block;
        vertical-align: middle; }
      .product-detail .product-bundle .bundle-footer .btn-add-to-cart .price span {
        display: inline-block;
        vertical-align: middle;
        color: #FFFFFF; }
      .product-detail .product-bundle .bundle-footer .btn-add-to-cart .price del ~ .sales {
        margin-right: 6.4px; }
        .product-detail .product-bundle .bundle-footer .btn-add-to-cart .price del ~ .sales span {
          color: #01DF9D; }
  @media (min-width: 1024px) {
    .product-detail .product-bundle .bundle-footer .notify-me {
      max-width: 364px;
      padding: 16px 32px; } }

.product-detail .product-bundle .attributes .row {
  margin-bottom: 24px; }

.product-detail .product-bundle .btn-add-to-cart .price {
  display: none; }

.product-detail .product-bundle .bundle-items-label {
  font-weight: bold;
  margin: 64px 0 48px; }
  @media (max-width: 1023.98px) {
    .product-detail .product-bundle .bundle-items-label {
      margin: 48px 0; } }

.product-detail .product-bundle .prices-add-to-cart-actions .ootb-bundle {
  width: 64px;
  display: inline-block;
  vertical-align: top;
  margin: 0 3px 8px;
  background: #F9F9F9;
  border-radius: 8px; }
  .product-detail .product-bundle .prices-add-to-cart-actions .ootb-bundle .primary-images .primary-images-main .slider-container.scroll-slider-active .slide {
    flex: 0 0 100%;
    padding: 0; }
    .product-detail .product-bundle .prices-add-to-cart-actions .ootb-bundle .primary-images .primary-images-main .slider-container.scroll-slider-active .slide img {
      height: auto;
      width: 100%; }
  .product-detail .product-bundle .prices-add-to-cart-actions .ootb-bundle .primary-images .primary-images-main.product-has-thumbnails {
    margin-bottom: 0; }

.product-detail .product-bundle .prices-add-to-cart-actions h2 {
  margin: 0 0 8px; }

.product-detail .product-bundle .ootb-bundle {
  display: none; }
  @media (min-width: 769px) {
    .product-detail .product-bundle .ootb-bundle {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  .product-detail .product-bundle .ootb-bundle.bundle-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (min-width: 769px) {
      .product-detail .product-bundle .ootb-bundle.bundle-items {
        flex-wrap: nowrap; } }
  .product-detail .product-bundle .ootb-bundle .primary-images {
    overflow: hidden; }
    @media (max-width: 1023.98px) {
      .product-detail .product-bundle .ootb-bundle .primary-images {
        margin: 0; } }
    .product-detail .product-bundle .ootb-bundle .primary-images .primary-images-main.product-has-thumbnails {
      margin: 0 0 20px; }
      @media (max-width: 1023.98px) {
        .product-detail .product-bundle .ootb-bundle .primary-images .primary-images-main.product-has-thumbnails {
          overflow: hidden;
          padding: 0; } }
  .product-detail .product-bundle .ootb-bundle .product-name {
    font-size: 16px;
    line-height: 24px;
    font-family: "Space Grotesk", sans-serif;
    text-transform: none;
    min-height: 42px;
    padding: 0 8px; }
    @media (min-width: 544px) {
      .product-detail .product-bundle .ootb-bundle .product-name {
        padding: 0; } }
  .product-detail .product-bundle .ootb-bundle .attributes {
    padding: 0 8px; }
    @media (min-width: 544px) {
      .product-detail .product-bundle .ootb-bundle .attributes {
        padding: 0; } }
  .product-detail .product-bundle .ootb-bundle .product-number-rating {
    display: none; }
  .product-detail .product-bundle .ootb-bundle .attributes .product-availability {
    display: none; }
  .product-detail .product-bundle .ootb-bundle .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value {
    font-family: "Inter", sans-serif; }
  .product-detail .product-bundle .ootb-bundle .bundle-item {
    padding: 0;
    flex-basis: 100%; }
    @media (min-width: 769px) {
      .product-detail .product-bundle .ootb-bundle .bundle-item {
        flex-basis: 50%;
        padding: 0 16px 0; } }
    @media (min-width: 1024px) {
      .product-detail .product-bundle .ootb-bundle .bundle-item {
        flex-basis: 33%; } }

.product-detail .product-bundle .non-color-attribute-swatches .swatch-tile-container {
  overflow: hidden; }

.product-detail .product-bundle .bundle-footer {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  z-index: 99;
  visibility: visible;
  position: fixed;
  transition: all 0.1s linear; }

.hide-oos-variants .swatch-tile-container.unselectable {
  display: none; }

.hide-oos-variants .color-swatches .unselectable-btn {
  display: none; }

.product-bundle .bundle-footer {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  z-index: 99;
  opacity: 1;
  position: fixed; }

.js-bundle .product-detail-attributes > .cart-and-ipay .add-to-cart-global .price {
  display: none !important; }

.scroll-direction-up .product-detail .product-bundle.byo .prices-add-to-cart-actions {
  padding-top: 0;
  transition: all 0.1s linear; }
  .scroll-direction-up .product-detail .product-bundle.byo .prices-add-to-cart-actions .thumbnails-container {
    width: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.1s linear;
    transform: translateY(100%); }

body.byo-pdp {
  padding-bottom: 200px; }
  @media (min-width: 769px) {
    body.byo-pdp {
      padding: 0; } }

.search-nav {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #BEBEBE; }
  .search-nav.hide-search-tabs {
    display: none; }
  .search-nav .nav-tabs-wrapper {
    padding: 0;
    width: 100%; }
    .search-nav .nav-tabs-wrapper .nav-link.active {
      background-color: #FFFFFF; }
    @media (min-width: 544px) {
      .search-nav .nav-tabs-wrapper {
        width: auto; } }

.search-results .show-more,
.search-results .show-more-content {
  padding: 16px 0;
  clear: both; }

.search-results .search-keywords {
  font-weight: bold; }

.search-results .category-item {
  margin-bottom: 16px; }

.search-results .tab-pane.active:focus {
  outline: none; }

.grid-header,
.content-grid-header {
  margin-bottom: 16px; }
  @media (min-width: 1024px) {
    .grid-header,
    .content-grid-header {
      margin-bottom: 0; } }
  .grid-header .breadcrumb-container > .row,
  .content-grid-header .breadcrumb-container > .row {
    align-items: center;
    height: 100%; }
  @media (min-width: 1024px) {
    .grid-header .breadcrumb,
    .content-grid-header .breadcrumb {
      margin-bottom: 0; } }
  .grid-header .result-count p,
  .content-grid-header .result-count p {
    margin-bottom: 16px; }
    @media (min-width: 544px) {
      .grid-header .result-count p,
      .content-grid-header .result-count p {
        margin-bottom: 0; } }
  .grid-header .filter-results,
  .grid-header select,
  .content-grid-header .filter-results,
  .content-grid-header select {
    width: 100%; }

.filter-header {
  margin-left: -16px;
  margin-right: -16px; }

.header-bar {
  background-color: #FFFFFF;
  border-bottom: 1px solid transparent;
  padding: 20px; }
  .header-bar button.close {
    line-height: 75%;
    margin: 0;
    padding: 0;
    text-shadow: none;
    opacity: 1; }
    .header-bar button.close:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: #FFFFFF;
      font-weight: normal; }
    .header-bar button.close[class*="btn-"]:not(.title) {
      padding-right: 42px; }
      .header-bar button.close[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .header-bar button.close[class*="btn-"]:not(.title).btn-sm, .header-bar .btn-group-sm > button.close.btn[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .header-bar button.close[class*="btn-"]:not(.title).btn-sm:after, .header-bar .btn-group-sm > button.close.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .header-bar button.close[class*="btn-"]:not(.title).btn-lg, .header-bar .btn-group-lg > button.close.btn[class*="btn-"]:not(.title) {
        padding-right: 63px; }
        .header-bar button.close[class*="btn-"]:not(.title).btn-lg:after, .header-bar .btn-group-lg > button.close.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .header-bar button.close[class*="btn-"]:not(.title) [class*="icon-"],
      .header-bar button.close[class*="btn-"]:not(.title) [class^="icon-"],
      .header-bar button.close[class*="btn-"]:not(.title) .fa {
        display: none; }
    .header-bar button.close span {
      display: none; }
  .header-bar .result-count {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    line-height: 28px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    color: #FFFFFF; }

.filter-bar ul {
  padding-left: 16px;
  margin: 0; }
  @media (min-width: 1440px) {
    .filter-bar ul {
      padding-left: 16px; } }

.filter-bar li {
  list-style: none;
  float: left;
  margin-right: 5px;
  margin-bottom: 16px; }
  .filter-bar li:not(:first-child) {
    margin-left: 5px; }
  .filter-bar li.filter-value {
    position: relative; }
    .filter-bar li.filter-value button {
      color: #000000;
      background: #F9F9F9;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      border: 1px solid #D4D4D4;
      border-radius: 20px;
      padding-top: 9px;
      padding-right: 32px;
      padding-bottom: 8px;
      padding-left: 16px; }
      .filter-bar li.filter-value button:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 8px;
        color: inherit;
        font-weight: normal; }
      .filter-bar li.filter-value button[class*="btn-"]:not(.title) {
        padding-right: 42px; }
        .filter-bar li.filter-value button[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .filter-bar li.filter-value button[class*="btn-"]:not(.title).btn-sm, .filter-bar li.filter-value .btn-group-sm > button.btn[class*="btn-"]:not(.title) {
          padding-right: 42px; }
          .filter-bar li.filter-value button[class*="btn-"]:not(.title).btn-sm:after, .filter-bar li.filter-value .btn-group-sm > button.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .filter-bar li.filter-value button[class*="btn-"]:not(.title).btn-lg, .filter-bar li.filter-value .btn-group-lg > button.btn[class*="btn-"]:not(.title) {
          padding-right: 63px; }
          .filter-bar li.filter-value button[class*="btn-"]:not(.title).btn-lg:after, .filter-bar li.filter-value .btn-group-lg > button.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .filter-bar li.filter-value button[class*="btn-"]:not(.title) [class*="icon-"],
        .filter-bar li.filter-value button[class*="btn-"]:not(.title) [class^="icon-"],
        .filter-bar li.filter-value button[class*="btn-"]:not(.title) .fa {
          display: none; }
      .filter-bar li.filter-value button:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px; }
      .filter-bar li.filter-value button:hover {
        color: black; }

.filter-bar .result-count {
  padding: 8px 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px; }
  .filter-bar .result-count:not(:only-child) {
    margin-right: 16px; }
    @media (min-width: 1440px) {
      .filter-bar .result-count:not(:only-child) {
        margin-right: 16px; } }
  .filter-bar .result-count p {
    margin-bottom: 0; }

.refinement-bar {
  overflow: auto; }
  .refinement-bar-horizontal {
    overflow: visible; }
  @media (min-width: 1024px) {
    .refinement-bar:not([class*="refinement-bar-horizontal"]) {
      margin-top: 16px; }
      .refinement-bar:not([class*="refinement-bar-horizontal"]) + div {
        margin-top: 16px; } }
  .refinement-bar ul {
    padding: 0;
    margin: 0;
    line-height: 1; }
  .refinement-bar .secondary-bar {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 16px; }
    .refinement-bar .secondary-bar li {
      padding: 0; }
    @media (min-width: 1024px) {
      .refinement-bar .secondary-bar {
        margin-top: 0; } }
    .refinement-bar .secondary-bar .secondary-bar-links {
      text-decoration: none;
      line-height: 24px;
      padding: 8px 0;
      display: block;
      width: 100%; }
    .refinement-bar .secondary-bar li:last-child .secondary-bar-links {
      text-align: right; }
  @media (max-width: 1023.98px) {
    .refinement-bar {
      position: fixed;
      transform: translateX(-100%);
      transition: transform 200ms ease-out;
      top: 0;
      left: 0;
      display: block;
      width: 350px;
      height: calc(100vh - 60px);
      background-color: #FFFFFF;
      z-index: 250;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 200px; }
      .refinement-bar.in {
        transform: translateX(0); } }
  @media (max-width: 768.98px) {
    .refinement-bar {
      height: calc(100vh - 60px); } }
  @media (max-width: 543.98px) {
    .refinement-bar {
      width: calc(100% - 50px);
      max-width: 350px; } }

.refinements > .card:last-child {
  border-bottom: none; }

.refinements .values.content {
  font-size: 0;
  display: flex;
  flex-wrap: wrap; }
  .refinements .values.content li {
    padding-left: 0;
    padding-bottom: 20px;
    display: block;
    max-width: 100%; }
    .refinements .values.content li.col-sm-4 {
      width: 100%;
      flex-basis: 100%; }
  .refinements .values.content button {
    border: none;
    background: none;
    padding: 0;
    text-align: left; }
    .refinements .values.content button:hover i.fa {
      box-shadow: 0 0 0 1px #595959; }
    .refinements .values.content button:hover .swatch-circle {
      box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 4px #181818; }
    .refinements .values.content button span {
      font-size: 14px;
      display: inline-block;
      vertical-align: middle; }
      .refinements .values.content button span:not(.color-value) {
        margin-right: 12px; }
      .refinements .values.content button span.unselectable.swatch-circle:hover {
        box-shadow: none; }
      .refinements .values.content button span.non-color-swatch-value {
        position: relative;
        color: #222222;
        background: #F9F9F9;
        border: 2px solid #F9F9F9;
        border-radius: 8px;
        padding: 16px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap;
        text-align: center;
        margin-right: 10px; }
        @media (min-width: 1024px) {
          .refinements .values.content button span.non-color-swatch-value {
            font-family: "Inter", sans-serif;
            font-size: 20px;
            line-height: 28px;
            text-transform: none;
            font-weight: normal;
            letter-spacing: 0;
            font-style: normal;
            font-display: swap; } }
        .refinements .values.content button span.non-color-swatch-value.Color-value {
          transition: box-shadow 200ms ease-out; }
          .refinements .values.content button span.non-color-swatch-value.Color-value:hover {
            box-shadow: none; }
          .refinements .values.content button span.non-color-swatch-value.Color-value.selected {
            border: 2px solid #181818;
            border-radius: 4px;
            box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
            .refinements .values.content button span.non-color-swatch-value.Color-value.selected:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 7px;
              color: #FFFFFF;
              font-weight: normal; }
            .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title) {
                padding-right: 42px; }
                .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-lg, .refinements .values.content button .btn-group-lg > span.non-color-swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title) {
                padding-right: 63px; }
                .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title).btn-lg:after, .refinements .values.content button .btn-group-lg > span.non-color-swatch-value.Color-value.selected.btn[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.Color-value.selected[class*="btn-"]:not(.title) .fa {
                display: none; }
            .refinements .values.content button span.non-color-swatch-value.Color-value.selected::after {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              display: none;
              background: rgba(34, 34, 34, 0.7);
              width: 14px;
              height: 14px;
              line-height: 14px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              z-index: 1; }
          .refinements .values.content button span.non-color-swatch-value.Color-value.unselectable {
            filter: blur(0px); }
            .refinements .values.content button span.non-color-swatch-value.Color-value.unselectable + .unselectable-icon {
              pointer-events: none;
              cursor: not-allowed;
              position: absolute;
              height: 72px;
              width: 72px;
              bottom: 0;
              right: 0; }
              @media (max-width: 768.98px) {
                .refinements .values.content button span.non-color-swatch-value.Color-value.unselectable + .unselectable-icon {
                  height: calc(18.5vw);
                  width: calc(18.5vw); } }
              .refinements .values.content button span.non-color-swatch-value.Color-value.unselectable + .unselectable-icon:before, .refinements .values.content button span.non-color-swatch-value.Color-value.unselectable + .unselectable-icon:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%) rotate(45deg);
                height: calc(100% + 18px);
                width: 1px; }
              .refinements .values.content button span.non-color-swatch-value.Color-value.unselectable + .unselectable-icon:after {
                background-color: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
                z-index: 2;
                width: 2px; }
          .refinements .values.content button span.non-color-swatch-value.Color-value.unselectable {
            pointer-events: none;
            pointer-events: auto;
            cursor: not-allowed;
            position: relative; }
        .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value {
          transition: border 200ms ease-out, box-shadow 200ms ease-out; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value:not(.unselectable):hover {
            box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 0 #181818; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover {
            background: #F9F9F9;
            color: #222222;
            border-color: #181818;
            box-shadow: none; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 8px;
              color: #FFFFFF;
              font-weight: normal; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title), .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
              padding-right: 42px; }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
                padding-right: 42px; }
                .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg, .refinements .values.content button .btn-group-lg > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg, .refinements .values.content button .btn-group-lg > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
                padding-right: 63px; }
                .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-lg:after, .refinements .values.content button .btn-group-lg > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-lg:after, .refinements .values.content button .btn-group-lg > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
                display: none; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected::after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover::after {
              position: absolute;
              top: -5.33333px;
              right: -5.33333px;
              background: #181818;
              width: 16px;
              height: 16px;
              line-height: 16px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              display: none; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unselectable {
            pointer-events: auto;
            cursor: not-allowed;
            position: relative;
            color: #D4D4D4; }
    .refinements .values.content button i.fa {
      position: relative;
      vertical-align: middle;
      margin-right: 12px;
      transition: box-shadow 200ms ease-out; }
    .refinements .values.content button i.fa-circle-o {
      content: "";
      width: 24px;
      height: 24px;
      border: 1px solid #595959;
      border-radius: 50%;
      background-color: #FFFFFF; }
    .refinements .values.content button i.fa-check-circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #FFFFFF;
      border: 1px solid #595959; }
      .refinements .values.content button i.fa-check-circle:after {
        content: "";
        background: none;
        width: 12px;
        height: 12px;
        top: calc(12px/ 2);
        left: calc(-34px + 12px/ 2);
        background-color: #181818;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto; }
    .refinements .values.content button i.fa-square-o {
      content: "";
      width: 24px;
      height: 24px;
      border: 1px solid #595959;
      background-color: #FFFFFF;
      border-radius: 2px; }
    .refinements .values.content button i.fa-check-square {
      content: "";
      width: 24px;
      height: 24px;
      background-color: #181818;
      border: 1px solid #595959;
      border-radius: 2px; }
      .refinements .values.content button i.fa-check-square:after {
        content: "";
        width: 24px;
        height: 24px;
        line-height: calc(24px + 2px);
        background: none;
        font-family: "icomoon";
        font-size: 16px;
        text-align: center;
        color: #FFFFFF;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto; }
  .refinements .values.content .swatches-vertical {
    flex-basis: 50%; }
  .refinements .values.content .swatches-grid {
    padding-bottom: 10px; }

.refinements .refinement-category .values.content {
  flex-direction: column; }

.refinements .refinement-category .values li {
  display: block; }

.refinements .refinement-category .values .values {
  margin-left: 34px;
  margin-top: 27px; }
  .refinements .refinement-category .values .values li {
    padding-top: 20px;
    padding-bottom: 0; }

.refinements .swatch-color-text {
  max-width: calc(100% - 20px - 12px);
  min-width: 0;
  padding-left: 12px; }

@media (min-width: 1024px) {
  .refinement-bar-horizontal .horizontal-filter-btn.custom-select, .refinement-bar-horizontal select.horizontal-filter-btn.form-control,
  .refinement-bar-horizontal select.horizontal-filter-btn {
    width: auto; }
  .horizontal-multipledropdowns-filter-refinement.card.aside {
    height: calc(16px + 12px + 14px + (1px*2));
    border-top: 1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    border-left: 1px solid #D4D4D4;
    border-right: 1px solid #D4D4D4;
    min-width: 150px;
    margin-right: 10px;
    margin-bottom: 0; }
    .horizontal-multipledropdowns-filter-refinement.card.aside.refinement-category .values.content {
      margin-left: 0;
      margin-top: 0; }
      .horizontal-multipledropdowns-filter-refinement.card.aside.refinement-category .values.content.values li {
        padding-top: 0; }
  .refinements .horizontal-multipledropdowns-filter-dropdown-menu .refinement-category .values.content button {
    padding-bottom: 20px; }
  .horizontal-multipledropdowns-filter-dropdown-menu {
    min-width: 100vw;
    margin-top: 0;
    padding: 64px 0 48px; }
    .horizontal-multipledropdowns-filter-dropdown-menu .card-body {
      min-height: 0; }
    .horizontal-multipledropdowns-filter-dropdown-menu .values.content {
      margin-left: 0;
      margin-top: 0;
      min-height: 0;
      max-width: 300px; }
    .horizontal-multipledropdowns-filter-dropdown-menu.show {
      position: absolute;
      transform: translate3d(0px, 43px, 0px);
      top: 0px;
      left: 0px;
      will-change: transform;
      display: block; }
  .horizontal-multipledropdowns-filter-btn {
    border: none;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 10px; }
    .horizontal-multipledropdowns-filter-btn:after {
      float: right; }
  .refinement-bar-horizontal-one-dropdown .desktop-search-refinements {
    position: relative; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements:not(.sticky-filters) .refinements-container {
      padding: 0; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper {
      display: none; }
      .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-filters {
        border-top: 1px solid #D4D4D4;
        border-bottom: 1px solid #D4D4D4;
        border-right: 1px solid #D4D4D4;
        border-left: 1px solid #D4D4D4;
        margin-top: 15px; }
      .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter {
        display: flex;
        border-top: 1px solid #D4D4D4;
        border-bottom: 1px solid #D4D4D4;
        border-right: 1px solid #D4D4D4;
        border-left: 1px solid #D4D4D4;
        max-width: 1408px;
        margin-top: 15px; }
        .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .horizontal-onedropdown-filter-refinement {
          width: 33%;
          padding-top: 15px;
          padding-bottom: 15px;
          border: none; }
        .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown {
          display: flex; }
          .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown:before {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: inherit;
            color: inherit;
            font-weight: normal; }
          .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) {
            padding-left: 42px; }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: inherit;
              font-weight: normal;
              position: absolute;
              transform: translateX(-30px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-sm, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-sm > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title) {
              padding-left: 42px; }
              .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-sm:before, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-sm > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title):before {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: inherit;
                font-weight: normal;
                position: absolute;
                transform: translateX(-32px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-lg, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-lg > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title) {
              padding-left: 63px; }
              .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-lg:before, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-lg > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title):before {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: inherit;
                font-weight: normal;
                position: absolute;
                transform: translateX(-32px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) [class*="icon-"],
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) [class^="icon-"],
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) .fa {
              display: none; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements.sticky-filters .horizontal-onedropdown-sortBy {
      position: relative; }
  .horizontal-onedropdown-filter-btn.title {
    width: 150px;
    background-color: transparent;
    padding-right: 10px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    border-right: 1px solid #D4D4D4;
    border-left: 1px solid #D4D4D4;
    text-align: left; }
  .collapse-filters-wrapper .horizontal-onedropdown-filter-btn.title::after,
  .collapse-filters-wrapper.active .horizontal-onedropdown-filter-btn.title::after {
    content: '';
    display: none; }
  .horizontal-onedropdown-filter select[name="sort-order"] {
    position: absolute;
    right: 0;
    width: 150px; }
  .horizontal-onedropdown-filter-group .horizontal-onedropdown-filter-btn {
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .horizontal-onedropdown-filter-group .horizontal-onedropdown-filter-btn .horizontal-onedropdown-filter-btn-arrow {
      background-image: url(../images/icons/icon-arrow-down.svg);
      background-size: 8px;
      width: 12px;
      height: 12px; }
  .horizontal-onedropdown-filter-group.active .horizontal-onedropdown-filter-btn-arrow {
    transform: scaleY(-1); } }

.horizontal-filter-refinements-bar {
  padding-top: 16px;
  padding-bottom: 16px; }
  @media (min-width: 1024px) {
    .horizontal-filter-refinements-bar .refinements-container {
      padding: 0; } }
  @media (min-width: 1024px) {
    .horizontal-filter-refinements-bar .refinements-container select[name="sort-order"] {
      font-size: 16px; } }
  @media (min-width: 1440px) {
    .horizontal-filter-refinements-bar.sticky-filters .refinements-container {
      padding: 0 16px; } }

.sticky-filters {
  position: fixed;
  left: 0;
  z-index: 300;
  background: #FFFFFF;
  width: 100%;
  border-bottom: none;
  padding-left: 16px;
  padding-right: 16px; }
  .sticky-header .sticky-filters {
    transition: transform 400ms ease-out; }
  .sticky-header.scroll-direction-up .sticky-filters {
    transform: translateY(var(--header-height));
    transition: transform 400ms ease-out 50ms; }

.plp .image-text-block.aspect-ratio-portrait .image-wrapper::before {
  padding-top: 0; }

.plp .promo-tile.col-sm-6, .plp .promo-tile.col-sm-12 {
  margin-bottom: 45px; }
  @media (min-width: 544px) {
    .plp .promo-tile.col-sm-6 .image-text-block .image-wrapper .background-image, .plp .promo-tile.col-sm-12 .image-text-block .image-wrapper .background-image {
      padding-top: 280px; } }
  @media (min-width: 769px) {
    .plp .promo-tile.col-sm-6 .image-text-block .image-wrapper .background-image, .plp .promo-tile.col-sm-12 .image-text-block .image-wrapper .background-image {
      padding-top: 394px; } }

.plp .image-text-block.square-size .image-wrapper .background-image {
  padding: calc(100% + (16px * 2) - (16px * 2)) 0 0; }

.plp .image-text-block .image-wrapper .background-image {
  position: relative;
  top: initial;
  left: initial;
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
  padding: calc(100% + (53px * 2) - (16px * 2)) 0 0;
  border-radius: 20px; }

.plp .image-text-block .image-wrapper::before {
  padding-top: 0; }

.plp .image-text-block.aspect-ratio-portrait .image-wrapper::before {
  padding-top: 0; }

.plp .image-text-block .image-text-block-text,
.plp .image-text-block.text-placement-below .image-text-block-text {
  margin-top: 10px; }

.plp .image-text-block.product-tile .image-wrapper .background-image {
  padding-top: calc(100% + (53px * 2) - (16px * 2)); }

.plp .image-text-block.medium .image-text-block-text .primary-text, .plp .image-text-block.large .image-text-block-text .primary-text {
  margin-bottom: 8px; }

.plp .image-text-block-text .primary-text {
  margin-bottom: 4px;
  padding-bottom: 0; }
  .plp .image-text-block-text .primary-text:hover {
    text-decoration: none; }
  .plp .image-text-block-text .primary-text.text-shadow h1, .plp .image-text-block-text .primary-text.text-shadow h2, .plp .image-text-block-text .primary-text.text-shadow h3, .plp .image-text-block-text .primary-text.text-shadow h4, .plp .image-text-block-text .primary-text.text-shadow h5, .plp .image-text-block-text .primary-text.text-shadow h6 {
    text-shadow: 0 40px 80px rgba(0, 0, 0, 0.3); }

.plp .image-text-block-text .secondary-text.text-shadow p {
  text-shadow: 0 40px 80px rgba(0, 0, 0, 0.3); }

.plp .image-text-block-text .image-text-block-subtitle.text-shadow h1, .plp .image-text-block-text .image-text-block-subtitle.text-shadow h2, .plp .image-text-block-text .image-text-block-subtitle.text-shadow h3, .plp .image-text-block-text .image-text-block-subtitle.text-shadow h4, .plp .image-text-block-text .image-text-block-subtitle.text-shadow h5, .plp .image-text-block-text .image-text-block-subtitle.text-shadow h6 {
  text-shadow: 0 40px 80px rgba(0, 0, 0, 0.3); }

.plp .slider-container.two-column-slider .tns-controls button {
  top: 50%; }

.plp .grid-header .custom-select:focus, .plp .grid-header select.form-control:focus,
.plp .grid-header select:focus, .plp .grid-header select:focus,
.plp .refinement-bar-horizontal .custom-select:focus,
.plp .refinement-bar-horizontal select.form-control:focus,
.plp .refinement-bar-horizontal select:focus,
.plp .refinement-bar-horizontal select:focus {
  box-shadow: none; }

.plp .grid-header .custom-select, .plp .grid-header select.form-control,
.plp .grid-header select, .plp .grid-header select.form-control, .plp .grid-header select,
.plp .refinement-bar-horizontal .custom-select,
.plp .refinement-bar-horizontal select.form-control,
.plp .refinement-bar-horizontal select,
.plp .refinement-bar-horizontal select.form-control,
.plp .refinement-bar-horizontal select {
  background-image: url(../images/icons/icon-arrow-down.svg);
  background-size: 10px; }
  @media (min-width: 1024px) {
    .plp .grid-header .custom-select, .plp .grid-header select.form-control,
    .plp .grid-header select, .plp .grid-header select.form-control, .plp .grid-header select,
    .plp .refinement-bar-horizontal .custom-select,
    .plp .refinement-bar-horizontal select.form-control,
    .plp .refinement-bar-horizontal select,
    .plp .refinement-bar-horizontal select.form-control,
    .plp .refinement-bar-horizontal select {
      padding-right: 32px; } }
  @media (max-width: 1023.98px) {
    .plp .grid-header .custom-select, .plp .grid-header select.form-control,
    .plp .grid-header select, .plp .grid-header select.form-control, .plp .grid-header select,
    .plp .refinement-bar-horizontal .custom-select,
    .plp .refinement-bar-horizontal select.form-control,
    .plp .refinement-bar-horizontal select,
    .plp .refinement-bar-horizontal select.form-control,
    .plp .refinement-bar-horizontal select {
      background-size: 12px; } }

.refinement-container {
  border-radius: 20px;
  background: #FFFFFF;
  margin-bottom: 0; }
  @media (min-width: 544px) {
    .refinement-container {
      box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.05); } }
  .refinement-container .horizontal-filter-refinements-bar {
    padding: 24px 16px; }
    .refinement-container .horizontal-filter-refinements-bar .sort-by-label {
      color: #181818;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .refinement-container .horizontal-filter-refinements-bar .sort-by-label {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          line-height: 24px;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .refinement-container .horizontal-filter-refinements-bar select[name="sort-order"] {
      color: #181818;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .refinement-container .horizontal-filter-refinements-bar select[name="sort-order"] {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          line-height: 24px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    @media (min-width: 1024px) {
      .refinement-container .horizontal-filter-refinements-bar .refinements-container-right {
        justify-content: space-between;
        min-width: 61.8%; } }
  @media (min-width: 1024px) {
    .refinement-container {
      margin-bottom: 32px; } }
  @media (max-width: 768.98px) {
    .refinement-container .slider-container.scroll-slider-active .slide {
      flex: 0 0 calc(42.5% - 10px);
      padding: 0 5px; }
      .refinement-container .slider-container.scroll-slider-active .slide:first-child {
        padding-left: 0; } }
  @media (max-width: 768.98px) {
    .refinement-container .slider-container.scroll-slider-active.next-slide-40.slider-buttons-hidden {
      margin-left: 0;
      margin-right: 0; } }
  @media (max-width: 768.98px) {
    .refinement-container .slider-container.scroll-slider-active.next-slide-22.slider-buttons-hidden {
      margin-left: 0;
      margin-right: 0; } }

.refinementproducttype {
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 100%; }
  .refinementproducttype .values.content {
    flex-wrap: unset; }
  .refinementproducttype .slider-container {
    width: 100%; }

.refinements .refinement-price .values.content li.col-sm-4 {
  width: auto;
  flex-basis: auto; }
  @media (max-width: 543.98px) {
    .refinements .refinement-price .values.content li.col-sm-4 {
      min-width: 50%; } }
  @media (min-width: 1024px) {
    .refinements .refinement-price .values.content li.col-sm-4 {
      padding: 0; }
      .refinements .refinement-price .values.content li.col-sm-4 button {
        padding: 16px; } }
  .refinements .refinement-price .values.content li.col-sm-4 .col-md-12 {
    width: auto;
    flex-basis: auto; }

.refinements .values.content {
  max-width: 887px; }
  .refinements .values.content:not(.no-gutters) {
    margin: 0 auto; }
  .refinements .values.content li {
    margin: 0;
    border-radius: 0;
    width: auto;
    border: 0;
    padding: 0 8px; }
    @media (min-width: 769px) {
      .refinements .values.content li {
        margin: 0 8px 16px;
        background-color: transparent;
        padding: 16px;
        border-radius: 8px;
        width: 100%;
        border: 1px solid transparent; } }
  .refinements .values.content button {
    color: #181818;
    padding: 10px 1px;
    border-radius: 8px;
    width: 100%; }
    @media (min-width: 769px) {
      .refinements .values.content button {
        border: 0;
        padding: 0;
        width: auto;
        background: none; } }
    .refinements .values.content button .swatch-circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-size: 35px;
      background-position: -1px -1px; }
      .refinements .values.content button .swatch-circle.selected {
        box-shadow: 0 0 0 calc(4px - 1px) #FFFFFF, 0 0 0 4px #181818; }
      .refinements .values.content button .swatch-circle.white, .refinements .values.content button .swatch-circle.White {
        border: 1px solid #D4D4D4; }
    .refinements .values.content button span:not(.color-value) {
      flex-grow: 1; }
    .refinements .values.content button.selected {
      border-color: #000000; }
    .refinements .values.content button span {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .refinements .values.content button span {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
    .refinements .values.content button:hover i.fa {
      box-shadow: 0 0 0 1px #595959; }
    .refinements .values.content button i.fa {
      position: relative;
      vertical-align: middle;
      margin-right: 12px;
      transition: box-shadow 200ms ease-out; }
    .refinements .values.content button i.fa-circle-o {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid #595959;
      border-radius: 50%;
      background-color: #FFFFFF;
      margin-top: 2px; }
    .refinements .values.content button i.fa-check-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #FFFFFF;
      border: 1px solid #595959;
      margin-top: 2px; }
      .refinements .values.content button i.fa-check-circle:after {
        content: "";
        background: none;
        width: 8px;
        height: 8px;
        top: calc(12px/ 2);
        left: calc(-34px + 12px/ 2);
        background-color: #181818;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto; }
    .refinements .values.content button i.fa-square-o {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid #595959;
      background-color: #FFFFFF;
      border-radius: 4px;
      margin-top: 2px; }
    .refinements .values.content button i.fa-check-square {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #181818;
      border: 1px solid #595959;
      border-radius: 4px;
      margin-top: 2px; }
      .refinements .values.content button i.fa-check-square:after {
        content: "";
        width: 20px;
        height: 20px;
        line-height: calc(20px + 2px);
        background: none;
        font-family: "icomoon";
        font-size: 10px;
        text-align: center;
        color: #FFFFFF;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto;
        border-radius: 4px; }

.refinements #refinement-group-price .values.content button span {
  margin-right: 0; }

@media (min-width: 1024px) {
  .refinements .refinement-color .values.content li {
    padding: 0; }
    .refinements .refinement-color .values.content li button {
      padding: 16px;
      width: 100%; } }

@media (max-width: 768.98px) {
  .refinements .refinement-color .values.content li button {
    padding: 4px 10px; }
    .refinements .refinement-color .values.content li button i.fa-square-o {
      margin-top: 6px; }
    .refinements .refinement-color .values.content li button i.fa-check-square {
      margin-top: 6px; } }

.refinement-product-type-card {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 8px;
  padding-left: 1px; }
  @media (min-width: 1024px) {
    .refinement-product-type-card {
      flex-direction: column;
      justify-content: center;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      font-weight: 500; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .refinement-product-type-card {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .refinement-product-type-card img {
    font-size: 0;
    height: 32px;
    width: 32px;
    margin: 0 16px 0 4px; }
  .refinement-product-type-card:hover i.fa {
    box-shadow: 0 0 0 1px #595959; }
  .refinement-product-type-card i.fa {
    position: relative;
    vertical-align: middle;
    margin-right: 12px;
    transition: box-shadow 200ms ease-out; }
  .refinement-product-type-card i.fa-circle-o {
    content: "";
    width: 24px;
    height: 24px;
    border: 1px solid #595959;
    border-radius: 50%;
    background-color: #FFFFFF; }
  .refinement-product-type-card i.fa-check-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 1px solid #595959; }
    .refinement-product-type-card i.fa-check-circle:after {
      content: "";
      background: none;
      width: 12px;
      height: 12px;
      top: calc(12px/ 2);
      left: calc(-34px + 12px/ 2);
      background-color: #181818;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      left: auto; }
  .refinement-product-type-card i.fa-square-o {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #595959;
    background-color: #FFFFFF;
    border-radius: 4px; }
  .refinement-product-type-card i.fa-check-square {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #181818;
    border: 1px solid #595959;
    border-radius: 4px; }
    .refinement-product-type-card i.fa-check-square:after {
      content: "";
      width: 20px;
      height: 20px;
      line-height: calc(20px + 2px);
      background: none;
      font-family: "icomoon";
      font-size: 10px;
      text-align: center;
      color: #FFFFFF;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      left: auto;
      border-radius: 4px; }

.refinement-bar .refinements.horizontal-multipledropdowns-filter .dropdown-menu,
.row .grid-header .refinements.horizontal-multipledropdowns-filter .dropdown-menu {
  overflow-y: auto;
  max-height: calc(100vh - 10px); }
  .refinement-bar .refinements.horizontal-multipledropdowns-filter .dropdown-menu .card,
  .refinement-bar .refinements.horizontal-multipledropdowns-filter .dropdown-menu .card-body,
  .row .grid-header .refinements.horizontal-multipledropdowns-filter .dropdown-menu .card,
  .row .grid-header .refinements.horizontal-multipledropdowns-filter .dropdown-menu .card-body {
    background: #FFFFFF; }

.refinement-bar .refinements.horizontal-multipledropdowns-filter.sticky-filters .dropdown-menu,
.row .grid-header .refinements.horizontal-multipledropdowns-filter.sticky-filters .dropdown-menu {
  transform: translate3d(5px, 35px, 0px);
  max-height: calc(100vh - 65px); }

.refinement-bar .horizontal-multipledropdowns-filter-refinement,
.row .grid-header .horizontal-multipledropdowns-filter-refinement {
  position: relative; }
  .refinement-bar .horizontal-multipledropdowns-filter-refinement .dropdown-menu,
  .row .grid-header .horizontal-multipledropdowns-filter-refinement .dropdown-menu {
    width: 300px;
    max-height: 550px !important;
    min-width: inherit;
    top: 44px !important;
    left: 0 !important;
    transform: initial !important;
    border: none;
    box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.05);
    padding: 40px 16px;
    /*PAP-4198: Adding scrollbar to show all the time for Safari*/
    /* Set the width and height of the scrollbar */
    /* Set the background color of the scrollbar */
    /* Set the color of the scrollbar thumb */
    /* Keep the scrollbar always visible */
    /*PAP-4198: Adding scrollbar to show all the time for Safari*/ }
    .refinement-bar .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar,
    .row .grid-header .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar {
      width: 10px;
      height: 10px; }
    .refinement-bar .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar-track,
    .row .grid-header .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar-track {
      background-color: #F1F1F1; }
    .refinement-bar .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar-thumb,
    .row .grid-header .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar-thumb {
      background-color: #888888;
      border-radius: 5px; }
    .refinement-bar .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar-thumb:vertical,
    .row .grid-header .horizontal-multipledropdowns-filter-refinement .dropdown-menu::-webkit-scrollbar-thumb:vertical {
      min-height: 8px; }

.refinement-bar .device-model-select .horizontal-multipledropdowns-filter-btn,
.row .grid-header .device-model-select .horizontal-multipledropdowns-filter-btn {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap;
  font-weight: 500 !important; }
  @media (min-width: 1024px) {
    .refinement-bar .device-model-select .horizontal-multipledropdowns-filter-btn,
    .row .grid-header .device-model-select .horizontal-multipledropdowns-filter-btn {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

.refinement-bar .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu,
.row .grid-header .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu {
  left: 0 !important;
  overflow: hidden;
  overflow-y: auto; }
  @media (max-width: 1023.98px) {
    .refinement-bar .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu,
    .row .grid-header .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu {
      max-height: 270px !important;
      width: 230px; } }

.refinement-bar .custom-select:focus, .refinement-bar select.form-control:focus,
.refinement-bar select:focus, .refinement-bar select:focus,
.row .grid-header .custom-select:focus,
.row .grid-header select.form-control:focus,
.row .grid-header select:focus,
.row .grid-header select:focus {
  box-shadow: none; }

.refinement-bar .custom-select, .refinement-bar select.form-control,
.refinement-bar select, .refinement-bar select.form-control, .refinement-bar select,
.row .grid-header .custom-select,
.row .grid-header select.form-control,
.row .grid-header select,
.row .grid-header select.form-control,
.row .grid-header select {
  background-image: url(../images/icons/icon-arrow-down.svg);
  background-size: 10px; }
  @media (min-width: 1024px) {
    .refinement-bar .custom-select, .refinement-bar select.form-control,
    .refinement-bar select, .refinement-bar select.form-control, .refinement-bar select,
    .row .grid-header .custom-select,
    .row .grid-header select.form-control,
    .row .grid-header select,
    .row .grid-header select.form-control,
    .row .grid-header select {
      padding-right: 32px; } }

.refinement-bar .horizontal-multipledropdowns-filter-btn,
.row .grid-header .horizontal-multipledropdowns-filter-btn {
  text-align: left;
  color: #181818;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-style: normal;
  font-display: swap;
  font-weight: 500 !important; }
  @media (min-width: 1024px) {
    .refinement-bar .horizontal-multipledropdowns-filter-btn,
    .row .grid-header .horizontal-multipledropdowns-filter-btn {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
  .refinement-bar .horizontal-multipledropdowns-filter-btn:after,
  .row .grid-header .horizontal-multipledropdowns-filter-btn:after {
    float: none; }
  @media (min-width: 1024px) {
    .refinement-bar .horizontal-multipledropdowns-filter-btn:after,
    .row .grid-header .horizontal-multipledropdowns-filter-btn:after {
      width: auto;
      height: auto; }
    .refinement-bar .horizontal-multipledropdowns-filter-btn:hover:after, .refinement-bar .horizontal-multipledropdowns-filter-btn:focus:after,
    .row .grid-header .horizontal-multipledropdowns-filter-btn:hover:after,
    .row .grid-header .horizontal-multipledropdowns-filter-btn:focus:after {
      top: 0;
      left: 0; } }

@media (max-width: 1199.98px) {
  .refinement-bar .mobile-search-refinements .collapsible-xl.aside .title::after,
  .row .grid-header .mobile-search-refinements .collapsible-xl.aside .title::after {
    height: auto;
    width: auto;
    text-align: right;
    padding-right: 10px;
    z-index: auto;
    top: auto; } }

.refinement-bar .mobile-search-refinements .refinements .card.aside,
.row .grid-header .mobile-search-refinements .refinements .card.aside {
  border-color: #D4D4D4;
  border-radius: 0; }
  .refinement-bar .mobile-search-refinements .refinements .card.aside .card-header .card-title,
  .row .grid-header .mobile-search-refinements .refinements .card.aside .card-header .card-title {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    padding-top: 32px;
    padding-bottom: 32px; }
    @media (min-width: 1024px) {
      .refinement-bar .mobile-search-refinements .refinements .card.aside .card-header .card-title,
      .row .grid-header .mobile-search-refinements .refinements .card.aside .card-header .card-title {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

.refinement-bar .mobile-search-refinements .filter-bar-mobile,
.refinement-bar .refinement-category .filter-bar-mobile,
.row .grid-header .mobile-search-refinements .filter-bar-mobile,
.row .grid-header .refinement-category .filter-bar-mobile {
  padding-top: 64px;
  border-top: 1px solid #F9F9F9; }
  .refinement-bar .mobile-search-refinements .filter-bar-mobile ul,
  .refinement-bar .refinement-category .filter-bar-mobile ul,
  .row .grid-header .mobile-search-refinements .filter-bar-mobile ul,
  .row .grid-header .refinement-category .filter-bar-mobile ul {
    display: flex;
    flex-wrap: wrap; }

@media (max-width: 1199.98px) {
  .refinement-bar .mobile-search-refinements .filter-bar-mobile,
  .refinement-bar .refinement-category .filter-bar-mobile,
  .row .grid-header .mobile-search-refinements .filter-bar-mobile,
  .row .grid-header .refinement-category .filter-bar-mobile {
    padding-top: 64px;
    border-top: 1px solid #D4D4D4; }
    .refinement-bar .mobile-search-refinements .filter-bar-mobile ul,
    .refinement-bar .refinement-category .filter-bar-mobile ul,
    .row .grid-header .mobile-search-refinements .filter-bar-mobile ul,
    .row .grid-header .refinement-category .filter-bar-mobile ul {
      display: flex;
      flex-wrap: wrap; }
  .refinement-bar .mobile-search-refinements .selected-filters-list,
  .refinement-bar .refinement-category .selected-filters-list,
  .row .grid-header .mobile-search-refinements .selected-filters-list,
  .row .grid-header .refinement-category .selected-filters-list {
    margin-left: -5px; }
    .refinement-bar .mobile-search-refinements .selected-filters-list li,
    .refinement-bar .refinement-category .selected-filters-list li,
    .row .grid-header .mobile-search-refinements .selected-filters-list li,
    .row .grid-header .refinement-category .selected-filters-list li {
      margin-left: 5px;
      margin-right: 5px; }
    .refinement-bar .mobile-search-refinements .selected-filters-list .text-link,
    .refinement-bar .refinement-category .selected-filters-list .text-link,
    .row .grid-header .mobile-search-refinements .selected-filters-list .text-link,
    .row .grid-header .refinement-category .selected-filters-list .text-link {
      color: #181818;
      margin-top: 8px;
      margin-left: 8px;
      font-size: 14px; } }

.refinement-bar .mobile-search-refinements .card-header,
.refinement-bar .refinement-category .card-header,
.row .grid-header .mobile-search-refinements .card-header,
.row .grid-header .refinement-category .card-header {
  background-color: #FFFFFF; }

.refinement-bar .mobile-search-refinements ul, .refinement-bar .mobile-search-refinements li, .refinement-bar .mobile-search-refinements button, .refinement-bar .mobile-search-refinements a,
.refinement-bar .refinement-category ul,
.refinement-bar .refinement-category li,
.refinement-bar .refinement-category button,
.refinement-bar .refinement-category a,
.row .grid-header .mobile-search-refinements ul,
.row .grid-header .mobile-search-refinements li,
.row .grid-header .mobile-search-refinements button,
.row .grid-header .mobile-search-refinements a,
.row .grid-header .refinement-category ul,
.row .grid-header .refinement-category li,
.row .grid-header .refinement-category button,
.row .grid-header .refinement-category a {
  display: flex;
  text-decoration: none; }
  .refinement-bar .mobile-search-refinements ul span, .refinement-bar .mobile-search-refinements li span, .refinement-bar .mobile-search-refinements button span, .refinement-bar .mobile-search-refinements a span,
  .refinement-bar .refinement-category ul span,
  .refinement-bar .refinement-category li span,
  .refinement-bar .refinement-category button span,
  .refinement-bar .refinement-category a span,
  .row .grid-header .mobile-search-refinements ul span,
  .row .grid-header .mobile-search-refinements li span,
  .row .grid-header .mobile-search-refinements button span,
  .row .grid-header .mobile-search-refinements a span,
  .row .grid-header .refinement-category ul span,
  .row .grid-header .refinement-category li span,
  .row .grid-header .refinement-category button span,
  .row .grid-header .refinement-category a span {
    margin: auto;
    line-height: 24px; }

.refinement-bar .filter-header,
.row .grid-header .filter-header {
  margin-left: 0;
  margin-right: 0;
  padding-top: 16px;
  padding-bottom: 16px; }
  .refinement-bar .filter-header .header-bar,
  .row .grid-header .filter-header .header-bar {
    display: flex;
    background: #FFFFFF;
    padding: 0; }
    .refinement-bar .filter-header .header-bar .result-count,
    .refinement-bar .filter-header .header-bar button.close:after,
    .row .grid-header .filter-header .header-bar .result-count,
    .row .grid-header .filter-header .header-bar button.close:after {
      color: #181818; }
    .refinement-bar .filter-header .header-bar .js-close-filters,
    .row .grid-header .filter-header .header-bar .js-close-filters {
      color: #181818; }

.refinement-bar .refinements .refinement-category .values .values li,
.row .grid-header .refinements .refinement-category .values .values li {
  padding-top: 0; }

@media (min-width: 1024px) {
  .horizontal-multipledropdowns-filter-refinement.card.aside {
    margin-right: 20px;
    min-width: auto;
    height: auto; }
    .horizontal-multipledropdowns-filter-refinement.card.aside:last-child {
      margin-right: 0; }
    .horizontal-multipledropdowns-filter-refinement.card.aside .horizontal-multipledropdowns-filter-btn {
      font-weight: 500 !important;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      padding: 5px 0;
      color: #181818; } }
    @media (min-width: 1024px) and (min-width: 1024px) {
      .horizontal-multipledropdowns-filter-refinement.card.aside .horizontal-multipledropdowns-filter-btn {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

@media (min-width: 1024px) {
      .horizontal-multipledropdowns-filter-refinement.card.aside .horizontal-multipledropdowns-filter-btn::before {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        background: #181818;
        content: " ";
        display: none; }
      .horizontal-multipledropdowns-filter-refinement.card.aside .horizontal-multipledropdowns-filter-btn[aria-expanded="true"]::before, .horizontal-multipledropdowns-filter-refinement.card.aside .horizontal-multipledropdowns-filter-btn:hover::before, .horizontal-multipledropdowns-filter-refinement.card.aside .horizontal-multipledropdowns-filter-btn:focus::before {
        display: block; }
      .horizontal-multipledropdowns-filter-refinement.card.aside .horizontal-multipledropdowns-filter-btn[aria-expanded="false"]:not(:hover)::before {
        display: none; } }

@media (min-width: 1200px) {
  .horizontal-multipledropdowns-filter-refinement.card.aside {
    margin-right: 35px; } }

@media (min-width: 1440px) {
  .horizontal-multipledropdowns-filter-refinement.card.aside {
    margin-right: 64px; } }

.device-model-select {
  margin-right: 20px; }
  @media (min-width: 1200px) {
    .device-model-select {
      margin-right: 35px; } }
  @media (min-width: 1440px) {
    .device-model-select {
      margin-right: 64px; } }
  @media (max-width: 1023.98px) {
    .device-model-select {
      width: 100%;
      margin-right: 0; } }
  .device-model-select .horizontal-multipledropdowns-filter-btn {
    padding-right: 0;
    position: relative;
    min-width: auto;
    width: auto;
    padding-left: 0;
    max-width: fit-content;
    color: #181818; }
    @media (max-width: 1023.98px) {
      .device-model-select .horizontal-multipledropdowns-filter-btn {
        border: 0;
        color: #181818;
        padding: 2px 0;
        font-weight: 500 !important;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  @media (max-width: 1023.98px) and (min-width: 1024px) {
    .device-model-select .horizontal-multipledropdowns-filter-btn {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }
    @media (max-width: 1023.98px) {
        .device-model-select .horizontal-multipledropdowns-filter-btn::after {
          content: "";
          background-image: url(../images/icons/down-arrow.svg);
          background-size: 14px;
          padding-right: 16px;
          background-repeat: no-repeat;
          background-position: right;
          width: 14px;
          height: 24px;
          margin: 0;
          top: -1px;
          right: 0;
          left: 0; } }
    .device-model-select .horizontal-multipledropdowns-filter-btn::before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
      background: #181818;
      content: " ";
      display: none; }
    .device-model-select .horizontal-multipledropdowns-filter-btn[aria-expanded="true"]::before, .device-model-select .horizontal-multipledropdowns-filter-btn:hover::before, .device-model-select .horizontal-multipledropdowns-filter-btn:focus::before {
      display: block; }
  .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body {
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin: 0;
    background: transparent; }
    .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li {
      position: relative;
      padding: 0 0 16px 24px !important;
      cursor: pointer;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; }
      @media (min-width: 1024px) {
        .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li {
          font-family: "Inter", sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-weight: normal;
          letter-spacing: 0;
          font-style: normal;
          font-display: swap; } }
      .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li button {
        width: 100%;
        text-align: left;
        color: #000000; }
      .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li::before {
        content: "";
        width: 24px;
        height: 24px;
        line-height: calc(24px + 2px);
        background: none;
        font-family: "icomoon";
        font-size: 16px;
        text-align: center;
        color: #FFFFFF;
        display: none; }
      .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li:last-child {
        padding-bottom: 0 !important; }
      .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li i {
        display: none !important; }
      .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li.selected {
        font-weight: bold; }
        .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li.selected::before {
          display: block;
          position: absolute;
          left: 0;
          top: 2px;
          font-size: 12px;
          line-height: 16px;
          color: #000000; }
        .device-model-select .horizontal-multipledropdowns-filter-refinement .dropdown-menu .card.card-body > li.selected button {
          font-weight: bold; }

@media (max-width: 1023.98px) {
  .refinement-bar {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    overflow: hidden;
    height: auto;
    bottom: 0; } }

.refinement-bar .device-model-select {
  width: 60%;
  padding-left: 10px; }
  .refinement-bar .device-model-select select {
    border: 0; }

.refinement-bar .mobile-filters-scrollable {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  flex: 1; }
  .refinement-bar .mobile-filters-scrollable .mobile-search-refinements .collapsible-xl.aside .title::after {
    top: 50%;
    transform: translateY(-50%); }
  .refinement-bar .mobile-filters-scrollable .mobile-search-refinements .refinements .card.aside .card-header .card-title {
    padding-top: 20px;
    padding-bottom: 20px; }
  .refinement-bar .mobile-filters-scrollable .selected-filter-wrapper {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .refinement-bar .mobile-filters-scrollable .selected-filter-wrapper {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .refinement-bar .mobile-filters-scrollable .sort-by-container .custom-radio .custom-control-label:before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #595959;
    border-radius: 50%;
    background-color: #FFFFFF; }
  .refinement-bar .mobile-filters-scrollable .sort-by-container .custom-radio .custom-control-label:after {
    top: 0;
    left: calc(-20px - 10px); }
  .refinement-bar .mobile-filters-scrollable .sort-by-container .custom-radio .custom-control-input:checked ~ .custom-control-label:before {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 1px solid #595959; }
  .refinement-bar .mobile-filters-scrollable .sort-by-container .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
    content: "";
    background: none;
    width: 8px;
    height: 8px;
    top: calc(12px/ 2);
    left: calc(-34px + 12px/ 2);
    background-color: #181818;
    border-radius: 50%;
    outline: none; }

.refinement-bar .filter-header {
  padding: 16px 0 24px; }
  .refinement-bar .filter-header .search-result-count {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    color: #595959; }
    @media (min-width: 1024px) {
      .refinement-bar .filter-header .search-result-count {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  .refinement-bar .filter-header .selected-filters-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    border-bottom: 1px solid #D4D4D4; }
  .refinement-bar .filter-header .reset {
    display: none; }

.refinement-bar .filter-footer {
  padding: 16px 0 16px;
  background-color: #FFFFFF;
  border-top: 1px solid #D4D4D4; }
  .refinement-bar .filter-footer .reset {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .refinement-bar .filter-footer .reset {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

.filter-bar li.filter-value button {
  background: transparent;
  display: flex;
  align-items: center; }
  .filter-bar li.filter-value button img {
    height: 20px;
    width: 20px;
    font-size: 0;
    margin-right: 8px; }
  .filter-bar li.filter-value button .swatch-circle {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-size: 22px;
    background-position: -1px -1px;
    margin-right: 8px; }
  .filter-bar li.filter-value button:after {
    left: initial;
    width: auto;
    height: auto;
    border-radius: initial;
    z-index: 0;
    transition: none;
    right: 16px;
    font-size: 9px;
    margin-top: 1px; }

.filter-bar .text-link.reset {
  font-size: 14px;
  margin-top: 8px;
  margin-left: 16px; }
  @media (min-width: 1024px) {
    .filter-bar .text-link.reset {
      margin-left: 20px; } }

.product-data .pdp-email-product-notify input {
  border-color: #595959;
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }
  .product-data .pdp-email-product-notify input:hover, .product-data .pdp-email-product-notify input:focus {
    box-shadow: none;
    border-color: #595959; }

.horizontal-multipledropdowns-filter-dropdown-menu .values.content {
  margin: 0 auto; }

@media (min-width: 769px) {
  .amer-refinements .refinements .refinement-color .values.content li.amer-swatch {
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0; }
    .amer-refinements .refinements .refinement-color .values.content li.amer-swatch button {
      margin: 0 24px; }
      .amer-refinements .refinements .refinement-color .values.content li.amer-swatch button .swatch-circle.White {
        border: 1px solid #D4D4D4; } }

@media (min-width: 1024px) {
  .amer-refinements .refinements .refinement-color .values.content {
    margin: 0 auto;
    justify-content: start;
    max-width: 100%;
    overflow: hidden; }
    .amer-refinements .refinements .refinement-color .values.content .swatches-grid .amer-swatch {
      margin: 0 0 16px; } }

@media (min-width: 1024px) {
  .amer-refinements .refinements .refinement-price .values.content li.col-sm-4,
  .amer-refinements .refinements .refinement-devicetype .values.content li.col-sm-4 {
    width: calc(25% - 16px);
    flex-basis: calc(25% - 16px);
    padding: 0; }
    .amer-refinements .refinements .refinement-price .values.content li.col-sm-4 button,
    .amer-refinements .refinements .refinement-devicetype .values.content li.col-sm-4 button {
      width: 100%;
      padding: 16px; } }

@media (min-width: 1024px) {
  .amer-refinements .refinements .refinement-price .values.content,
  .amer-refinements .refinements .refinement-devicetype .values.content {
    margin: 0 auto;
    justify-content: start;
    max-width: 100%;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px; }
    .amer-refinements .refinements .refinement-price .values.content .swatches-grid .amer-swatch,
    .amer-refinements .refinements .refinement-devicetype .values.content .swatches-grid .amer-swatch {
      margin: 0 0 16px; } }

.refinement-bar {
  width: 100%;
  max-width: 100%;
  z-index: 1000;
  /*covers header on mobile*/ }
  .refinement-bar .refinements.horizontal-multipledropdowns-filter .refinement-refinementproducttype .dropdown-menu {
    overflow-y: auto;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap; }
    @media (min-width: 1024px) {
      .refinement-bar .refinements.horizontal-multipledropdowns-filter .refinement-refinementproducttype .dropdown-menu {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  @media (max-width: 1199.98px) {
    .refinement-bar.in .custom-radio + .custom-radio {
      margin-top: 20px; } }
  @media (min-width: 769px) {
    .refinement-bar {
      z-index: 998; } }

#horizontal-filter-mobile {
  padding: 16px 0;
  margin-bottom: 0; }
  @media (min-width: 544px) {
    #horizontal-filter-mobile {
      padding: 16px; } }
  @media (min-width: 1024px) {
    #horizontal-filter-mobile {
      padding: 0; } }
  #horizontal-filter-mobile .search-result-count {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    color: #595959; }
    @media (min-width: 1024px) {
      #horizontal-filter-mobile .search-result-count {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }
  #horizontal-filter-mobile .device-model-select select {
    border: 0;
    padding-left: 0; }
  #horizontal-filter-mobile .filter-bar ul {
    overflow: hidden;
    padding-left: 0;
    padding-top: 20px;
    border-bottom: 1px solid #D4D4D4;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 45px; }
    #horizontal-filter-mobile .filter-bar ul li {
      float: none; }
  #horizontal-filter-mobile.sticky-filters {
    padding: 16px; }
    #horizontal-filter-mobile.sticky-filters .filter-bar-mobile {
      display: none; }

@media (min-width: 1024px) {
  .desktop-search-refinements .horizontal-multipledropdowns-filter .results-count-wrapper,
  .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-wrapper {
    width: auto; }
  .desktop-search-refinements .horizontal-multipledropdowns-filter .results-count-wrapper {
    color: #595959;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-style: normal;
    font-display: swap;
    font-weight: 500; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .desktop-search-refinements .horizontal-multipledropdowns-filter .results-count-wrapper {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap; } }

@media (min-width: 1024px) {
  .desktop-search-refinements .horizontal-multipledropdowns-filter .filter-left-part {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container {
    position: relative;
    justify-content: flex-start; }
    .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select[name=sort-order] {
      width: 82px;
      height: 36px;
      text-align: center;
      padding-left: 1px;
      text-align: left;
      margin-right: 20px;
      padding-right: 12px;
      background: none;
      background-image: none !important;
      border-radius: 0;
      color: #181818;
      border: 0;
      border-bottom: none;
      cursor: pointer;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-style: normal;
      font-display: swap;
      font-weight: 500 !important; } }
    @media (min-width: 1024px) and (min-width: 1024px) {
      .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select[name=sort-order] {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0;
        font-style: normal;
        font-display: swap; } }

@media (min-width: 1024px) {
      .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select[name=sort-order]:hover, .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select[name=sort-order]:focus {
        border-bottom: 1px solid #181818 !important; }
    .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select[name=sort-order].open {
      border-bottom: 1px solid #181818 !important; }
    .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container .dropdown-icon {
      position: absolute;
      left: 39%;
      top: 54%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background: url("../images/icons/down-arrow.svg") no-repeat center;
      background-size: contain;
      pointer-events: none;
      transition: transform 0.3s ease; }
    .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select.open + .dropdown-icon {
      transform: translateY(-50%) rotate(180deg); }
  .desktop-search-refinements .horizontal-multipledropdowns-filter .select-label {
    position: absolute;
    color: transparent;
    opacity: 0;
    z-index: -1;
    font-size: 14px; }
  .desktop-search-refinements .horizontal-multipledropdowns-filter .dropdown-toggle::after {
    content: "";
    background-image: url(../images/icons/down-arrow.svg);
    background-size: 14px;
    padding-right: 16px;
    background-repeat: no-repeat;
    background-position: right 4px;
    width: 14px;
    height: 22px;
    margin: 0;
    padding: 0;
    left: auto;
    right: -2px; } }

@media (min-width: 1200px) {
  .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select[name=sort-order] {
    margin-right: 35px; } }

@media (min-width: 1440px) {
  .desktop-search-refinements .horizontal-multipledropdowns-filter .sort-by-container select[name=sort-order] {
    margin-right: 64px; } }

.grid-header .filter-results::before {
  transform: translate(-30px, -50%);
  top: 50%; }
  @media (max-width: 1023.98px) {
    .grid-header .filter-results::before {
      transform: translateY(-50%);
      right: 12px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFySURBVHgBrVLLbYNAEGUxsuybL7bsmzuw0wFUYNJBXEGcCgIdkAqSVGCnApMKTCoIN5C4cAMhPnkPEWtJIIkUjzQsMzvz3uzMKMo/RXx1TCCj0egWvzrUh9phGPp/BlgsFqeqqtZQTwgxoeZ5bkRR5HUCNIxmbQixQuIO510QBM58Pl/CPsE+lGX5oqrqRE5O0/SgDYdDHf+PdCD4827PD0sHCCtZQ0nSAkBuXD+BTDzBsgbLHsEOyn4eDAYruJ/YB7A5qLQFQIJvPQCYheP+/EYhvCRJjBiidPWgy9lUpEN9sLjKDyKUXwRgOo4l1O0aZy8ApzMej48cqeS2AGLLcZpU8lnQsBgN2zXJ26IoPE3TbmBbs9nsDY2ud6JuIhwmO98qS4gYE/F5IsiQSN7lOMRca1mWuWDbyhdgjAFqAmDDRDKBfQObu/LAyTCOub09mE6n3Ikjq6DyOUzEhl7JcWofAHcfCQx+RTJ3wOY+KJeWD8x1soVV5jO5AAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-position: center;
      content: " ";
      display: block;
      height: 16px;
      width: 16px; } }

.grid-header .filter-results .filter-count {
  height: 33px;
  width: auto;
  border-radius: 50%;
  border: 0;
  padding: 0 0 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center; }

@media (max-width: 1199.98px) {
  .grid-header .filter-results {
    padding: 0 36px 0 15px;
    min-height: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    max-width: inherit;
    border: 0;
    font-size: 14px;
    line-height: 20px; } }

.grid-header .filter-results::after {
  border-width: 1px; }

.sticky-filters {
  right: 0;
  width: auto; }
  @media (min-width: 1024px) {
    .sticky-filters {
      width: 100%; } }

.product-grid .video-container {
  max-height: 370px; }
  @media (max-width: 1199.98px) {
    .product-grid .video-container {
      height: calc(100% - 190px); } }
  .product-grid .video-container .video-player {
    border-radius: 20px;
    overflow: hidden; }
    .product-grid .video-container .video-player iframe {
      height: 105%; }
      @media (min-width: 1024px) {
        .product-grid .video-container .video-player iframe {
          height: 114%; } }
      @media (min-width: 1200px) {
        .product-grid .video-container .video-player iframe {
          height: 120%; } }

.product-grid .product {
  display: flex;
  min-height: 100%;
  align-items: flex-start; }
  .product-grid .product .product-tile {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100%;
    margin-bottom: 32px; }
    @media (min-width: 1024px) {
      .product-grid .product .product-tile {
        margin-bottom: 45px; } }
    .product-grid .product .product-tile .main-link {
      flex: 1 0 70%;
      margin-bottom: 10px; }
    .product-grid .product .product-tile .product-tile-image-container {
      height: 100%;
      margin-bottom: 0; }
      @media (min-width: 1024px) {
        .product-grid .product .product-tile .product-tile-image-container.show-secondary-image {
          min-height: 406px; } }
      .product-grid .product .product-tile .product-tile-image-container.full-bleed-image {
        padding: calc(100% + (53px * 2) - (16px * 2)) 0 0; }
        .product-grid .product .product-tile .product-tile-image-container.full-bleed-image img {
          top: 0;
          left: 0;
          height: 100%;
          object-fit: cover;
          position: absolute; }

@media (max-width: 1023.98px) {
  .plp-three-tile-wrapper .image-text-block .image-cropper .image-wrapper .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 0;
    border-radius: 0%; } }

@media (max-width: 1023.98px) {
  .plp-three-tile-wrapper .image-text-block .image-cropper .image-wrapper::before {
    padding-top: calc(1 / 1 * 100%); } }

@media (max-width: 768.98px) {
  .search-results .row:not(.no-gutters).product-grid {
    margin-right: -11px;
    margin-left: -11px; } }

.search-results .row:not(.no-gutters) {
  margin-right: -4px;
  margin-left: -4px; }

.search-results .row:not(.no-gutters) > .col,
.search-results .row:not(.no-gutters) > [class*="col-"] {
  padding-right: 4px;
  padding-left: 4px; }

@media (min-width: 769px) {
  .search-results .row:not(.no-gutters) {
    margin-right: -8px;
    margin-left: -8px; }
  .search-results .row:not(.no-gutters) > .col,
  .search-results .row:not(.no-gutters) > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; } }

@media (min-width: 1440px) {
  .search-results .row:not(.no-gutters) {
    margin-right: -8px;
    margin-left: -8px; }
  .search-results .row:not(.no-gutters) > .col,
  .search-results .row:not(.no-gutters) > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; } }

.store-locator-container .btn-storelocator-search {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 32px;
  text-align: center;
  font-weight: 500;
  text-shadow: none;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 15px 8px 15px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  color: #FFFFFF;
  background-color: transparent;
  border: none;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  padding: 8px 15px 8px 15px; }
  .store-locator-container .btn-storelocator-search:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out; }
  .store-locator-container .btn-storelocator-search:hover:after, .store-locator-container .btn-storelocator-search:focus:after, .store-locator-container .btn-storelocator-search[aria-describedby*="popover"]:after {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }
  .store-locator-container .btn-storelocator-search:after {
    background-color: #181818; }
  .store-locator-container .btn-storelocator-search:hover, .store-locator-container .btn-storelocator-search:focus, .store-locator-container .btn-storelocator-search[aria-describedby*="popover"] {
    color: #FFFFFF;
    text-decoration: none; }
    .store-locator-container .btn-storelocator-search:hover:after, .store-locator-container .btn-storelocator-search:focus:after, .store-locator-container .btn-storelocator-search[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px;
      background-color: #5d5d5d; }
  .dark-theme .store-locator-container .btn-storelocator-search {
    color: #181818; }
    .dark-theme .store-locator-container .btn-storelocator-search:after {
      background-color: #FFFFFF; }
    .dark-theme .store-locator-container .btn-storelocator-search:hover, .dark-theme .store-locator-container .btn-storelocator-search:focus, .dark-theme .store-locator-container .btn-storelocator-search[aria-describedby*="popover"] {
      color: #181818; }
    .dark-theme .store-locator-container .btn-storelocator-search.disabled, .dark-theme .store-locator-container .btn-storelocator-search:disabled, .dark-theme .store-locator-container .btn-storelocator-search[disabled] {
      color: #FFFFFF; }
      .dark-theme .store-locator-container .btn-storelocator-search.disabled:after, .dark-theme .store-locator-container .btn-storelocator-search:disabled:after, .dark-theme .store-locator-container .btn-storelocator-search[disabled]:after {
        background-color: #D4D4D4; }
  .store-locator-container .btn-storelocator-search.disabled, .store-locator-container .btn-storelocator-search:disabled, .store-locator-container .btn-storelocator-search[disabled] {
    color: #FFFFFF; }
    .store-locator-container .btn-storelocator-search.disabled:after, .store-locator-container .btn-storelocator-search:disabled:after, .store-locator-container .btn-storelocator-search[disabled]:after {
      background-color: #D4D4D4; }
  .store-locator-container .btn-storelocator-search[class*="icon-"], .store-locator-container .btn-storelocator-search[class^="icon-"] {
    padding-left: 42px; }
    .store-locator-container .btn-storelocator-search[class*="icon-"]:before, .store-locator-container .btn-storelocator-search[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }

.store-locator-container .detect-location-spacer {
  margin-bottom: 16px; }

.store-locator-container .search-form {
  width: 100%; }

.store-locator-container .results-card > .card-body {
  padding-right: 0;
  padding-bottom: 0; }

.store-locator-container .filter-results {
  margin-bottom: 16px;
  padding-right: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .store-locator-container .filter-results .btn {
    margin-bottom: 5px;
    margin-right: 5px; }

@media (min-width: 769px) {
  .store-locator-container .results {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0;
    max-height: 350px; } }

.store-locator-container .results-card > .card-header label {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
  text-transform: none;
  letter-spacing: 0; }

.store-locator-container .results .store-result {
  display: flex;
  margin-bottom: 16px; }
  .store-locator-container .results .store-result .map-marker {
    flex-shrink: 0; }

.store-details .store-name a {
  display: block;
  margin-bottom: 5px; }

.store-details .store-name p {
  font-style: italic;
  font-weight: normal;
  margin-bottom: 5px; }

.store-details .store-map {
  display: block; }

.store-details .store-map,
.store-details .store-hours {
  margin-bottom: 5px; }

.store-details ul,
.store-details address {
  margin-bottom: 0; }

.data-checkout-stage .shipping-section .store-details .store-name a,
.product-summary-block .shipment-block .store-details .store-name a,
.product-summary-block .multi-shipping .store-details .store-name a,
.cart-page .line-item-store .store-details .store-name a {
  font-size: inherit;
  letter-spacing: 0; }

.cart-page .line-item-store .store-details {
  margin-top: 5px; }

.map-marker {
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  position: relative;
  letter-spacing: 0;
  margin-right: 16px; }
  .map-marker span {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    color: #FFFFFF;
    top: 0;
    width: 32px; }

.gm-style .gm-style-iw,
.gm-style .gm-style-iw-c {
  border-color: transparent;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.1);
  max-width: 300px; }
  .gm-style .gm-style-iw .store-details,
  .gm-style .gm-style-iw-c .store-details {
    margin-left: 0;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 1.5; }
    .gm-style .gm-style-iw .store-details address,
    .gm-style .gm-style-iw-c .store-details address {
      line-height: 1.75; }
    .gm-style .gm-style-iw .store-details .store-hours,
    .gm-style .gm-style-iw-c .store-details .store-hours {
      margin-bottom: 5px; }
  .gm-style .gm-style-iw .title,
  .gm-style .gm-style-iw-c .title {
    font-size: 18px; }

.store-locator-no-results {
  padding-right: 16px;
  display: none; }

.store-locator-no-apiKey {
  display: none; }

.map-canvas {
  height: 250px;
  display: block; }
  @media (min-width: 769px) {
    .map-canvas {
      height: 450px; } }

.store-detail-page .store-name {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 32px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal;
  color: #222222; }

.store-detail-page .store-attribute + .store-attribute {
  margin-top: 8px; }
  .store-detail-page .store-attribute + .store-attribute.store-additional {
    margin-top: 30px; }
  .store-detail-page .store-attribute + .store-attribute.store-image {
    margin-top: 30px; }

.store-detail-page .store-attribute label {
  font-weight: bold; }

.store-detail-page .store-map-link {
  display: block;
  margin-top: 16px; }

.styleguide .main__section > .row {
  background: #FFFFFF; }
  .styleguide .main__section > .row .brand-logo {
    height: 50px;
    max-width: 160px;
    background-image: url(../images/logo.svg); }
    @media (min-width: 769px) {
      .styleguide .main__section > .row .brand-logo {
        height: 100px;
        max-width: 212px;
        background-image: url(../images/logo.svg); } }
    @media (min-width: 1024px) {
      .styleguide .main__section > .row .brand-logo {
        max-width: 212px;
        background-image: url(../images/logo.svg); } }
  .styleguide .main__section > .row .sg__header1, .styleguide .main__section > .row .sg__header2, .styleguide .main__section > .row .sg__header3, .styleguide .main__section > .row .sg__header4 {
    color: rgba(24, 24, 24, 0.75); }
  .styleguide .main__section > .row .font--primary::after {
    content: "Inter, sans-serif"; }
  .styleguide .main__section > .row .font--secondary::after {
    content: "Space Grotesk, sans-serif"; }
  .styleguide .main__section > .row .font--tertiary {
    font-weight: bold; }
    .styleguide .main__section > .row .font--tertiary::after {
      content: "Space Grotesk, sans-serif";
      font-weight: bold; }
  .styleguide .main__section > .row .color-value.background--brand-primary::after {
    content: "#181818"; }
  .styleguide .main__section > .row .color-value.background--light-gray::after {
    content: "#F9F9F9"; }
  .styleguide .main__section > .row .color-value.background--medium-gray::after {
    content: "#D4D4D4"; }
  .styleguide .main__section > .row .color-value.background--dark-gray::after {
    content: "#595959"; }
  .styleguide .main__section > .row .color-value.background--primary-black::after {
    content: "#181818"; }
  .styleguide .main__section > .row .color-value.background--primary-black-hover::after {
    content: "#5d5d5d"; }
  .styleguide .main__section > .row .color-value.background--jade::after {
    content: "#2EAB2B"; }
  .styleguide .main__section > .row .color-value.background--neon::after {
    content: "#2EAB2B"; }
  .styleguide .main__section > .row .color-value.background--dark-mint::after {
    content: "#76CBB4"; }
  .styleguide .main__section > .row .color-value.background--mint::after {
    content: "#C6FDCF"; }
  .styleguide .main__section > .row .color-value.background--mint-2::after {
    content: "#d3f0ea"; }
  .styleguide .main__section > .row .color-value.background--sunshine::after {
    content: "#FFDF59"; }
  .styleguide .main__section > .row .color-value.background--cobalt::after {
    content: "#2867EC"; }
  .styleguide .main__section > .row .color-value.background--seafoam::after {
    content: "#ACE7FA"; }
  .styleguide .main__section > .row .color-value.background--violet::after {
    content: "#634ED4"; }
  .styleguide .main__section > .row .color-value.background--periwinkle::after {
    content: "#6C8FFF"; }
  .styleguide .main__section > .row .color-value.background--watermelon::after {
    content: "#FF748B"; }
  .styleguide .main__section > .row .color-value.background--hot-pink::after {
    content: "#F5A4AD"; }
  .styleguide .main__section > .row .color-value.background--tangerine::after {
    content: "#FF9244"; }
  .styleguide .main__section > .row .color-value.background--peach::after {
    content: "#FFAF80"; }
  .styleguide .main__section > .row .color-value.background--light-peach::after {
    content: "#FEF0E5"; }
  .styleguide .main__section > .row .color-value.background--success::after {
    content: "#01DF9D"; }
  .styleguide .main__section > .row .color-value.background--danger::after {
    content: "#a80b1d"; }
  .styleguide .main__section > .row .color-value.background--warning::after {
    content: "#FFDF59"; }
  .styleguide .main__section > .row .color-value.background--info::after {
    content: "#ACE7FA"; }
  .styleguide .main__section > .row .color-value.background--dark-red::after {
    content: "#8B0000"; }
  .styleguide .main__section > .row .color-value.background--spring-wood::after {
    content: "#F6F7F4"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-xs::after {
    content: "0 - 768px"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-md::after {
    content: "769px - 1439px"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-xl::after {
    content: "1440px +"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-xs::after {
    content: "16px"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-md::after {
    content: "32px"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-xl::after {
    content: "32px"; }
  .styleguide .main__section > .row .grid-columns__xs {
    padding-right: 8px;
    padding-left: 8px; }
    .styleguide .main__section > .row .grid-columns__xs .row {
      margin-right: -8px;
      margin-left: -8px; }
    .styleguide .main__section > .row .grid-columns__xs .col {
      padding-right: 8px;
      padding-left: 8px; }
  .styleguide .main__section > .row .grid-columns__md {
    padding-right: 16px;
    padding-left: 16px; }
    .styleguide .main__section > .row .grid-columns__md .row {
      margin-right: -16px;
      margin-left: -16px; }
    .styleguide .main__section > .row .grid-columns__md .col {
      padding-right: 16px;
      padding-left: 16px; }
  .styleguide .main__section > .row .grid-columns__xl {
    padding-right: 16px;
    padding-left: 16px; }
    .styleguide .main__section > .row .grid-columns__xl .row {
      margin-right: -16px;
      margin-left: -16px; }
    .styleguide .main__section > .row .grid-columns__xl .col {
      padding-right: 16px;
      padding-left: 16px; }

.styleguide .main .section--typography .bp--mobile::after {
  content: "0 - 768px"; }

.styleguide .main .section--typography .bp--tablet::after {
  content: "769px - 1023px"; }

.styleguide .main .section--typography .bp--mobile-tablet::after {
  content: "0 - 1023px"; }

.styleguide .main .section--typography .bp--desktop::after {
  content: "1024px +"; }

.styleguide .main .section--typography .h1--mobile .h1 {
  font-size: 32px;
  line-height: 36px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h1--tablet .h1 {
  font-size: 32px;
  line-height: 36px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h1--desktop .h1 {
  font-size: 32px;
  line-height: 36px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--mobile .h2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--tablet .h2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--desktop .h2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--mobile .h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--tablet .h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--desktop .h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--mobile .h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--tablet .h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--desktop .h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--mobile .h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--tablet .h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--desktop .h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--mobile .h6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--tablet .h6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--desktop .h6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .p--mobile .p--small {
  font-size: 16px;
  line-height: 24px; }

.styleguide .main .section--typography .p--mobile .p {
  font-size: 16px;
  line-height: 24px; }

.styleguide .main .section--typography .p--mobile .p--large {
  font-size: 24px;
  line-height: 32px; }

.styleguide .main .section--typography .p--desktop .p--small {
  font-size: 16px;
  line-height: 24px; }

.styleguide .main .section--typography .p--desktop .p {
  font-size: 16px;
  line-height: 24px; }

.styleguide .main .section--typography .p--desktop .p--large {
  font-size: 24px;
  line-height: 24px; }

/* ALL FILES KEPT LOCAL, PREVENTS ACCIDENTIAL BASE STYLES INCLUSION */
.salesforce-payments-errors {
  font-size: 0.8em;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border: 1px solid lightblue;
  border-radius: 20px;
  background: #fee url(../../images/exclamation.svg) 0.5rem 0.5rem/1rem no-repeat; }

.salesforce-payments-errors:empty {
  display: none; }

.salesforce-paymentrequest-element-errors {
  margin-top: 0.75rem; }

.salesforce-paymentrequest-element[disabled] {
  display: none; }

.salesforce-secure-payment {
  margin-left: 1.2rem;
  font-size: 0.8em;
  padding: 0.3rem 0.5rem 0.5rem 1.5rem;
  background: url(../../images/padlock.svg) no-repeat; }

.cart-and-ipay .add-to-cart {
  width: 100%;
  display: block; }

@media (max-width: 543.98px) {
  .cart-and-ipay .add-to-cart {
    width: 100%;
    margin: 0; } }

.salesforce-buynow-element {
  width: 100%; }

.salesforce-buynow-element:not(:empty) {
  margin-top: 1em; }

.salesforce-buynow-element-errors {
  margin-top: 0.75rem;
  text-align: left; }

.salesforce-buynow-element[disabled] {
  display: none; }

.cart-and-ipay .add-to-cart {
  width: 100%;
  display: block; }

@media (max-width: 543.98px) {
  .cart-and-ipay .add-to-cart {
    width: 100%;
    margin: 0; } }

.salesforce-buynow-element {
  width: 100%; }

.salesforce-buynow-element:not(:empty) {
  margin-top: 1em; }

.salesforce-buynow-element-errors {
  margin-top: 0.75rem;
  text-align: left; }

.salesforce-buynow-element[disabled] {
  display: none; }

.card.payment-method .card-header {
  border-bottom: 0; }

.card.payment-method .card-body {
  padding: 0; }

.salesforce-payments-element-errors {
  margin-bottom: 0.75rem; }

.salesforce-payments-errors:empty {
  display: none; }

.salesforce-payments-element .sfpp-payment-method-choice,
.salesforce-payments-element .sfpp-payment-method-choice .sfpp-payment-method-body,
.sfpp-payment-method-ideal-bank-element,
.sfpp-payment-method-iban-element {
  border-color: rgba(0, 0, 0, 0.125); }

.sfpp-payment-method-card-number-label::before,
.sfpp-payment-method-card-expiry-label::before,
.sfpp-payment-method-card-cvc-label::before,
.sfpp-payment-method-iban-label::before {
  content: "*";
  color: #a80b1d; }

.sfpp-payment-method-body-card .sfpp-payment-method-card-number-element,
.sfpp-payment-method-body-card .sfpp-payment-method-card-expiry-element,
.sfpp-payment-method-body-card .sfpp-payment-method-card-cvc-element,
.sfpp-payment-method-body-ideal .sfpp-payment-method-ideal-bank-element,
.sfpp-payment-method-body-sepa_debit .sfpp-payment-method-iban-element {
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 20px; }

.sfpp-payment-method-body .sfpp-payment-method-element--invalid {
  border-color: #a80b1d; }

.data-checkout-stage[data-checkout-stage] button.submit-billing {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.hidden {
  display: none !important; }

.data-checkout-stage[data-checkout-stage=payment] button.submit-billing {
  display: block; }

.data-checkout-stage .salesforce-paymentrequest-element {
  display: block; }

.data-checkout-stage[data-checkout-stage=customer] .salesforce-paymentrequest-element {
  display: none; }

.salesforce-paymentrequest-element:not(:empty) {
  margin-bottom: 1rem; }

.express-checkout-buttons {
  min-height: 2.75rem; }
  .express-checkout-buttons .salesforce-paymentrequest-element {
    border: 1px solid transparent;
    padding: 0 1.25rem; }
    .express-checkout-buttons .salesforce-paymentrequest-element:not(:empty) {
      margin: 0; }

.in-store-inventory-dialog .store-locator-container,
.pickup-in-store .store-locator-container {
  padding-left: 0;
  padding-right: 0; }
  .in-store-inventory-dialog .store-locator-container .store-result label::before,
  .in-store-inventory-dialog .store-locator-container .store-result label::after,
  .pickup-in-store .store-locator-container .store-result label::before,
  .pickup-in-store .store-locator-container .store-result label::after {
    margin-top: 2px;
    margin-left: 2px; }

.pickup-in-store {
  margin-bottom: 16px; }
  .pickup-in-store .select-store,
  .pickup-in-store ~ .change-store {
    margin-top: 16px; }
  .pickup-in-store .results-card .card-title {
    border-top: none; }

.cart-page .line-item-store {
  margin-top: 16px; }
  .cart-page .line-item-store .store-details {
    margin-left: 16px; }

/*
 * Based off of Yotpo CSS Widget Version: 2020-04-26_10-52-32
 */
.yotpo .main-widget a {
  color: #181818;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  color: #181818 !important; }
  .yotpo .main-widget a:hover {
    color: rgba(24, 24, 24, 0.8);
    text-decoration: underline; }
  .dark-theme .yotpo .main-widget a {
    color: #FFFFFF; }
    .dark-theme .yotpo .main-widget a:hover {
      color: #FFFFFF; }
  .yotpo .main-widget a:hover {
    color: rgba(24, 24, 24, 0.8) !important;
    text-decoration: underline !important; }

.yotpo .main-widget div, .yotpo .main-widget span, .yotpo .main-widget p, .yotpo .main-widget a, .yotpo .main-widget img, .yotpo .main-widget i, .yotpo .main-widget strong, .yotpo .main-widget sup, .yotpo .main-widget ul, .yotpo .main-widget li, .yotpo .main-widget form, .yotpo .main-widget label {
  letter-spacing: 0;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 1.43;
  color: #181818; }
  @media (min-width: 1024px) {
    .yotpo .main-widget div, .yotpo .main-widget span, .yotpo .main-widget p, .yotpo .main-widget a, .yotpo .main-widget img, .yotpo .main-widget i, .yotpo .main-widget strong, .yotpo .main-widget sup, .yotpo .main-widget ul, .yotpo .main-widget li, .yotpo .main-widget form, .yotpo .main-widget label {
      font-size: 16px; } }
  .yotpo .main-widget div:focus, .yotpo .main-widget span:focus, .yotpo .main-widget p:focus, .yotpo .main-widget a:focus, .yotpo .main-widget img:focus, .yotpo .main-widget i:focus, .yotpo .main-widget strong:focus, .yotpo .main-widget sup:focus, .yotpo .main-widget ul:focus, .yotpo .main-widget li:focus, .yotpo .main-widget form:focus, .yotpo .main-widget label:focus {
    outline: none; }

.yotpo .main-widget .yotpo-drop-down-layout .yotpo-dropdown-button .selected {
  display: inline; }

.yotpo .main-widget .yotpo-drop-down-layout .yotpo-dropdown-button .yotpo-icon {
  padding-top: 0; }

.yotpo .main-widget .yotpo-drop-down-layout .yotpo-dropdown .selected-item {
  color: #181818 !important; }

.yotpo .yotpo-icon {
  font-family: "icomoon" !important; }
  .yotpo .yotpo-icon:before {
    font-family: "icomoon" !important; }

.yotpo .rating-star, .yotpo .review-star {
  height: auto;
  line-height: normal;
  vertical-align: middle;
  font-size: 16px !important; }
  .yotpo .rating-star:before, .yotpo .review-star:before {
    font-family: "icomoon" !important; }
  .product-tile .yotpo .rating-star, .product-tile .yotpo .review-star {
    font-size: 11px !important; }

.yotpo .review-star {
  cursor: pointer; }

.yotpo .yotpo-icon-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: #181818;
  font-weight: normal; }

.yotpo .yotpo-icon-star[class*="btn-"]:not(.title) {
  padding-left: 42px; }
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #181818;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm, .yotpo .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .yotpo .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title).btn-lg, .yotpo .btn-group-lg > .yotpo-icon-star.btn[class*="btn-"]:not(.title) {
    padding-left: 63px; }
    .yotpo .yotpo-icon-star[class*="btn-"]:not(.title).btn-lg:before, .yotpo .btn-group-lg > .yotpo-icon-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo .yotpo-icon-half-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: #181818;
  font-weight: normal; }

.yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) {
  padding-left: 42px; }
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #181818;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm, .yotpo .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-lg, .yotpo .btn-group-lg > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title) {
    padding-left: 63px; }
    .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-lg:before, .yotpo .btn-group-lg > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo .yotpo-icon-empty-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: #181818;
  font-weight: normal; }

.yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) {
  padding-left: 42px; }
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #181818;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm, .yotpo .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title) {
    padding-left: 42px; }
    .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-lg, .yotpo .btn-group-lg > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title) {
    padding-left: 63px; }
    .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-lg:before, .yotpo .btn-group-lg > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #181818;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-default-button,
.yotpo input.yotpo-default-button.primary-color-btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  font-family: "Inter", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 32px;
  text-align: center;
  font-weight: 500;
  text-shadow: none;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 15px 8px 15px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  color: #FFFFFF;
  background-color: transparent;
  border: none;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  padding: 8px 15px 8px 15px;
  background-color: #181818 !important;
  border: 2px solid #181818 !important;
  height: auto; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:after,
  .yotpo input.yotpo-default-button.primary-color-btn:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 32px;
    z-index: -1;
    transition: all 200ms ease-out; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover:after, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:focus:after, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[aria-describedby*="popover"]:after,
  .yotpo input.yotpo-default-button.primary-color-btn:hover:after,
  .yotpo input.yotpo-default-button.primary-color-btn:focus:after,
  .yotpo input.yotpo-default-button.primary-color-btn[aria-describedby*="popover"]:after {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:after,
  .yotpo input.yotpo-default-button.primary-color-btn:after {
    background-color: #181818; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:focus, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[aria-describedby*="popover"],
  .yotpo input.yotpo-default-button.primary-color-btn:hover,
  .yotpo input.yotpo-default-button.primary-color-btn:focus,
  .yotpo input.yotpo-default-button.primary-color-btn[aria-describedby*="popover"] {
    color: #FFFFFF;
    text-decoration: none; }
    .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover:after, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:focus:after, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[aria-describedby*="popover"]:after,
    .yotpo input.yotpo-default-button.primary-color-btn:hover:after,
    .yotpo input.yotpo-default-button.primary-color-btn:focus:after,
    .yotpo input.yotpo-default-button.primary-color-btn[aria-describedby*="popover"]:after {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px;
      background-color: #5d5d5d; }
  .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button, .dark-theme
  .yotpo input.yotpo-default-button.primary-color-btn {
    color: #181818; }
    .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:after, .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn:after {
      background-color: #FFFFFF; }
    .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover, .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:focus, .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[aria-describedby*="popover"], .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn:hover, .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn:focus, .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn[aria-describedby*="popover"] {
      color: #181818; }
    .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button.disabled, .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:disabled, .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[disabled], .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn.disabled, .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn:disabled, .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn[disabled] {
      color: #FFFFFF; }
      .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button.disabled:after, .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:disabled:after, .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[disabled]:after, .dark-theme
      .yotpo input.yotpo-default-button.primary-color-btn.disabled:after, .dark-theme
      .yotpo input.yotpo-default-button.primary-color-btn:disabled:after, .dark-theme
      .yotpo input.yotpo-default-button.primary-color-btn[disabled]:after {
        background-color: #D4D4D4; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button.disabled, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:disabled, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[disabled],
  .yotpo input.yotpo-default-button.primary-color-btn.disabled,
  .yotpo input.yotpo-default-button.primary-color-btn:disabled,
  .yotpo input.yotpo-default-button.primary-color-btn[disabled] {
    color: #FFFFFF; }
    .yotpo.yotpo-main-widget .main-widget .yotpo-default-button.disabled:after, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:disabled:after, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[disabled]:after,
    .yotpo input.yotpo-default-button.primary-color-btn.disabled:after,
    .yotpo input.yotpo-default-button.primary-color-btn:disabled:after,
    .yotpo input.yotpo-default-button.primary-color-btn[disabled]:after {
      background-color: #D4D4D4; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class*="icon-"], .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class^="icon-"],
  .yotpo input.yotpo-default-button.primary-color-btn[class*="icon-"],
  .yotpo input.yotpo-default-button.primary-color-btn[class^="icon-"] {
    padding-left: 42px; }
    .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class*="icon-"]:before, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class^="icon-"]:before,
    .yotpo input.yotpo-default-button.primary-color-btn[class*="icon-"]:before,
    .yotpo input.yotpo-default-button.primary-color-btn[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover,
  .yotpo input.yotpo-default-button.primary-color-btn:hover {
    background-color: #5d5d5d !important;
    background: #5d5d5d !important;
    border: 2px solid #5d5d5d !important; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button .yotpo-icon::before, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button .write-question-review-button-text,
  .yotpo input.yotpo-default-button.primary-color-btn .yotpo-icon::before,
  .yotpo input.yotpo-default-button.primary-color-btn .write-question-review-button-text {
    color: #FFFFFF !important; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button .write-question-review-button-text,
  .yotpo input.yotpo-default-button.primary-color-btn .write-question-review-button-text {
    width: auto;
    height: auto;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit !important;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit; }

.yotpo .yotpo-icon-btn-small > span:before {
  color: #181818; }

.yotpo .yotpo-icon-btn-small:hover > span:before {
  color: rgba(24, 24, 24, 0.8); }

.yotpo.yotpo-small .yotpo-bottomline .yotpo-stars {
  display: flex;
  align-items: center; }

.yotpo.yotpo-small .yotpo-bottomline .write-review-btn-hidden {
  display: none; }

.yotpo.yotpo-small .yotpo-bottomline .text-m {
  font-size: 13px;
  margin-left: 5px;
  color: #595959 !important; }
  .yotpo.yotpo-small .yotpo-bottomline .text-m:hover {
    color: rgba(89, 89, 89, 0.8) !important; }

.product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m {
  display: none;
  font-size: 11px;
  color: #F9F9F9 !important; }
  .product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m:hover {
    color: #595959 !important; }

.yotpo.yotpo-main-widget .yotpo-label-container {
  text-align: right;
  border-top: 1px solid #D4D4D4; }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-link-new {
    display: inline-block; }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title,
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-btn {
    vertical-align: middle;
    color: #181818; }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title {
    letter-spacing: 0;
    font-size: 16px; }
    @media (min-width: 1024px) {
      .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title {
        font-size: 16px; } }
    .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title:hover {
      color: rgba(24, 24, 24, 0.8); }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-btn {
    font-size: 24px; }

.yotpo.yotpo-main-widget .yotpo-bottomline {
  text-align: center; }
  .yotpo.yotpo-main-widget .yotpo-bottomline .bottom-line-items {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
  .yotpo.yotpo-main-widget .yotpo-bottomline .avg-score {
    font-family: "Space Grotesk", sans-serif;
    font-size: 24px;
    line-height: 36px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    width: 100%;
    display: block;
    padding-top: 0;
    float: none;
    height: auto;
    color: #181818; }
  .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container {
    float: none;
    height: auto;
    width: auto;
    line-height: normal;
    vertical-align: top;
    margin-left: 0;
    padding-left: 5px;
    white-space: nowrap; }
    @media (min-width: 769px) {
      .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container {
        padding-right: 5px; } }
    .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container .rating-star {
      height: auto;
      float: none; }
      .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container .rating-star::before {
        font-size: 20px; }
        @media (min-width: 769px) {
          .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container .rating-star::before {
            font-size: 20px; } }
  .yotpo.yotpo-main-widget .yotpo-bottomline .reviews-qa-labels-container {
    float: none;
    display: inline-block;
    padding-top: 0;
    margin-left: 0;
    position: static;
    height: 28px;
    line-height: 28px;
    vertical-align: middle; }
    .yotpo.yotpo-main-widget .yotpo-bottomline .reviews-qa-labels-container .reviews-qa-label {
      vertical-align: middle; }
  @media (min-width: 769px) {
    .yotpo.yotpo-main-widget .yotpo-bottomline .bottom-line-items {
      text-align: center;
      margin-bottom: 10px; } }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-regular-box {
  border-top: 0; }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-header-title {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal; }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-mandatory-explain {
  display: none;
  font-size: 14px;
  color: #a80b1d; }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-mandatory-mark {
  display: none; }

.yotpo.yotpo-main-widget .main-widget form .write-form br {
  display: none; }

.yotpo.yotpo-main-widget .main-widget form .write-form label.y-label, .yotpo.yotpo-main-widget .main-widget form .write-form span.y-label {
  padding: 0;
  color: #181818;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 2px;
  text-transform: none;
  letter-spacing: 0; }
  .yotpo.yotpo-main-widget .main-widget form .write-form label.y-label .form-input-error, .yotpo.yotpo-main-widget .main-widget form .write-form span.y-label .form-input-error {
    color: #a80b1d;
    font-size: 14px; }

.yotpo.yotpo-main-widget .main-widget form .write-form .y-input {
  margin-top: 0;
  margin-bottom: 5px;
  display: block;
  border-color: #D4D4D4;
  background-color: #FFFFFF;
  outline: none;
  box-shadow: none;
  color: #595959;
  border-width: 1px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
  padding-top: 16px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  height: calc(16px + 16px + 14px + (1px*2));
  outline: 0; }
  @media (min-width: 769px) {
    .yotpo.yotpo-main-widget .main-widget form .write-form .y-input {
      font-size: 16px; } }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input::placeholder {
    color: #595959; }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input:hover {
    border-color: #D4D4D4;
    outline: none;
    box-shadow: 0 0 0 1px #D4D4D4; }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input:focus {
    border-color: #D4D4D4;
    outline: none;
    box-shadow: none; }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input.error {
    border-color: #a80b1d; }

.yotpo.yotpo-main-widget .main-widget form .write-form .connect-wrapper {
  padding-left: 0; }

.yotpo.yotpo-main-widget .main-widget form .write-form .submit-button .form-input-error {
  color: #a80b1d;
  font-size: 14px; }

.yotpo.yotpo-main-widget .main-widget form .socialize-wrapper {
  position: static; }
  .yotpo.yotpo-main-widget .main-widget form .socialize-wrapper .socialize {
    display: block;
    float: none; }
    .yotpo.yotpo-main-widget .main-widget form .socialize-wrapper .socialize .yotpo-icon-btn {
      padding: 6px 11px !important; }
      .yotpo.yotpo-main-widget .main-widget form .socialize-wrapper .socialize .yotpo-icon-btn[data-network="linkedin"] {
        display: none; }

.yotpo.yotpo-main-widget .main-widget form .yotpo-or {
  display: block;
  margin-top: 0;
  width: auto;
  margin-bottom: 5px;
  text-align: left; }

.yotpo.yotpo-main-widget .yotpo-messages .yotpo-thank-you .yotpo-thankyou-header span {
  color: #181818; }

.yotpo.yotpo-main-widget .new-yotpo-small-box {
  border: none; }
  .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav {
    border-bottom: none; }
    .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      width: 100% !important; }
      .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab {
        font: 14px "Inter", sans-serif;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-top: 15px;
        padding-right: 16px;
        padding-bottom: 11px;
        padding-left: 16px;
        text-decoration: none;
        background: transparent;
        color: #595959;
        border-top: none;
        border-right: none;
        border-bottom: 1px solid #F9F9F9;
        border-left: none;
        margin: 0;
        flex: 1 1 auto; }
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab:hover {
          text-decoration: none;
          background: transparent;
          color: #181818;
          border-top: none;
          border-right: none;
          border-bottom: 1px solid #F9F9F9;
          border-left: none; }
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
          text-decoration: none;
          background: transparent;
          color: #181818;
          border-top: 1px solid #F9F9F9;
          border-right: 1px solid #F9F9F9;
          border-bottom: none;
          border-left: 1px solid #F9F9F9; }
      @media (min-width: 769px) {
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul {
          width: 100%;
          display: block;
          border-bottom: 1px solid #F9F9F9; }
          .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab {
            font: 14px "Inter", sans-serif;
            text-transform: none;
            font-weight: bold;
            letter-spacing: 0.05em;
            padding-top: 15px;
            padding-right: 16px;
            padding-bottom: 11px;
            padding-left: 16px;
            text-decoration: none;
            background: transparent;
            color: #595959;
            border-top: none;
            border-right: none;
            border-bottom: 1px solid #F9F9F9;
            border-left: none;
            margin: 0;
            margin-bottom: -1px; }
            .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab:hover {
              text-decoration: none;
              background: transparent;
              color: #181818;
              border-top: none;
              border-right: none;
              border-bottom: 1px solid #F9F9F9;
              border-left: none; }
            .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
              text-decoration: none;
              background: transparent;
              color: #181818;
              border-top: 1px solid #F9F9F9;
              border-right: 1px solid #F9F9F9;
              border-bottom: none;
              border-left: 1px solid #F9F9F9; }
            .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
              background-color: #FFFFFF; } }
      .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
        background-color: #FFFFFF !important; }
      .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab .yotpo-nav-wrapper {
        border: none;
        padding: 0;
        color: unset;
        max-width: none;
        text-align: center; }
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab .yotpo-nav-wrapper span {
          color: unset; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input {
  border-color: #D4D4D4;
  background-color: #FFFFFF;
  outline: none;
  box-shadow: none;
  color: #595959;
  border-width: 1px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
  padding-top: 16px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  height: calc(16px + 16px + 14px + (1px*2));
  outline: 0;
  padding-left: 35px;
  margin: 0; }
  @media (min-width: 769px) {
    .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input {
      font-size: 16px; } }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input::placeholder {
    color: #595959; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input:hover {
    border-color: #D4D4D4;
    outline: none;
    box-shadow: 0 0 0 1px #D4D4D4; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input:focus {
    border-color: #D4D4D4;
    outline: none;
    box-shadow: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .clear-text-icon,
.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .magnifying-glass-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #181818 !important; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .filters-dropdown[data-analytic-label="images"] {
  display: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header .yotpo-icon-profile {
  background: #595959; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header .yotpo-icon-profile .yotpo-user-letter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header .yotpo-user-name {
  color: #181818; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header.yotpo-verified-user .yotpo-icon-circle-checkmark {
  color: #01DF9D; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header.yotpo-store-owner .yotpo-icon-circle-checkmark {
  color: #ACE7FA; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-main .yotpo-review-wrapper .yotpo-read-more {
  color: #181818; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-main .yotpo-review-wrapper .yotpo-read-more:hover {
    color: rgba(24, 24, 24, 0.8); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-footer .yotpo-action > span {
  color: #181818; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-footer .yotpo-action:hover > span {
  color: rgba(24, 24, 24, 0.8); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-footer .yotpo-icon-seperator:before {
  color: transparent; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-comments-box {
  border-left: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-questions {
  padding-top: 20px; }

.yotpo .color-blue {
  background: #3091E7; }

.yotpo .color-red {
  background: #a80b1d; }

.yotpo .color-yellow {
  background: #FFCC00; }

.yotpo .color-green {
  background: #2eab2b; }

.yotpo .color-gray {
  background: #BEBEBE; }

.yotpo .color-gray-light {
  background: #F9F9F9; }

.yotpo .color-gray-dark {
  background: #595959; }

.yotpo .color-primary {
  background: #181818; }

.yotpo .color-primary-d1 {
  background: black; }

.yotpo .color-primary-d2 {
  background: black; }

.yotpo .color-primary-l1 {
  background: #4b4b4b; }

.yotpo .color-primary-l2 {
  background: #7e7e7e; }

.yotpo .color-primary-l3 {
  background: #b1b1b1; }

.yotpo .color-primary-l4 {
  background: #e4e4e4; }

.yotpo .font-color-blue {
  color: #3091E7; }

.yotpo .font-color-yellow {
  color: #FFCC00; }

.yotpo .font-color-green {
  color: #2eab2b; }

.yotpo .font-color-gray {
  color: #BEBEBE; }

.yotpo .font-color-gray-light {
  color: #F9F9F9; }

.yotpo .font-color-gray-dark {
  color: #595959; }

.yotpo .font-color-primary {
  color: #181818; }

.yotpo .font-color-primary-d1 {
  color: black; }

.yotpo .font-color-primary-d2 {
  color: black; }

.yotpo .font-color-primary-l1 {
  color: #4b4b4b; }

.yotpo .font-color-primary-l2 {
  color: #7e7e7e; }

.yotpo .font-color-primary-l3 {
  color: #b1b1b1; }

.yotpo .font-color-primary-l4 {
  color: #e4e4e4; }

button.dw-apple-pay-button.applePaySpecificityOverride {
  width: 100%;
  display: block;
  padding: 8px 15px 8px 15px;
  margin-bottom: 16px;
  border-radius: 50px; }

.account-image {
  background-image: url(../../images/account.jpg);
  background-position-y: 40%; }

#sp-my-subscriptions {
  min-height: 30em; }

#sp-my-subscriptions .sp-field.custom-select select, #sp-my-subscriptions select.sp-field.form-control select, #sp-my-subscriptions select.sp-field select {
  border: none; }

.delivery-interval-group #delivery-periods {
  max-width: 50px; }

.trackingpage img.track_logo {
  height: 25px; }

.paymentmethodwarning {
  font-weight: bold;
  color: #a80b1d; }

/*
 * Based off of Yotpo CSS Widget Version: 2020-04-26_10-52-32
 */
.yotpo.yotpo-main-widget .yotpo-thank-you .yotpo-thankyou-footer .social-link {
  padding: 0 !important; }

.yotpo.yotpo-main-widget .yotpo-thank-you .yotpo-thankyou-footer .yotpo-default-button {
  padding: 6px 11px !important; }

.product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m {
  display: block;
  font-size: 11px;
  font-weight: 700;
  color: #181818 !important; }
  .product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m:hover {
    color: #181818 !important; }
  .product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m::before {
    content: "(";
    padding-right: 1px; }
  .product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m::after {
    content: ")";
    padding-left: 1px; }
