@import "~core/components/common";

.page {
    background-color: $page-background-color;
    overflow: hidden;
    &[data-action="Cart-Show"] {
        padding-bottom: 100px;
    }
    @include media-breakpoint-up(md) {
        overflow: visible; // no longer need to support ie11
        &[data-action="Cart-Show"] {
            padding-bottom: 0;
        }
    }
}

.error-page {
    h2 {
        font-size: $headline-s-font-size;
        line-height: $headline-s-line-height;
    }

    .error.continue-shopping {
        display: inline-block;
        width: auto;
    }

    .row, .col-sm-6 {
        text-align: center;
    }
}

.accountpage {
    overflow: hidden;
    &-header {
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $spacer*3;
        background-image: url('../../images/img-banner.jpg');
        background-size: cover;
        background-position: 50%;
        position: relative;
        &::after {
            @include absolute-cover;
            background-color: rgba($color: black, $alpha: 0.1);
            content: " ";
        }
        @include media-breakpoint-down(sm) {
            margin-left: $spacer;
            margin-right: $spacer;
        }
    }
    &-title {
        margin: 0;
        color: $white;
        max-width: 400px;
        z-index: 1;
    }
    .card-body {
        padding: 0 $spacer;
    }
    .form-group  {
        label {
            @include typography(13);
            margin-bottom: $spacer/2;
            display: inline-block;
            position: relative;
            width: max-content;
            &::before {
                position: absolute;
                right: -9px;
            }
        }
        &.custom-checkbox {
            label {
                width: auto;
            }
        }
        .custom-checkbox {
            label {
                width: auto;
            }
        }
    }
    .btn-save {
        max-width: 205px;
        margin: 0 auto;
    }
    .hero {
        .hero-container {
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
    }
    .description {
        padding-bottom: $spacer*4;
        @include media-breakpoint-up(md) {
            padding-bottom: $spacer*6;
        }
        p {
            margin: 0;
        }
    }
    .offer-free-recycle {
        font-weight: normal;
    }

}

.order-discount,
.shipping-discount {
    .col-8 {
        p {
            color: $primary-black;
            span {
                color: $primary-black;
            }
        }
    }
}
.shipping-discount-total,
.order-discount-total {
    color: $red;
}

.privacy-policy {
    overflow: hidden;
    padding: 40px 80px;
    h1 {
        text-align: center;
        margin-bottom: $spacer*3;
    }
    h2 {
        font-size: 15px;
    }
}

/* Chrome, Safari, Edge, Opera */
/* Firefox */
input {
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

input[type=number] {
	-moz-appearance: textfield;
}

.international {
    .btn-primary,
    .btn-secondary {
        width: 100%;
        height: 40px;
        opacity: 1;

        @include media-breakpoint-up (lg) {
            width: auto;
            min-width: 147px;
        }
    }

    .btn-secondary {
        color: $primary-black;
    }

    .flag-wrapper {
        @include media-breakpoint-up (lg) {
            margin: 0 auto;
            max-width: 550px;
        }
    }
}

.smarty-form-group {
    position: relative;

    .smarty-suggestions {
        position: absolute;
        left: 0;
        right: 0;
        background: white;
        padding: 0;
        z-index: 4;

        .suggestion-item {
            cursor: pointer;
            padding: 5px 15px;
            border-bottom: 1px solid $new-grey;
            font-size: 12px;
            line-height: 20px;
            color: $dark-gray;
            @include icon($icon-location, before);

            &::before {
                font-size: 13px;
                padding-right: 2px;
                vertical-align: middle;
            }

            &:hover {
                background: $seashell;
            }
        }
    }
}

#addressValidationModal {
    .modal-footer {
        @include media-breakpoint-down (md) {
            flex-wrap: wrap;

            buton {
                margin: 0 0 10px;
            }
        }
    }
}
