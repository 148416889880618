@import "~core/bootstrapOverrides";

// updates container padding based on $grid-gutter-widths map
@mixin update-container-padding($gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding-right: ($gutter);
      padding-left:  ($gutter);
    }
  }
}
.container {
  @include update-container-padding;
}
.container-fluid {
  @include update-container-padding;
}