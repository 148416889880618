@import "~core/components/productTiles";

.product-tile {
    position: relative;
    .color-swatches {
        margin-bottom: $product-tile-swatch-margin-bottom;
        font-size: 0;
        .swatches {
            @include clearfix;
        }
        .swatch-link {
            display: block;
            float: left;
            text-decoration: none;
            &:first-child .swatch-circle {
                margin-left: $color-swatch-hover-shadow-size;
            }
        }
        .swatch-circle {
            @include swatch($product-tile-swatch-size, $white);
            margin-right: $product-tile-swatch-spacing;
            margin-bottom: $product-tile-swatch-spacing;
            &.selected {
                box-shadow: $color-swatch-selected-shadow;
            }
        }
        .swatch-thumbnail {
            @include swatch($product-tile-swatch-thumbnail-size, $white, 'thumbnail');
            margin-right: $product-tile-swatch-thumbnail-spacing;
            margin-bottom: $product-tile-swatch-thumbnail-spacing;
            &.selected {
                box-shadow: $color-swatch-thumbnail-selected-shadow;
            }
        }
        .swatch-color-count {
            font-size: $default-text-font-size;
            line-height: $product-tile-swatch-size;
            letter-spacing: $default-text-letter-spacing;
            margin-left: $spacer-sm;
            white-space: nowrap;
            display: inline-block;
            vertical-align: middle;
            color: $product-tile-swatch-color-count-color;
            @include media-breakpoint-up(lg) {
                position: absolute;
                right: 0;
                top: 0;
                transform: translateY(180%);
                line-height: $default-text-line-height;
            }
        }
    }
    .product-tile-image-container{
        padding: $product-tile-padding;
        background-color: $product-tile-background-color;
        border-radius: $category-tile-border-radius;
        &.full-bleed-image {
            padding: calc(100% + (#{$product-tile-padding-top-bottom} * 2) - (#{$product-tile-padding-right-left} * 2)) 0 0;
            img {
                top: 0;
                left: 0;
                height: 100%;
                object-fit: cover;
                position: absolute;
                border-radius: $border-radius;
            }
            @include media-breakpoint-up(md) {
                &:hover {
                    .product-tile-image {
                        display: block;
                    }
                }
            }
        }
        .tile-image {
            border-radius: $spacer*0.5;
        }
    }
    .pdp-link {
        @include media-breakpoint-up(md) {
            padding: 0 $spacer/2;
        }
        .charity-link,
        .charity-link:hover,
        .charity-link:visited,
        .charity-link:active {
            color: $charity-font-color;
            font-size: $charity-font-size;
            text-decoration: none;
        }
    }
    
    .link {
        font-family: $primary-font;
    }

    &--buy-container {
        padding: 0 $spacer/2;
    }

    .yotpo {
        padding: 0 $spacer/2;
    }
  
    .price {
        @include typography(10);
        del,
        .strike-through {
            color: $product-tile-price-color;
        }
    }
    .badge-product-container {
        &.badge-image-over {
            top: $spacer/2;
            left: $spacer*0.875;
            @include media-breakpoint-down(sm) {
                top: $spacer*0.3125;
            }
        }
    }

    .show-secondary-image {
        @include media-breakpoint-up(md) {
            &:hover {
                .product-tile-image-secondary {
                    border-radius: $spacer*0.5;
                }
            }
        }
    }

    .quick-add-to-cart {
        position: absolute;
        left: $spacer*0.5;
        bottom: $spacer*0.5;
        margin: 0 !important;
        width: 100%;
        padding: 0;
        max-width: 30px;
        height: 30px;

        .row {
            position: static;
        }

        .btn {
            max-width: 30px;
            height: 30px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            font-size: 15px;
            font-weight: bold;
            color: $light-gray;

            &:hover,
            &:active,
            &:focus {
                &::after {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }

            &:active,
            &:focus {
                &::after {
                    background-color: $primary-black;
                }
            }

            &.btn-primary-outline {
                @include media-breakpoint-up (lg) {
                    max-width: 30px;
                    height: 30px;
                }
            }

            &.full-width {
                @include media-breakpoint-up (lg) {
                    max-width: 30px;
                    height: 30px;
                }
            }

            .diagonal-line {
                position: absolute;
                top: 15px;
                left: 2px;
                width: 24px;
                border-top: 2px solid #a80b1d;
                transform: rotate(135deg);
                transform-origin: center;
            }
        }
    }

    .addOverlay {
        opacity: 0.5;
    }

    .wishlist:not(.move) {
        top: auto;
        bottom: $spacer/2;
        right: $spacer/2;

        @include media-breakpoint-up(sm) {
            top: auto;
        }

        .wishlist-icon {
            background-color: $primary-black;
            color: $light-gray;
            border-radius: 100%;
            font-size: 15px;

            &:hover {
                background-color: $primary-black-hover;
            }

            &::before {
                color: $light-gray;
                font-size: 14px;
            }

            &.selected {
                &::before {
                    color: $light-gray;
                }
            }
        }
    }

    &.whiteBg-blackColor {
        .wishlist:not(.move) {
            .wishlist-icon {
                background-color: $white;
                color: $primary-black;

                &:hover {
                    background-color: $wild-sand;
                }

                &::before {
                    color: $primary-black;
                }

                &.selected {
                    &::before {
                        color: $primary-black;
                    }
                }
            }
        }

        .btn {
            color: $primary-black;

            &::after {
                background-color: $white;
            }

            &:hover,
            &:active,
            &:focus {
                &::after {
                    background-color: $wild-sand;
                }
            }

            &:active,
            &:focus {
                &::after {
                    background-color: $white;
                }
            }
        }
    }

    &.noBg-blackColor {
        .wishlist:not(.move) {
            .wishlist-icon {
                background-color: transparent;
                color: $primary-black;

                &:hover {
                    background-color: transparent;
                }

                &::before {
                    color: $primary-black;
                }

                &.selected {
                    &::before {
                        color: $primary-black;
                    }
                }
            }
        }

        .btn {
            color: $primary-black;

            &::after {
                background-color: transparent;
            }

            &:hover,
            &:active,
            &:focus {
                &::after {
                    background-color: transparent;
                }
            }

            &:active,
            &:focus {
                &::after {
                    background-color: transparent;
                }
            }
        }
    }
}

.slider-product-tile {
    .product-tile {
        .product-tile-image-container {
            @include media-breakpoint-up (lg) {
                &.show-secondary-image {
                    min-height: 394px;
                }
            } 
        }
    }

    &.mb-n6,
    &[data-slider-items-lg="6"] {
        .product-tile {
            .product-tile-image-container {
                @include media-breakpoint-up (lg) {
                    &.show-secondary-image {
                        min-height: 277px;
                    }
                } 
            }
        } 
    }
}

.tile-image-wrap {
    position: relative;
    margin-bottom: 10px;
}

.btn-addon-to-cart {
    display: none;
}

.product-addons {
    overflow: hidden;
    width: 100%;
    margin-bottom: $spacer;
    &.collapsible-content-group{
        margin-top: -1px;
    }
    .promotion, .promotions, .ratings, .wishlist {
        display: none;
    }
    .btn-addon-to-cart {
        display: block;
        font-family: $button-add-to-cart-font;
        text-transform: $button-add-to-cart-text-transform;
        color: $button-add-to-cart-color;
        background: transparent;
        border: none;
        text-decoration: $button-add-to-cart-text-decoration;
        text-align: $button-add-to-cart-text-align;
        font-weight: $button-add-to-cart-font-weight;
        font-size: $button-lg-font-size;
        max-width: 126px;
        margin-left: auto;
        padding: $spacer/2 $spacer-sm*2;
        line-height: $button-lg-line-height;
        letter-spacing: $button-lg-letter-spacing;
        @include media-breakpoint-up (md) {
            padding: $spacer/2 $spacer;
        }
    }
    .items {
        border: 1px solid $medium-gray;
        border-radius: $border-radius;
        background: $light-gray;
        padding: $pdp-attribute-vertical-spacing;
        @include media-breakpoint-down(sm) {
            padding:16px;
        }
        .add-on-text {
            width: 253px;
            height: $spacer * 1.5;
            top: $spacer * 1.5;
            left: $spacer * 1.5;
            gap: 0;
            font-family: $primary-font;
            font-size: $paragraph-font-size-medium;
            font-weight: $nav-link-font-weight-active;
            line-height: $paragraph-lg-line-height-medium;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
        .add-on-save {
            width: 157px;
            height: 19px;
            top: $spacer * 3.75;
            left: $spacer * 1.5;
            gap: 0;
            font-family: $primary-font;
            font-size: $spacer;
            font-weight: 400;
            line-height: $radiocheck-label-line-height;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
    }
    .product {
        display: flex;
        align-items: center;
        &:last-child {
            .product-tile::before {
                border-bottom: 0;
            }
        }
    }
    .product-tile   {
        margin-bottom: 0;
        padding: $spacer*1.5 $spacer*0.0625;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width:100%;
        img {
            width:100%;

            @include media-breakpoint-up (lg) {
                width: 110px;
            }
        }
        .price {
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include typography(10);
        }
        &> div {
            flex-basis: 44%;

            @include media-breakpoint-up (lg) {
                flex-basis: 40%;
            }

            &.tile-image-wrap {
                flex-basis: 18%;

                @include media-breakpoint-up (lg) {
                    flex-basis: 23%;
                }
            }

            .pdp-link {
                padding: 0 $spacer*0.1875;
                @include media-breakpoint-up (lg) {
                    padding: 0 $spacer*0.25 0 $spacer;
                }
            }
        }
        &> a {
            &.main-link {
                flex-basis: 18%;

                @include media-breakpoint-up (lg) {
                    flex-basis: 23%;
                }
            }
        }
        &> div.product-tile--buy-container {
            text-align: right;
            flex-basis: 38%;
            padding: 0;

            @include media-breakpoint-up (lg) {
                flex-basis: 37%;
            }

            .buy-with-prime-badge {
                padding: 0 !important;
            }
        }
        &-image-container {
            margin: 0;
            padding: 0;
            background-color: transparent;
        }

        &.addon-product-tile-emea {
            &> div {
                flex-basis: 55%;
    
                @include media-breakpoint-up (lg) {
                    flex-basis: 40%;
                }
    
                &.tile-image-wrap {
                    flex-basis: 18%;
    
                    @include media-breakpoint-up (lg) {
                        flex-basis: 23%;
                    }
                }
            }
            &> div.product-tile--buy-container {
                text-align: right;
                flex-basis: 60%;
                padding: 0;

                @include media-breakpoint-up (sm) {
                    flex-basis: 30%;
                }

                @include media-breakpoint-up (md) {
                    flex-basis: 32%;
                }
    
                @include media-breakpoint-up (lg) {
                    flex-basis: 37%;
                }

                .btn-addon-to-cart {
                    padding: $spacer/2 $spacer;
                    max-width: 145px;

                    @include media-breakpoint-up (md) {
                        max-width: inherit;
                    }
                }
            }
        }

        &.customisable-add-on-product-pdp {
            &> div {
                flex-basis: 32%;
    
                @include media-breakpoint-up (lg) {
                    flex-basis: 40%;
                }
    
                &.tile-image-wrap {
                    flex-basis: 18%;
    
                    @include media-breakpoint-up (lg) {
                        flex-basis: 23%;
                    }
                }
            }

            &> div.product-tile--buy-container {
                text-align: right;
                flex-basis: 50%;

                @include media-breakpoint-up (lg) {
                    flex-basis: 37%;
                }

                .btn-customize {
                    padding: $spacer/2 $spacer;
                }
            }
        }
    }

    .non-input-label {
        @include typography(10);
        padding: $spacer 0 $spacer*2;
    }

    .product-detail & .non-input-label {
        @include typography(10);
        padding: $spacer*2 0 $spacer;
    }

    .cart-page & {
        margin-bottom: $spacer;
    }
}
.product-addons {
    .product-tile {
        .price {
            del ~ .sales {
                @include media-breakpoint-down(lg) {
                    float: none;
                    margin-right: 0;
                    display: block;
                }
            }
        }
    }
}

.grid-tile-add-on {
    .product-tile {
        .product-tile-image-container {
            @include media-breakpoint-down (sd) {
                img {
                    width: $spacer * 6;
                }
            }
            @include media-breakpoint-down (lg) {
                img {
                    width: $spacer * 5;
                }
            }
        }
        
        .color-swatches {
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: auto;
            scrollbar-width: none;
            display: block;
            max-width: 100%;
            cursor: grab;
        }

        .color-swatches::-webkit-scrollbar {
            display: none;
        }

        .swatches {
            display: flex;
            flex-wrap: nowrap;
            gap: $spacer/2;
            width: max-content;
            .pdp-add-on-swatch-span {
                &:first-child {
                    margin-left: 0!important;
                }
            }
        }

        .pdp-add-on-swatch-span {
            width: $spacer * 2.5;
            height: $spacer * 2.5;
            display: inline-block;
            background-size: cover;
            background-position: center;
            flex-shrink: 0;
        }
    }
}
