.blogs-grid {
    .tab-section.new-category-tabs {
        padding: 0;
        .nav {
            padding: 0;
        }
    }

    .blog-card {
        img {
            display: block;
            overflow: hidden;
            width: 100%;
            height: auto;
            border-radius: 24px;
            margin-bottom: $spacer;
        }

        h5 {
            @include typography(8);
        }

        .card-text {
            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 3em;
            }
        }
    }
}