.bundle-items {
    .primary-images {
        .overlaid {
            .overlay-image {
                display: none;
            }
            .base-image + .overlay-image {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:nth-child(1) .base-image + .overlay-image {
                transform: translateY(-14%) rotateX(48deg);
            }
            &:nth-child(2) .base-image + .overlay-image {
                transform: none;
            }
            &:nth-child(4) .base-image + .overlay-image,
            &:nth-child(6) .base-image + .overlay-image {
                transform: translateX(4.1%) rotateY(46deg)
                    scale(0.30);
            }
            &:nth-child(5) .base-image + .overlay-image,
            &:nth-child(7) .base-image + .overlay-image {
                transform: translateX(9%) rotateY(46deg)
                    scale(0.30);
            }

            &.poptivism {
                &:nth-child(1) .base-image + .overlay-image {
                    transform: translateY(-14%) rotateX(48deg) scale(1.05);
                }
                &:nth-child(4) .base-image + .overlay-image,
                &:nth-child(6) .base-image + .overlay-image {
                    transform: translateX(4.1%) rotateY(46deg)
                        scale(0.305);
                }
                &:nth-child(5) .base-image + .overlay-image,
                &:nth-child(7) .base-image + .overlay-image {
                    transform: translateX(9%) rotateY(46deg)
                        scale(0.305);
                }
            }
        }
        .primary-images-main {
            .overlaid {
                &:nth-child(3) .base-image + .overlay-image {
                    transform: translateY(-1%) rotateX(43deg) scale(1.02);
                }

                &.poptivism {
                    &:nth-child(3) .base-image + .overlay-image {
                        transform: translateY(-1%) rotateX(43deg) scale(1.07);
                    }   
                }
            }
        }
        .primary-images-thumbnails {
            .overlaid {
                .base-image + .overlay-image {
                    top: $spacer/2;
                }
            }
            .overlaid:nth-child(1) .base-image + .overlay-image {
                transform: translateY(-11%) rotateX(48deg) scale(0.85);
            }
            .overlaid:nth-child(3) .base-image + .overlay-image {
                transform: rotateX(45deg) scale(0.82);
            }
        }
        @include media-breakpoint-down(md) {
            margin-bottom: $spacer*1.5;
        }

        &.magsafe-grips-slider-images {
            .overlaid {
                .overlay-image {
                    display: none;
                }
                .base-image + .overlay-image {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &:nth-child(1) .base-image + .overlay-image {
                    transform: translateY(0%) rotateX(0deg) scale(0.71);
                }
                &:nth-child(2) .base-image + .overlay-image {
                    transform: translateX(17.1%) rotateY(48deg) scale(0.8);
                }
                &:nth-child(3) .base-image + .overlay-image {
                    transform: translateY(1%) translateX(-0.1%) rotate(0deg) scale(0.275);
                }
                &:nth-child(4) .base-image + .overlay-image {
                    transform: translateX(5.1%) translateY(1%) rotateY(40deg) scale(0.257);
                }
                &:nth-child(6) .base-image + .overlay-image {
                    transform: translateX(33.1%) translateY(3%) rotateY(50deg) scale(0.3);
                }
                &:nth-child(5) .base-image + .overlay-image,
                &:nth-child(7) .base-image + .overlay-image {
                    transform: translateX(9%) rotateY(46deg)
                        scale(0.30);
                }
            }
            .primary-images-thumbnails {
                .overlaid {
                    .base-image + .overlay-image {
                        top: $spacer/2;
                    }
                }
                .overlaid:nth-child(1) .base-image + .overlay-image {
                    transform: translateY(0%) rotateX(0deg) scale(0.56);
                }
                .overlaid:nth-child(3) .base-image + .overlay-image {
                    transform: translateY(1%) translateX(-0.1%) rotate(0deg) scale(0.21);
                }
            }
        }
    }
    
    .attributes {       
        .bundle-swatches {
            margin-bottom: $spacer*2;
        } 

        .bundle-swatch-accordioncolor.row,
        .bundle-swatch-platformcolor.row {
            margin-bottom: 0;
            .attribute-option {
                display: flex;
                align-items: flex-start;
            }
            .grip-part-svg {
                padding-right: $spacer * 1.5; 
            }
        }

        .bundle-swatch-gripsetup {
            margin-bottom: $spacer * 2;
        }

        .bundle-swatch-accordioncolor.row {
            .attribute-option {
                border: none;
                margin-bottom: 0;
                padding-right: 0;
                position: relative;

                @include media-breakpoint-up (lg) {
                    padding-right: $spacer*2;
                    position: static;
                }

                &:last-child {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        .bundle-swatch-platformcolor.row {
            .attribute-option {
                border: none;
                border-top: 1px solid $medium-gray;
                padding-right: 0;
                position: relative;

                @include media-breakpoint-up (lg) {
                    border-top: none;
                    padding-top: 0;
                    padding-right: $spacer*2;
                    position: static;
                }

                &:first-child {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }

        .bundle-swatch-magsafecolor.row {
            .attribute-option {
                border: none;
                position: relative;
                padding-left: 0;

                @include media-breakpoint-up (lg) {
                    position: static;
                }

                .swatches-holder {
                    margin-left: 0;
                    .swatch {
                        &:first-child {
                            .swatch-circle {
                                margin-left: 0
                            }
                        }
                    }
                }
            }
        }

        .color-swatches {
            .swatches {
                @include clearfix;
                position: relative;

                .name {
                    display: none;
                    position: absolute;
                    top: $spacer*-0.75;
                    left: 0;
                    text-align: left;
                    width: calc(100% - #{$spacer});
                }

                .selected {
                    .name {
                        color: $brand-primary;
                        @include typography(11);
                    }
                }
            }
        }
        .swatch {
            + .bundle-swatch-gripsetup {
                margin-top: 32px;
            } 
        }
    }

    .quantity-select{
        display: none; // needs to be controlled by a product attribute in the future
    }
}

.product-detail {
    .product-bundle {
        &.byo {
            padding-top: $spacer*2;
            @include media-breakpoint-up(md) {
                padding-top: $spacer*4;
            }
            .details-popover {
                text-align: right;
                margin-top: -$spacer;
                padding-bottom: $spacer/2;
                @include media-breakpoint-up(md) {
                    margin-top: -$spacer*1.5;                    
                }
                .popover {
                    top: auto;
                    left: auto;
                    right: $spacer;
                    margin: 0;
                    z-index: 20;
                    width: auto;
                    bottom: 50px;
                    padding: 20px;
                    display: none;
                    max-width: 350px;
                    @include media-breakpoint-up(md) {
                        left: 50%;
                        right: auto;
                        bottom: 50px;
                        width: 450px;
                        padding: 30px;
                        max-width: 450px;
                        margin-left: -70px;
                    }
                    &:after,
                    &:before {
                        left: auto;
                        right: 30px;
                        content: '';
                        bottom: -10px;
                        position: absolute;
                        border-width: 10px 10px 0;
                        border-style: solid;
                        @include media-breakpoint-up(md) {
                            left: 50%;
                            right: auto;
                            margin-left: -3px;
                        }
                    }
                    &:after {
                        border-color: $white transparent;
                    }
                    &:before {
                        bottom: -11px;
                        border-color: $bullet-border-color transparent;
                    }
                }
                .btn-detail {
                    margin: 0 $spacer;
                    position: relative;
                    padding-left: 20px;
                    i {
                        top: 5px;
                        left: 0;
                        font-size: 12px;
                        font-weight: bold;
                        position: absolute;
                    }
                }
                .popover-close {
                    top: 15px;
                    right: 15px;
                    font-size: 12px;
                    font-weight: bold;
                    position: absolute;
                    text-decoration: none;

                    @include media-breakpoint-up(lg) {
                        top: 30px;
                        right: 15px;
                    }
                }
            }



            .bundle-footer {
                padding: $spacer $spacer $spacer*2;
                @include media-breakpoint-up(md) {
                    padding: $spacer*2 0;
                }
                .notify-submit-btn {
                    top: 16px;
                    height: 48px;
                }
            }
            .heading-container {
                &.no-subtitles {
                    margin-bottom: $spacer*2;
                    @include media-breakpoint-up (lg) {
                        margin-bottom: $spacer*3;
                    }

                    .product-name {
                        margin-bottom: $spacer*0.5;
                    }
                }
                p {
                    margin: 0;
                }
            }
            .product-name {
                @include typography(3);
                margin-bottom: $spacer*1.5;
                text-align: center;
                @include media-breakpoint-up(md) {
                    margin-bottom: $spacer*2;
                }
            }
            .attribute label {
                @include typography(10);
            }
            .bundle-items{
                &-label {
                    margin: $spacer*4 0 $spacer*3;
                    @include media-breakpoint-down(md) {
                        margin: $spacer*2 0;
                    }
                }
                &.ootb-bundle {
                    .primary-images {
                        > .row {
                            margin: 0;
                        }
                        .primary-images-main {
                            display: flex;
                            min-height: 370px;
                            align-items: center;
                            position: relative;
                            @include media-breakpoint-up(md) {
                                min-height: 432px;
                            }
                            .overlay-image {
                                z-index: 1;
                                top: 0;
                                left: 0;
                                position: absolute;
                                border-radius: 50%;
                                transform: translateY(-15%) scale(0.45);
                            }
                            &.product-has-thumbnails {
                                padding-bottom: 0;
                                margin-bottom: $spacer*1.5;
                                @include media-breakpoint-up(md) {
                                    margin-bottom: $spacer*2;
                                }
                            }
                            margin-bottom: $spacer*1.5;
                            @include media-breakpoint-up(md) {
                                margin-bottom: $spacer*2;
                            }
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        gap: 56px;
                    }
                    .bundle-item {
                        position: relative;
                        &:first-child {
                            padding-bottom: 0;

                            .primary-images {
                                &::after {
                                    @include media-breakpoint-up(md) {
                                        display: none;
                                    } 
                                }
                            }
                        }

                        &:last-child {
                            &::after {
                                @include media-breakpoint-down(sm) {
                                    display: none;
                                } 
                            }
                            .details-popover {
                                .popover {
                                    @include media-breakpoint-up(lg) {
                                        left: auto;
                                        right: 0;

                                        &::after, 
                                        &::before {
                                            left: auto;
                                            right: 50px;
                                        }
                                    }
                                }
                            }
                        }

                        .primary-images {
                            position: relative;
                            overflow: visible;
                            @include media-breakpoint-down (md) {
                                padding: 0;
                            }

                            &:after {
                                top: 50%;
                                left: -15px;
                                width: 26px;
                                height: 26px;
                                content: '';
                                bottom: auto;
                                display: none;
                                margin-top: -30px;
                                position: absolute;
                                background-image: url('../../images/icon-plus.svg');
                                background-size: 100%;
                                @include media-breakpoint-up(md) {
                                    display: block;
                                }
                                @include media-breakpoint-up(lg) {
                                    left: -41px;
                                }
                                
                            }
                        }
                        &:after {
                            width: 26px;
                            height: 26px;
                            content: '';
                            bottom: 40px;
                            display: block;
                            margin: $spacer/2 auto $spacer*2;
                            background-image: url('../../images/icon-plus.svg');
                            background-size: 100%;
                            @include media-breakpoint-up(md) {
                                top: 320px;
                                bottom: auto;
                                left: -41px;
                                display: none;
                                margin-right: 0;
                                margin-top: -13px;
                                position: absolute
                            }
                        }
                    }
                    .product-name {
                        @include typography(8);
                        margin-bottom: $spacer;
                        @include media-breakpoint-up(md) {
                            @include typography(19);
                            margin-bottom: $spacer*2;
                        }
                    }
                }
            }
            .attributes {
                display: flex;
                flex-direction: column;
                @include media-breakpoint-up(md) {
                    display: block;
                }
                .pop-top-design {
                    .attribute-option {
                        border-top: 0;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                    }
                }
                .attribute-option {
                    &.add {
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                    }
                    &.byo-swatches {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
                .row {
                    &.swatch {
                        margin-bottom: $spacer*1.5;
                        @include media-breakpoint-up(md) {
                            margin-bottom: $spacer*2;
                        }

                        .color-swatches {
                            .color-label {
                                @include media-breakpoint-down (md) {
                                    margin-top: $spacer*0.75;
                                }
                            }
                        }
                    }
                    &.static-quantity {
                        margin: 0;
                        @include media-breakpoint-up(md) {
                            margin-bottom: $spacer*1.5;
                        }
                    }
                }
            }
            .color-swatches {
                margin: 0;
            }
            .bundle-footer {
                .prices-add-to-cart-actions {
                    padding: 0;
                    position: relative;
                    transition: all 0.1s linear;
                    @include media-breakpoint-up(md) {
                        padding: 0;
                        position: static
                    }
                    .price {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        > span {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            del {
                                span {
                                    display: inline;
                                }
                                .sales {
                                    margin: 0;
                                }
                            }
                        }
                    }
                    h6 {
                        @include typography(12);
                        margin-bottom: $spacer;
                    }
                    .ootb-bundle {
                        width: 84px;
                        padding: 0;
                        background: none;
                        margin: 0 3px $spacer;
                        &.selected {
                            position: relative;
                            .primary-images {
                                .primary-images-main {
                                    &:after {
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        content: '';
                                        position: absolute;
                                        border-radius: 8px;
                                        pointer-events: none;
                                        border: 2px solid $primary-black;
                                    }
                                }
                            }
                        }
                        .overlay-image {
                            z-index: 1;
                            top: 0;
                            left: 0;
                            position: absolute;
                            border-radius: 50%;
                            transform: translateY(-15%) scale(0.45);
                        }
                        .primary-images {
                            padding: 0;
                            overflow: hidden;
                            > .row {
                                margin: 0;
                            }
                            &:after {
                                display: none;
                            }
                            .primary-images-main {
                                margin: 0;
                                display: flex;
                                height: 84px;
                                width: 84px;
                                border-radius: 8px;
                                background: $light-gray;
                                align-items: center;
                                .slider-container.scroll-slider-active {
                                    .slide {
                                        flex: 0 0 100%;
                                        padding: 0;
                                        img {
                                            height: auto;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .small-tiles {
                            text-align: left;
                            padding: $spacer/2 0 0;
                            p {
                                margin: 0;
                                @include typography(14);
                            }
                        }
                    }
                    .thumbnails-container {
                        position: relative;
                        transition: all 0.1s linear;
                    }
                    .thumbnails {
                        overflow: hidden;
                    }
                }
            }
        }
        .thumbnail-img {
            border-radius: 15px;
            background-color: $pdp-image-gallery-background-color;
        }
        .quantity {
            display: none;
        }
        .badge-product-container {
            &.badge-image-over {
                display: none;
            }
        }

        .bundle-footer {
            background: $footer-background;
            bottom: 20%;
            width: 100%;
            padding: $spacer*2 0;
            text-align: center;
            box-shadow: 0 0 6px rgba(0,0,0,0.1);
            @include media-breakpoint-up(md) {
                padding: $spacer*1.75 0;
            }
            .btn-add-to-cart {
                display: flex;
                margin: 0 auto;
                min-width: inherit;
                width: auto;
                padding: $spacer;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                overflow: hidden;
                border-radius: 50px;
                color: $white;
                @include media-breakpoint-up(lg) {
                    min-width: 364px;
                    padding: $spacer $spacer*2;
                }

                &:after {
                    background-color: $primary-black;
                }

                &.disabled,
                &:disabled,
                &[disabled] {
                    color: $button-disabled-color;
                    &:after {
                        background-color: $button-disabled-background;
                    }
                }
                
                .price {
                    display: block;
                    margin-left: $spacer/2.5;
                    &:before {
                        content: '-';
                        font-weight: bold;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    span {
                        display: inline-block;
                        vertical-align: middle;
                        color: $white;
                    }
                    del ~ .sales {
                        margin-right: $spacer/2.5;
                        span {
                            color: $success-green;
                        }
                    }
                }
            }
            .notify-me {
                @include media-breakpoint-up(lg) {
                    max-width: 364px;
                    padding: $spacer $spacer*2;
                }
            }
        }
        .attributes {
            .row {
                margin-bottom: $spacer*1.5;
            }
        }
        .btn-add-to-cart { 
            .price {
                display: none;
            }
        }
        .bundle-items-label {
            font-weight: $headline-l-font-weight;
            margin: $spacer*4 0 $spacer*3;
            @include media-breakpoint-down(md) {
                margin: $spacer*3 0;
            }
        }

        .prices-add-to-cart-actions {
            .ootb-bundle {
                width: 64px;
                display: inline-block;
                vertical-align: top;
                margin: 0 3px $spacer/2;
                background: $non-color-swatch-background;
                border-radius: $non-color-swatch-border-radius;
                .primary-images {
                    .primary-images-main {
                        .slider-container.scroll-slider-active {
                            .slide {
                                flex: 0 0 100%;
                                padding: 0;
                                img {
                                    height: auto;
                                    width: 100%;
                                }
                            }
                        }
                        &.product-has-thumbnails {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            h2 {
                margin: 0 0 $spacer/2;
            }
        }

        .ootb-bundle {
            display: none;
            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            &.bundle-items {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                @include media-breakpoint-up(md) {
                    flex-wrap: nowrap;
                }
            }


            .primary-images {
                overflow: hidden;
                @include media-breakpoint-down(md) {
                    margin: 0;
                }
                .primary-images-main {
                    &.product-has-thumbnails {
                        margin: 0 0 $spacer*1.25;
                        @include media-breakpoint-down(md) {
                            overflow: hidden;
                            padding: 0;
                        }
                    }
                }
            }
            .product-name {
                font-size: $default-text-font-size;
                line-height: $default-text-line-height;
                font-family: $product-tile-title-font;
                text-transform: none;
                min-height: 42px;
                padding: 0 $spacer/2;
                @include media-breakpoint-up(sm) {
                    padding: 0;
                }
            }
            .attributes {
                padding: 0 $spacer/2;
                @include media-breakpoint-up(sm) {
                    padding: 0;
                }
            }
            .product-number-rating {
                display: none;
            }
            .attributes {
                .product-availability {
                    display: none;
                }
            }
            .non-color-attribute-swatches {
                .swatch-tile-container {
                    .swatch .swatch-value {
                        font-family: $body-copy-l-font-family;
                    }
                }
            }
            .bundle-item {
                padding: 0;
                flex-basis: 100%;
                @include media-breakpoint-up(md) {
                    flex-basis: 50%;
                    padding: 0 $spacer 0;
                }
                @include media-breakpoint-up(lg) {
                    flex-basis: 33%;
                }
            }
        }
        .non-color-attribute-swatches {
            .swatch-tile-container {
                overflow: hidden;
            }
        }

        .bundle-footer {
            left: 0;
            top: auto;
            bottom: 0;
            width: 100%;
            z-index: 99;
            visibility: visible;
            position: fixed;
            transition: all 0.1s linear;
        }
    }
}

.hide-oos-variants {
    .swatch-tile-container {
        &.unselectable {
            display: none;
        }
    }

    .color-swatches {
        .unselectable-btn {
            display: none;
        }
    }
}

.product-bundle {
    .bundle-footer {
        left: 0;
        top: auto;
        bottom: 0;
        width: 100%;
        z-index: 99;
        opacity: 1;
        position: fixed;
    }
}

.js-bundle {
    .product-detail-attributes {
        > .cart-and-ipay {
            .add-to-cart-global {
                .price {
                    display: none !important;
                }
            }
        }
    }
}

.scroll-direction-up {
    .product-detail {
        .product-bundle.byo {
            .prices-add-to-cart-actions {
                padding-top: 0;
                transition: all 0.1s linear;
                .thumbnails-container {
                    width: 0;
                    height: 0;
                    overflow: hidden;
                    transition: all 0.1s linear;
                    transform: translateY(100%);
                }
            }
        }
    }
}

body.byo-pdp {
    padding-bottom: 200px;
    @include media-breakpoint-up(md) {
        padding: 0;
    }
}
