.breadcrumb .breadcrumb-item h1 {
    text-transform: capitalize;
}

@include media-breakpoint-down(sm) {
    .breadcrumb.more li.breadcrumb-item {
        display: none;
    }

    .breadcrumb.more li.breadcrumb-item:first-child + .breadcrumb-item:before {
        display: none;
    }

    .breadcrumb.more li.breadcrumb-item:nth-last-child(-n+3) {
        display: flex;
    }
}