.find-us-wrapper {
    background: linear-gradient(
        to bottom,
        $cornflower-blue 0%,
        $cornflower-blue 34%,
        $light-gray 34%,
        $light-gray 100%
    );
    padding: $spacer*2 0 $spacer;
    margin-top: $spacer*4;
    

    @include media-breakpoint-up (md) {
        background: linear-gradient(
        to right,
        $cornflower-blue 0%,
        $cornflower-blue 50%,
        $light-gray 50%,
        $light-gray 100%
        );
        padding: $spacer*2 0;
    }

    .region {
        text-align: center;

        @include media-breakpoint-up (lg) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &:first-child {
            flex-wrap: nowrap;

            .experience-commerce_assets-html {
                flex: 0 0 auto;

                .download-text {
                    @include typography (12);
                    font-family: $primary-font !important;
                    color: $white;

                    br {
                        display: none;
                    }

                    @include media-breakpoint-up (lg) {
                        @include typography (4);

                        br {
                            display: block;
                        }
                    }

                    a {
                        font-family: $primary-font !important;
                        color: $white;
                        text-decoration: none;
                        font-weight: inherit;
                    }
                }
    
                .loyalist-text {
                    @include typography (11);
                    color: $white;
                    margin-bottom: 0;
                }
            }

            .experience-commerce_assets-photoTile {
                flex: 0 0 auto;
                margin-bottom: 0;

                .find-us-popsockets {
                    height: 47px;
                    width: 47px;
                    margin-bottom: $spacer*0.875;

                    @include media-breakpoint-up (lg) {
                        height: 77px;
                        width: 77px;
                        margin-right: $spacer*3.125;
                        margin-bottom: 0;
                    }
                }

                .app-store {
                    width: 160px;
                    height: auto;
                    @include media-breakpoint-up (lg) {
                        margin-left: $spacer*2;
                    }
                }
            }
        }

        &:last-child {
            @include media-breakpoint-down (lg) {
                padding: $spacer*2 0 $spacer*0.125;
                margin-top: 0 !important;
            }

            .experience-commerce_assets-photoTile {
                @include media-breakpoint-down (lg) {
                    margin-bottom: 40px;
                }

                &:last-child {
                    @include media-breakpoint-down (lg) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .experience-commerce_assets-html {
            flex: 0 0 100%;

            .find-us-heaidng {
                @include typography (4);
                font-family: $primary-font;
                @include media-breakpoint-up (lg) {
                    font-family: $primary-font;
                }
            }
        }

        .experience-commerce_assets-photoTile {
            flex: 0 0 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto !important;

            .find-us-target {
                width: 134px;
                height: 75px;

                @include media-breakpoint-up (lg) {
                    width: 104px;
                }

                img {
                    height: 75px !important;
                    width: auto !important;
                }
            }

            .find-us-walmart {
                width: 210px;
                height: 75px;

                @include media-breakpoint-up (lg) {
                    width: 155px;
                    height: 45px;
                }
            }

            .find-best-buy {
                width: 180px;
                height: 60px;

                @include media-breakpoint-up (lg) {
                    width: 155px;
                    height: 45px;
                }
            }

            .find-us-amazon {
                width: 134px;
                height: 40px;

                @include media-breakpoint-up (lg) {
                    width: 104px;
                    height: 20px;
                }
            }
        }
    }
}