@import "~core/components/footer";

footer {
    padding-top: 0;
    margin-top: $spacer*4;
    .content li {
        padding-bottom: $spacer;
    }

    ul {
        padding-top: $spacer;
    }
    .title {
        margin: 0;
        @include typography(12);
        @include media-breakpoint-up(md) {
            @include typography(8);
        }
    }
    .footer-container {
        .col-md-2 {
            @include media-breakpoint-up(md) {
                flex: 0 0 19.4444367%; // footer designs dont line up with grid so need custom percentages
                max-width: 19.4444367%; // removes 1 column and spreads that 8.33333% between the 3 remaining nav columns 16.66667% + (8.33333/3) = 19.4444367%
            }
        }
    }
    .email-signup-form {
        text-align: center;
        h4 {
            margin-bottom: $spacer;
        }
        .email-disclaimer {
            margin-top: $spacer / 2;
            p {
                margin-bottom: 0;
            }
        }
        .btn:after {
            width: auto;
            height: auto;
            position: relative;
            top: initial;
            left: initial;
            border-radius: 0;
            z-index: initial;
            transition: none;
        }
        @include media-breakpoint-up(md) {
            text-align: left;
        }
        .nav-tabs {
            padding-bottom: 26px;
        }

        .nav-tabs .nav-item:first-child {
            .nav-link {
                border: none;
                border-radius: 4px 0 0 4px;
            }
        }

        .nav-tabs .nav-item:last-child {
            .nav-link {
                border: none;
                border-radius: 0 4px 4px 0;
            }
        }

        .stay-in-the-know-text::after {
            content: '🔥';
            font-size: 24px;
            padding-left: 5px;
        }

        .input-field-text-group,
        .input-field-email-group {
            button:focus-visible {
                outline-width: 0;
            }
        }

        .stay-in-the-know-footer-tabs {
            .nav-link {
                background-color: $light-gray;

                &.active {
                    background-color: $cobalt;
                    color: $white;
                }
            }
        }
    }
    .social {
        text-align: center;

        .social-links {
            padding-top: $spacer;
        }
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
    .footer-apps {
        text-align: left;
        margin-top: $spacer * 3;
        margin-bottom: $spacer * 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        @include media-breakpoint-up(md) {
            margin-top: $spacer * 4;
            margin-bottom: $spacer * 4;
            padding: $spacer * 2;
            text-align: center;
            justify-content: center;
            background-color: $light-gray;
            border-radius: 6px;
        }
        .we-have-an-app {
            font-family: $primary-font;
            margin: 0 $spacer * 0.5 0 0;
            width: 100%;
            flex-shrink: 0;
            text-transform: none;
            font-weight: bold;
            font-size: $paragraph-font-size-small;
            line-height: $paragraph-line-height-small;
            @include media-breakpoint-up(md) {
                margin: 0 $spacer * 0.5;
                width: auto;
            }
        }
        .create-and-share {
            font-size: $paragraph-font-size-small;
            line-height: $paragraph-line-height-small;
            @include media-breakpoint-up(md) {
                order: 1;
                flex-shrink: 0;
                width: 100%;
                margin-top: $spacer * 0.5;
            }
        }
        .footer-download-buttons {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            font-size: 0;
            @include media-breakpoint-up(sm) {
                flex-wrap: nowrap;
            }
            @include media-breakpoint-up(md) {
                display: inline;
                width: auto;
                font-size: $primary-font;
            }
            a {
                width: auto;
                margin: $spacer $spacer 0 0;
                &:nth-child(2) {
                    display: none; // temporary hide android while app is being built
                }
                @include media-breakpoint-up(md) {
                    margin: 0 $spacer * 0.5;
                    background: transparent;
                    color: $brand-primary;
                    border-radius: 0;
                    border: none;
                    padding: 0;
                    width: auto;
                    text-decoration: underline;
                    font-size: 14px;
                    img {
                        display: none;
                    }
                }
            }
        }
    }

    .copyright,
    .postscript {
        font-size: $footer-copyright-font-size;
        line-height: $footer-copyright-line-height;
    }
}

.back-to-top-btn {
    position: fixed;
    right: $spacer;
    bottom: $spacer*2.8125;
    background-color: $primary-black;
    border-radius: 50%;
    border-color: $primary-black;
    cursor: pointer;
    z-index: 100;
    height: $spacer*3;
    width: $spacer*3;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;

    @include media-breakpoint-up (lg) {
        height: $spacer*4.5;
        width: $spacer*4.5;
        right: $spacer*2.8125;
        bottom: $spacer*5.625;
    }

    &:focus {
        outline: 0;
    }

    svg {
        width: $spacer*1.25;

        @include media-breakpoint-up (lg) {
            width: $spacer*1.875;
        }
    }
}

.loading-skeleton {
    .loading-part {
        height: 400px;
        background-color: $alto;
        overflow: hidden;
        text-indent: -99999px;
        border-radius: 20px;
        background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, .5) 50%,
            rgba(255, 255, 255, 0) 60%
        ) $alto;
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1s loading ease-in-out infinite;

        @include media-breakpoint-up(md) {
            height: 500px;
        }
    }
}

.footer-item {
    &.collapsible-sm {
        .title::after {
            top: 20px;
        }
    }
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}

.rally-loader .rally-spinner {
    border: 3px solid $primary-black;
    border-top-color: $tangerine;
    margin-top: 3px;
}
