@import "~core/components/header";

.header {
    .brand .logo-home {
        &:focus {
            outline: 2px solid $outline-color;
        }
    }
    .wishlist-icon .icon-favorite {
        &:focus {
            outline: 2px solid $outline-color;
        }
    }
    @include media-breakpoint-up(lg) {
        .user:hover {
            .dropdown-menu {
                display: block;
            }
        }
    }
    .header-column-3 {
        &>.pull-right {
            margin-right: $spacer * 2;
   		    @include media-breakpoint-up(lg) {
                &.pr-4 {
                    padding-right: 0 !important;
                }
            }
        }
    }

    .brand {
        .logo-home {
            position: relative;
            &:focus {
                outline: 2px solid $outline-color;
            }
            &.selected:focus {
                outline: none;
            }
        }
    }
    
    .multilevel-dropdown {
        .navbar-nav {
            @include media-breakpoint-up(lg) {
                justify-content: center;
            }
        }
    }
    #mobile-search-toggle {
        padding-left: 8px;
        font-weight: 500;
        font-size: 20px;
    }
}
body.sub-category-open {
    //PAP-718 - remove display none for .header
    .page {
        overflow: hidden;
    }
}

body.nav-overlay {
    .modal-background {
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.header-banner {
    z-index: 999;
    position: relative;
    font-family: $header-promo-bar-font-family;
    font-size: $paragraph-font-size-small;
    line-height: $header-promo-bar-line-height;
    font-weight: $font-weight-medium;
    color: $header-promo-bar-color;
    padding: 0;
    @include media-breakpoint-down(md) {
        .header-promotion {
            height: 36px;
            display: flex;
            overflow: hidden;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 12px !important;
    }

    b {
        font-weight: $font-weight-medium;
    }

    a {
        color: $header-promo-bar-color;
        font-weight: $font-weight-medium;
    }
    .header-banner-column-2 {
        .close-button {
            z-index: 1;
            .close {
                text-shadow: none;
            }
        }
    }

    .tns-nav-controller {
        &:not(.with-pagination) {
            padding: 0;
            height: 24px;
        }
    }
    
    .container {
        max-width: 100%;
    }

    .mobile-1r-1c {
        padding : $header-promo-bar-padding;
        .container {
            @include media-breakpoint-up(lg) {
                max-width: 1440px;
            }
        }
    }
    .blue {
        background-color: $header-promo-blue-background;
    }
    .green {
        background-color: $header-promo-green-background;
    }
    .yellow {
        background-color:  $header-promo-yellow-background;

    }
    .red {
        background-color: $header-promo-red-background;
    }
    .brand-primary {
        a {
            color: $brand-primary;
        }
        strong {
            color: $brand-primary;
        }
        .header-promotion {
            color: $brand-primary;
        }
    }
    .white {
        a {
            color: $white;
        }
        strong {
            color: $white;
        }
        .header-promotion {
            color: $white;
        }
    }
    .light-gray {
        a {
            color: $light-gray;
        }
        strong {
            color: $light-gray;
        }
        .header-promotion {
            color: $light-gray;
        }
    }
    .medium-gray {
        a {
            color: $medium-gray;
        }
        strong {
            color:  $medium-gray;
        }
        .header-promotion {
            color:  $medium-gray;
        }
    }
    .dark-gray {
        a {
            color: $dark-gray;
        }
        strong {
            color: $dark-gray;
        }
        .header-promotion {
            color: $dark-gray;
        }
      }
}

.header{
    padding: $header-padding;
    height: $header-height;
    @include media-breakpoint-up(md) {
        height: $header-height-md;
        padding: $header-desktop-padding;
    }
    @include media-breakpoint-up(lg) {
        height: $header-height-lg;
        .user {
            .dropdown-menu {
                padding: 0;
            }
            .nav {
                padding: $spacer 0 0;
            }
        }
    }
    .fa-search {
        width: $header-search-width;
        height: $header-search-height;
        background: url(#{$header-search-icon-svg}) no-repeat center;
        background-size: contain;
        @include media-breakpoint-up(md) {
            width: $header-search-width-md;
            height: $header-search-height-md;
        }
    }
    .header-locale-container .country-selector {
        display: none;
    }
    .header-support-container .support {
        display: none;
    }
    .wishlist-icon {
        .icon-favorite {
            display: block;
            text-decoration: none;
            &:focus {
                outline: 2px solid $outline-color;
            }
            &:before {
                font-size: $button-icon-size;
                display: inline-block;
                vertical-align: middle;
            }
            &.selected:focus {
                outline: none;
            }
        }
    }

    .minicart {
        .minicart-total {
            position: relative;
            margin-right: $spacer;
        }
        .minicart-link {
            display: inline-block;
            text-decoration: none;
            vertical-align: top;
            &:focus,
            &:active {
                outline: 2px solid $outline-color;
            }
            &.selected:focus {
                outline: none;
            }

            .minicart-icon {
                display: block;

                &:before {
                    display: block;
                }
            }
        }
    }

    .navbar-toggler {
        border-radius: 0;
        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }
}

.header-nav {
    //PAP-647
    z-index: 999;
    .logo-center & {
        border-color: $border-color;
    }

    .flag-icon {
        cursor: pointer;
    }
}

.main-menu{
    .nav-link{
        font-family: $primary-font;
    }
}
.main-menu {
    .navbar {
        .close-menu button,
        .close-button button {
            padding: $spacer 0;
            color: $white;
            span.icon.icon-arrow-left-thin {
                color: $link-color;
            }
        }
    }
}

.fixed-header-enhanced {
    .sticky-header & {
        .header-nav {
            box-shadow: none;
            border-bottom: $header-navbar-border-bottom-width solid $header-navbar-border-bottom-color;
        } 
    }

    &.new-minicart-border {
        .header {
            padding: $spacer*1.125 0;
    
            @include media-breakpoint-up (md) {
                padding: $spacer*1.125 0;
            }
        }
    
        .header-nav {
            border-bottom: 2px solid $medium-gray;
        }
    }
}

// Account
.header-account-container {
    .user.custom-dropdown {
        .dropdown-toggle {
            padding: 0 0 0 0;
            &:focus,
            &:active {
                outline: 2px solid $outline-color;
            }
            &:after{
                display: none;
            }
            &.selected:focus {
                outline: none;
            }
        }
    }
}

//transparent nav
html:not(.sticky-header) {
    .transparent-header {
        .new-header {
            background-color: $white;
            position: relative;
        }
        .header-nav {
            transition: background-color $transition-speed linear, border-color $transition-speed linear;

            &.transparent-nav {
                &.transparent-nav-light {
                    &:not(.fixed) {
                        &.new-header {
                            @include media-breakpoint-up(lg) {
                                position: relative;
                            }
                        }
                        &.header-with-search {
                            @include media-breakpoint-down(md) {
                                background: $white;
                                position: relative;
                            }

                            .brand a:focus,
                            .fa-search:focus,
                            .wishlist-icon .icon-favorite:focus,
                            .minicart .minicart-link:focus,
                            .search-field:focus,
                            .header-link.nav-link:focus,
                            .nav-link:focus {
                                @include media-breakpoint-down(md) {
                                    outline: 2px solid $white;
                                }
                            }
                            .minicart {
                                .minicart-link {
                                    @include media-breakpoint-down(md) {
                                        text-decoration: none;

                                        .minicart-icon {
                                            display: block;
                                            color: $header-transparent-text-color-dark;

                                            &:before {
                                                display: block;
                                                color: $header-transparent-text-color-dark;
                                            }
                                        }
                                    }
                                }
                            }
                            .navbar-toggler:before,
                            .wishlist-icon .icon-favorite:before{
                                @include media-breakpoint-down(md) {
                                    color: $header-transparent-text-color-dark;
                                }
                            }
                            .brand a,
                            .fa-search {
                                @include media-breakpoint-down(md) {
                                    filter: brightness($header-search-icon-svg-brightness);
                                }
                            }                        
                            .brand a:focus,
                            .fa-search:focus,
                            .wishlist-icon .icon-favorite:focus,
                            .minicart .minicart-link:focus,
                            .search-field:focus,
                            .header-link.nav-link:focus,
                            .nav-link:focus {
                                @include media-breakpoint-down(md) {
                                    outline: 2px solid $white;
                                }
                            }
                        }
                        .minicart {
                            .minicart-link {
                                text-decoration: none;

                                .minicart-icon {
                                    display: block;

                                    &:before {
                                        display: block;
                                    }
                                }
                            }
                        }
                        .wishlist-icon .icon-favorite:before{
                            color: $header-transparent-text-color-light;
                        }

                        .fa-search {
                            filter: brightness($header-search-icon-svg-brightness) invert($header-search-icon-svg-invert);
                        }                        
                        .brand a:focus,
                        .fa-search:focus,
                        .wishlist-icon .icon-favorite:focus,
                        .minicart .minicart-link:focus,
                        .search-field:focus,
                        .header-link.nav-link:focus,
                        .nav-link:focus {
                            outline: 2px solid $outline-color;
                        }

                        .brand a:focus,
                        .fa-search:focus,
                        .wishlist-icon .icon-favorite:focus,
                        .minicart .minicart-link:focus,
                        .search-field:focus,
                        .header-link.nav-link:focus,
                        .nav-link:focus {
                            outline: 2px solid $outline-color;
                        }

                        .navbar {
                            .menu-group {
                                .nav.navbar-nav {
                                     > .nav-item:not(:hover).highlight {
                                        .nav-link {
                                            color: $header-link-highlight-color;
                        
                                            &:hover {
                                                color: $header-link-highlight-color-hover;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                                     
                    }
                }
                &.transparent-nav-dark {
                    &:not(.fixed) {
                        &.header-with-search {
                            @include media-breakpoint-down(md) {
                                background: $white;
                                position: relative;
                            }
                        }
                        .brand a:focus,
                        .fa-search:focus,
                        .wishlist-icon .icon-favorite:focus,
                        .minicart .minicart-link:focus,
                        .search-field:focus,
                        .header-link.nav-link:focus,
                        .nav-link:focus {
                            outline: 2px solid $white;
                        }
                        .minicart {
                            .minicart-link {
                                text-decoration: none;

                                .minicart-icon {
                                    display: block;

                                    &:before {
                                        display: block;
                                    }
                                }
                            }
                        }
                        .wishlist-icon .icon-favorite:before{
                            color: $header-transparent-text-color-dark;
                        }
                        .fa-search {
                            filter: brightness($header-search-icon-svg-brightness);
                        }                        
                        .brand a:focus,
                        .fa-search:focus,
                        .wishlist-icon .icon-favorite:focus,
                        .minicart .minicart-link:focus,
                        .search-field:focus,
                        .header-link.nav-link:focus,
                        .nav-link:focus {
                            outline: 2px solid $white;
                        }
                    }
                }

                &:not(.fixed) {
                    &.new-header {
                        background-color: $white;
                        position: relative;
                    }
                }
            }

            &:not(.fixed) {
                &.new-header {
                    background-color: $white;
                    position: relative;
                }
            }

            &.transparent-nav.transparent-nav-light:not(.fixed) {
                #search-mobile:before {
                    color: $header-transparent-text-color-light;
                }
                .minicart .minicart-quantity {
                    background: $header-transparent-text-color-light;
                    color: $header-transparent-text-color-dark;
                }
            }
       }
    }
}

.logo-center {
    @include media-breakpoint-down(md) {
        .header.container {
            padding: 0 $spacer;
        }

        .header-with-search {
            .header.container {
                padding: 0 $spacer $spacer;
            }
            .header.containers{
                padding: 0 $spacer $spacer;
            } 
        }

        .header-column-1 {
            order: 3; // menu
            justify-content: flex-end;
            flex-grow: 0;
            margin-right: $spacer*-1;
            box-sizing: border-box;
        }

        .header-column-2 {
            order: 1; // logo
            justify-content: flex-start;
            flex-grow: 1;
            max-width: 95%;
        }

        .header-column-3 {
            order: 2; // cart
            justify-content: flex-end;
            flex-grow: 0;
            //Begin PAP-778
            position: static;
            @include media-breakpoint-up(md) {
                position: relative;
            }
            //end PAP-778
            >.pull-right {
                margin-right: $spacer;
            }
            .minicart .minicart-total {
                margin-right: 0;
            }
            //PAP-647
            #search-mobile {
                top: -1px;
                margin: 0 $spacer;
                position: relative;
            }
        }
    }
}

#header-secondary-nav-mobile {
    .region {
        padding-top: $spacer/2;
        padding-bottom: $spacer/2;
        display: flex;
        justify-content: space-between;

        .button {
            > a.btn-tertiary {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .container {
        padding: 0;
    }
}

header {
    &.logo-center {
        .new-header {
            .header-column-2 {
                min-width: inherit;
            }
        }
    }
    .new-header {
        padding: 0 $spacer;
        @include media-breakpoint-up(lg) {
            position: relative;
            padding: 0;
            padding-block-end: 1.5%;
        }
        .header {
            height: auto;
            @include media-breakpoint-up(md) {
                padding: 0;
            }
            @include media-breakpoint-up(lg) {
                padding: $spacer;
                height: 82px;
                .header-column-2 {
                    justify-content: center;
                    .brand
                    {
                        padding-left: 15%;
                    }
                }
            }

            @include media-breakpoint-up(sd) {
                padding: $spacer*2;
                height: 112px;
            }

            > .row {
                @include media-breakpoint-down(md) {
                    height: 56px;
                }
            }
        }
        .header-column-2 {
            min-width: inherit;
        }

        .main-menu {
            .container {
                @include media-breakpoint-up(lg) {
                    padding: 0;
                }
            }
        }
        .navbar.bg-inverse {
            .navbar-nav {
                .nav-link {
                    color: inherit;
                    @include media-breakpoint-up(lg) {
                        padding: $spacer/2.5;
                    }
                    @include media-breakpoint-up(sd) {
                        padding: $spacer/1.5;
                    }
                    @include media-breakpoint-up(xl) {
                        padding: $spacer;
                    }
                }
            }

            .nav-item.highlight {
                .nav-link {
                    color: $header-link-highlight-color;
                        
                    &:hover {
                        color: $header-link-highlight-color-hover;
                    }
                }
            }
        }

        .header-column-2 {
            @include media-breakpoint-up(lg) {
                position: static;
                mask-image: none;
                flex-grow: 2;
            }
            .multilevel-dropdown {
                .dropdown-menu {
                    @include media-breakpoint-up(lg) {
                        top: 75%;
                    }
                }
            }
        }

        .brand {
            .logo-home {
                @include media-breakpoint-up(lg) {
                    width: 152px;
                }
                @include media-breakpoint-up(sd) {
                    width: 182px;
                }
                @include media-breakpoint-up(xl) {
                    width: 212px;
                }
            }
        }

        .header-column-1 {
            mask-image: none;
            order: 1;
            @include media-breakpoint-up(lg) {
                max-width: 20%;
                flex-grow: 0.6;
                order: 0;
            }

            @include media-breakpoint-up(xl) {
                flex-grow: 1;
            }
        }

        .multilevel-dropdown {
            .dropdown-menu {
                @include media-breakpoint-up(lg) {
                    top: 120%;
                }
            }
        }

        .header-column-3 {
            @include media-breakpoint-up(lg) {
                flex-grow: 1;
            }
            > .pull-right {
                @include media-breakpoint-up(lg) {
                    margin-right: $spacer/2;
                }

                @include media-breakpoint-up(sd) {
                    margin-right: $spacer;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .navbar-toggler {
                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }
            }

            .search-icon-only .site-search {
                @include media-breakpoint-up(lg) {
                    height: auto;
                }
                .search-field {
                    @include media-breakpoint-up(lg) {
                        width: 155px;
                        border: 1px solid $medium-gray;
                        padding: $spacer;
                    }
                    @include media-breakpoint-up(sd) {
                        width: 185px;
                    }
                    @include media-breakpoint-up(xl) {
                        width: 239px;
                    }
                }

                .fa-search {
                    right: 16px;
                }

                &.expend {
                    .search-field {
                        border: 1px solid $medium-gray;
                        padding: $spacer;

                        @include media-breakpoint-up(lg) {
                            width: 155px;
                        }

                        @include media-breakpoint-up(sd) {
                            width: 185px;
                        }

                        @include media-breakpoint-up(xl) {
                            width: 239px;
                        }
                    }
                }
            }
        }

        .site-search {
            .fa-search {
                @include media-breakpoint-down(md) {
                    display: block !important;
                    top: 7px;
                    right: $spacer;
                }
            }

            .fa-close,
            .reset-button {
                @include media-breakpoint-down(md) {
                    display: none !important;
                }
            }

            .search-field {
                @include media-breakpoint-down(md) {
                    height: 42px;
                    border: 1px solid $medium-gray;

                    &:focus {
                        border: 2px solid $primary-black;
                    }
                }
            }
        }

        .suggestions-wrapper {
            @include media-breakpoint-down(md) {
                height: auto;

                &.search-active {
                    height: calc(100vh - 150px);
                    position: absolute !important;
                    top: 100%;
                    left: 0;
                    right: 0;
                    z-index: 9;
                }

                .suggestions {
                    width: 100%;
                    border-radius: 0;
                }
            }
        }

        .minicart {
            .minicart-total {
                margin: 0;
            }
        }
    }

    .header-with-search {

        .header {
            @include media-breakpoint-down(md) {
                height: auto;
            }

            > .row {
                @include media-breakpoint-down(md) {
                    height: 56px;
                }
            }
        }
        .site-search {
            .fa-search {
                @include media-breakpoint-down(md) {
                    display: block !important;
                    top: 7px;
                    right: $spacer;
                }
            }
    
            .fa-close,
            .reset-button {
                @include media-breakpoint-down(md) {
                    display: none !important;
                }
            }
    
            .search-field {
                @include media-breakpoint-down(md) {
                    height: 40px;
                    border: 1px solid $medium-gray;
                    border-radius: 20px;
                    background: $concrete-grey;
    
                    &:focus {
                        border: 2px solid $primary-black;
                    }
                }
            }
        }
        #search-mobile {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    
        .suggestions-wrapper {
            @include media-breakpoint-down(md) {
                height: auto;
                &.search-active {
                    height: calc(100vh - 150px);
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 2;
                    right: 0;
                }
    
                .suggestions {
                    width: 100%;
                    border-radius: 0;
                }
            }
        }     
    }
}

.adjust-logo {
    @include media-breakpoint-down(md) {
        padding-left: 7%;
    }
}

@include media-breakpoint-up(lg) {
    .checkout-header {
        .new-header {
            padding-block-end: 0%;
        }
    }
}

.logo-center {
    @include media-breakpoint-down(md) {
        .usertopnavlayout {
            .header-column-1 {
                order: 1;
                justify-content: flex-start;
                margin-right: 0px;
            }
            .header-column-2 {
                order: 2;
                justify-content: center;
            }
            .header-column-3 {
                .minicart {
                    margin-right: 0px;
                }
            }
        }
        .header-column-3 {
            .minicart {
                margin-right: 16px;
            }
        }
    }
}
.usertopnavlayout {
    @include media-breakpoint-down(md) {
        .header-column-3 {
            .icon-spacing {
                margin-right: $spacer * 1.5;
            }
        }
    }
}
