@import "~core/utilities/swatch";

@mixin swatch-select($style: '') {
    &.Color-value {
        transition: box-shadow $transition-speed ease-out;
        @if $style == 'thumbnail' {
            &:hover:not(.unselectable) {
                box-shadow: $color-swatch-thumbnail-hover-shadow;
            }
            &.selected {
                box-shadow: $color-swatch-thumbnail-selected-shadow;
                @include icon($color-swatch-thumbnail-selected-icon, after, $color-swatch-thumbnail-selected-icon-size/2, $color-swatch-thumbnail-selected-icon-color);
                &::after {
                    position: absolute;
                    top: -$color-swatch-thumbnail-selected-icon-size/3;
                    right: -$color-swatch-thumbnail-selected-icon-size/3;
                    display: $color-swatch-thumbnail-selected-icon-display;
                    background: $color-swatch-thumbnail-selected-icon-background;
                    width: $color-swatch-thumbnail-selected-icon-size;
                    height: $color-swatch-thumbnail-selected-icon-size;
                    line-height: $color-swatch-thumbnail-selected-icon-size;
                    padding-left: 1px;
                    text-align: center;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
            &.unselectable {
                @include strike-through-swatch(calc(100% + 15px));
            }
        } @else {
            &:hover {
                box-shadow: none;
            }
            &.selected {
                border: 2px solid $primary-black;
                border-radius: $input-border-radius;
                box-shadow: 0 0 0 calc(#{$color-swatch-selected-shadow-size} - 1px) $page-background-color, 0 0 0 0 $color-swatch-selected-shadow-color;
                @include icon($color-swatch-selected-icon, after, $color-swatch-selected-icon-size/2, $color-swatch-selected-icon-color);
                &::after {
                    @include absolute-center;
                    display: $color-swatch-selected-icon-display;
                    background: $color-swatch-selected-icon-background;
                    width: $color-swatch-selected-icon-size;
                    height: $color-swatch-selected-icon-size;
                    line-height: $color-swatch-selected-icon-size;
                    padding-left: 1px;
                    text-align: center;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
            &.unselectable {
                filter: blur(0px);
                +.unselectable-icon {
                    @include disabled;
                    cursor: not-allowed;
                    position: absolute;
                    height: $pdp-color-swatch-height;
                    width: $pdp-color-swatch-width;
                    @include media-breakpoint-down (sm) {
                        height: calc(18.5vw);
                        width: calc(18.5vw);
                    }
                    bottom: 0;
                    right: 0;
                    @include strike-through-swatch(100%);
                }
            }
        }
        &.unselectable {
            @include disabled;
            pointer-events: auto;
            cursor: not-allowed;
            position: relative;
        }
    }
    &.non-color-swatch-value {
        transition: border $transition-speed ease-out, box-shadow $transition-speed ease-out;
        &:not(.unselectable):hover {
            box-shadow: 0 0 0 calc(#{$color-swatch-selected-shadow-size} - 1px) $page-background-color, 0 0 0 0 $color-swatch-selected-shadow-color;
        }
        &.selected,
        &.selected:hover {
            background: $non-color-swatch-selected-background;
            color: $non-color-swatch-selected-color;
            border-color: $non-color-swatch-selected-border-color;
            box-shadow: $non-color-swatch-selected-box-shadow;
            @include icon($non-color-swatch-selected-icon, after, $non-color-swatch-selected-icon-size/2, $non-color-swatch-selected-icon-color); 
            &::after {
                position: absolute;
                top: -$non-color-swatch-selected-icon-size/3;
                right: -$non-color-swatch-selected-icon-size/3;
                background: $non-color-swatch-selected-icon-background-color;
                width: $non-color-swatch-selected-icon-size;
                height: $non-color-swatch-selected-icon-size;
                line-height: $non-color-swatch-selected-icon-size;
                padding-left: 1px;
                text-align: center;
                border-radius: 50%;
                display: $non-color-swatch-selected-icon-display;
            } 
        }
        &.unselectable {
            pointer-events: auto;
            cursor: not-allowed;
            position: relative;
            color: $medium-gray;
        }
    }
}

@mixin swatch-non-color($min-width) {
    position: relative;
    color: $non-color-swatch-selected-color;
    background: $non-color-swatch-background;
    border: $non-color-swatch-border-width solid $non-color-swatch-border-color;
    border-radius: $non-color-swatch-border-radius;
    padding: $non-color-swatch-padding;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include typography(9);
    @include swatch-select;
}
.product-detail {
    .color-swatches {
        .unselectable-btn {
            position: relative;
            cursor: not-allowed;
            z-index: 1;

            +.unselectable-icon {
                @include disabled;
                cursor: not-allowed;
                position: absolute;
                height: $pdp-color-swatch-height;
                width: $pdp-color-swatch-width;
                @include media-breakpoint-down (sm) {
                    height: calc(18.5vw);
                    width: calc(18.5vw);
                }
                bottom: 0;
                right: 0;
                @include strike-through-swatch(100%);
            }
        }
        .unselectable {
            &.Color-value {
                &.swatch-circle {
                    &.selected {
                        position: relative;
                        &:before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            right: 50%;
                            transform: translate(50%, -50%) rotate(45deg);
                            width: 1px;
                            z-index: 2;
                            height: calc(100% - 1px);
                            background-color: transparent;
                            display: block;
                        }
                    }
                }
            }
        }
        .customColor-value {
            &.unselectable {
                filter: blur(1px);
                +.unselectable-icon {
                    @include disabled;
                    cursor: not-allowed;
                    position: absolute;
                    height: $pdp-color-swatch-height;
                    width: $pdp-color-swatch-width;
                    @include media-breakpoint-down (sm) {
                        height: calc(18.5vw);
                        width: calc(18.5vw);
                    }
                    bottom: 0;
                    right: 0;
                    @include strike-through-swatch(100%);
                }
            }
        }
    }
}
