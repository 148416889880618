.marquee-component {
    padding: $spacer*4 0;
    overflow: hidden;
    height: auto;

    @include media-breakpoint-up(md) {
        padding: $spacer*6 0;
    }

    .marquee-inner {
        display: block;
        position: relative;
        width: 100%;
        animation: marquee 5s linear infinite;

        @include media-breakpoint-up(md) {
            animation: marquee 10s linear infinite;
        }
        
        @include media-breakpoint-up(lg) {
            animation: marquee 15s linear infinite;
        }

        // &:hover {
        //     animation-play-state: paused; leaving in case feature is requested
        // }
    }

    @keyframes marquee {
        0% {
            left: 0;
        }

        100% {
            left: -100%;
        }
    }

    .marquee-inner-row {
        float: left;
        width: 100%;
        overflow: visible;
        position: relative;
        display: flex;
        justify-content: space-around;

        span {
            @include typography(3);
            text-align: center;
            margin: 0 24px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:nth-child(2){
                display: none;
            }

            &:nth-child(3){
                display: none;
            }

            @include media-breakpoint-up(md) {
                &:nth-child(2){
                    display: block;
                }
            }

            @include media-breakpoint-up(lg) {
                &:nth-child(3){
                    display: block;
                }
            }
        }
        
        span {
            font-size: 6vw;

            @include media-breakpoint-up(md) {
                font-size: 3vw;
            }

            @include media-breakpoint-up(lg) {
                font-size: 2vw;
            }
        }
    }

    .marquee-inner-row-copy {
        position: absolute;
        left: 100%;
    }
}