@import "bootstrap/scss/functions";
@import "utilities/icons";

/*
* COLORS
*/

$white: #FFFFFF !default;
$smoke-white:#F4F4F4 !default;
$concrete-grey:#f3f3f3 !default;
$off-white: #F2F2F2 !default;
$light-gray: #F9F9F9;
$medium-gray: #D4D4D4;
$dark-gray: #595959;
$gray: #BEBEBE !default;
$black: #000000;
$off-black: #222222 !default;
$primary-black: #181818 !default;
$new-grey: #EDEDED;
$dusty-grey: #8B8B8B;
$primary-black-hover: #5d5d5d;
$navy: $primary-black;
$green: #2eab2b !default;
$red: #a80b1d;
$yellow: #FFCC00 !default;
$blue: #3091E7 !default;
$jade: #2EAB2B;
$neon: #2EAB2B;
$malachite: #00D959;
$dark-mint: #76CBB4;
$mint: #C6FDCF;
$mint-2: #d3f0ea!default;
$sunshine: #FFDF59;
$cobalt: #2867EC;
$seafoam: #ACE7FA;
$violet: #634ED4;
$periwinkle: #6C8FFF;
$watermelon: #FF748B;
$hot-pink: #F5A4AD;
$tangerine: #FF9244;
$peach: #FFAF80;
$light-peach: #FEF0E5;
$apple-green: #2b8720;
$success-green: #01DF9D;
$pink: #ce02d6;
$cornflower-blue: #4586FF !default;
$success: $success-green;
$warning: $sunshine;
$danger: $red;
$info: $seafoam;
$sale: $red !default;
$spring-wood: #F6F7F4;
$cameo: #DBC4A9;
$alto: #D9D9D9;
$background-gray: #7f7d7d;
$gray-less: #aaa;
$wild-sand: #f5f5f5;
$seashell: #F1F1F1;
$scroll-gray: #888888;
$selgo: #ECF2FD;


$dark-red: #8B0000!default;

$brand-primary: $primary-black;
$brand-secondary: $dark-gray;
$brand-tertiary: $watermelon;
$vat-color: #66676e;
$cyan-blue: #0879c0;
$arsenic: #32414e;

$bullet-border-color: rgba(0,0,0,0.10);
$colors: (
    brand-primary: $brand-primary,
    light-gray: $light-gray,
    medium-gray: $medium-gray,
    dark-gray: $dark-gray,
    primary-black: $primary-black,
    primary-black-hover: $primary-black-hover,
    jade: $jade,
    neon: $neon,
    dark-mint: $dark-mint,
    mint: $mint,
    mint-2: $mint-2,
    sunshine: $sunshine,
    cobalt: $cobalt,
    seafoam: $seafoam,
    violet: $violet,
    periwinkle: $periwinkle,
    watermelon: $watermelon,
    hot-pink: $hot-pink,
    tangerine: $tangerine,
    peach: $peach,
    light-peach: $light-peach,
    success: $success,
    danger: $danger,
    warning: $warning,
    info: $info,
    dark-red: $dark-red,
    spring-wood: $spring-wood
);

$swatch-colors: (
    'beige': #f5f5dc,
    'black': $off-black,
    'blue': $blue,
    'brown': #a52a2a,
    'green': $green,
    'grey': #8f979d,
    'navy': #000080,
    'orange': #ffa500,
    'pink': #fe249a,
    'purple': #800080,
    'red': #f00,
    'white': $white,
    'yellow': #ff0
) !default;


/*
* GRID SYSTEM
*/
// These values should match the Breakpoints values in scripts/constants/SiteConstants.js
$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 1024px,
    sd: 1200px, // breakpoint added for small desktops/ipads
    xl: 1440px
) !default;

// Containers are set to higher values than corresponding breakpoints to allow them to be full width
$container-max-widths: (
    md: 1420px,
    lg: 1430px,
    xl: 1440px
) !default;

$spacer-sm: 5px !default;
$spacer: 16px !default; // This value should match the Spacer value in scripts/constants/SiteConstants.js
$spacer-xl: 16px !default;
$negative-spacer-sm: -$spacer-sm !default;
$negative-spacer: -$spacer !default;
$negative-spacer-xl: -$spacer-xl !default;

$grid-gutter-widths: (
  xs: $spacer,
  md: $spacer*2,
  xl: $spacer-xl*2 // Note: changing this will update all column and container spacing, but will not affect uses of $spacer variable
) !default;

$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4),
  8: ($spacer * 5),
  9: ($spacer * 6)
), $spacers);

/*
* Z Indexes
*/
$z-index-toast: 1100 !default;
$z-index-modal: 1050 !default;
$z-index-modal-backdrop: 1000 !default;
$z-index-header-fixed: normal;
$z-index-header-promo-banner: 350 !default;
$z-index-header: 300 !default;
$z-index-refinement-drawer: 250 !default;
$z-index-pdp-sticky-add-to-cart: 200 !default;
$z-index-sticky-footer: 100 !default;
$z-index-wishlist-icon: 50 !default;
$z-index-consent-drawer: 2147483601 !default; //This is set so high due to integrations like FreshChat that use z-index of 2147483600
$z-index-promo-caption: 2 !default;
$header-promo-blue-background: $seafoam;
$header-promo-green-background: $jade;
$header-promo-yellow-background:$sunshine;
$header-promo-red-background: $red;

$promotions-margin-top: 20px;
$promotions-margin-bottom: 40px;



/*
* ASPECT RATIOS
*/
$aspect-ratio-square: calc(1 / 1 * 100%) !default;
$aspect-ratio-landscape: calc(3 / 4 * 100%) !default;
$aspect-ratio-portrait: calc(4 / 3 * 100%) !default;
$aspect-ratio-one-one: calc(1 / 1 * 100%) !default;
$aspect-ratio-sixteen-nine: calc(9 / 16 * 100%) !default;
$aspect-ratio-nine-sixteen: calc(16 / 9 * 100%) !default;
$aspect-ratio-four-three: calc(3 / 4 * 100%) !default;
$aspect-ratio-three-four: calc(4 / 3 * 100%) !default;
// horizontal versions used for enforcing horizontal width in hero videos
$aspect-ratio-one-one-horizontal: calc(1 / 1 * 100vh) !default;
$aspect-ratio-sixteen-nine-horizontal: calc(16 / 9 * 100vh) !default;
$aspect-ratio-nine-sixteen-horizontal: calc(9 / 16 * 100vh) !default;
$aspect-ratio-four-three-horizontal: calc(4 / 3 * 100vh) !default;
$aspect-ratio-three-four-horizontal: calc(3 / 4 * 100vh) !default;

/*
* MISC
*/
$transition-speed: 200ms !default; // This value should match the TransitionSpeed value in scripts/constants/SiteConstants.js
$hero-transition-speed-long: 1000ms !default;
$hero-transition-speed-medium: 600ms !default;
$hero-transition-speed-short: 200ms !default;
$base-font-size: 16px !default;
$base-line-height: 1.43 !default;
$base-font-color: $brand-primary;
$border-radius: 20px;
$border-color: $medium-gray;
$page-background-color: $white !default;
$leading-lines-border-color: transparent !default;
$leading-lines-border-width: 0 !default;
$box-shadow: 0 24px 64px 0 rgba($black, 0.05);
$font-weight-medium: 500;


/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
$primary-font: 'Inter', sans-serif;
$secondary-font: 'Space Grotesk', sans-serif;
$tertiary-font: $secondary-font;
$header-font: $tertiary-font;
$body-font: $primary-font;

$fonts: (
    primary: $primary-font,
    secondary: $secondary-font,
    tertiary: $tertiary-font,
    header: $header-font,
    body: $body-font
) !default;


/*
* TEXT
*/
// Stand Alone Text Defaults
$default-text-font: $primary-font !default;
$default-text-font-size: 16px !default;
$default-text-m-line-height: 16px !default;
$default-text-line-height: 24px !default;
$default-text-font-weight: normal !default;
$default-text-letter-spacing: 0;

// Paragraphs
$paragraph-font-size: $default-text-font-size !default; // paragraph size on mobile
$paragraph-line-height: $default-text-line-height !default;
$paragraph-m-line-height: $default-text-m-line-height !default;
$paragraph-lg-font-size: $default-text-font-size !default; // paragraph size on desktop
$paragraph-lg-line-height: $default-text-line-height !default;
$paragraph-font-size-large: 24px; // large paragraph size on mobile
$paragraph-line-height-large: 32px;
$paragraph-lg-font-size-large: $paragraph-font-size-large !default;  // large paragraph size on desktop
$paragraph-lg-line-height-large: $default-text-line-height !default;
$paragraph-font-size-medium: 20px; // medium paragraph size on mobile
$paragraph-line-height-medium: 28px;
$paragraph-lg-font-size-medium: $paragraph-font-size-medium !default;  // medium paragraph size on desktop
$paragraph-lg-line-height-medium: $paragraph-line-height-medium !default;
$paragraph-font-size-small: 16px; // small paragraph size on mobile
$paragraph-line-height-small: 24px;
$paragraph-lg-font-size-small: $paragraph-font-size-small; // small paragraph size on desktop
$paragraph-lg-line-height-small: $paragraph-line-height-small;
$paragraph-letter-spacing: $default-text-letter-spacing !default;
$paragraph-bottom-margin: $spacer !default;
$search-letter-spacing: .13px;

// Links
$link-color: $brand-primary !default;
$link-color-hover-opacity: 0.8;
$link-color-hover: rgba($brand-primary, $link-color-hover-opacity);
$link-dark-theme-color: $white !default;
$link-dark-theme-color-hover: $white !default;
$link-font-weight: normal !default;
$link-text-decoration: underline !default;
$link-text-decoration-hover: underline !default;

$outline-color: $link-color !default;
$text-shadow: 0 40px 80px rgba(0,0,0,0.3) !default;

$headline-xxl-font-family: $primary-font;
$headline-xxl-font-size: 144px;
$headline-xxl-line-height: 144px;
$headline-xxl-text-transform: none;
$headline-xxl-letter-spacing: -6px;
$headline-xxl-font-weight: bold;

$headline-xxl-m-font-family: $primary-font;
$headline-xxl-m-font-size: 96px;
$headline-xxl-m-line-height: 96px;
$headline-xxl-m-text-transform: none;
$headline-xxl-m-letter-spacing: -6px;

$headline-xl-font-family: $header-font;
$headline-xl-font-size: 128px;
$headline-xl-line-height: 140px;
$headline-xl-text-transform: none;
$headline-xl-letter-spacing: -2px;
$headline-xl-font-weight: bold;

$headline-l-font-family: $header-font;
$headline-l-font-size: 72px;
$headline-l-line-height: 80px;
$headline-l-text-transform: none;
$headline-l-letter-spacing: -2px;
$headline-l-font-weight: bold;

$headline-m-font-family: $header-font;
$headline-m-font-size: 48px;
$headline-m-line-height: 52px;
$headline-m-text-transform: none;
$headline-m-letter-spacing: -2px;
$headline-m-font-weight: bold;

$headline-s-font-family: $header-font;
$headline-s-font-size: 32px;
$headline-s-line-height: 36px;
$headline-s-text-transform: none;
$headline-s-letter-spacing: -1px;
$headline-s-font-weight: bold;

$headline-xs-font-family: $header-font;
$headline-xs-font-size: 24px;
$headline-xs-line-height: 26px;
$headline-xs-text-transform: none;
$headline-xs-letter-spacing: -1px;
$headline-xs-font-weight: bold;

$header-l-font-family: $primary-font;
$header-l-font-size: 72px;
$header-l-line-height: 80px;
$header-l-text-transform: none;
$header-l-letter-spacing: -2px;
$header-l-font-weight: bold;

$header-m-font-family: $primary-font;
$header-m-font-size: 48px;
$header-m-line-height: 56px;
$header-m-text-transform: none;
$header-m-letter-spacing: -1px;
$header-m-font-weight: bold;

$header-s-font-family: $primary-font;
$header-s-font-size: 32px;
$header-s-line-height: 40px;
$header-s-text-transform: none;
$header-s-letter-spacing: -1px;
$header-s-font-weight: bold;

$header-xs-font-family: $primary-font;
$header-xs-font-size: 24px;
$header-xs-line-height: 32px;
$header-xs-text-transform: none;
$header-xs-letter-spacing: -1px;
$header-xs-font-weight: bold;

$body-copy-l-font-family: $primary-font;
$body-copy-l-font-size: $paragraph-font-size-large;
$body-copy-l-line-height: $paragraph-line-height-large;
$body-copy-l-text-transform: none;
$body-copy-l-letter-spacing: 0;
$body-copy-l-font-weight: bold;

$body-copy-l-book-font-family: $primary-font;
$body-copy-l-book-font-size: $paragraph-font-size-large;
$body-copy-l-book-line-height: $paragraph-line-height-large;
$body-copy-l-book-text-transform: none;
$body-copy-l-book-letter-spacing: 0;
$body-copy-l-book-font-weight: normal;

$body-copy-m-font-family: $primary-font;
$body-copy-m-font-size: $paragraph-font-size-medium;
$body-copy-m-line-height: $paragraph-line-height-medium;
$body-copy-m-text-transform: none;
$body-copy-m-letter-spacing: 0;
$body-copy-m-font-weight: bold;

$body-copy-m-book-font-family: $primary-font;
$body-copy-m-book-font-size: $paragraph-font-size-medium;
$body-copy-m-book-line-height: $paragraph-line-height-medium;
$body-copy-m-book-text-transform: none;
$body-copy-m-book-letter-spacing: 0;
$body-copy-m-book-font-weight: normal;

$body-copy-s-font-family: $primary-font;
$body-copy-s-font-size: $paragraph-font-size-small;
$body-copy-s-line-height: $paragraph-line-height-small;
$body-copy-s-text-transform: none;
$body-copy-s-letter-spacing: 0;
$body-copy-s-font-weight: bold;

$body-copy-s-book-font-family: $primary-font;
$body-copy-s-book-font-size: $paragraph-font-size-small;
$body-copy-s-book-line-height: $paragraph-line-height-small;
$body-copy-s-book-text-transform: none;
$body-copy-s-book-letter-spacing: 0;
$body-copy-s-book-font-weight: normal;

$body-copy-xs-font-family: $primary-font;
$body-copy-xs-font-size: 14px;
$body-copy-xs-line-height: 20px;
$body-copy-xs-text-transform: none;
$body-copy-xs-letter-spacing: 0;
$body-copy-xs-font-weight: bold;

$body-copy-xs-book-font-family: $primary-font;
$body-copy-xs-book-font-size: 14px;
$body-copy-xs-book-line-height: 20px;
$body-copy-xs-book-text-transform: none;
$body-copy-xs-book-letter-spacing: 0;
$body-copy-xs-book-font-weight: normal;

$body-copy-xxs-font-family: $primary-font;
$body-copy-xxs-font-size: 12px;
$body-copy-xxs-line-height: 16px;
$body-copy-xxs-text-transform: none;
$body-copy-xxs-letter-spacing: 0;
$body-copy-xxs-font-weight: bold;

$body-copy-xxs-book-font-family: $primary-font;
$body-copy-xxs-book-font-size: 12px;
$body-copy-xxs-book-line-height: 16px;
$body-copy-xxs-book-text-transform: none;
$body-copy-xxs-book-letter-spacing: 0;
$body-copy-xxs-book-font-weight: normal;

$header-l-letter-spacing: -2px;
$header-m-letter-spacing: -1px;
$header-xs-letter-spacing: 0;


$typography: (
    headline-xl $headline-l-font-family $headline-l-font-size $headline-l-line-height $headline-l-text-transform
        $headline-xl-font-family $headline-xl-font-size $headline-xl-line-height $headline-xl-text-transform 
        $headline-xl-font-weight $headline-xl-letter-spacing,
    headline-l $headline-m-font-family $headline-m-font-size $headline-m-line-height $headline-m-text-transform
        $headline-l-font-family $headline-l-font-size $headline-l-line-height $headline-l-text-transform 
        $headline-l-font-weight $headline-l-letter-spacing,
    headline-m $headline-s-font-family $headline-s-font-size $headline-s-line-height $headline-s-text-transform
        $headline-m-font-family $headline-m-font-size $headline-m-line-height $headline-m-text-transform 
        $headline-m-font-weight $headline-m-letter-spacing,
    headline-s $headline-xs-font-family $headline-xs-font-size $headline-xs-line-height $headline-xs-text-transform
        $headline-s-font-family $headline-s-font-size $headline-s-line-height $headline-s-text-transform 
        $headline-s-font-weight $headline-s-letter-spacing,
    headline-xs $headline-xs-font-family $headline-xs-font-size $headline-xs-line-height $headline-xs-text-transform
        $headline-xs-font-family $headline-xs-font-size $headline-xs-line-height $headline-xs-text-transform 
        $headline-xs-font-weight $headline-xs-letter-spacing,
    body-copy-l $body-copy-l-font-family $body-copy-l-font-size $body-copy-l-line-height $body-copy-l-text-transform
        $body-copy-l-font-family $body-copy-l-font-size $body-copy-l-line-height $body-copy-l-text-transform
        $body-copy-l-font-weight $body-copy-l-letter-spacing,
    body-copy-l-book $body-copy-m-book-font-family $body-copy-m-book-font-size $body-copy-m-book-line-height $body-copy-m-book-text-transform
        $body-copy-l-book-font-family $body-copy-l-book-font-size $body-copy-l-book-line-height $body-copy-l-book-text-transform
        $body-copy-l-book-font-weight $body-copy-l-book-letter-spacing,
    body-copy-m $body-copy-m-font-family $body-copy-m-font-size $body-copy-m-line-height $body-copy-m-text-transform
        $body-copy-m-font-family $body-copy-m-font-size $body-copy-m-line-height $body-copy-m-text-transform
        $body-copy-m-font-weight $body-copy-m-letter-spacing,
    body-copy-m-book $body-copy-s-book-font-family $body-copy-s-book-font-size $body-copy-s-book-line-height $body-copy-s-book-text-transform
        $body-copy-m-book-font-family $body-copy-m-book-font-size $body-copy-m-book-line-height $body-copy-m-book-text-transform
        $body-copy-m-book-font-weight $body-copy-m-book-letter-spacing,
    body-copy-s $body-copy-s-font-family $body-copy-s-font-size $body-copy-s-line-height $body-copy-s-text-transform
        $body-copy-s-font-family $body-copy-s-font-size $body-copy-s-line-height $body-copy-s-text-transform
        $body-copy-s-font-weight $body-copy-s-letter-spacing,
    body-copy-s-book $body-copy-s-book-font-family $body-copy-s-book-font-size $body-copy-s-book-line-height $body-copy-s-book-text-transform
        $body-copy-s-book-font-family $body-copy-s-book-font-size $body-copy-s-book-line-height $body-copy-s-book-text-transform
        $body-copy-s-book-font-weight $body-copy-s-book-letter-spacing,
    body-copy-xs $body-copy-xs-font-family $body-copy-xs-font-size $body-copy-xs-line-height $body-copy-xs-text-transform
        $body-copy-xs-font-family $body-copy-xs-font-size $body-copy-xs-line-height $body-copy-xs-text-transform
        $body-copy-xs-font-weight $body-copy-xs-letter-spacing,
    body-copy-xs-book $body-copy-xs-book-font-family $body-copy-xs-book-font-size $body-copy-xs-book-line-height $body-copy-xs-book-text-transform
        $body-copy-xs-book-font-family $body-copy-xs-book-font-size $body-copy-xs-book-line-height $body-copy-xs-book-text-transform
        $body-copy-xs-book-font-weight $body-copy-xs-book-letter-spacing,
    body-copy-xxs $body-copy-xxs-font-family $body-copy-xxs-font-size $body-copy-xxs-line-height $body-copy-xxs-text-transform
        $body-copy-xxs-font-family $body-copy-xxs-font-size $body-copy-xxs-line-height $body-copy-xxs-text-transform
        $body-copy-xxs-font-weight $body-copy-xxs-letter-spacing,
    body-copy-xxs-book $body-copy-xxs-book-font-family $body-copy-xxs-book-font-size $body-copy-xxs-book-line-height $body-copy-xxs-book-text-transform
        $body-copy-xxs-book-font-family $body-copy-xxs-book-font-size $body-copy-xxs-book-line-height $body-copy-xxs-book-text-transform
        $body-copy-xxs-book-font-weight $body-copy-xxs-book-letter-spacing,
    header-l $header-m-font-family $header-m-font-size $header-m-line-height $header-m-text-transform
        $header-l-font-family $header-l-font-size $header-l-line-height $header-l-text-transform 
        $header-l-font-weight $header-l-letter-spacing,
    header-m $header-s-font-family $header-s-font-size $header-s-line-height $header-s-text-transform
        $header-m-font-family $header-m-font-size $header-m-line-height $header-m-text-transform 
        $header-m-font-weight $header-m-letter-spacing,
    header-s $header-xs-font-family $header-xs-font-size $header-xs-line-height $header-xs-text-transform
        $header-s-font-family $header-s-font-size $header-s-line-height $header-s-text-transform 
        $header-s-font-weight $header-m-letter-spacing,
    header-xs $header-xs-font-family $header-xs-font-size $header-xs-line-height $header-xs-text-transform
        $header-xs-font-family $header-xs-font-size $header-xs-line-height $header-xs-text-transform 
        $header-xs-font-weight $header-xs-letter-spacing,
    headline-xxl $headline-xxl-m-font-family $headline-xxl-m-font-size $headline-xxl-m-line-height $headline-xxl-m-text-transform
        $headline-xxl-font-family $headline-xxl-font-size $headline-xxl-line-height $headline-xxl-text-transform 
        $headline-xxl-font-weight $headline-xxl-letter-spacing,
) !default;

// Charity specific links
$charity-font-size: 14px;
$charity-font-color: $primary-black;

/*
* HEADERS
*/
// Mobile header values
$h1-font-family: $headline-s-font-family;
$h1-font-size: $headline-s-font-size;
$h1-line-height: $headline-s-line-height;
$h1-text-transform: $headline-s-text-transform;
$h1-font-weight: $default-text-font-weight;
$h1-letter-spacing: $default-text-letter-spacing;
$h1-font-style: normal;

$h2-font-family: $headline-xs-font-family;
$h2-font-size: $headline-xs-font-size;
$h2-line-height: $headline-xs-line-height;
$h2-text-transform: $headline-xs-text-transform;
$h2-font-weight: $default-text-font-weight;
$h2-letter-spacing: $default-text-letter-spacing;
$h2-font-style: normal;

$h3-font-family: $body-copy-l-font-family;
$h3-font-size: $body-copy-l-font-size;
$h3-line-height: $body-copy-l-line-height;
$h3-text-transform: $body-copy-l-text-transform;
$h3-font-weight: $default-text-font-weight;
$h3-letter-spacing: $default-text-letter-spacing;
$h3-font-style: normal;

$h4-font-family: $body-copy-m-font-family;
$h4-font-size: $body-copy-m-font-size;
$h4-line-height: $body-copy-m-line-height;
$h4-text-transform: $body-copy-m-text-transform;
$h4-font-weight: $default-text-font-weight;
$h4-letter-spacing: $default-text-letter-spacing;
$h4-font-style: normal;

$h5-font-family: $body-copy-s-font-family;
$h5-font-size: $body-copy-s-font-size;
$h5-line-height: $body-copy-s-line-height;
$h5-text-transform: $body-copy-s-text-transform;
$h5-font-weight: $default-text-font-weight;
$h5-letter-spacing: $default-text-letter-spacing;
$h5-font-style: normal;

$h6-font-family: $body-copy-xs-font-family;
$h6-font-size: $body-copy-xs-font-size;
$h6-line-height: $body-copy-xs-line-height;
$h6-text-transform: $body-copy-xs-text-transform;
$h6-font-weight: $default-text-font-weight;
$h6-letter-spacing: $default-text-letter-spacing;
$h6-font-style: normal;

$h1-md-font-family: $h1-font-family;
$h1-md-font-size: $h1-font-size;
$h1-md-line-height: $h1-line-height;
$h1-md-text-transform: $h1-text-transform;
$h1-md-font-weight: $h1-font-weight;
$h1-md-letter-spacing: $h1-letter-spacing;
$h1-md-font-style: $h1-font-style;

$h2-md-font-family: $h2-font-family;
$h2-md-font-size: $h2-font-size;
$h2-md-line-height: $h2-line-height;
$h2-md-text-transform: $h2-text-transform;
$h2-md-font-weight: $h2-font-weight;
$h2-md-letter-spacing: $h2-letter-spacing;
$h2-md-font-style: $h2-font-style;

$h3-md-font-family: $h3-font-family;
$h3-md-font-size: $h3-font-size;
$h3-md-line-height: $h3-line-height;
$h3-md-text-transform: $h3-text-transform;
$h3-md-font-weight: $h3-font-weight;
$h3-md-letter-spacing: $h3-letter-spacing;
$h3-md-font-style: $h3-font-style;

$h4-md-font-family: $h4-font-family;
$h4-md-font-size: $h4-font-size;
$h4-md-line-height: $h4-line-height;
$h4-md-text-transform: $h4-text-transform;
$h4-md-font-weight: $h4-font-weight;
$h4-md-letter-spacing: $h4-letter-spacing;
$h4-md-font-style: $h4-font-style;

$h5-md-font-family: $h5-font-family;
$h5-md-font-size: $h5-font-size;
$h5-md-line-height: $h5-line-height;
$h5-md-text-transform: $h5-text-transform;
$h5-md-font-weight: $h5-font-weight;
$h5-md-letter-spacing: $h5-letter-spacing;
$h5-md-font-style: $h5-font-style;

$h6-md-font-family: $h6-font-family;
$h6-md-font-size: $h6-font-size;
$h6-md-line-height: $h6-line-height;
$h6-md-text-transform: $h6-text-transform;
$h6-md-font-weight: $h6-font-weight;
$h6-md-letter-spacing: $h6-letter-spacing;
$h6-md-font-style: $h6-font-style;

$h1-lg-font-family: $h1-font-family;
$h1-lg-font-size: $h1-font-size;
$h1-lg-line-height: $h1-line-height;
$h1-lg-text-transform: $h1-text-transform;
$h1-lg-font-weight: $h1-font-weight;
$h1-lg-letter-spacing: $h1-letter-spacing;
$h1-lg-font-style: $h1-font-style;

$h2-lg-font-family: $h2-font-family;
$h2-lg-font-size: $h2-font-size;
$h2-lg-line-height: $h2-line-height;
$h2-lg-text-transform: $h2-text-transform;
$h2-lg-font-weight: $h2-font-weight;
$h2-lg-letter-spacing: $h2-letter-spacing;
$h2-lg-font-style: $h2-font-style;

$h3-lg-font-family: $h3-font-family;
$h3-lg-font-size: $h3-font-size;
$h3-lg-line-height: $h3-line-height;
$h3-lg-text-transform: $h3-text-transform;
$h3-lg-font-weight: $h3-font-weight;
$h3-lg-letter-spacing: $h3-letter-spacing;
$h3-lg-font-style: $h3-font-style;

$h4-lg-font-family: $h4-font-family;
$h4-lg-font-size: $h4-font-size;
$h4-lg-line-height: $h4-line-height;
$h4-lg-text-transform: $h4-text-transform;
$h4-lg-font-weight: $h4-font-weight;
$h4-lg-letter-spacing: $h4-letter-spacing;
$h4-lg-font-style: $h4-font-style;

$h5-lg-font-family: $h5-font-family;
$h5-lg-font-size: $h5-font-size;
$h5-lg-line-height: $h5-line-height;
$h5-lg-text-transform: $h5-text-transform;
$h5-lg-font-weight: $h5-font-weight;
$h5-lg-letter-spacing: $h5-letter-spacing;
$h5-lg-font-style: $h5-font-style;

$h6-lg-font-family: $h6-font-family;
$h6-lg-font-size: $h6-font-size;
$h6-lg-line-height: $h6-line-height;
$h6-lg-text-transform: $h6-text-transform;
$h6-lg-font-weight: $h6-font-weight;
$h6-lg-letter-spacing: $h6-letter-spacing;
$h6-lg-font-style: $h6-font-style;

$headers: (
    h1 $h1-font-family $h1-font-size $h1-line-height $h1-text-transform,
    h2 $h2-font-family $h2-font-size $h1-line-height $h2-text-transform,
    h3 $h3-font-family $h3-font-size $h3-line-height $h3-text-transform,
    h4 $h4-font-family $h4-font-size $h4-line-height $h4-text-transform,
    h5 $h5-font-family $h5-font-size $h5-line-height $h5-text-transform,
    h6 $h6-font-family $h6-font-size $h6-line-height $h6-text-transform
) !default;

/*
* BUTTONS
*/
$button-font: $primary-font !default;
$button-font-size: 16px;
$button-line-height: 24px;
$button-font-weight: 500 !default;
$button-padding-top: 8px;
$button-padding-bottom: 8px;
$button-padding-left: 15px !default;
$button-padding-icon: 42px;
$button-padding-right: 15px !default;
$button-letter-spacing: 0;
$button-text-transform: none !default;
$button-border-radius: 32px;
$button-border-width: 2px;
$button-icon-size: 20px !default;
$button-text-align: center !default;
$button-hover-opacity: 0.8;
$button-disabled-background: $medium-gray;
$button-disabled-color: $white;

// Large Button
$button-lg-font-size: 16px;
$button-lg-line-height: 24px;
$button-lg-padding-top: 16px;
$button-lg-padding-bottom: 16px;
$button-lg-padding-left: 32px;
$button-lg-padding-right: 32px;
$button-lg-letter-spacing: 0;
$button-lg-icon-size: 22px !default;
$button-lg-padding-icon: 63px;

// Primary button
$button-primary-font: $button-font !default;
$button-primary-font-weight: $button-font-weight !default;
$button-primary-text-transform: $button-text-transform !default;
$button-primary-color: $white !default;
$button-primary-color-hover: $white !default;
$button-primary-background: $brand-primary !default;
$button-primary-background-hover: $primary-black-hover;
$button-primary-border-radius: $button-border-radius !default;
$button-primary-border-width: $button-border-width !default;
$button-primary-border-color: $brand-primary !default;
$button-primary-border-color-hover: $primary-black-hover;
$button-primary-text-decoration: none !default;
$button-primary-text-decoration-hover: none !default;
$button-primary-padding-top: $button-padding-top !default;
$button-primary-padding-bottom: $button-padding-bottom !default;
$button-primary-padding-left: $button-padding-left !default;
$button-primary-padding-right: $button-padding-right !default;
$button-primary-text-align: $button-text-align !default;
$button-primary-background-disabled: $medium-gray;
$button-primary-dark-theme-color: $brand-primary;
$button-primary-dark-theme-color-hover: $brand-primary;
$button-primary-dark-theme-background: $white;
$button-primary-dark-theme-border-color: $white;
$button-primary-dark-theme-background-hover: $button-primary-dark-theme-background;
$button-primary-dark-theme-border-color-hover: $button-primary-dark-theme-background;

// Secondary button
$button-secondary-font: $button-font !default;
$button-secondary-font-weight: $button-font-weight !default;
$button-secondary-text-transform: $button-text-transform !default;
$button-secondary-color: $brand-primary !default;
$button-secondary-color-hover: $button-secondary-color;
$button-secondary-background: rgba($white, 0);
$button-secondary-background-hover: $button-secondary-background;
$button-secondary-border-radius: $button-border-radius !default;
$button-secondary-border-width: $button-border-width !default;
$button-secondary-border-color: $brand-primary !default;
$button-secondary-border-color-hover: $button-secondary-color;
$button-secondary-text-decoration: none !default;
$button-secondary-text-decoration-hover: none !default;
$button-secondary-padding-top: $button-padding-top !default;
$button-secondary-padding-bottom: $button-padding-bottom !default;
$button-secondary-padding-left: $button-padding-left !default;
$button-secondary-padding-right: $button-padding-right !default;
$button-secondary-text-align: $button-text-align !default;
$button-secondary-dark-theme-background: rgba($brand-primary, 0);
$button-secondary-dark-theme-background-hover: $button-secondary-dark-theme-background;
$button-secondary-dark-theme-color: $white;
$button-secondary-dark-theme-color-hover: $white;
$button-secondary-dark-theme--border-color: $white;

// Tertiary button
$button-tertiary-font: $button-font !default;
$button-tertiary-font-weight: $button-font-weight !default;
$button-tertiary-text-transform: $button-text-transform !default;
$button-tertiary-color: $brand-primary !default;
$button-tertiary-color-hover: $button-tertiary-color;
$button-tertiary-background: rgba($white, 0);
$button-tertiary-background-hover: $button-tertiary-background;
$button-tertiary-border-radius: 0;
$button-tertiary-border-width: 0;
$button-tertiary-border-color: $brand-primary !default;
$button-tertiary-border-color-hover: $button-tertiary-color;
$button-tertiary-text-decoration: underline;
$button-tertiary-text-decoration-hover: underline;
$button-tertiary-padding-top: 0;
$button-tertiary-padding-bottom: 0;
$button-tertiary-padding-left: 0;
$button-tertiary-padding-right: 0;
$button-tertiary-text-align: $button-text-align !default;
$button-tertiary-dark-theme-color: $white;
$button-tertiary-dark-theme-color-hover: $white;
$button-tertiary-dark-theme--border-color: $white;

// Add to cart Button
$button-add-to-cart-font: $button-font !default;
$button-add-to-cart-font-weight: $button-font-weight !default;
$button-add-to-cart-text-transform: $button-text-transform !default;
$button-add-to-cart-color: $brand-primary !default;
$button-add-to-cart-color-hover: $button-add-to-cart-color;
$button-add-to-cart-background: $tangerine;
$button-add-to-cart-background-hover: $button-add-to-cart-background;
$button-add-to-cart-border-radius: $button-border-radius !default;
$button-add-to-cart-border-width: $button-border-width !default;
$button-add-to-cart-border-color: $button-add-to-cart-background;
$button-add-to-cart-border-color-hover: $button-add-to-cart-background;
$button-add-to-cart-text-decoration: none !default;
$button-add-to-cart-text-decoration-hover: none !default;
$button-add-to-cart-padding-top: $button-lg-padding-top !default;
$button-add-to-cart-padding-bottom: $button-lg-padding-bottom !default;
$button-add-to-cart-padding-left: $button-lg-padding-left !default;
$button-add-to-cart-padding-right: $button-lg-padding-right !default;
$button-add-to-cart-text-align: $button-text-align !default;
$button-add-to-cart-background-disabled: $dark-gray;
$button-add-to-cart-dark-theme-color: $button-add-to-cart-color;
$button-add-to-cart-dark-theme-color-hover: $button-add-to-cart-color-hover;
$button-add-to-cart-dark-theme-background: $button-add-to-cart-background;
$button-add-to-cart-dark-theme-border-color: $white;
$button-add-to-cart-dark-theme-background-hover: $button-add-to-cart-background-hover;
$button-add-to-cart-dark-theme-border-color-hover: $button-add-to-cart-border-color-hover;
$button-swipe-up-chekcout-options: transparent !default; 

// Small Button
$button-sm-font-size: 14px !default;
$button-sm-line-height: 18px !default;
$button-sm-padding-top: $spacer-sm !default;
$button-sm-padding-bottom: $spacer-sm !default;
$button-sm-padding-left: 20px !default;
$button-sm-padding-right: 20px !default;
$button-sm-letter-spacing: 0.05em !default;
$button-sm-icon-size: 22px !default;
$button-sm-primary-padding-top: $button-sm-padding-top !default;
$button-sm-primary-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-primary-padding-left: $button-sm-padding-left !default;
$button-sm-primary-padding-right: $button-sm-padding-right !default;
$button-sm-secondary-padding-top: $button-sm-padding-top !default;
$button-sm-secondary-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-secondary-padding-left: $button-sm-padding-left !default;
$button-sm-secondary-padding-right: $button-sm-padding-right !default;
$button-sm-tertiary-padding-top: $button-sm-padding-top !default;
$button-sm-tertiary-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-tertiary-padding-left: 0 !default;
$button-sm-tertiary-padding-right: 0 !default;

/*
* Notify button
*/

$button-notify-padding-top: 14px !default;
$button-notify-padding-bottom: 14px !default;

/*
* FORMs
*/
// Form Group
$form-group-margin-top: $spacer-xl !default;

// Inputs
$input-border-color: $medium-gray;
$input-border-color-focus: $input-border-color;
$input-border-color-hover: $input-border-color;
$input-background-color: $white !default;
$input-outline: none !default;
$input-outline-focus: none !default;
$input-outline-hover: none !default;
$input-box-shadow: none !default;
$input-box-shadow-focus: none !default;
$input-box-shadow-hover: 0 0 0 1px $medium-gray;
$input-text-color: $dark-gray !default;
$input-text-color-focus: $dark-gray;
$input-placeholder-color: $dark-gray !default;
$input-border-width: 1px !default;
$input-border-radius: 4px;
$input-font: $body-font !default;
$input-font-size: 16px !default; // this must be 16px or more to prevent automatic zooming on iPhones
$input-font-size-md: $default-text-font-size !default;
$input-font-line-height: 16px !default;
$input-font-letter-spacing: $default-text-letter-spacing !default;
$input-padding-top: 16px;
$input-padding-bottom: 14px;
$input-padding-left: 16px !default;
$input-padding-right: 16px !default;
$input-height: calc(#{$input-font-line-height} + #{$input-padding-top} + #{$input-padding-bottom} + (#{$input-border-width}*2)) !default;

// Small input
$input-sm-font-size: 11px !default;
$input-sm-font-line-height: 14px !default;
$input-sm-padding-top: 8px !default;
$input-sm-padding-bottom: 6px !default;
$input-sm-padding-left: 10px !default;
$input-sm-padding-right: 10px !default;
$input-sm-height: calc(#{$input-sm-font-line-height} + #{$input-sm-padding-top} + #{$input-sm-padding-bottom} + (#{$input-border-width}*2)) !default;

// Labels
$form-label-color: $base-font-color !default;
$form-label-font: $primary-font !default;
$form-label-font-weight: normal !default;
$form-label-font-size: $body-copy-xs-font-size;
$form-label-line-height: $body-copy-xs-line-height !default;
$form-label-margin-bottom: 2px !default;
$form-label-text-transform: none !default;
$form-label-letter-spacing: $default-text-letter-spacing !default;

// Help text
$form-help-text-color: $dark-gray !default;
$form-help-text-font-size: 14px !default;
$form-help-text-line-height: 20px !default;
$form-help-text-letter-spacing: 0 !default;
$form-help-text-margin-top: 4px !default;

// Validation
$form-validation-color: map-get($colors, danger) !default;
$form-validation-font-size: $form-label-font-size !default;

// Radio buttons and check boxes
$radiocheck-label-font: $form-label-font !default;
$radiocheck-label-font-weight: $form-label-font-weight !default;
$radiocheck-label-font-size: $form-label-font-size !default;
$radiocheck-label-line-height: 20px;
$radiocheck-label-text-transform: none !default;
$radiocheck-label-letter-spacing: $form-label-letter-spacing !default;
$radiocheck-background-color: $white !default;
$radiocheck-background-color-selected: $brand-primary !default;
$radiocheck-background-color-error: lighten($danger, 55%) !default;
$radiocheck-border-color: $dark-gray;
$radiocheck-border-color-selected: $dark-gray;
$radiocheck-border-color-error: $danger !default;
$radiocheck-icon-color: $white !default;
$radiocheck-button-size: 24px !default;
$filter-radiocheck-button-size: 20px !default;
$radiocheck-button-margin: 10px !default; // spacing between icon and text label
$filter-radiocheck-button-margin: 14px !default; // spacing between icon and text label
$radiocheck-vertical-spacing: 27px; // the margin between items when more than one are listed
$radiocheck-border-radius: 2px;
$filter-radiocheck-border-radius: 4px;
$radiobutton-selected-inset-amount: 12px;
$filter-radiobutton-selected-inset-amount: 12px;
$checkbox-active-background-color: $apple-green;

// Custom attribute radio button
$attribute-radiocheck-label-font: $form-label-font !default;
$attribute-radiocheck-label-font-weight: $form-label-font-weight !default;
$attribute-radiocheck-label-font-size: $form-label-font-size !default;
$attribute-radiocheck-label-line-height: 24px;
$attribute-radiocheck-label-text-transform: none !default;
$attribute-radiocheck-label-letter-spacing: $form-label-letter-spacing !default;
$attribute-radiocheck-background-color: $white !default;
$attribute-radiocheck-background-color-selected: $brand-primary !default;
$attribute-radiocheck-background-color-error: lighten($danger, 55%) !default;
$attribute-radiocheck-border-color: $brand-primary;
$attribute-radiocheck-border-color-selected: $brand-primary;
$attribute-radiocheck-border-color-error: $danger !default;
$attribute-radiocheck-icon-color: $white !default;
$attribute-radiocheck-button-size: $spacer*2;
$attribute-radiocheck-button-margin: $spacer*1.5; // spacing between icon and text label
$attribute-radiocheck-border-radius: 2px;
$attribute-radiobutton-selected-inset-amount: 10px;

$custom-control-indicator-checked-color: $attribute-radiocheck-icon-color;
$custom-control-indicator-checked-border-color: $attribute-radiocheck-border-color-selected;
$custom-control-indicator-checked-bg: $attribute-radiocheck-background-color-selected;

// Select dropdowns
$select-icon-size: 8px;
$select-padding-right: $input-padding-right + $select-icon-size + $spacer-sm !default;
// Small dropdown
$select-sm-icon-size: 6px;
$select-sm-padding-right: $input-sm-padding-right + $select-sm-icon-size + $spacer-sm !default;

// Quantity stepper
$quantity-stepper-icon-size: 10px !default;
$quantity-stepper-icon-size-small: 8px !default;

// Inline Button + Input group
$inline-input-button-border-color: $brand-secondary !default;
$inline-input-button-border-color-hover: $input-border-color;
$inline-input-button-background-color: transparent !default;
$inline-input-button-box-shadow: none !default;
$inline-input-button-box-shadow-hover: $input-box-shadow-hover;
$inline-input-button-text-color: $input-text-color !default;
$inline-input-button-placeholder-color: $input-placeholder-color !default;
$inline-input-button-border-width: $input-border-width;
$inline-input-button-border-radius: $input-border-radius;
$inline-input-button-font-size: $input-font-size !default;
$inline-input-button-font-line-height: $input-font-line-height !default;
$inline-input-button-font-letter-spacing: $input-font-letter-spacing !default;
$inline-input-button-padding-top: $input-padding-top !default;
$inline-input-button-padding-bottom: $input-padding-bottom !default;
$inline-input-button-padding-left: $input-padding-left !default;
$inline-input-button-padding-right: $input-padding-right !default;


/*
* PROGRESSBAR
*/

$progressbar-height: 12px;
$progress-bar-radius: $spacer/2;
$progress-bar-background: $white;
$progress-bar-background-fill: $cobalt;
$progress-bar-background-success: $malachite;
$progress-bar-border-color: $medium-gray;

/*
* BREADCRUMBS
*/
$breadcrumb-font-size: 12px;
$breadcrumb-line-height: 20px !default;
$breadcrumb-letter-spacing: 0;
$breadcrumb-font-weight: 500 !default;
$breadcrumb-text-decoration: none !default;
$breadcrumb-text-decoration-hover: underline !default;
$breadcrumb-divider: '/' !default;
$breadcrumb-divider-color: $link-color !default;
$breadcrumb-item-padding: 10px;
$breadcrumb-item-color: $link-color !default;
$breadcrumb-item-color-hover: $link-color-hover !default;

/*
* TOOLTIPS
*/
$tooltip-background-color: $white !default;
$tooltip-text-color: $off-black !default;
$tooltip-font-size: 10px !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-box-shadow: 0 2px 6px rgba($off-black, 0.1) !default;
$tooltip-padding: 12px 25px !default;
$tooltip-max-width: 200px !default;
$tooltip-text-align: center !default;
$tooltip-opacity: 1 !default;
$tooltip-icon: $icon-info !default;
$tooltip-icon-size: 120% !default;


/*
* POPOVERS
*/
$popover-font-size: 12px !default;
$popover-border-color: transparent !default;
$popover-box-shadow: $tooltip-box-shadow !default;
$popover-header-background-color: $dark-gray !default;
$popover-header-text-color: $white !default;
$popover-header-padding: $spacer-sm $spacer !default;
$popover-body-background-color: $white !default;
$popover-body-padding: $spacer !default;
$popover-border-radius: $border-radius !default;
$popover-max-width: 300px !default;


/*
* MODALS
*/
$modal-backdrop-background-color: rgba($off-black, 0.6) !default;
$modal-background-color: transparent !default;
$modal-border: none !default;
$modal-border-radius: $border-radius !default;
$modal-box-shadow: 0 2px 6px rgba($off-black, 0.1) !default;
$modal-max-width: 500px !default;
$modal-sm-max-width: 300px !default;
$modal-lg-max-width: 900px !default;
$modal-xl-max-width: 1140px !default;
$modal-margins-mobile: 8px !default;
$modal-margins-desktop: $spacer*2 auto !default;
$modal-display-desktop: flex !important;
$modal-display-mobile: block !important;
// Header
$modal-header-padding: $spacer*2 $spacer*2 $spacer;
$modal-header-background-color: $white;
$modal-header-text-color: $brand-primary;
$modal-header-border-color: transparent !default;
$modal-header-title-size: 4 !default; // This number should be 1-6, corresponding with h1-h6 headers
$modal-header-close-icon-size: 14px !default;
// Body
$modal-body-padding: $spacer $spacer*2;
$modal-body-background-color: $white !default;
$modal-body-text-color: $base-font-color !default;
// Footer
$modal-footer-padding: $spacer $spacer*2 $spacer*2;
$modal-footer-background-color: $white !default;
$modal-footer-text-color: $base-font-color !default;
$modal-footer-border-color: transparent !default;



/*
* ALERTS (also affects toast messages)
*/
$alert-border-radius: $border-radius !default;
$alert-padding: $spacer !default;
$alert-success-color: $brand-primary;
$alert-success-background: $success;
$alert-success-text: $brand-primary;
$alert-warning-color: $brand-primary;
$alert-warning-background: $warning;
$alert-warning-text: $brand-primary;
$alert-danger-color: $brand-primary;
$alert-danger-background: $danger;
$alert-danger-text: $white;
$alert-info-color: $brand-primary;
$alert-info-background: $info;
$alert-info-text: $brand-primary;


/*
* TOAST MESSAGES
*/
$toast-padding: $spacer !default;
$toast-box-shadow: 0 2px 4px rgba($off-black, 0.4) !default;


/*
* CARDS
*/
$card-border-radius: $border-radius !default;
$card-border-color: $light-gray !default;
$card-header-background: $light-gray;
$card-header-color: $brand-primary !default;
$card-header-border-color: $light-gray !default;
$card-header-padding-top: $spacer*2;
$card-header-padding-right: $spacer;
$card-header-padding-bottom: $spacer*2;
$card-header-padding-left: $spacer;
$card-header-padding-top-lg: $spacer*2;
$card-header-padding-right-lg: $spacer*2;
$card-header-padding-bottom-lg: $spacer;
$card-header-padding-left-lg: $spacer*2;
$card-header-font-family: $primary-font !default;
$card-header-font-size: 16px !default;
$card-header-line-height: 24px;
$card-header-letter-spacing: .01em !default;
$card-header-text-transform: none !default;
$card-header-font-weight: bold !default;
$card-body-font-size: $default-text-font-size !default;
$card-body-line-height: $default-text-line-height !default;
$card-body-letter-spacing: $default-text-letter-spacing !default;
$card-body-background: $light-gray;
$card-body-border-color: $light-gray !default;
$card-body-color: $base-font-color !default;
$card-body-padding-bottom: $spacer*2;
$card-body-padding-left: $card-header-padding-left;
$card-body-padding-right: $card-header-padding-right;
$card-body-padding-top: 32px !default;
$card-body-padding-bottom-lg: $spacer*2;
$card-body-padding-left-lg: $card-header-padding-left-lg;
$card-body-padding-right-lg: $card-header-padding-right-lg;
$card-body-padding-top-lg: 0 !default;
$card-no-header-body-padding-top: $card-header-padding-top;
$card-no-header-body-padding-top-lg: $card-header-padding-top-lg;
$card-footer-background: $light-gray;
$card-footer-color: $card-header-color !default;
$card-footer-border-color: $card-header-border-color !default;
$card-footer-padding: $card-header-padding-top $card-header-padding-right $card-header-padding-bottom $card-header-padding-left !default;
$card-ghost-opacity: 0.5 !default;
$card-form-value-color: $dark-gray !default;
$card-header-border-bottom-width: 0 !default;
$card-margin-bottom: $spacer !default;
$card-wishlist-remove-btn-margin-top: 5px !default;
$card-wishlist-remove-btn-margin-right: 0px !default;
$card-wishlist-remove-btn-margin-bottom: 0px !default;
$card-wishlist-remove-btn-margin-left: $spacer;
$card-wishlist-border-bottom: 1px solid $light-gray !default;
$card-wishlist-margin-bottom: $spacer;
$card-wishlist-padding-bottom: $card-header-padding-bottom;

// 'aside' card variation - used on PLP refinements
$card-aside-header-padding-top: $spacer;
$card-aside-header-padding-right: $card-header-padding-right !default;
$card-aside-header-padding-bottom: $spacer;
$card-aside-header-padding-left: 0 !default;
$card-aside-header-font-size: 13px !default;
$card-aside-header-font-weight: bold !default;
$card-aside-header-line-height: 18px !default;
$card-aside-body-padding: 0 !default;
$card-aside-border-width: 1px 0 !default;
$card-aside-border-color: $card-border-color !default;
$card-aside-body-border: none !default;
$card-aside-footer-border: none !default;

/*
* COLLAPSIBLE
*/
$collapsible-border-color: $card-header-border-color !default;
$collapsible-header-background: $white;
$collapsible-header-color: $card-header-color !default;
$collapsible-header-icon-color: $off-black !default;
$collapsible-header-icon-size: 12px !default;
$collapsible-body-background: $white;
$collapsible-body-color: $card-body-color !default;
$collapsible-header-icon-expand: $icon-expand;
$collapsible-header-icon-close: $icon-collapse;


/*
* TABS
*/
$tab-font: 14px $primary-font !default;
$tab-text-transform: none !default;
$tab-font-weight: bold !default;
$tab-letter-spacing: 0.05em !default;
$tab-border-color: $light-gray !default;
$tab-border-width: 1px !default;
$tab-border-radius-top-left: $border-radius !default;
$tab-border-radius-top-right: $border-radius !default;
$tab-border-radius-bottom-left: 0 !default;
$tab-border-radius-bottom-right: 0 !default;
$tab-padding-top: 15px !default;
$tab-padding-right: $spacer !default;
$tab-padding-bottom: 11px !default;
$tab-padding-left: $spacer !default;
$tab-text-decoration: none !default;
$tab-background: transparent !default;
$tab-color: $dark-gray !default;
$tab-border-top: none !default;
$tab-border-right: none !default;
$tab-border-bottom: $tab-border-width solid $tab-border-color !default;
$tab-border-left: none !default;
// hover state
$tab-text-decoration-hover: none !default;
$tab-background-hover: transparent !default;
$tab-color-hover: $brand-primary !default;
$tab-border-top-hover: none !default;
$tab-border-right-hover: none !default;
$tab-border-bottom-hover: $tab-border-width solid $tab-border-color !default;
$tab-border-left-hover: none !default;
// active state
$tab-text-decoration-active: none !default;
$tab-background-active: transparent !default;
$tab-color-active: $brand-primary !default;
$tab-border-top-active: $tab-border-width solid $tab-border-color !default;
$tab-border-right-active: $tab-border-width solid $tab-border-color !default;
$tab-border-bottom-active: none !default;
$tab-border-left-active: $tab-border-width solid $tab-border-color !default;


/*TAB MENU - NEW COMPONENT */
$tab-menu-background: $light-gray !default;

/*
* SLIDER
*/
// title
$slider-heading-size: 18; // This number should be 1-6, corresponding with h1-h6 headers
$slider-heading-text-align: center !default;
// nav buttons
$slider-button-prev-icon: $icon-arrow-left-thin !default;
$slider-button-next-icon: $icon-arrow-right-thin !default;
$slider-button-color: $brand-primary !default;
$slider-button-background: $white;
$slider-button-color-hover: $brand-primary;
$slider-button-background-hover: $white;
$slider-button-icon-size: 17px !default;
$slider-button-width: 48px;
$slider-button-margin: 20px;
$slider-button-shadow: 0 0 8px -4px rgba($black, 0.5);
// pagination bullets
$slider-bullet-size: 8px;
$slider-bullet-margin-top: $spacer !default;
$slider-bullet-margin-right: $spacer / 2;
$slider-bullet-margin-bottom: $spacer !default;
$slider-bullet-margin-left: $spacer / 2;
$slider-bullet-background: $medium-gray;
$slider-bullet-border: none !default;
$slider-bullet-background-active: $brand-primary;
$slider-bullet-border-active: none !default;
$slider-bullet-size-active: 12px !default;
$bullets-outside-size: $slider-bullet-size + $slider-bullet-margin-top + $slider-bullet-margin-bottom !default;

//pagination bullets - short lines
$slider-short-lines-bullet-background-color: $brand-tertiary !default;
$slider-short-lines-bullet-active-background-color: $brand-primary !default;
$slider-short-lines-bullet-size-width: 20px !default;
$slider-short-lines-bullet-size-height: 2px !default;
$slider-short-lines-bullet-active-size-height: 4px !default;

// pagination bullets - pause/play button
$slider-bullet-controller-size: 25px !default;
$slider-bullet-pause-icon-color: $light-gray !default;
$slider-bullet-play-icon-color: $light-gray !default;
$slider-bullet-pause-icon-color-dark: $primary-black !default;
$slider-bullet-play-icon-color-dark: $primary-black !default;
$slider-bullet-controller-inset-amount: 0 !default;
$slider-bullet-controller-padding: 5px !default;
$slider-bullet-with-pagination-padding-right: 5px !default;
$slider-bullet-top-controller-inset-amount: 50%;
$slider-bullet-translate-controller-inset-amount: translateY(-50%);

// thumbnail nav
$slider-thumbnail-prev-icon: $slider-button-prev-icon !default;
$slider-thumbnail-next-icon: $slider-button-next-icon !default;
$slider-thumbnail-button-color: $off-black !default;
$slider-thumbnail-button-color-hover: $off-black !default;
$slider-thumbnail-button-background-color: $page-background-color !default;
$slider-thumbnail-button-background-color-hover: $page-background-color !default;
$slider-thumbnail-button-size: 11px !default;
$slider-thumbnail-button-icon-size: 12px !default;
$slider-thumbnail-border-width: 0 !default;
$slider-thumbnail-border-color: transparent !default;
$slider-thumbnail-border-active-width: 0;
$slider-thumbnail-border-active-color: $off-black !default;
$slider-thumbnail-spacing: $spacer / 2;
$slider-thumbnail-width: 85px;
$slider-thumbnail-width-md: 85px;
$slider-thumbnail-width-lg: 85px;
$slider-thumbnail-play-icon-size: 26px !default;

// dots for thumbnails
$slider-thumbnail-dots-border-radius: 50% !default;

// light theme
$slider-light-button-color: rgba($white, 0.7) !default;
$slider-light-button-background: $navy !default;
$slider-light-button-color-hover: $white !default;
$slider-light-button-background-hover: $navy !default;
$slider-light-bullet-background: $medium-gray !default;
$slider-light-bullet-border: none !default;
$slider-light-bullet-background-active: $navy !default;
$slider-light-bullet-border-active: none !default;
$slider-light-short-lines-bullet-background-color: rgba($white, 0.5) !default;
$slider-light-short-lines-bullet-active-background-color: $white !default;
$slider-light-bullet-pause-icon-color: $white !default;
$slider-light-bullet-play-icon-color: $white !default;


// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;


/*
* DROPDOWNS
*/
$dropdown-menu-border: 1px solid $light-gray !default;
$dropdown-menu-border-radius: $border-radius !default;
$dropdown-menu-shadow: 0 2px 2px rgba($off-black, 0.2) !default;
$dropdown-menu-padding: 0 0 $spacer 0;
$dropdown-megamenu-border: none !default;
$dropdown-icon: $icon-expand !default;
$dropdown-icon-size: 10px !default;
$dropdown-item-color: $link-color !default;
$dropdown-item-border-color: $light-gray !default;
$dropdown-item-hover-background: $light-gray !default;
$dropdown-item-hover-text-decoration: underline;
$dropdown-item-hover-text-decoration-mobile: none;
$dropdown-item-hover-color: $brand-primary;
$dropdown-item-padding: $spacer;
$dropdown-item-padding-top: $spacer;
$dropdown-item-padding-bottom: 11px;
$dropdown-fadein-speed: $transition-speed !default; // set to zero to disable fade effect
$dropdown-font-size: 14px !default;

/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
$nav-link-font-family: $primary-font !default;
$nav-link-font-size: 16px;
$nav-link-font-weight: 500 !default;
$nav-link-line-height: 24px !default;
$nav-link-letter-spacing: 0 !default;
$nav-link-text-transform: none !default;
$nav-link-color: $brand-primary !default;
$nav-link-text-decoration: none !default;
$nav-link-text-decoration-hover: none !default;
$nav-sub-link-font-size: 14px !default;

$nav-link-font-size-s: 20px;
$nav-link-line-height-s: 28px !default;
$nav-link-font-weight-active: 700;


$nav-link-font-size-m: 22px;
$nav-link-line-height-m: 26px !default;

/*
* HEADER
*/
// Promo bar
$header-promo-bar-background: $primary-black;
$header-promo-bar-color: #BFBFBF;
$header-promo-bar-padding-left: $spacer/2 !default;
$header-promo-bar-padding-right: $spacer/2 !default;
$header-promo-bar-padding-top: $spacer/2 !default;
$header-promo-bar-padding-bottom: $spacer/2 !default;
$header-promo-bar-padding: $header-promo-bar-padding-top $header-promo-bar-padding-right $header-promo-bar-padding-bottom $header-promo-bar-padding-left;
$header-promo-bar-font-size: $default-text-font-size;
$header-promo-bar-font-family: $primary-font;
$header-promo-bar-link-font-size: $default-text-font-size;
$header-promo-bar-line-height: $default-text-line-height;


// Main header
$header-background: $white !default;
$header-fixed-shadow: $dropdown-menu-shadow !default;
$header-height: 60px !default;
$header-height-md: 60px !default;
$header-height-lg: 60px !default;
$header-padding: 0;
$header-desktop-padding: 24px 0 8px 0;
$header-logo-image: '../images/logo.svg' !default;
$header-logo-image-fixed: $header-logo-image !default;
$header-logo-image-md: $header-logo-image !default;
$header-logo-image-md-fixed: $header-logo-image !default;
$header-logo-image-lg: $header-logo-image !default;
$header-logo-image-lg-fixed: $header-logo-image !default;
$header-logo-height: 100% !default;
$header-logo-width: 160px !default;
$header-logo-width-md: 212px !default;
$header-logo-width-lg: 212px !default;
$header-logo-transparent-nav-brightness: 0 !default;
$header-logo-transparent-nav-invert: 1 !default;
$header-transition-speed: 400ms !default;
$header-link-color: $nav-link-color !default;
$header-link-color-hover: $off-black !default;
$header-link-highlight-color: $red !default;
$header-link-highlight-color-hover: $brand-secondary !default;
$header-link-border-color: transparent !default;
$header-link-font: $nav-link-font-family !default;
$header-link-font-size: 14px !default;
$header-link-top-level-font-size: $header-link-font-size !default;
$header-link-font-weight: $nav-link-font-weight !default;
$header-link-line-height: $nav-link-line-height !default;
$header-link-letter-spacing: .05em !default;
$header-link-underline-position-top: 100% !default;
$header-link-underline-color: $brand-primary !default; // hover state for left-align logo desktop view only, set to transparent to disable
$header-link-underline-height: 1px !default;
$header-link-text-transform: $nav-link-text-transform !default;
$header-link-font-size-logo-center: 14px !default;
$header-search-desktop-width: 125px !default;
$header-search-desktop-width-focus: 200px !default;
$header-search-desktop-expanded-border-color: transparent;
$header-search-desktop-border-width: 0px;
$header-search-icon-font-size: 22px !default;
$header-search-width: 22px !default;
$header-search-height: 22px !default;
$header-search-width-md: 25px !default;
$header-search-height-md: 25px !default;
$header-search-icon-svg: '../images/icons/Search.svg' !default;
$header-search-icon-svg-brightness: 0 !default;
$header-search-icon-svg-invert: 1 !default;
$header-search-icon-color: $header-link-color !default;
$header-search-icon-color-hover: transparent;
$header-search-text-font-size: 16px !default; //minimum of 16px required to prevent zooming on iphones
$header-minicart-count-background: $cobalt !default;
$header-minicart-count-color: $white !default;
$header-link-dropdown-text-align: left; // used for account + support
$header-link-dropdown-menu-border: none !default;
$header-link-dropdown-menu-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08) !default;
$header-link-dropdown-menu-padding: 16px 0 !default;
$header-link-dropdown-menu-font-size: 11px !default;
$header-link-dropdown-menu-li-padding: 8px !default;

$header-transparent-background: transparent !default;
$header-transparent-border-bottom: transparent !default;
$header-transparent-link-color: transparent !default;
$header-transparent-link-color-hover: transparent !default;
$header-transparent-search-desktop-expanded-border-color: transparent !default;

$header-transparent-text-color-light: $white !default;
$header-transparent-text-color-dark: $navy !default;
$header-transparent-minicart-count-background-color-light: $off-black !default;
$header-transparent-minicart-count-background-color-dark: $red !default;

// Checkout header
$header-checkout-locale-display: block !default;
$header-checkout-account-display: block !default;
$header-checkout-support-display: block !default;
$header-checkout-minicart-display: block !default;
// Main navbar
$header-navbar-border-bottom-width: 0px;
$header-navbar-border-bottom-color: transparent;
$header-navbar-link-spacing: 36px !default; // space between top-level menu items
$header-navbar-dropdown-icon: none !default; // desktop only - mobile falls back to standard dropdown icon ($dropdown-icon)
$header-navbar-dropdown-icon-size: 10px !default;
$header-navbar-account-icon: $icon-account !default;
$header-navbar-account-icon-size: 22px !default;
$header-navbar-account-font-size: 0 !default; // set to 0 if you only want an icon to show for account desktop only
$header-navbar-account-margin: 8px 0 0 !default;
$header-navbar-background: transparent; // center-logo desktop layout only
$header-navbar-color: $brand-primary; // center-logo desktop layout only
$header-navbar-background-hover: transparent !default; // center-logo desktop layout only
$header-navbar-color-hover: $brand-primary; // center-logo desktop layout only
$header-navbar-toggler-icon-size: 18px !default; // hamburger menu icon size, mobile only
$header-navbar-highlight-color: $brand-primary !default; // center-logo desktop layout only
$header-navbar-highlight-background-hover: $white !default; // center-logo desktop layout only
$header-navbar-highlight-color-hover: $brand-secondary !default; // center-logo desktop layout only
// Megamenu
$megamenu-background: $white !default;
$megamenu-box-shadow: $header-fixed-shadow !default;
$megamenu-padding-top-bottom: 30px !default;
$megamenu-padding: $megamenu-padding-top-bottom $spacer !default;
$megamenu-header-font: $header-link-font !default;
$megamenu-header-font-size: $nav-link-font-size !default;
$megamenu-header-font-weight: bold !default;
$megamenu-header-line-height: $nav-link-line-height !default;
$megamenu-header-text-transform: none !default;
$megamenu-header-color: $brand-primary !default;
$megamenu-header-color-hover: $brand-primary !default;
$megamenu-title-margin-bottom: 30px !default;
$megamenu-title-text-decoration: none !default;
$megamenu-secondary-color: $link-color !default;
$megamenu-secondary-color-hover: $brand-secondary !default;
$megamenu-secondary-font: $secondary-font !default;
$megamenu-secondary-font-size: $nav-sub-link-font-size !default;
$megamenu-secondary-font-weight: 500 !default;
$megamenu-secondary-text-transform: none !default;
$megamenu-secondary-indentation: 2px !default;
$megamenu-link-margin-top: 0 !default;
$megamenu-link-margin-bottom: $spacer !default;
$megamenu-link-padding-top: $spacer/2 !default;
$megamenu-link-padding-bottom: $spacer*2 !default;
$megamenu-link-padding-left: 0 !default;
$megamenu-link-padding-right: 0 !default;
$megamenu-asset-link-list-font-family: $megamenu-header-font !default;
$megamenu-asset-link-list-font-size: 18px !default;
$megamenu-asset-link-list-text-transform: none !default;
$megamenu-asset-link-list-padding: $megamenu-link-padding-top $megamenu-link-padding-right $megamenu-link-padding-bottom $megamenu-link-padding-left !default;
$megamenu-asset-link-list-text-decoration: none !default;
//Megamenu Left nav — Styles for mega-left megamenu only
$megamenu-left-nav-width: 15% !default;
$megamenu-left-nav-border-width: 2px !default;
$megamenu-left-nav-border-color: $off-white !default;
$megamenu-left-nav-border-color-hover: $brand-secondary !default;
$megamenu-left-nav-font-size: $megamenu-asset-link-list-font-size !default;
$megamenu-left-nav-text-transform: uppercase !default;
$megamenu-left-nav-tertiary-title-font-size: $megamenu-header-font-size !default;
$megamenu-left-nav-tertiary-title-font-weight: $megamenu-header-font-weight !default;
$megamenu-left-nav-tertiary-title-text-transform: $megamenu-header-text-transform !default;
// Search suggestions
$header-suggestions-results-width: 350px !default;
$header-suggestions-image-size: 30px !default;
$header-suggestions-header-font: $header-font !default;
$header-suggestions-header-font-size: 16px !default;
$header-suggestions-header-color: $brand-primary;
$header-suggestions-header-text-transform: uppercase;
$header-suggestions-border: none;
$header-suggestions-item-font: $body-font !default;
$header-suggestions-item-font-size: $default-text-font-size !default;
$header-suggestions-item-category-parent-color: $gray !default;

/*
* FOOTER
*/
$footer-background: $white !default;
$footer-border-top-color: $white;
$footer-border-top-width: 0px;
$footer-padding-bottom: 60px !default;
$footer-padding-top: 72px !default;
$footer-margin-top: 100px !default;
$footer-title-font-size: 16px;
$footer-title-line-height: 24px;
$footer-title-font-weight: normal;
$footer-link-font-size: $nav-link-font-size !default;
$footer-title-font-color: $nav-link-color !default;
$footer-title-font-family: $secondary-font;
$footer-title-text-transform: $nav-link-text-transform !default;
$footer-link-color: $link-color !default;
$footer-link-height: auto;
$footer-icon-size: 25px;
$footer-copyright-font-size: 12px !default;
$footer-copyright-line-height: 16px !default;
$footer-copyright-color: $dark-gray !default;
$footer-social-links-margin: 20px 23px 20px 0px;
$footer-backtotop-color: $brand-primary !default;
$footer-hr-margin: 72px 0 10px 0 !default;
$footer-hr-color: transparent !default;
$footer-signup-font-size: 16px !default;
$footer-signup-line-height: 22px !default;
$footer-signup-letter-spacing: .01em !default;


/*
* PAGE-LEVEL SETTINGS
*/
$page-header-padding-top: $spacer !default;
$page-header-padding-bottom: $spacer !default;
$page-header-padding-top-md: 10px;
$page-header-padding-bottom-md: 18px;


/*
* PRODUCT
*/
// Price
$product-strike-through-price-color: $brand-primary !default;
$product-sale-price: $red !default;

// Ratings
$product-rating-star-icon-font: $icomoon-font-family !default;
$product-rating-star-color: $brand-primary;
$product-rating-star-empty-color: $medium-gray;

// Color swatches - Default type (patterns and colors)
$color-swatch-border-radius: 50% !default;
$color-swatch-hover-shadow-size: 4px;
$color-swatch-hover-shadow-color: $brand-primary;
$color-swatch-hover-shadow: 0 0 0 calc(#{$color-swatch-hover-shadow-size} - 1px) $page-background-color, 0 0 0 $color-swatch-hover-shadow-size $color-swatch-hover-shadow-color !default;
$color-swatch-selected-shadow-color: $brand-primary;
$color-swatch-selected-shadow-size: 4px;
$color-swatch-selected-shadow: 0 0 0 calc(#{$color-swatch-selected-shadow-size} - 1px) $page-background-color, 0 0 0 $color-swatch-selected-shadow-size $color-swatch-selected-shadow-color !default;
$color-swatch-selected-icon-display: none !default; // use 'block' to show icon when swatch is selected, 'none' to hide
$color-swatch-selected-icon: $icon-checkmark !default;
$color-swatch-selected-icon-size: 14px !default;
$color-swatch-selected-icon-color: $white !default;
$color-swatch-selected-icon-background: rgba($off-black, 0.7) !default;

// Color swatches - Thumbnail type (product image)
$color-swatch-thumbnail-border-radius: 0 !default;
$color-swatch-thumbnail-hover-shadow-color: $dark-gray !default;
$color-swatch-thumbnail-hover-shadow-size: 2px !default;
$color-swatch-thumbnail-hover-shadow: inset 0px -#{$color-swatch-thumbnail-hover-shadow-size} 0px $color-swatch-thumbnail-hover-shadow-color !default;
$color-swatch-thumbnail-selected-shadow-color: $off-black !default;
$color-swatch-thumbnail-selected-shadow-size: 2px !default;
$color-swatch-thumbnail-selected-shadow: inset 0px -#{$color-swatch-thumbnail-selected-shadow-size} 0px $color-swatch-thumbnail-selected-shadow-color !default;
$color-swatch-thumbnail-selected-icon-display: none !default; // use 'block' to show icon when swatch is selected, 'none' to hide
$color-swatch-thumbnail-selected-icon: $icon-checkmark !default;
$color-swatch-thumbnail-selected-icon-size: 14px !default;
$color-swatch-thumbnail-selected-icon-color: $white !default;
$color-swatch-thumbnail-selected-icon-background: rgba($off-black, 0.7) !default;

// Non-color swatches (size, width, etc.)
$non-color-swatch-background: $light-gray;
$non-color-swatch-color: $brand-primary;
$non-color-swatch-border-radius: $spacer/2;
$non-color-swatch-border-width: 2px;
$non-color-swatch-border-color: $light-gray;
$non-color-swatch-padding: $spacer;
$non-color-swatch-hover-border-color: $light-gray;
$non-color-swatch-hover-box-shadow: none !default;
$non-color-swatch-selected-border-color: $brand-primary;
$non-color-swatch-selected-box-shadow: none !default;
$non-color-swatch-description-font-size: 12px !default;
$non-color-swatch-description-font-color: $gray !default;
$non-color-swatch-selected-background: $light-gray;
$non-color-swatch-selected-color: $off-black !default;
$non-color-swatch-selected-icon-display: none !default;
$non-color-swatch-selected-icon: $icon-checkmark !default;
$non-color-swatch-selected-icon-size: 16px !default;
$non-color-swatch-selected-icon-background-color: $brand-primary !default;
$non-color-swatch-selected-icon-color: $white !default;

// PDP
$pdp-name-header-value: 3 !default;
$pdp-name-header-color: $brand-primary;
$pdp-price-font-size: 16px;
$pdp-price-color: $brand-primary;
$pdp-price-font: $secondary-font;
$pdp-product-number-font-size: 13px !default;
$pdp-product-number-color: $dark-gray !default;
$pdp-rating-star-size: 16px;
$pdp-rating-star-size-large: 20px !default;
$pdp-rating-star-size-large-md: $pdp-rating-star-size-large !default;
$pdp-reviews-link-display: none !default;
$pdp-reviews-link-font-size: 13px !default;
$pdp-reviews-link-color: $light-gray !default;
$pdp-reviews-link-color-hover: $dark-gray !default;
$pdp-right-column-spacing-md: $spacer !default; // desktop only (additional space between images and data
$pdp-right-column-spacing-lg: $spacer; // ""
$pdp-right-column-spacing-xl: $spacer; // ""
$pdp-attribute-vertical-spacing: 24px !default;
$pdp-attribute-vertical-spacing-md: 24px !default;
$pdp-attribute-vertical-spacing-lg: 31px;
$pdp-color-swatch-size: 44px;
$pdp-color-swatch-width: 72px;
$pdp-color-swatch-height: 72px;
$pdp-color-swatch-spacing: $spacer;
$pdp-color-swatch-thumbnail-size: 30px !default;
$pdp-color-swatch-thumbnail-spacing: 1px !default;
$pdp-non-color-swatch-size: $pdp-color-swatch-size;
$pdp-non-color-swatch-min-width: 181px;
$pdp-non-color-swatch-spacing: $spacer/2;
$pdp-add-to-cart-button-icon: $icon-content-blank !default;
$pdp-promo-color: $base-font-color !default;
$pdp-social-icon-color: $link-color !default;
$pdp-social-icon-color-hover: $link-color-hover !default;
$pdp-social-icon-size: 30px !default;
$pdp-social-icon-spacing: 30px !default;
$pdp-description-detail-header-value: 5;
$pdp-description-detail-divider: 1px solid $border-color !default; // only applies when these are collapsible
$pdp-description-detail-vertical-spacing: $spacer !default;
$pdp-description-detail-vertical-spacing-md: 30px !default;
$pdp-description-detail-vertical-spacing-lg: 60px !default;
$pdp-tabs-vertical-spacing: $spacer !default;
$pdp-tabs-vertical-spacing-md: 30px !default;
$pdp-tabs-vertical-spacing-lg: 60px !default;
$pdp-asset-max-width: 700px !default;
$pdp-tabs-header-value: 6 !default;
$pdp-image-gallery-background-color: $light-gray;

// PDP STICKY ADD TO CART
$pdp-sticky-addtocart-background: $white !default;
$pdp-sticky-addtocart-opacity: 1 !default;
$pdp-sticky-addtocart-open-transition: transform $transition-speed linear !default;
$pdp-sticky-addtocart-shadow: $dropdown-menu-shadow !default;
$pdp-sticky-addtocart-name-header-value: 4 !default;
$pdp-sticky-addtocart-color-swatch-size: $pdp-color-swatch-size !default;
$pdp-sticky-addtocart-color-swatch-spacing: 10px !default;
$pdp-sticky-addtocart-color-swatch-thumbnail-size: $input-sm-height !default;
$pdp-sticky-addtocart-color-swatch-thumbnail-spacing: $pdp-color-swatch-thumbnail-spacing !default;
$pdp-sticky-addtocart-attribute-spacing: $spacer !default;
$pdp-sticky-addtocart-attribute-select-font-size: $input-sm-font-size !default;
$pdp-sticky-addtocart-attribute-select-line-height: $input-sm-font-line-height !default;
$pdp-sticky-addtocart-attribute-select-padding: $input-sm-padding-top $select-sm-padding-right $input-sm-padding-bottom $input-sm-padding-left !default;
$pdp-sticky-addtocart-attribute-select-height: $input-sm-height !default;
$pdp-sticky-addtocart-button-font-size: $button-sm-font-size !default;
$pdp-sticky-addtocart-button-line-height: $button-sm-line-height !default;
$pdp-sticky-addtocart-button-padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left !default;
$pdp-sticky-addtocart-button-letter-spacing: $button-sm-letter-spacing !default;

// QUICKVIEW
$quickview-selected-bonus-product-font-size: 10px !default;
$quickview-selected-bonus-product-font-size-sm: 16px !default;

// PRODUCT CARDS (order detail, checkout summary, order confirmation)
$product-card-image-size-sm: 100px !default;
$product-card-image-size-lg: 176px !default; // used on cart page large screens
$product-card-product-name-font-family: $primary-font !default;
$product-card-product-name-font-size: normal !default; // use small, normal, or large, corresponding with the paragraph-font-size mixin params
$product-card-product-name-text-transform: none !default;
$product-card-product-name-font-weight: normal !default;
$product-card-product-name-text-decoration: none !default;
$product-card-attribute-size: $default-text-font-size !default;
$product-card-attribute-color: $black!default;
$product-card-border-color: $card-body-border-color !default;
$product-card-item-border-width: 1px !default;
$product-card-price-qty-row-background: $white !default;
$product-card-price-qty-row-border-color: $card-body-border-color !default;
$product-card-remove-icon: $icon-close !default;
$product-card-remove-icon-size: 11px !default;
$product-card-remove-icon-background: $white !default;
$product-card-promo-color: $green !default;
$product-card-promo-font-size: $default-text-font-size !default;

// PLP - PRODUCT GRID
$plp-name-header-display-with-slot: none !default;
$product-refinement-font-size: $radiocheck-label-font-size !default;
$product-refinement-swatch-size: 20px !default;
$product-refinement-swatch-img-size: 30px !default;
$product-refinement-swatch-border: 1px solid rgba($off-black, 0.3) !default;
$product-refinement-swatch-spacing: 12px !default;
$product-refinement-non-color-swatch-min-width: 44px !default;
$product-refinement-non-color-swatch-spacing: 10px !default;
$product-refinements-drawer-width: calc(100% - 50px) !default;
$product-refinements-drawer-max-width: $header-suggestions-results-width !default; // mobile only
$product-refinements-drawer-padding-bottom: 200px !default;
$product-refinement-secondary-link-text-decoration: none !default;
$product-refinement-secondary-link-padding: 8px 0 !default;
$product-refinement-secondary-link-line-height: $default-text-line-height !default;
$product-refinement-header-padding: 20px !default;
$product-refinement-header-background-color: $modal-header-background-color !default;
$product-refinement-header-border-color: $modal-header-border-color !default;
$product-refinement-header-color: $white !default;
$product-refinement-header-title-size: 4 !default;
$product-refinement-value-padding-bottom: 20px !default;
$product-refinement-value-grid-padding-bottom: 10px !default;
$product-filter-font-size: 14px !default;
$product-filter-sort-arrow-size: 12px !default;
$product-filter-font-weight: normal !default;
$product-filter-color: $black !default;
$product-filter-border: 1px solid $medium-gray !default;
$product-filter-border-radius: $border-radius !default;
$product-filter-padding-top: 9px !default;
$product-filter-padding-right: $spacer*2;
$product-filter-padding-bottom: 8px !default;
$product-filter-padding-left: $spacer;
$product-filter-background: $light-gray;
$product-filter-icon-size: 8px !default;
$product-filter-spacing: $spacer-sm !default;
$product-filter-swatch-size: $product-filter-font-size + $product-filter-padding-top + $product-filter-padding-bottom + 2px !default;
$product-miscellaneous-swatch-background: linear-gradient(0deg, rgba(130, 30, 145, 1) 0, rgba(130, 30, 145, 1) 25%, rgba(237, 209, 52, 1) 25%, rgba(255, 255, 0, 1) 50%, rgba(237, 209, 52, 1) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgba(14, 92, 209, 1) 0, rgba(14, 92, 209, 1) 50%, rgba(226, 11, 11, 1) 50%, rgba(226, 11, 11, 1) 100%) !default;

$product-refinement-horizontal-padding-top: $spacer !default;
$product-refinement-horizontal-padding-bottom: $spacer !default;
$product-refinement-horizontal-dropdown-menu-padding: ($spacer*1.5+$border-radius*2) 0 $spacer*3;
$product-refinement-horizontal-dropdown-menu-max-width: 300px !default;

$product-refinement-horizontal-onedropdown-border-top: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-border-bottom: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-border-right: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-border-left: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-margin-top: 15px !default;
$product-refinement-horizontal-onedropdown-max-width: map-get($grid-breakpoints, xl) - map-get($grid-gutter-widths, xl) !default;
$product-refinement-horizontal-onedropdown-refinement-padding-top: 15px !default;
$product-refinement-horizontal-onedropdown-refinement-padding-bottom: 15px !default;
$product-refinement-horizontal-onedropdown-btn-padding-top: 6px !default;
$product-refinement-horizontal-onedropdown-btn-padding-bottom: 6px !default;
$product-refinement-horizontal-onedropdown-btn-padding-right: 10px !default;
$product-refinement-horizontal-onedropdown-btn-padding-left: 12px !default;
$product-refinement-horizontal-onedropdown-btn-border-top: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-btn-border-bottom: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-btn-border-right: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-btn-border-left: 1px solid $border-color !default;
$product-refinement-horizontal-onedropdown-btn-background-color: transparent !default;
$product-refinement-horizontal-onedropdown-btn-width: 150px !default;
$product-refinement-horizontal-onedropdown-sortby-btn-width: 150px !default;
$product-refinement-horizontal-onedropdown-filterby-btn-width: 12px !default;
$product-refinement-horizontal-onedropdown-filterby-btn-height: 12px !default;
$product-refinement-horizontal-onedropdown-sticky-bar-left: 0 !default;
$product-refinement-horizontal-onedropdown-sticky-bar-right: 0 !default;
$product-refinement-horizontal-onedropdown-sticky-bar-zindex: $z-index-header !default;
$product-refinement-horizontal-onedropdown-sticky-bar-padding: $spacer !default;
$product-refinement-horizontal-onedropdown-sticky-bar-background-color: $white !default;
$product-refinement-horizontal-onedropdown-sticky-bar-border-bottom: none!default;

$product-refinement-horizontal-multipledropdowns-btn-border-top: 1px solid $border-color !default;
$product-refinement-horizontal-multipledropdowns-btn-border-bottom: 1px solid $border-color !default;
$product-refinement-horizontal-multipledropdowns-btn-border-left: 1px solid $border-color !default;
$product-refinement-horizontal-multipledropdowns-btn-border-right: 1px solid $border-color !default;
$product-refinement-horizontal-multipledropdowns-btn-padding-top: 6px !default;
$product-refinement-horizontal-multipledropdowns-btn-padding-bottom: 6px !default;
$product-refinement-horizontal-multipledropdowns-btn-padding-left: 12px !default;
$product-refinement-horizontal-multipledropdowns-btn-padding-right: 10px !default;
$product-refinement-horizontal-multipledropdowns-height: calc(16px + 12px + 14px + (1px*2)) !default;
$product-refinement-horizontal-multipledropdowns-margin-right: 10px !default;
$product-refinement-horizontal-multipledropdowns-margin-bottom: 0 !default;
$product-refinement-horizontal-multipledropdowns-min-width: 150px !default;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-min-width: 100vw !default;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-margin-top: 0 !default;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-btn-padding-bottom: 20px !default;
$product-refinement-filter-button-height: 33px !default;
$product-refinement-filter-button-width: 33px !default;
$product-refinement-filter-button-border-radius: 50% !default;
$product-refinement-filter-button-border: 2px solid $white !default;

// PRODUCT TILES (product grid, recommended sliders)
$product-tile-grid-margin-bottom: 45px !default;
$product-tile-image-hover-shadow-color: $off-black !default;
$product-tile-image-hover-shadow-size: 0;
$product-tile-image-hover-shadow: inset 0px -#{$product-tile-image-hover-shadow-size} 0px $product-tile-image-hover-shadow-color !default;
$product-tile-image-margin-bottom: 10px !default;
$product-tile-swatch-size: 18px !default;
$product-tile-swatch-spacing: 8px !default;
$product-tile-swatch-thumbnail-size: 30px !default;
$product-tile-swatch-thumbnail-spacing: 1px !default;
$product-tile-swatch-margin-bottom: 10px !default;
$product-tile-swatch-color-count-color: $brand-secondary !default;
$product-tile-title-font: $secondary-font;
$product-tile-title-font-size: 16px !default;
$default-product-tile-line-height: 24px  !default;
$product-tile-title-font-weight: normal !default;
$product-tile-title-text-decoration: none !default;
$product-tile-title-text-decoration-hover: none !default;
$product-tile-title-color: $base-font-color !default;
$product-tile-title-color-hover: $base-font-color !default;
$product-tile-title-margin-bottom: 4px !default;
$product-tile-medium-title-margin-bottom: 8px !default;
$product-tile-price-font-size: 13px !default;
$product-tile-price-color: $base-font-color !default;
$product-tile-price-strike-through-color: $product-strike-through-price-color !default;
$product-tile-price-margin-bottom: 10px !default;
$product-tile-sale-color: $sale !default;
$product-tile-rating-margin-bottom: 20px !default;
$product-tile-rating-star-color: $product-rating-star-color !default;
$product-tile-rating-star-size: 11px !default;
$product-tile-reviews-link-display: none !default;
$product-tile-reviews-link-font-size: 11px !default;
$product-tile-reviews-link-color: $light-gray !default;
$product-tile-reviews-link-color-hover: $dark-gray !default;
$product-tile-quickview-link-icon: $icon-plus !default;
$product-tile-quickview-link-size: 30px !default; // Quickview link appears on desktop only
$product-tile-quickview-link-icon-size: 14px !default;
$product-tile-quickview-link-color: rgba($off-black, 0.7) !default;
$product-tile-quickview-link-background-color: rgba($white, 0.7) !default;
$product-tile-quickview-link-box-shadow: none !default;
$product-tile-wishlist-link-icon: $icon-favorite !default;
$product-tile-wishlist-link-icon-selected: $icon-favorite-selected !default;
$product-tile-wishlist-link-size: 30px !default;
$product-tile-wishlist-link-icon-size: 16px !default;
$product-tile-wishlist-link-color: $brand-primary;
$product-tile-wishlist-link-background-color: transparent;
$product-tile-wishlist-link-box-shadow: none !default;
$product-tile-wishlist-link-border-radius: 50% !default;

// PRODUCT TILES (badges)
$product-tile-badges-margin: 1px !default;
$product-tile-badge-padding: 2px 0px !default;
$product-tile-badge-padding-lg: 12px 12px;
$product-tile-badge-margin-bottom: 1px !default;
$product-tile-badge-font-weight: bold !default;
$product-tile-badge-font-size: 12px;
$product-tile-badge-font-size-lg: $default-text-font-size !default;
$product-tile-badge-text-align: left !default;
$product-tile-badge-line-height: $body-copy-xxs-line-height !default;
$product-tile-badge-font-family: $body-font !default;
$product-tile-badge-background: transparent;
$product-tile-badge-color: $primary-black;
$product-tile-badge-border-radius: $border-radius !default;
$product-tile-badge-border-width: 1px !default;
$product-tile-badge-border-style: solid !default;
$product-tile-badge-border-color: transparent !default;
$product-tile-background-color: $light-gray;
$product-tile-radius: $border-radius;
$product-tile-padding: 45px $spacer/2;
$product-tile-padding-top-bottom: 53px;
$product-tile-padding-right-left: $spacer;
$product-tile-pill-padding: 0 9px;

/*
* MINICART
*/
$minicart-size-mobile: 350px !default;
$minicart-size: 437px !default;
$minicart-totals-font-family: $primary-font !default;
$minicart-totals-font-size: small !default; // use small, normal, or large, corresponding with the paragraph-font-size mixin params
$minicart-totals-font-weight: bold !default;
$minicart-totals-text-transform: none !default;
$minicart-back-icon-svg: '../images/icons/back-arrow.svg' !default;
$minicart-shipping-icon-svg: '../images/icons/icon-arrow-down.svg' !default;
$minicart-back-icon-height: 16px;
$minicart-back-icon-width: 22px;




/*
* CART AND CHECKOUT
*/
$cart-card-margin-bottom: $spacer * 2;
$cart-number-of-items-font-size: $default-text-font-size !default;
$cart-product-name-text-overflow: ellipsis !default;
$cart-product-name-white-space: nowrap !default;
$cart-product-quantity-label-display: none !default;
$cart-recommended-products-border-top: 1px solid $border-color !default;
$cart-recommended-products-margin-top: 130px !default; // container margin
$cart-recommended-products-padding-top: 96px !default; // container padding
$checkout-mobile-footer-background: rgba($white, 1) !default;
$checkout-mobile-footer-box-shadow: 0 2px 6px rgba($off-black, 0.6) !default;
$checkout-approaching-discount-color: $green !default;
$checkout-promo-message-color: $green !default;
$checkout-totals-font-family: $primary-font !default;
$checkout-totals-font-size: 16px !default;
$checkout-totals-font-weight: normal !default;
$checkout-totals-text-transform: none !default;
$checkout-spacer: 40px !default;
$checkout-selected-payment-background: $off-white !default;
$checkout-selected-payment-color: $brand-primary !default;
$checkout-selected-payment-border: 1px solid $brand-primary !default;


/*
* PAGE DESIGNER MODULES
*/
$page-designer-layout-spacing: $spacer*4; // This sets the default spacing between layout containers
$page-designer-layout-spacing-desktop: $spacer*6; // This sets the default spacing between layout containers


/*
* HERO ASSETS
*/
$hero-title-size: 2; // This number should be 1-6, corresponding with h1-h6 headers
$hero-subtitle-font: $header-font !default;
$hero-subtitle-font-size: $paragraph-font-size-small !default;
$hero-subtitle-text-transform: none !default;
$hero-textblock-outer-padding: $spacer !default;
$hero-textblock-outer-padding-md: $spacer*2 !default;
$hero-textblock-outer-padding-lg: $spacer*2;
$hero-textblock-inner-padding: $spacer !default;
$hero-textblock-inner-padding-md: $spacer !default;
$hero-textblock-inner-padding-lg: $spacer !default;
$hero-textblock-background-opacity: 0.7 !default;
$hero-textblock-border-width: 1px !default;
$hero-textblock-items-vertical-spacing: $spacer !default;
$hero-min-height-short: 150px !default;
$hero-min-height-short-md: 80px !default;
$hero-min-height-short-lg: 80px !default;
$hero-min-height-medium: 250px !default;
$hero-min-height-medium-md: 300px !default;
$hero-min-height-medium-lg: 350px !default;
$hero-min-height-medium-xl: 470px !default;
$hero-min-height-tall: 263px;
$hero-min-height-tall-md: 544px;
$hero-min-height-tall-lg: $hero-min-height-tall-md;
$hero-quote-min-height-tall: 344px;
$hero-quote-min-height-tall-md: 600px;
$hero-quote-min-height-tall-lg: $hero-quote-min-height-tall-md;
$hero-colortheme-dark: $off-black !default;
$hero-colortheme-light: $white !default;
$hero-video-aspect-ratio: $aspect-ratio-sixteen-nine !default;
$hero-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal !default;
$hero-animation-delay: 0ms !default; // This value should match the HeroAnimationDelay value in scripts/constants/SiteConstants.js

/*
* POPSOCKETS HERO BANNER
*/
$popsockets-hero-banner-mobile-image-width: 414px;
$popsockets-hero-banner-mobile-image-height: 560px;
$popsockets-hero-banner-mobile-aspect-ratio: $popsockets-hero-banner-mobile-image-height / $popsockets-hero-banner-mobile-image-width * 100;
$popsockets-hero-banner-desktop-image-width: 1440px;
$popsockets-hero-banner-desktop-image-height: 800px;
$popsockets-hero-banner-desktop-aspect-ratio: $popsockets-hero-banner-desktop-image-height / $popsockets-hero-banner-desktop-image-width * 100;
$popsockets-hero-banner-destop-max-height: (map-get($container-max-widths, xl) - ($spacer * 4)) * ($popsockets-hero-banner-desktop-aspect-ratio / 100);


/*
* CATEGORY TILE ASSETS
*/
$category-tile-border-radius: $border-radius !default;
$category-tile-background-color: $light-gray;
$category-tile-primary-font-size: $paragraph-font-size-large !default;
$category-tile-secondary-line-height: $paragraph-m-line-height !default;
$category-tile-secondary-font-size: $paragraph-lg-font-size !default;
$category-tile-secondary-line-height: $paragraph-m-line-height !default;
$category-tile-text-shadow: none; // When text is in image area only
$category-tile-text-background: rgba($off-black, 0.3) !default; // When text is in image area only
$category-tile-text-inside-color: $white !default; // When text is in image area only
$category-tile-text-below-color: $off-black;
$category-tile-text-below-font-family: $secondary-font;
$category-tile-text-below-font-size: 14px;
$category-tile-hover-zoom-amount: 1.2 !default; // Only applicable if zoom is enabled
$category-tile-aspect-ratio-square: $aspect-ratio-square !default;
$category-tile-aspect-ratio-landscape: $aspect-ratio-landscape !default;
$category-tile-aspect-ratio-portrait: $aspect-ratio-portrait !default;
$category-tile-video-aspect-ratio: $aspect-ratio-sixteen-nine !default;
$category-tile-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal !default;
$category-tile-padding: $spacer;


/*
* IMAGE TEXT BLOCK ASSETS
*/
$image-text-block-border-radius: $category-tile-border-radius !default;
$image-text-block-subtitle-font-size: $paragraph-font-size-small !default;
$image-text-block-primary-font-size: $category-tile-primary-font-size !default;
$image-text-block-secondary-line-height: $category-tile-secondary-line-height !default;
$image-text-block-secondary-font-size: $category-tile-secondary-font-size !default;
$image-text-block-secondary-line-height: $default-text-line-height !default;
$image-text-block-text-shadow: $category-tile-text-shadow !default; // When text is in image area only
$image-text-block-text-background: $category-tile-text-background !default; // When text is in image area only
$image-text-block-text-background-gradient: linear-gradient(to bottom, transparent 0%, rgba($off-black, 0.55) 100%); // When text is in image area only
$image-text-block-text-inside-color: $category-tile-text-inside-color !default; // When text is in image area only
$image-text-block-hover-zoom-amount: $category-tile-hover-zoom-amount !default; // Only applicable if zoom is enabled
$image-text-block-aspect-ratio-square: $category-tile-aspect-ratio-square !default;
$image-text-block-aspect-ratio-landscape: $category-tile-aspect-ratio-landscape !default;
$image-text-block-aspect-ratio-portrait: $category-tile-aspect-ratio-portrait !default;
$image-text-block-video-aspect-ratio: $aspect-ratio-sixteen-nine !default;
$image-text-block-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal !default;


/*
* PHOTO HOTSPOTS ASSETS
*/
$photo-hotspot-size: 24px !default;
$photo-hotspot-tile-radius: 20px !default;
$photo-hotspot-background: rgba($alto, 0.7) !default;
$photo-hotspot-background-dark: rgba($off-black, 0.3) !default;
$photo-hotspot-border-color-inner: transparent !default;
$photo-hotspot-border-color-inner-dark: transparent !default;
$photo-hotspot-border-size-inner: 2px !default;
$photo-hotspot-border-color-outer: $white !default;
$photo-hotspot-border-color-outer-dark: $off-black !default;
$photo-hotspot-border-size-outer: 2px !default;
$photo-hotspot-margin-top-outer: 16px !default;
$photo-hotspot-margin-right-outer: 16px !default;
$photo-hotspot-border-radius: 50% !default;
$photo-hotspot-icon: $icon-plus !default;
$photo-hotspot-icon-color: $white !default;
$photo-hotspot-icon-color-dark: $off-black !default;
$photo-hotspot-icon-size: 10px !default;
$photo-hotspot-shadow: none !default;
$photo-hotspot-size-hover: 40px !default;
$photo-hotspot-background-hover: rgba($white, 0.8) !default;
$photo-hotspot-background-hover-dark: rgba($off-black, 0.5) !default;
$photo-hotspot-border-color-inner-hover: transparent !default;
$photo-hotspot-border-color-inner-hover-dark: transparent !default;
$photo-hotspot-border-size-inner-hover: 3px !default;
$photo-hotspot-border-color-outer-hover: $white !default;
$photo-hotspot-border-color-outer-hover-dark: $off-black !default;
$photo-hotspot-border-size-outer-hover: 3px !default;
$photo-hotspot-hover-icon: $icon-plus !default;
$photo-hotspot-tooltip-max-width: 300px !default;
$photo-hotspot-text-spacing: 8px !default;
$photo-hotspot-text-product-divider: 1px solid $border-color !default;
$photo-hotspot-attribute-spacing: 16px !default;
$photo-hotspot-tooltip-image-size: 75px !default;
$photo-hotspot-tooltip-product-name-font-size: $default-text-font-size !default;
$photo-hotspot-tooltip-product-name-color: $off-black !default;
$photo-hotspot-tooltip-product-name-text-overflow: ellipsis !default;
$photo-hotspot-tooltip-product-name-whitespace: nowrap !default;
$photo-hotspot-tooltip-price-font-size: $default-text-font-size !default;
$photo-hotspot-tooltip-price-color: $dark-gray !default;
$photo-hotspot-tooltip-message-display: block !default;
$photo-hotspot-tooltip-message-font-size: 10px !default;
$photo-hotspot-tooltip-message-text-transform: uppercase !default;
$photo-hotspot-tooltip-message-color: $dark-gray !default;
$photo-hotspot-tooltip-sale-price-color: $navy !default;


/*
* VIDEO ASSETS
*/
$video-play-icon: url('../images/icons/play.svg');
$video-play-icon-color: $white !default;
$video-play-icon-size: 50px !default;
$video-pause-icon: url('../images/icons/pause.svg');
$video-pause-icon-color: $white !default;
$video-pause-icon-color-hover: $brand-secondary !default;
$video-pause-icon-size: 50px !default;
$video-modal-size: 'xl' !default; // sm, lg, or xl

/*
* BLOG LANDING
*/
$blog-nav-background-color: $off-white !default;
$blog-nav-border-color: $border-color !default;
$blog-nav-item-text-decoration: none !default;
$blog-nav-item-active-font-weight: bold !default;

/*
* BLOG DETAIL
*/
$blog-detail-category-color: $dark-gray !default;
$blog-detail-date-color: $dark-gray !default;
$blog-detail-category-date-separator-color: $dark-gray !default;
$blog-detail-category-date-separator: '|' !default;
$blog-detail-category-date-separator-spacer-left: 10px !default;
$blog-detail-category-date-separator-spacer-right: 10px !default;
$blog-detail-title-size: 2 !default; // This number should be 1-6, corresponding with h1-h6 headers
$blog-detail-title-margin-top: 10px !default;
$blog-detail-author-color: $dark-gray !default;
$blog-detail-author-margin-top: 10px !default;
$blog-detail-social-icons-border-top: none !default;
$blog-detail-social-icons-margin-top: 80px !default;
$blog-detail-social-icons-padding-top: 0 !default;
$blog-detail-social-icon-color: $link-color !default;
$blog-detail-social-icon-color-hover: $link-color-hover !default;
$blog-detail-social-icon-size: 30px !default;
$blog-detail-social-icon-spacing: 30px !default;
$blog-detail-footer-text-align: center !default;
$blog-detail-search-words-border-top: 1px solid $border-color !default;
$blog-detail-search-words-margin-top: 20px !default;
$blog-detail-search-words-padding-top: 20px !default;
$blog-detail-search-words-separator: ',' !default;
$blog-detail-search-words-spacer-left: 0 !default;
$blog-detail-search-words-spacer-right: 7px !default;

/*
* CONTENT TILES
*/
$content-tile-image-aspect-ratio: $aspect-ratio-square !default;
$content-tile-image-zoom-amount: 1.2 !default; // set to 1 to turn zooming off
$content-tile-grid-margin-bottom: $product-tile-grid-margin-bottom !default;
$content-tile-image-hover-shadow-color: $product-tile-image-hover-shadow-color !default;
$content-tile-image-hover-shadow-size: $product-tile-image-hover-shadow-size !default;
$content-tile-image-hover-shadow: $product-tile-image-hover-shadow !default;
$content-tile-image-margin-bottom: $product-tile-image-margin-bottom !default;
$content-tile-category-font: $default-text-font !default;
$content-tile-category-font-size: $paragraph-font-size-small !default;
$content-tile-category-font-weight: $product-tile-title-font-weight !default;
$content-tile-category-text-decoration: $product-tile-title-text-decoration !default;
$content-tile-category-text-decoration-hover: $product-tile-title-text-decoration-hover !default;
$content-tile-category-color: $dark-gray !default;
$content-tile-category-color-hover: $dark-gray !default;
$content-tile-category-margin-bottom: 7px !default;
$content-tile-name-font: $header-font !default;
$content-tile-name-font-size: 18px !default;
$content-tile-name-font-weight: $product-tile-title-font-weight !default;
$content-tile-name-text-decoration: $product-tile-title-text-decoration !default;
$content-tile-name-text-decoration-hover: $product-tile-title-text-decoration-hover !default;
$content-tile-name-color: $product-tile-title-color !default;
$content-tile-name-color-hover: $product-tile-title-color-hover !default;
$content-tile-name-margin-bottom: 10px !default;
$content-tile-date-font: $header-font !default;
$content-tile-date-font-size: $paragraph-font-size-small !default;
$content-tile-date-font-weight: $product-tile-title-font-weight !default;
$content-tile-date-text-decoration: $product-tile-title-text-decoration !default;
$content-tile-date-text-decoration-hover: $product-tile-title-text-decoration-hover !default;
$content-tile-date-text-transform: uppercase !default;
$content-tile-date-color: $product-tile-title-color !default;
$content-tile-date-color-hover: $product-tile-title-color-hover !default;
$content-tile-date-margin-bottom: 7px !default;
$content-tile-description-font: $default-text-font !default;
$content-tile-description-font-size: $default-text-font-size !default;
$content-tile-description-font-weight: $default-text-font-weight !default;
$content-tile-description-text-decoration: none !default;
$content-tile-description-text-decoration-hover: none !default;
$content-tile-description-color: $base-font-color !default;
$content-tile-description-color-hover: $base-font-color !default;


/*
* STORE LOCATOR
*/
$store-map-canvas-height-sm: 250px !default;
$store-map-canvas-height: 450px !default;
$store-map-canvas-marker-font-family: $primary-font !default;
$store-map-canvas-marker-title-font-size: 18px !default;
$store-map-canvas-marker-font-size: $popover-font-size !default;
$store-map-canvas-marker-line-height: 1.5 !default;
$store-map-marker-size: 32px !default;
$store-results-container-max-height: 350px !default;
$store-type-map-marker-default-label-color: $white !default;


/*
* STORE DETAIL PAGE
*/
$store-detail-name-header-value: 3 !default;
$store-detail-name-header-color: $off-black !default;
$store-detail-attribute-vertical-spacing: 8px !default;
$store-detail-attribute-label-font-weight: bold !default;
$store-detail-additional-margin-top: 30px !default;
$store-detail-image-margin-top: 30px !default;
$store-detail-map-link-margin-top: $spacer !default;

/*
* ACCOUNT
*/
$account-login-tabs-margin-bottom: 20px !default;
$account-wishlist-social-link-size: 30px !default;
$account-wishlist-social-link-icon-size: 30px !default;
$account-wishlist-social-link-color: rgba($off-black, 0.7) !default;
$account-wishlist-social-link-background-color: rgba($white, 0.7) !default;
$account-wishlist-social-link-box-shadow: none !default;
$account-wishlist-social-link-border-radius: 50% !default;

/*
* GIFTCERTIFICATES
*/
$giftcertificate-balancemsg-font-size: 18px !default;
$giftcertificate-balancemsg-font-weight: bold !default;

/*
* PAYMENTMETHODS
*/
$paymentmethod-error-font-weight: bold !default;

/*
* ACCESSIBILITY
*/
$outline-focus-width: 2px !default;
$outline-focus-style: solid !default;
$outline-focus-color: $dark-gray !default;
$outline-color: $dark-gray !default;
$outline-color-mobile: transparent !default;
/*
* CONSENT DRAWER
*/
$consent-min-height: 85px !default;
$consent-border-top-width: 0;
$consent-border-top-style: solid !default;
$consent-border-top-color: $brand-tertiary !default;
$consent-background-color: $peach;
$consent-paragraph-margin: 4px !default;
$consent-paragraph-font-size: $default-text-font-size;
$consent-paragraph-line-height: $default-text-line-height;
$vat-price: 14px;
