@import "~core/components/menu";

.navbar-nav {
    padding: 16px 0 0;
    @include media-breakpoint-up(md) {
        padding: 0;
    }
}
.navbar {
    &.bg-inverse {
        .navbar-nav {
            .nav-link {
                padding: $dropdown-item-padding $spacer*1.5;

                .cat-nav-badge {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: $spacer/4;
                    padding: $spacer/8 $spacer/4;
                    border-width: 1px;
                    border-style: solid;
                    border-radius: $spacer;
                    font-size: $spacer/2;
                    line-height: 10px;
                }

                &:focus {
                    @include media-breakpoint-up (lg) {
                        outline: none;
                        text-decoration: $dropdown-item-hover-text-decoration;

                        .cat-nav-badge {
                            text-decoration: none;
                        }
                    }
                }

                &:hover {
                    text-decoration:$dropdown-item-hover-text-decoration-mobile;

                    @include media-breakpoint-up(lg) {
                        text-decoration: $dropdown-item-hover-text-decoration;
                    }

                    .cat-nav-badge {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .megamenu {
        &>.dropdown-menu .menu-subcategories {
            .dropdown-link {
                color: inherit;
                display: flex;
            }
            .dropdown-item {
                &.text--white {
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
        @include media-breakpoint-up(lg) {
            &:hover > .dropdown-menu {
                display: block;
            }
            // 3 column if no assets
            .menu-subcategories>.dropdown-item {
                
            }
            .menu-subcategories>.dropdown-item.custom-dropdown {
                flex-basis: 45%;
            }
            
            &>.dropdown-menu {
                .menu-subcategories {
                    width: 100%;
                    justify-content: space-around;
                    padding: $spacer*1.5 $spacer;

                    @include media-breakpoint-up (lg) {
                        justify-content: flex-start;
                    }

                    @include media-breakpoint-up (xl) {
                        justify-content: space-around;
                    }

                    .dropdown-item {
                        &:hover {
                            background-color: $dropdown-item-hover-background;
                        }

                        @include media-breakpoint-up (lg) {
                            max-width: 28%;
                        }
                        @include media-breakpoint-up (xl) {
                            max-width: inherit;
                        }
                        &:last-child {
                            &:nth-child(2), &:nth-child(8), &:nth-child(14) {
                                @include media-breakpoint-up(xl) {
                                    margin-right: 34%;
                                }
                            }

                            &:nth-child(10), &:nth-child(4) {
                                @include media-breakpoint-up(xl) {
                                    margin-right: 67.25%;
                                }
                            }
                        }
                        &.custom-dropdown {
                            @include media-breakpoint-up (lg) {
                                max-width: inherit;
                            }
                            +  .dropdown-item {
                                &:nth-child(2) {
                                    @include media-breakpoint-up(xl) {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                    &>.dropdown-item {
                        padding: 0;
                        margin: $spacer $spacer 0;
                    }
                }
            }
        }
    }
}

.lower-level-open {
    @include media-breakpoint-down(md) {
        height: 0;
        .nav-item {
            height:0;
            overflow: hidden;
            &.show {
                left: 0;
                right: 0;
                height: 100vh;
                overflow: auto;
                position: fixed;
                background-color: $white;
            }
        }
        .thirdCatOpen {
            .dropdown-item {
                height: 0;
            }
            .show .dropdown-item {
                height: 1.5em;
            }
        }
    }
}
 
// Megamenu
.navbar-nav .megamenu {
    > .dropdown-menu .menu-subcategories .dropdown-link {
        height: 96px;
        display: flex;
        align-items: center;
    }
    > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link {
        @include typography(11);
    }
    @include media-breakpoint-up(lg) {
        position: static;
        // Megamenu subcategory columns, dependent on presence of populated content slots// 5 column if no assets
        .menu-subcategories>.dropdown-item {
            flex-basis: 25%;
        }
        &>.dropdown-menu {
            max-width: 100%;

            .menu-subcategories {
                .dropdown-link {
                    padding: 10px 30px;

                    &:hover {
                        .cat-sub-nav-badge {
                            text-decoration: none;
                        }
                    }
                }
                .dropdown-item {
                    &:hover {
                        background-color: $dropdown-item-hover-background;
                        background-image: inherit;

                        .cat-sub-nav-badge {
                            text-decoration: none;
                        }
                    }
                }
            }

            &:before {
                width: 100%;
            }
        }
    }

    .cat-sub-nav-badge {
        display: inline-block;
        vertical-align: middle;
        margin-left: $spacer/4;
        padding: $spacer/8 $spacer/4;
        border-width: 1px;
        border-style: solid;
        border-radius: $spacer;
        font-size: $spacer/2;
        line-height: 10px;
    }
}

// Mobile menu
.main-menu.menu-toggleable-left {
    -ms-overflow-style: none;
    scrollbar-width: none; 
    overflow: hidden;
    @include media-breakpoint-down(sm) {
        height: 100vh;
    }
    &::-webkit-scrollbar { 
        display: none; 
    }
    .country-selector {
        display: none;
    }
    &.navbar-toggleable-md{
        width: 100%;
        max-width: 100%;
        .close-menu {
            background-color: transparent;
            @include media-breakpoint-down(md) {
                position: relative;
                height: 84px;
                button {
                    left: 0;
                    // PAP-781
                    width: 15%;
                    position: absolute;
                    top: 32px;
                    height: auto;
                    padding: 0;

                    > img {
                        float: left;
                        width: 24px;
                    }

                    &.close-menu-icon {
                        left: auto;
                        right: 0;
                        @include icon($icon-close, before, $button-sm-font-size, $brand-primary);
                        &.close-main-menu {
                            right: 0;
                            width: 50px;
                            z-index: 99;
                            top: 15px;
                        }

                        &::before {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .nav-item .top-category {
            & ~ .menu-subcategories {
                padding-left: 0;
                padding-right: 0;
                
                @include media-breakpoint-down(md) {
                    padding-bottom: 100px;
                    overflow: auto;
                    height: 100vh
                }
                > .dropdown-item > .dropdown-link {
                    padding: $spacer $spacer*6.25 $spacer $spacer*2;
                    white-space: normal;
                    flex-wrap: wrap;
                    line-height: $default-text-line-height;
                    @include media-breakpoint-up(md) {
                        padding: $spacer 0 $spacer*2 0;
                    }
                    &:hover {
                        text-decoration:$dropdown-item-hover-text-decoration-mobile;

                        @include media-breakpoint-up(lg) {
                            text-decoration: $dropdown-item-hover-text-decoration;
                        }
                    }
                    &:focus {
                        @include media-breakpoint-up (lg) {
                            outline: none;
                            text-decoration: $dropdown-item-hover-text-decoration;
                        }
                    }
                }
            }
            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 0;
                position: absolute;
                top: 12px;
                background: transparent;
                // PAP-781
                width: auto;
                left: 15%;
                right: 15%;
                z-index:1;
                //end PAP-781
                text-align:center;
                .nav-link {
                    margin: 0;
                    padding: $spacer 0;
                    @include typography(8)
                }
            }
        }
        > .dropdown-item {
            &:hover {
                background: $dropdown-item-hover-background;
                @include media-breakpoint-up(lg) {
                    background: $dropdown-item-hover-background;
                }
            }
        }
       .user-message + .dropdown-menu {
            position: static;
            transform: none;
            .dropdown-link {
                padding: $spacer $spacer*2 0;
            }
        }
        .dropdown-item.custom-dropdown .dropdown-menu{
            margin: 0;
            padding: 0;
        }
        
        @include media-breakpoint-down(md) {
            .nav-item .nav-link {
                padding: $spacer*2;
                color: inherit;
                font-family: $primary-font;
            }
            &.in .show > .dropdown-menu {
                padding: 0 $spacer 50%;
                margin: 0;
            }
            & > .nav-item {
                border-top: none;
                margin: 0 0 $spacer;
                border-radius: $border-radius;
                background: $dropdown-item-hover-background;
            }
            .nav-item ~ .nav-item:not(.nav-item-cat) {
                border-top: 1px solid $dropdown-item-hover-background;
            }
            .menu-group {
                padding-bottom: 200px;

                .close-menu {
                    display: flex;

                    .back {
                        padding-left: 0;
                    }

                    .icon-arrow-left-thin {
                        margin-top: 17px;
                        font-size: 18px;
                        font-weight: bold;
                    }
                }

                .brand {
                    flex-grow: 1;
                    max-width: 190px;
                    height: 50px;
                    height: 100%;
                    align-items: center;

                    .logo-home {
                        width: $header-logo-width;
                        height: $header-logo-height;
                        margin: 0 auto;
                        background: url(#{$header-logo-image}) no-repeat center;
                        background-size: contain;
                        display: block;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(xs) {
        width: 100%;
        max-width: 100%;
    }
    @include media-breakpoint-down(md) {
        height: 100vh;
        top: 0 !important;
        &.in {
            transform: translateX(0);
            .show > .dropdown-menu {
                transform: translateX(0);
                overflow: hidden;
                -ms-overflow-style: none;
                scrollbar-width: none; 
                &::-webkit-scrollbar { 
                    display: none; 
                }
            }

            .navbar-nav {
                padding: $spacer 0 82px;
                display: block;
                background-color: $white;
            }

            .mobile-nav-wrap {
                overflow-x: hidden;
                overflow-y: auto;
                height: 100vh;

                &.grid-view {
                    height: 100vh;
                    padding: 0;

                    .swatches {
                        padding: 0 32px 104px;
                    }

                    .first-category-name {
                        margin-bottom: 32px;
                    }

                    .subcategories-swatches {
                        margin: 0;
                        padding: 0;
                    }

                    .subcategory-swatch {
                        a {
                            max-width: initial;
                        }
                    }

                    .grid-view {
                        padding: 0;
                    }
                }
            }

            .content-asset {
                .dropdown {
                    z-index: 0;
                }
            }

            .search-mobile {
                .site-search {
                    padding: 0 $spacer;
                }

                .search-field {
                    border: 1px solid $medium-gray;
                    border-radius: 4px;
                }
                .suggestions-wrapper {
                    height: auto;
                }
            }

            &.search {
                .search-mobile {
                    display: block;
                }
                .menu-group {
                    display: none;
                }
            }
            //end PAP-647
        }
        .top-category {
            &~.menu-subcategories {
                border: 0;
            }

            > .nav-link {
                > .cat-nav-badge {
                    display: none;
                }
            }
        }

        .dropdown-menu {
            top: 0;
            z-index: 2;
        }
    }
}
.usertopnavlayout {
    .menu-group {
        .navbar-nav {
            .nav-link {
                @include media-breakpoint-up(lg) {
                    padding: $spacer/2;
                }
                @include media-breakpoint-up(sd) {
                    padding: $spacer;
                }
                @include media-breakpoint-up(xl) {
                    padding: $spacer*1.5;
                }
            }
            .lines {
                text-decoration: none;
                font-weight: 500;
                @include media-breakpoint-up(lg) {
                    padding: $spacer/2;
                }
                @include media-breakpoint-up(sd) {
                    padding: $spacer;
                }
                @include media-breakpoint-up(xl) {
                    padding: $spacer*1.5;
                }
                &:hover {
                    @include media-breakpoint-down(lg) {
                        text-decoration: none;
                        font-weight: 500;
                    }
                    @include media-breakpoint-up(lg) {
                        text-decoration: $dropdown-item-hover-text-decoration;
                        text-underline-offset: 8px;
                        color:$primary-black;
                    }
                }
            }
            .nav-link:hover {
                @include media-breakpoint-up(lg){
                    text-underline-offset: 8px;
                }
            }

            .nav-item {
                &:hover,
                &:focus {
                    > .categories {
                        > .lines {
                            @include media-breakpoint-up(lg) {
                                text-decoration: $dropdown-item-hover-text-decoration;
                                text-underline-offset: 8px;
                                color:$primary-black;
                            }
                        }
                    }
                }
            }
            
            .custom-dropdown:hover {
                .first-category-name {
                    > .lines {
                        @include media-breakpoint-up(lg) {
                            text-decoration: $dropdown-item-hover-text-decoration;
                            text-underline-offset: 8px;
                            color:$primary-black;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down (lg) {
        .main-menu.menu-toggleable-left {
            &.navbar-toggleable-md{
                width: 100%;
                background: transparent;
                .close-button {
                    position: absolute;
                    left: 83%;
                    margin-top: 16px;
                    padding: 0 16px 0 16px;
                    .new-close {
                        position: absolute;
                        background: $white;
                        left: 100%;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        color: $primary-black;
                    }
                }
                .nav-item.d-lg-none ~ .nav-item:not(.nav-item-cat) {
                    &::before {
                        background-color: transparent;
                    }
                }
            }
        }
        .mobile-nav-wrap {
            width: 85%;
        }
        .navbar-nav {
            align-items: center;
        }
        .main-menu.menu-toggleable-left {
            &.navbar-toggleable-md{
                .menu-group{
                    .close-menu{
                        display: none;
                        height: auto;
                        button {
                            top: 14px;
                        }
                    }
                }
            }
            &.in {
                .search-mobile {
                    .search-field {
                        background: $white;
                        border: 1px solid $medium-gray;
                        border-radius: 20px;
                    }:focus {
                        border: 2px solid $primary-black;
                    }
                }
            }
        }
        .subcategories-swatches {
            scrollbar-width: none;
        }
        .icon-arrow-left-thin {
            display: none;
        }
        
        .category-image {
            width: 72px;
            height: 72px;
        }
    }
    @include media-breakpoint-up(lg) {
        .navbar-nav {
            align-items: center !important;
            .megamenu{
                &>.dropdown-menu {
                    .menu-subcategories {
                        .dropdown-menu {
                            flex-flow: column wrap;
                        }
                    }
                }
            }
        }
        .navbar {
            .megamenu {
                &>.dropdown-menu {
                    max-width: 100%;
                    margin-top: 3px;
                    .swatches {
                        padding: 49px 20px 16px;
                        max-width: 1020px;
                        margin: 0 auto;

                        .tns-nav {
                            display: none;
                        }

                        .swatch-img-box {
                            width: 140px !important;
                        }

                        .tns-inner {
                            max-width: 1020px;
                            margin: 0 auto;
                            overflow: hidden;
                        }

                        .tns-controls button[data-controls='next'] {
                            right: 6px;
                        }

                        .tns-controls button[data-controls='prev'] {
                            left: 6px;
                        }

                        .swatch-view a {
                            display: flex;
                            flex-direction: column;
                            text-decoration: none;

                            .subcategory-image {
                                width: 120px;
                                height: 120px;
                                gap: 0px;
                                border-radius: 20px;
                                margin: 30px 10px 16px 10px;
                            }
                            .subcategory-name {
                                font-family: $primary-font;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 19.36px;
                                text-align: center;
                                margin-bottom: 40px;
                                text-wrap: nowrap;
                            }
                        }
                    }
                    .menu-subcategories {
                        position: relative;
                        display: flex;
                        flex-wrap: nowrap;
                        padding: 56px 0 40px;
                        justify-content: center;
                        background: $white;

                        .dropdown-item {
                            max-width: 100%;
                        }

                        .category-alignment {
                            display: flex;
                            flex-direction: column;
                            padding-left: 1rem;

                            .shop-all {
                                display: inline-flex;
                                justify-content: flex-start;
                                text-decoration: none;
                                text-wrap: nowrap;
                                width: 65px;
                                height: 19px;
                                font-family: $primary-font;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 19.36px;
                                text-align: left;
                                color: $primary-black;
                                margin-bottom: 12px;
                                margin-right: auto;
                                align-items: center;

                                .icon-arrow {
                                    width: 14px;
                                    height: 13px;
                                    padding: 0.39px 0px 0.39px 0px;
                                    margin-left: 14px;
                                }
                            }

                            .desktop-column {
                                margin: 0;
                            }

                            /* Default single column style */
                            .desktop-column.single-column {
                                display: flex;
                                flex-direction: column;
                            }

                            .desktop-column.single-column .coln {
                                display: flex;
                                flex-direction: column;
                            }

                            /* Two column style */
                            .desktop-column.two-column {
                                display: flex;
                                flex-direction: column;
                            }

                            .desktop-column.two-column .coln{
                                display: flex;
                                margin-top: 17px;
                                .dropdown-menu {
                                    margin-top: 6px;
                                }
                            }

                            .two-coln {
                                column-count: 2;
                                column-gap: 0;
                                display:block !important;
                                line-height: 0;
                            }

                            .desktop-column .coln > span {
                                display: block;
                                padding-right: 16px;
                                max-width: 216px;
                                min-width: 216px;
                                width: 100%;
                                &.category-type-1 {
                                    max-width: initial;
                                    min-width: initial;

                                    > .dropdown-item {
                                        > .dropdown-menu {
                                            min-width: 200px;
                                        }
                                        > .dropdown-menu.two-coln {
                                            min-width: 416px;
                                            width: 416px;
                                            column-gap: 16px;
                                        }
                                    }

                                }
                            }
                            .row {
                                width:100%;
                                display: flex;

                                .category-type-1,
                                .category-type-2 {
                                    .dropdown-item {
                                        background: none;
                                        background-size: 0 !important;
                                        margin: 0;
                                        border-radius: 0;

                                        .subcategory-swatch-header {
                                            font-family: $primary-font;
                                            font-size: 16px;
                                            font-weight: 700;
                                            line-height: 19.36px;
                                            text-align: left;
                                            opacity: 50%;
                                        }

                                        .dropdown-link {
                                            padding: 12px 0 !important;
                                            height: auto;
                                            align-items: center;
                                            font-family: $primary-font;
                                            font-size: 16px;
                                            font-weight: 500;
                                            line-height: 22px;
                                            text-align: left;
                                            display: inline-block;

                                            &:hover,
                                            &:focus {
                                                color: $primary-black;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .desktop-asset {
                            position: relative;
                            padding-right: 1rem;

                            .menu-images {
                                display: flex;
                                flex-direction: row;
                                padding: 0;
                        
                                .link {
                                    display: flex;
                                    gap: 1rem;
                        
                                    .type1,
                                    .type2 {
                                        display: flex;
                                        flex-direction: column;
                                        max-width: 360px;
                                        width: 100%;
                        
                                        .text-underline {
                                            text-decoration: none;
                        
                                            .image-styling {
                                                width: 100%;
                                                max-width: 100%;
                                                height: auto;
                                                aspect-ratio: 1/1;
                                                border-radius: 20px;
                                            }
                        
                                            .text {
                                                font-family: Inter;
                                                font-size: 18px;
                                                font-weight: 500;
                                                margin-top: 12px;
                                                line-height: 21.78px;
                                                text-align: left;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .first-category-name {
            text-wrap: nowrap;
        }
        .mobile-navigation-bar {
            display: none;
        }
        .mobile-subcategories {
            display: none !important;
        }
        .category-image {
            display: none;
        }
        .down-arrow {
            display: none;
        }
        .first-category-swatches {
            display: none;
        }
        .subcategories-swatches {
            display: none;
        }
        .view-all-button {
            display: none !important;
        } 
        .arrow-toggle {
            display: none;
        }
        .subcategories-list {
            display: none;
        }
        .mobile-nav-footer {
            display: none;
        }
        .subcategory-swatch {
            display: none;
        }
        .close-button {
            display: none;
        }
    }

    @include media-breakpoint-up(sd) {
        .navbar {
            .megamenu {
                &>.dropdown-menu {
                    .swatches {
                        padding: 49px 20px 16px;
                        max-width: 1160px;
                        margin: 0 auto;

                        .swatch-img-box {
                            width: 160px !important;
                        }

                        .tns-inner {
                            max-width: 1160px;
                        }

                        .swatch-view a {
                             .subcategory-image {
                                width: 140px;
                                height: 140px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .navbar {
            .megamenu {
                &>.dropdown-menu {
                    .swatches {
                        padding: 49px 20px 6px;
                        max-width: 1440px;
                        margin: 0 auto;

                        .swatch-img-box {
                            width: 200px !important;
                        }

                        .tns-inner {
                            max-width: 1440px;
                        }

                        .tns-controls button[data-controls='next'] {
                            right: 16px;
                        }

                        .tns-controls button[data-controls='prev'] {
                            left: 16px;
                        }

                        .swatch-view a {
                             .subcategory-image {
                                width: 160px;
                                height: 160px;
                                margin: 30px 20px 16px 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        .main-menu.menu-toggleable-left {
            &.navbar-toggleable-md{
                .nav-item ~ .nav-item:not(.nav-item-cat) {
                    border: none;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 90%;
                        height: 1px;
                        background-color: $white;
                        transform: translateX(-50%);
                    }
                }
                .menu-group{
                    .close-menu{
                        button {
                            top: 14px;
                        }
                    }
                }
            }
            &.in {
                .search-mobile {
                    .search-field {
                        border: 1px solid $medium-gray;
                        border-radius: 20px;
                    }:focus {
                        border: 2px solid $primary-black;
                    }
                }
            }
        }
        .mobile-nav-wrap {
            .search-bar {
                padding-top: 24px;
                padding-bottom: 24px;
                background: $light-gray;
                font-family: $primary-font;
                font-size: 16px;
                .fa-search {
                    width: $header-search-width;
                    height: $header-search-height;
                    background: url(#{$header-search-icon-svg}) no-repeat center;
                    background-size: contain;
                    @include media-breakpoint-up(md) {
                        width: $header-search-width-md;
                        height: $header-search-height-md;
                    }
                }
            }
            .view-all-button {
                text-decoration: underline;
                position: relative;
                float: right;
                font-size: 12px;
                color: $primary-black;
                right: 4vw;
            }
        }
        .norm-to-design {
            display: inline-flex;
            width: 100%;
        }
        .main-menu.menu-toggleable-left {
            &.navbar-toggleable-md{
                .nav-item {
                    .nav-link {
                        font-weight: 500;
                        padding: 0px;
                    }
                }
            }
        }

        li.nav-item{
            &.nav-item-cat {
                &.border-nav {
                    position: relative;
                    border: none;
                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 90%;
                        height: 1px;
                        background-color: $new-grey;
                        transform: translateX(-50%);
                    }
                }
            }
        }

        .subcategories-list.mobile-subcategories .shop-all {
            position: relative;
            display: inline-block;
        }

        .subcategories-list.mobile-subcategories::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 90%;
            height: 1px;
            background-color: $new-grey;
            transform: translateX(-50%);
        }
        .mobile-nav-footer .account-border
         &::after {
            content: "";
            position: absolute;
            bottom: 0; 
            left: 50%;
            width: 90%;
            height: 1px;
            background-color: $new-grey;
            transform: translateX(-50%);
        }

        .background-blur {
            &::before {
                background-color: #000000;
                height: 100%;
                position: absolute;
                opacity: 0.5;
                width: 100vw;
                top: 0;
                left: 0;
                content: " ";
                z-index: 1;
            }
        }
        
        .view-all-image-size {
            width: 100% !important;
            height: auto !important;
            border-radius: 8px !important;
        }
        .view-all-font-size {
            font-size: 14px !important;
            margin-top: 12px !important;
            margin-bottom: 12px !important;
        }

        .desktop-asset {
            display: none;
        }

        .nav-item-cat {
            background-color: transparent;
            margin:0;
            padding: 0;
            border-radius: 0;
        }

        .mobile-navigation-bar {
            display: flex;
            align-items: center;
            padding: 0 14px 24px 14px;

            .social-links {
                li {
                    margin-top: 16px;
                }
            }
        }
        
        .category-item {
            display: flex;
            align-items: center;
        }
        
        .category-content a {
            display: flex;
            align-items: center;
        }
        
        .category-image {
            width: 55px;
            height: 55px;
            border-radius: 55px;
            background: $smoke-white;
            margin: 20px 16px 20px 16px;
            padding: 0;
        }
        
        .mobile-nav-footer {
            background:$concrete-grey;
            padding: 16px 0 84px;

            li.nav-item {
                padding: 16px;
            }
        }
        .main-menu.menu-toggleable-left{
            &.in {
                .navbar-nav {
                    padding: 0px;
                }
            }
        }
        
        .icon-arrow-left-thin {
            margin-top: 2.25vh;
        }

        .top-arrow {
            rotate: 270deg !important;
        }
        .custom-dropdown {
            .categories {
                display: inline-flex;
                align-items: center;
                width: 100%;

                .lines {
                    text-decoration: none;
                }
            }
        }
        .down-arrow {
            float: right;
            color: $primary-black-hover;
            position: absolute;
            right: 16px;
            rotate: 90deg;
            z-index: 99;
        }

        .new-featured {
            font-family: $primary-font;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: $primary-black;
            text-decoration: none;
        }

        .subcategories-swatches {
            flex-direction: column;
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            padding-top: 16px;
            scrollbar-width: none;
        }

        .subcategory-image {
            width: 72px;
            height: 72px;
            gap: 0px;
            border-radius: 10px;
        }

        .swatches {
            flex-direction: column;
            overflow-x: auto;
            overflow-y: hidden;
            padding: 0 0 0 16px;
        }
        
        .subcategories-list {
            display: flex;
            flex-direction: row;
            gap: 0px;
            text-wrap: nowrap;
        }
        
        .subcategory-swatch {
            display: flex;
            align-items: flex-start;
            padding: 0px 10px 10px 10px;

            &:first-child {
                padding-left: 0;
            }

            .subcategory-name-list-view {
                padding-bottom: 14px;
            }
        }

        .desktop-view {
            a {
                max-width: 72px;
            }
        }

        .subcategory-swatch-header {
            padding: 0px 0px 24px 10px;
            .subcategory-name-list-view {
                font-family: $primary-font;
                font-size: 16px;
                font-weight: 700;
                color: $dusty-grey;
                padding-bottom: 0px;
            }
        }
        
        .subcategory-swatch a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: inherit;
            flex-direction: column;
        }

        .subcategory-name{
            font-family: $primary-font;
            font-size: 11px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            white-space: normal;
            margin-top: 8px;
            margin-bottom: 16px;
        }
        .first-category-name {
            margin-top: 14px;
            width: 100%;
        }
        .mobile-subcategories {
            display: none;
            flex-direction: column;
        }
        .shop-all {
            font-family: $primary-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            text-align: left;
            text-decoration: none;
            padding-top: 20px;
            padding-left: 16px;
            padding-bottom: 32px !important;
        }
        .icon-arrow {
            display: inline-block;
            width: auto;
            height: auto;
            margin-left: 8px;
            margin-bottom: 1px;
        }
        .subcategory-name-list-view {
            font-family: $primary-font;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: left;
            padding-left: 6px;
            padding-bottom: 4px;
        }

        .grid-view {
            justify-content: flex-start;
            display: flex;
            flex-wrap: wrap;
            padding-left: 16px;
            padding-right: 32px;
            gap: 30px;

            .subcategory-swatch {
                padding: 0px;
                justify-content: center;
                max-width: calc(33.33% - 20px);
            }
        }

        .space {
            margin-left: 32px;
        }
    }
    @include media-breakpoint-down (sm) {
        .main-menu.menu-toggleable-left {
            &.navbar-toggleable-md{
                .close-button {
                    left: 80%;
                    padding: 0 12px 0 16px;
                }
            }
        }
        .category-image {
            width: 55px;
            height: 55px;
            border-radius: 55px;
            background: $smoke-white;
            margin: 20px 16px 20px 16px;
            padding: 0;
        }
        .header-nav {
            .main-menu {
                .row.no-gutters {
                    height: 100%;
                }
                .navbar {
                    height: 100%;
                }
                .menu-group {
                    height: 100%;
                }
            }
        }
        .main-menu.menu-toggleable-left {
            &.navbar-toggleable-md{
                .nav-item {
                    .nav-link {
                        padding: 0px;
                    }
                }
                .menu-group {
                    padding-bottom: 0px;
                    .close-menu {
                        display: none;
                    }
                }
            }
        }

        .grid-view {
            padding-left: 0;
            padding-right: 0;
            gap: 16px;

            .subcategory-swatch {
                max-width: calc(50% - 8px);
            }
        }

        .mobile-nav-wrap.grid-view {
            .subcategories-swatches {
                margin: 0 -16px 0 0;
            }
        }
    }
}