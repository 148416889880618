
@import "~core/components/quickView";
.modal-dialog {
    max-width: 800px;
}

.cross-sell-copy,
.cross-sell-btn {
    display: none;
}

.card .card-footer {
    .cross-sell-btn {
        position: absolute;
        top: 0;
        z-index: 1;
        display: block;
        width: 93%;
        .quickview {
            width:100%;
            height:100%;
            display: block;
            padding: 15px;
            color: transparent;
        }
    }
}
.cross-sell {
    &.product-quickview .row {
        .row.mt-md-3 {
            display: none;
        }
        &-copy {
            display: block;
            width:100%;
        }
    }
}

#quickViewModal {
    display: $modal-display-desktop;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 0;
    overflow: visible;

    @include media-breakpoint-up (md) {
        justify-content: center;
    }

    &.show {
        height: 100%;
    }

    &.modal {
        &.show {
            .modal-dialog {
                @include media-breakpoint-down(sm) {
                    transform: none;
                }
            }
        }
    }
    .modal-dialog {
        max-width: 85%;
        width: 883px;
        height: 568px;
        max-height: 568px;
        @include media-breakpoint-down(sm) {
            margin: 0;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
        .name-price-sm {
            margin: 0 $spacer*0.5 0;
            .product-name {
                margin-top: 0;
            }
        }
    }
    .modal-header {
        position: relative;
        padding-left: 0;
        padding: $spacer*1.5 $spacer*2 $spacer 0;

        .quickviewHeading {
            position: absolute;
            top: -$spacer*2.625;
            padding-left: 0;
            font-size: $paragraph-lg-font-size-large;
            line-height: $paragraph-line-height-large;
            font-family: $primary-font;
            color: $white;
            font-weight: bold;
            @include media-breakpoint-down(sm) {
                font-size: $paragraph-font-size-medium;
                line-height: $paragraph-line-height-medium;
                padding-left: $spacer;
            }

        }
        @include media-breakpoint-down(sm) {
           border-radius: 0;
           padding: $spacer*2 0 0;
           border-radius:  $spacer*1.25 $spacer*1.25 $spacer*0.5 $spacer*0.5;
        }
        .close {
            position: absolute;
            left: auto;
            right: $spacer*1.5;
            top: $spacer;
            z-index: 1;
            opacity: 1;

            &.pull-right {
                @include media-breakpoint-down(sm) {
                    right: $spacer;
                    top: $spacer*2;
                    z-index: 1;
                }
            }
        }
    }
    .modal-body {
        padding: 0 $spacer*2.5;

        @include media-breakpoint-down(sm) {
            padding-left: $spacer;
            padding-right: $spacer;
            height: auto;
            padding-bottom: $spacer*.5;
            min-height: calc(76vh - 140px);
        } 
        .primary-images-thumbnails{
            display: none;
        }
        .product-detail {
            .product-name {
                margin-bottom: $spacer;
                max-width: 98%;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                    margin-right: $spacer *2;
                }
            }
            .prices {
                margin-bottom: $spacer;
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                    margin-bottom: $spacer*1.875;
                }
                .price {
                    font-family: $body-copy-m-font-family;
                    font-size: $body-copy-m-font-size;
                    line-height: $body-copy-m-line-height;
                    @include media-breakpoint-down(sm) {
                        font-size: $body-copy-s-font-size;
                        line-height: $body-copy-s-line-height;
                    }
                }
            }

            .non-input-label {
                &.non-input-label {
                    margin-bottom: 0;
                }
            }

            .color-attribute {
                margin: $spacer*0.125 $spacer*0.5 0 0;
            }

            .color-swatches {
                padding: 0;
                border: 0;
                margin-bottom: $spacer*2;
                .color-display-value {
                    margin-top: $spacer*0.25;
                }
                @include media-breakpoint-down(sm) {
                    margin-bottom: $spacer*1.5;
                }
            }
            #cross-sell-target {
               display: none;

            }
            .swatch {
                margin-bottom: 0;
                .attributes {
                    margin-bottom: 0;
                }
                .iPhoneType {
                    font-family: $body-copy-m-book-font-family;
                    font-weight: $body-copy-m-font-weight;
                }
                .select-iPhoneType {
                    margin-bottom: $spacer*2;
                    @include media-breakpoint-down(sm) {
                        margin-bottom: $spacer*1.5;
                    }
                }
            }
            .availability {
                margin-bottom: 0;
            }
            .primary-images {
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    padding-right: 0;
                }

                @include media-breakpoint-up (lg) {
                    flex: 0 0 calc(100% - 380px);
                    max-width: calc(100% - 380px);
                }

                & > .row {
                    position: static;
                    height: 100%;
                }
                .primary-images-main {
                    margin-left: 0;
                    flex-basis: auto;
                    max-width: 100%;
                    width: 100%;
                    padding-bottom: 0;
                    @include media-breakpoint-down(sm) {
                        margin: 0 auto;
                        padding-bottom: 0;
                        flex: initial; 
                    }
                }
                .slider-container {
                    height: 100%;

                    .tns-outer,
                    .tns-ovh,
                    .tns-inner,
                    .slider {
                        height: 100%;
                    }

                    .tns-controls {
                        button {
                            height: $spacer*2;
                            width: $spacer*2;

                            @include media-breakpoint-up(md) {
                                top: auto;
                                bottom: $spacer*0.5625;
                                left: auto;
                                right: $spacer;
                                background: $white;
                                border: 1px solid transparent;
                                box-shadow: 0 0 8px -4px rgba($black, .5);

                                &:disabled {
                                    opacity: 1;
                                    &:before {
                                        color: $medium-gray;
                                    }
                                }

                                &:hover {
                                    border-color: $primary-black;
                                }
    
                                &::before {
                                    line-height: $spacer*2;
                                    font-size: $body-copy-xs-font-size;
                                    font-weight: $body-copy-xs-font-weight;
                                }

                                &[data-controls='prev'] {
                                    left: auto;
                                    right: $spacer*3.75;
                                }
                            }
                        }
                    }

                    .slide {
                        img {
                            @include media-breakpoint-down(md) {
                                height: 300px;
                                width: auto;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
            .quantity {
                &.attribute {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
        .quickview-left {
            padding-left: $spacer*0.5;
            padding-right: $spacer*0.5;

            @include media-breakpoint-up(lg) {
                padding-left: $spacer*1.5;
                padding-right: 0;
                flex: 0 0 380px;
                max-width: 380px;
            }
        }

        .quick-view-action-wrapper {
            .footer-content-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include media-breakpoint-down(sm) {
                    flex-direction: column-reverse;
                    border-top: 1px solid $medium-gray;
                    align-items: stretch;
                }
                .cross-sell-decline {
                    max-width: $spacer*5.187;
                    border-radius: 0;
                    padding: 0;
                    border: none;
                    border-bottom: 1px solid $black;
                    padding-top: $spacer*1.75;
                    color: $black;
                    @include media-breakpoint-down(sm) {
                        margin: 0 auto;
                    }
                }
                .footer-content-right {
                    display: flex;
                    justify-content: space-between;
                    padding-top: $spacer*2;
                    width: 413px;
                    max-width: 413px;
                    border-top: 1px solid $medium-gray;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        max-width: inherit;
                        border: none;
                        padding-top: $spacer*1.5;
                    }
                    .quantity {
                        min-width: $spacer*4.9375;
                        margin-right: $spacer*0.5;

                        @include media-breakpoint-down(sm) {
                            min-width: $spacer*5.06;
                        }
                        .quantity-select {
                            border-radius: $spacer*5.4375;
                            height: $spacer*3.5;
                            width: 100%;
                            padding: $spacer $spacer*1.5;
                            font-weight: $body-copy-xs-font-weight;
                            margin-right: 0;
                        }
                    }
                    .btn-add-to-cart {
                        .price {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .modal-footer {
        display: block;
        border-top: none;
        padding: 0 $spacer $spacer*2.5;

        @include media-breakpoint-down(sm) {
            padding: 0 $spacer $spacer*2;
            border-radius: 0;
        }
        .footer-content-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(sm) {
                flex-direction: column-reverse;
                border-top: 1px solid $medium-gray;
                align-items: stretch;
            }
            .cross-sell-decline {
                max-width: $spacer*5.187;
                border-radius: 0;
                padding: 0;
                border: none;
                border-bottom: 1px solid $black;
                padding-top: $spacer*1.75;
                color: $black;
                @include media-breakpoint-down(sm) {
                    margin: 0 auto;
                }
            }
            .footer-content-right {
                display: flex;
                justify-content: space-between;
                padding-top: $spacer*2;
                width: 413px;
                max-width: 413px;
                border-top: 1px solid $medium-gray;
                @include media-breakpoint-down(sm) {
                    width: auto;
                    max-width: auto;
                    border: none;
                    padding-top: $spacer*1.5;
                }
                .quantity {
                    min-width: $spacer*7.56;
                    margin-right: $spacer*0.56;
                    @include media-breakpoint-down(sm) {
                        min-width: $spacer*5.06;
                    }
                    .quantity-select {
                        border-radius: $spacer*6.25;
                        height: $spacer*3.5;
                    }
                }
                .btn-add-to-cart {
                    .price {
                        display: none;
                    }
                }
            }
        }
    }
    .modal-content {
        background-color: $white;
    }
}
.modal-backdrop {
    &.quickview-backdrop {
        background-color: $black;
        opacity: 0.7;
    }
}