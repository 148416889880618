@import "~core/components/miniCart";

.minicart {
    position: static;
    @include media-breakpoint-up(md) {
        position: relative;
    }
    .minicart-error {
        font-size: 14px;
        .valid-cart-error {
            border-radius: 0;
        }
    }
    .popover {
        left: $spacer;
        right: $spacer;
        overflow: hidden;
        min-width: $minicart-size-mobile;
        max-width: 100%;
        min-height: inherit;
        @include media-breakpoint-up(md) {
            right: 0;
            left: auto;
            min-width: $minicart-size;
            max-width: $minicart-size;
            min-height: inherit;
        }
        &.show {
            overflow: hidden;
        }

        .btn-add-to-cart {
            padding: $spacer;
            min-height: 0;
            @include typography(8)
        }
    }

    .cart {
        padding-top: $spacer*1.5;
        padding-bottom: $spacer/2;
    }

    .remove-btn {
        left: auto;
        right: 0;
        color: $primary-black;
    }

    // the important flags below are needed to override the built in styles applied.
    .line-item-header,
    .line-item-availability,
    .line-item-attributes {
        margin-bottom: $spacer/2 !important;
    }
    .line-item-attributes {
        //width: 60%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }

    .minicart-footer {
        .leading-lines {
            .end-lines, 
            .start-lines {
                span {
                    background: $white; 
                }
            }
        }

        a,
        button {
            font-size: $spacer !important;
            margin-bottom: $spacer !important;
        }

        .checkout-continue {
            div {
                margin-bottom: 0 !important;
            }
        }
    }

    .line-item-promo {
        background:$light-peach;
        margin-top: 12px;
        padding: 12px;
        border-radius: 8px;
        text-align: center;
        > div {
            font-family: Inter;
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            padding: 0;
            color:$tangerine;
            p {
                font-family: Inter;
                font-size: 12px;
                font-weight: 500;
                line-height: 14px;
                padding: 0;
                margin-bottom:0;
                color:$tangerine;
            }
        }
        empty-state {
            background: $white;
        }
    }
    .line-item-promo.is-empty {
        background: none;
        padding: 0;
    }

    .product-line-item {
        .item-price-qty {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        .item-attributes {
            padding-bottom: 0 !important;
        }

        .buy-with-prime-prime-day-deal-badge-text-mini-cart {
            height: auto;
        }

        .buy-with-prime-prime-day-deal-badge-sm {
            height: 24px;
            width: 130px;
            padding: 0 5px;
        }
    }

    .minicart-quantity-selector {
        .form-control-sm  {
            justify-content: center;
            align-items: center;
            padding: 0 $spacer;
            font-size: $spacer;
            width: 78px;
            height: 44px;
            margin: $spacer/2 0 0 0;
            border-radius: 100px;
        }
    }

    .product-summary {
        border-top: 1px solid $card-body-border-color;

        .line-item-name {
            @include typography(12);
        }

        .line-item-availability,
        .line-item-attributes {
            @include typography(13);
        }
    }

    .minicart-label {
        @include typography(10);
    }

    .minicart-item {
        &+.minicart-item {
            border-radius: 0 !important;
        }
    }
    .product-info, 
    .product-summary, 
    .multi-shipping {
        .item-attributes {
            width: calc(100% - 220px);
        }
        
    }
    .btn.remove-product {
        display: none;
    }
    .swipe-up-checkout-btn {
        display: none;
    }
    .notavailabe-cart {
        color: $red;
    }
    .item-image {
        .product-image {
            border-radius: $spacer*0.5;
        }
    }
}

.line-item-total-price {
    text-align: right;
    position: relative;
    @include typography(12);
    padding-right: 0;
    .product-info &{ 
        width: 82px;
        padding-right: 0;
    }
    .product-summary &, 
    .multi-shipping & {
        width: 82px;
        //width: calc(100% - 300px);
    }
    .line-item-total-price-amount.discount {
        color: $product-sale-price;
    }
    .bundled-line-item .order-product-summary  & {
        width: 30%;
    }
    .position-absolute {
        bottom: 10px;
        right: 0;
    }
    @include media-breakpoint-up(lg) {
        padding-right: 0;
    }
}
.cart .prime-logo {
    margin: 0;
}

.fa-back {
    width: $minicart-back-icon-width;
    height: $minicart-back-icon-height;
    background: url(#{$minicart-back-icon-svg}) no-repeat center;
    background-size: contain;
}
 
.flyout-wrapper {
    .header-welcome-message {
        padding: $spacer*1.75 $spacer $spacer;
        @include media-breakpoint-down (sm) {
            font-size: $paragraph-font-size-medium;
            line-height: $paragraph-line-height-medium;
        }
    }

    .empty-minicart-content {
        padding: $spacer*0.75 0 $spacer;
        margin: $spacer*0.25 $spacer*2;

        h2 {
            @include typography(10);
        }

        .single-approaching-discount {
            @include typography(13);
        }

        .empty-cart-categories-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 350px;
            margin: 0 auto;

            .category {
                flex: 0 0 50%;

                @include media-breakpoint-up (sm) {
                    flex: 0 0 calc(50% - 16px);
                }

                &:nth-child(even) {
                    padding-left: $spacer*0.5;
                }

                &:nth-child(odd) {
                    padding-right: $spacer*0.5;
                }

                a {
                    text-decoration: none;
                    display: block;
                    margin-bottom: $spacer*0.875;
                }

               div {
                    display: block;
                    margin-bottom: $spacer*0.5;

                    @include media-breakpoint-up (sm) {
                        height: 167px;
                        width: 167px;
                    }
    
                    img {
                        width: 100%;
                        max-width: 100%;
                        border-radius: 10px;
                    }
               }
    
                p {
                    margin: 0;
                    padding: 0;
                    @include typography(13);
                    text-align: center;
                }
            }
        }    
    }

    .cart {
        height: 100dvh;
    }

    .minicart-content-wrapper,
    .empty-minicart-content-wrapper {
        height: calc(100% - 72px);
        display: flex;
        flex-direction: column;
    }

    .shipping-promotion-applied,
    .single-approaching-discount {
        .progress {
            margin: 12px 0;
            position: relative;
            overflow: visible;
            height: $progressbar-height;
            border-radius: $progress-bar-radius;
            background: $progress-bar-background;
            border: 1px solid $progress-bar-border-color;
            &-bar {
                top: 0;
                left: 0;
                z-index: 2;
                margin: -1px 0;
                position: absolute;
                height: $progressbar-height;
                border-radius: $progress-bar-radius;
                background: $progress-bar-background-fill;
            }
            &.shipping-completed {
                border-color: $progress-bar-background-fill;
                .progress-bar {
                    background: $progress-bar-background-fill;
                }
            }
        }
        p {
            margin: 0;
        }
    }

    .single-approaching-discount,
    .shipping-promotion-applied {
        font-size: $body-copy-xs-font-size;
        line-height: $body-copy-xs-line-height;

        p {
            font-size: $body-copy-xs-font-size;
            line-height: $body-copy-xs-line-height;
            @include media-breakpoint-up(md) {
                font-size: $body-copy-xs-font-size;
                line-height: $default-text-line-height;
            }
        }
        &:empty {
            border: 0;
            padding: 0;
            margin: 0;
            display: none;
        }
    }

    .single-approaching-discount {
        padding: $spacer $spacer*1.875;
        color: $brand-primary;
        background-color: $selgo;
    }

    .shipping-promotion-applied {
        text-align: center;
        background-color: $selgo;
        border: 1px solid $selgo;
        border-radius: $card-border-radius;
        margin-bottom: 0;
        padding: $spacer*2 $spacer;
        color: $brand-primary;
        @include media-breakpoint-up(md) {
            padding: $card-no-header-body-padding-top $card-body-padding-right $card-body-padding-bottom $card-body-padding-left;
        }
        .congrats-msg {
            position: relative;
            .content-asset:before {
                width: 24px;
                height: 24px;
                content: '';
                display: inline-block;
                vertical-align: middle;
                background-image: url('../../images/icon-congrats.svg');
                background-size: 100%;
                background-repeat: no-repeat;
                margin-right: 4px;
            }
        }
    }

    .product-summary {
        overflow: unset;
        max-height: inherit;
        padding: $spacer*2 0;
        border-top: 0;
        background-color: $light-gray;

        .minicart-quantity-selector {
            .form-control-sm {
                padding: $spacer*0.75 $spacer*1.125!important;
                @include typography(13);
            }
        }

        .minicart-item {
            .product-line-item {
                padding: 0 $spacer;
            }

            + .minicart-item {
                margin-top: $spacer*2;
                padding-top: $spacer*2;
            }

            .price {
                display: flex;

                .strike-through {
                    order: 1;
                }

                .line-item-total-price-amount {
                    &.discount {
                        margin-right: $spacer*0.375;
                    }
                }
            }
        }

        .item-image, 
        .multi-shipping .item-image {
            background-color: $light-gray;
            width: $spacer*7.375;
            height: $spacer*7.375;
            border-radius: $spacer*0.25;
        }

        .line-item-name {
            width: auto;
            text-overflow: unset;
            white-space: normal;
        }

        .remove-btn {
            top: -$spacer*0.875;
            right: $spacer*1.25;
            padding: 0;
            margin: $spacer*0.5 $spacer*0.75;
            background-color: $light-gray;
        }

        .product-edit {
            position: absolute;
            top: $spacer*2.25;
            right: $spacer*1.0625;
            z-index: 1;
        
            .wishlist {
                padding: $spacer*0.8125 $spacer;
                margin: 0 !important;
        
                span {
                    display: none;
                }
            }
        }

        .line-item-attributes {
            @include typography(15);
            margin-bottom: 0 !important;
        }

        .gift-wrap-toggle-container {
            flex-wrap: wrap !important;
            padding-left: $spacer*1.75;
            padding-bottom: $spacer;

            .gift-wrap-trigger-modal,
            .gift-wrap-toggle-label {
                @include typography(13);
            }

            .custom-control-label {
                line-height: 14px;
                &::before {
                    width: $spacer*1.25;
                    height: $spacer*1.25;
                    left: -$spacer*1.625;
                }

                &::after {
                    width: $spacer*1.25;
                    height: $spacer*1.25;
                    left: -$spacer*1.625;
                }
            }

            .custom-control-input {
                &:checked{
                    ~ .custom-control-label {
                        &::before {
                            width: $spacer*1.25;
                            height: $spacer*1.25;
                            left: -$spacer*1.625;
                        }

                        &::after {
                            width: $spacer*1.25;
                            height: $spacer*1.25;
                            left: -$spacer*1.625;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }

    .preorder-cart-msg {
        display: flex;
        padding: $spacer $spacer 0 13px;
        font-size: 15px;

        .icon {
            &::before {
                font-size: 24px;
                font-weight: 500;
                padding: 2px 5px 0 0;
            }
        }
    }

    .promo-codes {
        &.card {
            padding: $spacer*1.625 0;
            border-top: 1px solid $medium-gray;
            border-bottom: 1px solid $medium-gray;
            border-radius: 0;

            .card-header,
            .card-body,
            .card-footer {
                padding: 0 $spacer !important;
                background-color: transparent;
                border: 0 !important;

                label {
                    display: none;
                }

                .btn {
                    padding: $spacer*0.5 $spacer !important;
                }
            }

            .card-body {
                padding: $spacer*0.5 $spacer $spacer*0.75 !important;

                .form-group {
                    .row {
                        flex-wrap: nowrap;
                        align-items: flex-start;
                    }
                }

                .col-md-12 {
                    flex: auto;
                    flex-grow: 1;
                    max-width: inherit;
                    width: auto;
                    padding-right: 0 !important;
                }

                .promo-code-submit {
                    flex: inherit;
                    width: inherit;
                    max-width: inherit;
                    padding-left: $spacer*0.5;
                    padding-right: $spacer;
                    margin-top: 0 !important;
                }
            }

            .card-footer {
                border: 0;

                .promotion-information {
                    display: none;
                }
            }

            &.collapsible-xl {
                .title {
                    @include typography(11);

                    &::after {
                        top: 0;
                        right: 7px;
                    }
                }
            }

            .leading-lines .start-lines span,
            .leading-lines .end-lines span {
                background-color: transparent;
            }
        }
    } 

    .card-header {
        &.cart-order-summmary {
            display: none;
        }
    }

    .card {
        &.totals {
            padding: $spacer*0.5 $spacer;

            @include media-breakpoint-up(md) {
                padding: $spacer*0.5 $spacer 0;
            }

            &.card-total-emea {
                padding-top: $spacer;
            }

            .card-body {
                padding: 0;
            }

            .leading-lines .start-lines span,
            .leading-lines .end-lines span {
                background-color: transparent;
                color: $primary-black;
            }

            .shipping-method,
            .shipping-country-selection {
                margin-bottom: $spacer*1.25;

                .shipping-method-section-label {
                    margin-bottom: $spacer*0.5;
                }

                .custom-select, 
                select.form-control, 
                select {
                    border-width: 1px !important;
                    line-height: $spacer*1.5;
                    padding: $spacer*0.625 $spacer*1.875 $spacer*0.625 $spacer*0.625 !important;
                    background-image: url(#{$minicart-shipping-icon-svg});
                    background-size: $spacer*0.75;
                }
            }

            .shipping-country-selection {
                display: none;
            }

            .row {
                height: auto;

                &.estimated-total {
                    margin-bottom: $spacer*1.25;
                }
            }

            p {
                &.cart-sub-total {
                    margin-bottom: $spacer*0.5;
                }
            }

            .line-item-divider {
                margin-top: $spacer*0.125;
                margin-bottom: $spacer*1.125;
            }
        }
    }

    .shipping-promotion-applied {
        padding: $spacer $spacer*1.875;
        background-color: $selgo;
        border-radius: 0;

        .congrats-msg {
            @include typography(13);
            text-align: center;
            margin-bottom: $spacer*0.75;
        }
    }

    .product-addons {
        padding: 0 16px;
        margin-bottom: 0;
        background-color: $light-gray;

        .non-input-label {
            padding: 0 0 $spacer*0.75 0;
            @include typography(11);
        }

        .product {
            margin-bottom: $spacer*0.75;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .items {
            border: 0;
            border-radius: 12px;
            background: $white;
            box-shadow: 0 0 1rem 0 rgba(0,0,0, 0.2);
            margin-bottom: $spacer*2;
            padding: $spacer;
        }

        .product-tile {
            padding: 0;

            .tile-image-wrap {
                margin-bottom: 0;
            }

            img {
                height: 96px;
                width: 96px;
                background-color: $light-gray;
                border-radius: $spacer*0.25;
            }

            > div {
                flex-basis: 55%;
                @include media-breakpoint-down(sm) {
                    flex-basis: 46%;
                }

                .pdp-link {
                    padding: 0 $spacer*0.5;
                }

                &.product-tile--buy-container {
                    flex-basis: 22%;

                    @include media-breakpoint-down(sm) {
                        flex-basis: 26%;
                    }
                }
            }

            .link {
                @include typography(13);
            }

            .price {
                @include typography(12);
                margin: 0;
                padding: 0 $spacer*0.5;
                justify-content: flex-start;

                > span {
                    display: flex;

                    del {
                        order: 1;

                        ~ .sales {
                            float: none;
                            margin-right: $spacer*0.25;
                        }
                    }
                }
            }

            &::before {
                display: none;
            }

            .btn-addon-to-cart {
                padding: $spacer*0.5 $spacer;
                display: flex;
                justify-content: space-around;
                @include typography(13);
            }
        }
    }

    .minicart-footer {
        border-top: 0;
        box-shadow: 0px -18px 18px 0px rgba(142, 142, 142, 0.12);
        background-color: $white;
        transition: bottom 0.3s ease-in-out;

        @include media-breakpoint-down (sm) {
            padding-bottom: $spacer*0.5;
        }

        @include media-breakpoint-up (md) {
            padding-bottom: $spacer*1.125;
        }

        &.swipe-up {
            position: relative;
            z-index: 4;

            .cartAdditionalPaymentButtons,
            .buy-with-prime-wrapper {
                position: static;
                top: 0;
            }
        }

        .btn-add-to-cart, 
        .btn.add-to-cart, 
        .btn.add-to-cart-global {
            color: $white;
            margin-bottom: $spacer*0.5 !important;

            &::after {
                background-color: $primary-black;
            }
            &.disabled::after {
                background-color: $button-disabled-background;
            }
        }

        .cartAdditionalPaymentButtons {
            margin-top: $spacer;
            position: absolute;
            top: 110%;

            .js-paymentmethod-mutationobserver {
                .paypalLoader {
                    &.paypal-loader-container {
                        display: none !important;
                    }
                }

                margin-top: 0 !important;
            }
        }

        .buy-with-prime-wrapper {
            margin-top: $spacer*0.5;
            position: absolute;
            top: 110%;
            .btn-bwp {
                margin-bottom: 0 !important;
            }
        }

        .checkout-continue {
            .swipe-up-minicart-btn {
                position: absolute;
                background-color: $button-swipe-up-chekcout-options;
                color: $button-swipe-up-chekcout-options;
                left: 0;
                width: 100%;
                padding: $spacer;
                z-index: 2;
                display: inline;
            }

            .cross-sell-btn {
                position: absolute;
                top: 0;
                z-index: 1;
                display: block;
                width: 93%;
                height: $spacer*3.5;
                text-align: center;

                .quickview {
                    display: block;
                    width: 100%;
                    height: $spacer*3.5;
                    margin: 0 !important;
                    text-indent: -99999px;
                    overflow: hidden;
                }
            }
        }

        .checkout-btn {
            &.position-relative {
                pointer-events: none;
                .veil {
                    .underlay {
                        border-radius: $spacer*2;
                        opacity: 1;
                    }

                    .spinner {
                        height: 20px;
                        width: 20px;
                        margin-top: -20px;
                        margin-left: -20px;
                    }
                }
            }
        }
    }
}

.minicart-swipe-up-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $modal-backdrop-background-color;
    z-index: 3;
    display: none;
}