@import "~core/components/wishlist";

.wishlist-not-available { 
    color: $red;
}

.wishlistItemCards {
    .select-attributes-btn {
        white-space: nowrap;
    }
    .line-item-availability {
        margin-bottom: $spacer*0.5;
        @include typography(13);

        .line-item-attributes {
          white-space: normal;
        }
        .notavailabe-cart {
            color: $red;

            &:after {
                display: none;
            }
        }
    }
    .product-info {
        .line-item-name {
            @include media-breakpoint-down (md) {
                width: calc(100% - 50px);
            }

            @include typography(12);
        }

        .item-attributes {
            @include typography(13);

            @include media-breakpoint-up (lg) {
                padding: $spacer*0.5 0;
                width: calc(100% - 196px);
            }

            .line-item-attributes {
                @include typography(13);
            } 

            .line-item-price {
                @include typography(12);
            }
        }
    }

    .btn {
        &.add-to-cart, 
        &.add-to-cart-global {
            height: $spacer*2.25;
            min-height: $spacer*2.25;
            padding: $spacer*0.5 $spacer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            @include typography(13);

            &:after {
                background-color: $primary-black;
            }
        }
    }


    .wishlist-remove-btn {
        margin: 0;
        background: transparent;
        top: -$spacer*0.3125;
        left: auto;
        right: $spacer*0.25;
        color: $black;

        @include media-breakpoint-up (lg) {
            right: $spacer*0.75;
            top: $spacer*0.1875;
        }

        &::before {
            color: $black;
        }
    }

    .product-info .item-image {
        width: 118px;
        @include media-breakpoint-up (lg) {
            width: 180px;
        }
    }

    .card-body {
        border-radius: $spacer*1.5 !important;
        padding: $spacer*1.5 $spacer $spacer*1.5 $spacer*0.40625;

        @include media-breakpoint-up (lg) {
            border-radius: $spacer*1.25 !important;
            padding: $spacer;
        }
    }
}

.add-to-wishlist-messages {
    top: auto;
    right: 0;
    transform: none;
    bottom: 0;
    left: 0;
    padding: $spacer*2;
    border-radius: $spacer*0.5;
    background: $white;
    box-shadow: 0px -12px 44px 0px rgba(138, 138, 138, 0.12);

    @include media-breakpoint-up(lg) {
        top: auto;
        left: auto;
        right: $spacer*2.5;
        bottom: $spacer*4.375;
        width: 400px;
    }

    .add-to-wishlist-alert {
        background: transparent !important;
        border-color: transparent !important;
        margin-bottom: 0;
        padding: 0;
        position: static;
        @include typography(19);
        font-family: $secondary-font;

        @include media-breakpoint-up(lg) {
            font-family: $secondary-font;
        }
    }

    .wishlist-product-name {
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        font-weight: $body-copy-m-font-weight;
        font-family: $primary-font;
        margin-bottom: $spacer*2.1875;
    }

    .btn {
        &.btn-primary {
            height: $spacer*3.5;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.close {
            position: absolute;
            top: $spacer*1.4375;
            right: $spacer;
            padding: 0;
            opacity: 1;
            font-size: $paragraph-font-size;
        }
    }
}

.dont-lose-wishlist-msg {
    border-radius: $spacer*0.75;
    border: 0.75px solid $medium-gray;
    padding: $spacer*1.5 $spacer $spacer*1.25;
    margin-bottom: $spacer*0.75;

    @include media-breakpoint-up (lg) {
        padding: $spacer*1.5 $spacer*0.4375 $spacer*1.5 $spacer;
        margin-bottom: $spacer*2
    }

    p {
        @include typography(13);
    }

    .btn-link {
        @include text-link;
        padding: 0;
        vertical-align: baseline;
        margin-top: 10px;
        border: none;
        @include typography(13);
    }

    .btn-block {
        &.btn-primary {
            margin-bottom: $spacer*0.75;
        }
    }
}

.wishlist-counter {
    padding: $spacer*1.25 0 $spacer*0.75;

    @include media-breakpoint-up (lg) {
        padding: $spacer*2 0;
    }
}

.login-page {
    .card-body {
        border-radius: $spacer*1.5 !important;
        padding: $spacer*2 $spacer $spacer;
        margin-bottom: $spacer*0.75;

        @include media-breakpoint-up (lg){
            padding: $spacer*1.75 $spacer $spacer*1.125;
        }
    }

    .login-form-nav {
        .nav-tabs {
            .nav-link {
                color: $black;
                padding-top: 0;

                @include typography(11);

                &.active {
                    @include typography(10);
                }
            }

            .nav-item {
                padding: 0 $spacer $spacer*0.25 $spacer;

                &:first-child {
                    .nav-link {
                        text-align: right;
                    }
                }
            }
        }
    }

    .login,
    .registration {
        .form-group {
            .form-control-label,
            .custom-control-label {
                @include typography(13);
            }
        }

        .forgot-password {
            a {
                @include typography(13);
            }
        }

        .privacy-policy {
            padding: $spacer*0.5 $spacer*5 $spacer;

            @include typography(13);

            a {
                @include typography(13);
            }
        }

        .validator-container {
            .password-validation-helper {
                li {
                    @include typography(13);
                }
            }
        }

        .btn {
            &.btn-block {
                &.btn-primary {
                    margin: $spacer*3 auto $spacer*0.5;
                    @include media-breakpoint-up (lg) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .registration {
        .btn {
            &.btn-block {
                &.btn-primary {
                    margin: $spacer*1.125 auto $spacer;

                    @include media-breakpoint-up (lg) {
                        margin: $spacer*3 auto $spacer*0.5;
                    }
                }
            }
        }
    }

    .login-oauth {
        @include media-breakpoint-up (lg) {
            padding: 0 $spacer 0;
        }
    }
}