// extend this file from rvw_autobhan_core for PAP-1761
@import "~core/components/video";

.video-container {
    &.rounded-corners {
        overflow: hidden;
        iframe,
        video {
            overflow: hidden;
            border-radius: $border-radius;
        }
        .video-thumbnail,
        .video-thumbnail-image {
            overflow: hidden;
            border-radius: $border-radius;
        }
    }
    &.video-with-background {
        padding: $spacer*1.25 $spacer $spacer*4.5;
        @include media-breakpoint-up(lg) {
            padding: $spacer*6.625 $spacer*2;
        }
        .headline-m {
            font-family: $primary-font;
            @include media-breakpoint-up (lg) {
                margin-bottom: $spacer*4;
            }
            margin-bottom: $spacer*1.25;
        }
    }
}
.video-text-block-text {
    padding-top: $spacer*1.5;
}