@import "~core/components/card";

.card {
    margin-bottom: 0;
    .order-details-col {
        padding: 0;
        >.col {
            padding: 0;
        }
    }
    .order-image-col {
        float: left;
        width: 100px;
        padding: 0 10px 0 0;
    }
    .nfc-all-devices-link {
        float: right;
        font-size: $nav-sub-link-font-size;
        text-decoration: underline;
        text-transform: capitalize;
        &:hover {
            text-decoration: none;
        }
    }
    .nfc-device-form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        select {
            margin: 0 0 10px;
        }
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            select {
                margin: 0;
            }
        }
        .btn-primary {
            margin-right: 10px;
            margin-left: 0;
            @include media-breakpoint-up(md) {
                margin-left: 10px;
                margin-right: 0;
            }
        }
        .nfc-reset-device {
            background: #181818;
        }
    }
    .card-header {
        border: none;
        padding-top: $card-header-padding-top;
        padding-left: $card-header-padding-left;
        padding-right: $card-header-padding-right;
        padding-bottom: $card-header-padding-bottom;

        @include media-breakpoint-up(lg) {
            padding-top: $card-header-padding-top-lg;
            padding-left: $card-header-padding-left-lg;
            padding-right: $card-header-padding-right-lg;
            padding-bottom: $card-header-padding-bottom-lg;
        }

        .pull-right {
            padding-top: 0;
        }
    }
    .card-title,
    .card-header h2,
    .card-header h3,
    .card-header h4,
    .card-header .checkout-card-header,
    h2.card-header {
        padding: 0;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
    
    .card-body {
        border: none;
        border-radius: $card-border-radius;
        margin-bottom: $card-margin-bottom;
        padding: $card-body-padding-top $card-body-padding-right $card-body-padding-bottom $card-body-padding-left;
        @include media-breakpoint-up(lg) {
            padding: $card-no-header-body-padding-top-lg;
        }
        &:first-child {
            border-top: none;
        }
        &:last-child {
            border-bottom: none;
            border-radius: $border-radius;
        }
    }
    .card-body:not(:last-child) {
        border-radius: 0;
        margin-bottom: 0;
    }
    .card-header ~ .card-body {
        &:last-child {
            border-radius: 0 0 calc(#{$card-border-radius} - 1px) calc(#{$card-border-radius} - 1px);
        }
        @include media-breakpoint-up(lg) {
            padding: $card-body-padding-top-lg $card-body-padding-right-lg $card-body-padding-bottom-lg $card-body-padding-left-lg;
        }
    }
    .card-footer {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
    .refinements & {
        .card-title {
            display: block;
        }
    }
    &.order-history-card {
        border: 1px solid $black;
        margin-bottom: $spacer*2;
        @include media-breakpoint-up(lg) {
            max-width: 48.5%;
            min-width: 48.5%;
        }
        .card-header {
            .pull-right {
                text-decoration: none;
                font-weight: $font-weight-medium;
                padding: $spacer*0.25 $spacer*0.625;
                border: 2px solid $black;
                border-radius: $spacer*1.125;
                @include media-breakpoint-down(lg) {
                    float: left;
                    margin-top: $spacer;
                    clear: both;
                }
            }
            .pull-left {
                margin-top: 6px;
            }
        }
        .card-body {
            padding: $spacer $spacer*2;
            margin-bottom: 0;
        .order-image-col {
            width: 100%;
            @include media-breakpoint-down(lg) {
                margin-bottom: $spacer;
                text-align: center;
            }
        }

        }
        .card-footer {
            padding: 0 $spacer;
            .card-footer-wrapper {
                @include media-breakpoint-down(lg) {
                    display: flex;
                    justify-content: space-between;
                }
            }
            .card-info-group {
                @include media-breakpoint-down(lg) {
                    max-width: 49%;
                    min-width: 49%;
                }
                .orderTotal,.totalItems {
                    font-weight: $body-copy-m-font-weight;
                }
            }
        }
    }
    .coupon-code {
        display: flex;
        align-items: center;
        .coupon-style {
            width: 20px;
            height: 20px;
            margin: 0 $modal-margins-mobile 0 0;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            &:has(li:nth-child(2)) {
                li:first-child {
                    margin-top: 10%;
                }
            }
            li {
                font-family: $primary-font;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }
        }
        button {
            cursor: pointer;
            margin-left: auto;
            padding-right: 8px;
        }
        .coupon-not-applied {
            font-family: $primary-font;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
    }
}

.page-nfc {
    .login-oauth {
        display: none;
    }
}
.order-list-container {
    padding: 0 8px;
    @include media-breakpoint-up(md) {
        padding: 0 16px;
    }
}