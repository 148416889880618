@import "~core/components/dropdown";

.dropdown-menu {
    padding: $dropdown-menu-padding;
    .dropdown-item {
        padding: 0;
        margin: $megamenu-link-margin-top 0 $megamenu-link-margin-bottom;
        position: relative;
        //do not override background size helper classes.PAP-56
        .dropdown-link {
            color: inherit;
            &:hover {
                text-decoration: none;
                .cat-text {
                    text-decoration: $dropdown-item-hover-text-decoration;
                }

                .cat-sub-nav-badge {
                    text-decoration: none;
                }
            }
        }
        &:hover {
            text-decoration: none;
            background: $dropdown-item-hover-background;

            .cat-sub-nav-badge {
                text-decoration: none;
            }
        }

        &.custom-dropdown {
            .dropdown-menu {
                &.show {
                    @include media-breakpoint-up(lg) {
                        overflow: hidden;
                    }
                }
            }
        }
    }
    &.mega-standard .dropdown-item {
        background: $dropdown-item-hover-background;
        border-radius: $border-radius;
    }
    .dropdown-link {
        display: block;
    }
}

.nav-item-cat {
    background-repeat: no-repeat;
    background-position: 90% 50%;
    @include media-breakpoint-down(md) {
        background-color: $dropdown-item-hover-background;
        border-radius: $border-radius;
        margin: 0 $spacer $spacer;
    }
    @include media-breakpoint-up(lg) {
        background-image: none!important;
    }
}

.navbar-nav .megamenu > .dropdown-menu .menu-subcategories {

    .dropdown-item {
        .dropdown-item {
            background-color: transparent!important;
            border: none;
            padding: 0;
        }
    }
    
    > .dropdown-item.custom-dropdown {
        .dropdown-menu .dropdown-link {
            padding: 0 30px;
            background: transparent;
            height: auto;

            &.nav-link {
                @include media-breakpoint-down(md) {
                    padding: $spacer 0;
                }
            }
        }
    }
    .dropdown-menu {
        .dropdown-link {
            padding: 0;
            margin: 0;
        }
    }
    .dropdown-item.custom-dropdown .dropdown-menu,
    .dropdown-item {
        background-repeat: no-repeat!important;
        background-position: 90% 50%!important;

        @include media-breakpoint-down(md) {
            background-position: 92% 50%!important;  
        }
        //do not override background size helper classes. PAP-56, PAP-294
        &:not(.background--cover):not(.background--contain) {
            background-size: 56px auto;
        }
    }
}

.navbar {
    .dropdown-toggle {
        .nav-item > & {
            border-bottom: 2px solid transparent;
        }
        .nav-item.show > & {
            border-color: $link-color;
        }
        &:after {
            content: " ";
        }   
    }
}

.dropdown-tagline {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    ul, li, button, a {
        @include format-dropdown-text;
    }

    .card,
    .card-body {
        background: $white;
    }
}