@import "~core/mixins";

// apply typography styles, usage: @include typography(2) // headline-l styles
@mixin typography($typographyIndex) {
    $value: nth($typography, $typographyIndex);

    font-family: nth($value, 2);
    font-size: nth($value, 3);
    line-height: nth($value, 4);
    text-transform: nth($value, 5);
    font-weight: nth($value, -2);
    letter-spacing: nth($value, -1);
    font-style: normal;
    font-display: swap;

    @include media-breakpoint-up(lg) {
        font-family: nth($value, 6);
        font-size: nth($value, 7);
        line-height: nth($value, 8);
        text-transform: nth($value, 9);
        font-weight: nth($value, -2);
        letter-spacing: nth($value, -1);
        font-style: normal;
        font-display: swap;
    }
}

// apply header styles, usage: @include header(2) // h2 styles
@mixin header($headerIndex) {
    $value: nth($headers, $headerIndex);

    font-family: nth($value, 2);
    font-size: nth($value, 3);
    line-height: nth($value, 4);
    text-transform: nth($value, 5);
    letter-spacing: $default-text-letter-spacing;
    font-weight: $default-text-font-weight;
    font-style: normal;
}

@mixin disabled {
    pointer-events: none;
}

@mixin button($style: "", $customBackground: "", $customText: "", $customBorder: "") {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: $button-border-width;
    border-style: solid;
    font-family: $button-font;
    text-transform: $button-text-transform;
    transition: all $transition-speed ease-out;
    text-decoration: none;
    border-radius: $button-border-radius;
    text-align: $button-text-align;
    font-weight: $button-font-weight;
    text-shadow: none;
    z-index: 1;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: $button-border-radius;
        z-index: -1;
        transition: all 200ms ease-out;
    }

    &:hover,
    &:focus,
    &[aria-describedby*="popover"] {
        &:after {
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            top: -4px;
            left: -4px;
        }
    }

    @if $style == small {
        font-size: $button-sm-font-size;
        line-height: $button-sm-line-height;
        padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
        letter-spacing: $button-sm-letter-spacing;
    } @else if $style == large {
        font-size: $button-lg-font-size;
        line-height: $button-lg-line-height;
        padding: $button-lg-padding-top $button-lg-padding-right $button-lg-padding-bottom $button-lg-padding-left;
        letter-spacing: $button-lg-letter-spacing;
    } @else {
        font-size: $button-font-size;
        letter-spacing: $button-letter-spacing;
        line-height: $button-line-height;
        padding: $button-padding-top $button-padding-right $button-padding-bottom $button-padding-left;
    }
    @if $style == primary {
        font-family: $button-primary-font;
        text-transform: $button-primary-text-transform;
        color: $button-primary-color;
        background-color: transparent;
        border: none;
        text-decoration: $button-primary-text-decoration;
        text-align: $button-primary-text-align;
        font-weight: $button-primary-font-weight;
        padding: $button-primary-padding-top $button-primary-padding-right $button-primary-padding-bottom $button-primary-padding-left;
        &:after {
            background-color: $button-primary-background;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-primary-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
            &:after {
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                top: -4px;
                left: -4px;
                background-color: $primary-black-hover;
            }
        }
        .dark-theme & {
            color: $button-primary-dark-theme-color;
            &:after {
                background-color: $button-primary-dark-theme-background;
            }
            &:hover,
            &:focus,
            &[aria-describedby*="popover"] {
                color:  $button-primary-dark-theme-color-hover;
            }
            &.disabled,
            &:disabled,
            &[disabled] {
                color: $button-disabled-color;
                &:after {
                    background-color: $button-disabled-background;
                }
            }
        }
        &.disabled,
        &:disabled,
        &[disabled] {
            color: $button-disabled-color;
            &:after {
                background-color: $button-disabled-background;
            }
        }
    }
    @if $style == secondary {
        font-family: $button-secondary-font;
        text-transform: $button-secondary-text-transform;
        color: $button-secondary-color;
        background-color: transparent;
        border: none;
        text-decoration: $button-secondary-text-decoration;
        text-align: $button-secondary-text-align;
        font-weight: $button-secondary-font-weight;
        padding: $button-secondary-padding-top $button-secondary-padding-right $button-secondary-padding-bottom $button-secondary-padding-left;
        &:after {
            background-color: $button-secondary-background;
            border-width: $button-secondary-border-width;
            border-radius: $button-secondary-border-radius;
            border-color: $button-secondary-border-color;
            border-style: solid;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-secondary-color-hover;
            text-decoration: $button-secondary-text-decoration-hover;
            &:after {
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                top: -4px;
                left: -4px;
            }
        }
        .dark-theme & {
            color: $button-secondary-dark-theme-color;
            &:after {
                border-color: $button-secondary-dark-theme--border-color;
                background-color: $button-secondary-dark-theme-background;
            }
            &:hover,
            &:focus,
            &[aria-describedby*="popover"] {
                color:  $button-secondary-dark-theme-color-hover;
            }
            &.disabled,
            &:disabled,
            &[disabled] {
                color: $button-disabled-background;
                &:after {
                    border-color: $button-disabled-background;
                }
            }
        }
        &.disabled,
        &:disabled,
        &[disabled] {
            color: $button-disabled-background;
            &:after {
                border-color: $button-disabled-background;
            }
        }
    }
    @if $style == tertiary {
        font-family: $button-tertiary-font;
        text-transform: $button-tertiary-text-transform;
        color: $button-tertiary-color;
        border-width: $button-tertiary-border-width;
        border-radius: $button-tertiary-border-radius;
        background-color: $button-tertiary-background;
        border-color: $button-tertiary-border-color;
        text-decoration: $button-tertiary-text-decoration;
        text-align: $button-tertiary-text-align;
        font-weight: $button-tertiary-font-weight;
        padding: $button-tertiary-padding-top $button-tertiary-padding-right $button-tertiary-padding-bottom $button-tertiary-padding-left;

        &:after {
            display: none;
        }

        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-tertiary-color-hover;
            background-color: $button-tertiary-background-hover;
            border-color: $button-tertiary-border-color-hover;
            text-decoration: $button-tertiary-text-decoration-hover;
        }
        .dark-theme & {
            color: $button-tertiary-dark-theme-color;
            border-color: $button-tertiary-dark-theme--border-color;
            &:hover,
            &:focus,
            &[aria-describedby*="popover"] {
                color: $button-tertiary-dark-theme-color-hover;
                border-color: $button-tertiary-dark-theme--border-color;
            }
            &.disabled,
            &:disabled,
            &[disabled] {
                color: $button-disabled-color;
            }
        }
        &.disabled,
        &:disabled,
        &[disabled] {
            color: $button-disabled-color;
        }
    }
    @if $style == add-to-cart {
        font-family: $button-add-to-cart-font;
        text-transform: $button-add-to-cart-text-transform;
        color: $button-add-to-cart-color;
        background: transparent;
        border: none;
        text-decoration: $button-add-to-cart-text-decoration;
        text-align: $button-add-to-cart-text-align;
        font-weight: $button-add-to-cart-font-weight;
        font-size: $button-lg-font-size;
        padding: $button-add-to-cart-padding-top $button-add-to-cart-padding-right $button-add-to-cart-padding-bottom $button-add-to-cart-padding-left;
        line-height: $button-lg-line-height;
        letter-spacing: $button-lg-letter-spacing;
        min-height: calc(#{$button-lg-line-height} + #{$button-lg-padding-top} + #{$button-lg-padding-bottom});

        &:after {
            background-color: $button-add-to-cart-background;
        }

        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            text-decoration: $button-add-to-cart-text-decoration-hover;
            &:after {
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                top: -4px;
                left: -4px;
            }
        }
        .dark-theme & {
            color: $button-add-to-cart-dark-theme-color;
            &:after {
                background-color: $button-add-to-cart-dark-theme-background;
            }
            &:hover {
                color: $button-add-to-cart-dark-theme-color-hover;
            }
            &.disabled,
            &:disabled,
            &[disabled] {
                color: $button-disabled-color;
                &:after {
                    background-color: $button-disabled-background;
                }
            }
        }
        &.disabled,
        &:disabled,
        &[disabled] {
            color: $button-disabled-color;
            &:after {
                background-color: $button-disabled-background;
            }
        }
    }
    @if $style == custom {
        background: $customBackground;
        color: $customText;
        border-color: $customBorder;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            background: darken($customBackground, 5%);
            border-color: darken($customBorder, 5%);
        }
    }

    

    // If icon class is applied directly to button
    &[class*="icon-"],
    &[class^="icon-"] {
        padding-left: $button-padding-icon;
        @if $style == small {
            padding-left: $button-padding-icon;
            &:before {
                @include button-icon-styles(before, $button-sm-icon-size, inherit);
            }
        } @else if $style == large {
            padding-left: $button-lg-padding-icon;
            &:before {
                @include button-icon-styles(before, $button-lg-icon-size, inherit);
            }
        } @else {
            &:before {
                @include button-icon-styles(before, $button-icon-size, inherit);
            }
        }
    }
}

@mixin icon($icon, $position: before, $size: inherit, $color: inherit) {
    @if $icon != '' {
        &:#{$position} {
            content: $icon;
            @include icon-styles($size, $color);
        }

        // Handle mixin being applied to button, rather than a span inside it
        // also hides any existing font-awesome icon to avoid template changes
        // does not apply to buttons with a class of 'title', since those are collapsible items
        &[class*="btn-"]:not(.title) {
            &:#{$position} {
                @include button-icon-styles($position, $button-icon-size, $color);
            }
            @if $position == before {
                padding-left: $button-padding-icon;
            }
            @if $position == after {
                padding-right: $button-padding-icon;
            }
            &.btn-sm {
                &:#{$position} {
                    @include button-icon-styles($position, $button-sm-icon-size, $color);
                }
                @if $position == before {
                    padding-left: $button-padding-icon;
                }
                @if $position == after {
                    padding-right: $button-padding-icon;
                }
            }
            &.btn-lg {
                &:#{$position} {
                    @include button-icon-styles($position, $button-lg-icon-size, $color);
                }
                @if $position == before {
                    padding-left: $button-lg-padding-icon;
                }
                @if $position == after {
                    padding-right: $button-lg-padding-icon;
                }
            }
            [class*="icon-"],
            [class^="icon-"],
            .fa {
                display: none;
            }
        }
    }
}

@mixin checkbox-icon-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    border: 1px solid $radiocheck-border-color;
    background-color: $radiocheck-background-color;
    border-radius: $radiocheck-border-radius;
}

@mixin checkbox-icon-checked-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    background-color: $radiocheck-background-color-selected;
    border: 1px solid $radiocheck-border-color-selected;
    border-radius: $radiocheck-border-radius;
}

@mixin filter-checkbox-icon-background {
    content: "";
    width: $filter-radiocheck-button-size;
    height: $filter-radiocheck-button-size;
    border: 1px solid $radiocheck-border-color;
    background-color: $radiocheck-background-color;
    border-radius: $filter-radiocheck-border-radius;
}

@mixin filter-checkbox-icon-checked-background {
    content: "";
    width: $filter-radiocheck-button-size;
    height: $filter-radiocheck-button-size;
    background-color: $radiocheck-background-color-selected;
    border: 1px solid $radiocheck-border-color-selected;
    border-radius: $filter-radiocheck-border-radius;
}

@mixin filter-checkbox-icon-checked {
    content: $icon-checkmark;
    width: $filter-radiocheck-button-size;
    height: $filter-radiocheck-button-size;
    line-height: calc(#{$filter-radiocheck-button-size} + 2px);
    background: none;
    font-family: $icomoon-font-family;
    font-size: $filter-radiocheck-button-size/2;
    text-align: center;
    color: $radiocheck-icon-color;
}

@mixin filter-radio-icon-background {
    content: "";
    width: $filter-radiocheck-button-size;
    height: $filter-radiocheck-button-size;
    border: 1px solid $radiocheck-border-color;
    border-radius: 50%;
    background-color: $radiocheck-background-color;
}

@mixin filter-radio-icon-checked-background {
    width: $filter-radiocheck-button-size;
    height: $filter-radiocheck-button-size;
    border-radius: 50%;
    background-color: $radiocheck-icon-color;
    border: 1px solid $radiocheck-border-color-selected;
}

@mixin filter-radio-icon-checked {
    content: "";
    background: none;
    width: $filter-radiocheck-button-size - $filter-radiobutton-selected-inset-amount;
    height: $filter-radiocheck-button-size - $filter-radiobutton-selected-inset-amount;
    top: calc(#{$filter-radiobutton-selected-inset-amount}/ 2);
    left: calc(-#{$filter-radiocheck-button-size + $filter-radiocheck-button-margin} + #{$filter-radiobutton-selected-inset-amount}/ 2);
    background-color: $radiocheck-background-color-selected;
    border-radius: 50%;
}

@mixin radio-check-label {
    font-family: $radiocheck-label-font;
    font-weight: $radiocheck-label-font-weight;
    font-size: $radiocheck-label-font-size;
    line-height: $radiocheck-label-line-height;
    text-transform: $radiocheck-label-text-transform;
    letter-spacing: $radiocheck-label-letter-spacing;
    vertical-align: middle;
    transition: box-shadow $transition-speed ease-out;
    &:hover:before {
        box-shadow: $input-box-shadow-hover;
    }
}

@mixin attribute-radio-icon-background {
    content: "";
    width: $attribute-radiocheck-button-size;
    height: $attribute-radiocheck-button-size;
    border-radius: 50%;
    background-color: $attribute-radiocheck-background-color;
    border: 1px solid $attribute-radiocheck-border-color;
}

@mixin attribute-radio-icon-checked {
    content: $icon-checkmark;
    font-family: $icomoon-font-family;
    font-size: $attribute-radiocheck-button-size/2;
    line-height: $attribute-radiocheck-button-size - $radiobutton-selected-inset-amount;
    background: none;
    width: $attribute-radiocheck-button-size - $radiobutton-selected-inset-amount;
    height: $attribute-radiocheck-button-size - $radiobutton-selected-inset-amount;
    top: calc(50% - 10px);
    left: calc(#{$attribute-radiocheck-button-size} + 6px);
    color: $attribute-radiocheck-icon-color;
    text-align: center;
}

@mixin vertical-slider-thumbnails {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    .tns-outer,
    .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important; // hide scrollbars
        overflow: auto;
        max-height: 100%;
    }
    .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important;
    }
    .slide {
        display: block;
        width: 100% !important;
        padding-right: $spacer / 2 !important;
        &+.slide {
            margin-top: $slider-thumbnail-spacing;
        }
    }
    .slider-thumbnail-controls {
        .prev,
        .next {
            left: 0;
            text-align: center;
            height: $slider-thumbnail-button-size;
            width: $slider-thumbnail-width;
            @include media-breakpoint-up(md) {
                width: $slider-thumbnail-width-md;
            }
            @include media-breakpoint-up(lg) {
                width: $slider-thumbnail-width-lg;
            }
            &::before {
                transform: rotate(90deg);
                top: auto;
                bottom: 0;
                left: calc(50% - #{$slider-thumbnail-button-icon-size/2});
                width: $slider-thumbnail-button-size;
                height: $slider-thumbnail-button-icon-size;
            }
        }
        .prev {
            top: 0;
        }
        .next {
            top: auto;
            bottom: 0;
        }
    }
}

@mixin format-dropdown-text {
    display: flex;
    text-decoration: none;
    span {
        margin: auto;
        line-height: $card-header-line-height;
    }
}

$customize-icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M15.4462 9.19774C15.4462 12.9716 12.4191 16 8.72308 16C5.02702 16 2 12.9716 2 9.19774C2 5.4239 5.02702 2.39551 8.72308 2.39551C12.4191 2.39551 15.4462 5.4239 15.4462 9.19774Z' stroke='url(%23paint0_linear_432_5183)' stroke-width='4'/%3E%3Cpath d='M10.3859 9.99504L7.49839 10.8875C7.41781 10.9125 7.32989 10.892 7.26939 10.8342C7.20888 10.7765 7.18563 10.6908 7.20888 10.6112L8.04114 7.75703C8.05163 7.72138 8.07099 7.68866 8.09739 7.66206L10.3859 9.99504ZM10.3859 9.99504C10.4222 9.98395 10.4549 9.96418 10.4814 9.93749L10.3859 9.99504ZM15.4203 0.56685C15.4201 0.567028 15.4199 0.567206 15.4197 0.567384L8.29433 7.81833L8.54939 8.06181L10.0544 9.49845L10.3165 9.74864L17.4482 2.491L17.4489 2.49033C17.646 2.29122 17.7537 2.02469 17.7499 1.74929L15.4203 0.56685ZM15.4203 0.56685C15.6182 0.368282 15.8892 0.253852 16.1743 0.250095C16.4598 0.246337 16.7342 0.353786 16.9376 0.547424L17.4285 1.01601C17.631 1.20988 17.746 1.47375 17.7499 1.74922L15.4203 0.56685Z' fill='white' stroke='%23181818' stroke-width='0.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_432_5183' x1='0.311538' y1='34.5041' x2='18.4047' y2='34.5041' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FDD22A'/%3E%3Cstop offset='0.166667' stop-color='%23FF9244'/%3E%3Cstop offset='0.34375' stop-color='%23FF93FF'/%3E%3Cstop offset='0.536458' stop-color='%23AF0DFC'/%3E%3Cstop offset='0.697917' stop-color='%2300C4E6'/%3E%3Cstop offset='1' stop-color='%231EE1B2'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E";
.svg-icon {
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;

    &.customize-icon {
        background-image: url($customize-icon);
        height: $spacer*1.25;
        width: $spacer*1.25;
    }
}

@mixin btn-customize {
    position: relative;
    font-size: $base-font-size;
    line-height: $default-text-line-height;
    font-weight: $font-weight-medium;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    border-width: $button-border-width;
    border-style: solid;
    font-family: $primary-font;
    text-transform: none;
    transition: all 200ms ease-out;
    border-radius: $button-border-radius;
    text-shadow: none;
    z-index: 1;
    letter-spacing: $button-letter-spacing;
    text-transform: none;
    color: $primary-black;
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-align: center;
    padding: $spacer*0.5 $spacer*2 $spacer*0.5 $spacer;

    @include media-breakpoint-up(md) {
        padding: $spacer*0.5 $spacer*2.75 $spacer*0.5 $spacer;
    }

    &::after {
        content: "";
        display: inline-block;
        background-image: url($customize-icon);
        background-repeat: no-repeat;
        height: $spacer*1.25;
        width: $spacer*1.37;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $spacer*0.5;
        left: auto;

        @include media-breakpoint-up(md) {
            right: $spacer;
        }
    }
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: $button-border-radius;
        z-index: -1;
        transition: all 200ms ease-out;
        border: 2px solid $black;
    }
    &:hover,
    &:focus,
    &[aria-describedby*="popover"] {
        &::before {
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            top: -4px;
            left: -4px;
        }
    }
}

// Adds a diagonal line through a swatch, half white and half black to increase visibility on different backgrounds
@mixin strike-through-swatch($size, $dark: $black, $light: $white) {
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(#{$size} + 18px);
        width: 1px;
    }
    &:after {
        background-color: $white;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
        z-index: 2;
        width: 2px;
    }
}
