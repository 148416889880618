@import "~core/product/grid";

.plp {
    .image-text-block.aspect-ratio-portrait .image-wrapper::before {
        padding-top: 0;
    } 
    .promo-tile {
        &.col-sm-6,
        &.col-sm-12 {
            margin-bottom: 45px;
            .image-text-block .image-wrapper .background-image {
                @include media-breakpoint-up(sm) {
                    padding-top: 280px;
                }

                @include media-breakpoint-up(md) {
                    padding-top: 394px;
                }
            }
        }
    }

    .image-text-block {
        &.square-size {
            .image-wrapper {
                .background-image {
                    padding: calc(100% + (#{$spacer} * 2) - (#{$product-tile-padding-right-left} * 2)) 0 0;
                    
                }
            }
        }
    }

    .image-text-block {
        .image-wrapper {
            .background-image {
                position: relative;
                top: initial;
                left: initial;
                width: 100%;
                height: 0;
                background-repeat: no-repeat;
                padding: calc(100% + (#{$product-tile-padding-top-bottom} * 2) - (#{$product-tile-padding-right-left} * 2)) 0 0;
                border-radius: $border-radius;
            }

            &::before {
                padding-top: 0;
            }
        }
        &.aspect-ratio-portrait .image-wrapper::before {
            padding-top: 0;
        }
        & .image-text-block-text,
        &.text-placement-below .image-text-block-text {
            margin-top: $spacer-sm*2;
        }
        &.product-tile {
            .image-wrapper .background-image {
                padding-top: calc(100% + (#{$product-tile-padding-top-bottom} * 2) - (#{$product-tile-padding-right-left} * 2));
            }
        }
    }

    .image-text-block {
        &.medium,
        &.large {
            .image-text-block-text {
                .primary-text {
                    margin-bottom: $product-tile-medium-title-margin-bottom;
                }
            }
        }
    }

    .image-text-block-text {
        .primary-text {
            margin-bottom: $product-tile-title-margin-bottom;
            padding-bottom: 0;
            &:hover {
                text-decoration: $product-tile-title-text-decoration;
            }
            &.text-shadow {
                h1, h2, h3, h4, h5, h6 {
                    text-shadow: $text-shadow;
                }
            }
        }
        .secondary-text {
            &.text-shadow {
                p {
                    text-shadow: $text-shadow;
                }
            }
        }
        .image-text-block-subtitle {
            &.text-shadow {
                h1, h2, h3, h4, h5, h6 {
                    text-shadow: $text-shadow;
                }
            }
        }
    }

    .slider-container {
        &.two-column-slider {
            .tns-controls button {
                top: 50%;
            }
        }
    }

    .grid-header,
    .refinement-bar-horizontal {
        .custom-select:focus, select:focus {
            box-shadow: none;
        }

        .custom-select, select.form-control, select {
            background-image: url(../images/icons/icon-arrow-down.svg);
            background-size: $dropdown-icon-size;
            @include media-breakpoint-up(lg) {
                padding-right: $spacer-xl * 2;
            }
            @include media-breakpoint-down(md) {
                background-size: $product-filter-sort-arrow-size;
            }            
        }
    }
}

.refinement-container {
    border-radius: $border-radius;
    background: $white;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
        box-shadow: 0px $spacer*2 $spacer*4 rgba(0, 0, 0, 0.05);
    }

    .horizontal-filter-refinements-bar {
        padding: $spacer*1.5 $spacer;

        .sort-by-label {
            color: $brand-primary;
            @include typography(10);            
        }

        select[name="sort-order"] {
            color: $brand-primary;
            @include typography(11);
        }
        .refinements-container-right {
            @include media-breakpoint-up(lg) {
                justify-content: space-between;
                min-width: 61.8%;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: $spacer*2;
    }
    .slider-container{
        &.scroll-slider-active {
            .slide {
                @include media-breakpoint-down (sm) {
                    flex: 0 0 calc(42.5% - 10px);
                    padding: 0 5px;
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
        &.scroll-slider-active {
            &.next-slide-40.slider-buttons-hidden {
                @include media-breakpoint-down (sm) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            &.next-slide-22.slider-buttons-hidden {
                @include media-breakpoint-down (sm) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

.refinementproducttype {
    background: $white;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 100%;

    .values.content {
        flex-wrap: unset;
    }
    .slider-container {
        width: 100%;
    }
}

.refinements {
    .refinement-price {
        .values.content {
            li {
                &.col-sm-4 {
                    width: auto;
                    flex-basis: auto;
                    @include media-breakpoint-down (xs) {
                        min-width: 50%;
                    }

                    @include media-breakpoint-up (lg) {
                        padding: 0;
                        button {
                            padding: $spacer;
                        }
                    }
                    .col-md-12{
                        width: auto;
                        flex-basis: auto;
                    }
                }	
            }
        }
    }  
  
    .values.content {
        max-width: 887px;
        &:not(.no-gutters) {
            margin: 0 auto;
        }

        li {
            margin: 0;
            border-radius: 0;
            width: auto;
            border: 0;
            padding: 0 $spacer/2;
            @include media-breakpoint-up(md) {
                margin: 0 $spacer/2 $spacer;
                background-color: transparent;
                padding: $spacer;
                border-radius: 8px;
                width: 100%;
                border: 1px solid transparent;
            }
        }
        button {
            color: $primary-black;
            padding: $spacer*0.625 1px;
            border-radius: 8px;
            width: 100%;
            @include media-breakpoint-up(md) {
                border: 0;
                padding: 0;
                width: auto;
                background: none;
            }
            .swatch-circle {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-size: 35px;
                background-position: -1px -1px;
                &.selected {
                    box-shadow: $color-swatch-selected-shadow;
                }
                &.white,
                &.White {
                    border: 1px solid $medium-gray;
                }
            }
            span:not(.color-value) {
                flex-grow: 1;
            }
            &.selected {
                border-color: $black;
            }
            span {
                @include typography (13);
            }
            &:hover {
                i.fa {
                    box-shadow: 0 0 0 1px $radiocheck-border-color;
                }
            }
            i {
                &.fa {
                    position: relative;
                    vertical-align: middle;
                    margin-right: $product-refinement-swatch-spacing;
                    transition: box-shadow $transition-speed ease-out;
        
                }
                &.fa-circle-o {
                    @include filter-radio-icon-background;
                    margin-top: $spacer*0.125;
                }
                &.fa-check-circle {
                    @include filter-radio-icon-checked-background;
                    margin-top: $spacer*0.125;
                    &:after {
                        @include filter-radio-icon-checked;
                        @include absolute-center;
                        left: auto;
                    }
                }
                &.fa-square-o {
                    @include filter-checkbox-icon-background;
                    margin-top: $spacer*0.125;
                }
                &.fa-check-square {
                    @include filter-checkbox-icon-checked-background;
                    margin-top: $spacer*0.125;
                    &:after {
                        @include filter-checkbox-icon-checked;
                        @include absolute-center;
                        left: auto;
                        border-radius: $spacer*0.25;
                    }
                }
            }
        }
    }
    #refinement-group-price {
        .values {
            &.content {
                button {
                    span {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .refinement-color {
        .values {
            &.content{
                li {
                    @include media-breakpoint-up(lg) {
                        padding: 0;
                        button {
                            padding: $spacer;
                            width: 100%;
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        button {
                            padding: $spacer*0.25 $spacer*0.625;
                            i {
                                &.fa-square-o {
                                    margin-top: $spacer*0.375;
                                }
                                &.fa-check-square {
                                    margin-top: $spacer*0.375;
                                }
                            }
                        }
                    }
               }
            }
        }
    }
}


.refinement-product-type-card {
    //background: $light-gray;
    display: flex;
    align-items: center;
    height: 100%;
    //padding: $spacer $spacer*2.5;
    border-radius: 8px;
    padding-left: 1px;

    @include media-breakpoint-up (lg) {
        flex-direction: column;
        justify-content: center;
        @include typography(13);
        font-weight: 500;
    }

    img {
        font-size: 0;
        height: 32px;
        width: 32px;
        margin: 0 16px 0 4px;
    }

    &:hover {
        i.fa {
            box-shadow: 0 0 0 1px $radiocheck-border-color;
        }
    }
    i {
        &.fa {
            position: relative;
            vertical-align: middle;
            margin-right: $product-refinement-swatch-spacing;
            transition: box-shadow $transition-speed ease-out;

        }
        &.fa-circle-o {
            @include radio-icon-background;
        }
        &.fa-check-circle {
            @include radio-icon-checked-background;
            &:after {
                @include radio-icon-checked;
                @include absolute-center;
                left: auto;
            }
        }
        &.fa-square-o {
            @include filter-checkbox-icon-background;
        }
        &.fa-check-square {
            @include filter-checkbox-icon-checked-background;
            &:after {
                @include filter-checkbox-icon-checked;
                @include absolute-center;
                left: auto;
                border-radius: $spacer*0.25;
            }
        }
    }
}

.refinement-bar,
.row .grid-header {
    .refinements.horizontal-multipledropdowns-filter {
        .dropdown-menu {
            overflow-y: auto;
            max-height: calc(100vh - 10px);
            .card,
            .card-body {
                background: $white;
            }
        }
        &.sticky-filters {
            .dropdown-menu {
                transform: translate3d(5px, 35px, 0px);
                max-height: calc(100vh - 65px);
            }
        }
    }

    .horizontal-multipledropdowns-filter-refinement {
        position: relative;

        .dropdown-menu {
            width: 300px;
            max-height: 550px !important;
            min-width: inherit;
            top: $spacer*2.75 !important; // importants overwrite inline styles added by javascript for dropdowns
            left: 0 !important;
            transform: initial !important;
            border: none;
            box-shadow: 0px $spacer*2 $spacer*4 rgba(0, 0, 0, 0.05);
            padding: $spacer*2.5 $spacer;

             /*PAP-4198: Adding scrollbar to show all the time for Safari*/
            /* Set the width and height of the scrollbar */
            &::-webkit-scrollbar {
                width: $spacer*0.625;
                height: $spacer*0.625;
            }
            
            /* Set the background color of the scrollbar */
            &::-webkit-scrollbar-track {
                background-color: $seashell;
            }
            
            /* Set the color of the scrollbar thumb */
            &::-webkit-scrollbar-thumb {
                background-color: $scroll-gray;
                border-radius: $spacer*0.3125;
            }
            
            /* Keep the scrollbar always visible */
            &::-webkit-scrollbar-thumb:vertical {
                min-height: $spacer*0.5;
            }
            /*PAP-4198: Adding scrollbar to show all the time for Safari*/
        }
    }

    .device-model-select {
        .horizontal-multipledropdowns-filter-btn {
            @include typography(13);
            font-weight: 500 !important;
        }
        .horizontal-multipledropdowns-filter-refinement {
            .dropdown-menu {
                left: 0 !important;
                overflow: hidden;
                overflow-y: auto;

                @include media-breakpoint-down(md) {
                    max-height: 270px !important;
                    width: 230px;
                }
            }
        }
    }

    .custom-select:focus, select:focus {
        box-shadow: none;
    }

    .custom-select, select.form-control, select {
        background-image: url(../images/icons/icon-arrow-down.svg);
        background-size: $dropdown-icon-size;
        @include media-breakpoint-up(lg) {
            padding-right: $spacer-xl * 2;
        }
    }

    .horizontal-multipledropdowns-filter-btn {
        text-align: left;
        color: $brand-primary;
        @include typography(13);
        font-weight: 500 !important;

        &:after {
            float: none;
        }
        @include media-breakpoint-up(lg) {
            &:after {
                width: auto;
                height: auto;
            }
            &:hover:after,
            &:focus:after {
                top: 0;
                left: 0;
            }
        }
    }

    .mobile-search-refinements {
        .collapsible-xl.aside .title::after {
            @include media-breakpoint-down(lg) {
                height: auto;
                width: auto;
                text-align: right;
                padding-right: 10px;
                z-index: auto;
                top: auto;
            }
        }

        .refinements {
            .card.aside {
                border-color: $medium-gray;
                border-radius: 0;

                .card-header{
                    .card-title{
                        @include typography(10);
                        padding-top: $spacer*2;
                        padding-bottom: $spacer*2;
                    }
                }
            }
        }
    }

    .mobile-search-refinements,
    .refinement-category {
        .filter-bar-mobile {
            padding-top: $spacer*4;
            border-top: 1px solid $light-gray;
            ul {
                display: flex;
                flex-wrap: wrap;
            }
        }
        
        @include media-breakpoint-down(lg) {
            .filter-bar-mobile {
                padding-top: $spacer*4;
                border-top: 1px solid $medium-gray;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            .selected-filters-list {
                margin-left: -$spacer-sm;
                li {
                    margin-left: $spacer-sm;
                    margin-right: $spacer-sm;
                }
                .text-link {
                    color: $navy;
                    margin-top: 8px;
                    margin-left: 8px;
                    font-size: 14px;
                }
            }
        }

        .card-header {
            background-color: $white;
        }
        
        ul, li, button, a {
           @include format-dropdown-text;
        }
    }

    .filter-header {
        margin-left: 0;
        margin-right: 0;
        padding-top: $spacer;
        padding-bottom: $spacer;

        .header-bar {
            display: flex;
            background: $white;
            padding: 0;

            .result-count,
            button.close:after {
                color: $navy;
            }

            .js-close-filters {
                color: $primary-black;
            }
        }
    }

    .refinements .refinement-category .values .values li {
        padding-top: 0;
    }
}

//Horizontal Refinements - Desktop ONLY
@include media-breakpoint-up(lg) {
    //Multiple Dropdowns
    .horizontal-multipledropdowns-filter-refinement.card.aside {
        margin-right: 20px;
        min-width: auto;
        height: auto;

        &:last-child {
            margin-right: 0;
        }

        .horizontal-multipledropdowns-filter-btn {
            font-weight: 500 !important;
            @include typography(13);
            padding: 5px 0;
            color: $primary-black;

            &::before {
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 1px;
                width: 100%;
                background: $primary-black;
                content: " ";
                display: none;
            }
    
            &[aria-expanded="true"],
            &:hover,
            &:focus {
                &::before {
                    display: block;
                }
            }
            &[aria-expanded="false"]:not(:hover) {
                &::before {
                    display: none;
                }
            }
        }
    }
}

@include media-breakpoint-up(sd) {
    .horizontal-multipledropdowns-filter-refinement.card.aside {
        margin-right: 35px;
    }
}


@include media-breakpoint-up(xl) {
    .horizontal-multipledropdowns-filter-refinement.card.aside {
        margin-right: 64px;
    }
}


.device-model-select {
    margin-right: 20px;

    @include media-breakpoint-up(sd) {
        margin-right: 35px;
    }

    @include media-breakpoint-up(xl) {
        margin-right: 64px;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        margin-right: 0;
    }

    .horizontal-multipledropdowns-filter-btn {
        padding-right: 0;
        position: relative;
        min-width: auto;
        width: auto;
        padding-left: 0;
        max-width: fit-content;
        color: $primary-black;

        @include media-breakpoint-down(md) {
            border: 0;
            color: $primary-black;
            padding: 2px 0;
            font-weight: 500 !important;
            @include typography(13);

            &::after {
                content: "";
                background-image: url(../images/icons/down-arrow.svg);
                background-size: 14px;
                padding-right: $spacer;
                background-repeat: no-repeat;
                background-position: right;
                width: 14px;
                height: 24px;
                margin: 0;
                top: -1px;
                right: 0;
                left: 0;
            }
        }

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            width: 100%;
            background: $primary-black;
            content: " ";
            display: none;
        }

        &[aria-expanded="true"],
        &:hover,
        &:focus {
            &::before {
                display: block;
            }
        }
    }

    .horizontal-multipledropdowns-filter-refinement {
        .dropdown-menu {
            .card.card-body {
                padding: 0;
                font-size: 12px;
                line-height: 16px;
                color: $black;
                margin: 0;
                background: transparent;

                > li {
                    position: relative;
                    padding: 0 0 $spacer $spacer*1.5 !important;
                    cursor: pointer;
                    @include typography(13);

                    button {
                        width: 100%;
                        text-align: left;
                        color: $black;
                    }

                    &::before {
                        @include checkbox-icon-checked;
                        display: none;
                    }

                    &:last-child {
                        padding-bottom: 0 !important;
                    }

                    i {
                        display: none !important;
                    }

                    &.selected {
                        font-weight: bold;

                        &::before {
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 2px;
                            font-size: 12px;
                            line-height: 16px;
                            color: $black;
                        }
                        
                        button {
                            font-weight: bold;
                        }
                        
                    }
                }
            }
        }
    }
}

.refinement-bar {
    @include media-breakpoint-down (md) {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        overflow: hidden;
        height: auto;
        bottom: 0;
    }

    .device-model-select {
        width: 60%;
        padding-left: 10px;

        select {
            border: 0;
        }
    }

    .mobile-filters-scrollable {
        height: 100%;
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        flex: 1;

        .mobile-search-refinements {
            .collapsible-xl.aside {
                .title::after {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .refinements {
                .card.aside {
                    .card-header {
                        .card-title {
                            padding-top: $spacer*1.25;
                            padding-bottom: $spacer*1.25;
                        }
                    }
                }
            }
        }

        .selected-filter-wrapper {
            display: block;
            @include typography(13);
        }

        .sort-by-container {
            .custom-radio {
                .custom-control-label {
                    &:before {
                        @include filter-radio-icon-background;
                    }
                    &:after {
                        top: 0;
                        left: calc(#{-$filter-radiocheck-button-size} - #{$radiocheck-button-margin});
                    }
                }
                .custom-control-input {
                    &:checked ~ .custom-control-label {
                        &:before {
                            @include filter-radio-icon-checked-background;
                        }
                        &:after {
                            @include filter-radio-icon-checked;
                            outline: none;
                        }
                    }
                }
            }
        }
    }

    .filter-header {
        padding: $spacer 0 $spacer*1.5;

        .search-result-count {
            @include typography(13);
            color: $dark-gray;
        }

        .selected-filters-list{
            display: flex;
            flex-wrap: wrap;
            padding-top: $spacer*1.5;
            border-bottom: 1px solid $medium-gray;
        }

        .reset {
            display: none;
        }
    }

    .filter-footer {
        padding: $spacer 0 $spacer;
        background-color: $white;
        border-top: 1px solid $medium-gray;

        .reset {
            @include typography(13);
        }
    }
}

.filter-bar {
    li {
        &.filter-value {
            button {
                background: transparent;
                display: flex;
                align-items: center;

                img {
                    height: 20px;
                    width: 20px;
                    font-size: 0;
                    margin-right: $spacer*0.5;
                }

                .swatch-circle {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background-size: 22px;
                    background-position: -1px -1px;
                    margin-right: $spacer*0.5;
                }
                
                &:after {
                    left: initial;
                    width: auto;
                    height: auto;
                    border-radius: initial;
                    z-index: 0;
                    transition: none;
                    right: $spacer;
                    font-size: 9px;
                    margin-top: 1px;
                }
            }
        }
    }
    .text-link.reset {
        font-size: $spacer*0.875;
        margin-top: $spacer/2;
        margin-left: $spacer;
        @include media-breakpoint-up (lg) {
            margin-left: $spacer*1.25;
        }
    }
}

.product-data {
    .pdp-email-product-notify {
        input {
            border-color: $dark-gray;
            border-right: 0;
            border-top-left-radius: $input-border-radius;
            border-bottom-left-radius: $input-border-radius;
            &:hover,
            &:focus {
                box-shadow: none;
                border-color: $dark-gray;
            }
        }
    }
}

.horizontal-multipledropdowns-filter-dropdown-menu {
    .values.content {
        margin: 0 auto;
    }
}

.amer-refinements {
    .refinements {
        .refinement-color {
            .values {
                &.content {
                    @include media-breakpoint-up(md) {
                        li.amer-swatch {
                        background-color: transparent;
                        border: 0;
                        margin-left: 0;
                        margin-right: 0;

                            button {
                                margin: 0 $spacer*1.5;

                                .swatch-circle {
                                    &.White {
                                        border: 1px solid $medium-gray;
                                    }
                                }
                            }
                        }
                    }
                    @include media-breakpoint-up (lg) {
                        margin: 0 auto;
                        justify-content: start;
                        max-width: 100%;
                        overflow: hidden;

                        .swatches-grid {
                            .amer-swatch {
                                margin: 0 0 $spacer;
                            }
                        }
                    }
                }
            }
        }

        .refinement-price,
        .refinement-devicetype {
            .values.content {
                li.col-sm-4{
                    @include media-breakpoint-up (lg) {
                        width: calc(25% - 16px);
                        flex-basis: calc(25% - 16px);
                        padding: 0;

                        button {
                            width: 100%;
                            padding: $spacer;
                        }
                    }
                }

                @include media-breakpoint-up (lg) {
                    margin: 0 auto;
                    justify-content: start;
                    max-width: 100%;
                    overflow: hidden;
                    padding-left: $spacer;
                    padding-right: $spacer;

                    .swatches-grid {
                        .amer-swatch {
                            margin: 0 0 $spacer;
                        }
                    }
                }
            }
        }
    }
}

.refinement-bar {
    width: 100%;
    max-width: 100%;
    z-index: 1000; /*covers header on mobile*/

    .refinements.horizontal-multipledropdowns-filter {
        .refinement-refinementproducttype {
            .dropdown-menu {
                overflow-y: auto;
                @include typography(13);
            }
        }
    }
    &.in {
        @include media-breakpoint-down (lg) {
            .custom-radio {
                + .custom-radio {
                    margin-top: 20px;
                }
            }
        }
    }
    
    @include media-breakpoint-up (md) {
        z-index:998;
    }
}

#horizontal-filter-mobile {
    padding: $spacer 0;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
        padding: $spacer;
    }
    
    @include media-breakpoint-up(lg) {
        padding: 0;
    }

    .search-result-count {
        @include typography(13);
        color: $dark-gray;
    }

    .device-model-select {
        select {
            border: 0;
            padding-left: 0;
        }
    }

    .filter-bar {
        ul {
            overflow: hidden;
            padding-left: 0;
            padding-top: $spacer*1.25;
            border-bottom: 1px solid $medium-gray;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $spacer*2.8125;
    
            li {
                float: none;
            }
        }
    }

    &.sticky-filters {
        padding: $spacer;
        .filter-bar-mobile {
            display: none;
        }
    }
}


.desktop-search-refinements .horizontal-multipledropdowns-filter {
    @include media-breakpoint-up(lg) {
        .results-count-wrapper,
        .sort-by-wrapper {
            width: auto;
        }

        .results-count-wrapper {
            color: $dark-gray;
            @include typography(13);
            font-weight: 500;
        }

        .filter-left-part {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .sort-by-container {
            position: relative;
            justify-content: flex-start;
            select[name=sort-order] {
                width: 82px;
                height: 36px;
                text-align: center;
                padding-left: 1px;
                text-align: left;
                margin-right: 20px;
                padding-right: 12px;
                background: none;
                background-image: none!important;
                border-radius: 0;
                color: $primary-black;
                border: 0;
                border-bottom: none;
                cursor: pointer;
                @include typography(13);
                font-weight: 500 !important;
                &:hover,
                &:focus {
                    border-bottom: 1px solid $primary-black !important;
                }
            }
            select[name=sort-order].open {
                border-bottom: 1px solid $primary-black !important;
            }

            .dropdown-icon {
                position: absolute;
                left: 39%;
                top: 54%;
                transform: translateY(-50%);
                width: 14px;
                height: 14px;
                background: url('../images/icons/down-arrow.svg') no-repeat center;
                background-size: contain;
                pointer-events: none;
                transition: transform 0.3s ease;
            }

            select.open + .dropdown-icon {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .select-label {
            position: absolute;
            color: transparent;
            opacity: 0;
            z-index: -1;
            font-size: 14px;
        }

        .dropdown-toggle {
            &::after {
                content: "";
                background-image: url(../images/icons/down-arrow.svg);
                background-size: 14px;
                padding-right: $spacer;
                background-repeat: no-repeat;
                background-position: right 4px;
                width: 14px;
                height: 22px;
                margin: 0;
                padding: 0;
                left: auto;
                right: -2px;
            }
        }
    }

    @include media-breakpoint-up(sd) {
        .sort-by-container {
            select[name=sort-order] {
                margin-right: 35px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .sort-by-container {
            select[name=sort-order] {
                margin-right: 64px;
            }
        }
    }
}

.grid-header {
    .filter-results {
        &::before {
            transform: translate(-30px, -50%);
            top: 50%;
            @include media-breakpoint-down (md) {
                transform: translateY(-50%);
                right: 12px;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFySURBVHgBrVLLbYNAEGUxsuybL7bsmzuw0wFUYNJBXEGcCgIdkAqSVGCnApMKTCoIN5C4cAMhPnkPEWtJIIkUjzQsMzvz3uzMKMo/RXx1TCCj0egWvzrUh9phGPp/BlgsFqeqqtZQTwgxoeZ5bkRR5HUCNIxmbQixQuIO510QBM58Pl/CPsE+lGX5oqrqRE5O0/SgDYdDHf+PdCD4827PD0sHCCtZQ0nSAkBuXD+BTDzBsgbLHsEOyn4eDAYruJ/YB7A5qLQFQIJvPQCYheP+/EYhvCRJjBiidPWgy9lUpEN9sLjKDyKUXwRgOo4l1O0aZy8ApzMej48cqeS2AGLLcZpU8lnQsBgN2zXJ26IoPE3TbmBbs9nsDY2ud6JuIhwmO98qS4gYE/F5IsiQSN7lOMRca1mWuWDbyhdgjAFqAmDDRDKBfQObu/LAyTCOub09mE6n3Ikjq6DyOUzEhl7JcWofAHcfCQx+RTJ3wOY+KJeWD8x1soVV5jO5AAAAAElFTkSuQmCC);
                background-repeat: no-repeat;
                background-position: center;
                content: " ";
                display: block;
                height: $spacer;
                width: $spacer;
            }
        }
        .filter-count {
            height: $product-refinement-filter-button-height;
            width: auto;
            border-radius: $product-refinement-filter-button-border-radius;
            border: 0;
            padding: 0 0 0 8px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        @include media-breakpoint-down(lg) {
            padding: 0 36px 0 15px;
            min-height: $spacer*2.5;
            height: $spacer*2.5;
            display: flex;
            align-items: center;
            max-width: inherit;
            border: 0;
            font-size: 14px;
            line-height: 20px;
        }
        &::after {
            border-width: 1px;
        }
    }
}

.sticky-filters {
    right: $product-refinement-horizontal-onedropdown-sticky-bar-right;
    width: auto;
    @include media-breakpoint-up (lg) {
        width: 100%;
    }
}

.product-grid {
    .video-container {
        max-height: 370px;

        @include media-breakpoint-down (lg){
            height: calc(100% - 190px);
        }

        .video-player {
            border-radius: 20px;
            overflow: hidden;

            iframe {
                height: 105%;
                @include media-breakpoint-up (lg) {
                    height: 114%;
                }

                @include media-breakpoint-up(sd) {
                    height: 120%;
                }
            }
        }
    }

    .product {
        display: flex;
        min-height: 100%;
        align-items: flex-start;
        .product-tile {
            display: flex;
            width: 100%;
            flex-direction: column;
            min-height: 100%;
            margin-bottom: $spacer*2;
            @include media-breakpoint-up (lg) {
                margin-bottom: 45px;
            }

            .main-link {
                flex: 1 0 70%;
                margin-bottom: 10px;
            }

            .product-tile-image-container {
                height: 100%;
                margin-bottom: 0;
                @include media-breakpoint-up (lg) {
                    &.show-secondary-image {
                        min-height: 406px;
                    }
                }                

                &.full-bleed-image {
                    padding: calc(100% + (#{$product-tile-padding-top-bottom} * 2) - (#{$product-tile-padding-right-left} * 2)) 0 0;
                    img {
                        top: 0;
                        left: 0;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                    }
                }
            }
        }
    }
}

.plp-three-tile-wrapper {
    .image-text-block {
        .image-cropper {
            .image-wrapper {
                .background-image {
                    @include media-breakpoint-down (md) {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        padding-top: 0;
                        border-radius: 0%;
                    }
                }
                    @include media-breakpoint-down (md) {
                    &::before {
                        padding-top: $aspect-ratio-square;
                    }
                }
            }
        }
    }
}

.search-results {
    .row {
        &:not(.no-gutters) {
            &.product-grid {
                @include media-breakpoint-down (sm) {
                    margin-right: -$spacer*0.6875;
                    margin-left: -$spacer*0.6875;
                }
            }
        }
    }

    // allows for different grid gutter widths at different breakpoints
    @each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
        @include media-breakpoint-up($grid-breakpoint) {
        $grid-gutter-half-width: $grid-gutter-width / 4;
        .row:not(.no-gutters) {
            margin-right: -$grid-gutter-half-width;
            margin-left: -$grid-gutter-half-width;
        }
        .row:not(.no-gutters) > .col,
        .row:not(.no-gutters) > [class*="col-"] {
            padding-right: $grid-gutter-half-width;
            padding-left: $grid-gutter-half-width;
        }
        }
    }
}