//@import "~core/components/hero";

.popsockets-hero-banner {
    position: relative;
    overflow: hidden;
    display: flex;
    max-height: calc(100vh - 104px);
    height: #{$popsockets-hero-banner-mobile-aspect-ratio + 'vw'};
    
    @include media-breakpoint-up(md) {
        height: #{$popsockets-hero-banner-desktop-aspect-ratio + 'vw'};
        max-height: $popsockets-hero-banner-destop-max-height;

        .full-bleed & {
            max-height: 1000px;
        }

        .transparent-header~div & {
            padding-top: 70px; // height of tablet transparent nav
        }
    }

    @include media-breakpoint-up(lg) {
        .transparent-header~div & {
            padding-top: 136px; // height of desktop transparent nav
        }
    }    

    .hero-container {
        display: flex;
        padding: $spacer*2.5 $spacer;
        height: 100%;
        width: 100%;
    
        @include media-breakpoint-up(md) {
            padding: $spacer*5 $spacer*2;
        }
        @include media-breakpoint-up(xl) {
            .full-bleed & {
                max-width: map-get($container-max-widths, xl);
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.text-placement-middle-left {
            justify-content: flex-start;
            align-items: flex-end;
            
            @include media-breakpoint-up(md) {
                justify-content: flex-start;
                align-items: center;
            }
        }

        &.text-placement-bottom-left {
            justify-content: flex-start;
            align-items: flex-end;
        }

        &.text-placement-middle-center {
            justify-content: center;
            align-items: flex-end;
            text-align: center;
            
            @include media-breakpoint-up(md) {
                justify-content: center;
                align-items: center;
            }
        }
        &.overlay {
            &::after {
                @include absolute-cover;
                background-color: rgba($color: black, $alpha: 0.1);
                content: " ";
            }
        }
    }
    .hero-background-link {
        z-index: 1;
        @include absolute-cover;
        text-indent: -999px;
    }

    // Height settings
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 0; // fix for IE11 flexbox align-items bug
        &.no-image {
            height: auto;
        }
    }
    
    .hero-text {    
        @include media-breakpoint-up(md) {
            max-width: 75%;
        }
    
        @include media-breakpoint-up(lg) {
            max-width: 50%;
        }    
        
        .hero-subtitle {
            margin-top: $spacer / 2;
        }
        .hero-buttons {
            margin-top: $spacer * 1.5;
        }

        .hero-button {
            margin-top: 0;
            display: inline-block;
            position: relative;
        }
    }
    &.short {
        min-height: $hero-min-height-short;
        height: auto;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-short-md;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-short-lg;
        }
    }
    &.medium {
        min-height: $hero-min-height-medium;
        height: auto;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-medium-md;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-medium-lg;
        }
    }
    &.tall {
        min-height: $hero-min-height-tall;
        height: auto;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-tall-md;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-tall-lg;
        }
    }
    &.screen-height {
        min-height: calc(100vh - #{$header-height} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
        height: auto;
        @include media-breakpoint-up(md) {
            min-height: calc(100vh - #{$header-height-md} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
        }
        @include media-breakpoint-up(lg) {
            min-height: calc(100vh - #{$header-height-lg} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
        }
        .transparent-header~div & {
            min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            @include media-breakpoint-up(md) {
                min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            }
            @include media-breakpoint-up(lg) {
                min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            }
        }
    }
    &.short-mobile {
        @include media-breakpoint-down(sm) {
            min-height: $hero-min-height-short;
            height: auto;
        }
    }
    &.medium-mobile {
        @include media-breakpoint-down(sm) {
            min-height: $hero-min-height-medium;
            height: auto;
        }
    }
    &.tall-mobile {
        @include media-breakpoint-down(sm) {
            min-height: $hero-min-height-tall;
            height: auto;
        }
    }
    &.screen-height-mobile {
        @include media-breakpoint-down(sm) {
            min-height: calc(100vh - #{$header-height} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            height: auto;
        }
        .transparent-header~div & {
            @include media-breakpoint-down(sm) {
                min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                height: auto;
            }
        }
    }

    &.add-text-under {
        min-height: 456px !important;
        @include media-breakpoint-up (md) {
            min-height: 528px !important;
        }
    }

    &.photo-hotspots {
        border-radius: 0;
    }

    &.original-image {
        height: auto !important; 
        max-height: inherit !important ;
        min-height: auto !important;

        .hero-container {
            padding: 0;

            @include media-breakpoint-up(md) {
                padding: 0;
            }

            @include media-breakpoint-up(xl) {
                .full-bleed & {
                    max-width: 100%;
                }
            }
        }

        .image-wrapper {
            flex-basis: 100%;
        }

        .hero-text {
            position: absolute;
            padding: $spacer*2.5 $spacer;
        
            @include media-breakpoint-up(md) {
                padding: $spacer*5 $spacer*2;
            }
        }

        &.add-text-under {
            .hero-text {
                position: relative;
                padding: 0;

                @include media-breakpoint-up(md) {
                    padding: 0;
                }
            }
        }
    }
}

.hero-text {   
    &.text-under {
        margin-top: $spacer;
        padding: 0 $spacer $spacer;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-top: $spacer*2;
        }    

        .hero-title {
            font-family: $primary-font !important;
            margin-bottom: $spacer*0.5;
            a {
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-transform: inherit;
                letter-spacing: inherit;
                color: inherit;
                background: inherit;
                margin-bottom: inherit;
            }
        }

        .hero-subtitle {
            @include typography (9);
            font-size: 20px;
            a {
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-transform: inherit;
                letter-spacing: inherit;
                color: inherit;
                background: inherit;
            }
        }

        .hero-buttons {
            margin-top: $spacer * 1.5;
        }

        .hero-button {
            margin-top: 0;
            display: inline-block;
            position: relative;
        }
    } 
}
