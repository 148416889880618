//Extend this file from rvw_integeration_core for PAP-1333
/*
 * Based off of Yotpo CSS Widget Version: 2020-04-26_10-52-32
 */

.yotpo {
    //Override default styling of widget.css
    &.yotpo-main-widget {
        .yotpo-thank-you .yotpo-thankyou-footer {
            .social-link {
                padding: 0 !important;
            }
            .yotpo-default-button {
                padding: 6px 11px !important;
            }
        }
    }

    &.yotpo-small {
        .yotpo-bottomline {
            .product-tile & {
                .text-m {
                    display: block;
                    font-size: 11px;
                    font-weight: 700;
                    color: $primary-black !important;
                    &:hover {
                        color: $primary-black !important;
                    }
                    &::before {
                        content: "(";
                        padding-right: 1px;
                    }
                    &::after {
                        content: ")";
                        padding-left: 1px;
                    }
                }
            }
        }
    }
}