@import "~core/components/layout";

[class*='experience-commerce_layouts'] {

    // vertical spacing between layout containers
    &+[class*='experience-commerce_layouts'] > div {
        margin-top: $page-designer-layout-spacing;

        @include media-breakpoint-up(md) {
            margin-top: $page-designer-layout-spacing-desktop;
        }
    }


    //enables setting class on layout's custom class 
    .no-gutters  {
        .row {
            & > .region[class*=" col-"] {
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }
    }

    // vertical spacing between layout items on mobile
    .region[class*='col-md'] {
        @include media-breakpoint-down(sm) {
            // single column in mobile
            &+.region[class*='col-12']:not(.order-1),
            &.order-2[class*='col-12'] {
                margin-top: $spacer*1.5;
            }
            // 2 column in mobile
            &+.region[class*='col-6']:not(:nth-child(1)):not(:nth-child(2)) {
                margin-top: $spacer*1.5;
            }
            // 3 column in mobile
            &+.region[class*='col-4']:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                margin-top: $spacer*1.5;
            }
        }
    }

}

.mobile-2r-1c.no-wrap-on-mobile {
    .container {
        .region:first-child {
            padding-right: 0;
            @include media-breakpoint-up(lg) {
                padding-left: 0;
            }
        }
        .region:last-child {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.experience-belowFold {
    margin-top: $page-designer-layout-spacing;

    @include media-breakpoint-up(md) {
        margin-top: $page-designer-layout-spacing-desktop;
    }
}

// Make sure promo tiles on PLP are sized appropriately
.product-grid {
    .promo-tile {
        // hero as a promo tile
        .hero {
            &.short {
                min-height: $hero-min-height-short;
                @include media-breakpoint-up(md) {
                    min-height: $hero-min-height-short-md;
                }
                @include media-breakpoint-up(lg) {
                    min-height: $hero-min-height-short-lg;
                }
            }
            &.medium {
                min-height: $hero-min-height-medium;
                @include media-breakpoint-up(md) {
                    min-height: $hero-min-height-medium-md;
                }
                @include media-breakpoint-up(lg) {
                    min-height: $hero-min-height-medium-lg;
                }
            }
            &.tall {
                min-height: $hero-min-height-tall;
                @include media-breakpoint-up(md) {
                    min-height: $hero-min-height-tall-md;
                }
                @include media-breakpoint-up(lg) {
                    min-height: $hero-min-height-tall-lg;
                }
            }
            &.screen-height {
                min-height: calc(100vh - #{$header-height} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                @include media-breakpoint-up(md) {
                    min-height: calc(100vh - #{$header-height-md} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                }
                @include media-breakpoint-up(lg) {
                    min-height: calc(100vh - #{$header-height-lg} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                }
                .transparent-header~div & {
                    min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                    @include media-breakpoint-up(md) {
                        min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                    }
                    @include media-breakpoint-up(lg) {
                        min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                    }
                }
            }
            &.no-height {
                @include media-breakpoint-down(md) {
                    min-height: inherit;
                }
            }
        }
    }
}