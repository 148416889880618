@import "~core/components/photoHotspots";

.photo-hotspots {
    overflow: hidden;
    border-radius: $photo-hotspot-tile-radius;
    .hotspot {
        background: none;
        background-image: url('../../images/icons/icon-plus.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        &:before {
            display: none;
        }
        .hotspot-border-inner,
        .hotspot-border-outer {
            transition: none;
        }
        //inner border
        .hotspot-border-inner {
            border: 0;
        }

        //outer border
        .hotspot-border-outer {
            content: '';
            margin-top: -$photo-hotspot-margin-top-outer;
            margin-right: -$photo-hotspot-margin-right-outer;
            animation: shopTheLookDotKeyframe 1.5s ease-in-out infinite;
        }

        &:hover {
            width: $photo-hotspot-size;
            height: $photo-hotspot-size;
            margin-top: -$photo-hotspot-size/2;
            margin-left: -$photo-hotspot-size/2;
            .hotspot-border-inner {
                line-height: $photo-hotspot-size;
                width: $photo-hotspot-size + $photo-hotspot-border-size-inner*2;
                height: $photo-hotspot-size + $photo-hotspot-border-size-inner*2;
                border: $photo-hotspot-border-size-inner solid $photo-hotspot-border-color-inner;
            }
            .hotspot-border-outer {
                width: $photo-hotspot-size + $photo-hotspot-border-size-inner*2 + $photo-hotspot-border-size-outer*2;
                height: $photo-hotspot-size + $photo-hotspot-border-size-inner*2 + $photo-hotspot-border-size-outer*2;
                border: $photo-hotspot-border-size-outer solid $photo-hotspot-border-color-outer;
            }
        }
    }

    &.aspect-ratio-square .photo-tile-figure{
        @include aspect-ratio($image-text-block-aspect-ratio-square);

        img.photo-tile-image {
            position: absolute;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            height: auto;
        }
    }
    &.aspect-ratio-landscape .photo-tile-figure{
        @include aspect-ratio($image-text-block-aspect-ratio-landscape);

        img.photo-tile-image {
            position: absolute;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            height: auto;
        }
    }
    &.aspect-ratio-portrait .photo-tile-figure{
        @include aspect-ratio($image-text-block-aspect-ratio-portrait);
        
        &::before {
            padding-top: calc(4 / 3.2 * 100%);
        }

        img.photo-tile-image {
            position: absolute;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            height: auto;
        }
    }
}

@keyframes shopTheLookDotKeyframe {
    0% {
        opacity: 0.2;
        transform: scale(0.8);
    }
    50% {
        opacity: 0.4;
    }
    75% {
        opacity: 0.6;
    }
    100% {
        opacity: 0;
        transform: scale(1.3);
    }
}

// tooltip contents
.photo-hotspots-tooltip.tooltip {
    margin-bottom: $spacer;
}
.photo-hotspots-tooltip-text {
    text-align: left;
    padding: $photo-hotspot-text-spacing;
}
.photo-hotspots-tooltip-product {
    text-decoration: none;
    display: block !important;
    padding: $spacer;
    @include media-breakpoint-up(lg) {
        padding: $spacer*2;
    }
    .product-image {
        margin: 0 auto $spacer;
    }
    .product-attributes {
        padding: 0;
    }
}

.gallery-section {
    padding-top: $spacer*4;

    .head {
        padding: 0 $spacer;
    }

    h2 {
        margin: 0 0 $spacer*1.5;
        font-family: $headline-l-font-family;
        font-size: $headline-l-font-size;
        line-height: $headline-l-line-height;
        text-transform: $headline-l-text-transform;
        @include media-breakpoint-up(lg) {
            margin: 0 0 $spacer/2;
            font-family: $headline-xl-font-family;
            font-size: $headline-xl-font-size;
            line-height: $headline-xl-line-height;
            text-transform: $headline-xl-text-transform;
        }
    }

    P {
        margin: 0;
    }
}

.shop-the-look-container {
    .photo-hotspots {
        border-radius: $spacer*0.5;
    }
}
