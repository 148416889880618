
.row .form-group {
    margin-top: calc(#{$form-group-margin-top} - #{$form-help-text-margin-top});
}

.form-group {
    margin: 0;
    &~.form-group,
    &~.btn-block {
        margin-top: $form-group-margin-top;
    }
    &.required .form-control-label::before {
        content: "* ";
        color: $danger;
    }
    label {
        @include label-styles;
        color: $form-label-color;
    }
    .form-text,
    .invalid-feedback {
        font-size: $form-help-text-font-size;
        line-height: $form-help-text-line-height;
        letter-spacing: $form-help-text-letter-spacing;
        margin-top: $form-help-text-margin-top;
        display: block;
    }
    .form-text {
        color: $form-help-text-color;
    }
    .invalid-feedback {
        color: $danger;
        display: none;
    }
    .was-validated .form-control:invalid  {
        display: block;
    }
}


.form-control, select.form-control{
    outline: none;
    border: $input-border-width solid $input-border-color;
    box-shadow: $input-box-shadow;
    color: $input-text-color;
    background-color: $input-background-color;
    border-radius: $input-border-radius;
    font-family: $input-font;
    font-size: $input-font-size;
    line-height: $input-font-line-height;
    letter-spacing: $input-font-letter-spacing;
    transition: border-color $transition-speed ease-out, box-shadow $transition-speed ease-out;
    padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
    height: $input-height;
    background-clip: border-box;
    @include media-breakpoint-up(md) {
        font-size: $input-font-size-md;
    }
    &:hover {
        border-color: $input-border-color-hover;
        box-shadow: $input-box-shadow-hover;
    }
    &:focus,
    &.focus
    &:focus:hover {
        outline: none;
        border-color: $input-border-color-focus;
        box-shadow: $input-box-shadow-focus;
    }
    &:disabled,
    &.disabled {
        @include disabled;
        background-color: $input-background-color;
    }
    &.form-control-sm {
        font-size: $input-sm-font-size;
        line-height: $input-sm-font-line-height;
        padding: $input-sm-padding-top $input-sm-padding-right $input-sm-padding-bottom $input-sm-padding-left;
        height: $input-sm-height;
    }
    &.is-invalid {
        background-image: none;
        border-color: $danger;
        padding-right: $input-padding-right;
        &:focus {
            border-color: $danger;
            box-shadow: $input-box-shadow-focus;
        }
    }
    &::placeholder {
        color: $input-placeholder-color;
    }
}

// Radio buttons and checkboxes
.custom-control {
    min-height: 0;
}
.custom-radio,
.custom-checkbox {
    user-select: none;
    padding-left: calc(#{$radiocheck-button-size} + #{$radiocheck-button-margin});
    .custom-control-label {
        @include radio-check-label;
        &:before,
        &:after {
            top: 0;
            left: calc(#{-$radiocheck-button-size} - #{$radiocheck-button-margin});
        }
    }
    .custom-control-input {
        &:checked ~ .custom-control-label:before,
        &:checked:disabled ~ .custom-control-label:before {
            background-color: $radiocheck-background-color-selected;
            border-color: $radiocheck-border-color-selected;
        }
        &:disabled ~ .custom-control-label {
            color: inherit;
            opacity: 0.5;
            &:before {
                box-shadow: none;
            }
        }
        &.is-invalid ~ .custom-control-label:before {
            background-color: $radiocheck-background-color-error;
            border-color: $radiocheck-border-color-error;
        }
        &:focus ~ .custom-control-label:before {
            box-shadow: none;
            border-color: $radiocheck-border-color-selected;
        }
    }
}

// Checkboxes
.custom-checkbox {
    .custom-control-label:before {
        @include checkbox-icon-background;
    }
    .custom-control-input {
        &:checked ~ .custom-control-label {
            &:before {
                @include checkbox-icon-checked-background;
            }
            &:after {
                @include checkbox-icon-checked;
            }
        }
    }
    &+.custom-checkbox {
        margin-top: $radiocheck-vertical-spacing;
    }
}

// Radio buttons
.custom-radio {
    .custom-control-label:before {
        @include radio-icon-background;
    }
    .custom-control-input {
        &:checked ~ .custom-control-label {
            &:before {
                @include radio-icon-checked-background;
            }
            &:after {
                @include radio-icon-checked;
            }
        }
    }
    &+.custom-radio {
        margin-top: $radiocheck-vertical-spacing;
    }
}

.custom-select {
    background-image: url(../images/icons/icon-arrow-down.svg); // this svg was generated from our icomoon font library
    background-size: $select-icon-size;
    padding-right: $select-padding-right;
    &.form-control-sm {
        background-size: $select-sm-icon-size;
        padding-right: $select-sm-padding-right;
    }
    &.is-invalid {
        background-image: url(../images/icons/icon-arrow-down.svg);
        background-size: $select-icon-size;
        padding-right: $select-padding-right;
        &.form-control-sm {
            background-size: $select-sm-icon-size;
            padding-right: $select-sm-padding-right;
        }
    }
}
select.form-control,
select {
    @extend .custom-select;
}

// Quantity stepper (replacement for qty select dropdown)
.quantity-stepper {
    display: flex;
    padding: 0;
    input,
    button {
        border: none;
        padding: 0;
    }
    input {
        width: calc(100% - #{$quantity-stepper-icon-size * 2} - #{$input-padding-left} - #{$input-padding-right} - 6px);
        text-align: center;
        height: 100%;
        padding-bottom: $input-padding-bottom;
        padding-top: $input-padding-top;
        &:focus {
            outline: none;
        }
    }
    button {
        font-size: $quantity-stepper-icon-size;
        padding-top: $input-padding-top;
        padding-bottom: $input-padding-bottom;
        &:before {
            font-weight: bold;
        }
        &.icon-minus {
            padding-left: $input-padding-left;
            padding-right: 3px;
        }
        &.icon-plus {
            padding-right: $input-padding-right;
            padding-left: 3px;
        }
    }
    &.form-control-sm {
        padding: 0;
        input {
            width: calc(100% - #{$quantity-stepper-icon-size-small * 2} - #{$input-sm-padding-left} - #{$input-sm-padding-right} - 6px);
            padding-top: $input-sm-padding-top;
            padding-bottom: $input-sm-padding-bottom;
        }
        button {
            font-size: $quantity-stepper-icon-size-small;
            padding-top: $input-sm-padding-top;
            padding-bottom: $input-sm-padding-bottom;
            &.icon-minus {
                padding-left: $input-sm-padding-left;
                padding-right: 2px;
            }
            &.icon-plus {
                padding-right: $input-sm-padding-right;
                padding-left: 2px;
            }
        }
    }
}

// inline button
.inline-btn {
    background-color: $inline-input-button-background-color;
    border-color: $inline-input-button-border-color;
    border-radius: $inline-input-button-border-radius;
    border-style: solid;
    border-width: $inline-input-button-border-width;
    &:hover,
    &:focus {
        border-color: $inline-input-button-border-color-hover;
        box-shadow: $inline-input-button-box-shadow-hover;
    }
    input {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: $inline-input-button-text-color;
        padding-left: 0;
        &:hover {
            border: none;
            box-shadow: none;
        }
        &:focus {
            background-color: transparent;
            outline: none;
        }
        &::placeholder {
            color: $inline-input-button-placeholder-color;
        }
    }
    .btn {
        border: none;
        padding: 0 5px 0 10px;
    }
}

// password validation helper 
.show-password {
    float: right;
    input {
        margin-right: $spacer / 2;
    }
}
.validator-container {
    width: 100%;
    padding: $spacer $spacer 0 $spacer;
    .password-validation-helper {
        margin: 0 auto;
        padding: 0;
        li {
            font-family: inherit;
            list-style-type: none;
            padding: calc(#{$spacer}/3) calc(#{$spacer}/2);
            display: flex;
            flex-flow: wrap;
            align-items: center;
            color: $black;
            @include icon($icon-arrow-right, before, 13px, $gray);
            &:before {
                width: $spacer * 2;
            }
            &.valid {
                color: $light-gray;
                @include icon($icon-checkmark, before, 13px, $success);
            } 
            &.error {
                @include icon($icon-alert, before, 13px, $danger);
            }
        }
    }
    .order-confirm-create-account & {
        padding-left: 0;
        padding-right: 0;
        .password-validation-helper {
            li {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
} 
