.card {
    border: none;
    border-radius: $card-border-radius;
    margin-bottom: $card-margin-bottom;
    font-size: $card-body-font-size;
    letter-spacing: $card-body-letter-spacing;
    line-height: $card-body-line-height;
    background-color: transparent;
    .card-header {
        background-color: $card-header-background;
        color: $card-header-color;
        border: 1px solid $card-header-border-color;
        border-bottom: none;
        padding: 0;
        flex-basis: auto; // IE11 fix
        // links, etc. in the top right corner of card header
        .pull-right {
            color: $card-header-color;
            font-size: $card-body-font-size;
            letter-spacing: $card-body-letter-spacing;
            line-height: $card-body-line-height;
            padding-top: $card-header-padding-top;
            padding-right: $card-header-padding-right;
        }
    }
    // SFRA markup is all over the place for card headers, so this attempts to account for those variations
    .card-title,
    .card-header h2,
    .card-header h3,
    .card-header h4,
    .card-header .checkout-card-header,
    h2.card-header {
        font-family: $card-header-font-family;
        font-size: $card-header-font-size;
        font-weight: $card-header-font-weight;
        letter-spacing: $card-header-letter-spacing;
        line-height: $card-header-line-height;
        text-decoration: none;
        text-transform: $card-header-text-transform;
        position: relative;
        margin-bottom: 0;
        padding-top: $card-header-padding-top;
        padding-left: $card-header-padding-left;
        padding-right: $card-header-padding-right;
        padding-bottom: $card-header-padding-bottom;
    }
    .card-body {
        background-color: $card-body-background;
        color: $card-body-color;
        padding: $card-no-header-body-padding-top;
        border: 1px solid $card-body-border-color;
        border-bottom: none;
        border-top: none;
        &:first-child {
            border-top: 1px solid $card-body-border-color;
        }
        &:last-child {
            border-bottom: 1px solid $card-body-border-color;
            border-radius: 0 0 calc(#{$card-border-radius} - 1px) calc(#{$card-border-radius} - 1px);
        }
    }
    .card-header ~ .card-body {
        padding: $card-body-padding-top $card-body-padding-right $card-body-padding-bottom $card-body-padding-left;
    }
    .card-footer {
        background-color: $card-footer-background;
        color: $card-footer-color;
        padding: $card-footer-padding;
        border-left: 1px solid $card-footer-border-color;
        border-right: 1px solid $card-footer-border-color;
        border-bottom: 1px solid $card-footer-border-color;
        border-top: 0;
        a {
            color: $card-footer-color;
        }
    }
    &.ghost {
        opacity: $card-ghost-opacity;
    }
    &.aside {
        border: 1px solid $card-aside-border-color;
        border-width: $card-aside-border-width;
        .card-header {
            border: none;
            .card-title {
                font-size: $card-aside-header-font-size;
                font-weight: $card-aside-header-font-weight;
                line-height: $card-aside-header-line-height;
                padding-top: $card-aside-header-padding-top;
                padding-left: $card-aside-header-padding-left;
                padding-right: $card-aside-header-padding-right;
                padding-bottom: $card-aside-header-padding-bottom;
            }
        }
        .card-body {
            padding: $card-aside-body-padding;
            border: $card-aside-body-border;
            z-index: 1; //needs to be on top of .card-header for hover states to show
        }
        .card-footer {
            border:  $card-aside-footer-border;
        }
    }
    &.no-borders {
        .card-header,
        .card-title,
        .card-body,
        .card-footer {
            border: none;
            padding-left: 0;
            padding-right: 0;
        }
    }
}