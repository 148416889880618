@import "~core/components/badges";

.modal .product-detail .badge-product-container {
    @include media-breakpoint-up(md) {
        &.badge-image-over {
            top: 0px;
            left: 17px;
        }
        .badge-product > p  {
	        line-height: 14px;
        }
    }
}

.product-detail .badge-product-container {
    @include media-breakpoint-up(md) {
        &.badge-image-over {
            top: 14px;
            left: 17px;
        }
    }
}

.badge-product-container {
    strong,
    b {
        font-weight: bold;
    }
}

.badge-product > p {
    margin-bottom: $product-tile-badge-margin-bottom;
    line-height: $product-tile-badge-line-height;
    font-size: $product-tile-badge-font-size;

    strong,
    b {
        font-weight: bold;
    }
}

.badge-product-container.badge-image-over {
    position: absolute;
    padding: $product-tile-badge-padding;
    .badge-product {
        padding: 0;
    }
}

.badge--pill {
    background: $blue;
    color: $white;
    padding: $product-tile-pill-padding;
}

.chips-page {
    .chip-badge {
        margin: 0 0 4px 0;
        display: inline-block;
        vertical-align: top;

        &:last-child {
            margin: 0 10px 4px 0;
        }

        a {
            border-radius: 20px;
            padding: 6px 12px 5px;
            font-size: 12px;
            line-height: 16px;
            min-height: 28px;
            font-weight: 600;
            text-decoration: none;
            position: relative;
            margin: 2px;
            display: inline-block;
            vertical-align: middle;
            @include icon($icon-icon-up-right, after);
            transition: all 200ms ease-out;

            &::after {
                font-size: 8px;
                padding-left: 2px;
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                margin: 0;
                padding: 8px 14px 7px;
                transition: all 200ms ease-out;
            }
        }
    }
 }