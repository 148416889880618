@import "~core/helperClasses";

// Generate typography classes, e.g. headline-xl, .headline-xl
@each $value in $typography {
    $typographyIndex: index($typography, $value);
    .#{nth($value, 1)} {
        @include typography($typographyIndex);
    }
}

.btn {
    &-tertiary {
        @include button(tertiary);
    }
    &-add-to-cart,
    &.add-to-cart,
    &.add-to-cart-global {
        @include button(add-to-cart);
    }
    &-lg {
        @include button(large);
    }
}

// Used to make all child components full height in a column layout
.full-height-children {
    & > .container > .row { 
        align-items: center !important;
    }
    .experience-component,
    .hero {
        height: 100%;
    }
}

// Used to round corners of an element
.rounded-corners {
    border-radius: $border-radius;
}

// Used to round corners of all image tiles in a column layout
.rounded-images {
    .photo-tile-container {
        border-radius: $border-radius;
        overflow: hidden;
    }
    .hero-media {
        border-radius: $border-radius;
    }
}

.rounded-right {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    &-mobile {
        @include media-breakpoint-down(sm) {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
    &-desktop {
        @include media-breakpoint-up(md) {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

.rounded-left {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    &-mobile {
        @include media-breakpoint-down(sm) {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }
    &-desktop {
        @include media-breakpoint-up(md) {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }
}

.rounded-top {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    &-mobile {
        @include media-breakpoint-down(sm) {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
    }
    &-desktop {
        @include media-breakpoint-up(md) {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
    }
}

.rounded-bottom {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    &-mobile {
        @include media-breakpoint-down(sm) {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
    &-desktop {
        @include media-breakpoint-up(md) {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

// Used to apply proper border-radius to the right column within a slider with 2 column slides
.two-column-slider {
    .region.col-12.col-md-6:nth-of-type(2) {
        padding-right: 0;
        padding-left: 0;
        .mobile-1r-1c {
            border-radius: 0 0 $border-radius $border-radius;
            @include media-breakpoint-up(md) {
                border-radius: 0 $border-radius $border-radius 0;
            }
        }
    }
}

//Used to give custom scalable css to Product Tile Sliders
.slider-container {
    &.slider-product-tile {
        .tns-controls button {
           top: 33%;
           transform: translateY(-33%);
        }
        @include media-breakpoint-down(sm) {
            margin-left: -$spacer;
            .slider {
                padding-left: $spacer;
                padding-right: $spacer;
            }
        }
        @include media-breakpoint-down(sm) {
            margin-left: -$spacer;
            .slider {
                padding-left: $spacer;
                padding-right: $spacer;
            }
        }
   }
   &.slider-reviews {
        .hero.half-and-half {
            .hero-container {
                @include media-breakpoint-down (md) {
                    padding: $spacer $spacer*2 0;
                }
            }
            .hero-media,
            .hero-media.width-1-2 {
                max-width: 320px;
                order: 1;
                background-size: contain;
                @include media-breakpoint-down (md) {
                    height: 320px;
                    margin-bottom: $spacer;
                }
                @include media-breakpoint-up (md) {
                    right: 12.5%;
                }
            }
            .hero-text {
                .hero-subtitle + .hero-title, 
                .hero-title + .hero-body, 
                .hero-embedded-component + .hero-body, 
                .hero-embedded-component .experience-region > *,
                .hero-body +.hero-buttons .hero-button {
                    margin-top: $spacer*2;
                }
            }
        }
    }
}

// apply to any multi-column layout container to prevent it from changing to single column on mobile
.no-wrap-on-mobile {
    > .container > .row {
        > .region[class*="col-md-1"] {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-2"] {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-3"] {
            flex: 0 0 25%;
            max-width: 25%;
            + .region[class*="col-12"],
            + .region[class*="col-6"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-4"] {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-5"] {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-6"] {
            flex: 0 0 50%;
            max-width: 50%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-7"] {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-8"] {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-9"] {
            flex: 0 0 75%;
            max-width: 75%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-10"] {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
        > .region[class*="col-md-11"] {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
            + .region[class*="col-12"] {
                margin-top: 0;
            }
        }
    }
}


/*Gradients TODO - build mixin for this*/
.background-- {
    &gradient-jade-periwinkle {
        background: linear-gradient(180deg, $jade 0%, $periwinkle 100%);
    }
    &gradient-cobalt-violet {
        background: linear-gradient(180deg, $cobalt 0%, $violet 100%);
    }
    &gradient-peach-watermelon {
        background: linear-gradient(180deg, $peach 0%, $watermelon 100%);
    }
    &gradient-sunshine-tangerine {
        background: linear-gradient(180deg, $sunshine 0%, $tangerine 100%);
    }
    &gradient-sunshine-jade {
        background: linear-gradient(180deg, $sunshine 0%, $jade 100%);
    }
    &gradient-watermelon-cobalt {
        background: linear-gradient(180deg, $watermelon 0%, $cobalt 100%);
    }
    &none {
        background: none !important;
        background-color: transparent!important;
    }
    &contain {
        background-size: contain!important;
    }
    &cover {
        background-size: cover!important;
    }
}

/* rotate classes TODO: mixin to take integer for duration */
.rotate {
    animation: rotation 25s infinite linear;
    &--fast {
        animation-duration: 15s;
    }
    &--medium {
        animation-duration: 35s;
    }
    &--slow {
        animation-duration: 45s;
    }
}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

.headline-bg-rotate {
    z-index: 1;
    position: relative;
    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100%;
        animation: rotation 25s infinite linear;
    }
    &.img-donate {
        &:after {
            width: 50px;
            height: 50px;
            background-image: url('../../images/charitycircle.png');
            background-position: 50%;
            top: 2px;
            margin-left: -$spacer*1.25;
        }
    }
}
.btn-customize {
    @include btn-customize;
}
.btn {
    &.btn-customize {
        @include btn-customize;
    }
}
.dark-theme {
    .btn-customize {
        color: $white;
        &::before {
            border: 2px solid $white;
        }
    }
    .btn {
        &.btn-customize {
            color: $white;
            &::before {
                border: 2px solid $white;
            }
        }
    }
}
.dark-theme {
    &.btn-customize {
        color: $white;
        &::before {
            border: 2px solid $white;
        }
    }
}

.font-family-secondary {
    font-family: $secondary-font;
}

.font-weight-bold {
    font-weight: 700;
}

.font-family-primary {
    font-family: $primary-font;
}

.hero-banner-background-- {
    &none {
        .hero-media {
            background: none !important;
            background-color: transparent !important;
        }
    }
    &contain {
        .hero-media {
            background-size: contain !important;
        }
    }
    &cover {
        .hero-media {
            background-size: cover !important;
        }
    }
    &auto {
        .hero-media {
            background-size: auto !important;
        }
    }
}
