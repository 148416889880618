@import "~core/components/modal";

.modal {
    .modal-header {
        position: relative;
        .close {
            position: absolute;
            left: auto;
            right: $spacer*1.5625;
            top: $spacer*1.25;
            line-height: inherit;
        }
    }
    .notify-me-modal-dialog {
        top: 25%;
        button.notify-me-btn {
            width: 100%;
            font-family: $button-add-to-cart-font;
            padding: $button-add-to-cart-padding-top $button-add-to-cart-padding-right $button-add-to-cart-padding-bottom $button-add-to-cart-padding-left;
            &:after {
                background-color: $primary-black;
            }

            &:hover,
            &:focus,
            &[aria-describedby*="popover"] {
                text-decoration: $button-add-to-cart-text-decoration-hover;
                &:after {
                    width: calc(100% + 8px);
                    height: calc(100% + 8px);
                    top: -4px;
                    left: -4px;
                }
            }
            .dark-theme & {
                color: $white;
                &:after {
                    background-color: $primary-black;
                }
                &:hover {
                    color: $primary-black;
                }
            }
            .icon-mail {
                background: url(../images/icons/email-icon.svg) no-repeat center;
                background-size: contain;
                display: inline-block;
                width: 22px;
                height: 18px;
                margin-right: 6px;
                vertical-align: text-top;
            }
        }
        .notify-submit-btn {
            top: $spacer;
            height: $spacer*3;
            &::after {
                left: auto;
                right: 0;
            }
            &:hover {
                &::after {
                    right: -1px;
                }
            }
        }
    }
}


.modal-content {
    .wrapper{
        display: inline-flex;
        background: #FEF0E5;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 100%;
        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
        .timer-logo {
            width: 13.33px;
            height: 13.33px;
            color: #FF9244;
            margin-right: 4px;
        }
        .timer-text {
            font-family: Inter;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            color: #FF9244;
            margin-right: 8px;
        }
        .timer-wrapper {
            font-family: Inter;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
        }
    }
    .notify-me-section {
        font-family: $primary-font;
        text-align: center;
        .pdp-email-product-notify {
            input {
                border-color: $dark-gray;
                border-right: 0;
                border-top-left-radius: $input-border-radius;
                border-bottom-left-radius: $input-border-radius;
                &:hover,
                &:focus {
                    box-shadow: none;
                    border-color: $dark-gray;
                }
            }
            .oosNotifyMeEmail {
                border-radius: 5px;
                border: 1px solid $primary-black;
                line-height: 19px;
            }
            .notify-me-submit {
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }
        }
        .notify-me-header {
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: $primary-black;
        }
        .notify-modal-subheader {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: $primary-black;
        }
    }
    .notify-me-footer {
        font-family: $primary-font;
        text-align: center;
        color: $primary-black-hover;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .modal-footer {
        .quantity {
            select#quantity-1 {
                padding: 16px 16px 16px 48px;
                
                @include media-breakpoint-down(sm) {
                    padding: 16px 16px 16px 28px;
                }
            }
        }
        .notify-me-continue-shopping {
            height: 48px;
            line-height: 19px;
        }
    }
}
