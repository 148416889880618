.refinementproducttype .slide, 
.visual-category-tile__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    padding: $spacer;
    height: 100%;
    
    .link {
        @include typography(11);
        display: flex;
        flex-grow: 1;
        align-items: center;
        font-weight: $font-weight-medium;

        @include media-breakpoint-up (lg) {
            font-weight: $font-weight-medium;
        }
    }

    img.slide {
        margin-bottom: $spacer;
        height: 75px;
        width: auto;
        display: block;
    }
}

#refinementproducttype {
    .refinementproducttype {
        justify-content: start;
    }
}

.refinementproducttype {
    max-height: 490px;
    overflow-y: scroll;
    .slide {
        background: $light-gray;
        border: 1px solid $light-gray;
        margin: 4px $spacer/2;
        border: 0;
        border-radius: $border-radius;
        @include media-breakpoint-up(md) {
            padding: $spacer;
            box-sizing: border-box;
            width: 100%;
            justify-content: center;
            align-items: flex-start;
            background: transparent;
        }

        .refinement-product-type-card {
            text-decoration: none;
            flex-direction: row;
        }

        span.text-center {
            height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

    }
    ul.values.content.row {
        display: none;
    }
}

.refinement-container{
    .visual-category-slider {
        border-bottom: $product-filter-border;
        padding: $spacer;
        @include media-breakpoint-down(sm) {
            padding: $spacer 0;
            .tns-inner {
                padding-right: 20%;
            }
        }
        a {
            text-decoration: none;
            &:hover {
                border-color: $brand-primary;
            }
        }
        #tns1 {
            display: flex;
            align-items: stretch;
        }
        .slider {
            min-width: 100%;
            align-items: flex-start;
            justify-content: center;

            @include media-breakpoint-down(sm) {
                justify-content: flex-start;
            }
        }
        #tns2-iw {
            min-width: 100%;
        }
        @include media-breakpoint-down(sm) {
            [data-controls='prev'] {
                left: -10px;
                display: none;
            }
            [data-controls='next'] {
                right: -10px;
                display: none;
            }
        }

        .slider {
            .slide {
                max-width: 224px;

                @include media-breakpoint-down(sm) {
                    max-width: 160px;
                }
            }
        }

        &.less-three {
            .slider {
                justify-content: center;
            }

            .tns-ovh {
                .tns-inner {
                    padding-right: 0 !important;
                }
            }
        }
    }
}

.refinements {
    .visual-category-slider {
        .slider.tns-horizontal {
            display: flex;
            .slide.tns-item {
                margin-right: $spacer!important;
            }
        }
    }
}

.card-body.content.value#refinement-group-refinementproducttype {
    display: none;
    overflow-x: scroll;
    -ms-overflow-style: none; // IE / edge
    scrollbar-width: none; // firefox
    &::-webkit-scrollbar {
        display: none; // chrome / safari / opera
    }
    .slide {
        min-width: 130px;
    }
}

.collapsible-xl.active .card-body.content.value#refinement-group-refinementproducttype {
    display: block;

    @include media-breakpoint-up (lg) {
        display: flex;
        border-radius: 0;
    }
}