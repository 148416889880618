@import "~core/components/formFields";

.mandatory-note {
    position: relative;
    &:before {
        content: "* ";
        color: $danger;
    }
}

.form-control, select.form-control {
    color: $input-text-color;
    &:focus,
    &.focus
    &:focus:hover {
        color: $input-text-color-focus;
        outline: 2px solid $outline-color;
    }
}

.custom-select {
    background-image: url(../images/icons/icon-select.svg); // this svg was generated from our icomoon font library
    background-size: $select-icon-size;
    &.focus-visible:focus {
        outline: 2px solid;
    }
    &.form-control-sm {
        background-size: $select-sm-icon-size;
    }
    &.is-invalid {
        background-image: url(../images/icons/icon-select.svg);
        background-size: $select-icon-size;
        &.form-control-sm {
            background-size: $select-sm-icon-size;
        }
    }
    &.quantity, 
    &.quantity-select {
        background-position: right 20px center;
        background-image: url(../images/icons/icon-quantity-select.svg);
        background-size: 10px;
    }
}

.inline-btn {
    input {
        padding-left: $input-padding-left;
    }

    .btn {
        border: none;
        padding: 0 $input-padding-right 0 10px;
    }
}

.custom-attribute-radio {
    user-select: none;
    padding-left: calc(#{$attribute-radiocheck-button-size} + #{$attribute-radiocheck-button-margin});
    .custom-control-label {
        position: initial;
        @include radio-check-label;
        &:before,
        &:after {
            top: calc(50% - #{$attribute-radiocheck-button-size/2});
            left: $spacer*2;
        }
        &:before {
            @include attribute-radio-icon-background;
        }
    }
    &.out-of-stock,
    &.disabled {
        .custom-control-label,
        .attribute-description {
            color: $medium-gray;
        }
        .custom-control-label {
            &:before {
                border-color: $medium-gray;
            }
        }
    }
}

:not(.bundle-swatches .custom-attribute-radio .custom-control-input)  {
    &:checked ~ .custom-control-label:before,
    &:checked:disabled ~ .custom-control-label:before {
        background-color: $attribute-radiocheck-background-color-selected;
        border-color: $attribute-radiocheck-border-color-selected;
    }
    &:checked ~ .custom-control-label {
        &:after {
            @include attribute-radio-icon-checked;
        }
    }
    &:disabled ~ .custom-control-label {
        color: inherit;
        opacity: 0.5;
        &:before {
            box-shadow: none;
        }
    }
    &.is-invalid ~ .custom-control-label:before {
        background-color: $attribute-radiocheck-background-color-error;
        border-color: $attribute-radiocheck-border-color-error;
    }
    &:focus ~ .custom-control-label:before {
        box-shadow: none;
        border-color: $attribute-radiocheck-border-color-selected;
    }
}

.validator-container {
    padding: $spacer 0 0 0;
    .password-validation-helper li {
        padding: calc(#{$spacer}/3) 0;
        font-size:$paragraph-font-size-small;
        @include media-breakpoint-up(md) {
            padding: calc(#{$spacer}/3) calc(#{$spacer}/2);
            font-size: $base-font-size;
        }
        &:before {
            width: $spacer * 1.5;

            @include media-breakpoint-up(md) {
                width: $spacer * 2;
            }
        }
        &.valid {
            color: $dark-gray;
        } 
    }
}

.gripSetup-2 #gripSetup-2,
.gripSetup-1 #gripSetup-1 {
    &.custom-attribute-radio .custom-control-input ~ .custom-control-label {
        &:before {
            background-color: $attribute-radiocheck-background-color-selected;
            border-color: $attribute-radiocheck-border-color-selected;
        }
        &:after {
            @include attribute-radio-icon-checked;
        }
    }
}

.custom-checkbox {
   .custom-control-label:after {
        top: 0;
        vertical-align: middle;
        left: -34px;
        width: $spacer * 1.5;
        height: $spacer * 1.5;

    }
}
.custom-radio,
.custom-checkbox {
    .custom-control-input {
        &:focus ~ .custom-control-label {
            outline: 2px solid $outline-color;
            &::after {
                outline: 2px solid $outline-color;
            }
        }
    }
    .custom-control-label {
        @include typography (11);
    }
}
.form-control:focus {
    outline: 2px solid $outline-color;
}
//PAP-1371 force all focus states to have consistent color
*{
    &:focus-visible {
        outline-color: $outline-color-mobile;
        @include media-breakpoint-up (lg) {
            outline-color: $outline-color;
        }
    }
}

.form-group {
    label {
        @include typography (11);
    }
}

.form-control {
    &:focus {
        outline: none;
        border: 2px solid $primary-black;
    }
}

.custom-select, select.form-control, select {
    background-image: url(../images/icons/icon-arrow-down.svg);
    background-size: 12px;
    font-size: $body-copy-xs-font-size;
}

select.form-control.selectpicker {
    border: 1px solid $primary-black;
    border-radius: $non-color-swatch-border-radius;
    height: 56px;
    color: $primary-black;
    &:hover {
        border-color: $primary-black;
    }
}

.custom-dropdown-div {
    position: absolute;
    background-color: $white;
    border-radius: 12px;
    font-size: $dropdown-font-size;
    font-family: $primary-font;
    z-index: 9999;
    padding: 8px 0;
    width: 200px;
    max-height: 304px;
    overflow-y: auto;
    scrollbar-width: auto;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: $medium-gray;
        border-radius: 12px;
    }
    &::-webkit-scrollbar-track {
        background: $light-gray;
        margin: 8px 0;
        border-radius: 12px;
    }
}

$line-height: 0.5px;
.custom-option {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 95%;
        height: 0.5px;
        background-color: $new-grey;
        transform: translateX(-50%);
    }
    padding-bottom: calc($spacer + $line-height);
}


.custom-dropdown-div {
    div {
        padding: $spacer;
        color: $primary-black;
        font-family: $primary-font;
        font-weight: 400;
        cursor: pointer;
        line-height: $body-copy-xs-line-height;
        font-size: $dropdown-font-size;
        &:not(.disabled):hover {
            background-color: $light-gray;
            cursor: pointer;
        }
        &.selected-option {
            font-weight: 600;
            @include media-breakpoint-down (sm) {
                font-weight: 700;
            }
            color: $primary-black;
        }
        &.disabled {
            pointer-events: auto;
            cursor: not-allowed;
            color: #969696;
        }
    }
} 
